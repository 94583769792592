import {
  Avatar,
  Collapse,
  Fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ArrowDropDownRounded";
import ExpandMore from "@material-ui/icons/ArrowRightRounded";
import classNames from "classnames";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { CustomLink } from "../../components/DisableLink";
import { AppSections } from "../../types/AppTypes";
import { isAppSectionEnabled, isAppSubSectionEnabled } from "../../utils/FeatureFlags";
import { SidebarAppSection } from "../Types";
import { sidebarStyles } from "./sidebarStyles";

type Props = {
  sectionName: AppSections;
  section: SidebarAppSection;
  isLast: boolean;
  isSidebarOpen: boolean;
};

export const SectionNavGroup: React.FC<Props> = (props) => {
  const location = useLocation();
  const classes = sidebarStyles();
  const [isOpen, setIsOpen] = React.useState(
    location.pathname.includes(props.sectionName.toLowerCase())
  );

  const fadeDelay = props.isSidebarOpen ? "200ms" : "0ms";

  const toggleAppSection = () => {
    setIsOpen(!isOpen);
  };

  const expandedAppStyles = () => {
    switch (props.sectionName) {
      case AppSections.TEMPLATES:
        return classes.appWrapperSelectedTemplate;
      case AppSections.FORMULARIES:
      case AppSections.DRUG_LISTS:
        return classes.appWrapperSelectedFlex;
      case AppSections.BENEFITS:
        return classes.appWrapperSelectedPlans;
      case AppSections.NETWORK:
        return classes.appWrapperSelectedNetwork;
      default:
        return classes.appWrapperSelectedDefault;
    }
  };

  const selectedSectionStyles = () => {
    switch (props.sectionName) {
      case AppSections.TEMPLATES:
        return classes.selectedListItemTemplate;
      case AppSections.FORMULARIES:
      case AppSections.DRUG_LISTS:
      case AppSections.DRUG_SEARCH:
        return classes.selectedListItemFlex;
      case AppSections.BENEFITS:
        return classes.selectedListItemPlans;
      case AppSections.NETWORK:
        return classes.selectedListItemNetwork;
      default:
        return classes.selectedListItemDefault;
    }
  };

  const getSectionColoring = (section: AppSections) => {
    switch (section) {
      case AppSections.TEMPLATES:
        return classes.selectedTemplates;
      case AppSections.FORMULARIES:
      case AppSections.DRUG_LISTS:
      case AppSections.DRUG_SEARCH:
        return classes.selectedFormularies;
      case AppSections.BENEFITS:
        return classes.selectedPlans;
      case AppSections.NETWORK:
        return classes.selectedNetwork;
      default:
        return classes.selectedDefault;
    }
  };

  const Icon = props.section.icon;
  if (props.section.subSections) {
    return (
      <div
        className={classNames(
          isOpen ? expandedAppStyles() : classes.appWrapper,
          props.isLast ? classes.lastItemMargins : ""
        )}
      >
        <ListItem
          button
          disableRipple
          alignItems="center"
          key={props.sectionName}
          onClick={toggleAppSection}
          selected={isOpen}
          classes={{
            selected: classes.selectedApp,
            button: classes.listButtonStyle,
            gutters: classes.listItemGutters,
          }}
          disabled={isAppSectionEnabled(props.sectionName) === false}
        >
          <ListItemIcon>
            <Icon htmlColor="white" />
          </ListItemIcon>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            <ListItemText color="inherit" primaryTypographyProps={{ noWrap: true }}>
              {props.sectionName}
            </ListItemText>
          </Fade>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </Fade>
        </ListItem>
        <Collapse in={isOpen} timeout="auto">
          <List component="div" style={{ marginBottom: "-5px" }} disablePadding>
            {props.section.subSections.map((subSection, index) => {
              const isDisabled = isAppSubSectionEnabled(subSection.name) === false;
              return (
                <CustomLink
                  key={`${subSection}-${index}-link`}
                  to={`/${props.sectionName.toLowerCase()}/${subSection.route.toLowerCase()}`}
                  className={classes.appSectionText}
                  disabled={isDisabled}
                >
                  <ListItem
                    button
                    disableRipple
                    key={subSection.name}
                    className="nested"
                    selected={location.pathname.includes(subSection.route.toLowerCase())}
                    disabled={isDisabled}
                    classes={{
                      selected: selectedSectionStyles(),
                      button: classes.listButtonStyle,
                      gutters: classes.listItemGutters,
                    }}
                  >
                    {subSection.icon ? (
                      <ListItemIcon>
                        <subSection.icon htmlColor="white" />
                      </ListItemIcon>
                    ) : (
                      <ListItemAvatar>
                        <Avatar classes={{ root: classes.smallAvatar }} variant="square">
                          {subSection.initials}
                        </Avatar>
                      </ListItemAvatar>
                    )}
                    <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
                      <ListItemText primaryTypographyProps={{ noWrap: true }}>
                        {subSection.name}
                      </ListItemText>
                    </Fade>
                  </ListItem>
                </CustomLink>
              );
            })}
          </List>
        </Collapse>
      </div>
    );
  } else {
    const isDisabled = isAppSectionEnabled(props.sectionName) === false;
    return (
      <CustomLink
        key={`${props.sectionName}-link`}
        to={`/${props.sectionName.toLowerCase()}`}
        className={classes.appSectionText}
        disabled={isDisabled}
      >
        <ListItem
          button
          disabled={isDisabled}
          disableRipple
          alignItems="center"
          key={props.sectionName}
          selected={location.pathname.includes(props.sectionName.toLowerCase())}
          className={props.isLast ? classes.lastItemMargins : ""}
          classes={{
            selected: getSectionColoring(props.sectionName),
            button: classes.listButtonStyle,
            gutters: classes.listItemGutters,
          }}
        >
          <ListItemIcon>
            <Icon htmlColor="white" />
          </ListItemIcon>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            <ListItemText color="inherit" primaryTypographyProps={{ noWrap: true }}>
              {props.sectionName}
            </ListItemText>
          </Fade>
        </ListItem>
      </CustomLink>
    );
  }
};
