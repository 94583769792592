import { ColumnApi, ColumnState } from "ag-grid-community";
import { get, update } from "idb-keyval";

export interface SavedGridColumnState {
  columnState: Array<ColumnState>;
  columnGroupState: Array<{
    groupId: string;
    open: boolean;
  }>;
}

export function useAgGridStoredTableState() {
  function updateTable(tableId: string, gridColumnAPI: ColumnApi) {
    return get<SavedGridColumnState>(tableId).then((savedTableSettings) => {
      if (savedTableSettings) {
        gridColumnAPI.setColumnState(savedTableSettings.columnState);
        gridColumnAPI.setColumnGroupState(savedTableSettings.columnGroupState);
      }
    });
  }

  function saveTableState(
    tableId: string,
    { columnGroupState, columnState }: SavedGridColumnState
  ) {
    return update<SavedGridColumnState>(tableId, () => ({ columnGroupState, columnState }));
  }

  return { updateTable, saveTableState };
}
