import { useMutation } from "@apollo/client";
import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import * as React from "react";
import { CenteredCircularLoading } from "../../../../components/loading/CenteredCircularLoading";
import { StandardDatePicker } from "../../../../components/Pickers/DatePickers";
import { apiFormatDate } from "../../../../utils/Date";
import { ApiListRule, TerminateRuleInput, TerminateRuleResponse, TERMINATE_RULE } from "../../api";
import { RuleDrawer } from "../../../../components/drawers/RuleDrawer";
import { RuleCard } from "../../../../components/cards/RuleCard";

const useStyles = makeStyles((theme) => ({
  tabContent: {
    position: "relative",
    marginTop: theme.spacing(1),
    height: "99%",
    width: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "70px auto",
    gridTemplateColumns: " 80px auto 80px ",
    gridTemplateAreas: `"left header right" "left center right"`,
  },
  centerContentGrid: {
    gridArea: "center",
    display: "grid",
    gridTemplateColumns: `repeat(auto-fill, minmax(280px, 1fr))`,
    gridTemplateRows: `repeat(auto-fill, minmax(210px, 1fr))`,
    overflow: "auto",
  },
  buttonBar: {
    gridArea: "header",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addRuleButton: {
    height: "2.5rem",
    minHeight: "20px",
  },
  ruleCardItem: {
    placeSelf: "stretch",
    width: "280px",
    height: "200px",
    padding: theme.spacing(1),
  },
}));

type Props = {
  rules: Array<ApiListRule>;
  isLoading: boolean;
  asOfDate: Date;
  onChangeAsOfDate: (date: Date) => void;
  onTerminateRule: () => void;
  onAddRule: () => void;
};

export const AlternativesListRules: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [showNewRuleDialog, setShowNewRuleDialog] = React.useState(false);
  const [terminateRule] = useMutation<TerminateRuleResponse, TerminateRuleInput>(TERMINATE_RULE);

  const handleAddRule = () => {
    setShowNewRuleDialog(true);
  };

  const handleRuleSave = () => {
    setShowNewRuleDialog(false);
    props.onAddRule();
  };

  const handleTerminateRule = (ruleId: string) => (date: Date) => {
    terminateRule({ variables: { id: ruleId, terminationDate: apiFormatDate(date) as string } })
      .then(() => {
        props.onTerminateRule();
      })
      .catch((e) => {
        console.error("ERROR WHILE TERMINATING RULE", e);
      });
  };

  const handleChangeDate = (date: Date | null) => {
    if (date) {
      props.onChangeAsOfDate(date);
    }
  };

  return (
    <section className={classes.tabContent}>
      <RuleDrawer
        isOpen={showNewRuleDialog}
        onCancel={() => {
          setShowNewRuleDialog(false);
        }}
        onRuleSave={handleRuleSave}
      />
      <div className={classes.buttonBar}>
        <StandardDatePicker
          label="Rules Effective as of"
          onChange={handleChangeDate}
          value={props.asOfDate}
          disabled={props.isLoading}
        />
        <Button
          className={classes.addRuleButton}
          onClick={handleAddRule}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          disabled={props.isLoading}
        >
          Add Rule
        </Button>
      </div>
      {props.isLoading ? (
        <div style={{ gridArea: "center" }}>
          <CenteredCircularLoading />
        </div>
      ) : (
        <div className={classes.centerContentGrid}>
          {props.rules.map((rule, index) => (
            <div className={classes.ruleCardItem} key={rule.id || index}>
              <RuleCard
                key={rule.id || index}
                rule={rule}
                handleTerminate={handleTerminateRule(rule.id as string)}
              />
            </div>
          ))}
        </div>
      )}
    </section>
  );
};
