import * as React from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react/lib/agGridReact";
import { CenteredCircularLoading } from "../../loading/CenteredCircularLoading";

import "./tableStyles.scss";

export const StyledAgGrid: React.FC<AgGridReactProps & { isLoading?: boolean }> = (props) => {
  const gridProps: AgGridReactProps = {
    pivotMode: false,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressColumnFilter: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnSelectAll: true,
          },
        },
        "filters",
      ],
      position: "left",
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      enableRowGroup: true,
      filter: "text",
    },
    suppressMenuHide: true,
    rowSelection: "single",
    ...props,
  };

  return (
    <div
      className="ag-theme-material"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {Boolean(props.isLoading) && <CenteredCircularLoading />}
      <AgGridReact {...gridProps} />
    </div>
  );
};
