import { CircularProgress, Divider, makeStyles, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Check from "@material-ui/icons/DoneRounded";
import ValidationErrorIcon from "@material-ui/icons/PriorityHighRounded";
import * as React from "react";
import { HPMSFileInfo } from "../../types";

const useStyles = makeStyles({
  root: {
    width: "25%",
  },
  fileRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dividerMargins: {
    marginLeft: 20,
    marginRight: 20,
  },
  fileValidatingSpinner: {
    display: "inline-block",
    flexShrink: 0,
  },
});

type Props = {
  formularyFiles: Array<HPMSFileInfo>;
  isValidating?: boolean;
};

export const FilesSidebar: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body2">Files:</Typography>
        {props.formularyFiles.map((file) => (
          <div key={file.name} className={classes.fileRow}>
            <Typography noWrap>{file.name}</Typography>
            {file.status === "error" ? (
              <ValidationErrorIcon color="error" />
            ) : props.isValidating ? (
              <CircularProgress size="1em" className={classes.fileValidatingSpinner} />
            ) : (
              <Check
                htmlColor={green[500]}
                visibility={file.status === "validated" ? "show" : "hidden"}
              />
            )}
          </div>
        ))}
      </div>
      <Divider orientation="vertical" className={classes.dividerMargins} />
    </>
  );
};
