import { useLazyQuery, useMutation, useQuery, ApolloError } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  fade,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import produce, { Draft } from "immer";
import * as React from "react";
import { SaveFab } from "../../../components/buttons/SaveFab";
import { CharacterCounter } from "../../../components/CharacterCounter";
import { AlertDialog } from "../../../components/dialogs/AlertDialog";
import { CenteredCircularLoading } from "../../../components/loading/CenteredCircularLoading";
import { OptionsTable } from "../../../components/tables/OptionsTable";
import { UserContext } from "../../../components/UserContext";
import { FormularyTier } from "../../../Flex/Formularies/FormularyPage/types";
import { sharedStyles } from "../../../Flex/styles";
import { EditNetworkTier } from "../../../Network/api";
import { apiFormatDate, awsDateStringToDate } from "../../../utils/Date";
import {
  CreatePlanInput,
  CreatePlanResponse,
  CREATE_PLAN,
  GetFormularyTiersResponse,
  GetNetworkTiersResponse,
  GetPlanConfigResponse,
  GET_FORMULARY_TIERS,
  GET_NETWORK_TIERS,
  GET_PLAN_CONFIG,
  ListLOBsResponse,
  ListPlanConfigsResponse,
  LIST_LOBS,
  LIST_PLAN_CONFIGS,
  UpdatePlanInput,
  UpdatePlanResponse,
  UPDATE_PLAN,
} from "../../api";
import {
  APIPlan,
  APIPlanConfig,
  AWSApolloError,
  PlanPageMode,
  PlanStatus,
  PlanType,
} from "../../types";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  fab: {
    color: theme.palette.common.white,
  },
  marginRightSpacing: {
    marginRight: theme.spacing(),
  },
  primaryAvatar: {
    background: theme.palette.primary.main,
  },
  noTiers: {
    fontStyle: "italic",
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "fit-content",
  },
}));

interface Props {
  plan: APIPlan;
  pageMode: PlanPageMode;
  planConfig: APIPlanConfig | null;
  onCreate: (planId: string) => void;
  onUpdate: (updatedPlan: APIPlan, planConfig: APIPlanConfig | null) => void;
}

export function PlanSetupTab(props: Props): JSX.Element {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const isNewPlan = props.pageMode === PlanPageMode.NEW;
  const readOnly = props.pageMode === PlanPageMode.VIEW;
  const [plan, setPlan] = React.useState<APIPlan>(props.plan);
  const [planGroupIds] = React.useState<Array<string> | null>(
    plan.hierarchies?.map((h) => h.groupId) ?? null
  );
  const [planConfig, setPlanConfig] = React.useState<APIPlanConfig | null>(props.planConfig);
  const [formularyTiers, setFormularyTiers] = React.useState<Array<FormularyTier>>([]);
  const [networkTiers, setNetworkTiers] = React.useState<Array<EditNetworkTier>>([]);
  const [pageErrors, setPageErrors] = React.useState<AWSApolloError | undefined>(undefined);

  //#region Apollo Queries
  //holy god damn batman this page is absolutely ridiculous with queries

  //#region Queries

  const { data: planConfigs, loading: loadingConfigs } = useQuery<ListPlanConfigsResponse>(
    LIST_PLAN_CONFIGS,
    {
      onError: (err) => {
        console.error(err);
        setPageErrors(err);
      },
    }
  );

  const { data: lobs, loading: loadingLobs } = useQuery<ListLOBsResponse>(LIST_LOBS, {
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  //#endregion
  //#region Mutations

  const [createPlan, { loading: isCreating }] = useMutation<CreatePlanResponse, CreatePlanInput>(
    CREATE_PLAN,
    {
      onCompleted: (res) => {
        if (res) {
          props.onCreate(res.createPlan.id);
        }
      },
      onError: (err: ApolloError) => {
        console.error(JSON.stringify(err, null, 2));
        setPageErrors(err);
      },
    }
  );

  const [updatePlan, { loading: isUpdating }] = useMutation<UpdatePlanResponse, UpdatePlanInput>(
    UPDATE_PLAN,
    {
      onCompleted: (res) => {
        if (res) {
          props.onUpdate(res.updatePlan, planConfig);
        }
      },
      onError: (err) => {
        console.error(err);
        setPageErrors(err);
      },
    }
  );

  //#endregion
  //#region Lazy Queries

  const [getFormularyTiers, { loading: loadingFormularyTiers }] = useLazyQuery<
    GetFormularyTiersResponse,
    { id: string }
  >(GET_FORMULARY_TIERS, {
    onCompleted: (res) => {
      setFormularyTiers(res.formulary.tiers.items);
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  const [getNetworkTiers, { loading: loadingNetworkTiers }] = useLazyQuery<
    GetNetworkTiersResponse,
    { id: string }
  >(GET_NETWORK_TIERS, {
    onCompleted: (res) => {
      setNetworkTiers(res.network.tiers.items);
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  const [getPlanConfig, { loading: loadingNewPlanConfig }] = useLazyQuery<
    GetPlanConfigResponse,
    { id: string }
  >(GET_PLAN_CONFIG, {
    onCompleted: (res) => {
      if (res) {
        setPlanConfig(res.planConfig);
        if (res.planConfig.formularyId) {
          getFormularyTiers({ variables: { id: res.planConfig.formularyId } });
        }
        if (res.planConfig.networkId) {
          getNetworkTiers({ variables: { id: res.planConfig.networkId } });
        }
      }
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  //#endregion

  //#endregion

  React.useEffect(() => {
    if (props.planConfig !== null) {
      getPlanConfig({ variables: { id: props.planConfig.id } });
    }
  }, [getPlanConfig, props.planConfig]);

  const isLoadingProperties = loadingConfigs || loadingLobs;
  const loadingPlanConfig = loadingNewPlanConfig;
  const noConfig = plan.configId === null;
  const invalidRxBIN = plan.rxBIN !== null && plan.rxBIN.length > 6;
  const invalidRxPCN = plan.rxPCN !== null && plan.rxPCN.length > 10;
  const invalidRxGroup = plan.rxGroup !== null && plan.rxGroup.length > 15;
  const isValidToSave =
    !!planConfig &&
    !!plan.name &&
    !!plan.externalId &&
    !!plan.lineOfBusiness?.id &&
    !!plan.planType &&
    !!plan.effectiveDate &&
    !invalidRxBIN &&
    !invalidRxPCN &&
    !invalidRxGroup &&
    (plan.notes?.length || 0) <= 255;

  const isSaving = isCreating || isUpdating;

  //#region Component Functions

  function handleSavePlan() {
    if (isNewPlan) {
      createPlan({
        variables: {
          input: {
            name: plan.name,
            lineOfBusinessId: plan.lineOfBusiness!.id,
            planType: plan.planType!,
            configId: plan.configId!,
            effectiveDate: plan.effectiveDate!,
            groupIds: planGroupIds ?? null,
            calendarYear: plan.calendarYear,
            notes: plan.notes,
            rxBIN: plan.rxBIN,
            rxPCN: plan.rxPCN,
            rxGroup: plan.rxGroup,
            externalId: plan.externalId,
            terminationDate: plan.terminationDate,
          },
        },
      });
    } else if (plan.effectiveDate && plan.planType && plan.lineOfBusiness) {
      updatePlan({
        variables: {
          id: plan.id,
          input: {
            configId: plan.configId,
            externalId: plan.externalId,
            groupIds: planGroupIds ?? null,
            name: plan.name,
            notes: plan.notes,
            rxBIN: plan.rxBIN,
            rxPCN: plan.rxPCN,
            rxGroup: plan.rxGroup,
            terminationDate: plan.terminationDate,
            status: PlanStatus.Draft,
            lineOfBusinessId: plan.lineOfBusiness.id,
            planType: plan.planType,
            effectiveDate: plan.effectiveDate,
          },
        },
      });
    }
  }

  interface Options {
    nullable: boolean;
    translator?: (value: string) => string | undefined;
  }

  function handleUpdatePlan(
    setField: (draft: Draft<APIPlan>, value?: any) => void,
    options: Options = { nullable: true }
  ) {
    return (event: React.ChangeEvent<any>) => {
      if (options.nullable || (!options.nullable && (event.target.value !== undefined || true))) {
        setPlan(
          produce<APIPlan>(plan, (draft) => {
            if (options.translator) {
              const value = options.translator(event.target.value);
              setField(draft, value);
            } else {
              setField(draft, event.target.value);
            }
          })
        );
      }
    };
  }

  function handleChangePlanConfig(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) {
    setPlan({ ...plan, configId: event.target.value as string });
    const config = planConfigs?.planConfigs.items.find(
      (config) => config.id === (event.target.value as string)
    );
    if (config) {
      getPlanConfig({ variables: { id: config.id } });
    }
  }

  function getFormularyTiersCardTitle() {
    if (planConfig) {
      return `Formulary tiers for ${planConfig.name}`;
    } else {
      return `Formulary tiers`;
    }
  }

  function getNetworkTiersCardTitle() {
    if (planConfig) {
      return `Network tiers for ${planConfig.name}`;
    } else {
      return `Network tiers`;
    }
  }

  //#endregion

  //#region OptionsTable arrays
  const planConfigOption = isNewPlan
    ? [
        {
          label: "Plan configuration",
          options: (
            <Select
              required
              fullWidth
              value={plan.configId}
              className={classes.textFieldOptions}
              onChange={handleChangePlanConfig}
              disabled={readOnly}
            >
              {planConfigs?.planConfigs.items.length === 0 ? (
                <MenuItem key="noPlans" disabled>
                  No plan configurations found.
                </MenuItem>
              ) : (
                planConfigs?.planConfigs.items.map((planConfig) => (
                  <MenuItem key={planConfig.id} value={planConfig.id}>
                    {planConfig.name}
                  </MenuItem>
                ))
              )}
            </Select>
          ),
        },
      ]
    : [
        {
          label: "Plan configuration",
          options: (
            <Select
              required
              fullWidth
              value={plan.configId}
              className={classes.textFieldOptions}
              onChange={handleChangePlanConfig}
              disabled={readOnly}
            >
              {planConfigs?.planConfigs.items.length === 0 ? (
                <MenuItem key="noPlans" disabled>
                  No plan configurations found.
                </MenuItem>
              ) : (
                planConfigs?.planConfigs.items.map((planConfig) => (
                  <MenuItem key={planConfig.id} value={planConfig.id}>
                    {planConfig.name}
                  </MenuItem>
                ))
              )}
            </Select>
          ),
        },
      ];

  const propertiesOptions = [
    {
      label: "Plan Name",
      options: (
        <TextField
          required
          value={plan.name}
          onChange={handleUpdatePlan((draft, value) => {
            draft.name = value;
          })}
          className={classes.textFieldOptions}
          disabled={noConfig || readOnly}
        >
          {plan.name}
        </TextField>
      ),
    },
    {
      label: "Plan type",
      options: (
        <Select
          fullWidth
          value={plan.planType || ""}
          className={classes.textFieldOptions}
          disabled={noConfig || readOnly}
          onChange={handleUpdatePlan((draft, value) => {
            draft.planType = value;
          })}
          style={{ textTransform: "capitalize" }}
        >
          {Object.keys(PlanType).map((planType) => (
            <MenuItem key={planType} value={planType}>
              {planType}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      label: "External ID",
      options: (
        <TextField
          value={plan.externalId || ""}
          onChange={handleUpdatePlan((draft, value) => {
            draft.externalId = value;
          })}
          className={classes.textFieldOptions}
          disabled={noConfig || readOnly}
        >
          {plan.externalId}
        </TextField>
      ),
    },
    {
      label: "Effective date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            disabled={noConfig || readOnly}
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={plan.effectiveDate ? awsDateStringToDate(plan.effectiveDate) : new Date()}
            onChange={(date) => {
              setPlan({
                ...plan,
                effectiveDate: apiFormatDate(date),
                calendarYear: date?.getFullYear() || null,
              });
            }}
            InputProps={{ readOnly: props.pageMode === PlanPageMode.EDIT }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Termination date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={plan.terminationDate ? awsDateStringToDate(plan.terminationDate) : null}
            onChange={(date) => {
              setPlan({ ...plan, terminationDate: apiFormatDate(date) });
            }}
            className={classes.textFieldOptions}
            disabled={noConfig || readOnly}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Line of business",
      options: (
        <Select
          required
          fullWidth
          value={plan.lineOfBusiness?.id || ""}
          className={classes.textFieldOptions}
          disabled={noConfig || readOnly}
          onChange={(event) => {
            setPlan({
              ...plan,
              lineOfBusiness:
                lobs?.linesOfBusiness.items.find(
                  (lob) => lob.id === (event.target.value as string)
                ) || null,
            });
          }}
        >
          {lobs?.linesOfBusiness?.items?.map((lob) => (
            <MenuItem key={lob.id} value={lob.id}>
              {lob.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      label: "RxBIN",
      options: (
        <TextField
          error={invalidRxBIN}
          helperText={invalidRxBIN ? "Exceeds max length of 6" : ""}
          value={plan.rxBIN || ""}
          onChange={handleUpdatePlan((draft, value) => {
            draft.rxBIN = value;
          })}
          className={classes.textFieldOptions}
          disabled={noConfig || readOnly}
        >
          {plan.rxBIN}
        </TextField>
      ),
    },
    {
      label: "RxPCN",
      options: (
        <TextField
          error={invalidRxPCN}
          helperText={invalidRxPCN ? "Exceeds max length of 10" : ""}
          value={plan.rxPCN || ""}
          onChange={handleUpdatePlan((draft, value) => {
            draft.rxPCN = value;
          })}
          className={classes.textFieldOptions}
          disabled={noConfig || readOnly}
        >
          {plan.rxPCN}
        </TextField>
      ),
    },
    {
      label: "RxGroup",
      options: (
        <TextField
          error={invalidRxGroup}
          helperText={invalidRxGroup ? "Exceeds max length of 15" : ""}
          value={plan.rxGroup || ""}
          onChange={handleUpdatePlan((draft, value) => {
            draft.rxGroup = value;
          })}
          className={classes.textFieldOptions}
          disabled={noConfig || readOnly}
        >
          {plan.rxGroup}
        </TextField>
      ),
    },
  ];
  //#endregion

  return (
    <>
      <section className={sharedClasses.tabContent}>
        <SaveFab isSaving={isSaving} isValidToSave={isValidToSave} onClickSave={handleSavePlan} />
        <div>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title={isNewPlan ? "Choose a configuration" : "Configuration"} />
            <Divider />
            <CardContent>
              {loadingConfigs ? (
                <CenteredCircularLoading />
              ) : (
                <OptionsTable settings={planConfigOption} noHover />
              )}
            </CardContent>
          </Card>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title="Properties" />
            <Divider />
            <CardContent>
              {noConfig ? (
                <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                  Select a plan configuration to see properties.
                </Typography>
              ) : isLoadingProperties ? (
                <CenteredCircularLoading />
              ) : (
                <OptionsTable settings={propertiesOptions} disabled={noConfig} noHover={noConfig} />
              )}
            </CardContent>
          </Card>
          {!noConfig && (
            <Card className={sharedClasses.formularyCard}>
              <CardHeader title="Notes" />
              <Divider />
              <CardContent>
                <TextField
                  multiline
                  fullWidth
                  disabled={readOnly}
                  variant="outlined"
                  error={(plan.notes?.length || 0) > 255}
                  placeholder="Add any specific notes for this plan (Optional)."
                  onChange={(event) => setPlan({ ...plan, notes: event.target.value })}
                  value={plan.notes || ""}
                  rows={6}
                  rowsMax={6}
                />
                {!readOnly && (
                  <CharacterCounter max={255} contentLength={plan.notes?.length || 0} />
                )}
              </CardContent>
            </Card>
          )}
        </div>
        <div>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title={getFormularyTiersCardTitle()} />
            <Divider />
            <CardContent>
              {loadingPlanConfig || loadingFormularyTiers ? (
                <CenteredCircularLoading />
              ) : (
                <List dense={contentSpacing === "dense"}>
                  {noConfig ? (
                    <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                      No plan configuration selected.
                    </Typography>
                  ) : formularyTiers.length === 0 ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No tiers found for this formulary." />
                    </ListItem>
                  ) : (
                    formularyTiers.map((tier) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={classes.primaryAvatar}>{`T${tier.rank}`}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={tier.name} />
                      </ListItem>
                    ))
                  )}
                </List>
              )}
            </CardContent>
          </Card>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title={getNetworkTiersCardTitle()} />
            <Divider />
            <CardContent>
              {loadingPlanConfig || loadingNetworkTiers ? (
                <CenteredCircularLoading />
              ) : (
                <List dense={contentSpacing === "dense"}>
                  {noConfig ? (
                    <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                      No plan configuration selected.
                    </Typography>
                  ) : networkTiers.length === 0 ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No tiers found for this network." />
                    </ListItem>
                  ) : (
                    networkTiers.map((network) => (
                      <ListItem>
                        <ListItemText primary={network.name} />
                      </ListItem>
                    ))
                  )}
                </List>
              )}
            </CardContent>
          </Card>
        </div>
      </section>
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
        error={pageErrors}
      />
    </>
  );
}
