import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  Fade,
  ListItemText,
  makeStyles,
  Collapse,
  Tooltip,
  Zoom,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import User from "@material-ui/icons/PersonRounded";
import ExpandLess from "@material-ui/icons/ArrowDropDownRounded";
import ExpandMore from "@material-ui/icons/ArrowRightRounded";
import { User as UserType } from "../../utils/User";
import { UserAreaSections } from "../../types/AppTypes";
import { SidebarUserAreaSection, UserAreaSectionTabs } from "../Types";
import { sidebarStyles } from "./sidebarStyles";
import classNames from "classnames";
import { CustomLink } from "../../components/DisableLink";
import { isAdminSectionEnabled, isUserAreaSectionEnabled } from "../../utils/FeatureFlags";
import { useAccountSwitcher } from "../../components/AccountSwitcher";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    maxWidth: "220px",
    margin: "0 8px 0 8px",
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  fullWidth: {
    width: "100%",
  },
  bottomMargin: {
    marginBottom: theme.spacing(),
  },
}));

type Props = {
  isSidebarOpen: boolean;
  currentSubAccount: UserType;
  onTransition: (height: number) => void;
};

export function AccountArea(props: Props): JSX.Element {
  const classes = useStyles();
  const sharedStyles = sidebarStyles();
  const fadeDelay = props.isSidebarOpen ? "200ms" : "0ms";
  const [showAll, setShowAll] = React.useState<boolean>(false);

  return (
    <List disablePadding className={classes.listRoot}>
      <Tooltip
        arrow
        TransitionComponent={Zoom}
        title={props.currentSubAccount.subAccountName}
        placement="right"
        classes={{ tooltip: classes.fullWidth }}
      >
        <ListItem
          button
          key="subaccount"
          onClick={() => {
            setShowAll(!showAll);
          }}
          alignItems="center"
          classes={{
            button: sharedStyles.listButtonStyle,
            gutters: sharedStyles.listItemGutters,
          }}
        >
          <ListItemIcon>
            <User htmlColor="white" />
          </ListItemIcon>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            <ListItemText
              color="inherit"
              primary={props.currentSubAccount.subAccountName}
              primaryTypographyProps={{ noWrap: true }}
              secondary={props.currentSubAccount.accountName}
              secondaryTypographyProps={{ color: "inherit" }}
            />
          </Fade>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            {showAll ? <ExpandLess /> : <ExpandMore />}
          </Fade>
        </ListItem>
      </Tooltip>
      <Collapse
        in={showAll}
        onEntered={(node) => {
          props.onTransition(node.clientHeight);
        }}
        onExited={(node) => {
          props.onTransition(node.clientHeight);
        }}
      >
        {Object.entries(UserAreaSectionTabs).map(([sectionName, section], index) => (
          <UserSectionNavGroup
            key={index}
            section={section}
            sectionName={sectionName as UserAreaSections}
            isLast={Object.entries(UserAreaSectionTabs).length - 1 === index}
            isSidebarOpen={props.isSidebarOpen}
            onTransition={props.onTransition}
          />
        ))}
      </Collapse>
    </List>
  );
}

type NavGroupProps = {
  sectionName: UserAreaSections;
  section: SidebarUserAreaSection;
  isLast: boolean;
  isSidebarOpen: boolean;
  onTransition: (height: number) => void;
};

function UserSectionNavGroup(props: NavGroupProps): JSX.Element {
  const sidebarClasses = sidebarStyles();
  const classes = useStyles();
  const location = useLocation();
  const accountSelector = useAccountSwitcher();
  const [isOpen, setIsOpen] = React.useState(
    location.pathname.includes(props.sectionName.toLowerCase())
  );
  const fadeDelay = props.isSidebarOpen ? "200ms" : "0ms";

  const toggleAppSection = () => {
    setIsOpen(!isOpen);
  };

  const Icon = props.section.icon;
  if (props.section.subSections) {
    return (
      <div
        className={classNames(sidebarClasses.appWrapper, props.isLast ? classes.bottomMargin : "")}
      >
        <ListItem
          button
          disableRipple
          alignItems="center"
          key={props.sectionName}
          onClick={toggleAppSection}
          selected={isOpen}
          classes={{
            selected: sidebarClasses.selectedApp,
            button: sidebarClasses.listButtonStyle,
            gutters: sidebarClasses.listItemGutters,
          }}
          disabled={isUserAreaSectionEnabled(props.sectionName) === false}
        >
          <ListItemIcon>
            <Icon htmlColor="white" />
          </ListItemIcon>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            <ListItemText color="inherit" primaryTypographyProps={{ noWrap: true }}>
              {props.sectionName}
            </ListItemText>
          </Fade>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </Fade>
        </ListItem>
        <Collapse
          in={isOpen}
          onEntered={(node) => {
            props.onTransition(node.clientHeight);
          }}
          onExited={(node) => {
            props.onTransition(node.clientHeight);
          }}
        >
          <List component="div" style={{ marginBottom: "-5px" }} disablePadding>
            {props.section.subSections
              .filter((subSection) => isAdminSectionEnabled(subSection.name))
              .map((subSection, index) => {
                return (
                  <CustomLink
                    key={`${subSection}-${index}-link`}
                    to={`/account/${props.sectionName.toLowerCase()}/${subSection.route.toLowerCase()}`}
                    className={sidebarClasses.appSectionText}
                    disabled={false}
                  >
                    <ListItem
                      button
                      disableRipple
                      key={subSection.name}
                      className="nested"
                      selected={location.pathname.includes(subSection.route.toLowerCase())}
                      classes={{
                        selected: sidebarClasses.selectedListItemDefault,
                        button: sidebarClasses.listButtonStyle,
                        gutters: sidebarClasses.listItemGutters,
                      }}
                    >
                      {subSection.icon ? (
                        <ListItemIcon>
                          <subSection.icon htmlColor="white" />
                        </ListItemIcon>
                      ) : (
                        <ListItemAvatar>
                          <Avatar classes={{ root: sidebarClasses.smallAvatar }} variant="square">
                            {subSection.initials}
                          </Avatar>
                        </ListItemAvatar>
                      )}
                      <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
                        <ListItemText primaryTypographyProps={{ noWrap: true }}>
                          {subSection.name}
                        </ListItemText>
                      </Fade>
                    </ListItem>
                  </CustomLink>
                );
              })}
          </List>
        </Collapse>
      </div>
    );
  } else {
    const isDisabled = isUserAreaSectionEnabled(props.sectionName) === false;
    if (props.section.externalRoute) {
      return (
        <ListItem
          button
          disabled={isDisabled}
          disableRipple
          component="a"
          href={props.section.externalRoute}
          target="_blank"
          alignItems="center"
          key={props.sectionName}
          selected={location.pathname.includes(props.sectionName.toLowerCase())}
          classes={{
            selected: sidebarClasses.selectedDefault,
            button: sidebarClasses.listButtonStyle,
            gutters: sidebarClasses.listItemGutters,
          }}
        >
          <ListItemIcon>
            <Icon htmlColor="white" />
          </ListItemIcon>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            <ListItemText color="inherit" primaryTypographyProps={{ noWrap: true }}>
              {props.sectionName}
            </ListItemText>
          </Fade>
        </ListItem>
      );
    } else if (props.section.route) {
      return (
        <CustomLink
          key={`${props.sectionName}-link`}
          to={`/account/${props.section.route.toLowerCase()}`}
          className={sidebarClasses.appSectionText}
          disabled={isDisabled}
        >
          <ListItem
            button
            disabled={isDisabled}
            disableRipple
            alignItems="center"
            key={props.sectionName}
            selected={location.pathname.includes(props.section.route.toLowerCase())}
            classes={{
              selected: sidebarClasses.selectedDefault,
              button: sidebarClasses.listButtonStyle,
              gutters: sidebarClasses.listItemGutters,
            }}
          >
            <ListItemIcon>
              <Icon htmlColor="white" />
            </ListItemIcon>
            <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
              <ListItemText color="inherit" primaryTypographyProps={{ noWrap: true }}>
                {props.sectionName}
              </ListItemText>
            </Fade>
          </ListItem>
        </CustomLink>
      );
    } else if (props.section.action) {
      return (
        <ListItem
          button
          disableRipple
          disabled={isDisabled}
          alignItems="center"
          key={props.sectionName}
          onClick={() =>
            props.section.action!({ showAccountSelection: () => accountSelector.show() })
          }
          classes={{
            button: sidebarClasses.listButtonStyle,
            gutters: sidebarClasses.listItemGutters,
          }}
        >
          <ListItemIcon>
            <Icon htmlColor="white" />
          </ListItemIcon>
          <Fade in={props.isSidebarOpen} style={{ transitionDelay: fadeDelay }}>
            <ListItemText color="inherit" primaryTypographyProps={{ noWrap: true }}>
              {props.sectionName}
            </ListItemText>
          </Fade>
        </ListItem>
      );
    } else {
      return <></>;
    }
  }
}
