import * as React from "react";
import { Option } from "../components/lists/Types";
import EditIcon from "@material-ui/icons/CreateRounded";
import InfoIcon from "@material-ui/icons/InfoRounded";
import { CopyIcon } from "../components/Icons/customIcons";

export const MENU_OPTIONS: Array<Option> = [
  { optionName: "Info", link: "view", icon: <InfoIcon fontSize="small" /> },
  { optionName: "Edit", link: "edit", icon: <EditIcon fontSize="small" /> },
  { optionName: "Copy", link: "copy", icon: <CopyIcon fontSize="small" /> },
];
