import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Typography,
  Popover,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as React from "react";
import { standardUIFormat } from "../../../utils/Date";
import TableSettingsIcon from "@material-ui/icons/TuneRounded";
import DateIcon from "@material-ui/icons/TodayRounded";
import ExportIcon from "@material-ui/icons/SaveAltRounded";
import { UserContext } from "../../UserContext";

const useStyles = makeStyles((theme) => ({
  controlBar: {
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    "&& p": {
      padding: theme.spacing(),
      margin: theme.spacing(),
    },
  },
  optionsButton: {
    margin: theme.spacing(),
  },
}));

type Props = {
  tableViewDate: Date;
  onDateChange: (date: MaterialUiPickersDate) => void;
};

export const ViewTableControlBar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [listAnchorEl, setListAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [dateAnchorEl, setDateAnchorEl] = React.useState<HTMLDivElement | null>(null);

  return (
    <div className={classes.controlBar}>
      <Typography color="inherit" variant="body2">{`Viewing drugs as of ${standardUIFormat(
        props.tableViewDate
      )}`}</Typography>
      <Button
        className={classes.optionsButton}
        color="inherit"
        onClick={(event) => {
          setListAnchorEl(event.currentTarget);
        }}
        startIcon={<TableSettingsIcon />}
      >
        Table Options
      </Button>
      <Popover
        open={Boolean(listAnchorEl)}
        onClose={() => {
          setListAnchorEl(null);
        }}
        anchorEl={listAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List dense={contentSpacing === "dense"}>
          <ListItem
            button
            onClick={(event) => {
              setDateAnchorEl(event.currentTarget);
            }}
          >
            <ListItemIcon>
              <DateIcon />
            </ListItemIcon>
            <ListItemText>Change Table Date</ListItemText>
          </ListItem>
          <ListItem button disabled>
            {/* TODO: Implement Export all functionality */}
            <ListItemIcon>
              <ExportIcon />
            </ListItemIcon>
            <ListItemText>Export All</ListItemText>
          </ListItem>
        </List>
      </Popover>
      <Popover
        open={Boolean(dateAnchorEl)}
        onClose={() => {
          setDateAnchorEl(null);
        }}
        anchorEl={dateAnchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            orientation="landscape"
            variant="static"
            openTo="date"
            value={props.tableViewDate}
            onChange={props.onDateChange}
          />
        </MuiPickersUtilsProvider>
      </Popover>
    </div>
  );
};
