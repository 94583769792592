import { useLazyQuery, useMutation } from "@apollo/client";
import { makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import { fade, useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/CreateRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { QuickViewCard } from "../../components/cards/QuickViewCard";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { CategorySearchList } from "../../components/lists/CategorySearchList";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { awsDateStringToDate } from "../../utils/Date";
import {
  APIFormularyItem,
  DeleteFormularyInput,
  DeleteFormularyResponse,
  DELETE_FORMULARY,
  ListFormulariesResponse,
  LIST_FORMULARIES,
} from "./api";
import { LegacyChip } from "./LegacyChip";
import InfoIcon from "@material-ui/icons/InfoRounded";
import { MenuOption } from "../../components/lists/Types";

const useStyles = makeStyles((theme: Theme) => ({
  formulariesList: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  previewArea: {
    gridColumn: "7 / span 8",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    background: "white",
    alignItems: "center",
  },
}));

type FormularyDeletionDialog = {
  stage: "closed" | "open" | "deleting" | "error";
  formulary: APIFormularyItem | undefined;
  error?: any;
};

export const ListFormulariesPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [selectedFormulary, setSelectedFormulary] = React.useState<APIFormularyItem>();
  const [formularyDeletion, setFormularyDeletion] = React.useState<FormularyDeletionDialog>({
    stage: "closed",
    formulary: undefined,
    error: undefined,
  });

  const [getFormularies, { data, loading }] = useLazyQuery<ListFormulariesResponse>(
    LIST_FORMULARIES,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [deleteFormulary] = useMutation<DeleteFormularyResponse, DeleteFormularyInput>(
    DELETE_FORMULARY
  );

  React.useEffect(() => {
    getFormularies();
  }, [getFormularies]);

  const VIEW_MENU_OPTION: MenuOption = {
    name: "Info",
    action: (selectedItem: string) => {
      history.push(`/formularies/view/${selectedItem}`);
    },
    icon: <InfoIcon fontSize="small" />,
  };
  const EDIT_MENU_OPTION: MenuOption = {
    name: "Edit",
    action: (selectedItem: string) => {
      history.push(`/formularies/edit/${selectedItem}`);
    },
    icon: <EditIcon fontSize="small" />,
  };
  const DELETE_MENU_OPTION: MenuOption = {
    name: "Delete",
    action: (selectedItem: string) => {
      const selectedFormulary = data?.formularies.items.find(
        (formulary) => formulary.id === selectedItem
      );
      if (selectedFormulary) {
        setFormularyDeletion({
          stage: "open",
          formulary: selectedFormulary,
        });
        setSelectedFormulary(undefined);
      }
    },
    icon: <DeleteIcon fontSize="small" />,
  };

  const handleFormularySelected = (itemId: string) => {
    const foundFormulary = data?.formularies.items.find((form) => form.id === itemId);
    setSelectedFormulary(foundFormulary);
  };

  const handleDeleteFormulary = async (formularyId: string | undefined) => {
    if (formularyId) {
      setFormularyDeletion({ ...formularyDeletion, stage: "deleting" });
      deleteFormulary({
        variables: {
          id: formularyId,
        },
      })
        .then(() => {
          setFormularyDeletion({ ...formularyDeletion, stage: "closed" });
          getFormularies();
        })
        .catch((e) => {
          console.error("Error deleting formulary: ", e);
          setFormularyDeletion({ ...formularyDeletion, stage: "error", error: e });
        });
    }
  };

  const formularyDeletionDialogContent = () => {
    if (formularyDeletion.formulary) {
      if (formularyDeletion.stage === "deleting") {
        return <CenteredCircularLoading />;
      } else if (formularyDeletion.error) {
        return formularyDeletion.error;
      } else {
        return (
          <Typography>{`Are you sure you want to delete ${formularyDeletion.formulary.name}?`}</Typography>
        );
      }
    } else {
      return <Typography>No formulary selected</Typography>;
    }
  };

  const formularyDeletionDialogTitle = () => {
    if (formularyDeletion.formulary) {
      return `Delete ${formularyDeletion.formulary.name}?`;
    } else {
      return "Delete Formulary";
    }
  };

  const goToNewFormulary = () => {
    history.push("/formularies/new");
  };

  return (
    <>
      <Paper square className={classes.formulariesList}>
        <CategorySearchList
          listData={
            data?.formularies.items.map((formulary) => ({
              id: formulary.id,
              name: formulary.name,
              groupName: formulary.lineOfBusiness,
            })) || []
          }
          dataLoading={loading}
          onItemSelection={handleFormularySelected}
          listAction={{
            buttonText: "New Formulary",
            action: goToNewFormulary,
          }}
          menuOptions={[VIEW_MENU_OPTION, EDIT_MENU_OPTION, DELETE_MENU_OPTION]}
        />
      </Paper>
      <div className={classes.previewArea}>
        {selectedFormulary ? (
          <QuickViewCard
            effectiveDate={awsDateStringToDate(selectedFormulary.effectiveDate)}
            externalId={selectedFormulary.externalId}
            name={selectedFormulary.name}
            terminationDate={
              selectedFormulary.terminationDate
                ? awsDateStringToDate(selectedFormulary.terminationDate)
                : null
            }
            lineOfBusiness={selectedFormulary.lineOfBusiness}
            actionChip={selectedFormulary.hasLegacyPlan ? <LegacyChip /> : undefined}
          />
        ) : (
          <Typography variant="h5">
            Select a <span style={{ color: theme.palette.primary.main }}>Formulary</span> to quickly
            view its properties
          </Typography>
        )}
      </div>
      <ConfirmationDialog
        isOpen={formularyDeletion.stage !== "closed"}
        isDisabled={formularyDeletion.stage === "deleting" || formularyDeletion.stage === "closed"}
        onNoHandler={() => {
          setFormularyDeletion({ ...formularyDeletion, stage: "closed" });
        }}
        onYesHandler={() => {
          handleDeleteFormulary(formularyDeletion.formulary?.id);
        }}
        dialogTitle={formularyDeletionDialogTitle()}
        dialogContent={formularyDeletionDialogContent()}
      />
    </>
  );
};
