import { CircularProgress, Drawer, makeStyles, Theme, Typography } from "@material-ui/core";
import Questionmark from "@material-ui/icons/HelpRounded";
import * as React from "react";
import { FormContext, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { PdfPreview } from "../../components/pdfPreview/PdfPreview";
import { useFormularyTemplatePreview } from "./api";
import { DEFAULT_STARTING_TEMPLATE, FORMULARY_TEMPLATE_LIST_PAGE } from "./constants";
import { FormularyTemplateForm } from "./FormularyTemplateForm/FormularyTemplateForm";
import { SimpleTemplateGuide } from "./FormularyTemplateForm/PreviewArea/SimpleTemplateGuide";
import { TemplateSection } from "./FormularyTemplateForm/types";
import {
  FormularyTemplateStatus,
  FullFormularyTemplate,
  toApiFormularyTemplateSettings,
} from "./types";

interface StyleProps {
  previewOut: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  settingsArea: {
    gridColumn: "span 8",
    alignSelf: "start",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
  },
  loadingArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  previewArea: (props) => ({
    position: "relative",
    backgroundColor: "#979797",
    gridColumn: "span 8",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    userSelect: "none",
    overflow: "scroll",
    ...(props.previewOut && {
      overflow: "hidden",
    }),
  }),
  fullPreviewDrawer: (props) => ({
    position: "sticky",
    top: 0,
    zIndex: 2,
    ...(!props.previewOut && {
      zIndex: 0,
      transition: theme.transitions.create("z-index", {
        duration: theme.transitions.duration.leavingScreen,
        delay: theme.transitions.duration.leavingScreen,
      }),
    }),
    width: "100%",
    height: "100%",
  }),
  fullPreviewDrawerPaper: {
    position: "inherit",
  },
}));

interface Props {
  templateStatus: FormularyTemplateStatus;
  saveTemplate: (template: FullFormularyTemplate) => void;
}

export const FormularyTemplateContainer: React.FC<Props> = (props) => {
  const history = useHistory();

  const { previewStatus, previewFormularyTemplate } = useFormularyTemplatePreview(
    <CenteredCircularLoading />
  );

  const [currentSection, setCurrentSection] = React.useState<TemplateSection>(null);
  const [showFullPreview, setShowFullPreview] = React.useState(false);
  const [isDialogVisible, setDialogVisibility] = React.useState(false);
  const [initializing, setIsInitializing] = React.useState(true); // tied to internal loading state

  const classes = useStyles({ previewOut: showFullPreview });

  const methods = useForm<FullFormularyTemplate>({
    defaultValues: DEFAULT_STARTING_TEMPLATE,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  React.useEffect(() => {
    if (props.templateStatus.status === "available" && initializing) {
      setIsInitializing(false);
      methods.reset(props.templateStatus.template);
    }
  }, [props.templateStatus, initializing, methods]);

  const handleChangeCurrentSection = (section: TemplateSection) => {
    setCurrentSection(section);
  };

  const navigateBack = () => {
    setDialogVisibility(!isDialogVisible);
    history.push(FORMULARY_TEMPLATE_LIST_PAGE);
  };

  const toggleConfirmDialog = () => {
    setDialogVisibility(!isDialogVisible);
  };

  const onPreview = () => {
    if (props.templateStatus.status !== "loading") {
      setShowFullPreview(true);
      previewFormularyTemplate(
        toApiFormularyTemplateSettings(methods.getValues({ nest: true }).settings)
      );
    }
  };

  const handleClosePreview = () => {
    setShowFullPreview(false);
  };

  const handleSaveTemplate = () => {
    props.saveTemplate(methods.getValues({ nest: true }));
  };

  return (
    <>
      <div className={classes.settingsArea}>
        {initializing ? (
          <div className={classes.loadingArea}>
            <CircularProgress />
          </div>
        ) : (
          <FormContext {...methods}>
            <form>
              <FormularyTemplateForm
                onChangeSection={handleChangeCurrentSection}
                onPreview={onPreview}
                onBackButtonClick={toggleConfirmDialog}
                onClosePreview={handleClosePreview}
                isSaving={props.templateStatus.status === "saving"}
                saveTemplate={handleSaveTemplate}
              />
            </form>
          </FormContext>
        )}
      </div>
      <div id="previewArea" className={classes.previewArea}>
        <Drawer
          anchor="right"
          variant="persistent"
          classes={{
            root: classes.fullPreviewDrawer,
            paper: classes.fullPreviewDrawerPaper,
          }}
          open={showFullPreview}
          SlideProps={{
            unmountOnExit: true,
            mountOnEnter: true,
          }}
        >
          <PdfPreview status={previewStatus} />
        </Drawer>
        <SimpleTemplateGuide selectedArea={currentSection} />
      </div>
      <ConfirmationDialog
        isOpen={isDialogVisible}
        onYesHandler={navigateBack}
        onNoHandler={toggleConfirmDialog}
        dialogIcon={<Questionmark fontSize="large" color="inherit" />}
        dialogTitle="Warning"
        dialogContent={
          <Typography variant="body1">
            Leaving this page will result in the loss of all unsaved changes. Are you sure you want
            to leave?
          </Typography>
        }
      />
    </>
  );
};
