export enum AppSections {
  TEMPLATES = "Templates",
  FORMULARIES = "Formularies",
  DRUG_LISTS = "Lists",
  DRUG_SEARCH = "Drugs",
  BENEFITS = "Benefits",
  NETWORK = "Networks",
}

export enum UserAreaSections {
  SUPPORT = "Support",
  SWITCH_ACCOUNTS = "Switch Accounts",
  SIGN_OUT = "Sign Out",
  ADMIN = "Admin",
  APP_SETTINGS = "App Settings",
}

export enum AdminSections {
  USERS = "Users",
  ACCOUNTS = "Accounts",
}

// todo - Hyphenate "Web Search" instead of space for route readability
export enum TemplateSections {
  FORMULARY = "Formulary",
  CRITERIA = "Criteria",
  WEB_SEARCH = "Web Search",
}

export enum FlexSections {
  FORMULARIES = "Formularies",
  DRUG_SEARCH = "Drugs",
  DRUG_LISTS = "Lists",
}

export enum ListSections {
  TIER = "Tier",
  EDIT = "Edit",
  ALT = "Alternatives",
  PRICE = "Pricing",
}

export type AppSubSections = TemplateSections | ListSections | BenefitsSections;

export enum Security {
  LOGIN = "Login",
  // TODO: Need to revisit this section to add more types for SIGN_UP and FORGOT_PASSWORD
}

export enum BenefitsSections {
  PLANS = "Plans",
  PLAN_CONFIGS = "Plan Configs",
}

export enum AdminDomains {
  CLARIVATE = "clarivate.com",
}
