import * as React from "react";
import { makeStyles, Fab, CircularProgress } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/EditRounded";
import SaveIcon from "@material-ui/icons/SaveRounded";

const useStyles = makeStyles((theme) => ({
  buttonArea: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  isSaving: boolean;
  isDisabled: boolean;
  isNew: boolean;
  onClickSave: () => void;
};

export const SaveFabButton: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <section className={classes.buttonArea}>
      <Fab
        size="large"
        disabled={props.isSaving || props.isDisabled}
        color="primary"
        variant="extended"
        aria-label="create or update"
        onClick={props.onClickSave}
      >
        {props.isNew ? (
          <>
            {props.isSaving ? (
              <CircularProgress size="1.5rem" color="inherit" className={classes.extendedIcon} />
            ) : (
              <CreateIcon className={classes.extendedIcon} />
            )}
            Create
          </>
        ) : (
          <>
            {props.isSaving ? (
              <CircularProgress size="1.5rem" color="inherit" className={classes.extendedIcon} />
            ) : (
              <SaveIcon className={classes.extendedIcon} />
            )}
            Update
          </>
        )}
      </Fab>
    </section>
  );
};
