import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";

/**
 * Configures basic parameters regarding aws endpoints, authentication, and modification to request headers
 *
 * https://api.adaptiverxtest.com/graphql
 * https://vxiht7txzzcubatnuippr2bpxy.appsync-api.us-east-2.amazonaws.com/graphql
 */

enum EnvHostname {
  DEV = "dev.next.drgadaptive.com",
  STAGING = "staging.next.drgadaptive.com",
  PROD = "next.drgadaptive.com",
}

interface ConnectionKeys {
  GQL_URL: string;
  STORAGE: string;
  auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    authenticationFlowType: string;
    identityPoolId: string;
    identityPoolRegion: string;
  };
}

const ENV_CONFIGS: Record<EnvHostname, ConnectionKeys> = {
  [EnvHostname.PROD]: {
    GQL_URL: "https://7watjfob2zgddbt5dote5hnbtq.appsync-api.us-east-1.amazonaws.com/graphql",
    STORAGE: "prod-next-app-data-storage",
    auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_EF30PU0xg",
      userPoolWebClientId: "4gse2jneip4io1ag6vj821krh3",
      authenticationFlowType: "CUSTOM_AUTH",
      identityPoolId: "us-east-1:647c6955-bac2-4dd8-a2b1-1ef23d77b074",
      identityPoolRegion: "us-east-1",
    },
  },
  [EnvHostname.STAGING]: {
    GQL_URL: "https://kagbbryesjberiahhuwxba6obe.appsync-api.us-east-1.amazonaws.com/graphql",
    STORAGE: "staging-next-app-data-storage",
    auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_OePO798hn",
      userPoolWebClientId: "3r9u2miphj8isl0d68s6uqi9uq",
      authenticationFlowType: "CUSTOM_AUTH",
      identityPoolId: "us-east-1:a8ba4618-64cb-4157-94a9-1417064dcfdc",
      identityPoolRegion: "us-east-1",
    },
  },
  [EnvHostname.DEV]: {
    GQL_URL: "https://e75kqq54d5ev3jhiltnt63kgfq.appsync-api.us-east-1.amazonaws.com/graphql",
    STORAGE: "dev-next-app-data-storage",
    auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_ZUS9YBXhJ",
      userPoolWebClientId: "2qhp6d3haefr3e9p5pib8c416",
      authenticationFlowType: "CUSTOM_AUTH",
      identityPoolId: "us-east-1:ad1cf5b3-387c-479f-8dda-608b0e91a9b3",
      identityPoolRegion: "us-east-1",
    },
  },
};

const DEV_USER_CONFIGS: Record<string, ConnectionKeys> = {
  eboozer: {
    GQL_URL: "https://2pxle4koiza6xk6avl7je7x26e.appsync-api.us-east-1.amazonaws.com/graphql",
    STORAGE: "eboozer-next-app-data-storage",
    auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_kZOB9kroo",
      userPoolWebClientId: "kvqef12s0hkakarrobc7sukr9",
      authenticationFlowType: "CUSTOM_AUTH",
      identityPoolId: "us-east-1:733e9bad-23a5-4ad2-a015-b1ff98305a08",
      identityPoolRegion: "us-east-1",
    },
  },
  tclay: {
    GQL_URL: "https://f5kqz5mus5einphnyq7oitb7nm.appsync-api.us-east-1.amazonaws.com/graphql",
    STORAGE: "tclay-next-app-data-storage",
    auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_0SILsvOof",
      userPoolWebClientId: "5pnmr7fr08sv4f68lnlnebn3m5",
      authenticationFlowType: "CUSTOM_AUTH",
      identityPoolId: "us-east-1:9dd7443e-b2f5-41d7-8003-c7526425686a",
      identityPoolRegion: "us-east-1",
    },
  },
  bforce: {
    GQL_URL: "https://3p7n2tm6obfkzorntmzwyos23m.appsync-api.us-east-1.amazonaws.com/graphql",
    STORAGE: "bforce-next-app-data-storage",
    auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_Z2sKTUtG1",
      userPoolWebClientId: "50hg9n8jm3d5miibqo2sbee8kl",
      authenticationFlowType: "CUSTOM_AUTH",
      identityPoolId: "us-east-1:9430b446-f7bb-4ba8-948b-214193977960",
      identityPoolRegion: "us-east-1",
    },
  },
};

const ALL_CONFIGS: Record<EnvHostname | string, ConnectionKeys> = {
  ...DEV_USER_CONFIGS,
  ...ENV_CONFIGS,
};

function awsConfig(hostname: EnvHostname | string) {
  if (process.env.NODE_ENV === "production") {
    return (
      ALL_CONFIGS[hostname] ?? new Error("Unrecognized Hostname. Cannot provide app configuration")
    );
  } else if (process.env["REACT_APP_USER_STACK"]) {
    return (
      ALL_CONFIGS[process.env["REACT_APP_USER_STACK"]] ??
      new Error(`No AWS Config defined for ${process.env["REACT_APP_USER_STACK"]}`)
    );
  } else if (hostname === "localhost") {
    return ALL_CONFIGS[EnvHostname.DEV];
  } else {
    return new Error("Unrecognized Hostname. Cannot provide app configuration");
  }
}

async function authHeaders() {
  const idToken = (await Auth.currentSession()).getIdToken();
  return {
    Authorization: idToken.getJwtToken(),
  };
}

const AWS_CONFIG = awsConfig(window.location.hostname);
if (AWS_CONFIG instanceof Error) {
  console.error(AWS_CONFIG);
} else {
  Auth.configure(AWS_CONFIG.auth);
  Storage.configure({
    Storage: {
      AWSS3: {
        bucket: AWS_CONFIG.STORAGE,
        region: "us-east-1",
      },
    },
  });
  API.configure({
    aws_appsync_graphqlEndpoint: AWS_CONFIG.GQL_URL,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    graphql_headers: authHeaders,
  });
}

// IF not a config cannot be defined, do not expose a GQL URL
export const AWS_GQL_ENDPOINT: string = AWS_CONFIG instanceof Error ? "" : AWS_CONFIG.GQL_URL;
