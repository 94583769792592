import { makeStyles, TextField } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DESCRIPTION_COLUMN } from "../../../FieldNameConstant";
import { FullFormularyTemplate } from "../../types";
import { ConfigOptionsTable } from "../../../../components/tables/ConfigOptionsTable";
import { ColumnSettingsForm } from "./ColumnSettingsForm";
import { ColumnWidthInput } from "./ColumnWidthInput";

const useStyles = makeStyles((theme) => ({
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

type Props = {
  isActiveColumn: boolean;
  disabled: boolean;
};

export const DescriptionColumnForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { errors } = useFormContext<FullFormularyTemplate>();

  const hasMaxWidthError = Boolean(
    errors.settings?.descriptionColumnSettings?.width?.type === "max"
  );
  const hasMinWidthError = Boolean(
    errors.settings?.descriptionColumnSettings?.width?.type === "min"
  );
  const hasRequiredError = Boolean(
    errors.settings?.descriptionColumnSettings?.width?.type === "required"
  );

  return (
    <ColumnSettingsForm
      columnName="Description"
      columnHidden={false}
      activeColumn={props.isActiveColumn}
    >
      {/* Added for to register values with react-hook-form for state management. Not registered w/ standard input to persist number type */}
      <div style={{ display: "none" }}>
        <Controller
          as={
            <TextField
              style={{ width: "200px" }}
              inputProps={{ style: { textOverflow: "ellipsis" } }}
              InputProps={{ classes: { focused: classes.themedUnderline } }}
              disabled
            />
          }
          name={DESCRIPTION_COLUMN.POSITION}
        />
      </div>
      <ConfigOptionsTable
        settings={[
          {
            label: "Title",
            options: (
              <Controller
                as={
                  <TextField
                    style={{ width: "200px" }}
                    disabled={props.disabled}
                    inputProps={{ style: { textOverflow: "ellipsis" } }}
                    InputProps={{ classes: { focused: classes.themedUnderline } }}
                  />
                }
                name={DESCRIPTION_COLUMN.TITLE}
              />
            ),
          },
          {
            label: "Column width",
            options: (
              <ColumnWidthInput
                disabled={props.disabled}
                widthFieldName={DESCRIPTION_COLUMN.WIDTH}
                hasMaxWidthError={hasMaxWidthError}
                hasMinWidthError={hasMinWidthError}
                hasRequiredError={hasRequiredError}
              />
            ),
          },
        ]}
      />
    </ColumnSettingsForm>
  );
};
