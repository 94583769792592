import Auth from "@aws-amplify/auth";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import TemplateIcon from "@material-ui/icons/StyleRounded";
import NetworkIcon from "@material-ui/icons/WifiTetheringRounded";
import SupportIcon from "@material-ui/icons/ContactSupportRounded";
import SettingsIcon from "@material-ui/icons/SettingsRounded";
import AdminPanelIcon from "@material-ui/icons/SecurityRounded";
import UsersIcon from "@material-ui/icons/SupervisedUserCircleRounded";
import AccountTreeIcon from "@material-ui/icons/AccountTreeRounded";

import {
  DrugListIcon,
  DrugSearchIcon,
  FlexFormularyIcon,
  PlanIcon,
  TemplateCriteriaIcon,
  TemplateFormularyIcon,
  TemplateWebSearchIcon,
  LogOutIcon,
  SwitchAccountsIcon,
} from "../components/Icons/customIcons";
import {
  UserAreaSections,
  AppSections,
  BenefitsSections,
  ListSections,
  TemplateSections,
  AdminSections,
} from "../types/AppTypes";
import { useHistory } from "react-router-dom";

export type SidebarAppEntries = {
  [appName in AppSections]: SidebarAppSection;
};

export type SidebarUserAreaEntries = {
  [appName in UserAreaSections]: SidebarUserAreaSection;
};

export type SidebarAppSection = {
  icon: React.ComponentType<SvgIconProps>;
  route: string;
  subSections?: Array<SidebarAppSubSection>;
  controlFlagName?: string;
};

export type SidebarUserAreaSection = {
  icon: React.ComponentType<SvgIconProps>;
  route?: string;
  action?: (context: { showAccountSelection: () => void }) => void;
  externalRoute?: string;
  subSections?: Array<SidebarUserAreaSubSection>;
  controlFlagName?: string;
};

export type SidebarAppSubSection = {
  name: TemplateSections | ListSections | BenefitsSections;
  route: string;
  initials: string;
  icon?: React.ComponentType<SvgIconProps>;
  controlFlagName?: string;
};

export type SidebarUserAreaSubSection = {
  name: AdminSections;
  route: string;
  initials: string;
  icon?: React.ComponentType<SvgIconProps>;
  controlFlagName?: string;
};

const CUSTOMER_SUPPORT_LINK = "https://adaptiverx.atlassian.net/servicedesk/customer/portal/18";

export const UserAreaSectionTabs: SidebarUserAreaEntries = {
  [UserAreaSections.SUPPORT]: {
    icon: SupportIcon,
    externalRoute: CUSTOMER_SUPPORT_LINK,
  },
  [UserAreaSections.SIGN_OUT]: {
    icon: LogOutIcon,
    action: () => {
      Auth.signOut()
        .then(() => {
          useHistory().push("/");
        })
        .catch((e) => {
          console.error("Error signing out", e);
        });
    },
  },
  [UserAreaSections.SWITCH_ACCOUNTS]: {
    icon: SwitchAccountsIcon,
    action: ({ showAccountSelection }) => {
      showAccountSelection();
    },
  },
  [UserAreaSections.APP_SETTINGS]: {
    icon: SettingsIcon,
    route: "app-settings",
  },
  [UserAreaSections.ADMIN]: {
    icon: AdminPanelIcon,
    route: UserAreaSections.ADMIN,
    subSections: [
      {
        name: AdminSections.USERS,
        route: AdminSections.USERS,
        icon: UsersIcon,
        initials: "U",
      },
      {
        name: AdminSections.ACCOUNTS,
        route: AdminSections.ACCOUNTS,
        icon: AccountTreeIcon,
        initials: "A",
      },
    ],
  },
};

export const AppSectionTabs: SidebarAppEntries = {
  [AppSections.TEMPLATES]: {
    icon: TemplateIcon,
    route: AppSections.TEMPLATES,
    subSections: [
      {
        name: TemplateSections.FORMULARY,
        route: TemplateSections.FORMULARY,
        initials: "F",
        icon: TemplateFormularyIcon,
      },
      {
        name: TemplateSections.CRITERIA,
        route: TemplateSections.CRITERIA,
        initials: "C",
        icon: TemplateCriteriaIcon,
      },
      {
        name: TemplateSections.WEB_SEARCH,
        route: TemplateSections.WEB_SEARCH,
        initials: "WS",
        icon: TemplateWebSearchIcon,
      },
    ],
  },
  [AppSections.DRUG_SEARCH]: {
    icon: DrugSearchIcon,
    controlFlagName: "flexAppDrugSearch",
    route: AppSections.DRUG_SEARCH,
  },
  [AppSections.FORMULARIES]: {
    icon: FlexFormularyIcon,
    controlFlagName: "flexAppFormularies",
    route: AppSections.FORMULARIES,
  },
  [AppSections.DRUG_LISTS]: {
    icon: DrugListIcon,
    subSections: [
      { name: ListSections.TIER, route: ListSections.TIER, initials: "T" },
      { name: ListSections.EDIT, route: "UM", initials: "E" },
      { name: ListSections.ALT, route: ListSections.ALT, initials: "ALT" },
      {
        name: ListSections.PRICE,
        route: ListSections.PRICE,
        initials: "P",
        controlFlagName: "flexListPricing",
      },
    ],
    controlFlagName: "flexAppDrugLists",
    route: AppSections.DRUG_LISTS,
  },
  [AppSections.BENEFITS]: {
    icon: PlanIcon,
    controlFlagName: "flexAppPlans",
    route: AppSections.BENEFITS,
    subSections: [
      { name: BenefitsSections.PLANS, route: BenefitsSections.PLANS, initials: "P" },
      { name: BenefitsSections.PLAN_CONFIGS, route: "plan-configs", initials: "PC" },
    ],
  },
  [AppSections.NETWORK]: {
    icon: NetworkIcon,
    controlFlagName: "unknown",
    route: AppSections.NETWORK,
  },
};
