import {
  Collapse,
  Divider,
  fade,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Menu,
  MenuItem,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import OptionsIcon from "@material-ui/icons/MoreVertRounded";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/SearchRounded";
import classNames from "classnames";
import produce from "immer";
import * as React from "react";
import { Item, MenuOption } from "./Types";
import { CenteredCircularLoading } from "../loading/CenteredCircularLoading";
import { UserContext } from "../UserContext";

const useStyles = makeStyles((theme: Theme) => ({
  groupListItem: {
    color: fade(theme.palette.text.primary, 0.75),
  },
  listItem: {
    paddingLeft: theme.spacing(4),
  },
  deleteButton: {
    opacity: 0,
  },
  searchListItem: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  searchBar: {
    padding: "0 20px",
    width: "80%",
    backgroundColor: "#ECECEC",
  },
  noMatch: {
    textAlign: "center",
    backgroundColor: "white",
    color: fade(theme.palette.text.primary, 0.5),
  },
  noData: {
    textAlign: "center",
    color: fade(theme.palette.text.primary, 0.5),
    backgroundColor: fade(theme.palette.common.black, 0.1),
    fontStyle: "italic",
  },
  listItemText: {
    color: "inherit",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectedText: {
    color: "white",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));

const groupItems = <T extends Item>(itemList: Array<T>): GroupedList<T> => {
  return itemList.reduce((groupedList, currentItem) => {
    if (currentItem.groupName && groupedList[currentItem.groupName]) {
      groupedList[currentItem.groupName].group.push(currentItem);
      groupedList[currentItem.groupName].isExpanded = true;
    } else {
      if (currentItem.groupName) {
        groupedList[currentItem.groupName] = {
          group: [currentItem],
          isExpanded: true,
        };
      }
    }
    return groupedList;
  }, {} as GroupedList<T>);
};

type GroupedList<T extends Item> = {
  [groupName: string]: {
    group: Array<T>;
    isExpanded: boolean;
  };
};

interface Props<T extends Item> {
  listData: Array<T>;
  dataLoading?: boolean;
  onItemSelection: (itemId: string) => void;
  listAction?: {
    buttonText: string;
    action: () => void;
  };
  menuOptions?: Array<MenuOption>;
}

export function CategorySearchList<T extends Item>(props: Props<T>): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [items, setItems] = React.useState<GroupedList<T>>({});
  const [selectedItem, setSelectedItem] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedMenuId, setSelectedMenuId] = React.useState("");

  React.useEffect(() => {
    const lengthOfItems = Object.values(items).reduce(
      (totalLength, itemGroup) => totalLength + itemGroup.group.length,
      0
    );
    if (lengthOfItems !== props.listData.length) {
      setItems(groupItems(props.listData));
    }
  }, [props.listData, items]);

  const itemClick = (id: string) => (_: React.MouseEvent) => {
    setSelectedItem(id);
    props.onItemSelection(id);
  };

  const handleClickOptions = (itemId: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenuId(itemId);
  };

  const toggleGroupDisplay = (groupName: string) => (_: React.MouseEvent<{}>) => {
    if (items) {
      setItems(
        produce(items, (draft) => {
          if (draft[groupName]) {
            draft[groupName].isExpanded = !draft[groupName].isExpanded;
          }
        })
      );
    }
  };

  const searchList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const buildList = (filteredData: GroupedList<T>) => {
    return Object.entries(filteredData).map((entry) => {
      const [groupName, groupData] = entry;
      const { group, isExpanded } = groupData;
      return (
        <React.Fragment key={groupName}>
          <ListItem
            button
            className={classes.groupListItem}
            onClick={toggleGroupDisplay(groupName)}
          >
            <ListItemText
              primary={groupName}
              primaryTypographyProps={{
                variant: "body2",
                color: "inherit",
              }}
            />
            {isExpanded ? <ExpandMore /> : <ExpandLess />}
          </ListItem>
          {
            <Collapse in={isExpanded} unmountOnExit>
              <List
                dense={contentSpacing === "dense"}
                style={{ backgroundColor: "white" }}
                disablePadding
              >
                {group.map((item) => {
                  return (
                    <ListItem
                      button
                      selected={selectedItem !== "" && item.id === selectedItem}
                      className={classes.listItem}
                      key={item.id}
                      onClick={itemClick(item.id)}
                      classes={{ selected: classes.selectedListItem }}
                    >
                      <ListItemText
                        primary={item.name}
                        primaryTypographyProps={{
                          noWrap: true,
                        }}
                        className={item.id === selectedItem ? classes.selectedText : ""}
                        classes={{
                          primary: item.id === selectedItem ? classes.selectedText : "",
                        }}
                      />
                      {props.menuOptions && (
                        <ListItemSecondaryAction>
                          <IconButton
                            size="small"
                            className={item.id === selectedItem ? classes.selectedText : ""}
                            onClick={handleClickOptions(item.id)}
                          >
                            <OptionsIcon color="inherit" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          }
        </React.Fragment>
      );
    });
  };

  const getFilteredList = () => {
    if (searchTerm === "") {
      return items;
    } else {
      const searchText = searchTerm.toLowerCase();
      const filteredItems = produce(items, (draft) => {
        for (let entry in draft) {
          const adjustedGroupName = draft[entry].group.filter((item) => {
            return (
              item.name.toLowerCase().includes(searchText) ||
              (item.groupName && item.groupName.toLowerCase().includes(searchText))
            );
          });
          if (adjustedGroupName.length === 0) {
            delete draft[entry];
          } else {
            draft[entry].group = adjustedGroupName;
          }
        }
      });
      return filteredItems;
    }
  };

  const buildCollapsibleList = (filteredList: GroupedList<T>) => {
    if (filteredList && Object.entries(filteredList).length !== 0) {
      return buildList(filteredList);
    } else if (searchTerm !== "") {
      return (
        <ListItem className={classes.noMatch} key="no entries">
          <ListItemText
            primary="No matches found for"
            secondary={searchTerm}
            primaryTypographyProps={{ color: "inherit" }}
          />
        </ListItem>
      );
    } else {
      return (
        <ListItem className={classNames(classes.noMatch, classes.noData)} key="no data">
          <ListItemText primary="No Data" primaryTypographyProps={{ color: "inherit" }} />
        </ListItem>
      );
    }
  };

  const addButton = () => {
    return (
      <>
        {props.listAction && (
          <ListItem button onClick={props.listAction.action}>
            <ListItemIcon style={{ minWidth: "40px" }}>
              <AddIcon htmlColor={theme.palette.text.primary} />
            </ListItemIcon>
            <ListItemText>
              <Typography
                style={{ fontStyle: "italic", textTransform: "capitalize" }}
                variant="body2"
              >
                {props.listAction.buttonText}
              </Typography>
            </ListItemText>
          </ListItem>
        )}
        <Divider />
      </>
    );
  };

  const searchBar = () => {
    return (
      <>
        <ListItem className={classes.searchListItem} key="search">
          <Paper elevation={0} className={classes.searchBar}>
            <InputBase
              placeholder="Search"
              fullWidth
              inputProps={{ "aria-label": "search term" }}
              onChange={searchList}
              endAdornment={
                <IconButton>
                  <SearchIcon />
                </IconButton>
              }
            />
          </Paper>
        </ListItem>
        <Divider />
      </>
    );
  };

  return (
    <>
      <List style={{ backgroundColor: "white" }} disablePadding>
        {searchBar()}
        {addButton()}
        {!props.dataLoading && buildCollapsibleList(getFilteredList())}
        {props.menuOptions && (
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {props.menuOptions.map((option) => (
              <MenuItem
                key={option.name}
                onClick={(_) => {
                  option.action(selectedMenuId);
                  setAnchorEl(null);
                }}
              >
                {option.icon && <ListItemIcon style={{ minWidth: 40 }}>{option.icon}</ListItemIcon>}
                <Typography variant="body2">{option.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </List>
      {props.dataLoading && (
        <div style={{ background: "white", height: "100%" }}>
          <CenteredCircularLoading />
        </div>
      )}
    </>
  );
}
