import { useParams } from "react-router-dom";

type DrugListRouteParams = {
  drugListId?: string;
};

export enum ListTypeRoute {
  TIER = "/lists/tier",
  EDIT = "/lists/um",
  ALTERNATIVE = "/lists/alternatives",
  PRICE = "/lists/pricing",
}

export const useDrugListRouteParams = () => useParams<DrugListRouteParams>();

export const alternativesListRoute = (id: string) => `${ListTypeRoute.ALTERNATIVE}/edit/${id}`;

export const tiersListRoute = (id: string) => `${ListTypeRoute.TIER}/edit/${id}`;
