import {
  Button,
  Divider,
  Drawer,
  Fade,
  IconButton,
  List,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import MenuShrinkIcon from "@material-ui/icons/MenuOpenRounded";
import MenuExpandIcon from "@material-ui/icons/MenuRounded";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../components/UserContext";
import logo from "../../styles/Graphics/drgLogoWhite.svg";
import { AppSections } from "../../types/AppTypes";
import { User } from "../../utils/User";
import { AppSectionTabs } from "../Types";
import { AccountArea } from "./AccountArea";
import dashboardBackground from "./graphics/dashboardBG-compressed.webp";
import flexBackground from "./graphics/flexBG-compressed.webp";
import networkBackground from "./graphics/networkBG-compressed.webp";
import plansBackground from "./graphics/plansBG-compressed.webp";
import templateBackground from "./graphics/templateBG-compressed.webp";
import { SectionNavGroup } from "./SectionNavGroup";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    color: "white",
    overflow: "hidden",
  },
  openDrawer: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  closedDrawer: {
    width: theme.spacing() * 8 + 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  openDrawerIconButtonOverride: {
    position: "absolute",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "rgb(255, 255, 255, 0.2)",
    },
  },
  closeDrawerIconButtonOverride: {
    position: "absolute",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "rgb(255, 255, 255, 0.0)",
    },
  },
  drawerContent: {
    backgroundColor: `rgb(22, 24, 26, 0.8)`,
    position: "relative",
    width: "100%",
    height: "100%",
    "&:before": {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
      content: `""`,
      backgroundSize: "cover",
      backgroundImage: `url(${dashboardBackground})`,
      backgroundPosition: "top",
      transition: theme.transitions.create("background-image", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),
    },
  },
  drawerTemplate: {
    "&:before": {
      backgroundImage: `url(${templateBackground})`,
    },
  },
  drawerFlex: {
    "&:before": {
      backgroundImage: `url(${flexBackground})`,
    },
  },
  drawerPlans: {
    "&:before": {
      backgroundImage: `url(${plansBackground})`,
    },
  },
  drawerNetwork: {
    "&:before": {
      backgroundImage: `url(${networkBackground})`,
    },
  },
  drawerToolbar: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    marginBottom: theme.spacing(),
    ...theme.mixins.toolbar,
  },
  drawerToolbarClosed: {
    justifyContent: "center",
  },
  drawerToolbarOpen: {
    justifyContent: "flex-end",
  },
  paperAnchorDockedLeft: {
    border: 0,
  },
  listRoot: {
    maxWidth: "220px",
    margin: "0 8px 0 8px",
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  logo: {
    width: "6rem",
  },
  logoWrapper: {
    margin: "0 auto",
  },
  logoButton: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.0)",
    },
  },
  sidebarDivider: {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
  },
  medicareMode: {
    padding: theme.spacing(),
  },
  sidebarTopContent: {
    margin: `${theme.spacing()}px 0 ${theme.spacing()}px 0`,
  },
  navWrapper: {
    marginBottom: theme.spacing(),
    overflow: "auto",
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    "scrollbar-width": "none", // Hide Scrollbar - Firefox
    "&::-webkit-scrollbar": {
      // Hide Scrollbar - Chrome & Safari
      display: "none",
    },
  },
}));

type Props = {
  currentSubAccount: User;
  sidebarSize: string;
};

export const Sidebar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const topRef = React.useRef<HTMLDivElement>(null);
  const {
    user: { isManagingMedicare },
  } = React.useContext(UserContext);
  const [sidebarSize, setSidebarSize] = React.useState<string>(props.sidebarSize);
  const [topContentHeight, setTopContentHeight] = React.useState(0);
  const isSidebarExpanded = sidebarSize === "full";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    setTopContentHeight(topRef.current?.clientHeight || 0);
  });

  const handleToggleSidebar = () => {
    if (isSidebarExpanded) {
      localStorage.setItem("sidebar", "mini");
      setSidebarSize("mini");
    } else {
      localStorage.setItem("sidebar", "full");
      setSidebarSize("full");
    }
    // Trigger a custom resize event after opening/closing the sidebar to resize various ui elements
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent("resize"));
    }, 50);
  };

  const handleAccountAreaExpansion = (newHeight: number) => {
    if (topRef.current) {
      setTopContentHeight(topRef.current.clientHeight + newHeight);
    } else {
      setTopContentHeight(0 + newHeight);
    }
  };

  const drawerClasses = `${classes.drawer} ${
    isSidebarExpanded ? classes.openDrawer : classes.closedDrawer
  }`;

  const getDrawerThemeClass = () => {
    if (location.pathname.includes("/templates")) {
      return classes.drawerTemplate;
    } else if (
      location.pathname.includes("/formularies") ||
      location.pathname.includes("/drugs") ||
      location.pathname.includes("/list")
    ) {
      return classes.drawerFlex;
    } else if (location.pathname.includes("/benefits")) {
      return classes.drawerPlans;
    } else if (location.pathname.includes("/networks")) {
      return classes.drawerNetwork;
    } else {
      return "";
    }
  };

  const fadeDelay = isSidebarExpanded ? "200ms" : "0ms";

  return (
    <Drawer
      className={drawerClasses}
      classes={{
        paper: drawerClasses,
        paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
      }}
      open={isSidebarExpanded}
      variant="permanent"
    >
      <div className={`${classes.drawerContent} ${getDrawerThemeClass()}`}>
        <div id="sidebarTopContent" className={classes.sidebarTopContent} ref={topRef}>
          <div
            className={`${classes.drawerToolbar} ${
              isSidebarExpanded ? classes.drawerToolbarOpen : classes.drawerToolbarClosed
            }`}
          >
            <Fade in={isSidebarExpanded} style={{ transitionDelay: fadeDelay }}>
              <div className={classes.logoWrapper}>
                <Button
                  disableFocusRipple
                  classes={{ root: classes.logoButton }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <img className={classes.logo} src={logo} alt="home button" />
                </Button>
              </div>
            </Fade>
            <Fade in={isSidebarExpanded} style={{ transitionDelay: fadeDelay }}>
              <IconButton
                disableRipple
                color="inherit"
                classes={{ root: classes.closeDrawerIconButtonOverride }}
                onClick={handleToggleSidebar}
              >
                <MenuShrinkIcon />
              </IconButton>
            </Fade>
            <Fade in={!isSidebarExpanded} style={{ transitionDelay: "200ms" }}>
              <IconButton
                disableRipple
                color="inherit"
                classes={{ root: classes.openDrawerIconButtonOverride }}
                onClick={handleToggleSidebar}
              >
                <MenuExpandIcon />
              </IconButton>
            </Fade>
          </div>
          {isManagingMedicare && isSidebarExpanded && (
            <>
              <Divider className={classes.sidebarDivider} variant="middle" />
              <Typography
                variant="subtitle1"
                color="inherit"
                align="center"
                className={classes.medicareMode}
              >
                Medicare Mode
              </Typography>
            </>
          )}
          <Divider className={classes.sidebarDivider} variant="middle" />
          <AccountArea
            onTransition={handleAccountAreaExpansion}
            isSidebarOpen={isSidebarExpanded}
            currentSubAccount={props.currentSubAccount}
          />
          <Divider className={classes.sidebarDivider} variant="middle" />
        </div>

        <nav
          id="navWrapper"
          className={classes.navWrapper}
          style={{ height: `calc(100% - ${topContentHeight}px - ${theme.spacing(2)}px)` }}
        >
          <List disablePadding className={classes.listRoot}>
            {Object.entries(AppSectionTabs).map(([sectionName, section], index) => (
              <SectionNavGroup
                key={index}
                section={section}
                sectionName={sectionName as AppSections}
                isLast={Object.entries(AppSectionTabs).length - 1 === index}
                isSidebarOpen={isSidebarExpanded}
              />
            ))}
          </List>
        </nav>
      </div>
    </Drawer>
  );
};
