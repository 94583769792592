import { Button, IconButton, Input, InputAdornment, makeStyles } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/CancelRounded";
import * as React from "react";
import { UploadFormularyIcon } from "../Icons/customIcons";

const useStyles = makeStyles({
  centeredContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

type Props = {
  handleFile: (file: File) => void;
  buttonIcon?: JSX.Element;
  existingFile?: {
    fileName: string;
    onRemoveFile: (fileName: string) => void;
  };
};

export const FilePicker: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState(
    props.existingFile ? props.existingFile.fileName : ""
  );

  const inputElement = React.useRef<HTMLInputElement>(null);
  const id = `file-picker-${Math.random()}`;

  const clearFileInput = () => {
    if (props.existingFile) {
      props.existingFile.onRemoveFile(fileName);
      setFileName("");
    }
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      setFileName(file.name);
      props.handleFile(file);
    }
  };

  return (
    <div className={classes.centeredContainer}>
      <input
        id={id}
        ref={inputElement}
        type="file"
        style={{ display: "none" }}
        onChange={handleFile}
      />
      {fileName ? (
        <Input
          type="text"
          value={fileName}
          inputProps={{
            style: { textOverflow: "ellipsis" },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={clearFileInput}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        <label htmlFor={id}>
          <Button
            variant="outlined"
            component="span"
            color="primary"
            startIcon={props.buttonIcon ? props.buttonIcon : <UploadFormularyIcon />}
          >
            Choose
          </Button>
        </label>
      )}
    </div>
  );
};
