import * as React from "react";
import { useHistory } from "react-router-dom";
import { Typography, makeStyles, Button } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/HomeRounded";
import LostSpaceman from "../../styles/Graphics/lostSpaceman.webp";
import stars from "../../styles/Graphics/stars.webp";
import { FLEX_PRIMARY_COLOR } from "../../styles/Theme";

const useStyles = makeStyles({
  notFoundWrapper: {
    height: "100vh",
    width: "100vw",
    zIndex: 1200,
    background: `#000 url(${stars}) repeat top center`,
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  notFoundText: {
    display: "flex",
    flexDirection: "column",
    color: "white",
  },
  fadedBackgroundText: {
    position: "absolute",
    right: 10,
    bottom: 0,
    opacity: 0.2,
    fontSize: "5rem",
    fontWeight: 100,
  },
  enlargedH1Text: {
    fontWeight: 200,
    fontSize: "9rem",
  },
  backButton: {
    marginTop: 20,
    width: "fit-content",
    backgroundColor: FLEX_PRIMARY_COLOR,
  },
});

export const NotFound: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={classes.notFoundWrapper}>
        <img src={LostSpaceman} alt="lost spaceman" style={{ width: "30%", marginRight: 30 }} />
        <div className={classes.notFoundText}>
          <Typography className={classes.fadedBackgroundText} color="inherit">
            404
          </Typography>
          <Typography className={classes.enlargedH1Text} color="inherit" variant="h1">
            Lost?
          </Typography>
          <Typography color="inherit" variant="h5">
            There's nothing here but empty space.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.backButton}
            startIcon={<HomeIcon />}
            onClick={() => {
              history.push("/");
            }}
          >
            Home
          </Button>
        </div>
      </div>
    </>
  );
};
