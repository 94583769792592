import gql from "graphql-tag";
import { WebSearchTemplate } from "./types";

export const LIST_WEB_SEARCH_TEMPLATES = gql`
  query webSearchTemplates {
    templates(templateType: WEB_SEARCH) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        settings {
          ... on WebSearchTemplateSettings {
            bannerPath
            landingPageInfoText
            logoPath
            primaryColor
          }
        }
      }
    }
  }
`;
export type ListWebSearchTemplatesResponse = {
  templates: {
    items: Array<WebSearchTemplate>;
  };
};

export const GET_WEB_SEARCH_TEMPLATE = gql`
  query GetWebSearchTemplate($templateId: ID!) {
    template(templateId: $templateId) {
      id
      name
      settings {
        ... on WebSearchTemplateSettings {
          bannerPath
          landingPageInfoText
          logoPath
          primaryColor
        }
      }
    }
  }
`;
export type GetWebSearchTemplateInput = {
  templateId: string;
};
export type GetWebSearchTemplateResponse = {
  template: WebSearchTemplate;
};

export const CREATE_WEB_SEARCH_TEMPLATE = gql`
  mutation saveWebSearchTemplate($name: String, $settings: InputWebSearchTemplate!) {
    saveWebSearchTemplate(name: $name, settings: $settings) {
      id
      name
      settings {
        ... on WebSearchTemplateSettings {
          bannerPath
          landingPageInfoText
          logoPath
          primaryColor
        }
      }
    }
  }
`;
export type CreateWebSearchTemplateInput = {
  name: string;
  settings: {
    bannerPath: string | null;
    landingPageInfoText: string | null;
    logoPath: string | null;
    primaryColor: string;
  };
};
export type SaveWebSearchTemplateResponse = {};

export const UPDATE_WEB_SEARCH_TEMPLATE = gql`
  mutation saveWebSearchTemplate(
    $name: String
    $settings: InputWebSearchTemplate!
    $templateId: ID
  ) {
    saveWebSearchTemplate(name: $name, settings: $settings, templateId: $templateId) {
      id
      name
      settings {
        ... on WebSearchTemplateSettings {
          bannerPath
          landingPageInfoText
          logoPath
          primaryColor
        }
      }
    }
  }
`;
export type UpdateWebSearchTemplateInput = CreateWebSearchTemplateInput & {
  templateId: string;
};
