import { ApolloError, useQuery } from "@apollo/client";
import {
  Button,
  Collapse,
  Divider,
  fade,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { NamedPageHeader } from "../../components/NamedPageHeader";
import { GetPlanConfigResponse, GET_PLAN_CONFIG } from "../api";
import { useBenefitRouteParams } from "../BenefitsRouteUtils";
import { APIPlanConfig, PlanConfigPageMode } from "../types";
import { PlanConfigCostShare } from "./PlanConfigCostShare";
import { PlanConfigSetup } from "./PlanConfigSetup";

const useStyles = makeStyles((theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButtons: {
    color: theme.palette.error.main,
  },
}));

enum PlanConfigTabs {
  SETUP = "Setup",
  COST_SHARE = "Cost Share",
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: PlanConfigTabs;
}

interface Props {
  mode: PlanConfigPageMode;
}

const DEFAULT_NEW_PLAN_CONFIG: APIPlanConfig = {
  id: "",
  name: "",
  formularyId: null,
  networkId: null,
};

export function PlanConfigPage(props: Props): JSX.Element {
  const { planConfigId } = useBenefitRouteParams();
  const classes = useStyles();
  const history = useHistory();

  const isNewPlanConfig = props.mode === PlanConfigPageMode.NEW;
  const isReadOnly = props.mode === PlanConfigPageMode.VIEW;

  const [activeTab, setActiveTab] = React.useState<PlanConfigTabs>(PlanConfigTabs.SETUP);
  const [planConfig, setPlanConfig] = React.useState<APIPlanConfig>(DEFAULT_NEW_PLAN_CONFIG);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<ApolloError | undefined>(undefined);

  //#region Apollo Queries
  const { loading } = useQuery<GetPlanConfigResponse, { id: string }>(GET_PLAN_CONFIG, {
    skip: isNewPlanConfig,
    variables: {
      id: planConfigId as string,
    },
    onCompleted: (res) => {
      if (res) {
        setPlanConfig(res.planConfig);
      }
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });
  //#endregion

  function handleTabChange(_: React.ChangeEvent<{}>, newTab: any) {
    setActiveTab(newTab);
  }

  function handleUpdatePlanConfig(newConfig: APIPlanConfig) {
    if (props.mode === PlanConfigPageMode.NEW) {
      history.push(`/benefits/plan-configs/edit/${newConfig.id}`);
    } else {
      setPlanConfig(newConfig);
    }
  }

  function getPlanConfigName() {
    if (props.mode !== PlanConfigPageMode.NEW) {
      return planConfig.name;
    } else {
      return "New Plan Configuration";
    }
  }

  function TabPanel({ children, value, ...other }: TabPanelProps) {
    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  }

  return (
    <>
      <div className={classes.fullWidthPageArea}>
        {loading ? (
          <CenteredCircularLoading />
        ) : (
          <>
            <NamedPageHeader name={getPlanConfigName()} backLink={`/benefits/plan-configs`} />
            <Divider variant="inset" />
            <Tabs
              centered
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="formulary navigation tabs"
            >
              <Tab
                label={
                  <Typography color="inherit">
                    {isReadOnly ? "Info" : PlanConfigTabs.SETUP}
                  </Typography>
                }
                value={PlanConfigTabs.SETUP}
                className={classes.tabText}
              />
              <Tab
                label={<Typography color="inherit">{PlanConfigTabs.COST_SHARE}</Typography>}
                disabled={isNewPlanConfig}
                value={PlanConfigTabs.COST_SHARE}
                className={classes.tabText}
              />
            </Tabs>
            <Divider />
            <TabPanel value={PlanConfigTabs.SETUP}>
              <PlanConfigSetup
                planConfig={planConfig}
                mode={props.mode}
                onUpdatePlanConfig={handleUpdatePlanConfig}
              />
            </TabPanel>
            <TabPanel value={PlanConfigTabs.COST_SHARE}>
              {activeTab === PlanConfigTabs.COST_SHARE &&
                planConfig.formularyId &&
                planConfig.networkId &&
                planConfig.id && (
                  <PlanConfigCostShare
                    formularyId={planConfig.formularyId}
                    networkId={planConfig.networkId}
                    planConfigId={planConfig.id}
                    readonly={isReadOnly}
                  />
                )}
            </TabPanel>
          </>
        )}
      </div>
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error on this page. Please wait a moment and refresh or try again. If the
          problem persists, contact your administrator.
        </Typography>
        <Button
          className={classes.errorButtons}
          style={{ width: "fit-content" }}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Divider />
          <Table className={classes.technicalDetailsTable}>
            <TableHead>
              <TableRow key="errorHead">
                <TableCell>
                  <Typography variant="body2" color="error">
                    {pageErrors?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageErrors?.graphQLErrors.map((error, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" color="error">
                      {error.message}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </>
  );
}
