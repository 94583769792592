import { WebSearchTemplate } from "./types";

export const WebSearchTemplatesManagementPage = "/templates/web search";

export const DEFAULT_NEW_WEB_SEARCH_TEMPLATE: WebSearchTemplate = {
  id: "",
  name: "New Web Search Template",
  settings: {
    primaryColor: "#000000",
    logoPath: null,
    bannerPath: null,
    landingPageInfoText: "",
  },
};
