import gql from "graphql-tag";
import { executeQuery, toAwsDateStr } from "../../../../utils/GraphQL";
import { ApiListRule, DRUG_ATTRIBUTES, DrugAttribute, RuleCondition } from "../../api";

//#region Types
export type FoundDrugs = Record<keyof typeof DRUG_ATTRIBUTES, string>;

export type CreateListRuleResponse = {
  createListRule: ApiListRule;
};

export type CreateListRuleInput = {
  input: {
    conditions: Array<RuleCondition>;
    effectiveDate: string;
    terminationDate?: string;
    price?: number;
  };
  listId: string;
};

type NonPersistedListRule = Omit<ApiListRule, "ruleId" | "createdAt" | "terminationDate">;
//#endregion

//#region GraphQL Queries
export const CREATE_RULE = gql`
  mutation CreateListRule($input: CreateListRuleInput!, $listId: ID!) {
    createListRule(input: $input, listId: $listId) {
      id
      effectiveDate
      createdAt
      terminationDate
      price
      conditions {
        drugAttribute
        matchValue
      }
    }
  }
`;
//#endregion

//#region Rule Specific Methods

export interface AttributeSuggestInput {
    attribute: DrugAttribute;
    search: string;
    limit?: number;
}

export interface AttributeSuggestResults {
  attributeSuggestions: {
    attribute: DrugAttribute;
    results: Array<string>;
  }
}

export const attributeSuggestions = (input: AttributeSuggestInput): Promise<Array<string>> => {
  return executeQuery<AttributeSuggestResults>(
    `query($input: AttributeSuggestInput!) {
      attributeSuggestions(input: $input) {
        attribute
        results
      }
    }`,
    {
      input
    }
  ).then((response) => {
    if (response.data) {
      return response.data.attributeSuggestions.results;
    }
    console.warn(
      `Unable to retrieve any results for < ${input.attribute} > with search < ${input.search} >. Returning an empty list.`
    );
    return [];
  });
};

export interface AttributeMatchInput {
  attribute: DrugAttribute;
  search: string;
}

export interface MultiAttributeMatchInput {
  attributeMatchers: Array<AttributeMatchInput>;
  limit?: number;
}

export interface MultiAttributeMatchResult {
  multiAttributeMatches: Array<FoundDrugs>;
}

export const multiAttributeMatches = (
  input: MultiAttributeMatchInput
): Promise<Array<FoundDrugs>> => {
  return executeQuery<MultiAttributeMatchResult>(
    `query($input: MultiAttributeMatchInput!) {
      multiAttributeMatches(input: $input) {
        brandName
        drugName
        drugDescriptorId
        gcn
        gcnSequenceNumber
        genericName
        genericDescription
        gni
        gpi2
        gpi4
        gpi6
        gpi8
        gpi10
        gpi12
        gpi14
        hic3
        hic3Description
        hiclSequenceNumber
        isBrand
        isOtc
        isRepackager
        labelName
        lastUpdated
        medId
        mony
        ndc
        ndc5
        ndc9
        rxcui
        onMarketDate
        offMarketDate
      }
    }`,
    {
      input
    }
  ).then((response) => {
    if (response.data) {
      return response.data.multiAttributeMatches;
    }
    console.warn(
      `Unable to retrieve any results for < ${JSON.stringify(input.attributeMatchers, null, 2)} >`
    );
    return [];
  });
};

export const terminateRule = (
  drugListId: string,
  ruleId: string,
  termDate: Date
): Promise<boolean> => {
  console.debug("calling terminate rule");
  return executeQuery<{ terminateDrugListRule: boolean | undefined }>(
    `
      mutation($input: TermDrugListRuleInput) {
        terminateDrugListRule(input: $input)
      }
    `,
    {
      input: {
        drugListId,
        ruleId,
        terminationDate: toAwsDateStr(termDate),
      },
    }
  )
    .then((response) => {
      if (response.data && response.data.terminateDrugListRule === true) {
        return true;
      } else if (response.errors) {
        console.error(response.errors);
        return false;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

export const getDrugsForConditions = (
  conditions: NonPersistedListRule["conditions"]
): Promise<Array<FoundDrugs>> => {
  if (conditions.length < 1) {
    return Promise.resolve([]);
  }
  if (
    conditions.map((cond) => cond.matchValue).filter((mv) => mv !== undefined && mv.trim() !== "")
      .length < 1
  ) {
    return Promise.resolve([]);
  }

  const input: MultiAttributeMatchInput = {
    attributeMatchers: conditions.map((condition) => ({
      attribute: condition.drugAttribute,
      search: condition.matchValue,
    })),
    limit: 100,
  };

  return multiAttributeMatches(input);
};
//#endregion
