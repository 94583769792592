import gql from "graphql-tag";
import {
  ApiKey,
  ApiKeyPlan,
  NextAccount,
  NextClaimSystem,
  NextDrugSource,
  NextSubAccount,
  NextUser,
  NextUserListItem,
  UserEntitlements,
  UserPermissions,
} from "./types";

export const LIST_USERS = gql`
  query ListUsers {
    users {
      items {
        id
        email
        name
        title
        enabled
        createdAt
        updatedAt
        userAgreementAgreedToAt
        userAgreementVersionAgreedTo
      }
    }
  }
`;

export type ListUsersResponse = {
  users: {
    items: Array<NextUserListItem>;
  };
};

export const LIST_ACCOUNTS = gql`
  query ListAccounts {
    accounts {
      items {
        id
        name
        legacyCustomerCode
        launchDate
        createdAt
        updatedAt
        subAccounts {
          items {
            id
            name
            legacyClientCode
            isMedicareEnabled
          }
        }
      }
    }
  }
`;

export type ListAccountsResponse = {
  accounts: {
    items: Array<NextAccount>;
  };
};

export const LIST_USER_ACCOUNTS_FILTERED = gql`
  query ListAccounts($userId: ID!) {
    accounts(filter: { userId: $userId }) {
      items {
        id
        name
        subAccounts(filter: { userId: $userId }) {
          items {
            id
            name
            isMedicareEnabled
          }
        }
      }
    }
  }
`;

export const LIST_USER_ACCOUNTS = gql`
  query ListAccounts {
    accounts {
      items {
        id
        name
        subAccounts {
          items {
            id
            name
            isMedicareEnabled
          }
        }
      }
    }
  }
`;

export type ListUserAccountsResponse = {
  accounts: {
    items: Array<NextAccount>;
  };
};

export type ListUserAccountsInput = {
  userId?: string;
};

export const GET_ACCOUNT = gql`
  query GetAccount($accountId: ID!) {
    account(accountId: $accountId) {
      id
      name
      shortName
      legacyCustomerCode
      launchDate
      createdAt
      updatedAt
      subAccounts {
        items {
          id
          name
        }
      }
    }
  }
`;

export type GetAccountResponse = {
  account: NextAccount;
};

export type GetAccountInput = {
  accountId: string;
};

export const GET_ACCOUNT_SUBACCOUNTS = gql`
  query GetAccountSubAccounts($accountId: ID!) {
    account(accountId: $accountId) {
      subAccounts {
        items {
          id
          name
          isParent
          legacyClientCode
          isMedicareEnabled
          apiKeys {
            id
            name
            key
          }
          drugSource {
            id
            shortName
            drugSource
          }
          claimSystem {
            id
            sourceName
          }
        }
      }
    }
  }
`;

export type GetAccountSubAccountsResponse = {
  account: {
    subAccounts: {
      items: Array<NextSubAccount>;
    };
  };
};

export type GetAccountSubAccountsInput = {
  accountId: string;
};

export const GET_USER = gql`
  query GetUser($userId: ID!, $entitlements: [UserEntitlementType!]) {
    user(userId: $userId) {
      id
      email
      name
      title
      legacyId
      enabled
      sub
      userAgreementAgreedToAt
      userAgreementVersionAgreedTo
      createdAt
      updatedAt
      subAccounts(filter: { entitlements: $entitlements }) {
        items {
          id
          name
          isMedicareEnabled
          drugSource {
            shortName
          }
        }
      }
      permissions {
        subAccountId
        entitlements
      }
      managedByAccount {
        id
        name
      }
    }
  }
`;

export type GetUserResponse = {
  user: NextUser;
};

export type GetUserInput = {
  userId: string;
  entitlements?: Array<string>;
};

export const CREATE_USER = gql`
  mutation CreateUser($user: NewUserInput!) {
    user: createUser(input: $user) {
      id
      email
      name
      title
      legacyId
      enabled
      sub
      userAgreementAgreedToAt
      userAgreementVersionAgreedTo
      createdAt
      updatedAt
      subAccounts {
        items {
          id
        }
      }
      permissions {
        subAccountId
        entitlements
      }
    }
  }
`;

export type CreateUserResponse = {
  user: NextUser;
};

export type CreateUserInput = {
  user: {
    email: string;
    name: string;
    title?: string;
    legacyId?: number;
    managedByAccountId?: string;
  };
};

export const UPDATE_USER = gql`
  mutation UpdateUser($user: UpdateUserInput!) {
    user: updateUser(input: $user) {
      id
      email
      name
      title
      legacyId
      enabled
      sub
      userAgreementAgreedToAt
      userAgreementVersionAgreedTo
      createdAt
      updatedAt
      subAccounts {
        items {
          id
        }
      }
      permissions {
        subAccountId
        entitlements
      }
    }
  }
`;

export type UpdateUserResponse = {
  user: NextUser;
};

export type UpdateUserInput = {
  user: {
    id: string;
    name: string;
    title?: string;
    legacyId?: number;
    managedByAccountId?: string;
  };
};

export const ADD_USER_TO_SUBACCOUNT = gql`
  mutation AddToSubaccount($userId: ID!, $subAccountId: ID!) {
    addUserToSubAccount(userId: $userId, subAccountId: $subAccountId) {
      id
      email
      name
      title
      legacyId
      enabled
      sub
      userAgreementAgreedToAt
      userAgreementVersionAgreedTo
      createdAt
      updatedAt
      subAccounts {
        items {
          id
        }
      }
      permissions {
        subAccountId
        entitlements
      }
    }
  }
`;

export type AddUserToSubaccountResponse = {
  user: NextUser;
};

export type AddUserToSubaccountInput = {
  userId: string;
  subAccountId: string;
};

export const REMOVE_USER_FROM_SUBACCOUNT = gql`
  mutation RemoveFromSubaccount($userId: ID!, $subAccountId: ID!) {
    removeUserFromSubAccount(userId: $userId, subAccountId: $subAccountId) {
      id
      email
      name
      title
      legacyId
      enabled
      sub
      userAgreementAgreedToAt
      userAgreementVersionAgreedTo
      createdAt
      updatedAt
      subAccounts {
        items {
          id
        }
      }
      permissions {
        subAccountId
        entitlements
      }
    }
  }
`;

export type RemoveUserFromSubaccountResponse = {
  user: NextUser;
};

export type RemoveUserFromSubaccountInput = {
  userId: string;
  subAccountId: string;
};

export const SET_USER_ENABLED = gql`
  mutation SetUserEnabled($userId: ID!, $enabled: Boolean!) {
    setUserEnabled(userId: $userId, enabled: $enabled) {
      id
      email
      name
      title
      legacyId
      enabled
      sub
      userAgreementAgreedToAt
      userAgreementVersionAgreedTo
      createdAt
      updatedAt
      subAccounts {
        items {
          id
        }
      }
      permissions {
        subAccountId
        entitlements
      }
    }
  }
`;

export type SetUserEnabledInput = {
  userId: string;
  enabled: boolean;
};

export type SetUserEnabledResponse = {
  user: NextUser;
};

export const GET_DRUG_SOURCE_AND_CLAIM_SYSTEMS = gql`
  query GetDrugSourcesAndClaimSystems {
    drugSources {
      items {
        id
        shortName
        drugSource
      }
    }
    claimSystems {
      items {
        id
        sourceName
      }
    }
  }
`;

export type GetDrugSourceAndClaimSystemsResponse = {
  drugSources: {
    items: Array<NextDrugSource>;
  };
  claimSystems: {
    items: Array<NextClaimSystem>;
  };
};

export const GET_SUB_ACCOUNT = gql`
  query GetSubAccount($subAccountId: ID!) {
    subAccount(subAccountId: $subAccountId) {
      id
      name
      isParent
      legacyClientCode
      apiKeys {
        id
        name
        key
      }
      drugSource {
        id
        shortName
        drugSource
      }
      claimSystem {
        id
        sourceName
      }
    }
  }
`;

export type GetSubAccountInput = {
  subAccountId: string;
};

export type GetSubAccountResponse = {
  subAccount: NextSubAccount;
};

export const UDPATE_SUB_ACCOUNT = gql`
  mutation UpdateSubAccount($id: ID!, $subAccount: UpdateSubAccountInput!) {
    updateSubAccount(id: $id, input: $subAccount) {
      id
      name
      isParent
      legacyClientCode
      apiKeys {
        id
        name
      }
      drugSource {
        id
        shortName
        drugSource
      }
      claimSystem {
        id
        sourceName
      }
    }
  }
`;

export type UpdateSubAccountInput = {
  id: string;
  subAccount: {
    name: string;
    isParent?: boolean;
    legacyClientCode?: string;
    drugSourceId: string;
    claimSystemId: string;
  };
};

export type UpdateSubAccountResponse = {
  updateSubAccount: NextSubAccount;
};

export const CREATE_SUB_ACCOUNT = gql`
  mutation CreateSubAccount($subAccount: SubAccountInput!) {
    createSubAccount(input: $subAccount) {
      id
      name
      isParent
      legacyClientCode
      apiKeys {
        id
        name
      }
      drugSource {
        id
        shortName
        drugSource
      }
      claimSystem {
        id
        sourceName
      }
    }
  }
`;

export type CreateSubAccountInput = {
  subAccount: {
    accountId: string;
    name: string;
    isParent?: boolean;
    legacyClientCode?: string;
    drugSourceId: string;
    claimSystemId: string;
  };
};

export type CreateSubAccountResponse = {
  createSubAccount: NextSubAccount;
};

export const ADD_API_KEY_TO_SUBACCOUNT = gql`
  mutation AddApiKeyToSubaccount($subAccountId: ID!, $apiKeyId: ID!) {
    addApiKey(subAccountId: $subAccountId, apiKeyId: $apiKeyId) {
      id
      name
      isParent
      legacyClientCode
      apiKeys {
        id
        name
      }
      drugSource {
        id
        shortName
        drugSource
      }
      claimSystem {
        id
        sourceName
      }
    }
  }
`;

export type AddApiKeyToSubaccountInput = {
  subAccountId: string;
  apiKeyId: string;
};

export type AddApiKeyToSubaccountRespose = {
  addApiKey: NextSubAccount;
};

export const REMOVE_API_KEY_FROM_SUBACCOUNT = gql`
  mutation AddApiKeyToSubaccount($subAccountId: ID!, $apiKeyId: ID!) {
    removeApiKey(subAccountId: $subAccountId, apiKeyId: $apiKeyId) {
      id
      name
      isParent
      legacyClientCode
      apiKeys {
        id
        name
      }
      drugSource {
        id
        shortName
        drugSource
      }
      claimSystem {
        id
        sourceName
      }
    }
  }
`;

export type RemoveApiKeyFromSubaccountInput = {
  subAccountId: string;
  apiKeyId: string;
};

export type RemoveApiKeyFromSubaccountRespose = {
  addApiKey: NextSubAccount;
};

export const GET_API_KEYS = gql`
  query GetApiKeys($accountId: ID!) {
    account(accountId: $accountId) {
      apiKeys {
        items {
          id
          name
          description
          enabled
          key
          plan {
            id
            name
          }
          createdAt
        }
      }
    }
  }
`;

export type GetApiKeysInput = {
  accountId: string;
};

export type GetApiKeysResponse = {
  account: {
    apiKeys: {
      items: Array<ApiKey>;
    };
  };
};

export const GET_API_KEY_PLANS = gql`
  query ApiKeyPlans {
    apiKeyPlans {
      items {
        id
        name
      }
    }
  }
`;

export type GetApiKeyPlansResponse = {
  apiKeyPlans: {
    items: Array<ApiKeyPlan>;
  };
};

export const CREATE_API_KEY = gql`
  mutation CreateApiKey($apiKey: AccountApiKeyInput!) {
    createAccountApiKey(input: $apiKey) {
      id
      name
      description
      enabled
      key
      plan {
        id
        name
      }
      createdAt
    }
  }
`;

export type CreateApiKeyInput = {
  apiKey: {
    accountId: string;
    apiKeyPlanId: string;
    name: string;
    description?: string;
  };
};

export type CreateApiKeyResponse = {
  createAccountApiKey: ApiKey;
};

export const UPDATE_API_KEY = gql`
  mutation UpdateApiKey($apiKey: UpdateApiKeyInput!) {
    updateAccountApiKey(input: $apiKey) {
      id
      name
      description
      enabled
      key
      plan {
        id
        name
      }
      createdAt
    }
  }
`;

export type UpdateApiKeyInput = {
  apiKey: {
    id: string;
    apiKeyPlanId: string;
    name: string;
    description?: string;
  };
};

export type UpdateApiKeyResponse = {
  updateAccountApiKey: ApiKey;
};

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($id: ID!, $account: UpdateAccountInput!) {
    updateAccount(id: $id, input: $account) {
      id
      name
      shortName
      legacyCustomerCode
      launchDate
      createdAt
      updatedAt
      subAccounts {
        items {
          id
          name
        }
      }
    }
  }
`;

export type UpdateAccountInput = {
  id: string;
  account: {
    name?: string;
    shortName?: string;
    legacyCustomerCode?: string;
    launchDate?: string;
  };
};

export type UpdateAccountResponse = {
  updateAccount: NextAccount;
};

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($account: AccountInput!) {
    createAccount(input: $account) {
      id
      name
      shortName
      legacyCustomerCode
      launchDate
      createdAt
      updatedAt
      subAccounts {
        items {
          id
          name
        }
      }
    }
  }
`;

export type CreateAccountInput = {
  account: {
    name?: string;
    shortName?: string;
    legacyCustomerCode?: string;
    launchDate?: string;
  };
};

export type CreateAccountResponse = {
  createAccount: NextAccount;
};

export const DELETE_SUB_ACCOUNT = gql`
  mutation DeleteSubAccount($subAccountId: ID!) {
    deleteSubAccount(id: $subAccountId) {
      id
    }
  }
`;

export type DeleteSubAccountInput = {
  subAccountId: string;
};

export type DeleteSubAccountResponse = { deleteSubAccount: {} };

export const SAVE_USER_ENTITLEMENTS = gql`
  mutation SaveUserEntitlements(
    $userId: ID!
    $subAccountId: ID!
    $entitlements: [UserEntitlementType!]!
  ) {
    saveUserEntitlements(
      userId: $userId
      subAccountId: $subAccountId
      entitlements: $entitlements
    ) {
      subAccountId
      entitlements
    }
  }
`;

export type SaveUserEntitlementsInput = {
  userId: string;
  subAccountId: string;
  entitlements: Array<UserEntitlements>;
};

export type SaveUserEntitlementsResponse = {
  saveUserEntitlements: UserPermissions;
};

export const SET_SELECTED_SUB_ACCOUNT = gql`
  mutation SetSelectedSubAccount($subAccountId: ID!, $userMode: UserModes) {
    setSelectedSubAccount(input: { subAccountId: $subAccountId, userMode: $userMode }) {
      id
      name
    }
  }
`;

export type SetSelectedSubAccountInput = {
  subAccountId: string;
  userMode?: "MEDICARE" | "NONMEDICARE";
};

export type SetSelectedSubAccountResponse = {
  setSelectedSubAccount: { id: string; name: string };
};
