import * as React from "react";
import { Button, makeStyles } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles({
  rootButton: {
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "0px",
    textAlign: "left",
    alignItems: "flex-start",
    width: "80px",
  },
  buttonIcon: {
    marginRight: "5px",
  },
});

interface Props {
  isHidden: boolean;
  disabled?: boolean;
  onHide: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onShow: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const HideButton: React.FC<Props> = (props) => {
  const classes = useStyles();

  const isDisabled = props.disabled ?? false;

  return props.isHidden ? (
    <Button
      className={classes.rootButton}
      disabled={isDisabled}
      onClick={props.onShow}
      data-testid="show-button"
    >
      <VisibilityIcon className={classes.buttonIcon} />
      Show
    </Button>
  ) : (
    <Button
      className={classes.rootButton}
      disabled={isDisabled}
      onClick={props.onHide}
      data-testid="hide-button"
    >
      <VisibilityOffIcon className={classes.buttonIcon} />
      Hide
    </Button>
  );
};
