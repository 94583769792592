export const EXTERNAL_ID_HELP_TEXT =
  "An optional field meant to hold an identifying code for a third-party adjudication system.";

export const CUSTOM_PRIORITY_HELP_TEXT =
  "This feature allows you to set your own priority rankings for drugs contained in this list. This will affect which alternative drug comes up first when a user looks for alternatives.";

export const USE_ALL_DRUGS_FROM_RULES_HELP_TEXT =
  "This feature allows the alternative drug list to include both priority rules, and any other drugs specified by this drug list. Any prioritized drugs will always return first, followed by any remaining drugs specified by the drug list rules.";

export const CUSTOM_PRICING_LIST_HELP_TEXT =
  "If you have your own pricing list for alternatives, you may want to enable this feature. Using a pricing list allows to pricing data to show up while sorting, or alternatively, sort the entire list purely by price.";

export const UNLISTED_DRUG_GROUP_BY_HELP_TEXT =
  "If the drug being searched is not in an existing alternative list, this field tells Web Search what it should be grouped by.";

export const FILTER_BY_TIER_HELP_TEXT =
  "Turning this on will show the Tier filter options panel. This is useful if you want to group the results of one tier in with another.";

export const PRICING_LIST_HELP_TEXT =
  "If you have a default pricing list to use for unlisted drugs, you can specify that list here. Using a pricing list here allows us to sort any matching unlisted drugs according to the corresponding prices.";
