import { useQuery } from "@apollo/client";
import { Divider, IconButton, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import BackButton from "@material-ui/icons/ArrowBackRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../components/UserContext";
import { apiFormatDate, awsDateStringToDate } from "../../../utils/Date";
import { APIPricingList, GET_LIST, GetListInput, GetListResponse } from "../api";
import { ListTypeRoute, useDrugListRouteParams } from "../DrugListRouteUtils";
import produce from "immer";
import { compareDesc } from "date-fns";
import { PricingListSetup } from "./Setup/PricingListSetup";
import { PricingListRules } from "./Rules/PricingListRules";
import { PricingListView } from "./View/PricingListView";
import { HeaderSkeleton } from "../../../components/loading/Skeletons";

enum PricingListTabs {
  SETUP = "Setup",
  RULES = "Rules",
  VIEW = "View",
}

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
}));

export const ManagePricingDrugList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { drugListId } = useDrugListRouteParams();
  const {
    user: { isManagingMedicare },
  } = React.useContext(UserContext);
  const [activeTab, setActiveTab] = React.useState<PricingListTabs>(
    drugListId ? PricingListTabs.VIEW : PricingListTabs.SETUP
  );
  const [viewDate, setViewDate] = React.useState<Date>(new Date());
  const [pricingList, setPricingList] = React.useState<APIPricingList | undefined>(undefined);

  const isNew = drugListId === undefined;

  const { loading: isLoadingList, refetch: refetchList } = useQuery<
    GetListResponse<APIPricingList>,
    GetListInput
  >(GET_LIST, {
    skip: isNew,
    variables: {
      id: drugListId as string,
      rulesInput: {
        asOfDate: apiFormatDate(viewDate),
      },
      drugsInput: {
        asOfDate: apiFormatDate(viewDate),
      },
    },
    onCompleted: (response) => {
      if (!isNew) {
        setPricingList(
          produce(response.list, (draft) => {
            draft?.rules?.items?.sort((a, b) =>
              a.createdAt && b.createdAt
                ? compareDesc(awsDateStringToDate(a.createdAt), awsDateStringToDate(b.createdAt))
                : 0
            );
          })
        );
      }
    },
  });

  const handleSaveList = (listId: string) => {
    history.push(`${ListTypeRoute.PRICE}/edit/${listId}`);
    setActiveTab(PricingListTabs.RULES);
  };

  const TabPanel = (props: React.PropsWithChildren<{ value: PricingListTabs }>) => {
    const { children, value, ...other } = props;
    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  const refetchRules = (viewDate: Date) => () => {
    if (drugListId) {
      refetchList({
        id: drugListId,
        rulesInput: {
          asOfDate: apiFormatDate(viewDate),
        },
        drugsInput: {
          asOfDate: apiFormatDate(viewDate),
        },
      }).then((value) => {
        setPricingList(
          produce(value.data.list, (draft) => {
            draft?.rules?.items?.sort((a, b) =>
              a.createdAt && b.createdAt
                ? compareDesc(awsDateStringToDate(a.createdAt), awsDateStringToDate(b.createdAt))
                : 0
            );
          })
        );
      });
    }
  };

  const handleChangeViewDate = (date: Date) => {
    setViewDate(date);
    refetchRules(date);
  };

  const Header = () => {
    if (isNew) {
      return <Typography>{`New${isManagingMedicare ? " Medicare" : ""} Pricing List`}</Typography>;
    } else if (isLoadingList) {
      return (
        <div style={{ width: "100%" }}>
          <HeaderSkeleton headerVariant="h4" />
        </div>
      );
    } else if (pricingList?.name) {
      return (
        <Typography variant="h4" color="primary">
          {pricingList?.name}
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.fullWidthPageArea}>
      <div className={classes.headerArea}>
        <IconButton onClick={() => history.push(ListTypeRoute.PRICE)}>
          <BackButton />
        </IconButton>
        <Header />
      </div>
      <Divider variant="middle" />
      <Tabs
        centered
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, value) => setActiveTab(value)}
        aria-label="formulary navigation tabs"
      >
        {Object.values(PricingListTabs).map((tabValue, index) => (
          <Tab
            key={index}
            label={<Typography color="inherit">{tabValue}</Typography>}
            value={tabValue}
            className={classes.tabText}
            disabled={tabValue !== PricingListTabs.SETUP && !Boolean(drugListId)}
          />
        ))}
      </Tabs>
      <TabPanel value={PricingListTabs.SETUP}>
        <PricingListSetup drugList={pricingList as APIPricingList} onSave={handleSaveList} />
      </TabPanel>
      <TabPanel value={PricingListTabs.RULES}>
        <PricingListRules
          rules={pricingList?.rules?.items ?? []}
          viewDate={viewDate}
          isLoading={isLoadingList}
          onTerminateRule={refetchRules(viewDate)}
          onChangeViewDate={handleChangeViewDate}
          onAddRule={refetchRules(viewDate)}
        />
      </TabPanel>
      {drugListId && (
        <>
          <TabPanel value={PricingListTabs.VIEW}>
            <PricingListView
              listId={drugListId}
              viewDate={viewDate}
              isLoading={isLoadingList}
              onDateChange={setViewDate}
            />
          </TabPanel>
        </>
      )}
    </div>
  );
};
