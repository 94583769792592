import { makeStyles, TextField } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { HideButton } from "../../../../components/buttons/HideButton";
import { EDITS_COLUMN } from "../../../FieldNameConstant";
import { FullFormularyTemplate } from "../../types";
import { ConfigOptionsTable } from "../../../../components/tables/ConfigOptionsTable";
import { ColumnSettingsForm } from "./ColumnSettingsForm";
import { ColumnWidthInput } from "./ColumnWidthInput";
import { LabelSwitchController } from "../../../../components/switches/LabelSwitchController";
import { EDIT_DETAILS_HELP_TEXT } from "../../../helpText";
import { IsViewingTemplateContext } from "../../../isEditingTemplateContext";

const useStyles = makeStyles((theme) => ({
  switchEdgeFix: {
    marginLeft: "-12px",
  },
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

type Props = {
  isActiveColumn: boolean;
  onHideColumn: () => void;
};

export const EditsColumnForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { getValues, setValue, errors } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  const [isHidden, setIsHidden] = React.useState(false);

  React.useEffect(() => {
    const formStateHidden = Boolean(getValues(EDITS_COLUMN.HIDDEN));
    if (formStateHidden !== isHidden) {
      setIsHidden(formStateHidden);
    }
  }, [isHidden, getValues]);

  const hasMaxWidthError = Boolean(errors.settings?.editsColumnSettings?.width?.type === "max");
  const hasMinWidthError = Boolean(errors.settings?.editsColumnSettings?.width?.type === "min");
  const hasRequiredError = Boolean(
    errors.settings?.editsColumnSettings?.width?.type === "required"
  );

  return (
    <ColumnSettingsForm
      columnName="Edits"
      activeColumn={props.isActiveColumn}
      columnHidden={isHidden}
      topContent={
        <Controller
          name={EDITS_COLUMN.HIDDEN}
          as={
            <HideButton
              isHidden={isHidden}
              disabled={isViewOnly}
              onHide={() => {
                setIsHidden(true);
                props.onHideColumn();
              }}
              onShow={() => {
                setValue(EDITS_COLUMN.HIDDEN, false);
                setIsHidden(false);
              }}
            />
          }
        />
      }
    >
      {/* Added for to register values with react-hook-form for state management. Not registered w/ standard input to persist number type */}
      <div style={{ display: "none" }}>
        <Controller as={<TextField />} name={EDITS_COLUMN.POSITION} />
      </div>
      {
        <ConfigOptionsTable
          disabled={getValues(EDITS_COLUMN.HIDDEN)}
          settings={[
            {
              label: "Title",
              options: (
                <Controller
                  as={
                    <TextField
                      disabled={isViewOnly}
                      style={{ width: "200px" }}
                      inputProps={{ style: { textOverflow: "ellipsis" } }}
                      InputProps={{ classes: { focused: classes.themedUnderline } }}
                    />
                  }
                  name={EDITS_COLUMN.TITLE}
                />
              ),
            },

            {
              label: "Column width",
              options: (
                <ColumnWidthInput
                  disabled={isViewOnly}
                  widthFieldName={EDITS_COLUMN.WIDTH}
                  hasMaxWidthError={hasMaxWidthError}
                  hasMinWidthError={hasMinWidthError}
                  hasRequiredError={hasRequiredError}
                />
              ),
            },
            {
              label: "Extended Edit Details",
              helpText: EDIT_DETAILS_HELP_TEXT,
              options: (
                <LabelSwitchController
                  disabled={isViewOnly}
                  isOn={getValues(EDITS_COLUMN.SHOW_DETAILS)}
                  name={EDITS_COLUMN.SHOW_DETAILS}
                />
              ),
            },
          ]}
        />
      }
    </ColumnSettingsForm>
  );
};
