import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { HideButton } from "../../../components/buttons/HideButton";
import { EditorDialog } from "../../../components/dialogs/EditorDialog";
import { LEGEND_SETTINGS, PAGE_SETTINGS } from "../../FieldNameConstant";
import { FullFormularyTemplate } from "../types";
import { LabelSwitchController } from "../../../components/switches/LabelSwitchController";
import { ConfigOptionsTable } from "../../../components/tables/ConfigOptionsTable";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";

interface StyleProps {
  isHidden: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>({
  root: {
    alignSelf: "start",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  legendContent: (props) => ({
    display: "flex",
    flexDirection: "column",
    height: props.isHidden ? 0 : "auto",
    visibility: props.isHidden ? "hidden" : "visible",
  }),
  switchEdgeFix: {
    marginLeft: "-12px",
  },
  headerFooterButtons: {
    marginTop: 15,
    padding: "10px 8px",
    width: "fit-content",
    textTransform: "capitalize",
  },
});

interface Props {
  showTiers: boolean;
  showEdits: boolean;
}

export const LegendSettings: React.FC<Props> = (props) => {
  const { setValue, getValues, watch } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  const [isHidden, setIsHidden] = React.useState(false);
  const classes = useStyles({ isHidden });

  watch([
    LEGEND_SETTINGS.HEADER,
    LEGEND_SETTINGS.FOOTER,
    LEGEND_SETTINGS.EDITS,
    LEGEND_SETTINGS.TIERS,
  ]);

  const showLegend = () => {
    setValue(LEGEND_SETTINGS.EDITS, true);
    setValue(LEGEND_SETTINGS.TIERS, true);
    setIsHidden(false);
  };

  const hideLegend = () => {
    setValue(LEGEND_SETTINGS.EDITS, false);
    setValue(LEGEND_SETTINGS.TIERS, false);
    setIsHidden(true);
  };

  const updateHeaderContent = (newContent: string) => {
    setValue(LEGEND_SETTINGS.HEADER, newContent);
  };

  const updateFooterContent = (newContent: string) => {
    setValue(LEGEND_SETTINGS.FOOTER, newContent);
  };

  return (
    <div id="legend-settings" data-testid="legend-settings" className={classes.root}>
      <HideButton
        disabled={isViewOnly}
        isHidden={isHidden}
        onHide={hideLegend}
        onShow={showLegend}
      />
      <div className={classes.legendContent}>
        <ConfigOptionsTable
          disabled={isHidden}
          settings={[
            {
              label: "Display tiers",
              options: (
                <LabelSwitchController
                  isOn={getValues(LEGEND_SETTINGS.TIERS) || true}
                  disabled={isViewOnly}
                  name={LEGEND_SETTINGS.TIERS}
                />
              ),
            },
            {
              label: "Display edits",
              options: (
                <LabelSwitchController
                  isOn={getValues(LEGEND_SETTINGS.EDITS) || true}
                  disabled={isViewOnly}
                  name={LEGEND_SETTINGS.EDITS}
                />
              ),
            },
          ]}
        />
        <Controller
          as={
            <EditorDialog
              title="Legend Header"
              templateFont={getValues(PAGE_SETTINGS.FONT)}
              leftMargin={getValues(PAGE_SETTINGS.LEFT_MARGIN)}
              rightMargin={getValues(PAGE_SETTINGS.RIGHT_MARGIN)}
              units={getValues(PAGE_SETTINGS.UNITS)}
              content={getValues(LEGEND_SETTINGS.HEADER)}
              updateContent={updateHeaderContent}
              disabled={isViewOnly || isHidden}
            />
          }
          name={LEGEND_SETTINGS.HEADER}
        />
        <Controller
          as={
            <EditorDialog
              title="Legend Footer"
              templateFont={getValues(PAGE_SETTINGS.FONT)}
              leftMargin={getValues(PAGE_SETTINGS.LEFT_MARGIN)}
              rightMargin={getValues(PAGE_SETTINGS.RIGHT_MARGIN)}
              units={getValues(PAGE_SETTINGS.UNITS)}
              content={getValues(LEGEND_SETTINGS.FOOTER)}
              updateContent={updateFooterContent}
              disabled={isViewOnly || isHidden}
            />
          }
          name={LEGEND_SETTINGS.FOOTER}
        />
      </div>
    </div>
  );
};
