import produce from "immer";
import { FormularyTier } from "../../Flex/Formularies/FormularyPage/types";
import { EditNetworkTier } from "../../Network/api";

/**
 * Description:
 *  The Cost Share Table in Plan Config is persisted as JSON which the client models the data of.
 *  This file models the Cost Share JSON Schema which describes the data being transmitted and stored over backend systems.
 *
 * Info and Questions
 *  - cost share id - medicare has 3-4 cost share phase, commercial just has 1 phase (default 1)
 */
export interface CostShare {
  formularyTiers: FormularyTierRows;
  lists: ListRows;
  isUsingClaimRanges: boolean;
  isUsingDaySupplyRanges: boolean;
}

export interface FormularyTierRows {
  [formularyTierId: string]: FormularyTierRow;
}

export interface FormularyTierRow {
  isEnabled: boolean;
  outOfNetworkPenalty?: OutOfNetworkPenaltyValue;
  networkTiers: NetworkTiers;
}

/**
 * Although Network Tiers on a Network can change, once created a Network has a set of fixed Tiers. Each of these Network Tiers contains
 * a Sub Account Network Tier that can be swapped out. In this way Network Tiers can both stay the same but the underlying
 * Sub Account Network Tier can change.
 */
export interface NetworkTiers {
  [networkTierId: string /* network code */]: {
    daySupplyRanges: DaySupplyRanges;
  };
}

export interface DaySupplyRanges {
  [daySupplyRangeStartingValue: number /* int > 0 */]: {
    claimRanges: ClaimRanges;
  };
}

export interface ClaimRanges {
  [claimRangeStartingCost: number /* float */]: ClaimRangeValue;
}

/**
 * For now, lists primarily means Benefits Lists
 */
export interface ListRows {
  [id: string]: ListRow;
}

export interface ListRow {
  order: number; // position of list after formulary tiers. Positive int starting at 0
  outOfNetworkPenalty?: OutOfNetworkPenaltyValue;
  networkTiers: NetworkTiers;
}

export interface OutOfNetworkPenaltyValue {
  valueType: ValueType;
  value?: number;
}

export type ClaimRangeValue =
  | {
      coPayCoIns: ValueType.PERCENTAGE;
      value?: number;
      min?: number;
      max?: number;
    }
  | { coPayCoIns: ValueType.DOLLAR; value?: number };

export enum ValueType {
  PERCENTAGE = "PERCENTAGE",
  DOLLAR = "DOLLAR",
}

export const EMPTY_CLAIM_RANGES: { claimRanges: ClaimRanges } = {
  claimRanges: { 0: { coPayCoIns: ValueType.DOLLAR, value: undefined } },
};

/**
 * Create an empty Cost Share for a newly created Plan Config.
 * Ensures that the Formulary Tiers are sorted by rank, and that the Network Tiers are applied in the same order on each Formulary
 */
export function createDefaultEmptyCostShare(
  formularyTiers: Array<FormularyTier>,
  networkTiers: Array<EditNetworkTier>
): CostShare {
  const costShareNetworkTiers = emptyNetworkTierColumns(networkTiers, startingDaySupplyRanges);

  const costShareFormularyTiers = produce(formularyTiers, (draft) => {
    draft.sort((a, b) => a.rank - b.rank);
  }).reduce<FormularyTierRows>((formularyTierRows, formularyTier) => {
    return {
      ...formularyTierRows,
      [formularyTier.id]: emptyFormularyTierRow(costShareNetworkTiers),
    };
  }, {});

  return {
    formularyTiers: costShareFormularyTiers,
    lists: {},
    isUsingClaimRanges: false,
    isUsingDaySupplyRanges: true,
  };
}

export function startingDaySupplyRanges(networkTier: EditNetworkTier): DaySupplyRanges {
  const SPECIALTY_TIER_NAME = "specialty";

  const BSC_STANDARD_DAY_SUPPLY_RANGES: DaySupplyRanges = {
    1: EMPTY_CLAIM_RANGES,
    31: EMPTY_CLAIM_RANGES,
    61: EMPTY_CLAIM_RANGES,
    91: EMPTY_CLAIM_RANGES,
  };

  const BSC_SPECIALTY_DAY_SUPPLY_RANGES: DaySupplyRanges = {
    1: EMPTY_CLAIM_RANGES,
    91: EMPTY_CLAIM_RANGES,
  };

  return networkTier.name.toLowerCase() === SPECIALTY_TIER_NAME
    ? BSC_SPECIALTY_DAY_SUPPLY_RANGES
    : BSC_STANDARD_DAY_SUPPLY_RANGES;
}

export function emptyNetworkTierColumns(
  networkTiers: Array<EditNetworkTier>,
  daySupplyRanges: (networkTier: EditNetworkTier) => DaySupplyRanges
): NetworkTiers {
  return networkTiers.reduce<NetworkTiers>((networkTierColumns, networkTier) => {
    return {
      ...networkTierColumns,
      [networkTier.id]: {
        daySupplyRanges: daySupplyRanges(networkTier),
      },
    };
  }, {});
}

export function emptyFormularyTierRow(networkTiers: NetworkTiers): FormularyTierRow {
  return {
    isEnabled: true,
    networkTiers,
    outOfNetworkPenalty: undefined,
  };
}

export function createEmptyRow(
  networkTiers: Array<EditNetworkTier>,
  costShare: CostShare,
  formularyTiers: Array<FormularyTier>
): ListRow {
  const costShareNetworkTiers = networkTiers.reduce<NetworkTiers>(
    (networkTierColumns, networkTier) => {
      return {
        ...networkTierColumns,
        [networkTier.id]: {
          daySupplyRanges: daySupplyRangeStartingValues(
            costShare,
            networkTier.id,
            formularyTiers
          ).reduce<DaySupplyRanges>((daySupplyRanges, rangeStartingValue) => {
            daySupplyRanges[rangeStartingValue] = EMPTY_CLAIM_RANGES;
            return daySupplyRanges;
          }, {}),
        },
      };
    },
    {}
  );

  return {
    networkTiers: costShareNetworkTiers,
    order: Object.keys(costShare.lists).length,
    outOfNetworkPenalty: undefined,
  };
}

export function firstFormularyTierRow(
  costShare: CostShare,
  formularyTiers: Array<FormularyTier>
): FormularyTierRow {
  const sortedRanks = formularyTiers
    .map((formularyTier) => formularyTier.rank)
    .sort((rankA, rankB) => rankA - rankB);
  const lowestRank = sortedRanks[0];
  const firstFormulary = formularyTiers.find((tier) => tier.rank === lowestRank)!;
  return costShare.formularyTiers[firstFormulary.id];
}

export function daySupplyRanges(
  costShare: CostShare,
  networkTierId: string,
  formularyTiers: Array<FormularyTier>
): DaySupplyRanges {
  const firstFormularyTier = firstFormularyTierRow(costShare, formularyTiers);
  return firstFormularyTier.networkTiers[networkTierId].daySupplyRanges;
}

export type RowKind =
  | { kind: "formulary tier"; formularyTierId: string }
  | { kind: "list"; listId: string };

export function getRow(rowKind: RowKind, costShare: CostShare): FormularyTierRow | ListRow {
  return rowKind.kind === "formulary tier"
    ? costShare.formularyTiers[rowKind.formularyTierId]
    : costShare.lists[rowKind.listId];
}

export function daySupplyRangeStartingValues(
  costShare: CostShare,
  networkTierId: string,
  formularyTiers: Array<FormularyTier>,
  rowKind?: RowKind
): Array<number> {
  const row = rowKind
    ? rowKind.kind === "formulary tier"
      ? costShare.formularyTiers[rowKind.formularyTierId]
      : costShare.lists[rowKind.listId]
    : firstFormularyTierRow(costShare, formularyTiers);

  return Object.keys(row.networkTiers[networkTierId].daySupplyRanges)
    .map((startingValue) => parseInt(startingValue))
    .sort((a, b) => a - b);
}

export function numberOfDaySupplyRangesOnNetworkTier(
  costShare: CostShare,
  networkTierId: string,
  formularyTiers: Array<FormularyTier>
): number {
  return Object.keys(daySupplyRanges(costShare, networkTierId, formularyTiers)).length;
}

/**
 * Remove certain pieces of data that are no longer being used. Examples:
 *  - If day supply ranges are disabled, then remove unused day supply range data
 *  - If claim ranges are disabled, then remove unused claim range data
 *
 * For a more seamless user experience, while editing some unused data is persisted to prevent data loss.
 * This persisted data should be removed on save though..
 */
export function cleanCostShare(costShare: CostShare): CostShare {
  return produce(costShare, (draft) => {
    if (draft.isUsingDaySupplyRanges === false) {
      for (const formularyTierId in draft.formularyTiers) {
        const currentFormularyTier = draft.formularyTiers[formularyTierId];
        for (const networkTierId in currentFormularyTier.networkTiers) {
          const currentNetworkTier = currentFormularyTier.networkTiers[networkTierId];
          for (const daySupplyRangeStartingValue in currentNetworkTier.daySupplyRanges) {
            const rangeStartingValue = parseInt(daySupplyRangeStartingValue);
            if (rangeStartingValue !== 1) {
              delete currentNetworkTier.daySupplyRanges[daySupplyRangeStartingValue];
            }
          }
        }
      }
    }
  });
}
