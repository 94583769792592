import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import * as React from "react";
import { PreviewStatus } from "../../types/PreviewTypes";
import {
  APIFormularyTemplateSettings,
  APIFullFormularyTemplate,
  FullFormularyTemplate,
} from "./types";

export const LIST_FORMULARY_TEMPLATES = gql`
  query formularyTemplates {
    templates(templateType: FULL_FORMULARY) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        settings {
          ... on FullFormularyTemplateSettings {
            font
            indexFontSize
            indexFooterBase64
            indexHeaderBase64
            indexNumberOfColumns
            indexTitle
            indexTitleTextAlignment
            legendFooterBase64
            legendHeaderBase64
            legendShowLimitations
            legendShowTiers
            showIndex
            tableCellsFontSize
            tableFooterBase64
            tableGroupDrugsByName
            tableHeaderBase64
            tableHeadingBackgroundColor
            tableHeadingFontSize
            tableShowColumnDividers
            tableShowGroupDosage
            tableShowRowDividers
            marginSettings {
              left
              bottom
              right
              top
              units
            }
            descriptionColumnSettings {
              position
              title
              width
            }
            editsColumnSettings {
              hidden
              position
              showDetails
              title
              width
            }
            tierColumnSettings {
              hidden
              position
              rankPrefix
              showDescription
              showRank
              title
              width
            }
          }
        }
      }
    }
  }
`;

export type ListFormularyTemplatesResponse = {
  templates: {
    items: Array<FullFormularyTemplate>;
  };
};

export const GET_FORMULARY_TEMPLATE = gql`
  query GetFormularyTemplate($templateId: ID!) {
    template(templateId: $templateId) {
      id
      name
      settings {
        ... on FullFormularyTemplateSettings {
          font
          indexFontSize
          indexFooterBase64
          indexHeaderBase64
          indexNumberOfColumns
          indexTitle
          indexTitleTextAlignment
          legendFooterBase64
          legendHeaderBase64
          legendShowLimitations
          legendShowTiers
          showIndex
          tableCellsFontSize
          tableFooterBase64
          tableGroupDrugsByName
          tableHeaderBase64
          tableHeadingBackgroundColor
          tableHeadingFontSize
          tableShowColumnDividers
          tableShowGroupDosage
          tableShowRowDividers
          marginSettings {
            left
            bottom
            right
            top
            units
          }
          descriptionColumnSettings {
            position
            title
            width
          }
          editsColumnSettings {
            hidden
            position
            showDetails
            title
            width
          }
          tierColumnSettings {
            hidden
            position
            rankPrefix
            showDescription
            showRank
            title
            width
          }
        }
      }
    }
  }
`;
export type GetFormularyTemplateInput = {
  templateId: string;
};
export type GetFormularyTemplateResponse = {
  template: APIFullFormularyTemplate;
};

export const CREATE_FORMULARY_TEMPLATE = gql`
  mutation CreateFullFormularyTemplate($name: String, $settings: InputFullFormularyTemplate!) {
    saveFullFormularyTemplate(name: $name, settings: $settings) {
      id
      name
      settings {
        ... on FullFormularyTemplateSettings {
          font
          indexFontSize
          indexFooterBase64
          indexHeaderBase64
          indexNumberOfColumns
          indexTitle
          indexTitleTextAlignment
          legendFooterBase64
          legendHeaderBase64
          legendShowLimitations
          legendShowTiers
          showIndex
          tableCellsFontSize
          tableFooterBase64
          tableGroupDrugsByName
          tableHeaderBase64
          tableHeadingBackgroundColor
          tableHeadingFontSize
          tableShowColumnDividers
          tableShowGroupDosage
          tableShowRowDividers
          marginSettings {
            left
            bottom
            right
            top
            units
          }
          descriptionColumnSettings {
            position
            title
            width
          }
          editsColumnSettings {
            hidden
            position
            showDetails
            title
            width
          }
          tierColumnSettings {
            hidden
            position
            rankPrefix
            showDescription
            showRank
            title
            width
          }
        }
      }
    }
  }
`;
export type CreateFormularyTemplateInput = {
  name: string;
  settings: APIFormularyTemplateSettings;
};

export type SaveFormularyTemplateResponse = {};

export const UPDATE_FORMULARY_TEMPLATE = gql`
  mutation UpdateFormularyTemplate(
    $name: String
    $settings: InputFullFormularyTemplate!
    $templateId: ID
  ) {
    saveFullFormularyTemplate(name: $name, settings: $settings, templateId: $templateId) {
      id
      name
      settings {
        ... on FullFormularyTemplateSettings {
          font
          indexFontSize
          indexFooterBase64
          indexHeaderBase64
          indexNumberOfColumns
          indexTitle
          indexTitleTextAlignment
          legendFooterBase64
          legendHeaderBase64
          legendShowLimitations
          legendShowTiers
          showIndex
          tableCellsFontSize
          tableFooterBase64
          tableGroupDrugsByName
          tableHeaderBase64
          tableHeadingBackgroundColor
          tableHeadingFontSize
          tableShowColumnDividers
          tableShowGroupDosage
          tableShowRowDividers
          marginSettings {
            left
            bottom
            right
            top
            units
          }
          descriptionColumnSettings {
            position
            title
            width
          }
          editsColumnSettings {
            hidden
            position
            showDetails
            title
            width
          }
          tierColumnSettings {
            hidden
            position
            rankPrefix
            showDescription
            showRank
            title
            width
          }
        }
      }
    }
  }
`;
export type UpdateFormularyTemplateInput = {
  templateId: string;
  name: string;
  settings: APIFormularyTemplateSettings;
};

export const PREVIEW_FORMULARY_TEMPLATE = gql`
  query PreviewFormularyTemplate($settings: InputFullFormularyTemplate!) {
    templatePreview {
      fullFormulary(settings: $settings) {
        preview
      }
    }
  }
`;
export type PreviewFormularyTemplateResponse = {
  templatePreview: {
    fullFormulary: {
      preview: string;
    };
  };
};
export type PreviewFormularyTemplateInput = {
  settings: APIFormularyTemplateSettings;
};

export const useFormularyTemplatePreview = (initMessage: JSX.Element | string) => {
  const [previewTemplate, { loading, data }] = useLazyQuery<
    PreviewFormularyTemplateResponse,
    PreviewFormularyTemplateInput
  >(PREVIEW_FORMULARY_TEMPLATE, { fetchPolicy: "network-only" });

  const [previewStatus, setPreviewStatus] = React.useState<PreviewStatus>({
    type: "init",
    content: initMessage,
  });

  React.useEffect(() => {
    if (loading === true && previewStatus.type !== "loading") {
      setPreviewStatus({ type: "loading" });
    } else if (loading === false && previewStatus.type === "loading" && data?.templatePreview) {
      setPreviewStatus({
        type: "preview",
        base64Content: data.templatePreview.fullFormulary.preview,
      });
    }
  }, [data, loading, previewStatus.type]);

  const previewFormularyTemplate = (apiTemplateSettings: APIFormularyTemplateSettings) => {
    previewTemplate({
      variables: {
        settings: apiTemplateSettings,
      },
    });
  };

  return { previewStatus, previewFormularyTemplate };
};
