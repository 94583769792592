import { Button, FormControl, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import UploadLogoIcon from "@material-ui/icons/AddPhotoAlternateRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import * as React from "react";
import { ChromePicker } from "react-color";
import { UploadButton } from "../../components/buttons/UploadButton";
import { ConfigOptionsTable } from "../../components/tables/ConfigOptionsTable";
import { checkColorContrasts, hexToRGBConverter } from "../../utils/Color";
import { saveFile } from "../../utils/Files";
import { IsViewingTemplateContext } from "../isEditingTemplateContext";
import { TemplateToolbar } from "../TemplateToolbar";
import { FullScreenEditorDialog } from "./Editor/FullScreenEditorDialog";
import { WebSearchTemplate } from "./types";

const useStyles = makeStyles((theme) => ({
  colorCircle: (color: { r: string; g: string; b: string; a: string }) => ({
    width: "30px",
    height: "30px",
    borderRadius: "30px",
    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  }),
  swatch: {
    padding: "5px",
    background: "#fff",
    borderRadius: "30px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
    height: "100%",
  },
  settingsArea: {
    gridColumn: "span 6",
    alignSelf: "start",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    margin: theme.spacing(2),
  },
  formContainer: {
    padding: theme.spacing(2),
  },
  primaryColorHolder: {
    display: "flex",
    justifyContent: "space-between",
  },
  fullWidth: {
    width: "100%",
  },
  templateName: {
    maxWidth: "100%",
    marginBottom: 20,
    display: "inline-flex",
    alignItems: "flex-start",
    "&>p": {
      color: theme.palette.error.main,
    },
  },
  templateNameWithEditButton: {
    maxWidth: "100%",
    marginBottom: 20,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
  },
  errorArea: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  centeredContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type Props = {
  template: WebSearchTemplate;
  isSaving: boolean;
  onUpdateTemplate: (template: WebSearchTemplate) => void;
  onBackButtonClick: () => void;
  saveTemplate: () => void;
};

export const WebSearchTemplateForm: React.FC<Props> = (props) => {
  const [colorPicker, setColorPicker] = React.useState(
    initialPrimaryColor(props.template.settings.primaryColor)
  );
  const classes = useStyles(colorPicker.color);

  const isViewOnly = React.useContext(IsViewingTemplateContext);

  const [contrastError, setContrastError] = React.useState<string | undefined>(undefined);
  const [isEditorDialogOpen, setIsEditorDialogOpen] = React.useState(false);

  const handleColorPickerClick = () => {
    if (!isViewOnly) {
      setColorPicker({
        ...colorPicker,
        displayColorPicker: !colorPicker.displayColorPicker,
      });
    }
  };

  const handleCloseColorPicker = (): void => {
    setColorPicker({ ...colorPicker, displayColorPicker: false });
  };

  const handleChange = (color: any): void => {
    setContrastError(undefined);
    setColorPicker({
      ...colorPicker,
      color: color.rgb,
    });
    props.onUpdateTemplate({
      ...props.template,
      settings: { ...props.template.settings, primaryColor: color.hex },
    });
    const wcagCheck = checkColorContrasts(color.hex);
    if (wcagCheck.backgroundColorNonCompliant || wcagCheck.textColorNonCompliant) {
      setContrastError(`Sorry, color ${wcagCheck.backgroundColor} does not meet accessibility standards. 
      Some elements on the page may be difficult to read.  
      Please choose another color.`);
    }
  };

  const templateNameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdateTemplate({ ...props.template, name: event.target.value });
  };

  const uploadLogo = async (file: File) => {
    try {
      const uploadPath = await saveFile({ fileType: { type: "web search" }, file });
      props.onUpdateTemplate({
        ...props.template,
        settings: { ...props.template.settings, logoPath: uploadPath },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const removeLogo = () => {
    props.onUpdateTemplate({
      ...props.template,
      settings: { ...props.template.settings, logoPath: null },
    });
  };

  // TODO - inline
  const chromePickerWrapper = (
    <div style={{ position: "absolute", zIndex: 2, margin: "36px 0 0 0" }}>
      <div
        style={{
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        }}
        onClick={handleCloseColorPicker}
      />
      <ChromePicker
        color={`rgba(${colorPicker.color.r}, ${colorPicker.color.g}, ${colorPicker.color.b}, ${colorPicker.color.a})`}
        onChange={handleChange}
        disableAlpha={true}
      />
    </div>
  );

  const isTemplateValidToSave = Boolean(
    props.template.name &&
      props.template.settings.primaryColor &&
      props.template.settings.logoPath &&
      !contrastError &&
      props.template.name.length <= 200
  );

  return (
    <>
      <div className={classes.settingsArea}>
        <TemplateToolbar
          isValid={isTemplateValidToSave}
          isSaving={props.isSaving}
          isViewOnly={isViewOnly}
          onBack={props.onBackButtonClick}
          onSave={props.saveTemplate}
        />
        <div className={classes.container}>
          <TextField
            fullWidth
            label={<Typography>Template Name</Typography>}
            disabled={isViewOnly}
            type="text"
            name="templateName"
            className={classes.templateName}
            onChange={templateNameChangeHandler}
            value={props.template.name}
            inputProps={{
              style: { textOverflow: "ellipsis" },
            }}
            helperText={
              props.template.name === ""
                ? "Cannot be blank"
                : props.template.name.length >= 201
                ? "Exceeds maximum length of 200 characters"
                : ""
            }
          />
          <Paper className={classes.formContainer}>
            <ConfigOptionsTable
              settings={[
                {
                  label: "Primary Color",
                  options: (
                    <FormControl className={classes.fullWidth} disabled={isViewOnly}>
                      <div className={classes.primaryColorHolder} onClick={handleColorPickerClick}>
                        <TextField
                          disabled={isViewOnly}
                          value={props.template.settings.primaryColor}
                          style={{ padding: 5 }}
                        />
                        {colorPicker.displayColorPicker ? chromePickerWrapper : null}
                        <div className={classes.swatch}>
                          <div className={classes.colorCircle} />
                        </div>
                      </div>
                    </FormControl>
                  ),
                  hasError: Boolean(contrastError),
                },
                {
                  label: "Logo image",
                  options: (
                    <UploadButton
                      fileType="image/*"
                      disabled={isViewOnly}
                      fileName={fileName(props.template.settings.logoPath || "")}
                      handleUpload={uploadLogo}
                      onRemoveFile={removeLogo}
                      buttonIcon={<UploadLogoIcon />}
                    />
                  ),
                },
                {
                  label: "Landing page text",
                  optional: true,
                  options: (
                    <div className={classes.centeredContainer}>
                      <Button
                        color="primary"
                        disabled={isViewOnly}
                        variant="outlined"
                        onClick={() => {
                          setIsEditorDialogOpen(true);
                        }}
                        startIcon={<EditIcon />}
                      >
                        edit
                      </Button>
                    </div>
                  ),
                },
              ]}
            />
          </Paper>
          {contrastError && (
            <div className={classes.errorArea}>
              <ErrorIcon fontSize="large" color="error" />
              <Typography variant="body2" color="error">
                {contrastError}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <FullScreenEditorDialog
        isOpen={isEditorDialogOpen}
        editorText={decodeURIComponent(props.template.settings.landingPageInfoText ?? "")}
        onClickClose={() => {
          setIsEditorDialogOpen(false);
        }}
        onClickUpdate={(content: string) => {
          props.onUpdateTemplate({
            ...props.template,
            settings: { ...props.template.settings, landingPageInfoText: content },
          });
          setIsEditorDialogOpen(false);
        }}
      />
    </>
  );
};

const initialPrimaryColor = (color?: string) => {
  if (color) {
    const [r, g, b] = hexToRGBConverter(color);
    return {
      displayColorPicker: false,
      color: {
        r: r.toString(),
        g: g.toString(),
        b: b.toString(),
        a: "1",
      },
    };
  } else {
    return DEFAULT_COLOR_PICKER_SETTINGS;
  }
};

const DEFAULT_COLOR_PICKER_SETTINGS = {
  displayColorPicker: false,
  color: {
    r: "0",
    g: "0",
    b: "0",
    a: "1",
  },
};

const fileName = (filePath: string | null) => {
  if (filePath) {
    const pathSections = filePath.split("/");
    return pathSections[pathSections.length - 1];
  } else {
    return "";
  }
};
