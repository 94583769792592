import { ApolloError, useApolloClient, useMutation } from "@apollo/client";
import {
  Button,
  Collapse,
  Divider,
  fade,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import DenseIcon from "@material-ui/icons/ReorderRounded";
import NormalIcon from "@material-ui/icons/ViewStreamRounded";
import { AgGridEvent, ColDef, GridApi } from "ag-grid-community";
import * as React from "react";
import ReactDOMServer from "react-dom/server";
import { SaveFab } from "../../../components/buttons/SaveFab";
import { AlertDialog } from "../../../components/dialogs/AlertDialog";
import { ConfirmationDialog } from "../../../components/dialogs/ConfirmationDialog";
import { PlanStyledAgGrid } from "../../../components/tables/dataTables/PlanStyledAgGrid";
import { UserContext } from "../../../components/UserContext";
import {
  ListHierarchiesResponse,
  LIST_HIERARCHIES,
  PlanAccount,
  PlanCarrier,
  PlanGroup,
  UpdatePlanInput,
  UpdatePlanResponse,
  UPDATE_PLAN,
} from "../../api";
import { APIPlan, APIPlanHierarchy } from "../../types";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: `${theme.spacing(2)}px auto`,
    height: `calc(100% - ${theme.spacing(4)}px)`,
    width: "60%",
  },
  gridWrapper: {
    height: `calc(100% - 57px)`,
  },
  controlBar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    "&& p": {
      padding: theme.spacing(),
      margin: theme.spacing(),
    },
  },
  controlBarButton: {
    margin: theme.spacing(),
    marginTop: theme.spacing() + 2, //offset from loading bar
    "&:hover": {
      background: fade(theme.palette.common.white, 0.2),
    },
  },
  overlayPaper: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "fit-content",
  },
}));

let TABLE_SIZE = "dense";

interface Props {
  planId: string;
  cags: Array<APIPlanHierarchy> | null;
  readonly?: boolean;
  onUpdate: (updatedPlan: APIPlan) => void;
}

export function PlanHierarchy(props: Props): JSX.Element {
  const classes = useStyles();
  const Apollo = useApolloClient();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [isTableLoading, setIsTableLoading] = React.useState(false);
  const [gridApi, setGridApi] = React.useState<GridApi | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<ApolloError | undefined>(undefined);

  const readonly = props.readonly ?? false;

  const [updatePlan, { loading: isUpdating }] = useMutation<UpdatePlanResponse, UpdatePlanInput>(
    UPDATE_PLAN,
    {
      onCompleted: (res) => {
        if (gridApi) {
          gridApi.hideOverlay();
        }
        props.onUpdate(res.updatePlan);
      },
      onError: (err) => {
        console.error(err);
        setPageErrors(err);
      },
    }
  );

  React.useEffect(() => {
    if (contentSpacing) {
      TABLE_SIZE = contentSpacing;
    }
  }, [contentSpacing]);

  //#region Grid Functions

  function groupsFromPlanCarriers(carriers: Array<PlanCarrier>, cagGroupIds: Set<string>) {
    const groupArray: Array<
      PlanGroup & Omit<PlanAccount, "planGroups"> & Omit<PlanCarrier, "planAccounts">
    > = [];

    carriers.forEach((carrier) => {
      carrier.planAccounts.forEach((account) => {
        account.planGroups.forEach((group) => {
          if ((readonly && cagGroupIds.has(group.groupId)) || !readonly) {
            groupArray.push({
              groupCode: group.groupCode,
              groupName: group.groupName,
              groupId: group.groupId,
              accountCode: account.accountCode,
              accountName: account.accountName,
              accountId: account.accountId,
              carrierCode: carrier.carrierCode,
              carrierName: carrier.carrierName,
              carrierId: carrier.carrierId,
            });
          }
        });
      });
    });

    return groupArray;
  }

  function getCAGs() {
    return Apollo.query<ListHierarchiesResponse>({
      query: LIST_HIERARCHIES,
    });
  }

  function onGridReady(params: AgGridEvent) {
    setGridApi(params.api);
    setIsTableLoading(true);
    params.api.showLoadingOverlay();
    getCAGs().then((res) => {
      if (res.data) {
        const cagGroupIds = new Set(props.cags?.map((cag) => cag.groupId));
        params.api.hideOverlay();
        params.api.setRowData(groupsFromPlanCarriers(res.data.planCAGs.items, cagGroupIds));
        setIsTableLoading(false);
        params.api.sizeColumnsToFit();

        if (!readonly) {
          params.api.forEachNode((node) => {
            if (cagGroupIds.has(node.data.groupId as string)) {
              params.api.selectNode(node, true);
            }
          });
        }
      } else {
        params.api.showNoRowsOverlay();
        setIsTableLoading(false);
      }
    });
  }

  function changeRowHeight(_: any) {
    if (TABLE_SIZE === "dense") {
      return 28;
    } else {
      return 48;
    }
  }

  const COL_DEFS: Array<ColDef> = [
    { field: "carrierCode", checkboxSelection: !readonly },
    { field: "carrierName" },
    { field: "accountCode" },
    { field: "accountName" },
    { field: "groupCode" },
    { field: "groupName" },
  ];

  //#endregion

  function handleSaveSelection() {
    if (gridApi) {
      const selection = gridApi.getSelectedNodes();
      const groupIds = selection.map((row) => {
        return row.data.groupId;
      });
      gridApi.showLoadingOverlay();
      updatePlan({
        variables: {
          id: props.planId,
          input: {
            groupIds,
          },
        },
      });
    }
  }

  function getConfirmDialogContent() {
    if (gridApi) {
      const selection = gridApi.getSelectedNodes();
      return (
        <>
          <Typography>{`Are you sure you want to save the following CAGs to this plan?`}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Group Code</TableCell>
                <TableCell>Group Name</TableCell>
                <TableCell>Account Code</TableCell>
                <TableCell>Account Name</TableCell>
                <TableCell>Carrier Code</TableCell>
                <TableCell>Carrier Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selection.map((row) => {
                const { data } = row;
                return (
                  <TableRow>
                    <TableCell>{data.groupCode}</TableCell>
                    <TableCell>{data.groupName}</TableCell>
                    <TableCell>{data.accountCode}</TableCell>
                    <TableCell>{data.accountName}</TableCell>
                    <TableCell>{data.carrierCode}</TableCell>
                    <TableCell>{data.carrierName}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      );
    }
  }

  function handleSelectSave() {
    setShowConfirmDialog(true);
  }

  return (
    <>
      <SaveFab isValidToSave={!readonly} isSaving={isUpdating} onClickSave={handleSelectSave} />
      <Paper className={classes.tableContainer}>
        <div style={{ height: 55 }}>
          <TableControlBar
            onTableSizeChange={() => {
              if (gridApi) {
                gridApi.resetRowHeights();
              }
            }}
          />
        </div>
        {isTableLoading || isUpdating ? (
          <LinearProgress color="primary" style={{ height: 2 }} />
        ) : (
          <Divider color="primary" className={classes.divider} />
        )}
        <div className={classes.gridWrapper}>
          <PlanStyledAgGrid
            animateRows
            rowSelection="multiple"
            rowMultiSelectWithClick
            columnDefs={COL_DEFS}
            getRowHeight={changeRowHeight}
            getRowNodeId={(data) => data.groupId}
            onGridReady={onGridReady}
            sideBar={{
              toolPanels: ["filters"],
              position: "left",
            }}
            overlayLoadingTemplate={ReactDOMServer.renderToString(
              <Paper className={classes.overlayPaper}>
                <Typography>Working...</Typography>
              </Paper>
            )}
          />
        </div>
      </Paper>
      <ConfirmationDialog
        isOpen={showConfirmDialog}
        onYesHandler={() => {
          setShowConfirmDialog(false);
          handleSaveSelection();
        }}
        onNoHandler={() => {
          setShowConfirmDialog(false);
        }}
        dialogTitle={"Save these CAGs?"}
        dialogContent={getConfirmDialogContent()}
      />
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error on this page. Please wait a moment and refresh or try again. If the
          problem persists, contact your administrator.
        </Typography>
        <Button
          className={classes.errorButton}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Divider />
          <Table className={classes.technicalDetailsTable}>
            <TableHead>
              <TableRow key="errorHead">
                <TableCell>
                  <Typography variant="body2" color="error">
                    {pageErrors?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageErrors?.graphQLErrors &&
                pageErrors.graphQLErrors.map((error, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2" color="error">
                        {error.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </>
  );
}

interface HierarchyTableControlBarProps {
  onTableSizeChange: () => void;
}

function TableControlBar(props: HierarchyTableControlBarProps): JSX.Element {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const [tableSizeState, setTableSizeState] = React.useState<string>(
    contentSpacing ? contentSpacing : TABLE_SIZE
  );

  function handleTableSizeSwitch() {
    if (TABLE_SIZE === "normal") {
      TABLE_SIZE = "dense";
      setTableSizeState("dense");
      props.onTableSizeChange();
    } else if (TABLE_SIZE === "dense") {
      TABLE_SIZE = "normal";
      setTableSizeState("normal");
      props.onTableSizeChange();
    }
  }

  return (
    <div className={classes.controlBar}>
      <div>
        <Typography color="inherit" variant="body2">
          Associate Plan to CAGs
        </Typography>
      </div>
      <div>
        <Button
          className={classes.controlBarButton}
          color="inherit"
          onClick={handleTableSizeSwitch}
          startIcon={tableSizeState === "dense" ? <DenseIcon /> : <NormalIcon />}
        >
          {`ROW SPACING: ${tableSizeState}`}
        </Button>
      </div>
    </div>
  );
}
