import {
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import * as React from "react";
import { UserContext } from "../UserContext";
import { OptionsTableSetting } from "./OptionTableTypes";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    border: 0,
  },
  labelCell: {
    borderRight: "1px solid lightgray",
    width: "60%",
  },
  cellErrorOutline: {
    outline: `2px solid ${theme.palette.error.main}`,
  },
  helpButton: {
    marginLeft: theme.spacing(1),
  },
  popover: {
    padding: theme.spacing(2),
    minWidth: 200,
    maxWidth: 500,
    minHeight: 50,
  },
  popoverHeader: {
    marginBottom: theme.spacing(1),
  },
}));

type Props = Readonly<{
  settings: Array<OptionsTableSetting>;
  disabled?: boolean;
}>;

export const ConfigOptionsTable: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [helpText, setHelpText] = React.useState<string>();

  const handleHelpClick = (helpText: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setHelpText(helpText);
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Table size={contentSpacing === "dense" ? "small" : "medium"}>
        <TableBody>
          {props.settings.map((setting, index) => {
            return (
              <TableRow key={index} hover>
                <TableCell className={classNames(classes.tableCell, classes.labelCell)}>
                  <span>
                    <Typography style={{ opacity: props.disabled ? 0.5 : 1, display: "inline" }}>
                      {setting.label}
                    </Typography>
                    {setting.optional && (
                      <Typography
                        variant="subtitle1"
                        style={{ opacity: 0.5, display: "inline", fontStyle: "italic" }}
                      >
                        {" "}
                        - optional
                      </Typography>
                    )}
                  </span>
                  {setting.helpText && (
                    <IconButton
                      size="small"
                      className={classes.helpButton}
                      onClick={handleHelpClick(setting.helpText)}
                    >
                      <HelpIcon style={{ opacity: 0.5 }} />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell
                  className={classNames(classes.tableCell, {
                    [classes.cellErrorOutline]: setting.hasError,
                  })}
                >
                  {setting.options}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleHelpClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <Typography color="primary" variant="body2" className={classes.popoverHeader}>
          What is this?
        </Typography>
        <Typography>{helpText}</Typography>
      </Popover>
    </>
  );
};
