import { GraphQLResult } from "@aws-amplify/api/lib/types";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLError } from "graphql";

export interface GraphQLTypedResponse<T extends object> extends GraphQLResult {
  data?: T;
  errors?: [GraphQLError];
}

export const executeQuery = async <T extends object>(
  queryStringTemplate: string,
  payload?: object
): Promise<GraphQLTypedResponse<T>> =>
  API.graphql(graphqlOperation(queryStringTemplate, payload)) as GraphQLTypedResponse<T>;

export const typedKeys = <T extends object>(obj: T) => Object.keys(obj) as (keyof T)[];

export const toAwsDateStr = (date?: Date): string | undefined =>
  date ? date.toISOString().substring(0, 10) : undefined;

export const fromAwsDateStr = (dateStr?: string | null): Date | undefined =>
  dateStr ? new Date(`${dateStr}T00:00`) : undefined;
