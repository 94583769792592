import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { Route, Switch } from "react-router";
import { NotFound } from "../../components/screens/404";
import { UserContext } from "../../components/UserContext";
import { getAppTheme } from "../../styles/Theme";
import { AppSections, ListSections } from "../../types/AppTypes";
import { isAppSubSectionEnabled } from "../../utils/FeatureFlags";
import { TierListsLandingPage } from "./../DrugLists/TierListsLandingPage";
import { ManageAlternativesDrugList } from "./AlternativeLists/AlternativesListsPageIndex";
import { AlternativesListsLandingPage } from "./AlternativesListsLandingPage";
import { ListTypeRoute } from "./DrugListRouteUtils";
import { ManageEditDrugList } from "./EditLists/EditListPageIndex";
import { EditListsLandingPage } from "./EditListsLandingPage";
import { ManagePricingDrugList } from "./PricingLists/PricingListPageIndex";
import { PricingListsLandingPage } from "./PricingListsLandingPage";
import { ManageTierDrugList } from "./TierLists/TierListPageIndex";

export const DrugListRoutes: React.FC = () => {
  const {
    user: { settings },
  } = React.useContext(UserContext);

  return (
    <ThemeProvider theme={getAppTheme(AppSections.DRUG_LISTS, settings.textSize)}>
      <Switch>
        {/* Tier Lists */}
        {isAppSubSectionEnabled(ListSections.TIER) && (
          <Route path={ListTypeRoute.TIER} exact={true}>
            <TierListsLandingPage />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.TIER) && (
          <Route path={`${ListTypeRoute.TIER}/new`} exact={true}>
            <ManageTierDrugList />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.TIER) && (
          <Route path={`${ListTypeRoute.TIER}/edit/:drugListId`} exact={true}>
            <ManageTierDrugList />
          </Route>
        )}

        {/* Edit Lists */}
        {isAppSubSectionEnabled(ListSections.EDIT) && (
          <Route path={ListTypeRoute.EDIT} exact={true}>
            <EditListsLandingPage />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.EDIT) && (
          <Route path={`${ListTypeRoute.EDIT}/new`} exact={true}>
            <ManageEditDrugList />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.EDIT) && (
          <Route path={`${ListTypeRoute.EDIT}/edit/:drugListId`} exact={true}>
            <ManageEditDrugList />
          </Route>
        )}

        {/* Alternatives Lists */}
        {isAppSubSectionEnabled(ListSections.ALT) && (
          <Route path={ListTypeRoute.ALTERNATIVE} exact={true}>
            <AlternativesListsLandingPage />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.ALT) && (
          <Route path={`${ListTypeRoute.ALTERNATIVE}/new`} exact={true}>
            <ManageAlternativesDrugList />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.ALT) && (
          <Route path={`${ListTypeRoute.ALTERNATIVE}/edit/:drugListId`} exact={true}>
            <ManageAlternativesDrugList />
          </Route>
        )}

        {/* Pricing Lists */}
        {isAppSubSectionEnabled(ListSections.PRICE) && (
          <Route path={ListTypeRoute.PRICE} exact={true}>
            <PricingListsLandingPage />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.PRICE) && (
          <Route path={`${ListTypeRoute.PRICE}/new`} exact={true}>
            <ManagePricingDrugList />
          </Route>
        )}
        {isAppSubSectionEnabled(ListSections.PRICE) && (
          <Route path={`${ListTypeRoute.PRICE}/edit/:drugListId`} exact={true}>
            <ManagePricingDrugList />
          </Route>
        )}

        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};
