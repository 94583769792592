import { useQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import cn from "classnames";
import compareDesc from "date-fns/compareDesc";
import produce from "immer";
import * as React from "react";
import { Link } from "react-router-dom";
import { ErrorIcon } from "../../../../../components/Icons/ErrorIcon";
import { DragAndDropPriorityList } from "../../../../../components/lists/DragAndDropPriorityList";
import { CenteredCircularLoading } from "../../../../../components/loading/CenteredCircularLoading";
import { LabelSwitch } from "../../../../../components/switches/LabelSwitch";
import { OptionsTable } from "../../../../../components/tables/OptionsTable";
import { UserContext } from "../../../../../components/UserContext";
import { awsDateStringToDate, shortenedUIFormat } from "../../../../../utils/Date";
import {
  APIAlternativesList,
  GetAltListsResponse,
  GetPricingListsResponse,
  GET_ALT_LISTS,
  GET_PRICING_LISTS,
} from "../../../../DrugLists/api";
import { alternativesListRoute } from "../../../../DrugLists/DrugListRouteUtils";
import {
  FILTER_BY_TIER_HELP_TEXT,
  PRICING_LIST_HELP_TEXT,
  UNLISTED_DRUG_GROUP_BY_HELP_TEXT,
} from "../../../../helpText";
import { sharedStyles } from "../../../../styles";
import { FormularyEditingContext } from "../../FormularyEditingContext";
import { FormularyTier } from "../../types";
import {
  AlternativeGroupingType,
  AlternativesPriorityListItem,
  FDBGroupingType,
  FormularyAlternativesListConfig,
  GetFormularyAlternativesListConfigInput,
  GetFormularyAlternativesListConfigResponse,
  GET_FORMULARY_ALTERNATIVES_LIST_CONFIG,
  MSPANGroupingType,
  TierFilter,
} from "../api";

const useStyles = makeStyles((theme: Theme) => ({
  tabContent: {
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    height: "100%",
  },
  additionalSettingsContainer: {
    height: "fit-content",
  },
  drawer: {
    minWidth: 350,
    maxWidth: 500,
  },
  dragAndDropContainer: {
    marginLeft: `calc(150px + ${theme.spacing(2)}px)`,
  },
  linkButton: {
    paddingLeft: theme.spacing(1),
    textDecoration: "none",
  },
  cardContent: {
    paddingTop: 0,
  },
  effectiveDate: {
    marginBottom: "0.25rem",
  },
  terminationDate: {
    fontStyle: "italic",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  tierFilterOptionsError: {
    display: "flex",
    flexDirection: "row",
    marginTop: "0.25rem",
  },
  tierFilterOptionsErrorText: {
    marginLeft: "0.5rem",
  },
  tierAvatar: {
    width: "2em",
    height: "2em",
    background: "transparent",
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.text.primary}`,
    marginRight: theme.spacing(),
  },
  disabledIcon: {
    color: theme.palette.grey[500],
  },
  terminationDateWarning: {
    color: theme.palette.warning.main,
  },
  terminationDateError: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  formularyId: string;
  formularyTiers: Array<FormularyTier>;
  onSaveConfig: (config: FormularyAlternativesListConfig) => void;
}

export const FormularyAlternativesConfig: React.FC<Props> = (props) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const Theme = useTheme();

  const {
    user: {
      drugSourceShort,
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const isEditing = React.useContext(FormularyEditingContext);

  const [showAlternativesListsSlider, setShowAlternativesListsSlider] = React.useState(false);
  const [formularyAlternativesListConfig, setFormularyAlternativesListConfig] = React.useState<
    FormularyAlternativesListConfig | undefined
  >(undefined);

  const { data, loading } = useQuery<GetAltListsResponse>(GET_ALT_LISTS);
  const { data: pricingListData } = useQuery<GetPricingListsResponse>(GET_PRICING_LISTS);
  const { loading: isLoadingListConfig } = useQuery<
    GetFormularyAlternativesListConfigResponse,
    GetFormularyAlternativesListConfigInput
  >(GET_FORMULARY_ALTERNATIVES_LIST_CONFIG, {
    variables: { formularyId: props.formularyId },
    onCompleted: (res) => {
      setFormularyAlternativesListConfig(
        produce(res.formularyAlternativesConfig, (draft) => {
          const tierFilters: Array<TierFilter> =
            draft.unlistedDrugSettings.tierFilters.length > 0
              ? draft.unlistedDrugSettings.tierFilters
              : defaultTierFilters(props.formularyTiers);

          draft.unlistedDrugSettings.tierFilters = tierFilters.sort(compareByRank);
        })
      );
    },
    onError: (err) => {
      console.error(err);
      setFormularyAlternativesListConfig(defaultConfig(drugSourceShort, props.formularyTiers));
    },
  });

  const onAddListClick = () => {
    setShowAlternativesListsSlider(true);
  };

  const handleCloseDrawer = () => {
    setShowAlternativesListsSlider(false);
  };

  const handleAddAlternativesList = ({
    id,
    name,
    effectiveDate,
    terminationDate,
  }: APIAlternativesList) => () => {
    if (formularyAlternativesListConfig) {
      const updatedConfig: FormularyAlternativesListConfig = {
        ...formularyAlternativesListConfig,
        alternativesLists: formularyAlternativesListConfig.alternativesLists.concat({
          id,
          listName: name,
          effectiveDate: effectiveDate as string,
          terminationDate,
        }),
      };
      setFormularyAlternativesListConfig(updatedConfig);
      synchronizeUpdate(updatedConfig);
    }
    setShowAlternativesListsSlider(false);
  };

  const handleUpdatePriority = (prioritizedList: Array<AlternativesPriorityListItem>) => {
    if (formularyAlternativesListConfig) {
      const updatedConfig: FormularyAlternativesListConfig = {
        ...formularyAlternativesListConfig,
        alternativesLists: prioritizedList,
      };
      setFormularyAlternativesListConfig(updatedConfig);
      synchronizeUpdate(updatedConfig);
    }
  };

  const handleRemoveList = (idOfListToRemove: string) => () => {
    if (formularyAlternativesListConfig) {
      const updatedConfig: FormularyAlternativesListConfig = {
        ...formularyAlternativesListConfig,
        alternativesLists: formularyAlternativesListConfig.alternativesLists.filter(
          (list) => list.id !== idOfListToRemove
        ),
      };
      setFormularyAlternativesListConfig(updatedConfig);
      synchronizeUpdate(updatedConfig);
    }
  };

  const handleUpdateGroupBy = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (formularyAlternativesListConfig) {
      const updatedFormularyConfig = produce(formularyAlternativesListConfig, (draft) => {
        draft.unlistedDrugSettings.groupBy = event.target.value as AlternativeGroupingType;
      });
      setFormularyAlternativesListConfig(updatedFormularyConfig);
      synchronizeUpdate(updatedFormularyConfig);
    }
  };

  const handleUpdatePricingList = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (formularyAlternativesListConfig) {
      const updatedFormularyConfig = produce(formularyAlternativesListConfig, (draft) => {
        draft.unlistedDrugSettings.pricingListId = event.target.value as string;
      });
      setFormularyAlternativesListConfig(updatedFormularyConfig);
      synchronizeUpdate(updatedFormularyConfig);
    }
  };

  const pricingListOptions = (drugListId: string | null): Array<JSX.Element> => {
    const items: Array<JSX.Element> = [<MenuItem selected={!!drugListId}>None</MenuItem>];
    pricingListData?.lists?.items?.forEach((dl) => {
      items.push(
        <MenuItem key={dl.id} value={dl.id}>
          {dl.name}
        </MenuItem>
      );
    });

    return items;
  };

  const handleUpdateFilterByTier = () => {
    if (formularyAlternativesListConfig) {
      const updatedFormularyConfig = produce(formularyAlternativesListConfig, (draft) => {
        const filterByTier = !draft.unlistedDrugSettings.filterByTier;
        draft.unlistedDrugSettings.filterByTier = filterByTier;
        draft.unlistedDrugSettings.tierFilters = filterByTier
          ? defaultTierFilters(props.formularyTiers)
          : [];
      });
      setFormularyAlternativesListConfig(updatedFormularyConfig);
      synchronizeUpdate(updatedFormularyConfig);
    }
  };

  const handleUpdateMultiSelect = (updatedTierFilterName: string | null) => (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (formularyAlternativesListConfig) {
      const { value } = event.target;
      if (Array.isArray(value)) {
        const tiersToAdd = (value as Array<number>).map(
          (tierRankToAdd) =>
            props.formularyTiers.find((formularyTier) => formularyTier.rank === tierRankToAdd)!
        );
        const updatedFormularyConfig: FormularyAlternativesListConfig = {
          ...formularyAlternativesListConfig,
          unlistedDrugSettings: {
            ...formularyAlternativesListConfig.unlistedDrugSettings,
            tierFilters: formularyAlternativesListConfig.unlistedDrugSettings.tierFilters.map(
              (tierFilter) =>
                tierFilter.name === updatedTierFilterName
                  ? {
                      ...tierFilter,
                      matchingTiers: tiersToAdd,
                    }
                  : tierFilter
            ),
          },
        };
        setFormularyAlternativesListConfig(updatedFormularyConfig);
        synchronizeUpdate(updatedFormularyConfig);
      }
    }
  };

  const synchronizeUpdate = (updatedConfig: FormularyAlternativesListConfig) => {
    if (atLeastOneMatchedTier(updatedConfig.unlistedDrugSettings.tierFilters)) {
      props.onSaveConfig(updatedConfig);
    }
  };

  /**
   * The drawer should only be possible to open once the formulary alternatives list config has loaded
   */
  const drawerItems = (): Array<APIAlternativesList> => {
    // filter down the drawer items to those which do not match the
    if (formularyAlternativesListConfig && data) {
      const addedIds = new Set<string>(
        formularyAlternativesListConfig.alternativesLists.map((list) => list.id)
      );
      return data.lists.items.filter((list) => addedIds.has(list.id) === false);
    } else if (data) {
      return data.lists.items;
    } else {
      return [];
    }
  };

  const renderDrugCardContent = (item: AlternativesPriorityListItem) => {
    const parsedDate = item.terminationDate ? awsDateStringToDate(item.terminationDate) : null;
    const isListTerminated = parsedDate === null ? false : isTerminated(parsedDate);
    return (
      <>
        <CardHeader
          title={
            <Typography variant="h6" color={isListTerminated ? "textSecondary" : "primary"}>
              {item.listName}
            </Typography>
          }
          action={
            <IconButton
              className={isListTerminated ? classes.disabledIcon : undefined}
              aria-label="remove list"
              disabled={!isEditing}
              onClick={handleRemoveList(item.id)}
            >
              <ClearIcon />
            </IconButton>
          }
        />
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.effectiveDate}
            color={isListTerminated ? "textSecondary" : undefined}
          >
            Effective: {shortenedUIFormat(awsDateStringToDate(item.effectiveDate))}
          </Typography>
          {parsedDate && (
            <Typography
              className={cn(classes.terminationDate, {
                [classes.terminationDateError]: isListTerminated,
                [classes.terminationDateWarning]: !isListTerminated,
              })}
            >
              {isListTerminated
                ? `Terminated: ${shortenedUIFormat(parsedDate)}`
                : `Terminates on: ${shortenedUIFormat(parsedDate)}`}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Link to={alternativesListRoute(item.id)} className={classes.linkButton} target="_blank">
            <Button size="small" color="primary" disabled={isListTerminated}>
              View List
            </Button>
          </Link>
        </CardActions>
      </>
    );
  };

  const hasAtLeastOneMatchedTier = atLeastOneMatchedTier(
    formularyAlternativesListConfig?.unlistedDrugSettings.tierFilters
  );

  return (
    <>
      <div className={classes.tabContent}>
        {isLoadingListConfig || formularyAlternativesListConfig === undefined ? (
          <div style={{ height: "100%" }}>
            <CenteredCircularLoading />
          </div>
        ) : (
          <>
            <div className={classes.additionalSettingsContainer}>
              <Card className={sharedClasses.formularyCard}>
                <CardHeader title="Unlisted drug settings" />
                <Divider />
                <CardContent>
                  <OptionsTable
                    settings={[
                      {
                        label: "Group by",
                        helpText: UNLISTED_DRUG_GROUP_BY_HELP_TEXT,
                        options: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Select
                              value={formularyAlternativesListConfig.unlistedDrugSettings.groupBy}
                              disabled={!isEditing}
                              onChange={handleUpdateGroupBy}
                              style={{ width: 150 }}
                            >
                              {Object.values(
                                drugSourceShort === "FDB" ? FDBGroupingType : MSPANGroupingType
                              ).map((groupingType) => (
                                <MenuItem key={groupingType} value={groupingType}>
                                  {groupingType}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        ),
                      },
                      {
                        label: "Filter by tier",
                        helpText: FILTER_BY_TIER_HELP_TEXT,
                        options: (
                          <LabelSwitch
                            disabled={!isEditing}
                            switchProps={{
                              checked:
                                formularyAlternativesListConfig.unlistedDrugSettings.filterByTier,
                              onChange: handleUpdateFilterByTier,
                            }}
                          />
                        ),
                      },
                      {
                        label: "Pricing List",
                        helpText: PRICING_LIST_HELP_TEXT,
                        options: (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Select
                              value={
                                pricingListData?.lists.items.find(
                                  (dl) =>
                                    dl.id ===
                                    formularyAlternativesListConfig.unlistedDrugSettings
                                      .pricingListId
                                )?.id ?? ""
                              }
                              disabled={!isEditing}
                              onChange={handleUpdatePricingList}
                              style={{ width: 150 }}
                            >
                              {pricingListOptions(
                                formularyAlternativesListConfig.unlistedDrugSettings.pricingListId
                              )}
                            </Select>
                          </div>
                        ),
                      },
                    ]}
                  />
                </CardContent>
              </Card>
              {formularyAlternativesListConfig.unlistedDrugSettings.filterByTier && (
                <Card style={{ marginTop: "24px" }} className={sharedClasses.formularyCard}>
                  {hasAtLeastOneMatchedTier ? (
                    <CardHeader title="Tier filter options" />
                  ) : (
                    <CardHeader
                      title="Tier filter options"
                      subheader={
                        <div className={classes.tierFilterOptionsError}>
                          <ErrorIcon size="default" />
                          <span className={classes.tierFilterOptionsErrorText}>
                            Error: At least 1 Matched Tier required.
                          </span>
                        </div>
                      }
                      subheaderTypographyProps={{ color: "error" }}
                    />
                  )}

                  <Divider />
                  <CardContent>
                    <OptionsTable
                      settings={Array.from(
                        formularyAlternativesListConfig.unlistedDrugSettings.tierFilters
                      )
                        .sort(compareByRank)
                        .map((tierFilter) => {
                          return {
                            label: !!tierFilter.name
                              ? `Tier ${tierFilter.rank} ${tierFilter.name}`
                              : `NON-FORMULARY`,
                            options: (
                              <Select
                                disabled={!isEditing}
                                fullWidth
                                multiple
                                value={tierFilter.matchingTiers.map(
                                  (matchingFilter) => matchingFilter.rank
                                )}
                                onChange={handleUpdateMultiSelect(tierFilter.name)}
                                renderValue={(selected) => (
                                  <div className={classes.chips}>
                                    {(selected as string[]).map((value) => (
                                      <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                  </div>
                                )}
                                MenuProps={MenuProps}
                                style={{ minWidth: 150, maxWidth: 225 }}
                              >
                                {formularyAlternativesListConfig.unlistedDrugSettings.tierFilters
                                  .filter((tierFilter) => !!tierFilter.name)
                                  .map((tier) => (
                                    <MenuItem
                                      key={tier.id}
                                      disabled={!isEditing}
                                      value={tier.rank ?? undefined}
                                      style={getTiersSelectStyle(
                                        tier.name ?? "",
                                        tier.matchingTiers.map((matchedTier) => matchedTier.name),
                                        Theme
                                      )}
                                    >
                                      <Avatar className={classes.tierAvatar}>{tier.rank}</Avatar>
                                      {tier.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            ),
                          };
                        })}
                    />
                  </CardContent>
                </Card>
              )}
            </div>

            <div className={classes.dragAndDropContainer}>
              <DragAndDropPriorityList
                disabled={!isEditing}
                listData={formularyAlternativesListConfig.alternativesLists}
                onAddListItem={onAddListClick}
                onListDataPriorityChange={handleUpdatePriority}
                renderCardContent={renderDrugCardContent}
              />
            </div>
          </>
        )}
      </div>

      <Drawer
        PaperProps={{ className: classes.drawer }}
        anchor="right"
        open={showAlternativesListsSlider}
        onClose={handleCloseDrawer}
      >
        {loading ? (
          <CenteredCircularLoading />
        ) : (
          <List dense={contentSpacing === "dense"}>
            <ListSubheader>
              Select an Alternatives List to Add
              <Divider />
            </ListSubheader>
            {drawerItems().map((alternativeList) => (
              <ListItem
                button
                key={alternativeList.id}
                disabled={!isEditing}
                onClick={handleAddAlternativesList(alternativeList)}
              >
                <ListItemText>{alternativeList.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </Drawer>
    </>
  );
};

const defaultConfig = (
  drugSourceShort: "FDB" | "MSPAN",
  formularyTiers: Array<FormularyTier>
): FormularyAlternativesListConfig => {
  return {
    alternativesLists: [],
    unlistedDrugSettings: {
      filterByTier: false,
      groupBy:
        drugSourceShort === "FDB"
          ? FDBGroupingType.HIC3
          : drugSourceShort === "MSPAN"
          ? MSPANGroupingType.GPI6
          : AlternativeGroupingType.THERAPEUTIC,
      tierFilters: defaultTierFilters(formularyTiers),
      pricingListId: null,
    },
  };
};

const defaultTierFilters = (formularyTiers: Array<FormularyTier>): Array<TierFilter> => {
  const tiersSortedByRank = produce(formularyTiers, (draft) => {
    draft.sort(compareByRank);
  });
  const defaultTiers: Array<TierFilter> = tiersSortedByRank.map((tier, index) => ({
    ...tier,
    matchingTiers: tiersSortedByRank.slice(0, index + 1),
  }));
  defaultTiers.push({
    id: null,
    name: null,
    rank: null,
    matchingTiers: tiersSortedByRank,
  });
  return defaultTiers;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const getTiersSelectStyle = (tierName: string, selectedTiers: Array<string>, theme: Theme) => {
  return {
    fontWeight: selectedTiers.includes(tierName)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  };
};

function compareByRank<T extends { rank: number | null }>(a: T, b: T) {
  return (a.rank ?? Infinity) - (b.rank ?? Infinity);
}

const atLeastOneMatchedTier = (tierFilterOptions?: Array<TierFilter>): boolean => {
  return Boolean(
    tierFilterOptions &&
      (tierFilterOptions.length === 0 ||
        tierFilterOptions.some((tierFilter) => tierFilter.matchingTiers.length > 0))
  );
};

const isTerminated = (terminationDate: Date): boolean =>
  compareDesc(terminationDate, new Date()) === 1;
