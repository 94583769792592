enum COMMON_COLORS {
  BLACK = "#000000",
  WHITE = "#FFFFFF",
  BACKGROUND_DEFAULT = "#ECECEC",
}

export const hexToRGBConverter = (hexColor: string) => {
  const regEx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const match = /.{2}/g;
  const convertedColor: RegExpMatchArray | null = hexColor
    .replace(regEx, (r: string, g: string, b: string) => "#" + r + r + g + g + b + b)
    .substring(1)
    .match(match);
  if (convertedColor) {
    return convertedColor.map((x: string) => parseInt(x, 16));
  } else {
    return [0, 0, 0];
  }
};

export const invertColor = (hexColor: string) => {
  const L = luminance(hexColor);
  return L > 0.179 ? "#000000" : "#FFFFFF";
};

const luminance = (hexColor: string) => {
  const color = hexColor.charAt(0) === "#" ? hexColor.substring(1, 7) : hexColor;
  const red = parseInt(color.substring(0, 2), 16);
  const green = parseInt(color.substring(2, 4), 16);
  const blue = parseInt(color.substring(4, 6), 16);
  const c = [red, green, blue].map((v) => {
    const adjustedColor = v / 255;
    return adjustedColor <= 0.03928
      ? adjustedColor / 12.92
      : Math.pow((adjustedColor + 0.055) / 1.055, 2.4);
  });
  return c[0] * 0.2126 + c[1] * 0.7152 + c[2] * 0.0722;
};

/**
 * Determine the contrast ratio by comparing the luminance of two colors.
 *
 * sources:
 *  https://ux.stackexchange.com/questions/107318/formula-for-color-contrast-between-text-and-background
 *
 * The higher the luminance number the greater the intensity of the light of that color.
 * Contrast ratio is determined by: lighter color luminance / darker color luminance.
 * To calculate the darker and lighter color we look at the luminance values. The higher the luminance the brighter the color.
 */
const compareLuminance = (foregroundLuminance: number, backgroundLuminance: number) => {
  const lighterColor =
    foregroundLuminance > backgroundLuminance ? foregroundLuminance : backgroundLuminance;
  const darkerColor =
    lighterColor === foregroundLuminance ? backgroundLuminance : foregroundLuminance;
  return (lighterColor + 0.05) / (darkerColor + 0.05);
};

export type ContrastCheckResult = {
  backgroundColor: string;
  backgroundContrast: number;
  backgroundColorNonCompliant: boolean;
  textContrastColor: string;
  textContrast: number;
  textColorNonCompliant: boolean;
};

/**
 * Calculating Contrast
 *
 * resources
 *  https://webaim.org/resources/contrastchecker/
 *  https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
 *  https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
 *
 * WCAG 2.0 Level AA (Required for 508 Compliance)
 *  4.5:1 for normal text
 *  3:1 for large text
 *  3:1 for graphics and user interface components
 *
 * WCAG 2.0 Level AAA
 *  7:1 for normal text
 *  4.5:1 for large text
 */
export const checkColorContrasts = (hexColor: string): ContrastCheckResult => {
  const primaryLuminance = luminance(hexColor);
  const backgroundLuminance = luminance(COMMON_COLORS.BACKGROUND_DEFAULT);
  const backgroundContrast = compareLuminance(primaryLuminance, backgroundLuminance);
  const backgroundColorNonCompliant = backgroundContrast < 3;

  const textContrastColor = invertColor(hexColor);
  const textContrastLuminance = luminance(textContrastColor);
  const textContrast = compareLuminance(textContrastLuminance, primaryLuminance);
  const textColorNonCompliant = textContrast < 4.5;

  return {
    backgroundColor: hexColor,
    backgroundContrast,
    backgroundColorNonCompliant,
    textContrastColor,
    textContrast,
    textColorNonCompliant,
  };
};
