import { makeStyles, TextField } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { HideButton } from "../../../../components/buttons/HideButton";
import { LabelSwitchController } from "../../../../components/switches/LabelSwitchController";
import { ConfigOptionsTable } from "../../../../components/tables/ConfigOptionsTable";
import { TIERS_COLUMN } from "../../../FieldNameConstant";
import { TIER_RANK_PREFIX_HELP_TEXT } from "../../../helpText";
import { IsViewingTemplateContext } from "../../../isEditingTemplateContext";
import { FullFormularyTemplate } from "../../types";
import { ColumnSettingsForm } from "./ColumnSettingsForm";
import { ColumnWidthInput } from "./ColumnWidthInput";

const useStyles = makeStyles((theme) => ({
  formItem: {
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "0px",
    textAlign: "left",
    alignItems: "flex-start",
  },
  switchEdgeFix: {
    marginLeft: "-12px",
  },
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

type Props = {
  isActiveColumn: boolean;
  onHideColumn: () => void;
};

export const TiersColumnForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { getValues, setValue, errors, watch } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  watch(TIERS_COLUMN.SHOW_RANK);

  const [isHidden, setIsHidden] = React.useState(false);

  const hasMaxWidthError = Boolean(errors.settings?.tierColumnSettings?.width?.type === "max");
  const hasMinWidthError = Boolean(errors.settings?.tierColumnSettings?.width?.type === "min");
  const hasRequiredError = Boolean(errors.settings?.tierColumnSettings?.width?.type === "required");

  React.useEffect(() => {
    const formStateHidden = Boolean(getValues(TIERS_COLUMN.HIDDEN));
    if (formStateHidden !== isHidden) {
      setIsHidden(formStateHidden);
    }
  }, [isHidden, getValues]);

  return (
    <ColumnSettingsForm
      columnName="Tier"
      activeColumn={props.isActiveColumn}
      columnHidden={isHidden}
      topContent={
        <Controller
          as={
            <HideButton
              isHidden={isHidden}
              disabled={isViewOnly}
              onHide={() => {
                setIsHidden(true);
                props.onHideColumn();
              }}
              onShow={() => {
                setIsHidden(false);
                setValue(TIERS_COLUMN.HIDDEN, false);
              }}
            />
          }
          name={TIERS_COLUMN.HIDDEN}
        />
      }
    >
      {/* Added for to register values with react-hook-form for state management. Not registered w/ standard input to persist number type */}
      <div style={{ display: "none" }}>
        <Controller as={<TextField />} name={TIERS_COLUMN.POSITION} />
      </div>
      <ConfigOptionsTable
        settings={[
          {
            label: "Title",
            options: (
              <Controller
                as={
                  <TextField
                    disabled={isViewOnly}
                    style={{ width: "200px" }}
                    inputProps={{ style: { textOverflow: "ellipsis" } }}
                    InputProps={{ classes: { focused: classes.themedUnderline } }}
                  />
                }
                name={TIERS_COLUMN.TITLE}
              />
            ),
          },
          {
            label: "Column width",
            options: (
              <ColumnWidthInput
                disabled={isViewOnly}
                widthFieldName={TIERS_COLUMN.WIDTH}
                hasMaxWidthError={hasMaxWidthError}
                hasMinWidthError={hasMinWidthError}
                hasRequiredError={hasRequiredError}
              />
            ),
          },
          {
            label: "Display tier rank",
            options: (
              <LabelSwitchController
                disabled={isViewOnly}
                isOn={getValues(TIERS_COLUMN.SHOW_RANK) || true}
                name={TIERS_COLUMN.SHOW_RANK}
              />
            ),
          },
          {
            label: "Display tier description",
            options: (
              <LabelSwitchController
                disabled={isViewOnly}
                isOn={getValues(TIERS_COLUMN.SHOW_DESCRIPTION) || true}
                name={TIERS_COLUMN.SHOW_DESCRIPTION}
              />
            ),
          },
          {
            label: "Tier rank prefix",
            helpText: TIER_RANK_PREFIX_HELP_TEXT,
            options: (
              <Controller
                as={
                  <TextField
                    className={classes.formItem}
                    style={{ width: "200px" }}
                    inputProps={{ style: { textOverflow: "ellipsis" } }}
                    InputProps={{ classes: { focused: classes.themedUnderline } }}
                    disabled={isViewOnly || !Boolean(getValues(TIERS_COLUMN.SHOW_RANK))}
                  />
                }
                name={TIERS_COLUMN.RANK_PREFIX}
              />
            ),
          },
        ]}
      ></ConfigOptionsTable>
    </ColumnSettingsForm>
  );
};
