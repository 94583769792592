import {
  Card,
  CardContent,
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ChevronRightRounded";
import ExpandMore from "@material-ui/icons/ExpandMoreRounded";
import * as React from "react";
import { NextAccount, NextSubAccount } from "../../AccountArea/types";

const useStyles = makeStyles((theme) => ({
  subaccountList: {
    background: "white",
  },
  listItem: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
  root: {
    width: "100%",
  },
  rootContent: {
    padding: "0 !important",
  },
}));

type Props = {
  accounts: Array<NextAccount>;
  currentSubAccountId: string;
  onSelectSubaccount: (subAccountId: string) => void;
};

export const SubaccountSelectionList: React.FC<Props> = ({
  accounts,
  currentSubAccountId,
  onSelectSubaccount,
}) => {
  const classes = useStyles();

  const [selectedAccountId, setSelectedAccountId] = React.useState<string | null>(
    accounts.find((account) =>
      account.subAccounts.items.some((subAccount) => subAccount.id === currentSubAccountId)
    )?.id ?? null
  );

  const children: Array<JSX.Element> = [];

  if (accounts.length > 1) {
    children.push(
      ...accounts.map((account, index) => (
        <React.Fragment key={`account-${index}`}>
          <AccountItem
            account={account}
            selectedSubAccountId={currentSubAccountId}
            isExpanded={account.id === selectedAccountId}
            setExpandedAccount={setSelectedAccountId}
            setSelectedSubAccount={onSelectSubaccount}
          />
        </React.Fragment>
      ))
    );
  } else if (accounts.length === 1) {
    children.push(
      <SubAccountList
        subaccounts={accounts?.[0].subAccounts.items ?? []}
        setSelectedSubAccount={onSelectSubaccount}
        selectedSubAccountId={currentSubAccountId}
      />
    );
  } else {
    return <Typography variant="h6">No Accounts Available</Typography>;
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.rootContent} children={children} />
    </Card>
  );
};

type SubAccountListProps = {
  subaccounts: Array<NextSubAccount>;
  setSelectedSubAccount: (subAccountId: string) => void;
  selectedSubAccountId: string | null;
};

const SubAccountList: React.FC<SubAccountListProps> = ({
  subaccounts,
  setSelectedSubAccount,
  selectedSubAccountId,
}) => {
  const classes = useStyles();

  return (
    <List className={classes.subaccountList} disablePadding>
      {subaccounts.map((subaccount) => (
        <AccountListItem
          accountName={subaccount.name}
          selected={subaccount.id === selectedSubAccountId}
          className={classes.listItem}
          classes={{ selected: classes.selected }}
          key={subaccount.id}
          onClick={() => setSelectedSubAccount(subaccount.id)}
        />
      ))}
    </List>
  );
};

type AccountItemProps = {
  account: NextAccount;
  isExpanded: boolean;
  setExpandedAccount: (accountId: string) => void;
  setSelectedSubAccount: (subAccountId: string) => void;
  selectedSubAccountId: string | null;
};

const AccountItem: React.FC<AccountItemProps> = ({
  account,
  isExpanded,
  setExpandedAccount,
  selectedSubAccountId,
  setSelectedSubAccount,
}) => {
  return (
    <>
      <AccountListItem accountName={account.name} onClick={() => setExpandedAccount(account.id)}>
        {isExpanded ? <ExpandMore /> : <ExpandLess />}
      </AccountListItem>
      <Collapse in={isExpanded}>
        <SubAccountList
          subaccounts={account.subAccounts.items}
          selectedSubAccountId={selectedSubAccountId}
          setSelectedSubAccount={setSelectedSubAccount}
        />
      </Collapse>
    </>
  );
};

type AccountListItemProps = {
  accountName: string;
  onClick: () => void;
  selected?: boolean;
  className?: string;
  classes?: Record<string, string>;
};

const AccountListItem: React.FC<AccountListItemProps> = ({
  accountName,
  onClick,
  children,
  selected,
  className,
  classes,
}) => {
  return (
    <ListItem button onClick={onClick} selected={selected} className={className} classes={classes}>
      <ListItemText
        primary={accountName}
        primaryTypographyProps={{
          variant: "body2",
          color: "inherit",
        }}
      />
      {children}
    </ListItem>
  );
};
