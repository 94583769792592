import Auth from "@aws-amplify/auth";
import { InMemoryCache, ApolloClient, ApolloLink } from "@apollo/client";
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { AWS_GQL_ENDPOINT } from "./aws-exports";

const AWSApolloClientConfig = {
  url: AWS_GQL_ENDPOINT,
  region: "us-east-1",
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as "AMAZON_COGNITO_USER_POOLS",
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
};

export const AWSApolloClient = new ApolloClient({
  link: ApolloLink.from([
    /**
     * Custom link to strip off __typename from queries and mutations
     */
    new ApolloLink((operation, forward) => {
      if (operation.variables) {
        const omitTypename = (key: string, value: any) =>
          key === "__typename" ? undefined : value;
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
      }
      return forward(operation).map((data) => data);
    }),
    createAuthLink(AWSApolloClientConfig),
    createSubscriptionHandshakeLink(AWSApolloClientConfig),
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  /**
   * TODO - switch caching behavior.
   *  With the `fetchPolicy: "no-cache"` option we are not utilizing the cache for any operation with Apollo and
   *  missing out on some benefits of the library including improved loading times. Later when more time is availble we can
   *  return to this decision and adjust the caching behavior when we have the time to also fix some downstream issues from that config.
   */
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});
