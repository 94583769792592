import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Edit from "@material-ui/icons/EditRounded";
import GroupBySettingsIcon from "@material-ui/icons/SettingsRounded";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BasicDialog } from "../../../components/dialogs/BasicDialog";
import { TABLE_SETTINGS } from "../../FieldNameConstant";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";
import { FullFormularyTemplate, ShowDosage } from "../types";

const useStyles = makeStyles({
  switchEdgeFix: {
    marginLeft: "-12px",
  },
  table: {
    minWidth: 500,
  },
  tableSectionDivider: {
    marginTop: "20px",
    marginBottom: "25px",
  },
  tableTitle: {
    lineHeight: 2,
  },
});

export const TableGroupBy: React.FC = () => {
  const classes = useStyles();

  const { getValues, register, setValue } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [tableGroupBy, setTableGroupBy] = React.useState<ShowDosage>(
    ShowDosage.WHEN_DRUG_IS_SPLIT_BETWEEN_ROWS
  );

  React.useEffect(() => {
    const showDosage = getValues(TABLE_SETTINGS.SHOW_GROUP_DOSAGES);
    if (showDosage !== tableGroupBy) {
      setTableGroupBy(showDosage as ShowDosage);
    }
  }, [tableGroupBy, getValues]);

  const generateTableBody = () => {
    const rowData =
      (tableGroupBy || getValues(TABLE_SETTINGS.SHOW_GROUP_DOSAGES)) === ShowDosage.ON_ALL_DRUGS
        ? ON_ALL_DRUGS_ROWS
        : WHEN_DRUG_IS_SPLIT_BETWEEN_ROWS_ROWS;

    return rowData.map((rowData, index) => (
      <TableRow key={index}>
        <TableCell>{rowData.description}</TableCell>
        <TableCell>{rowData.tier}</TableCell>
        <TableCell>{rowData.edits}</TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <Controller
        as={
          <Switch color="primary" disabled={isViewOnly} classes={{ root: classes.switchEdgeFix }} />
        }
        name={TABLE_SETTINGS.GROUP_DRUGS_BY_NAME}
        onChange={([event]) => {
          setIsDisabled(!event.target.checked);
          return event.target.checked;
        }}
      />
      <IconButton
        disabled={isViewOnly || isDisabled}
        size="small"
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <GroupBySettingsIcon />
      </IconButton>

      <div style={{ display: "none" }}>
        <input
          type="radio"
          name={TABLE_SETTINGS.SHOW_GROUP_DOSAGES}
          value={ShowDosage.ON_ALL_DRUGS}
          ref={register}
        />
        <input
          type="radio"
          name={TABLE_SETTINGS.SHOW_GROUP_DOSAGES}
          value={ShowDosage.WHEN_DRUG_IS_SPLIT_BETWEEN_ROWS}
          ref={register}
        />
      </div>

      <BasicDialog
        isOpen={isDialogOpen}
        dialogIcon={<Edit />}
        dialogTitle="Group by Settings"
        closeButtonText={"OK"}
        onExitHandler={() => {
          setDialogOpen(false);
        }}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">Show Dosage...</FormLabel>
          <RadioGroup
            name="Group by Options"
            aria-label="eee"
            value={tableGroupBy}
            onChange={(_, value) => {
              setValue(TABLE_SETTINGS.SHOW_GROUP_DOSAGES, value);
              setTableGroupBy(value as ShowDosage);
            }}
          >
            <FormControlLabel control={<Radio />} label="Always" value={ShowDosage.ON_ALL_DRUGS} />
            <FormControlLabel
              control={<Radio />}
              label="Only When Drugs are Split Between Rows"
              value={ShowDosage.WHEN_DRUG_IS_SPLIT_BETWEEN_ROWS}
            />
          </RadioGroup>
        </FormControl>
        <Divider variant="fullWidth" className={classes.tableSectionDivider} />
        <Typography variant="h5" className={classes.tableTitle}>
          Example:
        </Typography>
        <Paper>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Tiers</TableCell>
                <TableCell>Edits</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{generateTableBody()}</TableBody>
          </Table>
        </Paper>
      </BasicDialog>
    </>
  );
};

const ON_ALL_DRUGS_ROWS = [
  { description: "Humira (5mg, 10mg, 15mg)", tier: "1", edits: "QL" },
  { description: "Abilify (5mg, 10mg, 15mg)", tier: "1", edits: "PA" },
  { description: "Abilify (20mg, 30mg)", tier: "2", edits: "QL" },
];

const WHEN_DRUG_IS_SPLIT_BETWEEN_ROWS_ROWS = [
  { description: "Humira", tier: "1", edits: "QL" },
  { description: "Abilify (5mg, 10mg, 15mg)", tier: "1", edits: "PA" },
  { description: "Abilify (20mg, 30mg)", tier: "2", edits: "QL" },
];
