import gql from "graphql-tag";

export const LIST_FORMULARIES = gql`
  query listFormularies {
    formularies {
      items {
        id
        name
        lineOfBusiness
        effectiveDate
        isMedicare
        externalId
        terminationDate
        hasLegacyPlan
      }
    }
  }
`;
export type ListFormulariesResponse = {
  formularies: {
    items: Array<APIFormularyItem>;
  };
};
export type APIFormularyItem = {
  id: string;
  name: string;
  lineOfBusiness: string;
  effectiveDate: string;
  terminationDate: string | null;
  isMedicare: boolean;
  externalId: string | null;
  hasLegacyPlan: boolean;
};

export const DELETE_FORMULARY = gql`
  mutation DeleteFormulary($id: ID!) {
    deleteFormulary(id: $id) {
      id
      name
    }
  }
`;
export type DeleteFormularyInput = {
  id: string;
};
export type DeleteFormularyResponse = {
  deleteFormulary: {
    id: string;
    name: string;
  };
};
