import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@material-ui/styles";
import { withAuthenticator } from "aws-amplify-react";
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import { GeneralErrorPage } from "./components/screens/broken";
import * as Config from "./Config";
import { Login } from "./Login/Login";
import * as serviceWorker from "./serviceWorker";
import { baseTheme } from "./styles/Theme";
import { asyncLocalStorage, TEXT_SIZE } from "./utils/localStorageUtils";
import "./index.css";
import "./components/tables/dataTables/planTableStyles.scss";
import { BroadcastProvider } from "./components/BroadcastProvider";

/**
 * This file models the highest level of the application and handles
 *  - config required for plugging various libraries into react
 *  - mounting the component tree to the dom
 */

serviceWorker.unregister();

const renderWithAllFeatures = async (AppComponent: React.ComponentType) => {
  try {
    const WithAuthenticator = withAuthenticator(AppComponent, false, [<Login />], null);
    const textSize = await asyncLocalStorage.getItem(TEXT_SIZE);

    ReactDOM.render(
      <ApolloProvider client={Config.ApolloConfig.AWSApolloClient}>
        <ThemeProvider theme={baseTheme(textSize)}>
          <BrowserRouter>
            <BroadcastProvider>
              <WithAuthenticator />
            </BroadcastProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>,
      document.getElementById("root")
    );
  } catch (e) {
    console.error(e);
    ReactDOM.render(<GeneralErrorPage />, document.getElementById("root"));
  }
};

renderWithAllFeatures(App);
