import { CircularProgress, makeStyles, Tab, Tabs } from "@material-ui/core";
import Back from "@material-ui/icons/ArrowBackRounded";
import Create from "@material-ui/icons/CreateRounded";
import ClosePreview from "@material-ui/icons/ExitToAppRounded";
import Preview from "@material-ui/icons/PlayArrowRounded";
import Save from "@material-ui/icons/SaveRounded";
import classnames from "classnames";
import * as React from "react";
import previewToolbarBackdrop from "../Templates/previewToolbarBackdrop.svg";
import { useCurrentAction } from "./customHooks";

const useStyles = makeStyles({
  toolbar: {
    gridColumn: "span 8",
    alignSelf: "start",
    boxShadow: "0px 2px 4px 0 rgba(0, 0, 0, 0.35)",
    width: "100%",
    height: "72px",
    order: 0,
    zIndex: 100,
    backgroundColor: "white",
  },
  toolbarBackground: {
    background: `no-repeat url(${previewToolbarBackdrop})`,
    backgroundPositionX: "90%",
  },
  tabRoot: {
    minWidth: 80,
    textTransform: "capitalize",
    padding: "initial",
    fontFamily: "-apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, sans-serif",
  },
  verticalRule: {
    boxShadow: "-1px -65px 0px 0px white, -1px -10px 0px 0px rgba(0,0,0, 0.4)",
  },
  disabledTab: {
    display: "none",
  },
  disabledTabGreyedOut: {
    display: "inherit",
  },
  centerTabs: {
    justifyContent: "center",
  },
});

enum ToolbarActions {
  Back,
  Save,
  Create,
  Preview,
  ClosePreview,
}

type ToolbarTab = {
  icon: JSX.Element;
  label: string;
  value: ToolbarActions;
  disabled: boolean;
};

type Props = {
  isValid: boolean;
  isSaving: boolean;
  isViewOnly: boolean;
  onBack: () => void;
  onSave: () => void;
  onPreview?: () => void;
  onPreviewClose?: () => void;
};

/**
 * TODO
 *  Remove action dependency on location?
 */
export const TemplateToolbar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const action = useCurrentAction();

  const [previewOpen, setPreviewOpen] = React.useState(false);

  const { isSaving } = props;

  const ToolbarTabs: Array<ToolbarTab> = [
    {
      icon: <Back />,
      label: "Back",
      value: ToolbarActions.Back,
      disabled: isSaving,
    },
    {
      icon: <Save />,
      label: "Save",
      value: ToolbarActions.Save,
      disabled: props.isViewOnly || action !== "edit" || isSaving || !props.isValid,
    },
    {
      icon: <CircularProgress />,
      label: "Saving",
      value: ToolbarActions.Save || ToolbarActions.Create,
      disabled: !isSaving,
    },
    {
      icon: <Create />,
      label: "Create",
      value: ToolbarActions.Create,
      disabled: props.isViewOnly || action === "edit" || isSaving || !props.isValid,
    },
    {
      icon: <Preview />,
      label: "Preview",
      value: ToolbarActions.Preview,
      disabled: !props.isValid || isSaving,
    },
    {
      icon: <ClosePreview />,
      label: "Close",
      value: ToolbarActions.ClosePreview,
      disabled: !previewOpen,
    },
  ];

  const handleAction = (_: React.ChangeEvent<{}>, action: number) => {
    switch (action) {
      case ToolbarActions.Preview:
        setPreviewOpen(true);
        props.onPreview && props.onPreview();
        break;
      case ToolbarActions.ClosePreview:
        setPreviewOpen(false);
        props.onPreviewClose && props.onPreviewClose();
        break;
      case ToolbarActions.Back:
        props.onBack();
        break;
      case ToolbarActions.Save:
        props.onSave();
        break;
      case ToolbarActions.Create:
        props.onSave();
        break;
      default:
        break;
    }
  };

  const removeTabs = (tab: ToolbarTab) => {
    switch (tab.label) {
      case "Saving":
        return isSaving;
      case "Save":
        return action === "edit" && !isSaving;
      case "Create":
        return action !== "edit" && !isSaving;
      case "Close":
        return previewOpen;
      case "Preview":
        return props.onPreview && props.onPreviewClose;
      default:
        return true;
    }
  };

  return (
    <Tabs
      value={false}
      onChange={handleAction}
      variant="scrollable"
      scrollButtons="auto"
      className={`${classes.toolbar} ${previewOpen ? classes.toolbarBackground : ""}`}
      classes={{ flexContainer: classes.centerTabs }}
    >
      {ToolbarTabs.filter(removeTabs).map((tab) => {
        return (
          <Tab
            id={tab.label}
            key={tab.label}
            icon={tab.icon}
            label={tab.label}
            value={tab.value}
            disabled={tab.disabled}
            className={classnames({
              [classes.verticalRule]: tab.value === ToolbarActions.ClosePreview,
            })}
            classes={
              isSaving
                ? { root: classes.tabRoot, disabled: classes.disabledTabGreyedOut }
                : { root: classes.tabRoot }
            }
          />
        );
      })}
    </Tabs>
  );
};
