import { makeStyles } from "@material-ui/core";
import { AgGridReact, AgGridReactProps } from "ag-grid-react/lib/agGridReact";
import cn from "classnames";
import * as React from "react";
import { CenteredCircularLoading } from "../../loading/CenteredCircularLoading";

const useStyles = makeStyles({
  root: {
    height: "100%",
  },
});

interface Props extends AgGridReactProps {
  isLoading?: boolean;
}

export function PlanStyledAgGrid({ isLoading, ...tableProps }: Props) {
  const classes = useStyles();

  const gridProps: AgGridReactProps = {
    pivotMode: false,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressRowGroups: true,
          },
        },
        "filters",
      ],
      position: "left",
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      enableRowGroup: false,
      filter: true,
    },
    autoGroupColumnDef: {
      headerName: "External Id",
      field: "ExternalId",
      autoHeight: true,
    },
    overlayLoadingTemplate: `<h3>Working...</h3>`,
    suppressMenuHide: true,
    ...tableProps,
  };

  return (
    <div className={cn("plan-table", classes.root)}>
      {Boolean(isLoading) && <CenteredCircularLoading />}
      <AgGridReact {...gridProps} />
    </div>
  );
}
