import { Card, CardContent, Fab, Fade, Theme, Typography } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import Help from "@material-ui/icons/HelpRounded";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { TemplateSection } from "../types";
import Footer from "./Footer.svg";
import Header from "./Header.svg";
import Index from "./Index.svg";
import Legend from "./Legend.svg";
import Table from "./Table.svg";

interface StyleProps {
  helpMode: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  previewWrapper: {
    position: "absolute",
    width: "100%",
  },
  sectionCard: {
    margin: 15,
  },
  sectionCardContent: {
    border: "3px solid rgba(0, 0, 0, 0)",
    padding: "0 40px 0 40px !important",
    transition: "border 200ms",
  },
  imageFill: (props) => ({
    width: "100%",
    "&:hover": {
      opacity: 0.4,
      transition: "opacity 200ms ease-in-out 750ms",
    },
    ...(props.helpMode && {
      opacity: 0.4,
    }),
    transition: "opacity 200ms ease-in-out",
  }),
  helperWrapper: (props) => ({
    position: "relative",
    color: fade(theme.palette.text.secondary, 0),
    "&:hover": {
      color: theme.palette.text.secondary,
      transition: "color 200ms ease-in-out 750ms",
    },
    ...(props.helpMode && {
      color: theme.palette.text.secondary,
    }),
    transition: "color 200ms ease-in-out",
  }),
  hoverText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: 400,
    fontSize: "4vh",
    cursor: "default",
    userSelect: "none",
  },
  helpButton: {
    zIndex: 1,
    position: "fixed",
    right: "1rem",
    bottom: "1rem",
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedSectionOverlay: {
    position: "absolute",
    zIndex: 1,
    width: "calc(100% - 6px)",
    height: "calc(100% - 6px)",
    backgroundColor: "rgba(255, 0, 0, 0.1)",
    border: "3px solid red",
  },
  selectedPageMarginsOutsideBorder: {
    backgroundColor: "rgba(255, 0, 0, 0.1)",
    border: "3px solid red",
  },
  selectedPageMarginsInsideBorder: {
    position: "absolute",
    zIndex: 1,
    width: "calc(100% - 6px)",
    height: "calc(100% - 6px)",
    border: "3px solid red",
  },
  titleText: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));

const previewSections = [
  {
    img: Legend,
    title: "Legend",
    // TODO: get these boolean values from outside the component
    hasHeader: true,
    hasFooter: true,
  },
  {
    img: Table,
    title: "Table",
    // TODO: get these boolean values from outside the component
    hasHeader: true,
    hasFooter: true,
  },
  {
    img: Index,
    title: "Index",
    // TODO: get these boolean values from outside the component
    hasHeader: true,
    hasFooter: true,
  },
];

const header = {
  img: Header,
  title: "Header",
};

const footer = {
  img: Footer,
  title: "Footer",
};

interface Props {
  selectedArea: TemplateSection;
}

export const SimpleTemplateGuide: React.FC<Props> = (props) => {
  const [helpMode, setHelpMode] = React.useState(false);

  const classes = useStyles({ helpMode });

  const handleHelpClick = () => {
    setHelpMode(!helpMode);
  };

  React.useEffect(() => {
    const section = document.getElementById(props.selectedArea as string);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.selectedArea]);

  const showAppropriateTitle = (sectionType: string) => {
    if (sectionType === "Index") {
      return (
        <Typography variant="h5" align="center" className={classes.titleText}>
          {"Index Title"}
        </Typography>
      );
    } else {
      return "";
    }
  };

  const buildHeader = (pageHasHeader: boolean) => {
    if (pageHasHeader) {
      return (
        <div className={classes.helperWrapper}>
          <Typography variant="h2" color="inherit" className={classes.hoverText}>
            {header.title.toUpperCase()}
          </Typography>
          <img className={classes.imageFill} src={header.img} alt={header.title} />
        </div>
      );
    }
  };

  const buildFooter = (pageHasFooter: boolean) => {
    if (pageHasFooter) {
      return (
        <div className={classes.helperWrapper}>
          <Typography variant="h2" color="inherit" className={classes.hoverText}>
            {footer.title.toUpperCase()}
          </Typography>
          <img className={classes.imageFill} src={footer.img} alt={footer.title} />
        </div>
      );
    }
  };

  return (
    <div className={classes.previewWrapper}>
      {previewSections.map((section) => (
        <Card key={section.title} className={classes.sectionCard} id={section.title}>
          <CardContent
            className={`${classes.sectionCardContent} ${
              props.selectedArea === "Page" ? classes.selectedPageMarginsOutsideBorder : ""
            }`}
          >
            {buildHeader(section.hasHeader)}
            {showAppropriateTitle(section.title)}
            <div className={classes.helperWrapper}>
              <Fade in={props.selectedArea === section.title}>
                <div className={classes.selectedSectionOverlay}></div>
              </Fade>
              <Fade in={props.selectedArea === "Page"}>
                <div className={classes.selectedPageMarginsInsideBorder}></div>
              </Fade>
              <Typography variant="h2" color="inherit" className={classes.hoverText}>
                {section.title.toUpperCase()}
              </Typography>
              <img className={classes.imageFill} src={section.img} alt={section.title} />
            </div>
            {buildFooter(section.hasFooter)}
          </CardContent>
        </Card>
      ))}
      <Fab size="medium" color="primary" className={classes.helpButton} onClick={handleHelpClick}>
        <Help />
      </Fab>
    </div>
  );
};
