import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  makeStyles,
  Switch,
  Typography,
} from "@material-ui/core";
import Auth from "@aws-amplify/auth";
import produce from "immer";
import * as React from "react";
import {
  ListUserAccountsInput,
  ListUserAccountsResponse,
  LIST_USER_ACCOUNTS_FILTERED,
  SetSelectedSubAccountInput,
  SetSelectedSubAccountResponse,
  SET_SELECTED_SUB_ACCOUNT,
} from "../AccountArea/api";
import { NextAccount } from "../AccountArea/types";
import { useBroadcastChannel } from "./BroadcastProvider";
import { SubaccountSelectionList } from "./lists/SubaccountList";
import { UserContext } from "./UserContext";
import { AlertDialog } from "./dialogs/AlertDialog";
import { useHistory } from "react-router-dom";

type AccountSwitcherContextProps = {
  show: () => void;
};

const useStyles = makeStyles((theme) => {
  return {
    dialog: {
      maxHeight: "80vh",
    },
    accountHeader: {
      paddingLeft: 0,
      paddingTop: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  };
});

const AccountSwitcherContext = React.createContext<AccountSwitcherContextProps>({ show() {} });

export const AccountSwitcher: React.FC = (props) => {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [isSwitchingAccounts, setSwitchingAccounts] = React.useState(false);
  const [accounts, setAccounts] = React.useState<Array<NextAccount>>([]);
  const [selectedSubAccountId, setSelectedSubAccount] = React.useState<string>(user.subAccountId);
  const [filteredAccounts, setFilteredAccounts] = React.useState<{
    filteredByMedicare: boolean;
    accounts: Array<NextAccount>;
  }>({ filteredByMedicare: user.isManagingMedicare, accounts: [] });

  const setAccountFilter = ({ filteredByMedicare }: { filteredByMedicare: boolean }) => {
    setFilteredAccounts({
      filteredByMedicare,
      accounts: accounts
        .filter(
          (account) =>
            !filteredByMedicare ||
            account.subAccounts.items.some((subAcct) => subAcct.isMedicareEnabled)
        )
        .map((account) =>
          produce(account, (draft) => {
            draft.subAccounts.items = draft.subAccounts.items.filter(
              (subAcct) => !filteredByMedicare || subAcct.isMedicareEnabled
            );
          })
        ),
    });
  };

  React.useEffect(() => {
    setAccountFilter(filteredAccounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const isValidSelection =
    (selectedSubAccountId !== user.subAccountId ||
      filteredAccounts.filteredByMedicare !== user.isManagingMedicare) &&
    filteredAccounts.accounts.some((account) =>
      account.subAccounts.items.some((subAccount) => subAccount.id === selectedSubAccountId)
    );

  const [getAccounts, { loading: isLoadingAccounts }] = useLazyQuery<
    ListUserAccountsResponse,
    ListUserAccountsInput
  >(LIST_USER_ACCOUNTS_FILTERED, {
    variables: {
      userId: user.userId,
    },
    onCompleted: (res) => {
      setAccounts(
        res.accounts.items
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((account) =>
            produce(account, (draft) => {
              draft.subAccounts.items = draft.subAccounts.items.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
            })
          )
      );
    },
  });

  const { sendMessage } = useBroadcastChannel();
  const history = useHistory();

  const [switchSubAccount] = useMutation<SetSelectedSubAccountResponse, SetSelectedSubAccountInput>(
    SET_SELECTED_SUB_ACCOUNT,
    {
      onCompleted: async (res) => {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: any) => {
          if (err) {
            console.error(err);
            setError(`Error while switching subaccounts`);
            setOpen(false);
            setSwitchingAccounts(false);
          }
          sendMessage({ event: "signIn" });
          history.replace("/");
          window.location.reload();
        });
      },
      onError: (error) => {
        setError(error.message);
        setOpen(false);
        setSwitchingAccounts(false);
      },
    }
  );

  const onSwitchSubAccount = async () => {
    setSwitchingAccounts(true);
    await switchSubAccount({
      variables: {
        subAccountId: selectedSubAccountId,
        userMode: filteredAccounts.filteredByMedicare ? "MEDICARE" : "NONMEDICARE",
      },
    });
  };

  const onShow = () => {
    getAccounts({
      variables: {
        userId: user.userId,
      },
    });
    setOpen(true);
  };

  return (
    <AccountSwitcherContext.Provider value={{ show: onShow }}>
      {props.children}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
        className={classes.dialog}
      >
        <DialogTitle>{isSwitchingAccounts ? "Switching Accounts" : "Select Account"}</DialogTitle>
        {(isLoadingAccounts || isSwitchingAccounts) && <LinearProgress />}
        {!isSwitchingAccounts && !isLoadingAccounts && (
          <DialogContent>
            <FormControlLabel
              label="Medicare"
              control={
                <Switch
                  checked={filteredAccounts.filteredByMedicare}
                  onChange={(event) =>
                    setAccountFilter({ filteredByMedicare: event.target.checked })
                  }
                />
              }
            />
            <SubaccountSelectionList
              accounts={filteredAccounts.accounts}
              onSelectSubaccount={setSelectedSubAccount}
              currentSubAccountId={selectedSubAccountId}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={onSwitchSubAccount}
            variant="contained"
            color="primary"
            disabled={!isValidSelection || isSwitchingAccounts}
          >
            Switch
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        isError
        isOpen={Boolean(error)}
        onExitHandler={() => {
          setError(null);
        }}
      >
        <Typography>{error}</Typography>
      </AlertDialog>
    </AccountSwitcherContext.Provider>
  );
};

export const useAccountSwitcher = () => React.useContext(AccountSwitcherContext);
