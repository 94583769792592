import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormLabel,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import TerminateRuleIcon from "@material-ui/icons/DeleteForeverRounded";
import TerminateIcon from "@material-ui/icons/DeleteRounded";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { format } from "date-fns";
import * as React from "react";
import { TwoActionDialog } from "../dialogs/TwoActionDialog";
import {
  ApiListRule,
  DRUG_ATTRIBUTES,
  DrugListType,
  RuleCondition,
} from "../../Flex/DrugLists/api";
import { awsDateStringToDate } from "../../utils/Date";
import { formatMoneyToUSD } from "../../utils/Numbers";

interface StyleProps {
  width?: string;
  height?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  ruleCard: (props) => ({
    position: "relative",
    width: props.width ?? "100%",
    height: props.height ?? "100%",
  }),
  attribute: {
    width: "120px",
  },
  smallFont: {
    fontSize: "0.8rem !important",
  },
  ruleCardHeader: {
    padding: 8,
  },
  ruleCardHeaderAvatar: {
    marginRight: theme.spacing(1),
  },
  ruleCardHeaderAction: {
    marginTop: 0,
    marginRight: 0,
  },
  ruleCardContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  conditionsArea: {
    minHeight: "100px",
    width: "100%",
  },
  condition: {
    width: "100%",
    display: "table-row",
    "& span": {
      display: "table-cell",
      fontSize: "0.8rem",
    },
  },
  ruleCardFooter: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    right: 5,
    background: "white",
  },
}));

interface Props {
  rule: ApiListRule;
  handleTerminate?: (termDate: Date) => void;
  height?: string;
  width?: string;
  listType?: DrugListType;
}

export const RuleCard: React.FC<Props> = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { rule } = props;
  const showTerminateOption = Boolean(props.handleTerminate) && !Boolean(rule.terminationDate);
  const [showTermDateDialog, setShowTermDateDialog] = React.useState(false);
  const [termDate, setTermDate] = React.useState<Date>(new Date());

  const getCardTitle = () => {
    if (rule.terminationDate) {
      return (
        <>
          <Typography className={classes.smallFont} style={{ fontStyle: "italic" }}>
            {`To be terminated: ${format(
              awsDateStringToDate(rule.terminationDate),
              "MMM do, yyyy"
            )}`}
          </Typography>
          {props.listType === DrugListType.PRICE && (
            <Typography className={classes.smallFont}>
              Price: {formatMoneyToUSD(rule.price!)}
            </Typography>
          )}
        </>
      );
    } else if (rule.effectiveDate) {
      return (
        <>
          <Typography className={classes.smallFont}>
            {`Effective as of: ${format(awsDateStringToDate(rule.effectiveDate), "MMM do, yyyy")}`}
          </Typography>
          {props.listType === DrugListType.PRICE && (
            <Typography className={classes.smallFont}>
              Price: {formatMoneyToUSD(rule.price!)}
            </Typography>
          )}
        </>
      );
    }
  };

  const TerminatedIndicator: React.FC = () => {
    return (
      <svg width={theme.spacing(2)} height={theme.spacing(2)} style={{ overflow: "visible" }}>
        <circle
          cy={theme.spacing(1)}
          cx={theme.spacing(1)}
          r={theme.spacing(1)}
          fill="white"
          stroke={theme.palette.primary.main}
          strokeWidth={2}
        />
      </svg>
    );
  };

  const ActiveIndicator: React.FC = () => {
    return (
      <svg width={theme.spacing(2)} height={theme.spacing(2)}>
        <circle
          cy={theme.spacing(1)}
          cx={theme.spacing(1)}
          r={theme.spacing(1)}
          fill={theme.palette.primary.main}
        />
      </svg>
    );
  };

  return (
    <>
      <Card className={classes.ruleCard}>
        <CardHeader
          title={getCardTitle()}
          className={classes.ruleCardHeader}
          classes={{ avatar: classes.ruleCardHeaderAvatar, action: classes.ruleCardHeaderAction }}
          avatar={rule.terminationDate ? <TerminatedIndicator /> : <ActiveIndicator />}
          action={
            props.handleTerminate ? (
              <IconButton
                size="small"
                style={{ visibility: showTerminateOption ? "visible" : "hidden" }}
                onClick={() => setShowTermDateDialog(true)}
              >
                <TerminateIcon />
              </IconButton>
            ) : null
          }
        />
        <CardContent className={classes.ruleCardContent}>
          <Typography className={classes.smallFont} color="textSecondary">
            Conditions
          </Typography>
          <Divider />
          <div className={classes.conditionsArea}>
            {rule.conditions.map((cond, index) => (
              <RuleConditions condition={cond} key={index} />
            ))}
          </div>
          {rule.createdAt && (
            <div className={classes.ruleCardFooter}>
              <Typography align="right" className={classes.smallFont}>
                Created on date {format(new Date(rule.createdAt), "MMMM do, yyyy")}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
      {props.handleTerminate && (
        <TwoActionDialog
          dialogTitle="Terminate Rule"
          dialogIcon={<TerminateRuleIcon />}
          isOpen={showTermDateDialog}
          primaryActionHandler={() => {
            setShowTermDateDialog(false);
            if (props.handleTerminate) {
              props.handleTerminate(termDate);
            }
          }}
          primaryActionName="Terminate"
          secondaryActionHandler={() => {
            setShowTermDateDialog(false);
            setTermDate(new Date());
          }}
          secondaryActionName="Cancel"
        >
          <FormLabel>Select Termination Date</FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disablePast
              allowKeyboardControl
              disableToolbar
              variant="static"
              format="MMMM do, yyyy"
              value={termDate}
              onChange={(date: MaterialUiPickersDate) => setTermDate(date as Date)}
            />
          </MuiPickersUtilsProvider>
        </TwoActionDialog>
      )}
    </>
  );
};

const RuleConditions: React.FC<{ condition: RuleCondition }> = (props) => {
  const classes = useStyles({});

  const attribute = DRUG_ATTRIBUTES[props.condition.drugAttribute];
  const valueDisplay =
    attribute && attribute.options
      ? attribute.options.find((opt) => opt.value === props.condition.matchValue)?.display
      : props.condition.matchValue;
  return (
    <div className={classes.condition}>
      <Typography className={classes.attribute} component="span">
        {attribute.display}
      </Typography>
      <Typography component="span">{valueDisplay}</Typography>
    </div>
  );
};
