import { useMutation } from "@apollo/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { TemplateFonts } from "../Formulary/types";
import {
  CreateCriteriaTemplateInput,
  CREATE_CRITERIA_TEMPLATE,
  SaveCriteriaTemplateResponse,
} from "./api";
import { DefaultMarginSettings, DEFAULT_SETTINGS, ManagementPage } from "./constants";
import { CriteriaTemplateContainer } from "./CriteriaFormContainer";
import { CriteriaTemplate } from "./types";

export const NewCriteriaTemplatePage: React.FC = () => {
  const history = useHistory();
  const [createCriteriaTemplate, { loading: isSaving }] = useMutation<
    SaveCriteriaTemplateResponse,
    CreateCriteriaTemplateInput
  >(CREATE_CRITERIA_TEMPLATE);

  const [template, setTemplate] = React.useState(DEFAULT_SETTINGS);

  const handleUpdateTemplate = (newTemplate: CriteriaTemplate) => {
    setTemplate(newTemplate);
  };

  const handleSaveTemplate = () => {
    const { criteriaTitleSize, elementInfoSize, elementNameSize, font } = template.settings;
    createCriteriaTemplate({
      variables: {
        name: template.name,
        settings: {
          criteriaTitleSize,
          elementInfoSize,
          elementNameSize,
          header: null,
          footer: null,
          font: font as TemplateFonts,
          marginSettings: DefaultMarginSettings,
        },
      },
    }).then(() => {
      history.push(ManagementPage);
    });
  };

  return (
    <CriteriaTemplateContainer
      templateStatus={isSaving ? { status: "saving", template } : { status: "available", template }}
      saveTemplate={handleSaveTemplate}
      updateTemplate={handleUpdateTemplate}
    />
  );
};
