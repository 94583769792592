import {
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { UserContext } from "../../../components/UserContext";
import { Accumulator, APIAccumulatorCategory } from "../../types";

interface AccumulatorCategoryTableProps {
  accumulator: Accumulator;
  disabled?: boolean;
  onUpdateAccumulator: (updatedAccumulator: Accumulator) => void;
}

export function AccumulatorCategoryTable(props: AccumulatorCategoryTableProps): JSX.Element {
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  function updateDeductibleIN(category: APIAccumulatorCategory) {
    if (props.accumulator.kind === "deductible" && props.accumulator.details?.inNetwork) {
      props.onUpdateAccumulator({
        kind: "deductible",
        details: { ...props.accumulator.details, inNetwork: category },
      });
    }
  }

  function updateDeductibleOON(category: APIAccumulatorCategory) {
    if (props.accumulator.kind === "deductible" && props.accumulator.details?.outOfNetwork) {
      props.onUpdateAccumulator({
        kind: "deductible",
        details: { ...props.accumulator.details, outOfNetwork: category },
      });
    }
  }

  function updateMaxOOPIN(category: APIAccumulatorCategory) {
    if (props.accumulator.kind === "maxOOP" && props.accumulator.details?.inNetwork) {
      props.onUpdateAccumulator({
        kind: "maxOOP",
        details: { ...props.accumulator.details, inNetwork: category },
      });
    }
  }

  function updateMaxOOPOON(category: APIAccumulatorCategory) {
    if (props.accumulator.kind === "maxOOP" && props.accumulator.details?.outOfNetwork) {
      props.onUpdateAccumulator({
        kind: "maxOOP",
        details: { ...props.accumulator.details, outOfNetwork: category },
      });
    }
  }

  function updateMaxOOPMailOrder(category: APIAccumulatorCategory) {
    if (props.accumulator.kind === "maxOOP" && props.accumulator.details?.mailOrder) {
      props.onUpdateAccumulator({
        kind: "maxOOP",
        details: { ...props.accumulator.details, mailOrder: category },
      });
    }
  }

  return (
    <Table size={contentSpacing === "dense" ? "small" : "medium"}>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Ind</TableCell>
          <TableCell>Family</TableCell>
          <TableCell>Ind + 1</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.accumulator.kind === "deductible" ? (
          <>
            <AccumulatorTableRow
              disabled={props.disabled}
              rowName="In Network"
              accumulatorCategory={props.accumulator.details?.inNetwork}
              handleUpdateCategory={updateDeductibleIN}
            />
            <AccumulatorTableRow
              disabled={props.disabled}
              rowName="Out of Network"
              accumulatorCategory={props.accumulator.details?.outOfNetwork}
              handleUpdateCategory={updateDeductibleOON}
            />
          </>
        ) : (
          <>
            <AccumulatorTableRow
              disabled={props.disabled}
              rowName="In Network"
              accumulatorCategory={props.accumulator.details?.inNetwork}
              handleUpdateCategory={updateMaxOOPIN}
            />
            <AccumulatorTableRow
              disabled={props.disabled}
              rowName="Out of Network"
              accumulatorCategory={props.accumulator.details?.outOfNetwork}
              handleUpdateCategory={updateMaxOOPOON}
            />
            <AccumulatorTableRow
              disabled={props.disabled}
              rowName="Mail Order"
              accumulatorCategory={props.accumulator.details?.mailOrder}
              handleUpdateCategory={updateMaxOOPMailOrder}
            />
          </>
        )}
      </TableBody>
    </Table>
  );
}

interface AccumulatorTableRowProps {
  rowName: string;
  accumulatorCategory: APIAccumulatorCategory | null | undefined;
  disabled?: boolean;
  handleUpdateCategory: (updatedCategory: APIAccumulatorCategory) => void; // could it just take the new value and then update it appropriately?
}

function AccumulatorTableRow(props: AccumulatorTableRowProps): JSX.Element {
  function updateIndividual(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.accumulatorCategory) {
      props.handleUpdateCategory({
        ...props.accumulatorCategory,
        individual: parseInt(event.target.value),
      });
    }
  }

  function updateFamily(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.accumulatorCategory) {
      props.handleUpdateCategory({
        ...props.accumulatorCategory,
        family: parseInt(event.target.value),
      });
    }
  }

  function updateIndividualPlusOne(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.accumulatorCategory) {
      props.handleUpdateCategory({
        ...props.accumulatorCategory,
        individualPlus1: parseInt(event.target.value),
      });
    }
  }

  return (
    <TableRow>
      <TableCell>{props.rowName}</TableCell>
      <AccumulatorTableNumberCell
        disabled={props.disabled}
        value={props.accumulatorCategory?.individual || ""}
        onChange={updateIndividual}
      />
      <AccumulatorTableNumberCell
        disabled={props.disabled}
        value={props.accumulatorCategory?.family || ""}
        onChange={updateFamily}
      />
      <AccumulatorTableNumberCell
        disabled={props.disabled}
        value={props.accumulatorCategory?.individualPlus1 || ""}
        onChange={updateIndividualPlusOne}
      />
    </TableRow>
  );
}

const useAccumulatorTableCellStyles = makeStyles({
  textField: { width: "5rem" },
});

interface AccumulatorTableNumberCellProps {
  value: number | string;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function AccumulatorTableNumberCell(props: AccumulatorTableNumberCellProps): JSX.Element {
  const classes = useAccumulatorTableCellStyles();

  return (
    <TableCell>
      <TextField
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
        className={classes.textField}
        InputProps={{
          inputComponent: NumberFormatPrice as any,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </TableCell>
  );
}

interface NumberFormatPriceProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

function NumberFormatPrice(props: NumberFormatPriceProps): JSX.Element {
  const { inputRef, onChange, ...other } = props;

  function handleChange(values: NumberFormatValues) {
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });
  }

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      min={0}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
}
