import * as React from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { useDialogStyles } from "./DialogStyles";
import Warning from "@material-ui/icons/WarningRounded";

interface Props {
  isOpen: boolean;
  dialogTitle: JSX.Element | React.ComponentType | string;
  onExitHandler: () => void;
  dialogIcon?: React.ComponentType<SvgIconProps> | JSX.Element;
  closeButtonText?: string;
  children?: React.ReactNode;
}

export function BasicDialog(props: Props): JSX.Element {
  const classes = useDialogStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.isOpen}
      PaperProps={{ classes: { root: classes.dialogBox } }}
    >
      <DialogTitle disableTypography className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleIconArea}>
          {props.dialogIcon ? props.dialogIcon : <Warning fontSize="large" color="inherit" />}
        </div>
        <Typography variant="h6" className={classes.dialogTitleTextArea}>
          {props.dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContentArea}>{props.children}</DialogContent>
      <DialogActions>
        <Button onClick={props.onExitHandler} className={classes.actionButtons}>
          {props.closeButtonText ? props.closeButtonText : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
