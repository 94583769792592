/**
 * Reusable component for displaying error indicator
 */
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles(() => ({
  root: {
    width: "55%",
    display: "flex",
    alignSelf: "end",
    justifyContent: "flex-end",
    height: "100%",
    marginTop: "5px",
    color: "red",
  },
  message: {
    color: "red",
    fontSize: "14px",
    margin: "4px 0 0 5px",
  },
}));

interface Props {
  isError: boolean;
  styleObject?: object;
}

export const FormularyErrorIndicator: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.isError ? (
        <div className={classes.root} style={props.styleObject}>
          <ErrorOutline />
          <span className={classes.message} style={props.styleObject}>
            Please fix errors
          </span>
        </div>
      ) : null}
    </>
  );
};
