import { useQuery } from "@apollo/client";
import { Divider, Fade, IconButton, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import BackButton from "@material-ui/icons/ArrowBackRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { CenteredCircularLoading } from "../../../components/loading/CenteredCircularLoading";
import { GeneralErrorPage } from "../../../components/screens/broken";
import { UserContext } from "../../../components/UserContext";
import { awsDateStringToDate } from "../../../utils/Date";
import { useFormularyRouteParams } from "../FormularyRouteUtils";
import { LegacyChip } from "../LegacyChip";
import { GET_FORMULARY, GetFormularyInput, GetFormularyResponse } from "./api";
import { ManagedConfiguration } from "./Configuration/Managed/ManagedConfiguration";
import { PartDConfiguration } from "./Configuration/PartD/PartDConfiguration";
import { FormularyEditingContext } from "./FormularyEditingContext";
import { FormularyPublishing } from "./Publishing/FormularyPublishing";
import { FormularySetup } from "./Setup/FormularySetup";
import {
  ApiFormulary,
  FormularyDetails,
  FormularyTabs,
  NewFormularyDetails,
  SnapshotStatus,
} from "./types";

const useStyles = makeStyles((theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  formularyIdentifier: {
    marginLeft: 10,
    color: theme.palette.primary.main,
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
  tabTransition: {
    transition: "flex 1000ms linear",
  },
  legacyAvatar: {
    objectFit: "contain",
    width: "75%",
  },
  legacyAvatarBG: {
    backgroundColor: "transparent !important",
  },
}));

interface Props {
  kind: "new" | "edit" | "view";
}

export const FormularyPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { formularyId } = useFormularyRouteParams();
  const history = useHistory();
  const {
    user: { isManagingMedicare },
  } = React.useContext(UserContext);
  const isEditing = props.kind === "new" || props.kind === "edit";

  const [formulary, setFormulary] = React.useState<FormularyDetails | undefined>(
    props.kind === "new" || formularyId === undefined
      ? { kind: "new", details: DEFAULT_STARTING_FORMULARY }
      : undefined
  );
  const [activeTab, setActiveTab] = React.useState<FormularyTabs>(FormularyTabs.SETUP);

  const { loading, refetch } = useQuery<GetFormularyResponse, GetFormularyInput>(GET_FORMULARY, {
    skip: formulary?.kind === "new",
    fetchPolicy: "no-cache",
    variables: { id: formularyId ?? "new" },
    notifyOnNetworkStatusChange: true,
    onCompleted: (res: GetFormularyResponse | undefined) => {
      if (res) {
        if (formulary === undefined) {
          setActiveTab(FormularyTabs.CONFIGURATION);
        }
        setFormulary({ kind: "edit", details: editFormularyResponseToDetails(res) });
      }
    },
  });

  const goBack = () => {
    history.push("/formularies");
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, newTab: FormularyTabs) => {
    setActiveTab(newTab);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, ...other } = props;

    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  const getFormularyName = () => {
    if (formulary) {
      if (formulary.kind === "new") {
        return isManagingMedicare ? "New Medicare Formulary" : "New Formulary";
      } else if (formulary.details.hasLegacyPlan) {
        return (
          <>
            {formulary.details.name} <LegacyChip />
          </>
        );
      } else {
        return formulary.details.name;
      }
    } else {
      return "";
    }
  };

  const handleCreateFormulary = (newFormulary: ApiFormulary) => {
    history.push(`/formularies/edit/${newFormulary.id}`);
    setFormulary({ kind: "edit", details: { ...newFormulary, snapshotHistory: { items: [] } } });
    setActiveTab(FormularyTabs.CONFIGURATION);
  };

  const handleUpdateFormulary = (updatedFormulary: ApiFormulary) => {
    setFormulary({ kind: "edit", details: updatedFormulary });
  };

  const handleFinishConfig = () => {
    if (formulary?.kind === "edit" && formularyId) {
      refetch({ id: formularyId });
      setActiveTab(FormularyTabs.PUBLISH);
    }
  };

  const isLegacyPlan = Boolean(formulary?.kind === "edit" && formulary.details.hasLegacyPlan);
  const hasData =
    isLegacyPlan ||
    (formulary?.kind === "edit" &&
      formulary.details.snapshotHistory.items.some(
        (snapshot) => snapshot.status === SnapshotStatus.COMPLETED
      ));

  const renderConfigTabPanel = () => {
    if (formulary && formulary.kind === "edit" && formularyId) {
      if (isManagingMedicare && !isLegacyPlan) {
        return (
          <PartDConfiguration
            snapshotHistory={formulary.details.snapshotHistory.items}
            formularyId={formularyId}
            onFinishConfig={handleFinishConfig}
          />
        );
      } else {
        return (
          <ManagedConfiguration
            formularyId={formularyId}
            formularyTiers={formulary.details.tiers.items}
          />
        );
      }
    } else {
      console.error("No formulary detected");
      return <GeneralErrorPage />;
    }
  };

  return (
    <FormularyEditingContext.Provider value={isEditing}>
      <div className={classes.fullWidthPageArea}>
        {loading ? (
          <CenteredCircularLoading />
        ) : (
          <>
            <div className={classes.headerArea}>
              <IconButton onClick={goBack}>
                <BackButton />
              </IconButton>
              <Typography variant="h4" className={classes.formularyIdentifier}>
                {getFormularyName()}
              </Typography>
            </div>
            <Divider variant="inset" />
            <Tabs
              centered
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="formulary navigation tabs"
            >
              <Tab
                label={<Typography color="inherit">{FormularyTabs.SETUP}</Typography>}
                value={FormularyTabs.SETUP}
                className={classes.tabText}
              />
              {formulary?.kind === "edit" && (
                <Tab
                  label={
                    <Fade in={formulary.kind === "edit"} timeout={{ enter: 500 }}>
                      <Typography color="inherit">{FormularyTabs.CONFIGURATION}</Typography>
                    </Fade>
                  }
                  value={FormularyTabs.CONFIGURATION}
                  className={classes.tabText}
                />
              )}
              {!isLegacyPlan && formulary?.kind === "edit" && (
                <Tab
                  label={
                    <Fade in={formulary.kind === "edit"} timeout={{ enter: 500 }}>
                      <Typography color="inherit">{FormularyTabs.VIEW}</Typography>
                    </Fade>
                  }
                  value={FormularyTabs.VIEW}
                  className={classes.tabText}
                  disabled={true}
                  // TODO: use the following disabled value when view page is actually implemented
                  // disabled={!flags.fullFormularyManagement || hasData === false}
                />
              )}
              {formulary?.kind === "edit" && (
                <Tab
                  label={
                    <Fade in={formulary.kind === "edit"} timeout={{ enter: 500 }}>
                      <Typography color="inherit">{FormularyTabs.PUBLISH}</Typography>
                    </Fade>
                  }
                  value={FormularyTabs.PUBLISH}
                  className={classes.tabText}
                  disabled={!hasData}
                />
              )}
            </Tabs>
            <Divider />
            <TabPanel value={FormularyTabs.SETUP}>
              {activeTab === FormularyTabs.SETUP && formulary && (
                <FormularySetup
                  formulary={formulary}
                  onCreateFormulary={handleCreateFormulary}
                  onUpdateFormulary={handleUpdateFormulary}
                />
              )}
            </TabPanel>
            <TabPanel value={FormularyTabs.CONFIGURATION}>
              {activeTab === FormularyTabs.CONFIGURATION && renderConfigTabPanel()}
            </TabPanel>
            <TabPanel value={FormularyTabs.VIEW}>
              {activeTab === FormularyTabs.VIEW && <p>View Tab</p>}
            </TabPanel>
            <TabPanel value={FormularyTabs.PUBLISH}>
              {activeTab === FormularyTabs.PUBLISH && formulary?.kind === "edit" && (
                <FormularyPublishing formulary={formulary.details} />
              )}
            </TabPanel>
          </>
        )}
      </div>
    </FormularyEditingContext.Provider>
  );
};

type TabPanelProps = {
  children?: React.ReactNode;
  value: FormularyTabs;
};

const DEFAULT_STARTING_FORMULARY: NewFormularyDetails = {
  effectiveDate: new Date(),
  terminationDate: null,
  hasLegacyPlan: false,
  isManaged: false,
  lineOfBusiness: "",
  externalId: "",
  name: "",
  tiers: { items: [] },
};

const editFormularyResponseToDetails = ({ formulary }: GetFormularyResponse): ApiFormulary => {
  const {
    effectiveDate,
    terminationDate,
    publishDate,
    cmsApprovedOn,
    tiers,
    snapshotHistory,
    cmsEffectiveDate,
  } = formulary;

  return {
    ...formulary,
    effectiveDate: new Date(`${effectiveDate}T00:00`),
    terminationDate: !!terminationDate ? new Date(`${terminationDate}T00:00`) : null,
    publishDate: publishDate ? awsDateStringToDate(publishDate) : null,
    cmsApprovedOn: cmsApprovedOn ? awsDateStringToDate(cmsApprovedOn) : null,
    cmsEffectiveDate: cmsEffectiveDate ? awsDateStringToDate(cmsEffectiveDate) : null,
    tiers: { items: tiers.items.sort((a, b) => a.rank - b.rank) },
    snapshotHistory,
  };
};
