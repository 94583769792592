import { useMutation } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  Fab,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Zoom,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/SaveRounded";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as React from "react";
import { AlertDialog } from "../../../../components/dialogs/AlertDialog";
import { OptionsTable } from "../../../../components/tables/OptionsTable";
import { apiFormatDate } from "../../../../utils/Date";
import { sharedStyles } from "../../../styles";
import { EXTERNAL_ID_HELP_TEXT } from "../../../helpText";
import {
  apiListToList,
  APITierList,
  CREATE_LIST,
  CreateListInput,
  CreateListResponse,
  DrugListType,
  TierList,
  UPDATE_LIST,
  UpdateListInput,
  UpdateListResponse,
} from "../../api";
import { CenteredCircularLoading } from "../../../../components/loading/CenteredCircularLoading";

const useStyles = makeStyles({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  tabRoot: {
    minWidth: 80,
    textTransform: "capitalize",
  },
  wrapper: {
    position: "relative",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
});

const NEW_DRUG_LIST_DEFAULT: APITierList = {
  id: "",
  name: "",
  listType: DrugListType.TIER,
  effectiveDate: null,
  isMedicare: true,
  terminationDate: null,
  externalId: null,
  description: null,
  rules: {
    items: [],
  },
  drugs: {
    items: [],
  },
};

type Props = {
  isLoading: boolean;
  onCreateList: (drugListId: string) => void;
  onUpdateList: () => void;
  drugList?: APITierList;
};

export const TierListSetup: React.FC<Props> = (props) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const [drugList, setDrugList] = React.useState<TierList>(
    apiListToList(props.drugList || NEW_DRUG_LIST_DEFAULT) as TierList
  );
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const [createList, { loading: isSaving, error: errorSaving }] = useMutation<
    CreateListResponse,
    CreateListInput
  >(CREATE_LIST);
  const [updateList, { loading: isUpdating, error: errorUpdating }] = useMutation<
    UpdateListResponse,
    UpdateListInput
  >(UPDATE_LIST);

  const isSaved = Boolean(props.drugList);

  const handleSaveClick = () => {
    if (isSaved) {
      updateList({
        variables: {
          input: {
            id: drugList.id,
            description: drugList.description || null,
            name: drugList.name,
            externalId: drugList.externalId || null,
            terminationDate: apiFormatDate(drugList.terminationDate),
          },
        },
      })
        .then(() => {
          props.onUpdateList();
        })
        .catch((err) => {
          setShowAlert(true);
          console.error(err);
        });
    } else {
      createList({
        variables: {
          input: {
            name: drugList.name,
            listType: drugList.listType,
            effectiveDate: apiFormatDate(drugList.effectiveDate as Date) as string,
            description: drugList.description || null,
            externalId: drugList.externalId || null,
            terminationDate: apiFormatDate(drugList.terminationDate),
          },
        },
      })
        .then((res) => {
          props.onCreateList(res.data?.createList.id as string);
        })
        .catch((err) => {
          setShowAlert(true);
          console.error(err);
        });
    }
  };

  const updateDrugListName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setDrugList({
      ...drugList,
      name,
    });
  };

  const shouldShowSave = () => {
    return drugList?.name !== "" && drugList?.effectiveDate !== null;
  };

  const drugListProperties = [
    {
      label: "Name",
      options: (
        <TextField
          required
          value={drugList?.name}
          onChange={updateDrugListName}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "External ID",
      helpText: EXTERNAL_ID_HELP_TEXT,
      options: (
        <TextField
          value={drugList.externalId}
          onChange={(event) =>
            setDrugList({
              ...drugList,
              externalId: event.target.value,
            })
          }
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "Effective Date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            disabled={isSaved}
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={drugList.effectiveDate}
            onChange={(date) => setDrugList({ ...drugList, effectiveDate: date })}
            maxDate={drugList.terminationDate}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Termination Date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={drugList.terminationDate}
            onChange={(date) => setDrugList({ ...drugList, terminationDate: date })}
            minDate={drugList.effectiveDate}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
  ];

  return (
    <>
      <section className={sharedClasses.tabContent}>
        <div className={sharedClasses.fab}>
          <Zoom in={shouldShowSave()} timeout={{ enter: 200, exit: 200 }}>
            <div className={classes.wrapper} style={{ marginRight: 15 }}>
              <Button
                variant="contained"
                aria-label="create formulary"
                color="primary"
                onClick={handleSaveClick}
                disabled={isSaving || isUpdating}
              >
                {isSaved ? "Update List" : "Save List"}
              </Button>
              {(isSaving || isUpdating) && (
                <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </div>
          </Zoom>
          <Zoom in={shouldShowSave()}>
            <div className={classes.wrapper}>
              <Fab
                variant="round"
                size="large"
                aria-label="create formulary"
                color="primary"
                onClick={handleSaveClick}
                disabled={isSaving || isUpdating}
              >
                <SaveIcon />
              </Fab>
              {(isSaving || isUpdating) && (
                <CircularProgress size={68} className={classes.fabProgress} />
              )}
            </div>
          </Zoom>
        </div>
        <Card className={sharedClasses.formularyCard}>
          <CardHeader title="Properties" />
          <Divider />
          <CardContent>
            {props.isLoading ? (
              <CenteredCircularLoading />
            ) : (
              <OptionsTable settings={drugListProperties} />
            )}
          </CardContent>
        </Card>
        <Card className={sharedClasses.formularyCard}>
          <CardHeader title="Description" />
          <Divider />
          <CardContent>
            {props.isLoading ? (
              <CenteredCircularLoading />
            ) : (
              <TextField
                style={{ width: "100%" }}
                placeholder="Detail the purpose of the drug list (Optional)."
                onChange={(event) => setDrugList({ ...drugList, description: event.target.value })}
                value={drugList.description}
                variant="outlined"
                multiline
                rows={4}
              />
            )}
          </CardContent>
        </Card>
      </section>
      <AlertDialog
        isError
        isOpen={showAlert}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setShowAlert(false);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error saving or updating your list. Please wait a moment and refresh or try
          again. If the problem persists, please contact support.
        </Typography>
        <Button
          color="primary"
          style={{ width: "fit-content" }}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Table>
            <TableBody>
              {errorSaving && (
                <TableRow>
                  <TableCell>
                    <Typography>{errorSaving.message}</Typography>
                  </TableCell>
                </TableRow>
              )}
              {errorSaving &&
                errorSaving.graphQLErrors.map((error, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography color="error">{error.message}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              {errorUpdating && (
                <TableRow>
                  <TableCell>
                    <Typography>{errorUpdating.message}</Typography>
                  </TableCell>
                </TableRow>
              )}
              {errorUpdating &&
                errorUpdating.graphQLErrors.map((error, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography color="error">{error.message}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </>
  );
};
