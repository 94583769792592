import { makeStyles, Theme } from "@material-ui/core";
import {
  TEMPLATE_PRIMARY_COLOR,
  FLEX_PRIMARY_COLOR,
  PLANS_PRIMARY_COLOR,
  NETWORK_PRIMARY_COLOR,
  DEFAULT_THEME_COLOR,
} from "../../styles/Theme";

export const sidebarStyles = makeStyles((theme: Theme) => ({
  appSectionText: {
    color: "white !important",
    overflow: "hidden !important",
    maxHeight: "24px",
    textDecoration: "none",
  },
  appWrapper: {
    marginTop: theme.spacing(),
  },
  appWrapperSelectedTemplate: {
    borderRadius: theme.spacing(),
    boxShadow: `0 0 0 2pt ${TEMPLATE_PRIMARY_COLOR}`,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  appWrapperSelectedFlex: {
    borderRadius: theme.spacing(),
    boxShadow: `0 0 0 2pt ${FLEX_PRIMARY_COLOR}`,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  appWrapperSelectedPlans: {
    borderRadius: theme.spacing(),
    boxShadow: `0 0 0 2pt ${PLANS_PRIMARY_COLOR}`,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  appWrapperSelectedNetwork: {
    borderRadius: theme.spacing(),
    boxShadow: `0 0 0 2pt ${NETWORK_PRIMARY_COLOR}`,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    marginTop: theme.spacing(),
  },
  appWrapperSelectedDefault: {
    borderRadius: theme.spacing(),
    boxShadow: `0 0 0 2pt ${DEFAULT_THEME_COLOR}`,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    marginTop: theme.spacing(),
  },
  listItemGutters: {
    paddingLeft: theme.spacing() + 4,
    paddingRight: theme.spacing() + 4,
    marginBottom: theme.spacing() - 3,
  },
  listButtonStyle: {
    borderRadius: theme.spacing(),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.15)",
    },
  },
  selectedApp: {
    backgroundColor: "rgba(255, 255, 255, 0.15) !important",
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedListItemTemplate: {
    backgroundColor: `${TEMPLATE_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedListItemFlex: {
    backgroundColor: `${FLEX_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedListItemPlans: {
    backgroundColor: `${PLANS_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedListItemNetwork: {
    backgroundColor: `${NETWORK_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedListItemDefault: {
    backgroundColor: `${DEFAULT_THEME_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  smallAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    background: "transparent",
    overflow: "visible",
    fontSize: "1rem",
  },
  selectedTemplates: {
    backgroundColor: `${TEMPLATE_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedFormularies: {
    backgroundColor: `${FLEX_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedPlans: {
    backgroundColor: `${PLANS_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedNetwork: {
    backgroundColor: `${NETWORK_PRIMARY_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  selectedDefault: {
    backgroundColor: `${DEFAULT_THEME_COLOR} !important`,
    transition: theme.transitions.create("background-color", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  lastItemMargins: {
    marginBottom: theme.spacing(3),
  },
}));
