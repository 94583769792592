import * as React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  LinearProgress,
  makeStyles,
  Slider,
  Typography,
} from "@material-ui/core";
import { asyncLocalStorage, TEXT_SIZE } from "../../utils/localStorageUtils";
import { UserContext } from "../../components/UserContext";
import ApplyIcon from "@material-ui/icons/CheckCircleRounded";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    height: 610,
    position: "relative",
  },
  cardContentRoot: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  cardActionRoot: {
    bottom: 0,
    position: "absolute",
  },
  textSizeSlider: {
    width: 600,
    padding: theme.spacing(3),
  },
  demoText: {
    maxWidth: 600,
    padding: theme.spacing(3),
  },
  acceptButton: {
    position: "absolute",
    bottom: -50,
    width: 110,
    left: "calc(50% - 55px)",
  },
}));

export function TextSizing() {
  const classes = useStyles();
  const [textSize, setTextSize] = React.useState({
    setting: 3,
    previousSetting: 3,
  });
  const [isSaving, setIsSaving] = React.useState(false);
  const {
    updateUserSettings,
    user: { settings },
  } = React.useContext(UserContext);

  React.useEffect(() => {
    setIsSaving(true);
    asyncLocalStorage.getItem(TEXT_SIZE).then((res) => {
      setIsSaving(false);
      switch (res) {
        case "0.8rem":
          setTextSize({ setting: 1, previousSetting: 1 });
          break;
        case "0.9rem":
          setTextSize({ setting: 2, previousSetting: 2 });
          break;
        case "1rem":
          setTextSize({ setting: 3, previousSetting: 3 });
          break;
        case "1.2rem":
          setTextSize({ setting: 4, previousSetting: 4 });
          break;
        default:
          setTextSize({ setting: 1, previousSetting: 1 });
          break;
      }
    });
  }, []);

  function getTextSize(size: number) {
    switch (size) {
      case 1:
        return "0.8rem";
      case 2:
        return "0.9rem";
      case 3:
        return "1rem";
      case 4:
        return "1.2rem";
      default:
        return "1rem";
    }
  }

  function handleSizeChange(newSize: number) {
    setTextSize({ ...textSize, setting: newSize });
  }

  function saveChanges() {
    setIsSaving(true);
    asyncLocalStorage.setItem(TEXT_SIZE, getTextSize(textSize.setting)).then(() => {
      updateUserSettings({ ...settings, textSize: getTextSize(textSize.setting) });
    });
  }

  return (
    <>
      {isSaving ? <LinearProgress /> : <Divider style={{ height: 4, visibility: "hidden" }} />}
      <Card className={classes.cardRoot}>
        <CardHeader
          title="Drag the slider below to the preferred text size to use for the app"
          titleTypographyProps={{ align: "center", variant: "h6" }}
        />
        <CardContent className={classes.cardContentRoot}>
          <Typography
            align="center"
            className={classes.demoText}
            style={{ fontSize: getTextSize(textSize.setting) }}
          >
            The FitnessGram™ Pacer Test is a multistage aerobic capacity test that progressively
            gets more difficult as it continues. The 20 meter pacer test will begin in 30 seconds.
            Line up at the start. The running speed starts slowly, but gets faster each minute after
            you hear this signal. Ding A single lap should be completed each time you hear this
            sound. Ding Remember to run in a straight line, and run as long as possible. The second
            time you fail to complete a lap before the sound, your test is over. The test will begin
            on the word start
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActionRoot}>
          <TextChoiceSlider size={textSize.setting} onSizeChange={handleSizeChange} />
        </CardActions>
      </Card>
      <Fade in={textSize.setting !== textSize.previousSetting}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ApplyIcon />}
          className={classes.acceptButton}
          onClick={saveChanges}
        >
          Apply
        </Button>
      </Fade>
    </>
  );
}

function TextChoiceSlider(props: { size: number; onSizeChange: (size: number) => void }) {
  const classes = useStyles();

  enum TextChoices {
    TINY = "Tiny",
    SMALL = "Small",
    MEDIUM = "Medium",
    BIG = "Big",
  }

  const marks = [
    {
      value: 1,
      label: <Typography style={{ fontSize: "0.8rem" }}>{TextChoices.TINY}</Typography>,
    },
    {
      value: 2,
      label: <Typography style={{ fontSize: "0.9rem" }}>{TextChoices.SMALL}</Typography>,
    },
    {
      value: 3,
      label: <Typography style={{ fontSize: "1rem" }}>{TextChoices.MEDIUM}</Typography>,
    },
    {
      value: 4,
      label: <Typography style={{ fontSize: "1.2rem" }}>{TextChoices.BIG}</Typography>,
    },
  ];

  function handleChange(_: React.ChangeEvent<{}>, value: number | number[]) {
    props.onSizeChange(value as number);
  }

  return (
    <div className={classes.textSizeSlider}>
      <Typography gutterBottom>Text Size:</Typography>
      <Slider
        defaultValue={props.size}
        step={null}
        min={1}
        max={4}
        marks={marks}
        valueLabelDisplay="off"
        value={props.size}
        onChange={handleChange}
      />
    </div>
  );
}
