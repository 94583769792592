import format from "date-fns/format";

/**
 * TODO
 *  Date translations for AWSDate and AWSDateTime
 */

export const apiFormatDate = (date: Date | null) => (date ? format(date, "yyyy-MM-dd") : null);
export const awsDateStringToDate = (date: string) =>
  new Date(date.includes("T") ? date : `${date}T00:00:00`);

export const standardUIFormat = (date: Date) => format(date, "MMMM do, yyyy");

export const shortenedUIFormat = (date: Date) => format(date, "MMM do, yyyy");

export function planTableFileDate(date: Date) {
  return format(date, "MMddyyyy-hhmmss");
}
