import * as React from "react";

import { Avatar, Chip, makeStyles } from "@material-ui/core";
import adaptiveLogo from "../../styles/Graphics/legacy.webp";

const useStyles = makeStyles({
  legacyAvatar: {
    objectFit: "contain",
    width: "75%",
  },
  legacyAvatarBG: {
    backgroundColor: "transparent !important",
  },
});

export const LegacyChip: React.FC = () => {
  const classes = useStyles();
  return (
    <Chip
      avatar={<Avatar classes={{ img: classes.legacyAvatar }} alt="legacy" src={adaptiveLogo} />}
      color="primary"
      variant="outlined"
      label="LEGACY PLAN"
      classes={{ avatarColorPrimary: classes.legacyAvatarBG }}
    />
  );
};
