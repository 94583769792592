import { useQuery } from "@apollo/client";
import { fade, makeStyles, Paper, Typography, useTheme } from "@material-ui/core";
import EditIcon from "@material-ui/icons/CreateRounded";
import InfoIcon from "@material-ui/icons/InfoRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { QuickViewCard } from "../components/cards/QuickViewCard";
import { CategorySearchList } from "../components/lists/CategorySearchList";
import { awsDateStringToDate } from "../utils/Date";
import { APIEditNetworkItem, ListNetworksResponse, LIST_NETWORKS } from "./api";

const useStyles = makeStyles((theme) => ({
  networkList: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  previewArea: {
    gridColumn: "7 / span 8",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const ListNetworksPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [selectedNetwork, setSelectedNetwork] = React.useState<APIEditNetworkItem>();
  const { data: getNetworksResponse, loading: loadingNetworks } = useQuery<ListNetworksResponse>(
    LIST_NETWORKS
  );

  const EDIT_MENU_OPTION = {
    name: "Edit",
    action: (selectedItem: string) => {
      history.push(`/networks/edit/${selectedItem}`);
    },
    icon: <EditIcon fontSize="small" />,
  };

  const INFO_MENU_OPTION = {
    name: "Info",
    action: (selectedItem: string) => {
      history.push(`/networks/view/${selectedItem}`);
    },
    icon: <InfoIcon fontSize="small" />,
  };

  const handleSelectNetwork = (itemId: string) => {
    const foundNetwork = getNetworksResponse?.networks.items.find((net) => net.id === itemId);
    setSelectedNetwork(foundNetwork);
  };

  const goToNewNetwork = () => {
    history.push("/networks/new");
  };

  return (
    <>
      <Paper square className={classes.networkList}>
        <CategorySearchList
          listData={
            getNetworksResponse?.networks.items.map((network) => ({
              id: network.id,
              name: network.name,
              groupName: network.lineOfBusiness,
            })) || []
          }
          dataLoading={loadingNetworks}
          onItemSelection={handleSelectNetwork}
          listAction={{
            buttonText: "New Network",
            action: goToNewNetwork,
          }}
          menuOptions={[INFO_MENU_OPTION, EDIT_MENU_OPTION]}
        />
      </Paper>
      <div className={classes.previewArea}>
        {selectedNetwork ? (
          <QuickViewCard
            effectiveDate={awsDateStringToDate(selectedNetwork.effectiveDate)}
            terminationDate={
              selectedNetwork.terminationDate
                ? awsDateStringToDate(selectedNetwork.terminationDate)
                : null
            }
            name={selectedNetwork.name}
            lineOfBusiness={selectedNetwork.lineOfBusiness}
          />
        ) : (
          <Typography variant="h5">
            Select a <span style={{ color: theme.palette.primary.main }}>Network</span> to quickly
            view its properties
          </Typography>
        )}
      </div>
    </>
  );
};
