import { Divider, makeStyles, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Check from "@material-ui/icons/DoneRounded";
import * as React from "react";
import { FilePicker } from "../../../../../components/buttons/FilePicker";
import { OptionsTable } from "../../../../../components/tables/OptionsTable";
import { APIFileType, HPMSFileInfo, HPMSFileToUpload } from "../../types";

const useStyles = makeStyles({
  stepWrapper: {
    display: "flex",
    height: "calc(100% - 40px)",
  },
  secondStepFiles: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    alignItems: "center",
    width: "75%",
  },
  stickyInstructions: {
    position: "sticky",
    top: 0,
    zIndex: 3,
    background: "white",
    paddingBottom: 10,
    width: "100%",
  },
  fileRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dividerMargins: {
    marginLeft: 20,
    marginRight: 20,
  },
});

type Props = {
  formularyFiles: Array<HPMSFileInfo>;
  onAddFile: (file: HPMSFileToUpload) => void;
  onRemoveFile: (fileType: APIFileType) => void;
  areFilesValidated: boolean;
};

export const SecondStep: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleAddFile = (type: APIFileType) => (file: File) => {
    props.onAddFile({ file, type });
  };

  const handleRemoveFile = (fileType: APIFileType) => () => {
    props.onRemoveFile(fileType);
  };

  const getExistingFile = (fileType: APIFileType) => {
    const fileName = props.formularyFiles.find((file) => file.type === fileType)?.name;
    return fileName === undefined
      ? undefined
      : {
          fileName,
          onRemoveFile: handleRemoveFile(fileType),
        };
  };

  return (
    <div className={classes.stepWrapper}>
      <div style={{ width: "25%" }}>
        <Typography variant="body2">Files:</Typography>
        {props.formularyFiles.map((formularyFile) => (
          <div key={formularyFile.name} className={classes.fileRow}>
            <Typography noWrap>{formularyFile.name}</Typography>
            <Check
              htmlColor={green[500]}
              visibility={props.areFilesValidated ? "show" : "hidden"}
            />
          </div>
        ))}
      </div>
      <Divider orientation="vertical" className={classes.dividerMargins} />
      <div className={classes.secondStepFiles}>
        <div className={classes.stickyInstructions}>
          <Typography align="center">
            Now, add any additional files you have that supplement your main file.
          </Typography>
        </div>
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <OptionsTable
            settings={[
              {
                label: "Prior authorization",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_PAFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_PAFile)}
                  />
                ),
              },
              {
                label: "Step therapy",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_STFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_STFile)}
                  />
                ),
              },
              {
                label: "Free first fill",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_FFFFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_FFFFile)}
                  />
                ),
              },
              {
                label: "Partial gap coverage",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_PGCFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_PGCFile)}
                  />
                ),
              },
              {
                label: "Home infusion",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_HIFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_HIFile)}
                  />
                ),
              },
              {
                label: "Excluded drugs",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_EDFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_EDFile)}
                  />
                ),
              },
              {
                label: "Over the counter",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_OTCFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_OTCFile)}
                  />
                ),
              },
              {
                label: "Indication based coverage",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_IBCFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_IBCFile)}
                  />
                ),
              },
              {
                label: "Medicare-Medicaid Plan Additional Demonstrational Drug",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_MMPFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_MMPFile)}
                  />
                ),
              },
              {
                label: "Value based insurance design",
                options: (
                  <FilePicker
                    handleFile={handleAddFile(APIFileType.HPMS_VBIDFile)}
                    existingFile={getExistingFile(APIFileType.HPMS_VBIDFile)}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
