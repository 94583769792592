const USCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
});

const USWholeNumberCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export function formatMoneyToUSD(money: number): string {
  return USCurrencyFormatter.format(money);
}

export function formuatMoneyToUSD_withWholeValues(money: number): string {
  return money % 1 === 0
    ? USWholeNumberCurrencyFormatter.format(money)
    : USCurrencyFormatter.format(money);
}
