import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Home } from "../dashboard/Home";
import { DrugListRoutes } from "../Flex/DrugLists/DrugListRoutes";
import { DrugSearch } from "../Flex/DrugSearch/DrugSearchIndex";
import { FormularyRoutes } from "../Flex/FormularyRoutes";
import PageGraphic from "../styles/Graphics/pagegraphic.svg";
import { TemplatePageRoutes } from "../Templates/TemplateRoutes";
import { NotFound } from "./screens/404";
import { BenefitsRoutes } from "../Benefits/BenefitsRoutes";
import { isAppSectionEnabled } from "../utils/FeatureFlags";
import { AppSections } from "../types/AppTypes";
import { NetworkRoutes } from "../Network/NetworkRoutes";
import { AccountAreaRoutes } from "../AccountArea/AccountAreaRoutes";

const useStyles = makeStyles({
  main: {
    height: "100vh",
    width: "100%",
    overflow: "auto",
    backgroundPosition: "right bottom",
    backgroundAttachment: "fixed",
    background: `no-repeat url(${PageGraphic})`,
    backgroundSize: "80%",
    display: "grid",
    gridTemplateColumns: "repeat(16, 1fr)",
  },
});

export const MainContentRoutes: React.FC = () => {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {isAppSectionEnabled(AppSections.TEMPLATES) && (
          <Route path="/templates">
            <TemplatePageRoutes />
          </Route>
        )}
        {isAppSectionEnabled(AppSections.FORMULARIES) && (
          <Route path="/formularies">
            <FormularyRoutes />
          </Route>
        )}
        {isAppSectionEnabled(AppSections.DRUG_LISTS) && (
          <Route path="/lists">
            <DrugListRoutes />
          </Route>
        )}
        {isAppSectionEnabled(AppSections.DRUG_SEARCH) && (
          <Route path="/drugs">
            <DrugSearch />
          </Route>
        )}
        {isAppSectionEnabled(AppSections.BENEFITS) && (
          <Route path="/benefits">
            <BenefitsRoutes />
          </Route>
        )}
        {isAppSectionEnabled(AppSections.NETWORK) && (
          <Route path="/networks">
            <NetworkRoutes />
          </Route>
        )}

        <Route path="/account">
          <AccountAreaRoutes />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </main>
  );
};
