import * as React from "react";
import { Typography, Grid, Switch } from "@material-ui/core";

// TODO - patch the any type present in the onChange handler
type Props = {
  switchProps: {
    onChange: (event: any) => void;
    checked: boolean;
    id?: string;
  };
  yesLabel?: string;
  noLabel?: string;
  position?: "center" | "end";
  disabled?: boolean;
};

export const LabelSwitch: React.FC<Props> = (props) => {
  const { switchProps } = props;
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      justify={props.position === undefined || props.position === "center" ? "center" : "flex-end"}
      spacing={1}
    >
      <Grid item>
        <Typography
          color={
            props.disabled ? "textSecondary" : switchProps.checked ? "textSecondary" : "textPrimary"
          }
          variant={switchProps.checked ? "body1" : "body2"}
        >
          {props.noLabel ? props.noLabel : "NO"}
        </Typography>
      </Grid>
      <Grid item>
        <Switch disabled={props.disabled} color="primary" {...switchProps} />
      </Grid>
      <Grid item>
        <Typography
          color={
            props.disabled ? "textSecondary" : switchProps.checked ? "primary" : "textSecondary"
          }
          variant={switchProps.checked ? "body2" : "body1"}
        >
          {props.yesLabel ? props.yesLabel : "YES"}
        </Typography>
      </Grid>
    </Grid>
  );
};
