import {
  Checkbox,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLessRounded";
import ExpandMore from "@material-ui/icons/ExpandMoreRounded";
import EditPermissionsIcon from "@material-ui/icons/EditRounded";

import * as React from "react";
import { NextAccount, NextSubAccount } from "../types";

const useStyles = makeStyles((theme) => {
  return {
    subAccountItem: {
      marginLeft: theme.spacing(4),
    },
    accountHeader: {
      paddingLeft: 0,
      paddingTop: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
  };
});

type Props = {
  account: NextAccount;
  disabled?: boolean;
  selectedSubAccounts: Array<string>;
  onSubAccountsChanged: (selectedSubAccounts: Array<string>) => void;
  onEditUserPermissions: (subAccount: NextSubAccount) => void;
};

export const AccountListItem: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [showSubAccounts, setShowSubAccounts] = React.useState<boolean>(false);
  const subAccountIds = props.account.subAccounts.items.map((subAccount) => subAccount.id);

  const someSubAccountsSelected = subAccountIds.some((id) =>
    props.selectedSubAccounts.includes(id)
  );
  const allSubAccountsSelected = !subAccountIds.some(
    (id) => !props.selectedSubAccounts.includes(id)
  );

  const modifyAll = () => {
    if (!allSubAccountsSelected) {
      const toAdd = subAccountIds.filter(
        (subAccountId) => !props.selectedSubAccounts.includes(subAccountId)
      );

      props.onSubAccountsChanged([...props.selectedSubAccounts, ...toAdd]);
    } else {
      props.onSubAccountsChanged(
        props.selectedSubAccounts.filter((id) => !subAccountIds.includes(id))
      );
    }
  };

  return (
    <div key={props.account.id}>
      <ListSubheader className={classes.accountHeader}>
        <ListItem key={`account-header-${props.account.id}`}>
          <ListItemIcon>
            <Checkbox
              name="accountSelected"
              disabled={props.disabled}
              indeterminate={!allSubAccountsSelected && someSubAccountsSelected}
              onChange={modifyAll}
              checked={someSubAccountsSelected}
            />
          </ListItemIcon>
          <ListItemText
            primary={props.account.name}
            onClick={() => setShowSubAccounts(!showSubAccounts)}
          />
          <ListItemSecondaryAction onClick={() => setShowSubAccounts(!showSubAccounts)}>
            {showSubAccounts ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        </ListItem>
      </ListSubheader>
      <Collapse in={showSubAccounts}>
        {props.account.subAccounts.items.map((subAccount) => {
          const onSubAccountSelectionChanged = (event: any, checked: boolean) => {
            if (checked) {
              props.onSubAccountsChanged([...props.selectedSubAccounts, subAccount.id]);
            } else {
              props.onSubAccountsChanged(
                props.selectedSubAccounts.filter((id) => id !== subAccount.id)
              );
            }
          };
          return (
            <ListItem className={classes.subAccountItem} key={subAccount.id}>
              <ListItemIcon>
                <Checkbox
                  name="checkedC"
                  disabled={props.disabled}
                  onChange={onSubAccountSelectionChanged}
                  checked={props.selectedSubAccounts.some((id) => subAccount.id === id)}
                />
              </ListItemIcon>
              <ListItemText primary={subAccount.name} />
              <ListItemSecondaryAction>
                <Tooltip title="Edit Permissions">
                  <IconButton onClick={() => props.onEditUserPermissions(subAccount)}>
                    <EditPermissionsIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </Collapse>
    </div>
  );
};
