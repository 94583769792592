import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import WebTemplatesIcon from "@material-ui/icons/PublicRounded";
import produce from "immer";
import * as React from "react";
import { FlexCriteriaIcon, FlexFormularyIcon } from "../../../../components/Icons/customIcons";
import { CenteredCircularLoading } from "../../../../components/loading/CenteredCircularLoading";
import { UserContext } from "../../../../components/UserContext";
import { ApiTemplateType } from "../../../../types/ApiTypes";
import { PublishingTemplate } from "./DocumentTypes";

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: "white",
    color: theme.palette.text.primary,
  },
}));

type Props = {
  isLoading: boolean;
  templates: Map<string, Array<PublishingTemplate>>;
  onClickTemplate: (template: PublishingTemplate) => void;
};

export const TemplateList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const renderTemplates = () => {
    const listItems: Array<JSX.Element> = [];
    props.templates.forEach((templates, header) => {
      listItems.push(
        <React.Fragment key={header}>
          <ListSubheader color="primary" className={classes.listSubheader}>
            {header}
            <Divider />
          </ListSubheader>
          {produce(templates, (draft) => {
            draft.sort((a, b) => (a.name > b.name ? 1 : -1));
          }).map((template) => {
            return (
              <ListItem
                button
                key={template.id}
                onClick={() => {
                  props.onClickTemplate(template);
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    {template.templateType === ApiTemplateType.WebSearch ? (
                      <WebTemplatesIcon />
                    ) : template.templateType === ApiTemplateType.Criteria ? (
                      <FlexCriteriaIcon />
                    ) : (
                      <FlexFormularyIcon />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography noWrap title={template.name} variant="body2">
                    {template.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </React.Fragment>
      );
    });
    return listItems;
  };

  return props.isLoading ? (
    <CenteredCircularLoading />
  ) : (
    <List dense={contentSpacing === "dense"} disablePadding>
      {renderTemplates()}
    </List>
  );
};
