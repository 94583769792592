import { useQuery } from "@apollo/client";
import { Divider, IconButton, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import BackButton from "@material-ui/icons/ArrowBackRounded";
import compareDesc from "date-fns/compareDesc";
import produce from "immer";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { HeaderSkeleton } from "../../../components/loading/Skeletons";
import { UserContext } from "../../../components/UserContext";
import { apiFormatDate, awsDateStringToDate } from "../../../utils/Date";
import { APITierList, GetListInput, GetListResponse, GET_LIST } from "../api";
import { useDrugListRouteParams } from "../DrugListRouteUtils";
import { ListTypeRoute } from "./../DrugListRouteUtils";
import { TierListRules } from "./Rules/TierListRules";
import { TierListSetup } from "./Setup/TierListSetup";
import { TierListView } from "./View/TierListView";

enum TierListTabs {
  SETUP = "Setup",
  RULES = "Rules",
  VIEW = "View",
}

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
}));

export const ManageTierDrugList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { drugListId } = useDrugListRouteParams();
  const {
    user: { isManagingMedicare },
  } = React.useContext(UserContext);

  const [activeTab, setActiveTab] = React.useState<TierListTabs>(
    drugListId ? TierListTabs.VIEW : TierListTabs.SETUP
  );
  const [viewDate, setViewDate] = React.useState(new Date());
  const [asOfDate, setAsOfDate] = React.useState(new Date());
  const [drugList, setDrugList] = React.useState<APITierList | undefined>(undefined);

  const isNew = drugListId === undefined;

  const { loading, refetch } = useQuery<GetListResponse<APITierList>, GetListInput>(GET_LIST, {
    skip: isNew,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: drugListId as string,
      rulesInput: {
        asOfDate: apiFormatDate(asOfDate),
      },
      drugsInput: {
        asOfDate: apiFormatDate(asOfDate),
      },
    },
    onCompleted: (res) => {
      if (isNew === false) {
        const sortedResponse = produce(res, (draft) => {
          draft.list.rules.items.sort((a, b) =>
            a.createdAt && b.createdAt
              ? compareDesc(awsDateStringToDate(a.createdAt), awsDateStringToDate(b.createdAt))
              : 0
          );
        });
        setDrugList(sortedResponse.list);
      }
    },
  });

  const handleCreateList = (drugListId: string) => {
    history.push(`${ListTypeRoute.TIER}/edit/${drugListId}`);
    setActiveTab(TierListTabs.RULES);
  };

  const TabPanel = (props: React.PropsWithChildren<{ value: TierListTabs }>) => {
    const { children, value, ...other } = props;
    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  const handleChangeAsOfDate = (date: Date) => {
    setAsOfDate(date);
    refetchList();
  };

  const refetchList = () => {
    if (drugListId) {
      refetch({
        id: drugListId,
        rulesInput: {
          asOfDate: apiFormatDate(asOfDate),
        },
        drugsInput: {
          asOfDate: apiFormatDate(asOfDate),
        },
      });
    }
  };

  const Header = () => {
    if (isNew) {
      return <Typography>{`New ${isManagingMedicare ? "Medicare" : ""} Tier List`}</Typography>;
    } else if (loading) {
      return (
        <div style={{ width: "100%" }}>
          <HeaderSkeleton headerVariant="h4" />
        </div>
      );
    } else if (drugList?.name) {
      return (
        <Typography variant="h4" color="primary">
          {drugList?.name}
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.fullWidthPageArea}>
      <div className={classes.headerArea}>
        <IconButton onClick={() => history.push(ListTypeRoute.TIER)}>
          <BackButton />
        </IconButton>
        <Header />
      </div>
      <Divider variant="middle" />
      <Tabs
        centered
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, value) => setActiveTab(value)}
        aria-label="formulary navigation tabs"
      >
        {Object.values(TierListTabs).map((tabValue, index) => (
          <Tab
            key={index}
            label={<Typography color="inherit">{tabValue}</Typography>}
            value={tabValue}
            className={classes.tabText}
            disabled={tabValue !== TierListTabs.SETUP && !Boolean(drugListId)}
          />
        ))}
      </Tabs>
      <TabPanel value={TierListTabs.SETUP}>
        <TierListSetup
          drugList={drugList as APITierList}
          isLoading={loading}
          onCreateList={handleCreateList}
          onUpdateList={refetchList}
        />
      </TabPanel>
      <TabPanel value={TierListTabs.RULES}>
        <TierListRules
          rules={drugList?.rules.items ?? []}
          asOfDate={asOfDate}
          isLoading={loading}
          onTerminateRule={refetchList}
          onChangeAsOfDate={handleChangeAsOfDate}
          onAddRule={refetchList}
        />
      </TabPanel>
      {drugListId && (
        <TabPanel value={TierListTabs.VIEW}>
          <TierListView
            listId={drugListId}
            isLoading={loading}
            onDateChange={setViewDate}
            viewDate={viewDate}
          />
        </TabPanel>
      )}
    </div>
  );
};
