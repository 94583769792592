import * as React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { makeStyles } from "@material-ui/core";
import { HorizontalSortableItem } from "./HorizontalSortableItem";
import { SortableItem } from "./HorizontalListTypes";

const useStyles = makeStyles({
  listRoot: {
    display: "flex",
    flexDirection: "row",
    listStyle: "none",
    alignContent: "space-between",
    height: "100%",
    borderRadius: "5px",
    boxShadow: "0.5px 0.5px 2px rgba(0, 0, 0, 0.4)",
    marginLeft: "10px",
    "&>div>p": { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
  },
  listRootDisabled: {
    backgroundColor: "lightgrey",
  },
});

interface Props {
  items: Array<SortableItem>;
  disabled: boolean;
}

const List: React.FC<Props> = ({ items, disabled }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.listRoot} ${disabled ? classes.listRootDisabled : ""}`}>
      {items.map((item, index) => (
        <HorizontalSortableItem key={`item-${index}`} index={index} item={item} />
      ))}
    </div>
  );
};

export const HorizontalSortableList = SortableContainer(List);
