import Auth from "@aws-amplify/auth";
import { CssBaseline, Grid, Paper } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import logo from "../styles/Graphics/drgLogoWhite.svg";
import loginBackground from "../styles/Graphics/loginBG.jpg";
import { getAppTheme } from "../styles/Theme";
import { Security } from "../types/AppTypes";
import { LoginForm } from "./Forms/LoginForm";
import { SubAccount } from "./LoginTypes";
import { ModeSelectionForm } from "./Forms/ModeSelectionForm";
import { NewPasswordForm } from "./Forms/NewPasswordForm";
import { SubaccountForm } from "./Forms/SubaccountForm";
import { UserAgreementForm } from "./Forms/UserAgreementForm";
import { ForgotPasswordForm } from "./Forms/ForgotPasswordForm";
import { AdminDomains } from "../types/AppTypes";
import Cookies from "universal-cookie";

const useStyle = makeStyles({
  root: {
    height: "100vh",
  },
  formWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 400,
  },
  logo: {
    width: "50rem",
  },
  image: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: `rgb(22, 24, 26, 0.5)`,
    "&:before": {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
      content: `""`,
      backgroundSize: "cover",
      backgroundImage: `url(${loginBackground})`,
      backgroundPosition: "top",
    },
  },
});

type LoginForms =
  | { form: "login" }
  | { form: "change password" }
  | { form: "forgot password" }
  | {
      form: "select subaccount";
      availableSubAccounts: Array<SubAccount>;
    }
  | { form: "medicare mode selection"; modes: Array<string> }
  | {
      form: "user agreement";
      userTitle: string;
      customerName?: string;
      accountName: string;
      agreementVersion: string;
      agreementUrl: string;
    };

export const Login: React.FC = () => {
  const classes = useStyle();
  const [user, setUser] = React.useState<any | undefined>(undefined);
  const [email, setEmail] = React.useState("");
  const [activeForm, setActiveForm] = React.useState<LoginForms>({ form: "login" });

  if (typeof email !== "undefined" && email) {
    const domain = email.substring(email.lastIndexOf("@") + 1);
    const cookies = new Cookies();

    if (domain === AdminDomains.CLARIVATE) {
      cookies.set("isAdmin", "true", { path: "/" });
      console.log(cookies.get("isAdmin"));
    } else {
      cookies.set("isAdmin", "false", { path: "/" });
      console.log(cookies.get("isAdmin"));
    }
  }

  const selectSubaccount = async (subAccountId: string, user: any) => {
    await Auth.sendCustomChallengeAnswer(user, subAccountId);
    if (user.challengeName) {
      handleChallenges(user);
    } else {
      window.location.reload();
    }
  };

  const selectMode = async (mode: string, user: any) => {
    await Auth.sendCustomChallengeAnswer(user, mode);
    if (user.challengeName) {
      handleChallenges(user);
    } else {
      window.location.reload();
    }
  };

  const changePassword = (newPassword: string) => {
    return Auth.completeNewPassword(user, newPassword, {}).then(() => {
      if (user.challengeName) {
        handleChallenges(user);
      } else {
        window.location.reload();
      }
    });
  };

  const changePasswordWithCode = (userName: string, code: string, newPassword: string) => {
    return Auth.forgotPasswordSubmit(userName, code, newPassword).then(() => {
      window.location.reload();
    });
  };

  const acceptUserAgreement = async (passedInUser?: any) => {
    if (passedInUser) {
      await Auth.sendCustomChallengeAnswer(passedInUser, JSON.stringify({ agreed: true }));
    } else {
      await Auth.sendCustomChallengeAnswer(user, JSON.stringify({ agreed: true }));
    }
    if (user.challengeName) {
      handleChallenges(user);
    } else {
      window.location.reload();
    }
  };

  const declineUserAgreement = async () => {
    await Auth.signOut();
    window.location.reload();
  };

  const handleChallenges = (user: any) => {
    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      setActiveForm({ form: "change password" });
    } else if (user.challengeName === "CUSTOM_CHALLENGE") {
      if (user.challengeParam.challengeType === "SUB_ACCOUNT_SELECTION") {
        const availableSubAccounts: Array<SubAccount> = JSON.parse(
          user.challengeParam.availableSubAccounts
        );
        if (availableSubAccounts.length === 1) {
          selectSubaccount(availableSubAccounts[0].id, user);
        } else {
          setActiveForm({ form: "select subaccount", availableSubAccounts });
        }
      } else if (user.challengeParam.challengeType === "USER_AGREEMENT") {
        const {
          customerName,
          accountName,
          userAgreementUrl,
          userAgreementVersion,
          userTitle,
        } = user.challengeParam;
        setActiveForm({
          form: "user agreement",
          customerName,
          accountName,
          userTitle,
          agreementUrl: userAgreementUrl,
          agreementVersion: userAgreementVersion,
        });
      } else if (user.challengeParam.challengeType === "MEDICARE_MODE_SELECTION") {
        const apiModes: unknown = user?.challengeParam?.availableModes;
        const availableModes: Array<string> =
          apiModes === undefined
            ? []
            : typeof apiModes === "string"
            ? JSON.parse(apiModes)
            : apiModes;
        if (Array.isArray(availableModes)) {
          if (availableModes.length > 1) {
            setActiveForm({ form: "medicare mode selection", modes: availableModes });
          } else if (availableModes.length === 1) {
            selectMode(availableModes[0], user);
          } else {
            window.location.reload();
          }
        } else {
          window.location.reload();
        }
      }
    }
  };

  const handleRedirectToLogin = async () => {
    await Auth.signOut();
    window.location.reload();
  };

  const loginWithPassword = (password: string) => {
    return Auth.signIn(email, password).then((user) => {
      setUser(user);
      if (user.challengeName) {
        handleChallenges(user);
      } else {
        window.location.reload();
      }
    });
  };

  const renderActiveForm = () => {
    switch (activeForm.form) {
      case "login":
        return (
          <LoginForm
            email={email}
            onChangeEmail={(newEmail) => {
              setEmail(newEmail);
            }}
            onLogin={loginWithPassword}
            onForgotPassword={() => {
              setActiveForm({ form: "forgot password" });
            }}
          />
        );
      case "change password":
        return (
          <NewPasswordForm onChangePassword={changePassword} onRedirect={handleRedirectToLogin} />
        );
      case "forgot password":
        return (
          <ForgotPasswordForm
            email={email}
            onChangeEmail={(newEmail) => {
              setEmail(newEmail);
            }}
            onChangePassword={changePasswordWithCode}
            onRedirect={handleRedirectToLogin}
          />
        );
      case "select subaccount":
        return (
          <SubaccountForm
            availableSubAccounts={activeForm.availableSubAccounts}
            onSelectSubaccount={(subaccountId) => selectSubaccount(subaccountId, user)}
            onBackClick={() => {
              setActiveForm({ form: "login" });
            }}
          />
        );
      case "user agreement":
        return (
          <UserAgreementForm
            email={email}
            customerName={activeForm.customerName ?? activeForm.accountName}
            title={activeForm.userTitle}
            agreementUrl={activeForm.agreementUrl}
            agreementVersion={activeForm.agreementVersion}
            onAcceptUserAgreement={acceptUserAgreement}
            onDeclineUserAgreement={declineUserAgreement}
          />
        );
      case "medicare mode selection":
        return (
          <ModeSelectionForm
            modes={activeForm.modes}
            onSelectMode={(mode) => selectMode(mode, user)}
          />
        );
    }
  };

  return (
    <ThemeProvider theme={getAppTheme(Security.LOGIN)}>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={8} className={classes.image}>
          <img className={classes.logo} src={logo} alt="DRG Adaptive" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
          className={classes.formWrapper}
        >
          <div className={classes.loginForm}>{renderActiveForm()}</div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
