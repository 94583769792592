import * as React from "react";
import { CircularProgress, makeStyles, Zoom, Typography } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import { PreviewStatus } from "../../types/PreviewTypes";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface Props {
  status: PreviewStatus;
  style?: React.CSSProperties;
}

export const PdfPreview: React.FC<Props> = (props) => {
  const classes = useStyles();

  const renderContent = () => {
    switch (props.status.type) {
      case "error":
        return (
          <Zoom in>
            <div style={{ textAlign: "center" }}>
              <ErrorIcon color="primary" style={{ marginBottom: "2rem", fontSize: "3.5rem" }} />

              <Typography variant="h5">There was an error loading this preview.</Typography>
            </div>
          </Zoom>
        );
      case "loading":
        return <CircularProgress />;
      case "preview":
        return <NativePDFPreview base64Content={props.status.base64Content} />;
      case "init":
        return props.status.content;
    }
  };

  return (
    <div className={classes.root} style={props.style ? props.style : {}}>
      {renderContent()}
    </div>
  );
};

interface NativePDFPreviewProps {
  base64Content: string;
}

const NativePDFPreview: React.FC<NativePDFPreviewProps> = (props) => {
  return (
    <iframe
      title="template preview"
      src={`data:application/pdf;base64,${props.base64Content}#pagemode=none`}
      style={{ width: "100%", height: "100%", borderWidth: 0 }}
    ></iframe>
  );
};
