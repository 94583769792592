import * as React from "react";
import { makeStyles, Typography, Fade } from "@material-ui/core";

const useStyles = makeStyles({
  formRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
  formItem: {
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "0px",
    textAlign: "left",
    alignItems: "flex-start",
  },
});

interface Props {
  columnName: string;
  activeColumn: boolean;
  columnHidden?: boolean;
  topContent?: React.ComponentType | JSX.Element | string;
}

export const ColumnSettingsForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.formRoot} style={{ display: props.activeColumn ? "block" : "none" }}>
      <Typography className={classes.formItem} variant="h6">
        {`${props.columnName} Options ${props.columnHidden ? "(Hidden)" : ""}`}
      </Typography>
      {props.topContent}
      <Fade in={!props.columnHidden}>
        <div className={classes.formRoot}>{props.children}</div>
      </Fade>
    </div>
  );
};
