export enum TEMPLATE_TYPES {
  FORMULARY = "formulary",
  CRITERIA = "criteria",
  WEB_SEARCH = "web search",
}

export type EditTemplateActions = "copy" | "new" | "edit";

export type TemplateStatus<T> =
  | { status: "loading" }
  | { status: "available"; template: T }
  | { status: "saving"; template: T };
