import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Warning from "@material-ui/icons/WarningRounded";

import { useDialogStyles } from "./DialogStyles";

type Props = Readonly<{
  isOpen: boolean;
  primaryActionName: string;
  primaryActionHandler: () => void;
  secondaryActionName: string;
  secondaryActionHandler: () => void;
  onExitHandler?: () => void;
  dialogIcon?: React.ComponentType<SvgIconProps> | JSX.Element;
  dialogTitle?: JSX.Element | React.ComponentType | string;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
  disabled?: boolean;
}>;

export const TwoActionDialog: React.FC<Props> = (props) => {
  const classes = useDialogStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.isOpen}
      PaperProps={{ classes: { root: classes.dialogBox } }}
      onExit={props.onExitHandler ? props.onExitHandler : () => {}}
      maxWidth={props.maxWidth}
    >
      <DialogTitle disableTypography className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleIconArea}>
          {props.dialogIcon ? props.dialogIcon : <Warning fontSize="large" color="inherit" />}
        </div>
        <Typography variant="h6" className={classes.dialogTitleTextArea}>
          {props.dialogTitle ? props.dialogTitle : ""}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContentArea}>{props.children}</DialogContent>
      <DialogActions>
        <Button
          onClick={props.secondaryActionHandler}
          className={classes.secondaryButton}
          color="primary"
        >
          {props.secondaryActionName}
        </Button>
        <Button
          onClick={props.primaryActionHandler}
          className={classes.primaryButton}
          color="primary"
          disabled={props.disabled}
        >
          {props.primaryActionName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
