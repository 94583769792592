import { Input, makeStyles, MenuItem, Select, Tab, Tabs, TextField } from "@material-ui/core";
import AlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import AlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import AlignRightIcon from "@material-ui/icons/FormatAlignRight";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { HideButton } from "../../../components/buttons/HideButton";
import { EditorDialog } from "../../../components/dialogs/EditorDialog";
import { INDEX_SETTINGS, PAGE_SETTINGS } from "../../FieldNameConstant";
import { FullFormularyTemplate, HORIZONTAL_ALIGNMENT } from "../types";
import { ConfigOptionsTable } from "../../../components/tables/ConfigOptionsTable";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  headerFooterButtons: {
    marginTop: 15,
    padding: "10px 8px",
    width: "fit-content",
    textTransform: "capitalize",
  },
  textAlignButtons: {
    width: "fit-content",
    textTransform: "capitalize",
  },
  switchEdgeFix: {
    marginLeft: "-12px",
  },
  formControlLabel: {
    alignItems: "flex-start",
    marginLeft: 0,
    marginTop: 15,
    "&:first-child": {
      marginTop: 0,
    },
  },
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  icon: {
    fontSize: 20,
  },
  toolbar: {
    width: "100%",
    height: "63px",
  },
  tabRoot: {
    minWidth: 80,
    textTransform: "capitalize",
  },
  centerTabs: {
    justifyContent: "center",
  },
}));

type Props = {
  titleAlignment: HORIZONTAL_ALIGNMENT;
  showIndex: boolean;
};

export const IndexSettings: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { getValues, watch, register, setValue } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);
  console.log("index view only? ", isViewOnly);

  watch([
    INDEX_SETTINGS.HEADER_BASE_64,
    INDEX_SETTINGS.FOOTER_BASE_64,
    INDEX_SETTINGS.TITLE_ALIGNMENT,
    INDEX_SETTINGS.SHOW_INDEX,
  ]);

  const updateHeaderContent = (newContent: string) => {
    setValue(INDEX_SETTINGS.HEADER_BASE_64, newContent);
  };

  const updateFooterContent = (newContent: string) => {
    setValue(INDEX_SETTINGS.FOOTER_BASE_64, newContent);
  };

  const isDisabled = !props.showIndex;

  return (
    <div id="index-settings" className={classes.root} data-testid="index-settings">
      <Controller
        as={
          <HideButton
            isHidden={isDisabled}
            disabled={isViewOnly}
            onHide={() => {
              setValue(INDEX_SETTINGS.SHOW_INDEX, false);
            }}
            onShow={() => {
              setValue(INDEX_SETTINGS.SHOW_INDEX, true);
            }}
          />
        }
        name={INDEX_SETTINGS.SHOW_INDEX}
      />
      <ConfigOptionsTable
        disabled={isDisabled}
        settings={[
          {
            label: "Title",
            options: (
              <Controller
                as={
                  <TextField
                    disabled={isViewOnly || isDisabled}
                    margin="normal"
                    data-testid="Custom Title"
                    name="indexSettings.indexTitle"
                    style={{ width: "200px" }}
                    InputProps={{ classes: { focused: classes.themedUnderline } }}
                  />
                }
                name={INDEX_SETTINGS.TITLE}
              />
            ),
          },
          {
            label: "Title text alignment",
            options: (
              <>
                <div style={{ display: "none" }}>
                  <input
                    type="radio"
                    disabled={isViewOnly}
                    name={INDEX_SETTINGS.TITLE_ALIGNMENT}
                    value={HORIZONTAL_ALIGNMENT.LEFT}
                    ref={register}
                  />
                  <input
                    type="radio"
                    disabled={isViewOnly}
                    name={INDEX_SETTINGS.TITLE_ALIGNMENT}
                    value={HORIZONTAL_ALIGNMENT.CENTER}
                    ref={register}
                  />
                  <input
                    type="radio"
                    disabled={isViewOnly}
                    name={INDEX_SETTINGS.TITLE_ALIGNMENT}
                    value={HORIZONTAL_ALIGNMENT.RIGHT}
                    ref={register}
                  />
                </div>

                <Tabs
                  disabled={isViewOnly || isDisabled}
                  value={props.titleAlignment || HORIZONTAL_ALIGNMENT.CENTER}
                  className={classes.toolbar}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(_, val) => {
                    if (!isViewOnly) {
                      setValue(INDEX_SETTINGS.TITLE_ALIGNMENT, val);
                    }
                  }}
                >
                  <Tab
                    disabled={isViewOnly || isDisabled}
                    icon={<AlignLeftIcon className={classes.icon} />}
                    label="Left"
                    value={HORIZONTAL_ALIGNMENT.LEFT}
                    classes={{ root: classes.tabRoot }}
                  />
                  <Tab
                    disabled={isViewOnly || isDisabled}
                    icon={<AlignCenterIcon className={classes.icon} />}
                    label="Center"
                    value={HORIZONTAL_ALIGNMENT.CENTER}
                    classes={{ root: classes.tabRoot }}
                  />
                  <Tab
                    disabled={isViewOnly || isDisabled}
                    icon={<AlignRightIcon className={classes.icon} />}
                    label="Right"
                    value={HORIZONTAL_ALIGNMENT.RIGHT}
                    classes={{ root: classes.tabRoot }}
                  />
                </Tabs>
              </>
            ),
          },
          {
            label: "Number of columns",
            options: (
              <Controller
                as={
                  <Select
                    data-testid="Number of columns"
                    disabled={isViewOnly || isDisabled}
                    input={
                      <Input disabled={isViewOnly} classes={{ focused: classes.themedUnderline }} />
                    }
                  >
                    {NUMBER_OF_COLUMNS_OPTIONS.map((numberOfColumnsOption) => (
                      <MenuItem key={numberOfColumnsOption} value={numberOfColumnsOption}>
                        {numberOfColumnsOption}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name={INDEX_SETTINGS.NUMBER_OF_COLUMNS}
              />
            ),
          },
          {
            label: "Font size",
            options: (
              <Controller
                as={
                  <Select
                    data-testid="Font size"
                    disabled={isViewOnly || isDisabled}
                    input={<Input classes={{ focused: classes.themedUnderline }} />}
                  >
                    {INDEX_FONT_SIZES.map((fontSize) => (
                      <MenuItem key={fontSize} value={fontSize}>
                        {fontSize}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name={INDEX_SETTINGS.FONT_SIZE}
              />
            ),
          },
        ]}
      />
      <Controller
        as={
          <EditorDialog
            title="Index Header"
            disabled={isViewOnly || isDisabled}
            templateFont={getValues(PAGE_SETTINGS.FONT)}
            leftMargin={getValues(PAGE_SETTINGS.LEFT_MARGIN)}
            rightMargin={getValues(PAGE_SETTINGS.RIGHT_MARGIN)}
            units={getValues(PAGE_SETTINGS.UNITS)}
            content={getValues(INDEX_SETTINGS.HEADER_BASE_64)}
            updateContent={updateHeaderContent}
          />
        }
        name={INDEX_SETTINGS.HEADER_BASE_64}
      />
      <Controller
        as={
          <EditorDialog
            title="Index Footer"
            disabled={isViewOnly || isDisabled}
            templateFont={getValues(PAGE_SETTINGS.FONT)}
            leftMargin={getValues(PAGE_SETTINGS.LEFT_MARGIN)}
            rightMargin={getValues(PAGE_SETTINGS.RIGHT_MARGIN)}
            units={getValues(PAGE_SETTINGS.UNITS)}
            content={getValues(INDEX_SETTINGS.FOOTER_BASE_64)}
            updateContent={updateFooterContent}
          />
        }
        name={INDEX_SETTINGS.FOOTER_BASE_64}
      />
    </div>
  );
};

const NUMBER_OF_COLUMNS_OPTIONS = [1, 2, 3, 4];
const INDEX_FONT_SIZES = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
