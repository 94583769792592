import { ApolloError, useApolloClient, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { fade, useTheme } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/CreateRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import InfoIcon from "@material-ui/icons/InfoRounded";
import classNames from "classnames";
import * as React from "react";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { BasicDialog } from "../../components/dialogs/BasicDialog";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { CopyIcon } from "../../components/Icons/customIcons";
import { SearchList } from "../../components/lists/SearchList";
import { UserContext } from "../../components/UserContext";
import { awsDateStringToDate, standardUIFormat } from "../../utils/Date";
import {
  AssociatedPlanItem,
  CopyPlanConfigInput,
  CopyPlanConfigResponse,
  COPY_PLAN_CONFIG,
  DeletePlanConfigInput,
  DeletePlanConfigResponse,
  DELETE_PLAN_CONFIG,
  GetAssociatedPlansResponse,
  GET_ASSOCIATED_PLANS,
  ListPlanConfigsResponse,
  LIST_PLAN_CONFIGS,
} from "../api";
import { APIPlanConfigItem } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  previewArea: {
    gridColumn: "5 / span 12",
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(20),
  },
  captionText: {
    alignSelf: "center",
    color: theme.palette.text.disabled,
  },
  previewCardWrapper: {
    display: "flex",
  },
  infoCard: {
    height: "fit-content",
    pointerEvents: "none",
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: "20rem",
  },
  noTableBorder: {
    borderBottom: 0,
  },
  rowLabel: {
    width: "40%",
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButtons: {
    color: theme.palette.error.main,
  },
}));

export function ListPlanConfigsPage(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const Apollo = useApolloClient();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<ApolloError | undefined>(undefined);
  const [selectedPlanConfig, setSelectedPlanConfig] = React.useState<APIPlanConfigItem>();
  const [planConfigs, setPlanConfigs] = React.useState<Array<APIPlanConfigItem>>([]);
  const [associatedPlans, setAssociatedPlans] = React.useState<Array<AssociatedPlanItem>>([]);
  const [showDeleteWarn, setShowDeleteWarn] = React.useState(false);
  const [showCannotDeleteWarn, setShowCannotDeleteWarn] = React.useState(false);

  //#region  Apollo Queries
  const { loading, refetch } = useQuery<ListPlanConfigsResponse>(LIST_PLAN_CONFIGS, {
    onCompleted: (res) => {
      if (res) {
        setPlanConfigs(res.planConfigs.items);
      }
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  const [getAssociatedPlans, { loading: loadingPlans }] = useLazyQuery<
    GetAssociatedPlansResponse,
    { input: { planConfigId: string } }
  >(GET_ASSOCIATED_PLANS, {
    onCompleted: (res) => {
      if (res) {
        setAssociatedPlans(
          res.plans.items.sort((planA, planB) => planA.name.localeCompare(planB.name))
        );
      }
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  const [deletePlan] = useMutation<DeletePlanConfigResponse, DeletePlanConfigInput>(
    DELETE_PLAN_CONFIG,
    {
      onError: (err) => {
        console.error(err);
        setPageErrors(err);
      },
    }
  );

  const [copyPlan] = useMutation<CopyPlanConfigResponse, CopyPlanConfigInput>(COPY_PLAN_CONFIG);
  //#endregion

  const EDIT_MENU_OPTION = {
    optionName: "Edit",
    link: "edit",
    icon: <EditIcon fontSize="small" />,
  };

  const COPY_MENU_OPTION = {
    optionName: "Copy",
    icon: <CopyIcon fontSize="small" />,
    fn: (itemId: string) => {
      handleCopyPlan(itemId);
    },
  };

  const INFO_MENU_OPTION = {
    optionName: "Info",
    link: "view",
    icon: <InfoIcon fontSize="small" />,
  };

  const DELETE_MENU_OPTION = {
    optionName: "Delete",
    icon: <DeleteIcon fontSize="small" />,
    fn: (itemId: string) => {
      handleSelectPlanForDeletion(itemId);
    },
  };

  function handleDeletePlan() {
    if (selectedPlanConfig) {
      deletePlan({
        variables: {
          id: selectedPlanConfig.id,
        },
      }).then(() => {
        setShowDeleteWarn(false);
        refetch().then((res) => {
          if (res.data) {
            setPlanConfigs(res.data.planConfigs.items);
          }
        });
      });
    }
  }

  function handleSelectPlanForDeletion(itemId: string) {
    const foundPlanConfig = planConfigs.find((config) => config.id === itemId);
    if (foundPlanConfig) {
      setSelectedPlanConfig(foundPlanConfig);
      Apollo.query<GetAssociatedPlansResponse, { input: { planConfigId: string } }>({
        query: GET_ASSOCIATED_PLANS,
        variables: {
          input: { planConfigId: foundPlanConfig.id },
        },
        fetchPolicy: "no-cache",
      }).then((res) => {
        if (res.data) {
          setAssociatedPlans(res.data.plans.items);
          if (res.data.plans.items.length !== 0) {
            setShowCannotDeleteWarn(true);
          } else {
            setShowDeleteWarn(true);
          }
        }
      });
    }
  }

  function handlePlanSelected(itemId: string) {
    const foundPlanConfig = planConfigs.find((config) => config.id === itemId);
    if (foundPlanConfig) {
      setSelectedPlanConfig(foundPlanConfig);
      getAssociatedPlans({
        variables: { input: { planConfigId: foundPlanConfig.id } },
      });
    }
  }

  function handleCopyPlan(planId: string) {
    const foundPlanConfig = planConfigs.find((config) => config.id === planId);
    if (foundPlanConfig) {
      let newName = `${foundPlanConfig.name} - Copy`;
      const dupNames = planConfigs.filter((config) => config.name.includes(newName));
      if (dupNames.length > 0) {
        newName = `${newName} (${dupNames.length})`;
      }
      copyPlan({
        variables: {
          id: planId,
          input: {
            name: newName,
          },
        },
      }).then((_) => {
        // NOTE: Refetching the list here would be ideal, but currently a bug within our version of Apollo prevents us from using this with live-refresh.
        // https://github.com/apollographql/react-apollo/issues/3862
        // refetch();
        window.location.reload();
      });
    } else {
      console.error("could not find configId:", planId);
    }
  }

  return (
    <>
      <Paper square className={classes.container}>
        <SearchList
          listData={planConfigs}
          dataLoading={loading}
          onItemSelection={handlePlanSelected}
          menuOptions={[INFO_MENU_OPTION, EDIT_MENU_OPTION, COPY_MENU_OPTION, DELETE_MENU_OPTION]}
          hasAddButton
          addButtonText="New Plan Configuration"
          redirectLink="/benefits/plan-configs"
        />
      </Paper>
      <div className={classes.previewArea}>
        {selectedPlanConfig ? (
          <>
            <Typography variant="caption" className={classes.captionText}>
              Quick view
            </Typography>
            <div className={classes.previewCardWrapper}>
              <Fade in={true} timeout={250}>
                <Card className={classes.infoCard}>
                  <CardHeader title={selectedPlanConfig.name} />
                  <Divider variant="middle" />
                  <CardContent>
                    <Table size="small" style={{ marginTop: 16 }}>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className={classNames(classes.noTableBorder, classes.rowLabel)}
                          >
                            <Typography variant="body2" color="primary">
                              Formulary:
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.noTableBorder}>
                            {selectedPlanConfig.formularyName || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classNames(classes.noTableBorder, classes.rowLabel)}
                          >
                            <Typography variant="body2" color="primary">
                              Network:
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.noTableBorder}>
                            {selectedPlanConfig.networkName || "N/A"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classNames(classes.noTableBorder, classes.rowLabel)}
                          >
                            <Typography variant="body2" color="primary">
                              Created On:
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.noTableBorder}>
                            {standardUIFormat(awsDateStringToDate(selectedPlanConfig.createdOn))}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className={classNames(classes.noTableBorder, classes.rowLabel)}
                          >
                            <Typography variant="body2" color="primary">
                              Created By:
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.noTableBorder}>
                            {selectedPlanConfig.createdBy}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Fade>
              <AssociatedPlansCard
                associatedPlans={associatedPlans}
                contentSpacing={contentSpacing || ""}
                isLoadingAssociatedPlans={loadingPlans}
              />
            </div>
          </>
        ) : (
          <Typography variant="h5" align="center">
            Select a <span style={{ color: theme.palette.primary.main }}>Plan</span> to quickly view
            its properties
          </Typography>
        )}
      </div>
      <BasicDialog
        isOpen={showCannotDeleteWarn}
        dialogTitle="Cannot delete"
        onExitHandler={() => {
          setShowCannotDeleteWarn(false);
        }}
      >
        <Typography>{`${selectedPlanConfig?.name} is associated with ${associatedPlans.length} plans. Cannot delete ${selectedPlanConfig?.name} before it has been removed from the plans it is associated with.`}</Typography>
      </BasicDialog>
      <ConfirmationDialog
        isOpen={showDeleteWarn}
        onYesHandler={handleDeletePlan}
        onNoHandler={() => {
          setShowCannotDeleteWarn(false);
        }}
        dialogTitle={"Delete Plan Config?"}
        dialogContent={
          <Typography>{`Are you sure you want to delete ${selectedPlanConfig?.name}?`}</Typography>
        }
      />
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error on this page. Please wait a moment and refresh or try again. If the
          problem persists, contact your administrator.
        </Typography>
        <Button
          className={classes.errorButtons}
          style={{ width: "fit-content" }}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Divider />
          <Table className={classes.technicalDetailsTable}>
            <TableHead>
              <TableRow key="errorHead">
                <TableCell>
                  <Typography variant="body2" color="error">
                    {pageErrors?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageErrors?.graphQLErrors.map((error, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" color="error">
                      {error.message}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </>
  );
}

const useAssociatedPlansCardStyles = makeStyles((theme) => ({
  cardRoot: {
    margin: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)}px)`,
    minWidth: "20rem",
  },
  cardContent: {
    maxHeight: "20rem",
    overflow: "auto",
  },
}));

interface AssociatedPlansCardProps {
  isLoadingAssociatedPlans: boolean;
  associatedPlans: Array<AssociatedPlanItem>;
  contentSpacing: string;
}

function AssociatedPlansCard(props: AssociatedPlansCardProps): JSX.Element {
  const classes = useAssociatedPlansCardStyles();

  return (
    <Fade in={true} timeout={250}>
      <Card className={classes.cardRoot}>
        <CardHeader title="Associated Plans" />
        <Divider variant="middle" />
        <CardContent className={classes.cardContent}>
          <List dense={props.contentSpacing === "dense"}>
            {props.isLoadingAssociatedPlans ? (
              <ListItem key="finding">
                <ListItemSecondaryAction>
                  <CircularProgress />
                </ListItemSecondaryAction>
                <ListItemText primary="Finding plans..." />
              </ListItem>
            ) : props.associatedPlans.length === 0 ? (
              <ListItem key="noPlans">
                <ListItemText primary="No associated plans" />
              </ListItem>
            ) : (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "10rem" }}>External ID</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.associatedPlans.map((plan) => {
                    return (
                      <TableRow key={plan.id}>
                        <TableCell>{plan.externalId}</TableCell>
                        <TableCell>{plan.name}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </List>
        </CardContent>
      </Card>
    </Fade>
  );
}
