import { useMutation } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import Edit from "@material-ui/icons/EditRounded";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as React from "react";
import { BasicDialog } from "../../components/dialogs/BasicDialog";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { OptionsTable } from "../../components/tables/OptionsTable";
import {
  CreateListInput,
  CreateListResponse,
  CREATE_LIST,
  DrugListType,
} from "../../Flex/DrugLists/api";
import { EXTERNAL_ID_HELP_TEXT } from "../../Flex/helpText";
import { apiFormatDate } from "../../utils/Date";
import { CostShare } from "./CostShareSchema";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  addFormRoot: {
    height: "10rem",
    display: "flex",
    flexDirection: "column",
  },
  addFormSelect: {
    width: "10rem",
    alignSelf: "center",
  },
  addFormConfirm: {
    color: "white",
    width: "5rem",
    alignSelf: "flex-end",
    marginTop: theme.spacing(3),
  },
  createFormRoot: {
    display: "flex",
    flexDirection: "column",
  },
  createFormConfirm: {
    color: "white",
    width: "8rem",
    alignSelf: "flex-end",
    marginTop: theme.spacing(3),
  },
  tabContentContainer: {
    marginTop: theme.spacing(3),
  },
}));

interface BenefitsList {
  name: string;
  externalId: string;
  effectiveDate: Date | null;
  terminationDate: Date | null;
}

interface Props {
  benefitsLists: Array<{ id: string; name: string }>;
  costShare: CostShare;
  readonly?: boolean;
  onAddList: (list: { id: string; name: string }) => void;
}

export function CustomDrugListControls(props: Props): JSX.Element | null {
  const classes = useStyles();

  const [isAddListDialogOpen, setIsAddDialogOpen] = React.useState(false);
  const [selectedBenefitsListId, setSelectedBenefitsListId] = React.useState("");
  const [benefitsList, setBenefitsList] = React.useState<BenefitsList>(EMPTY_BENEFITS_LIST);
  const [activeTab, setActiveTab] = React.useState(0);

  const [createBenefitsList, { loading: isCreatingList }] = useMutation<
    CreateListResponse,
    CreateListInput
  >(CREATE_LIST, {
    onCompleted: (res) => {
      props.onAddList(res.createList);
      handleCloseDialog();
    },
  });

  function handleCloseDialog() {
    setIsAddDialogOpen(false);
    setBenefitsList(EMPTY_BENEFITS_LIST);
    setSelectedBenefitsListId("");
  }
  function handleOpenDialog() {
    setIsAddDialogOpen(true);
  }

  function updateListName(event: React.ChangeEvent<HTMLInputElement>) {
    setBenefitsList({ ...benefitsList, name: event.target.value });
  }
  function updateExternalId(event: React.ChangeEvent<HTMLInputElement>) {
    setBenefitsList({ ...benefitsList, externalId: event.target.value });
  }
  function updateEffectiveDate(date: Date | null) {
    setBenefitsList({ ...benefitsList, effectiveDate: date });
  }
  function updateTerminationDate(date: Date | null) {
    setBenefitsList({ ...benefitsList, terminationDate: date });
  }

  function handleAddList() {
    if (selectedBenefitsListId) {
      const listToAdd = props.benefitsLists.find(
        (benefitsList) => benefitsList.id === selectedBenefitsListId
      );
      if (listToAdd) {
        props.onAddList(listToAdd);
        setIsAddDialogOpen(false);
      }
    }
  }

  function handleCreateList() {
    const formattedEffectiveDate = apiFormatDate(benefitsList.effectiveDate);
    if (formattedEffectiveDate && benefitsList.name) {
      createBenefitsList({
        variables: {
          input: {
            description: "",
            effectiveDate: formattedEffectiveDate,
            externalId: benefitsList.externalId ?? null,
            listType: DrugListType.BENEFITS,
            name: benefitsList.name,
            terminationDate: apiFormatDate(benefitsList.terminationDate),
          },
        },
      });
    }
  }

  const drugListProperties = [
    {
      label: "Name",
      options: (
        <TextField
          required
          value={benefitsList.name}
          onChange={updateListName}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "External ID (Optional)",
      helpText: EXTERNAL_ID_HELP_TEXT,
      options: (
        <TextField
          value={benefitsList.externalId}
          onChange={updateExternalId}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "Effective Date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={benefitsList.effectiveDate}
            onChange={updateEffectiveDate}
            maxDate={benefitsList.terminationDate}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Termination Date (Optional)",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={benefitsList.terminationDate}
            onChange={updateTerminationDate}
            minDate={benefitsList.effectiveDate}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
  ];

  if (Boolean(props.readonly)) {
    return null;
  } else {
    return (
      <>
        <Button color="primary" onClick={handleOpenDialog}>
          Add custom drug list
        </Button>
        <BasicDialog
          closeButtonText="cancel"
          dialogIcon={<Edit />}
          dialogTitle="Add Benefits List"
          isOpen={isAddListDialogOpen}
          onExitHandler={handleCloseDialog}
        >
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
              setActiveTab(newValue);
            }}
          >
            <Tab label="Add" />
            <Tab label="Create" />
          </Tabs>

          {isCreatingList ? (
            <CenteredCircularLoading styles={{ marginTop: "32px" }} />
          ) : (
            <div className={classes.tabContentContainer}>
              <TabPanel activeTabIndex={activeTab} tabIndex={0}>
                <div className={classes.addFormRoot}>
                  <Typography variant="h6">Add existing list</Typography>
                  <FormControl className={classes.addFormSelect}>
                    <InputLabel id="select-benefits-list-label">Benefits List</InputLabel>
                    <Select
                      labelId="select-benefits-list-label"
                      value={selectedBenefitsListId}
                      onChange={(e) => {
                        setSelectedBenefitsListId(e.target.value as string);
                      }}
                    >
                      {props.benefitsLists
                        .filter(
                          (benefitsList) => props.costShare.lists[benefitsList.id] === undefined
                        )
                        .map((benefitsList) => (
                          <MenuItem value={benefitsList.id} key={benefitsList.id}>
                            {benefitsList.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.addFormConfirm}
                    onClick={handleAddList}
                    disabled={!selectedBenefitsListId}
                  >
                    add
                  </Button>
                </div>
              </TabPanel>
              <TabPanel activeTabIndex={activeTab} tabIndex={1}>
                <div className={classes.createFormRoot}>
                  <Typography variant="h6">Create a new list</Typography>
                  <OptionsTable settings={drugListProperties}></OptionsTable>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateList}
                    className={classes.createFormConfirm}
                    disabled={!benefitsList.name || !benefitsList.effectiveDate}
                  >
                    create list
                  </Button>
                </div>
              </TabPanel>
            </div>
          )}
        </BasicDialog>
      </>
    );
  }
}

const EMPTY_BENEFITS_LIST = {
  name: "",
  externalId: "",
  effectiveDate: null,
  terminationDate: null,
};

interface TabPanelProps {
  activeTabIndex: number;
  tabIndex: number;
  children: React.ReactNode;
}

function TabPanel(props: TabPanelProps): JSX.Element {
  return (
    <div role="tabpanel" hidden={props.activeTabIndex !== props.tabIndex}>
      {props.children}
    </div>
  );
}
