import { useQuery } from "@apollo/client";
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { format } from "date-fns";
import * as React from "react";
import { Link } from "react-router-dom";
import { ConfirmationDialog } from "../../../../../components/dialogs/ConfirmationDialog";
import { DragAndDropPriorityList } from "../../../../../components/lists/DragAndDropPriorityList";
import { CenteredCircularLoading } from "../../../../../components/loading/CenteredCircularLoading";
import { UserContext } from "../../../../../components/UserContext";
import { awsDateStringToDate } from "../../../../../utils/Date";
import { APITierList, GetTierListsResponse, GET_TIER_LISTS } from "../../../../DrugLists/api";
import { tiersListRoute } from "../../../../DrugLists/DrugListRouteUtils";
import { FormularyEditingContext } from "../../FormularyEditingContext";
import { FormularyTier } from "../../types";
import {
  FormularyTiersListConfig,
  GetFormularyTiersListConfigInput,
  GetFormularyTiersListConfigResponse,
  GET_FORMULARY_TIERS_LIST_CONFIG,
  SetFormularyTiersListConfigInput,
  TierListItem,
} from "../api";

const useStyles = makeStyles((theme) => ({
  tabContent: {
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    height: "100%",
  },
  drawer: {
    minWidth: 350,
    maxWidth: 500,
  },
  link: {
    textDecoration: "none",
  },
  dragAndDropContainer: {
    marginLeft: `calc(150px + ${theme.spacing(2)}px)`,
  },
  formControl: {
    padding: theme.spacing(2),
  },
}));

interface Props {
  formularyId: string;
  formularyTiers: Array<FormularyTier>;
  onSaveConfig: (configInput: SetFormularyTiersListConfigInput) => void;
}

interface TierMenuState {
  anchorEl: HTMLButtonElement | null;
  tierList: TierListItem | null;
  dialogTier: FormularyTier | null;
}

interface DeleteDialogState {
  show: boolean;
  listName?: string;
  listId?: string;
}

type SimplifiedTierList = Pick<
  APITierList,
  "id" | "name" | "description" | "effectiveDate" | "terminationDate"
>;

const DEFAULT_TIER_DIALOG = { anchorEl: null, tierList: null, dialogTier: null };
const DEFAULT_FORMULARY_TIER_CONFIG = {
  tierLists: [],
};

export const FormularyTiersConfig: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const isEditing = React.useContext(FormularyEditingContext);

  const [showTierListsSlider, setShowTierListsSlider] = React.useState(false);
  const [tierMenu, setTierMenu] = React.useState<TierMenuState>(DEFAULT_TIER_DIALOG);
  const [allTierLists, setAllTierLists] = React.useState<Array<SimplifiedTierList>>([]);
  const [formularyTiersListConfig, setFormularyTiersListConfig] = React.useState<
    FormularyTiersListConfig
  >(DEFAULT_FORMULARY_TIER_CONFIG);
  const [deleteDialog, setDeleteDialog] = React.useState<DeleteDialogState>({
    show: false,
  });

  const { loading: isLoadingTiers } = useQuery<GetTierListsResponse>(GET_TIER_LISTS, {
    onCompleted: (res) => {
      const simpList = res.lists.items.map((list) => {
        return {
          id: list.id,
          name: list.name,
          description: list.description,
          effectiveDate: list.effectiveDate,
          terminationDate: list.terminationDate,
        };
      });
      setAllTierLists(simpList);
    },
  });

  const { loading: isLoadingListConfig } = useQuery<
    GetFormularyTiersListConfigResponse,
    GetFormularyTiersListConfigInput
  >(GET_FORMULARY_TIERS_LIST_CONFIG, {
    variables: { formularyId: props.formularyId },
    onCompleted: (res) => {
      setFormularyTiersListConfig(res.formularyTiersConfig);
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const handleRemoveList = (listId: string) => {
    const tierList = formularyTiersListConfig.tierLists.find(
      (listItem) => listItem.listId === listId
    );

    setDeleteDialog({
      show: true,
      listId,
      listName: tierList?.listName,
    });
  };

  const handleDelete = (listId: string) => {
    const tierLists = formularyTiersListConfig.tierLists.filter(
      (listItem) => listItem.listId !== listId
    );

    const configInput: SetFormularyTiersListConfigInput = {
      formularyId: props.formularyId,
      input: {
        tierLists: tierLists.map((tierList, index) => ({
          listId: tierList.listId,
          priority: index + 1,
          formularyTierId: tierList.tier?.id ?? null,
        })),
      },
    };
    props.onSaveConfig(configInput);
    setFormularyTiersListConfig({
      tierLists,
    });
  };

  const handleUpdatePriority = (newList: Array<TierListItem>) => {
    console.info(`Updating Priority... ${JSON.stringify(newList, null, 2)}`);
    const listCopy = newList.map((tierList, index) => ({
      ...tierList,
      priority: index + 1,
    }));
    const configInput: SetFormularyTiersListConfigInput = {
      formularyId: props.formularyId,
      input: {
        tierLists: listCopy.map((tierList) => ({
          listId: tierList.listId,
          priority: tierList.priority,
          formularyTierId: tierList.tier?.id ?? null,
        })),
      },
    };
    props.onSaveConfig(configInput);
    setFormularyTiersListConfig({
      tierLists: newList,
    });
  };

  const handleAddTierList = (listId: string) => (): void => {
    if (isEditing) {
      const tierListsInput = formularyTiersListConfig.tierLists
        .map((tierList, index) => ({
          listId: tierList.listId,
          priority: index + 1,
          formularyTierId: tierList.tier?.id ?? null,
        }))
        .concat({
          listId: listId,
          priority: formularyTiersListConfig.tierLists.length + 1,
          formularyTierId: null,
        });
      const configInput: SetFormularyTiersListConfigInput = {
        formularyId: props.formularyId,
        input: {
          tierLists: tierListsInput,
        },
      };
      props.onSaveConfig(configInput);
      setFormularyTiersListConfig({
        tierLists: [...formularyTiersListConfig.tierLists].concat({
          listId,
          listName: allTierLists.find((tierList) => tierList.id === listId)?.name ?? "",
          priority: formularyTiersListConfig.tierLists.length + 1,
          tier: null,
        }),
      });
      setShowTierListsSlider(false);
    }
  };

  const handleTierChange = (tier: FormularyTier) => {
    const configInput = {
      formularyId: props.formularyId,
      input: {
        tierLists: formularyTiersListConfig.tierLists.map((list, index) => ({
          listId: list.listId,
          priority: index + 1,
          formularyTierId:
            (list.listId === tierMenu.tierList?.listId ? tierMenu.dialogTier?.id : list.tier?.id) ??
            null,
        })),
      },
    };
    props.onSaveConfig(configInput);
    setFormularyTiersListConfig({
      tierLists: formularyTiersListConfig.tierLists.map((list) => ({
        ...list,
        tier: list.listId === tierMenu.tierList?.listId ? tier : list.tier,
      })),
    });
    setTierMenu({ ...tierMenu, anchorEl: null });
  };

  const getDrawerItems = (): Array<SimplifiedTierList> => {
    const addedIds = new Set<string>(formularyTiersListConfig.tierLists.map((list) => list.listId));
    return allTierLists.filter((list) => !addedIds.has(list.id));
  };

  const renderDrugCardContent = (item: TierListItem) => {
    const list = allTierLists.find((list) => list.id === item.listId);
    return (
      <>
        <CardHeader
          title={
            <Typography variant="h6" color="primary">
              {item.listName}
            </Typography>
          }
          action={
            <IconButton
              aria-label="remove list"
              disabled={!isEditing}
              onClick={() => {
                handleRemoveList(item.listId);
              }}
            >
              <ClearIcon />
            </IconButton>
          }
        />
        <CardContent style={{ paddingTop: 0 }}>
          <>
            <Typography paragraph display="block">
              <em>Effective as of:</em>{" "}
              {format(awsDateStringToDate(list?.effectiveDate!), "MMM do, yyyy")}
              {!!list?.terminationDate ? (
                <>
                  <br />
                  <em>To be terminated:</em>{" "}
                  {format(awsDateStringToDate(list.terminationDate), "MMM do, yyyy")}
                </>
              ) : null}
            </Typography>
            {item.tier ? (
              <Typography>
                Tier {item.tier.rank} - {item.tier.name}
              </Typography>
            ) : (
              <Typography>No Tier set</Typography>
            )}
          </>
        </CardContent>
        <CardActions>
          <Link to={tiersListRoute(item.listId)} className={classes.link} target="_blank">
            <Button size="small">View List</Button>
          </Link>
          <Button
            size="small"
            disabled={!isEditing}
            onClick={(event) => {
              setTierMenu({
                anchorEl: event.currentTarget as HTMLButtonElement,
                tierList: item,
                dialogTier: item.tier ?? props.formularyTiers[0],
              });
            }}
          >
            Change Tier
          </Button>
        </CardActions>
      </>
    );
  };

  return (
    <>
      <div className={classes.tabContent}>
        {isLoadingListConfig || isLoadingTiers || allTierLists.length === 0 ? (
          <div style={{ height: "100%" }}>
            <CenteredCircularLoading />
          </div>
        ) : (
          <>
            <div className={classes.dragAndDropContainer}>
              <DragAndDropPriorityList
                disabled={!isEditing}
                listData={formularyTiersListConfig.tierLists}
                onAddListItem={() => {
                  setShowTierListsSlider(true);
                }}
                onListDataPriorityChange={handleUpdatePriority}
                renderCardContent={renderDrugCardContent}
              />
            </div>
            <Drawer
              PaperProps={{ className: classes.drawer }}
              anchor="right"
              open={showTierListsSlider}
              onClose={() => {
                setShowTierListsSlider(false);
              }}
            >
              <List dense={contentSpacing === "dense"}>
                <ListSubheader>
                  Select a Tier List
                  <Divider />
                </ListSubheader>
                {getDrawerItems().map((tierList: SimplifiedTierList) => (
                  <ListItem button key={tierList.id} onClick={handleAddTierList(tierList.id)}>
                    <ListItemText>{tierList.name}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Drawer>

            <Menu
              open={Boolean(tierMenu.anchorEl)}
              anchorEl={tierMenu.anchorEl}
              onClose={() => {
                setTierMenu({ ...tierMenu, anchorEl: null });
              }}
            >
              {props.formularyTiers.map((tier) => (
                <MenuItem
                  key={tier.id}
                  disabled={!isEditing}
                  onClick={() => {
                    handleTierChange(tier);
                  }}
                >{`Tier ${tier.rank} - ${tier.name}`}</MenuItem>
              ))}
            </Menu>
          </>
        )}
      </div>
      <ConfirmationDialog
        isOpen={deleteDialog.show}
        onYesHandler={() => {
          if (deleteDialog.listId) {
            handleDelete(deleteDialog.listId);
          }
          setDeleteDialog({ show: false });
        }}
        onNoHandler={() => {
          setDeleteDialog({ show: false });
        }}
        dialogTitle={`Delete ${deleteDialog.listName}?`}
        dialogContent={`Are you sure you want to delete ${deleteDialog.listName}?`}
      />
    </>
  );
};
