import { useQuery } from "@apollo/client";
import { makeStyles, Paper } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import produce from "immer";
import * as React from "react";
import { SearchList } from "../../components/lists/SearchList";
import { MENU_OPTIONS } from "../constants";
import { TEMPLATE_TYPES } from "../types";
import { ListWebSearchTemplatesResponse, LIST_WEB_SEARCH_TEMPLATES } from "./api";
import { WebSearchTemplate } from "./types";

const useStyles = makeStyles((theme) => ({
  container: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
}));

type Props = {
  onSelectTemplate: (webSearchTemplate: WebSearchTemplate) => void;
};

export const WebSearchTemplateList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { data, loading } = useQuery<ListWebSearchTemplatesResponse>(LIST_WEB_SEARCH_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  });

  const handleClickTemplate = (templateId: string) => {
    if (data) {
      const selectedTemplate = data.templates.items.find((template) => template.id === templateId);
      if (selectedTemplate) {
        props.onSelectTemplate(
          produce(selectedTemplate, (draft) => {
            const { landingPageInfoText } = draft.settings;
            draft.settings.landingPageInfoText = landingPageInfoText
              ? atob(landingPageInfoText)
              : null;
          })
        );
      }
    }
  };

  return (
    <Paper className={classes.container}>
      <SearchList
        listData={data?.templates.items.map(({ id, name }) => ({ id, name })) ?? []}
        dataLoading={loading}
        onItemSelection={handleClickTemplate}
        menuOptions={MENU_OPTIONS}
        hasAddButton
        addButtonText={`New ${TEMPLATE_TYPES.WEB_SEARCH} Template`}
        redirectLink={`/templates/${TEMPLATE_TYPES.WEB_SEARCH}`}
      />
    </Paper>
  );
};
