import { useQuery } from "@apollo/client";
import { makeStyles, Paper } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import gql from "graphql-tag";
import * as React from "react";
import { SearchList } from "../../components/lists/SearchList";
import { MENU_OPTIONS } from "../constants";
import { TEMPLATE_TYPES } from "../types";
import { CriteriaTemplate } from "./types";

const useStyles = makeStyles((theme) => ({
  container: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  loading: {
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
  },
}));

type Props = {
  selectedTemplateId?: string;
  onSelectTemplate: (criteriaTemplate: CriteriaTemplate) => void;
};

export const CriteriaTemplateList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { loading, data } = useQuery<LoadedTemplates>(GET_CRITERIA_TEMPLATES, {
    fetchPolicy: "cache-and-network",
  });

  const handleClickTemplate = (templateId: string) => {
    if (data) {
      const filteredTemplate = data.templates.items.find(
        (template: any) => template.id === templateId
      );
      if (filteredTemplate) {
        props.onSelectTemplate(filteredTemplate);
      }
    }
  };

  return (
    <Paper className={classes.container}>
      <SearchList
        listData={
          data?.templates.items.map((template) => ({ id: template.id, name: template.name })) ?? []
        }
        dataLoading={loading}
        onItemSelection={handleClickTemplate}
        menuOptions={MENU_OPTIONS}
        hasAddButton
        addButtonText={`New ${TEMPLATE_TYPES.CRITERIA} Template`}
        redirectLink={`/templates/${TEMPLATE_TYPES.CRITERIA}`}
      />
    </Paper>
  );
};

type LoadedTemplates = {
  templates: {
    items: Array<CriteriaTemplate>;
  };
};

const GET_CRITERIA_TEMPLATES = gql`
  query criteriaTemplates {
    templates(templateType: CRITERIA) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        settings {
          ... on CriteriaTemplateSettings {
            font
            criteriaTitleSize
            elementNameSize
            elementInfoSize
          }
        }
      }
    }
  }
`;
