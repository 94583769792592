import { useMutation, useQuery } from "@apollo/client";
import produce from "immer";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  CreateWebSearchTemplateInput,
  CREATE_WEB_SEARCH_TEMPLATE,
  GetWebSearchTemplateInput,
  GetWebSearchTemplateResponse,
  GET_WEB_SEARCH_TEMPLATE,
  SaveWebSearchTemplateResponse,
} from "./api";
import { WebSearchTemplatesManagementPage } from "./constants";
import { convertLandingPageText, WebSearchTemplate, WebSearchTemplateStatus } from "./types";
import { WebSearchTemplatePageContainer } from "./WebSearchTemplatePageContainer";

export const CopyWebSearchTemplatePage: React.FC = () => {
  const history = useHistory();
  const { templateId } = useParams<any>();
  const { data } = useQuery<GetWebSearchTemplateResponse, GetWebSearchTemplateInput>(
    GET_WEB_SEARCH_TEMPLATE,
    { variables: { templateId: templateId as string } }
  );
  const [createWebSearchTemplate, { loading: isSaving }] = useMutation<
    SaveWebSearchTemplateResponse,
    CreateWebSearchTemplateInput
  >(CREATE_WEB_SEARCH_TEMPLATE);

  const [templateStatus, setTemplateStatus] = React.useState<WebSearchTemplateStatus>({
    status: "loading",
  });

  React.useEffect(() => {
    if (templateStatus.status === "loading" && data) {
      setTemplateStatus({
        status: "available",
        template: produce(data.template, (draft) => {
          draft.name = `${draft.name} - Copy`;
          draft.settings.landingPageInfoText = convertLandingPageText(
            draft.settings.landingPageInfoText
          );
        }),
      });
    } else if (isSaving && templateStatus.status === "available") {
      setTemplateStatus({ ...templateStatus, status: "saving" });
    }
  }, [data, templateStatus, isSaving]);

  const updateTemplate = (newTemplate: WebSearchTemplate) => {
    if (templateStatus.status === "available") {
      setTemplateStatus({ ...templateStatus, template: newTemplate });
    }
  };

  const saveTemplate = () => {
    if (templateStatus.status === "available") {
      const {
        name,
        settings: { bannerPath, landingPageInfoText, logoPath, primaryColor },
      } = templateStatus.template;
      createWebSearchTemplate({
        variables: {
          name,
          settings: {
            bannerPath,
            landingPageInfoText: landingPageInfoText
              ? btoa(encodeURIComponent(landingPageInfoText))
              : null,
            logoPath,
            primaryColor,
          },
        },
      }).then(() => {
        history.push(WebSearchTemplatesManagementPage);
      });
    }
  };

  return (
    <WebSearchTemplatePageContainer
      templateStatus={templateStatus}
      updateTemplate={updateTemplate}
      saveTemplate={saveTemplate}
    />
  );
};
