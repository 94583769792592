import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { CostShareTable } from "../CostShare/CostShareTable";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    height: `calc(90% - ${theme.spacing(4)}px)`,
  },
}));

interface Props {
  formularyId: string;
  planConfigId: string;
  networkId: string;
  readonly: boolean;
}

export function PlanConfigCostShare(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <CostShareTable
        formularyId={props.formularyId}
        networkId={props.networkId}
        planConfigId={props.planConfigId}
        readonly={props.readonly}
      />
    </section>
  );
}
