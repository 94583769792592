import { Button, makeStyles, fade } from "@material-ui/core";
import ExportIcon from "@material-ui/icons/SaveAltRounded";
import * as React from "react";
import ExportExample from "./exports-sample.xlsx";
import { format } from "date-fns";

/**
 * Created solely for the purposes of Demoing the Export Functionality. Importing exports.xlsm
 * was not working out in a .tsx (TS React) file
 *
 * TODO
 *  Delete after integrating a proper exports button
 */

const useStyles = makeStyles((theme) => ({
  controlBarButton: {
    margin: theme.spacing(),
    "&:hover": {
      background: fade(theme.palette.common.white, 0.2),
    },
  },
}));

export function ExportSampleButton() {
  const classes = useStyles();

  return (
    <Button className={classes.controlBarButton} color="inherit" startIcon={<ExportIcon />}>
      <a
        href={ExportExample}
        download={`Adjudication_File_${format(new Date(), "MMddyyyy")}.xlsx`}
        style={{ textDecoration: "none", color: "white" }}
      >
        Full Export
      </a>
    </Button>
  );
}
