import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/CancelRounded";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import * as React from "react";
import { UploadFormularyIcon } from "../Icons/customIcons";

const useStyles = makeStyles({
  centeredContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorIcon: {
    marginLeft: "5px",
    color: "red",
  },
});

interface Props {
  fileName: string;
  fileType?: string; // accepted file format
  handleUpload: (file: File) => Promise<void>;
  onRemoveFile?: (fileName: string) => void;
  buttonIcon?: JSX.Element;
  disabled?: boolean;
}

export const UploadButton: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [hasUploadFailed, setHasUploadFailed] = React.useState(false);
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [fileName, setFileName] = React.useState(props.fileName ? props.fileName : "");

  const inputElement = React.useRef<HTMLInputElement>(null);

  const disabled = props.disabled ?? false;
  const id = `upload-button-${Math.random()}`;

  //TODO: Remove use effect and change fileName usage to props
  React.useEffect(() => {
    setFileName(props.fileName);
  }, [props.fileName]);

  const clearFileInput = () => {
    if (inputElement && inputElement.current) {
      inputElement.current.value = "";
      if (props.onRemoveFile) {
        props.onRemoveFile(fileName);
      }
      setFileName("");
    }
  };

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setIsFileUploading(true);
      setHasUploadFailed(false);
      const file = event.target.files[0];
      setFileName(file.name);
      try {
        await props.handleUpload(file);
        setIsFileUploading(false);
      } catch (e) {
        console.error(e);
        setIsFileUploading(false);
        setHasUploadFailed(true);
        clearFileInput();
      }
    }
  };

  const fileUploadSection = () => {
    if (isFileUploading) {
      return (
        <div className={classes.centeredContainer}>
          <CircularProgress size={36} />
        </div>
      );
    } else if (fileName && !hasUploadFailed) {
      return (
        <Input
          type="text"
          value={fileName}
          disabled={disabled}
          inputProps={{
            style: { textOverflow: "ellipsis" },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton disabled={disabled} onClick={clearFileInput}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      );
    } else {
      return (
        <label htmlFor={id}>
          <Button
            disabled={disabled}
            variant="outlined"
            component="span"
            color="primary"
            startIcon={props.buttonIcon ? props.buttonIcon : <UploadFormularyIcon />}
          >
            Upload
          </Button>
        </label>
      );
    }
  };

  return (
    <div className={classes.centeredContainer}>
      <input
        id={id}
        ref={inputElement}
        type="file"
        accept={props.fileType}
        style={{ display: "none" }}
        disabled={disabled}
        onChange={uploadFile}
      />
      {fileUploadSection()}
      {hasUploadFailed && (
        <Tooltip title="Upload failed!" placement="right">
          <ErrorOutline className={classes.errorIcon} />
        </Tooltip>
      )}
    </div>
  );
};
