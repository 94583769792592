export const GROUP_BY_DRUG_NAME_HELP_TEXT =
  "This setting groups like drugs by their drug name. Instead of seeing the same drug as different rows on the table, they will be combined into a single row. The gear cog next to the switch offers more settings on how drugs should be combined into a single row.";

export const SHOW_ROW_DIVIDERS_HELP_TEXT =
  "Turning this feature on shows horizontal line dividers between each row. This can be helpful for differentiating rows from each other.";

export const SHOW_COL_DIVIDERS_HELP_TEXT =
  "Turning this feature on shows vertical line dividers between each column.";

export const EDIT_DETAILS_HELP_TEXT =
  "Enabling this feature shows extended information about a specific edit, such as the amount specified for a Quantity Limit.";

export const TIER_RANK_PREFIX_HELP_TEXT =
  "An optional field that allows you to assign a prefix before each numbered tier in the table. For example, a prefix of 'Medical' would show up in the Tier column as Medical 1, Medical 2, etc.";
