import { makeStyles, Paper } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    height: `calc(100% - ${theme.spacing(4)}px)`,
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  children: React.ReactNode;
}

export function TableContainer(props: Props): JSX.Element {
  const classes = useStyles();

  return <Paper className={classes.root}>{props.children}</Paper>;
}
