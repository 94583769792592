import { useMutation } from "@apollo/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  CreateWebSearchTemplateInput,
  SaveWebSearchTemplateResponse,
  CREATE_WEB_SEARCH_TEMPLATE,
} from "./api";
import { DEFAULT_NEW_WEB_SEARCH_TEMPLATE, WebSearchTemplatesManagementPage } from "./constants";
import { WebSearchTemplate } from "./types";
import { WebSearchTemplatePageContainer } from "./WebSearchTemplatePageContainer";

export const CreateWebSearchTemplatePage: React.FC = () => {
  const history = useHistory();
  const [createWebSearchTemplate, { loading: isSaving }] = useMutation<
    SaveWebSearchTemplateResponse,
    CreateWebSearchTemplateInput
  >(CREATE_WEB_SEARCH_TEMPLATE);

  const [template, setTemplate] = React.useState<WebSearchTemplate>(
    DEFAULT_NEW_WEB_SEARCH_TEMPLATE
  );

  const handleSaveTemplate = () => {
    const { bannerPath, landingPageInfoText, logoPath, primaryColor } = template.settings;
    createWebSearchTemplate({
      variables: {
        name: template.name,
        settings: {
          bannerPath,
          landingPageInfoText: landingPageInfoText
            ? btoa(encodeURIComponent(landingPageInfoText))
            : null,
          logoPath,
          primaryColor,
        },
      },
    }).then(() => {
      history.push(WebSearchTemplatesManagementPage);
    });
  };

  const handleUpdateTemplate = (newTemplate: WebSearchTemplate) => {
    setTemplate(newTemplate);
  };

  return (
    <WebSearchTemplatePageContainer
      saveTemplate={handleSaveTemplate}
      templateStatus={isSaving ? { status: "saving", template } : { status: "available", template }}
      updateTemplate={handleUpdateTemplate}
    />
  );
};
