import Storage from "@aws-amplify/storage";
import { getUser } from "./User";

type UploadFileType =
  | { type: "formulary file"; formularyId: string; utcTimestamp: number }
  | { type: "documents uploads" }
  | { type: "web search" };

export interface SaveFileParameters {
  fileType: UploadFileType;
  file: File;
}

// Storage.put is not typed well but it does return a key on a successful Put
interface SaveFileResult {
  key: string;
}

export async function saveFile({ fileType, file }: SaveFileParameters): Promise<string> {
  const { subAccountId } = await getUser();

  const key = uploadPath(fileType, subAccountId, file);

  const res = (await Storage.put(key, file, {
    contentType: file.type,
  })) as SaveFileResult;

  return res.key;
}

const uploadPath = (fileType: UploadFileType, subAccountId: string, file: File) => {
  switch (fileType.type) {
    case "documents uploads":
      return `${subAccountId}/documents/uploads/${file.name}`;
    case "formulary file":
      return `${subAccountId}/formularyFiles/${fileType.formularyId}/${fileType.utcTimestamp}/${file.name}`;
    case "web search":
      return `${subAccountId}/websearch/${file.name}`;
  }
};

export const locationPrefix = (fileType: UploadFileType, subAccountId: string) => {
  switch (fileType.type) {
    case "documents uploads":
      return `public/${subAccountId}/documents/uploads`;
    case "formulary file":
      return `public/${subAccountId}/formularyFiles/${fileType.formularyId}/${fileType.utcTimestamp}`;
    case "web search":
      return `public/${subAccountId}/websearch`;
  }
};

interface DownloadParams {
  fileKey: string;
}

export const downloadFile = ({ fileKey }: DownloadParams) => {
  return Storage.get(fileKey);
};

export const listFiles = async (fileType: UploadFileType) => {
  const { subAccountId } = await getUser();

  switch (fileType.type) {
    case "documents uploads":
      return Storage.list(`${subAccountId}/documents/uploads`);
    case "formulary file":
      return Storage.list(
        `${subAccountId}/formularyFiles/${fileType.formularyId}/${fileType.utcTimestamp}`
      );
    case "web search":
      return Storage.list(`${subAccountId}/websearch`);
  }
};

export const getFileName = (filePath: string) => {
  const pathSections = filePath.split("/");
  return pathSections[pathSections.length - 1];
};

type ListDocumentsResponse = {
  eTag: string;
  key: string;
  lastModified: Date;
  size: number;
};

export const downloadPublishedDocument = async (formularyId: string, documentId: string) => {
  const { subAccountId } = await getUser();

  const listedDocs: Array<ListDocumentsResponse> = await Storage.list(
    `${subAccountId}/documents/published/${formularyId}`
  );

  const hasDocBeenPublished = listedDocs
    .map(({ key }) => {
      const parts = key.split("/");
      return parts[parts.length - 1].replace(".pdf", "");
    })
    .some((docId) => {
      return docId === documentId;
    });

  if (hasDocBeenPublished) {
    return Storage.get(`${subAccountId}/documents/published/${formularyId}/${documentId}.pdf`);
  } else {
    throw new Error(DOCUMENT_NOT_PUBLISHED_ERROR);
  }
};
export const DOCUMENT_NOT_PUBLISHED_ERROR = "Document not published";

export function publishedDocumentPreviewPath(
  hostname: string,
  documentPath: string,
  versionId?: string
): string {
  const rootPath = `${basePath(hostname)}/${documentPath}`;
  return versionId ? `${rootPath}?versionId=${versionId}` : rootPath;
}

export function basePath(hostname: string) {
  if (hostname.includes("localhost") || hostname.includes("dev")) {
    return `https://dev.next.drgadaptive.com`;
  } else if (hostname.includes("staging")) {
    return `https://staging.next.drgadaptive.com`;
  } else {
    return `https://next.drgadaptive.com`;
  }
}
