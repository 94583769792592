import * as React from "react";
import {
  Typography,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import ModeSwitchIcon from "@material-ui/icons/CallSplitRounded";
import { LabelSwitch } from "../../components/switches/LabelSwitch";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  buttonWrapper: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  continueButton: {
    marginLeft: "0.25rem",
  },
  cardContent: {
    padding: theme.spacing(3),
    maxHeight: "75vh",
    overflow: "auto",
  },
  modeSelector: {
    width: "90%",
  },
}));

enum MODE_UI_NAMES {
  MEDICARE = "Medicare",
  NONMEDICARE = "Nonmedicare",
}

type Props = {
  modes: Array<string>;
  onSelectMode: (mode: string) => Promise<void>;
};

export const ModeSelectionForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [selectedMode, setSelectedMode] = React.useState<MODE_UI_NAMES>(MODE_UI_NAMES.NONMEDICARE);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const handleConfirmMode = () => {
    if (selectedMode) {
      setIsLoading(true);
      props
        .onSelectMode(selectedMode.toUpperCase())
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.code + " Error Selecting Mode. Please try again.");
        });
    }
  };

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <ModeSwitchIcon />
          </Avatar>
        }
        title="Select application mode"
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <LabelSwitch
          yesLabel={MODE_UI_NAMES.MEDICARE}
          noLabel={MODE_UI_NAMES.NONMEDICARE}
          switchProps={{
            onChange: (event) => {
              setSelectedMode(
                event.target.checked ? MODE_UI_NAMES.MEDICARE : MODE_UI_NAMES.NONMEDICARE
              );
            },
            checked: selectedMode === MODE_UI_NAMES.MEDICARE,
          }}
        />
        {/* <Select
          className={classes.modeSelector}
          value={selectedMode}
          onChange={(event) => {
            setSelectedMode(event.target.value as string);
          }}
        >
          {props.modes.map((mode, index) => (
            <MenuItem key={index} value={mode}>
              {MODE_UI_NAMES[mode] ?? mode}
            </MenuItem>
          ))}
        </Select> */}
      </CardContent>
      <Divider />
      <CardActions>
        <div className={classes.buttonWrapper}>
          <Button
            fullWidth
            className={classes.continueButton}
            color="primary"
            variant="contained"
            disabled={selectedMode === null || isLoading}
            onClick={handleConfirmMode}
          >
            Continue
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </CardActions>
      {error && <Typography color="error">{error}</Typography>}
    </Card>
  );
};
