/**
 * In certain circumstances a dialog needs to appear from within a react material-ui dialog
 * that is natively rendered by a framework like in our TinyMCE editor. This modifies a property
 * on some html rendered by material-ui so that nested native dialogs can be edited.
 */
export const enableNestedNativeDialogs = () => {
  const paperEle = document.querySelector(`[class*="scrollPaper-"]`);
  if (paperEle) {
    paperEle.removeAttribute("tabindex");
  }
};
