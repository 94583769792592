import { ApolloQueryResult } from "@apollo/client";
import { makeStyles, Paper } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  AgGridEvent,
  ColDef,
  IServerSideDatasource,
  RowClickedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import * as React from "react";
import { StyledAgGrid } from "../../components/tables/dataTables/StyledAgGrid";
import { ViewTableControlBar } from "../../components/tables/dataTables/ViewTableControlBar";
import { UserContext } from "../../components/UserContext";
import { PaginationInput } from "../../types/ApiTypes";
import {
  DrugAttribute,
  DrugListType,
  DRUG_ATTRIBUTES,
  GetListDrugsResponse,
  ListDrug,
  ListDrugsInput,
  toListDrugsInput,
} from "./api";
import { DrugListDrugDrawer } from "./TierLists/View/DrugListDrugDrawer";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    gridColumn: "span 16",
    margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
  },
  controlBar: {
    display: "flex",
    "&& p": {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
  },
}));

interface Props {
  viewDate: Date;
  isLoading: boolean;
  onDateChange: (date: Date) => void;
  fetchListDrugs: (
    drugsInput: ListDrugsInput,
    paginationInput: PaginationInput
  ) => Promise<ApolloQueryResult<GetListDrugsResponse>>;
  listType: DrugListType;
}

export function ListsViewPage(props: Props) {
  const classes = useStyles();
  const {
    user: { drugSourceShort },
  } = React.useContext(UserContext);
  const [selectedDrug, setSelectedDrug] = React.useState<ListDrug | undefined>(undefined);

  const onGridReady = (params: AgGridEvent) => {
    const createServerSideDataSource: IServerSideDatasource = {
      getRows(params) {
        const { input, paginationInput } = toListDrugsInput(params.request, props.viewDate);
        props
          .fetchListDrugs(input, paginationInput)
          .then((result) => {
            if (result.errors) {
              console.error(result.errors);
              params.failCallback();
            } else {
              const drugsPage = result.data.list.drugs;
              const items = drugsPage.items.map((i: any) => ({
                ...i,
                ...(i.fdbDetails ?? {}),
                ...(i.medispanDetails ?? {}),
                isBrand: i.type === "BRAND",
              }));
              let totalRowCount = -1;
              if (drugsPage.pageInfo?.endCursor === "*") {
                totalRowCount = parseInt(paginationInput.after as any) + items.length;
              }
              params.successCallback(items, totalRowCount);
            }
          })
          .catch((err) => {
            console.error(err);
            params.failCallback();
          });
      },
    };
    params.api.setServerSideDatasource(createServerSideDataSource);
  };

  const handleRowClicked = (event: RowClickedEvent) => {
    if (event.data?.ndc) {
      setSelectedDrug(event.data);
    }
  };
  const handleDateChange = (date: MaterialUiPickersDate) => {
    props.onDateChange(date as Date);
  };

  return (
    <Paper className={classes.tableContainer}>
      {selectedDrug && (
        <DrugListDrugDrawer
          asOfDate={props.viewDate}
          drug={selectedDrug}
          onClose={() => setSelectedDrug(undefined)}
        />
      )}
      <div style={{ height: 55 }}>
        <ViewTableControlBar tableViewDate={props.viewDate} onDateChange={handleDateChange} />
      </div>
      <div style={{ height: `calc(100% - 55px)` }}>
        <StyledAgGrid
          isLoading={props.isLoading}
          columnDefs={tableColumns(drugSourceShort, props.listType)}
          onRowClicked={handleRowClicked}
          rowModelType="serverSide"
          onGridReady={onGridReady}
          serverSideSortingAlwaysResets={true}
        />
      </div>
    </Paper>
  );
}

const tableColumns = (drugSourceShort: "FDB" | "MSPAN", listType: DrugListType): Array<ColDef> =>
  COL_DEFS.filter((col) => {
    const { exclusiveToDrugSource, exclusiveForView, exclusiveForListType } = DRUG_ATTRIBUTES[
      col.field as DrugAttribute
    ];
    return (
      (exclusiveToDrugSource === drugSourceShort || exclusiveToDrugSource === undefined) &&
      (exclusiveForView === "LIST_GRID" || exclusiveForView === undefined) &&
      (exclusiveForListType === listType || exclusiveForListType === undefined)
    );
  });

const COL_DEFS: Array<ColDef> = [
  { field: DrugAttribute.brandName },
  {
    field: DrugAttribute.genericName,
    valueFormatter: (params: ValueFormatterParams) => {
      return params.value?.toLowerCase();
    },
  },
  { field: DrugAttribute.ndc },
  { field: DrugAttribute.gpi14 },
  {
    field: DrugAttribute.isBrand,
    filter: true,
    filterParams: {
      suppressMiniFilter: true,
      values: [true, false],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value === "true" ? "Brand" : "Generic";
      },
    },
    valueFormatter: (params: ValueFormatterParams) => {
      const { data } = params;
      if (Boolean(data?.isBrand)) {
        return "Brand";
      } else {
        return "Generic";
      }
    },
  },
  { field: DrugAttribute.gcn },
  {
    field: DrugAttribute.mony,
    filter: true,
    filterParams: {
      suppressMiniFilter: true,
      values: Array.from("MONY"),
    },
  },
  { field: DrugAttribute.hic3 },
  {
    field: DrugAttribute.isOtc,
    filter: true,
    filterParams: {
      suppressMiniFilter: true,
      values: [true, false],
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value === "true" ? "OTC" : "Rx";
      },
    },
    hide: true,
    valueFormatter: (params: ValueFormatterParams) => {
      const { data } = params;
      if (Boolean(data?.isOtc)) {
        return "OTC";
      } else {
        return "Rx";
      }
    },
  },
  { field: DrugAttribute.labelName, hide: true, rowGroup: true },
  { field: DrugAttribute.drugName, hide: true },
  { field: DrugAttribute.gcnSequenceNumber, hide: true },
  { field: DrugAttribute.hiclSequenceNumber, hide: true },
  { field: DrugAttribute.rxcui, hide: true },
  { field: DrugAttribute.drugDescriptorId, hide: true },
  { field: DrugAttribute.price },
].map((coldef) => ({
  ...coldef,
  headerName: DRUG_ATTRIBUTES[coldef.field].display,
})) as Array<ColDef>;
