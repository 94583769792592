import { CircularProgress, Fab, makeStyles, Zoom } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/SaveRounded";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  fabContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(6),
  },
  fab: {
    color: theme.palette.common.white,
  },
  marginRightSpacing: {
    marginRight: theme.spacing(),
  },
}));

interface Props {
  isSaving: boolean;
  isValidToSave?: boolean;
  onClickSave: () => void;
}

export function SaveFab(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div id="save-fab" className={classes.fabContainer}>
      <Zoom in={props.isValidToSave ?? true} timeout={{ enter: 200, exit: 200 }}>
        <Fab
          variant="extended"
          size="large"
          aria-label="save"
          color="primary"
          onClick={props.onClickSave}
          disabled={props.isSaving}
          className={classes.fab}
        >
          {props.isSaving ? (
            <CircularProgress size={30} className={classes.marginRightSpacing} />
          ) : (
            <SaveIcon className={classes.marginRightSpacing} />
          )}
          {props.isSaving ? "Saving" : "Save"}
        </Fab>
      </Zoom>
    </div>
  );
}
