import { ThemeProvider } from "@material-ui/core";
import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { getAppTheme } from "../styles/Theme";
import { UserAreaSections } from "../types/AppTypes";
import { AccountAdmin } from "./Admin/Accounts";
import { UserAdmin } from "./Admin/UserAdmin";
import { UserAppSettings } from "./AppSettings/UserAppSettings";
import { AccountEditor } from "./Admin/AccountEditor";
import { NotFound } from "../components/screens/404";
import { UserContext } from "../components/UserContext";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const AccountAreaRoutes: React.FC = () => {
  let userAdminPage = false;
  if (cookies.get("isAdmin") === "true") {
    userAdminPage = true;
  }

  const {
    user: { settings },
  } = React.useContext(UserContext);
  return (
    <ThemeProvider theme={getAppTheme(UserAreaSections.ADMIN, settings.textSize)}>
      <Switch>
        <Route path="/account/app-settings" exact={true}>
          <UserAppSettings />
        </Route>
        {userAdminPage && (
          <Route path="/account/admin/users" exact={true}>
            <UserAdmin />
          </Route>
        )}
        {userAdminPage && (
          <Route path="/account/admin/accounts/edit/:accountId" exact={true}>
            <AccountEditor />
          </Route>
        )}
        {userAdminPage && (
          <Route path="/account/admin/accounts/new" exact={true}>
            <AccountEditor />
          </Route>
        )}
        {userAdminPage && (
          <Route path="/account/admin/accounts" exact={true}>
            <AccountAdmin />
          </Route>
        )}
        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};
