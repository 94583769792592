import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { TemplateFonts } from "../Formulary/types";
import { IsViewingTemplateContext } from "../isEditingTemplateContext";
import {
  GetCriteriaTemplateInput,
  GetCriteriaTemplateResponse,
  GET_CRITERIA_TEMPLATE,
  SaveCriteriaTemplateResponse,
  UpdateCriteriaTemplateInput,
  UPDATE_CRITERIA_TEMPLATE,
} from "./api";
import { DefaultMarginSettings } from "./constants";
import { CriteriaTemplateContainer } from "./CriteriaFormContainer";
import { CriteriaTemplate, CriteriaTemplateStatus } from "./types";

export const EditCriteriaTemplatePage: React.FC = () => {
  const { templateId } = useParams<any>();
  const { pathname } = useLocation();

  const [templateStatus, setTemplateStatus] = React.useState<CriteriaTemplateStatus>({
    status: "loading",
  });

  const { data } = useQuery<GetCriteriaTemplateResponse, GetCriteriaTemplateInput>(
    GET_CRITERIA_TEMPLATE,
    {
      variables: {
        templateId: templateId as string,
      },
    }
  );
  const [updateCriteriaTemplate, { loading: isSaving }] = useMutation<
    SaveCriteriaTemplateResponse,
    UpdateCriteriaTemplateInput
  >(UPDATE_CRITERIA_TEMPLATE);

  React.useEffect(() => {
    if (templateStatus.status === "loading" && data) {
      setTemplateStatus({ status: "available", template: data.template });
    } else if (isSaving && templateStatus.status === "available") {
      setTemplateStatus({ status: "saving", template: templateStatus.template });
    }
  }, [templateStatus, data, isSaving]);

  const saveTemplate = () => {
    if (templateStatus.status === "available") {
      const { id, name } = templateStatus.template;
      const {
        font,
        criteriaTitleSize,
        elementInfoSize,
        elementNameSize,
      } = templateStatus.template.settings;
      updateCriteriaTemplate({
        variables: {
          templateId: id,
          name,
          settings: {
            criteriaTitleSize,
            elementInfoSize,
            elementNameSize,
            font: font as TemplateFonts,
            header: null,
            footer: null,
            marginSettings: DefaultMarginSettings,
          },
        },
      }).then(() => {
        setTemplateStatus({ status: "available", template: templateStatus.template });
      });
    }
  };

  const updateTemplate = (newTemplate: CriteriaTemplate) => {
    if (templateStatus.status !== "loading") {
      setTemplateStatus({ ...templateStatus, template: newTemplate });
    }
  };

  return (
    <IsViewingTemplateContext.Provider value={pathname.includes("view")}>
      <CriteriaTemplateContainer
        templateStatus={templateStatus}
        saveTemplate={saveTemplate}
        updateTemplate={updateTemplate}
      />
    </IsViewingTemplateContext.Provider>
  );
};
