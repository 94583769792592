import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as React from "react";

type StandardDatePickerProps = {
  label: string;
  value: ParsableDate;
  minDate?: Date | null;
  maxDate?: Date | null;
  required?: boolean;
  disabled?: boolean;
  margin?: "none" | "dense" | "normal";
  disableDates?: ((day: MaterialUiPickersDate) => boolean) | undefined;
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
};

export const StandardDatePicker: React.FC<StandardDatePickerProps> = (props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        required={props.required ?? false}
        disabled={props.disabled === undefined ? false : props.disabled}
        minDate={props.minDate}
        maxDate={props.maxDate}
        disableToolbar
        autoOk
        variant="inline"
        inputVariant="outlined"
        shouldDisableDate={props.disableDates}
        InputAdornmentProps={{ position: "start" }}
        format="MMMM do, yyyy"
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        InputProps={{ readOnly: true }}
        margin={props.margin}
      />
    </MuiPickersUtilsProvider>
  );
};
