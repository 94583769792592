export const REQUIRED_ERROR = "Required";

export const minValueError = (minimumValue: number, name: string = "Field") =>
  `${name} cannot be less than ${minimumValue}`;
export const maxValueError = (maximumValue: number, name: string = "Field") =>
  `${name} cannot be grater than ${maximumValue}`;

export const minLengthError = (numberOfCharacters: number) =>
  `Minnimum length is required of ${numberOfCharacters} characters`;
export const maxLengthError = (maximumCharacters: number) =>
  `Exceeds maximum length of ${maximumCharacters} characters`;
