import {
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";
import * as React from "react";
import { UserContext } from "../UserContext";

const useStyles = makeStyles((theme) => ({
  tableBody: {
    paddingRight: "10px",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableCell: {
    border: 0,
  },
  labelCell: {
    borderRight: "1px solid lightgray",
    width: "20%",
  },
  addIconWrapper: {
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  deleteCell: {
    width: "5%",
  },
  errorColor: {
    outline: `2px solid ${theme.palette.error.main}`,
  },
  capitalText: {
    textTransform: "capitalize",
  },
}));

export interface TierOptionTableItem {
  name: string;
  itemSubAccountId: string;
  order: number;
}

export type AvailableTierItem = Pick<TierOptionTableItem, "itemSubAccountId" | "name">;

interface Props {
  tiers: Array<TierOptionTableItem>;
  availableSubAccountTiers: Array<AvailableTierItem>;
  tierItemIdsWithErrors?: Set<string>;
  nameOnly?: boolean;
  canAdd?: boolean;
  canDelete?: boolean;
  readonly?: boolean;
  onUpdateTierOrder: (tierIndex: number, newOrder: number) => void;
  afterOrderChange?: (tierIndex: number) => void;
  onUpdateTierName: (tierIndex: number, tier: AvailableTierItem) => void;
  onRemoveTier: (tierIndex: number) => void;
  onAddTier: () => void;
}

export function TierOptionsTable(props: Props): JSX.Element {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const readonly = props.readonly ?? false;
  const canAdd = props.canAdd ?? true;
  const canDelete = props.canDelete ?? true;

  const handleUpdateOrder = (tierIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const order = parseInt(event.target.value);
    if (event.target.value === "") {
      props.onUpdateTierOrder(tierIndex, 1);
    } else if (order < 1) {
      props.onUpdateTierOrder(tierIndex, 1);
    } else if (order < 100) {
      props.onUpdateTierOrder(tierIndex, order);
    } else {
      props.onUpdateTierOrder(tierIndex, 99);
    }
  };

  const handleBlurOrder = (index: number) =>
    props.afterOrderChange
      ? () => {
          props.afterOrderChange?.(index);
        }
      : undefined;

  const handleKeyDown = (index: number) =>
    props.afterOrderChange
      ? (event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === "Enter") {
            props.afterOrderChange?.(index);
          }
        }
      : undefined;

  const showDeleteButton = (tierIndex: number) => {
    if (canDelete && !props.nameOnly && !readonly) {
      return (
        <IconButton
          onClick={() => {
            props.onRemoveTier(tierIndex);
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      );
    }
  };

  return (
    <Table size={contentSpacing === "dense" ? "small" : "medium"}>
      <TableBody className={classes.tableBody}>
        {props.tiers.map((tier, index) => (
          <TableRow
            key={`${tier.itemSubAccountId}_${index}`}
            className={
              props.tierItemIdsWithErrors?.has(tier.itemSubAccountId) ? classes.errorColor : ""
            }
          >
            <TableCell className={classNames(classes.tableCell, classes.labelCell)}>
              {props.nameOnly ? (
                <Typography align="center" variant="body2">
                  {tier.order}
                </Typography>
              ) : (
                <TextField
                  fullWidth
                  type="number"
                  name="drugTierId"
                  value={tier.order}
                  inputProps={{ min: "0", max: "99" }}
                  disabled={readonly}
                  onChange={handleUpdateOrder(index)}
                  onBlur={handleBlurOrder(index)}
                  onKeyDown={handleKeyDown(index)}
                />
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Select
                fullWidth
                onChange={(event) => {
                  const selectedTier = props.availableSubAccountTiers.find(
                    (availableTier) => availableTier.itemSubAccountId === event.target.value
                  );
                  if (selectedTier) {
                    props.onUpdateTierName(index, selectedTier);
                  }
                }}
                disabled={readonly}
                value={tier.itemSubAccountId ?? ""}
                className={classes.capitalText}
              >
                {props.availableSubAccountTiers.map((tier) => (
                  <MenuItem
                    key={tier.itemSubAccountId}
                    value={tier.itemSubAccountId}
                    className={classes.capitalText}
                  >
                    {tier.name.toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell className={classNames(classes.tableCell, classes.deleteCell)}>
              {showDeleteButton(index)}
            </TableCell>
          </TableRow>
        ))}
        {canAdd && !props.nameOnly && !readonly && (
          <TableRow hover onClick={props.onAddTier} className={classes.tableRow}>
            <TableCell
              className={classNames(classes.tableCell, classes.labelCell, classes.addIconWrapper)}
            >
              <Add />
            </TableCell>
            <TableCell className={classes.tableCell} colSpan={2}>
              <Typography color="primary" variant="body2">
                Add Tier
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
