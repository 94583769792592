import { makeStyles, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import produce from "immer";
import * as React from "react";
import { fontSizeMinMaxBoundary, matchOnlyDigit } from "../../../utils/FormUtils";
import { toLowerCaseUserText } from "../../../utils/StringUtils";
import { TemplateToolbar } from "../../TemplateToolbar";
import { TemplateFonts } from "../../Formulary/types";
import { CriteriaTemplate } from "../types";
import { ConfigOptionsTable } from "../../../components/tables/ConfigOptionsTable";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";

const useStyles = makeStyles((theme) => ({
  editSectionRoot: {
    gridColumn: "span 8",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  followingContent: {
    order: 1,
    height: "calc(100vh - 72px)",
    overflow: "auto",
    margin: 0,
    padding: 20,
    zIndex: 0,
    "scrollbar-width": "none", // Hide Scrollbar - Firefox
    "&::-webkit-scrollbar": {
      // Hide Scrollbar - Chrome & Safari
      display: "none",
    },
  },
  templateName: {
    maxWidth: "100%",
    marginBottom: 20,
    display: "inline-flex",
    alignItems: "flex-start",
    "&>p": {
      color: theme.palette.error.main,
    },
  },
  templateNameWithEditButton: {
    maxWidth: "100%",
    marginBottom: 20,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
  },
  configTable: {
    padding: "10px",
  },
}));

type Props = {
  template: CriteriaTemplate;
  isSaving: boolean;
  updateTemplate: (template: CriteriaTemplate) => void;
  saveTemplate: () => void;
  onClosePreview: () => void;
  onPreview: () => void;
  onBack: () => void;
};

/**
 * TODOs
 *  eventually split out toolbar completely from the form?
 */
export const CriteriaTemplateForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { criteriaTitleSize, elementNameSize, elementInfoSize } = props.template.settings;
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  const handleExponentialPlusAndMinus = (_: string | number) => (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    matchOnlyDigit(e.key.valueOf(), e);
  };

  const isTemplateValidToSave = Boolean(
    props.template.name !== "" &&
      elementInfoSize <= CriteriaTextSizeBounds.max &&
      elementInfoSize >= CriteriaTextSizeBounds.min &&
      elementNameSize <= CriteriaTextSizeBounds.max &&
      elementNameSize >= CriteriaTextSizeBounds.min &&
      criteriaTitleSize <= CriteriaTitleSizeBounds.max &&
      criteriaTitleSize >= CriteriaTitleSizeBounds.min &&
      props.template.name.length <= 200
  );

  return (
    <form>
      <div className={classes.editSectionRoot}>
        <TemplateToolbar
          isValid={isTemplateValidToSave}
          isSaving={props.isSaving}
          isViewOnly={isViewOnly}
          onBack={props.onBack}
          onPreview={props.onPreview}
          onPreviewClose={props.onClosePreview}
          onSave={props.saveTemplate}
        />

        <div className={classes.followingContent}>
          <TextField
            fullWidth
            label={<Typography>Template Name</Typography>}
            disabled={isViewOnly}
            type="text"
            name="templateName"
            className={classes.templateName}
            onChange={(event) => {
              props.updateTemplate({ ...props.template, name: event.target.value });
            }}
            value={props.template.name}
            inputProps={{
              style: { textOverflow: "ellipsis" },
            }}
            helperText={
              props.template.name === ""
                ? "Cannot be blank"
                : props.template.name.length >= 201
                ? "Exceeds maximum length of 200 characters"
                : ""
            }
          />
          <Paper className={classes.configTable}>
            <ConfigOptionsTable
              settings={[
                {
                  label: "Font",
                  options: (
                    <Select
                      value={props.template.settings.font}
                      disabled={isViewOnly}
                      onChange={(event) => {
                        props.updateTemplate(
                          produce(props.template, (draft) => {
                            draft.settings.font = event.target.value as string;
                          })
                        );
                      }}
                      style={{ textTransform: "capitalize" }}
                    >
                      {Object.keys(TemplateFonts).map((fontKey, index) => {
                        const font = (TemplateFonts as any)[fontKey as any];
                        return (
                          <MenuItem
                            key={index}
                            value={font}
                            style={{ textTransform: "capitalize" }}
                          >
                            {toLowerCaseUserText(font)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  ),
                },
                {
                  label: "Criteria title size",
                  options: (
                    <TextField
                      type="number"
                      value={criteriaTitleSize}
                      disabled={isViewOnly}
                      onChange={(event) => {
                        if (event.target.value) {
                          props.updateTemplate(
                            produce(props.template, (draft) => {
                              draft.settings.criteriaTitleSize = parseInt(event.target.value);
                            })
                          );
                        }
                      }}
                      onKeyPress={handleExponentialPlusAndMinus("titleFontSize")}
                      inputProps={{ min: 10, max: 32 }}
                    />
                  ),
                },
                {
                  label: "Element name size",
                  options: (
                    <TextField
                      type="number"
                      disabled={isViewOnly}
                      onChange={(event) => {
                        if (event.target.value) {
                          props.updateTemplate(
                            produce(props.template, (draft) => {
                              draft.settings.elementNameSize = parseInt(event.target.value);
                            })
                          );
                        }
                      }}
                      value={elementNameSize}
                      onKeyPress={handleExponentialPlusAndMinus("headerFontSize")}
                      inputProps={fontSizeMinMaxBoundary}
                    />
                  ),
                },
                {
                  label: "Element info size",
                  options: (
                    <TextField
                      type="number"
                      value={elementInfoSize}
                      disabled={isViewOnly}
                      onChange={(event) => {
                        if (event.target.value) {
                          props.updateTemplate(
                            produce(props.template, (draft) => {
                              draft.settings.elementInfoSize = parseInt(event.target.value);
                            })
                          );
                        }
                      }}
                      onKeyPress={handleExponentialPlusAndMinus("bodyTextFontSize")}
                      inputProps={fontSizeMinMaxBoundary}
                    />
                  ),
                },
              ]}
            />
          </Paper>
        </div>
      </div>
    </form>
  );
};

const CriteriaTitleSizeBounds = { min: 10, max: 32 };
const CriteriaTextSizeBounds = { min: 8, max: 18 };
