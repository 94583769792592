import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";

import { useMutation, useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Drawer,
  Fab,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import BackButton from "@material-ui/icons/ArrowBackRounded";
import Add from "@material-ui/icons/AddRounded";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import {
  CreateAccountInput,
  CreateAccountResponse,
  CREATE_ACCOUNT,
  GetAccountInput,
  GetAccountResponse,
  GetAccountSubAccountsInput,
  GetAccountSubAccountsResponse,
  GetApiKeyPlansResponse,
  GetApiKeysInput,
  GetApiKeysResponse,
  GetDrugSourceAndClaimSystemsResponse,
  GET_ACCOUNT,
  GET_ACCOUNT_SUBACCOUNTS,
  GET_API_KEYS,
  GET_API_KEY_PLANS,
  GET_DRUG_SOURCE_AND_CLAIM_SYSTEMS,
  UpdateAccountInput,
  UpdateAccountResponse,
  UPDATE_ACCOUNT,
} from "../api";
import {
  ApiKey,
  ApiKeyPlan,
  NextAccount,
  NextClaimSystem,
  NextDrugSource,
  NextSubAccount,
} from "../types";
import { sharedStyles } from "../../Flex/styles";
import { OptionsTable } from "../../components/tables/OptionsTable";
import produce, { Draft } from "immer";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledAgGrid } from "../../components/tables/dataTables/StyledAgGrid";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { SubaccountEditor } from "./SubaccountEditor";
import { ApiKeyEditor } from "./ApiKeyEditor";

import SaveIcon from "@material-ui/icons/SaveRounded";

enum AccountEditorTab {
  SETUP = "Setup",
  SUB_ACCOUNTS = "Subaccounts",
  API_KEYS = "API Keys",
}

const useStyles = makeStyles((theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  accountIdentifier: {
    marginLeft: 10,
    color: theme.palette.primary.main,
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  tableTabContent: {
    display: "flex",
    flexFlow: "column wrap",
    alignContent: "center",
    justifyContent: "center",
    height: "100%",
    // width: "fit-content",
  },
  rightDrawer: {
    maxWidth: "40%",
    minWidth: "35%",
  },
  title: {
    margin: theme.spacing(3),
  },
}));

type AccountEditorParams = {
  accountId: string;
};

type TabPanelProps = {
  children?: React.ReactNode;
  value: AccountEditorTab;
};

export const AccountEditor: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { accountId } = useParams<AccountEditorParams>();
  const isNewAccount = !Boolean(accountId);

  const [account, setAccount] = React.useState<NextAccount | undefined>(undefined);

  useQuery<GetAccountResponse, GetAccountInput>(GET_ACCOUNT, {
    variables: { accountId },
    fetchPolicy: "network-only",
    skip: isNewAccount,
    onCompleted: (data) =>
      setAccount(
        data?.account ?? {
          createdAt: "",
          id: accountId,
          name: "",
          subAccounts: { items: [] },
        }
      ),
  });

  const [activeTab, setActiveTab] = React.useState<AccountEditorTab>(AccountEditorTab.SETUP);

  const handleTabChange = (_: React.ChangeEvent<{}>, newTab: AccountEditorTab) =>
    setActiveTab(newTab);

  const goBack = () => history.goBack();

  const TabPanel = ({ children, value, ...other }: TabPanelProps) => {
    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  return (
    <div className={classes.fullWidthPageArea}>
      {!account ? (
        <CenteredCircularLoading />
      ) : (
        <>
          <div className={classes.headerArea}>
            <IconButton onClick={goBack}>
              <BackButton />
            </IconButton>
            <Typography variant="h4" className={classes.accountIdentifier}>
              {isNewAccount ? "New Account" : account.name}
            </Typography>
          </div>
          <Divider variant="middle" />
          {!isNewAccount && (
            <Tabs
              centered
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="account navigation tabs"
            >
              {Object.values(AccountEditorTab).map((tab) => (
                <Tab
                  label={<Typography color="inherit">{tab}</Typography>}
                  value={tab}
                  className={classes.tabText}
                />
              ))}
            </Tabs>
          )}
          <TabPanel value={AccountEditorTab.SETUP}>
            <AccountSetupTab account={account} onAccountSaved={setAccount} />
          </TabPanel>
          <TabPanel value={AccountEditorTab.SUB_ACCOUNTS}>
            <SubaccountsTab accountId={account.id} />
          </TabPanel>
          <TabPanel value={AccountEditorTab.API_KEYS}>
            <ApiKeysTab accountId={account.id} />
          </TabPanel>
        </>
      )}
    </div>
  );
};

type AccountSetupTabProps = {
  account: NextAccount;
  onAccountSaved: (account: NextAccount) => void;
};

const AccountSetupTab: React.FC<AccountSetupTabProps> = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const sharedStyleClasses = sharedStyles();

  const isNewAccount = !Boolean(props.account.id);

  const [account, setAccount] = React.useState<NextAccount>(props.account);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [createAccount] = useMutation<CreateAccountResponse, CreateAccountInput>(CREATE_ACCOUNT);
  const [updateAccount] = useMutation<UpdateAccountResponse, UpdateAccountInput>(UPDATE_ACCOUNT);

  type UpdateAccountOptions = {
    nullable: boolean;
    translator?: (value: string) => string | undefined;
  };

  const handleUpdateAccount = (
    setField: (draft: Draft<NextAccount>, value?: any) => void,
    options: UpdateAccountOptions = { nullable: false }
  ) => (event: React.ChangeEvent<any>) => {
    if (options.nullable || (!options.nullable && (event.target.value !== undefined || true))) {
      setAccount(
        produce<NextAccount>(account, (draft) => {
          if (options.translator) {
            const value = options.translator(event.target.value);
            setField(draft, value);
          } else {
            setField(draft, event.target.value);
          }
        })
      );
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    let accountResult: NextAccount;
    try {
      if (isNewAccount) {
        const result = await createAccount({
          variables: {
            account: {
              name: account.name,
              shortName: account.shortName,
              legacyCustomerCode: account.legacyCustomerCode,
              launchDate: account.launchDate?.split("T")[0],
            },
          },
        });

        accountResult = result.data!.createAccount;

        history.replace(`edit/${accountResult.id}`);
      } else {
        const result = await updateAccount({
          variables: {
            id: account.id,
            account: {
              name: account.name,
              shortName: account.shortName,
              legacyCustomerCode: account.legacyCustomerCode,
              launchDate: account.launchDate?.split("T")[0],
            },
          },
        });

        accountResult = result.data!.updateAccount;
      }

      props.onAccountSaved(accountResult);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const PROPERTIES_OPTIONS_TABLE_SETTINGS = [
    {
      label: "Name",
      options: (
        <TextField
          required
          value={account.name}
          onChange={handleUpdateAccount((draft, value) => {
            draft.name = value;
          })}
          className={classes.textFieldOptions}
        >
          {account.name}
        </TextField>
      ),
    },
    {
      label: "Short Name",
      options: (
        <TextField
          required
          value={account.shortName}
          onChange={handleUpdateAccount((draft, value) => {
            draft.shortName = value;
          })}
          className={classes.textFieldOptions}
        >
          {account.shortName}
        </TextField>
      ),
    },
    {
      label: "Legacy Customer Code",
      options: (
        <TextField
          value={account.legacyCustomerCode}
          onChange={handleUpdateAccount((draft, value) => (draft.legacyCustomerCode = value), {
            nullable: true,
          })}
          className={classes.textFieldOptions}
        >
          {account.legacyCustomerCode}
        </TextField>
      ),
    },
    {
      label: "Launch Date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={account.launchDate}
            onChange={(date) =>
              setAccount(
                produce(account, (draft) => {
                  draft.launchDate = date?.toISOString();
                })
              )
            }
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
  ];

  return (
    <>
      <section className={sharedStyleClasses.tabContent}>
        <Card className={sharedStyleClasses.formularyCard}>
          <CardHeader title="Properties" />
          <Divider />
          <CardContent>
            <OptionsTable settings={PROPERTIES_OPTIONS_TABLE_SETTINGS} />
          </CardContent>
        </Card>
      </section>
      <Fab
        className={sharedStyleClasses.fab}
        color="primary"
        disabled={!account.name.trim().length}
        onClick={handleSave}
      >
        {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
      </Fab>
    </>
  );
};

type SubaccountsTabProps = {
  accountId: string;
};

const SubaccountsTab: React.FC<SubaccountsTabProps> = (props) => {
  const classes = useStyles();
  const sharedStyleClasses = sharedStyles();

  const { accountId } = props;

  const [claimSystems, setClaimSystems] = React.useState<Array<NextClaimSystem>>([]);
  const [drugSources, setDrugSources] = React.useState<Array<NextDrugSource>>([]);

  const [selectedSubAccount, setSelectedSubAccount] = React.useState<NextSubAccount | undefined>(
    undefined
  );

  const { data: account, loading: accountLoading, refetch: reloadSubAccounts } = useQuery<
    GetAccountSubAccountsResponse,
    GetAccountSubAccountsInput
  >(GET_ACCOUNT_SUBACCOUNTS, {
    variables: {
      accountId,
    },
  });

  const { data: apiKeys } = useQuery<GetApiKeysResponse, GetApiKeysInput>(GET_API_KEYS, {
    variables: {
      accountId,
    },
  });

  const subAccountUpdated = () => {
    setSelectedSubAccount(undefined);
    reloadSubAccounts();
  };

  useQuery<GetDrugSourceAndClaimSystemsResponse>(GET_DRUG_SOURCE_AND_CLAIM_SYSTEMS, {
    onCompleted: (data) => {
      setClaimSystems(data.claimSystems.items);
      setDrugSources(data.drugSources.items);
    },
  });

  const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      sort: "asc",
    },
    {
      headerName: "Is Parent",
      field: "isParent",
    },
    {
      headerName: "Legacy Client Code",
      field: "legacyClientCode",
      flex: 1,
    },
    {
      headerName: "Claim System",
      field: "claimSystem.sourceName",
    },
    {
      headerName: "Drug Source",
      children: [
        {
          headerName: "Short Name",
          field: "drugSource.shortName",
        },
        {
          headerName: "Name",
          field: "drugSource.drugSource",
        },
      ],
    },
  ];

  return (
    <>
      <section className={classes.tableTabContent}>
        <Drawer
          anchor="right"
          open={selectedSubAccount !== undefined}
          PaperProps={{ className: classes.rightDrawer }}
          onClose={() => setSelectedSubAccount(undefined)}
        >
          {selectedSubAccount && (
            <SubaccountEditor
              onSubAccountUpdated={subAccountUpdated}
              subAccount={selectedSubAccount}
              accountId={accountId}
              drugSources={drugSources}
              claimSystems={claimSystems}
              apiKeys={apiKeys?.account.apiKeys.items ?? []}
            />
          )}
        </Drawer>
        <StyledAgGrid
          isLoading={accountLoading}
          columnDefs={COLUMN_DEFS}
          rowData={account?.account.subAccounts.items}
          onRowClicked={(event) => setSelectedSubAccount(event.data)}
        />
      </section>
      <Fab
        className={sharedStyleClasses.fab}
        color="primary"
        onClick={() =>
          setSelectedSubAccount({
            id: "",
            claimSystem: claimSystems[0],
            drugSource: drugSources[0],
            name: "",
            isMedicareEnabled: false,
            isParent: false,
          })
        }
      >
        <Add />
      </Fab>
    </>
  );
};

const ApiKeysTab: React.FC<SubaccountsTabProps> = (props) => {
  const classes = useStyles();
  const sharedStyleClasses = sharedStyles();

  const { accountId } = props;

  const [selectedApiKey, setSelectedApiKey] = React.useState<ApiKey | undefined>();
  const [apiKeyPlans, setApiKeyPlans] = React.useState<Array<ApiKeyPlan>>([]);

  const { data: apiKeys, loading: keysLoading, refetch: reloadApiKeys } = useQuery<
    GetApiKeysResponse,
    GetApiKeysInput
  >(GET_API_KEYS, {
    variables: {
      accountId,
    },
  });

  useQuery<GetApiKeyPlansResponse>(GET_API_KEY_PLANS, {
    onCompleted(data) {
      setApiKeyPlans(data.apiKeyPlans.items);
    },
  });

  const updateApiKeys = () => {
    setSelectedApiKey(undefined);
    reloadApiKeys();
  };

  const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
      sort: "asc",
    },
    {
      headerName: "Enabled",
      field: "enabled",
    },
    {
      headerName: "Plan",
      field: "plan.name",
      flex: 1,
    },
  ];

  return (
    <>
      <section className={classes.tableTabContent}>
        <Drawer
          anchor="right"
          open={selectedApiKey !== undefined}
          PaperProps={{ className: classes.rightDrawer }}
          onClose={() => setSelectedApiKey(undefined)}
        >
          {selectedApiKey && (
            <ApiKeyEditor
              accountId={accountId}
              apiKey={selectedApiKey}
              plans={apiKeyPlans}
              onSaved={updateApiKeys}
            />
          )}
        </Drawer>
        <StyledAgGrid
          isLoading={keysLoading}
          columnDefs={COLUMN_DEFS}
          rowData={apiKeys?.account.apiKeys.items ?? []}
          onRowClicked={(event) => setSelectedApiKey(event.data)}
        />
      </section>
      <Fab
        className={sharedStyleClasses.fab}
        color="primary"
        onClick={() =>
          setSelectedApiKey({
            id: "",
            name: "",
            enabled: true,
            plan: apiKeyPlans[0],
            createdAt: "",
            key: "",
          })
        }
      >
        <Add />
      </Fab>
    </>
  );
};
