import { useApolloClient } from "@apollo/client";
import * as React from "react";
import { PaginationInput } from "../../../../types/ApiTypes";
import {
  DrugListType,
  GetListDrugsInput,
  GetListDrugsResponse,
  ListDrugsInput,
  TIER_LIST_DRUGS,
} from "../../api";
import { ListsViewPage } from "../../ListViewPage";

type Props = {
  listId: string;
  viewDate: Date;
  isLoading: boolean;
  onDateChange: (date: Date) => void;
};

export const TierListView: React.FC<Props> = (props) => {
  const Apollo = useApolloClient();

  const handleFetchListDrugs = (drugsInput: ListDrugsInput, paginationInput: PaginationInput) =>
    Apollo.query<GetListDrugsResponse, GetListDrugsInput>({
      query: TIER_LIST_DRUGS,
      variables: {
        id: props.listId,
        drugsInput,
        paginationInput,
      },
      fetchPolicy: "no-cache",
    });

  return (
    <ListsViewPage
      fetchListDrugs={handleFetchListDrugs}
      isLoading={props.isLoading}
      onDateChange={props.onDateChange}
      viewDate={props.viewDate}
      listType={DrugListType.TIER}
    />
  );
};
