import { ApolloError } from "@apollo/client";
import { Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import * as React from "react";

type ErrorDetailsTableProps = {
  errors: Array<ApolloError | undefined>;
};

export const ErrorDetailsTable: React.FC<ErrorDetailsTableProps> = (props) => {
  return (
    <Table>
      <TableBody>
        {props.errors
          .filter(Boolean)
          .map((error) => [
            <TableRow>
              <TableCell>
                <Typography>{error!.message}</Typography>
              </TableCell>
            </TableRow>,
            ...error!.graphQLErrors.map((error, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography color="error">{error.message}</Typography>
                </TableCell>
              </TableRow>
            )),
          ])
          .flat()}
      </TableBody>
    </Table>
  );
};
