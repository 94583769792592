import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { NotFound } from "../components/screens/404";
import { UserContext } from "../components/UserContext";
import { getAppTheme } from "../styles/Theme";
import { AppSections, TemplateSections } from "../types/AppTypes";
import { isAppSubSectionEnabled } from "../utils/FeatureFlags";
import { CriteteriaTemplatePageSwitch } from "./Criteria/CriteriaTemplatePageSwitch";
import { CriteriaTemplatesListPage } from "./Criteria/CriteriaTemplatesListPage";
import { FormularyTemplatePageSwitch } from "./Formulary/FormularyTemplatePageSwitch";
import { FormularyTemplatesListPage } from "./Formulary/TemplatesListPage/FormularyTemplatesListPage";
import { ManageWebSearchTemplate } from "./WebSearch/ManageWebSearchTemplate";
import { WebSearchTemplatePageSwitch } from "./WebSearch/WebSearchTemplatePageSwitch";

export const TemplatePageRoutes: React.FC = () => {
  const {
    user: { settings },
  } = React.useContext(UserContext);

  return (
    <ThemeProvider theme={getAppTheme(AppSections.TEMPLATES, settings.textSize)}>
      <Switch>
        {/* Formulary Template Routes */}
        {isAppSubSectionEnabled(TemplateSections.FORMULARY) && (
          <Route path="/templates/formulary" exact={true}>
            <FormularyTemplatesListPage />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.FORMULARY) && (
          <Route path="/templates/formulary/new" exact={true}>
            <FormularyTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.FORMULARY) && (
          <Route path="/templates/formulary/edit/:templateId" exact={true}>
            <FormularyTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.FORMULARY) && (
          <Route path="/templates/formulary/view/:templateId" exact={true}>
            <FormularyTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.FORMULARY) && (
          <Route path="/templates/formulary/copy/:templateId" exact={true}>
            <FormularyTemplatePageSwitch />
          </Route>
        )}

        {/* Criteria Template Routes */}
        {isAppSubSectionEnabled(TemplateSections.CRITERIA) && (
          <Route path="/templates/criteria" exact={true}>
            <CriteriaTemplatesListPage />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.CRITERIA) && (
          <Route path="/templates/criteria/new" exact={true}>
            <CriteteriaTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.CRITERIA) && (
          <Route path="/templates/criteria/edit/:templateId" exact={true}>
            <CriteteriaTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.CRITERIA) && (
          <Route path="/templates/criteria/view/:templateId" exact={true}>
            <CriteteriaTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.CRITERIA) && (
          <Route path="/templates/criteria/copy/:templateId" exact={true}>
            <CriteteriaTemplatePageSwitch />
          </Route>
        )}

        {/* Web Search Template Routes */}
        {isAppSubSectionEnabled(TemplateSections.WEB_SEARCH) && (
          <Route path="/templates/web search/new" exact={true}>
            <WebSearchTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.WEB_SEARCH) && (
          <Route path="/templates/web search/edit/:templateId" exact={true}>
            <WebSearchTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.WEB_SEARCH) && (
          <Route path="/templates/web search/view/:templateId" exact={true}>
            <WebSearchTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.WEB_SEARCH) && (
          <Route path="/templates/web search/copy/:templateId" exact={true}>
            <WebSearchTemplatePageSwitch />
          </Route>
        )}
        {isAppSubSectionEnabled(TemplateSections.WEB_SEARCH) && (
          <Route path="/templates/web search" exact={true}>
            <ManageWebSearchTemplate />
          </Route>
        )}
        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};
