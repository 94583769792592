import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { DollarInputCell } from "./CostShareTableInputCells";

const useStyles = makeStyles({
  rangeStartingCost: { marginRight: "0.4rem" },
  toText: { marginRight: "0.4rem" },
});

interface Props {
  claimRangeStartCost: number;
  nextClaimRangeStartCost: number;
  readonly: boolean;
  onBlur: (newEndVal: number) => void;
}

export function ClaimRangeCells(props: Props): JSX.Element {
  const classes = useStyles();
  const [endCost, setEndCost] = React.useState(props.nextClaimRangeStartCost);

  function handleValueChange(newVal: number) {
    setEndCost(newVal);
  }

  function handleBlur() {
    props.onBlur(endCost);
  }

  return (
    <>
      <Typography variant="body1" className={classes.rangeStartingCost}>
        ${props.claimRangeStartCost}
      </Typography>
      <Typography className={classes.toText}>-</Typography>
      <DollarInputCell
        value={endCost}
        disabled={props.readonly}
        width={"4rem"}
        onValueChange={handleValueChange}
        onBlur={handleBlur}
      />
    </>
  );
}
