import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import * as React from "react";
import { publishedDocumentPreviewPath } from "../../../../utils/Files";
import { ApiFullFormularyDocument, DocumentRenderStatus } from "./DocumentTypes";

const useStyles = makeStyles((theme) => ({
  centeredMessageRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  instructions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  colorEmphasis: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  rightPaneContent: {
    gridColumn: "5 / 17",
  },
}));

interface Props {
  document: ApiFullFormularyDocument;
  formularyId: string;
  isPublishing: boolean;
}

type DocumentStatus =
  | { status: "loading" }
  | { status: "display"; url: string }
  | { status: "error" }
  | { status: "not published" };

export const DocumentPreview: React.FC<Props> = ({ document, isPublishing, formularyId }) => {
  const classes = useStyles();
  const [documentStatus, setDocumentStatus] = React.useState<DocumentStatus>({
    status: "loading",
  });

  React.useEffect(() => {
    if (isPublishing === false) {
      if (
        document.downloadPath &&
        document.publishHistory.items.some(
          (docHistoryVersion) => docHistoryVersion.status === DocumentRenderStatus.DONE
        )
      ) {
        setDocumentStatus({
          status: "display",
          url: publishedDocumentPreviewPath(window.location.hostname, document.downloadPath),
        });
      } else {
        setDocumentStatus({ status: "not published" });
      }
    }
  }, [document.downloadPath, document.id, document.publishHistory, formularyId, isPublishing]);

  if (document === undefined) {
    return (
      <Typography
        variant="h5"
        className={classnames(classes.instructions, classes.rightPaneContent)}
      >
        Please select a document to see a preview, or add a new one.
      </Typography>
    );
  } else if (documentStatus.status === "not published") {
    return (
      <Typography
        variant="h5"
        className={classnames(classes.instructions, classes.rightPaneContent)}
      >
        Publish Formulary to Build a PDF of {document.name}
      </Typography>
    );
  } else if (isPublishing) {
    return <PublishingMessage />;
  } else if (documentStatus.status === "loading") {
    return (
      <div className={classnames(classes.centeredMessageRoot, classes.rightPaneContent)}>
        <Typography variant="h5" color="primary" style={{ marginRight: 10 }}>
          Downloading Document
        </Typography>
        <CircularProgress />
      </div>
    );
  } else if (documentStatus.status === "display") {
    return (
      <iframe
        src={documentStatus.url}
        width="100%"
        height="100%"
        title={document.name}
        style={{ gridColumn: "5 / 17", borderWidth: 0 }}
      />
    );
  } else if (documentStatus.status === "error") {
    return (
      <Typography
        variant="h5"
        className={classnames(classes.instructions, classes.rightPaneContent)}
      >
        Error downloading {document.name}. Please try again.
      </Typography>
    );
  } else {
    return (
      <Typography
        variant="h5"
        className={classnames(classes.instructions, classes.rightPaneContent)}
      >
        Publish Formulary to Build a PDF of {document.name}
      </Typography>
    );
  }
};

export const PublishingMessage = () => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.centeredMessageRoot, classes.rightPaneContent)}>
      <Typography variant="h5" color="primary" style={{ marginRight: 10 }}>
        Building Documents
      </Typography>
      <CircularProgress />
    </div>
  );
};
export const NothingSelectedMessage = () => {
  const classes = useStyles();

  return (
    <Typography variant="h5" className={classnames(classes.instructions, classes.rightPaneContent)}>
      Please select a document to see a preview, or add a new one.
    </Typography>
  );
};
