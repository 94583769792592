import gql from "graphql-tag";
import { FormularyTier } from "../types";

export const GET_FORMULARY_TIERS_LIST_CONFIG = gql`
  query GetFormularyTiersListConfig($formularyId: ID!) {
    formularyTiersConfig(formularyId: $formularyId) {
      tierLists {
        listId
        listName
        priority
        tier {
          id
          subAccountFormularyTierId
          name
          rank
        }
      }
    }
  }
`;

export type GetFormularyTiersListConfigInput = {
  formularyId: string;
};

export type GetFormularyTiersListConfigResponse = {
  formularyTiersConfig: FormularyTiersListConfig;
};

export type TierListItem = {
  listId: string;
  listName: string;
  priority: number;
  tier: FormularyTier | null;
};

export type FormularyTiersListConfig = {
  tierLists: Array<TierListItem>;
};

export const SET_FORMULARY_TIERS_CONFIG = gql`
  mutation SetFormularyTiersConfig($formularyId: ID!, $input: FormularyTiersConfigInput!) {
    setFormularyTiersConfig(formularyId: $formularyId, input: $input) {
      tierLists {
        listId
        listName
        priority
        tier {
          id
          subAccountFormularyTierId
          name
          rank
        }
      }
    }
  }
`;

export type TierListItemInput = {
  listId: string;
  priority: number;
  formularyTierId: string | null;
};

export type SetFormularyTiersListConfigInput = {
  formularyId: string;
  input: {
    tierLists: Array<TierListItemInput>;
  };
};

export const GET_FORMULARY_ALTERNATIVES_LIST_CONFIG = gql`
  query GetFormularyListConfig($formularyId: ID!) {
    formularyAlternativesConfig(formularyId: $formularyId) {
      alternativesLists {
        id
        listName
        effectiveDate
        terminationDate
      }
      unlistedDrugSettings {
        groupBy
        filterByTier
        tierFilters {
          id
          name
          rank
          matchingTiers {
            id
            name
            rank
          }
        }
        pricingListId
      }
    }
  }
`;

export enum AlternativeGroupingType {
  GPI6 = "GPI6",
  HIC3 = "HIC3",
  THERAPEUTIC = "THERAPEUTIC",
}
const SharedGroupingType = {
  THERAPEUTIC: AlternativeGroupingType.THERAPEUTIC,
};
export const MSPANGroupingType = {
  ...SharedGroupingType,
  GPI6: AlternativeGroupingType.GPI6,
};
export const FDBGroupingType = {
  ...SharedGroupingType,
  HIC3: AlternativeGroupingType.HIC3,
};
export type GetFormularyAlternativesListConfigInput = {
  formularyId: string;
};

export type GetFormularyAlternativesListConfigResponse = {
  formularyAlternativesConfig: FormularyAlternativesListConfig;
};
export type FormularyAlternativesListConfig = {
  alternativesLists: Array<AlternativesPriorityListItem>;
  unlistedDrugSettings: {
    filterByTier: boolean;
    groupBy: AlternativeGroupingType;
    tierFilters: Array<TierFilter>;
    pricingListId: string | null;
  };
};
export type AlternativesPriorityListItem = {
  id: string;
  listName: string;
  effectiveDate: string;
  terminationDate: string | null;
};
export type TierFilter = {
  id: string | null;
  name: string | null;
  rank: number | null;
  matchingTiers: Array<{
    id: string;
    name: string;
    rank: number | null;
  }>;
};

export const UPDATE_ALTERNATIVES_CONFIG = gql`
  mutation UpdateFormularyAlternativesConfig(
    $formularyId: ID!
    $input: FormularyAlternativesConfigInput!
  ) {
    setFormularyAlternativesConfig(formularyId: $formularyId, input: $input) {
      alternativesLists {
        id
        listName
      }
      unlistedDrugSettings {
        groupBy
        filterByTier
        tierFilters {
          id
          matchingTiers {
            id
            rank
          }
          rank
        }
        pricingListId
      }
    }
  }
`;
export type UpdateAlternativesConfigInput = {
  formularyId: string;
  input: {
    alternativesLists: Array<{ id: string; priority: number }>;
    unlistedDrugSettings: {
      filterByTier: boolean;
      groupBy: AlternativeGroupingType;
      tierFilters: TierFiltersInput | null;
      pricingListId: string | null;
    };
  };
};
export type TierFiltersInput = Array<{
  id: string | null;
  matchingTiers: Array<string>; // array of tier ids on formulary
}>;
export type UpdateAlternativesConfigResponse = unknown;
