//#region Interface and Types

import { ApolloError } from "@apollo/client";
import produce from "immer";
import { GraphQLError } from "graphql";

export interface BasicIdNamePair {
  id: string;
  name: string;
}

export type Accumulator =
  | { kind: "deductible"; details: PlanAccumulatorDeductible | null }
  | { kind: "maxOOP"; details: PlanAccumulatorMaxOOP | null };

export interface APIPlanItem extends BasicIdNamePair {
  lineOfBusiness: {
    id: string;
    name: string;
  };
  planType: PlanType;
  effectiveDate: string;
  externalId: string | null;
  terminationDate: string | null;
  calendarYear: number | null;
  formulary: {
    name: string;
  };
  network: {
    name: string;
  };
  planConfigName: string;
  notes: string | null;
  hierarchies: Array<{
    carrierCode: string;
    carrierName: string | null;
    accountCode: string;
    accountName: string | null;
    groupCode: string;
    groupName: string | null;
    effectiveDate: string | null;
    terminationDate: string | null;
    contactName: string | null;
    contactNumber: string | null;
  }> | null;
  accumulator: {
    deductible: {
      isIntegrated: boolean | null;
      isEmbedded: boolean | null;
      amountIndividual: number | null;
      amountFamily: number | null;
      amountIndividualPlus1: number | null;
      excludedTiers: Array<number> | null;
    } | null;
    maxOutOfPocket: {
      isIntegrated: boolean | null;
      isEmbedded: boolean | null;
      amountIndividual: number | null;
      amountFamily: number | null;
      amountIndividualPlus1: number | null;
      amountIndividualMailOrder: number | null;
      excludedTiers: Array<number> | null;
    } | null;
  };
  status: PlanStatus | null;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
}

// TODO: When the below error handling type is expanded to other parts of the app, this should be moved to a more common location
export enum ServerErrorCodeUnhandled {
  // No action was taken
  NULL_OPERATION = 0,
  // Error not explicitly accounted for
  UNKNOWN = 1,
  // Possible SQL error, or DB failure
  DATABASE_FAILURE = 2,
}

// TODO: When the below error handling type is expanded to other parts of the app, this should be moved to a more common location
export enum ServerErrorCodeInput {
  // Request is missing conditionally required content
  MISSING_REQUIRED_INPUT = 101,
  // Request data does not meet a required condition
  INVALID_REQUIRED_CONDITION = 102,
  // Request data does not meet the required uniqueness constraints
  UNIQUE_IDENTIFIER_CONFLICT = 103,
  // Number of items for a field on the request does not meet the required count
  INVALID_CARDINALITY = 104,
}

// TODO: When the below error handling type is expanded to other parts of the app, this should be moved to a more common location
export type ServerErrorCode = ServerErrorCodeUnhandled | ServerErrorCodeInput;

// TODO: When the below error handling type is expanded to other parts of the app, this should be moved to a more common location
export interface ServerError {
  code: ServerErrorCode;
  context?: Array<string>;
}

// TODO: When the below error handling type is expanded to other parts of the app, this should be moved to a more common location
export interface AWSGraphQLError extends GraphQLError {
  data?: ServerError | null;
  errorType?: string | null;
  errorInfo?: unknown | null;
}

// TODO: When the below error handling type is expanded to other parts of the app, this should be moved to a more common location
export interface AWSApolloError extends ApolloError {
  graphQLErrors: ReadonlyArray<AWSGraphQLError>;
}

export interface APILineOfBusinessItem extends BasicIdNamePair {
  isMedicare: boolean;
}

export type APIPlanHierarchy = {
  carrierId: string;
  accountId: string;
  groupId: string;
};

export interface APIPlan extends BasicIdNamePair {
  lineOfBusiness: APILineOfBusinessItem | null;
  planType: PlanType | null;
  configId: string | null;
  externalId: string | null;
  effectiveDate: string | null;
  terminationDate: string | null;
  calendarYear: number | null;
  rxBIN: string | null;
  rxPCN: string | null;
  rxGroup: string | null;
  hierarchies: Array<APIPlanHierarchy> | null;
  networkId: string | null;
  notes: string | null;
  status: PlanStatus | null;
}

export interface APIPlanConfig extends BasicIdNamePair {
  formularyId: string | null;
  networkId: string | null;
}

export interface APIPlanConfigItem extends BasicIdNamePair {
  formularyName: string;
  networkName: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string | null;
  updatedOn: string | null;
}

export interface PlanCoverageOption {
  coverageOrder: number;
  enumValues: Array<string> | null;
  id: string;
  question: string;
  type: "BOOL" | "DECIMAL" | "ENUM" | "INT" | "TEXT";
  value: string | null;
}

export interface APIPlanAccumulator {
  id: string;
  planId: string;
  annualResetDate: string;
  hasDeductible: boolean;
  hasMaxOutOfPocket: boolean;
  deductible: APIPlanAccumulatorDeductible | null;
  maxOop: APIPlanAccumulatorMaxOOP | null;
}

export interface PlanAccumulators {
  id: string;
  planId: string;
  annualResetDate: string;
  hasDeductible: boolean;
  hasMaxOutOfPocket: boolean;
  deductible: PlanAccumulatorDeductible | null;
  maxOop: PlanAccumulatorMaxOOP | null;
}

export function toPlanAccumulator(accumulator: APIPlanAccumulator): PlanAccumulators {
  return produce(accumulator, (draft) => {
    if (draft.deductible) {
      if (draft.deductible.isEmbedded === null) {
        draft.deductible.isEmbedded = false;
      }
      if (draft.deductible.isIntegrated === null) {
        draft.deductible.isIntegrated = false;
      }
    }
    if (draft.maxOop) {
      if (draft.maxOop.isEmbedded === null) {
        draft.maxOop.isEmbedded = false;
      }
      if (draft.maxOop.isIntegrated === null) {
        draft.maxOop.isIntegrated = false;
      }
    }
  }) as PlanAccumulators;
}

export interface APIPlanAccumulatorDeductible {
  excludedTiers: Array<number> | null;
  isEmbedded: boolean | null;
  isIntegrated: boolean | null;
  inNetwork: APIAccumulatorCategory | null;
  outOfNetwork: APIAccumulatorCategory | null;
}

export interface PlanAccumulatorDeductible {
  excludedTiers: Array<number> | null;
  isEmbedded: boolean;
  isIntegrated: boolean;
  inNetwork: APIAccumulatorCategory | null;
  outOfNetwork: APIAccumulatorCategory | null;
}

export interface APIPlanAccumulatorMaxOOP {
  excludedTiers: Array<number> | null;
  isEmbedded: boolean | null;
  isIntegrated: boolean | null;
  inNetwork: APIAccumulatorCategory | null;
  outOfNetwork: APIAccumulatorCategory | null;
  mailOrder: APIAccumulatorCategory | null;
}

export interface PlanAccumulatorMaxOOP {
  excludedTiers: Array<number> | null;
  isEmbedded: boolean;
  isIntegrated: boolean;
  inNetwork: APIAccumulatorCategory | null;
  outOfNetwork: APIAccumulatorCategory | null;
  mailOrder: APIAccumulatorCategory | null;
}

export type APIAccumulatorCategory = {
  individual: number | null;
  family: number | null;
  individualPlus1: number | null;
};

export type FlatPlan = {
  id: string;
  name: string;
  effectiveDate: string;
  formularyName: string;
  networkName: string;
  externalId: string | null;
  planConfigName: string;
  planType: PlanType;
  notes: string | null;
  calendarYear: number | null;
  lineOfBusinessId: string;
  lineOfBusinessName: string;
  deductibleIntegrated: boolean | null;
  deductibleEmbedded: boolean | null;
  deductibleAmountIndividual: number | null;
  deductibleAmountFamily: number | null;
  deductibleAmountIndividualPlus1: number | null;
  deductibleExcludedTiers: string | null;
  maxOOPIntegrated: boolean | null;
  maxOOPEmbedded: boolean | null;
  maxOOPAmountIndividual: number | null;
  maxOOPAmountFamily: number | null;
  maxOOPAmountIndividualPlus1: number | null;
  maxOOPAmountIndividualMailOrder: number | null;
  maxOOPExcludedTiers: string | null;
  carrierCode: string | null;
  carrierName: string | null;
  accountCode: string | null;
  accountName: string | null;
  groupCode: string | null;
  groupName: string | null;
  groupEffectiveDate: string | null;
  groupTerminationDate: string | null;
  groupContactName: string | null;
  groupContactNumber: string | null;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
};

export function flattenPlan(plan: APIPlanItem): Array<FlatPlan> {
  const { lineOfBusiness, formulary, network, hierarchies, accumulator, ...flatPlan } = plan;
  const { deductible, maxOutOfPocket } = accumulator;
  const planRow = {
    ...flatPlan,
    lineOfBusinessId: lineOfBusiness.id,
    lineOfBusinessName: lineOfBusiness.name,
    formularyName: formulary.name,
    networkName: network.name,
    deductibleIntegrated: deductible?.isIntegrated ?? null,
    deductibleEmbedded: deductible?.isEmbedded ?? null,
    deductibleAmountIndividual: deductible?.amountIndividual ?? null,
    deductibleAmountFamily: deductible?.amountFamily ?? null,
    deductibleAmountIndividualPlus1: deductible?.amountIndividualPlus1 ?? null,
    deductibleExcludedTiers: deductible?.excludedTiers
      ? deductible?.excludedTiers.toString()
      : null,
    maxOOPIntegrated: maxOutOfPocket?.isIntegrated ?? null,
    maxOOPEmbedded: maxOutOfPocket?.isEmbedded ?? null,
    maxOOPAmountIndividual: maxOutOfPocket?.amountIndividual ?? null,
    maxOOPAmountFamily: maxOutOfPocket?.amountFamily ?? null,
    maxOOPAmountIndividualPlus1: maxOutOfPocket?.amountIndividualPlus1 ?? null,
    maxOOPAmountIndividualMailOrder: maxOutOfPocket?.amountIndividualPlus1 ?? null,
    maxOOPExcludedTiers: maxOutOfPocket?.excludedTiers
      ? maxOutOfPocket?.excludedTiers.toString()
      : null,
    carrierCode: null,
    carrierName: null,
    accountCode: null,
    accountName: null,
    groupCode: null,
    groupName: null,
    groupEffectiveDate: null,
    groupTerminationDate: null,
    groupContactName: null,
    groupContactNumber: null,
  };
  return !!hierarchies && hierarchies.length > 0
    ? hierarchies.map((hierarchy) => ({
        ...planRow,
        carrierCode: hierarchy.carrierCode,
        carrierName: hierarchy.carrierName,
        accountCode: hierarchy.accountCode,
        accountName: hierarchy.accountName,
        groupCode: hierarchy.groupCode,
        groupName: hierarchy.groupName,
        groupEffectiveDate: hierarchy.effectiveDate,
        groupTerminationDate: hierarchy.terminationDate,
        groupContactName: hierarchy.contactName,
        groupContactNumber: hierarchy.contactNumber,
      }))
    : [planRow];
}

export interface CostShareType {
  description: string;
  id: string;
  isMedicare: string;
}

//#endregion

//#region Enums

export enum PlanStatus {
  Draft = "DRAFT",
  Published = "PUBLISHED",
}

export enum PlanPageMode {
  NEW = "NEW",
  EDIT = "EDIT",
  VIEW = "VIEW",
  ERROR = "ERROR",
}

export enum PlanType {
  HMO = "HMO",
  PPO = "PPO",
  POS = "POS",
  EPO = "EPO",
  HDHP = "HDHP",
}

export enum PlanConfigPageMode {
  NEW = "NEW",
  EDIT = "EDIT",
  VIEW = "VIEW", // used for "info" readonly mode too
  ERROR = "ERROR",
}

export enum PlanAttributes {
  ID = "id",
  NAME = "name",
  PLAN_TYPE = "planType",
  EFFECTIVE_DATE = "effectiveDate",
  TERMINATION_DATE = "terminationDate",
  FORMULARY_NAME = "formularyName",
  NETWORK_NAME = "networkName",
  EXTERNAL_ID = "externalId",
  PLAN_CONFIG_NAME = "planConfigName",
  NOTES = "notes",
  CALENDAR_YEAR = "calendarYear",
  LOB_NAME = "lineOfBusinessName",
  DEDUCTIBLE_INTEGRATED = "deductibleIntegrated",
  DEDUCTIBLE_EMBEDDED = "deductibleEmbedded",
  DEDUCTIBLE_AMOUNT_INDIVIDUAL = "deductibleAmountIndividual",
  DEDUCTIBLE_AMOUNT_FAMILY = "deductibleAmountFamily",
  DEDUCTIBLE_AMOUNT_INDIVIDUAL_PLUS_1 = "deductibleAmountIndividualPlus1",
  DEDUCTIBLE_EXCLUDED_TIERS = "deductibleExcludedTiers",
  MAX_OOP_INTEGRATED = "maxOOPIntegrated",
  MAX_OOP_EMBEDDED = "maxOOPEmbedded",
  MAX_OOP_AMOUNT_INDIVIDUAL = "maxOOPAmountIndividual",
  MAX_OOP_AMOUNT_FAMILY = "maxOOPAmountFamily",
  MAX_OOP_AMOUNT_INDIVIDUAL_PLUS_1 = "maxOOPAmountIndividualPlus1",
  MAX_OOP_AMOUNT_INDIVIDUAL_MAIL_ORDER = "maxOOPAmountIndividualMailOrder",
  MAX_OOP_EXCLUDED_TIERS = "maxOOPExcludedTiers",
  STATUS = "status",
  CARRIER_CODE = "carrierCode",
  CARRIER_NAME = "carrierNames",
  ACCOUNT_CODE = "accountCode",
  ACCOUNT_NAME = "accountNames",
  GROUP_CODE = "groupCode",
  GROUP_NAME = "groupNames",
  GROUP_EFFECTIVE_DATE = "groupEffectiveDate",
  GROUP_TERMINATION_DATE = "groupTerminationDate",
  GROUP_CONTACT_NAME = "groupContactName",
  GROUP_CONTACT_NUMBER = "groupContactNumber",
  CREATED_BY = "createdBy",
  CREATED_ON = "createdOn",
  UPDATED_BY = "updatedBy",
  UPDATED_ON = "updatedOn",
}

//#endregion
