import * as React from "react";
import { Menu, MenuItem, makeStyles, ListItemIcon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { TEXT_PRIMARY_COLOR } from "../../styles/Theme";
import { Option } from "./Types";

const useStyles = makeStyles(() => ({
  linkText: {
    color: TEXT_PRIMARY_COLOR,
    textDecoration: "none",
  },
}));

interface Props<T extends Option> {
  options: Array<T>;
  onOptionItemClick?: <T>(whichOption: T) => void;
  redirectTo: string;
  menuId: string;
  showMenu: null | HTMLElement;
  closeMenu: () => void;
}

export const OptionMenu = <T extends Option>(props: Props<T>) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(props.showMenu);

  const handleCloseOptions = () => {
    setAnchorEl(null);
    props.closeMenu();
  };

  React.useEffect(() => {
    setAnchorEl(props.showMenu);
  }, [props.showMenu]);

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseOptions}>
      {props.options.map((option) => {
        if (option.link) {
          return (
            <Link
              key={option.optionName}
              to={`${props.redirectTo}/${option.link}/${props.menuId}`}
              className={classes.linkText}
            >
              <MenuItem key={option.optionName} onClick={handleCloseOptions}>
                {option.icon && <ListItemIcon style={{ minWidth: 40 }}>{option.icon}</ListItemIcon>}
                {option.optionName}
              </MenuItem>
            </Link>
          );
        } else if (option.fn) {
          return (
            <MenuItem
              key={option.optionName}
              onClick={(event) => {
                option.fn!(props.menuId);
                handleCloseOptions();
              }}
            >
              {option.icon && <ListItemIcon style={{ minWidth: 40 }}>{option.icon}</ListItemIcon>}
              {option.optionName}
            </MenuItem>
          );
        } else {
          return (
            <MenuItem key={option.optionName} disabled>
              {option.icon && <ListItemIcon style={{ minWidth: 40 }}>{option.icon}</ListItemIcon>}
              {option.optionName}
            </MenuItem>
          );
        }
      })}
    </Menu>
  );
};
