import { createContext } from "react";
import { AppSettings, User } from "../utils/User";

/**
 * Creates the user context
 *
 * createContext's first argument is the default context.
 *  This is mostly used in code testing scenarios where components might render without the other components having set up the context.
 *  In practice this default context will never be used in dev/prod builds when running the app since the app only renders once the context has been properly set.
 */
export const UserContext = createContext<UserContextType>({
  user: {
    accountName: "",
    subAccountId: "",
    userName: "",
    subAccountName: "",
    code: "",
    drugSourceId: "",
    drugSourceShort: "FDB",
    claimSystemId: "",
    claimSystemSourceName: "",
    isManagingMedicare: true,
    userId: "",
    userEmail: "",
    sub: "",
    settings: {
      textSize: null,
      contentSpacing: null,
    },
  },
  updateUserSettings: () => {},
});

type UserContextType = { user: User; updateUserSettings: (newSettings: AppSettings) => void };
