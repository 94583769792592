import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { IsViewingTemplateContext } from "../isEditingTemplateContext";
import {
  GetFormularyTemplateInput,
  GetFormularyTemplateResponse,
  GET_FORMULARY_TEMPLATE,
  SaveFormularyTemplateResponse,
  UpdateFormularyTemplateInput,
  UPDATE_FORMULARY_TEMPLATE,
} from "./api";
import { FormularyTemplateContainer } from "./FormularyTemplatePageContainer";
import {
  FormularyTemplateStatus,
  fromApiFormularyTemplateSettings,
  FullFormularyTemplate,
  toApiFormularyTemplateSettings,
} from "./types";

export const EditFormularyTemplatePage: React.FC = () => {
  const { templateId } = useParams<any>();

  const { pathname } = useLocation();
  const isEditing = pathname.includes("view");

  const { data } = useQuery<GetFormularyTemplateResponse, GetFormularyTemplateInput>(
    GET_FORMULARY_TEMPLATE,
    { variables: { templateId: templateId as string } }
  );

  const [updateTemplate, { loading: isSaving }] = useMutation<
    SaveFormularyTemplateResponse,
    UpdateFormularyTemplateInput
  >(UPDATE_FORMULARY_TEMPLATE);

  const [templateStatus, setTemplateStatus] = React.useState<FormularyTemplateStatus>({
    status: "loading",
  });

  React.useEffect(() => {
    if (templateStatus.status === "loading" && data) {
      setTemplateStatus({
        status: "available",
        template: {
          ...data.template,
          settings: fromApiFormularyTemplateSettings(data.template.settings),
        },
      });
    } else if (isSaving && templateStatus.status === "available") {
      setTemplateStatus({ status: "saving", template: templateStatus.template });
    }
  }, [isSaving, templateStatus, data]);

  const saveTemplate = (template: FullFormularyTemplate) => {
    if (templateStatus.status === "available") {
      updateTemplate({
        variables: {
          templateId: templateId as string,
          name: template.name,
          settings: toApiFormularyTemplateSettings(template.settings),
        },
      }).then(() => {
        setTemplateStatus({ status: "available", template });
      });
    }
  };

  return (
    <IsViewingTemplateContext.Provider value={isEditing}>
      <FormularyTemplateContainer saveTemplate={saveTemplate} templateStatus={templateStatus} />
    </IsViewingTemplateContext.Provider>
  );
};
