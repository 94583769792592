import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Warning from "@material-ui/icons/WarningRounded";

import { useDialogStyles } from "./DialogStyles";

interface Props {
  isOpen: boolean;
  onYesHandler: () => void;
  onNoHandler: () => void;
  isDisabled?: boolean;
  dialogContent?: JSX.Element | React.ComponentType | string;
  dialogIcon?: React.ComponentType<SvgIconProps> | JSX.Element;
  dialogTitle?: string;
}

export function ConfirmationDialog(props: Props): JSX.Element {
  const classes = useDialogStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={props.isOpen}
      PaperProps={{ classes: { root: classes.dialogBox } }}
    >
      <DialogTitle disableTypography className={classes.dialogTitleArea}>
        <div className={classes.dialogTitleIconArea}>
          {props.dialogIcon ? props.dialogIcon : <Warning fontSize="large" color="inherit" />}
        </div>
        <Typography variant="h6" className={classes.dialogTitleTextArea}>
          {props.dialogTitle ? props.dialogTitle : "Are you sure?"}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContentArea}>{props.dialogContent}</DialogContent>
      <DialogActions>
        <Button
          disabled={props.isDisabled}
          onClick={props.onYesHandler}
          className={classes.actionButtons}
        >
          YES
        </Button>
        <Button
          disabled={props.isDisabled}
          onClick={props.onNoHandler}
          className={classes.actionButtons}
        >
          NO
        </Button>
      </DialogActions>
    </Dialog>
  );
}
