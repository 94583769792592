import {
  Divider,
  fade,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import Add from "@material-ui/icons/AddRounded";
import OptionsIcon from "@material-ui/icons/MoreVertRounded";
import SearchIcon from "@material-ui/icons/SearchRounded";
import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { TEXT_PRIMARY_COLOR } from "../../styles/Theme";
import { CenteredCircularLoading } from "../loading/CenteredCircularLoading";
import { UserContext } from "../UserContext";
import { OptionMenu } from "./OptionsMenu";
import { Item, Option } from "./Types";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "white",
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
  },
  selectedListItem: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  linkText: {
    color: TEXT_PRIMARY_COLOR,
    textDecoration: "none",
  },
  selectedText: {
    color: "white",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  nameEllipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  searchListItem: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  searchBar: {
    padding: "0 20px",
    width: "80%",
    backgroundColor: "#ECECEC",
  },
  noMatch: {
    textAlign: "center",
    backgroundColor: "white",
    color: fade(theme.palette.text.primary, 0.5),
  },
  noData: {
    textAlign: "center",
    color: fade(theme.palette.text.primary, 0.5),
    backgroundColor: fade(theme.palette.common.black, 0.1),
    fontStyle: "italic",
  },
}));

interface Props<T extends Item> {
  listData: Array<T>;
  onItemSelection: (itemId: string) => void;
  dataLoading?: boolean;
  menuOptions?: Array<Option>;
  hasAddButton?: boolean;
  addButtonText?: string;
  redirectLink?: string;
  noItemsText?: string;
}

export const SearchList = <T extends Item>(props: Props<T>) => {
  const classes = useStyles();

  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const [selectedItem, setSelectedItem] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedMenuId, setSelectedMenuId] = React.useState("");
  const [searchTerm, setSearchTerm] = React.useState("");

  const itemClick = (id: string) => (_: React.MouseEvent) => {
    setSelectedItem(id);
    props.onItemSelection(id);
  };

  const handleClickOptions = (itemId: string) => (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenuId(itemId);
    setSelectedItem(itemId);
  };

  const handleAnchorReset = () => {
    setAnchorEl(null);
  };

  const searchList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const getFilteredList = () => {
    if (searchTerm === "") {
      return props.listData;
    } else {
      const searchText = searchTerm.toLowerCase();
      return props.listData.filter((item) => item.name.toLowerCase().includes(searchText));
    }
  };

  const buildList = (filteredList: Array<T>) => {
    return (
      <>
        {filteredList.map((item) => {
          return (
            <ListItem
              button
              key={item.id}
              className={classes.nestedListItem}
              selected={selectedItem !== "" && item.id === selectedItem}
              onClick={itemClick(item.id)}
              classes={{ selected: classes.selectedListItem }}
            >
              <ListItemText
                className={item.id === selectedItem ? classes.selectedText : classes.nameEllipsis}
                classes={{
                  primary: item.id === selectedItem ? classes.selectedText : "",
                }}
                primaryTypographyProps={{ variant: "body2" }}
              >
                {item.name}
              </ListItemText>
              {props.menuOptions && (
                <ListItemSecondaryAction>
                  <IconButton
                    size="small"
                    onClick={handleClickOptions(item.id)}
                    className={item.id === selectedItem ? classes.selectedText : classes.linkText}
                  >
                    <OptionsIcon color="inherit" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
        {props.menuOptions && props.redirectLink && (
          <OptionMenu
            menuId={selectedMenuId}
            options={props.menuOptions}
            redirectTo={props.redirectLink}
            showMenu={anchorEl}
            closeMenu={handleAnchorReset}
          />
        )}
      </>
    );
  };

  const buildSimpleList = (filteredList: Array<T>) => {
    if (filteredList !== undefined) {
      if (filteredList && Object.entries(filteredList).length !== 0) {
        return buildList(filteredList);
      } else if (searchTerm !== "") {
        return (
          <ListItem className={classes.noMatch} key="no entries">
            <ListItemText
              primary="No matches found for"
              secondary={searchTerm}
              primaryTypographyProps={{ color: "inherit" }}
            />
          </ListItem>
        );
      } else {
        return (
          <ListItem className={classNames(classes.noMatch, classes.noData)} key="no data">
            <ListItemText
              primary={props.noItemsText ? props.noItemsText : "No Data"}
              primaryTypographyProps={{ color: "inherit" }}
            />
          </ListItem>
        );
      }
    }
  };

  const searchBar = () => {
    return (
      <>
        <ListItem className={classes.searchListItem} key="search">
          <Paper elevation={0} className={classes.searchBar}>
            <InputBase
              placeholder="Search"
              fullWidth
              inputProps={{ "aria-label": "search term" }}
              onChange={searchList}
              endAdornment={
                <IconButton>
                  <SearchIcon />
                </IconButton>
              }
            />
          </Paper>
        </ListItem>
        <Divider />
      </>
    );
  };

  const addButton = () => {
    return (
      <>
        {props.redirectLink && props.hasAddButton && props.addButtonText && (
          <Link to={`${props.redirectLink}/new`} style={{ textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon style={{ minWidth: "40px" }}>
                <Add htmlColor={TEXT_PRIMARY_COLOR} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  style={{ fontStyle: "italic", textTransform: "capitalize" }}
                  variant="body2"
                >
                  {props.addButtonText}
                </Typography>
              </ListItemText>
            </ListItem>
          </Link>
        )}
        <Divider />
      </>
    );
  };

  return (
    <>
      <List dense={contentSpacing === "dense"} className={classes.root} disablePadding>
        {searchBar()}
        {addButton()}
        {!props.dataLoading && buildSimpleList(getFilteredList())}
      </List>
      {props.dataLoading && (
        <div style={{ background: "white", height: "100%" }}>
          <CenteredCircularLoading />
        </div>
      )}
    </>
  );
};
