import { ApolloError } from "@apollo/client";
import {
  Button,
  Collapse,
  Divider,
  fade,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { AlertDialog } from "../components/dialogs/AlertDialog";

const useStyles = makeStyles((theme) => ({
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
}));

interface Props {
  isOpen: boolean;
  pageErrors: ApolloError | undefined;
  onClose: () => void;
}

export function NetworkErrorDialog(props: Props): JSX.Element {
  const classes = useStyles();

  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);

  function toggleTechnicalErrors() {
    setShowTechnicalErrors(!showTechnicalErrors);
  }

  return (
    <AlertDialog isError isOpen={props.isOpen} dialogTitle={"Error"} onExitHandler={props.onClose}>
      <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
        There was an error on this page. Please wait a moment and refresh or try again. If the
        problem persists, contact your administrator.
      </Typography>
      <Button color="primary" style={{ width: "fit-content" }} onClick={toggleTechnicalErrors}>
        See Details
      </Button>
      <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
        <Divider />
        <Table className={classes.technicalDetailsTable}>
          <TableHead>
            <TableRow key="errorHead">
              <TableCell>
                <Typography variant="body2" color="error">
                  {props.pageErrors?.message}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.pageErrors?.graphQLErrors.map((error, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body2" color="error">
                    {error.message}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Collapse>
    </AlertDialog>
  );
}
