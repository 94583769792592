import { ApolloError, useMutation, useQuery } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import produce from "immer";
import * as React from "react";
import { SaveFab } from "../components/buttons/SaveFab";
import { CenteredCircularLoading } from "../components/loading/CenteredCircularLoading";
import { OptionsTable } from "../components/tables/OptionsTable";
import { AvailableTierItem, TierOptionsTable } from "../components/tables/TiersOptionsTable";
import { sharedStyles } from "../Flex/styles";
import { apiFormatDate, awsDateStringToDate } from "../utils/Date";
import {
  APIEditNetworkItem,
  CreateNetworkInput,
  CREATE_NETWORK,
  GetLOBResponse,
  GetNetworkTiersResponse,
  GET_LOB,
  GET_NETWORK_TIERS,
  LineOfBusiness,
  NetworkPageMode,
  NetworkTierUpdate,
  UpdateNetworkInput,
  UPDATE_NETWORK,
} from "./api";
import { NetworkErrorDialog } from "./NetworkErrorDialog";
import { NetworkState, NetworkTier, toNetworkState } from "./types";

const useStyles = makeStyles({
  tierCardContent: {
    overflow: "auto",
    maxHeight: "60vh",
  },
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
});

interface Props {
  mode: NetworkPageMode;
  network: NetworkState;
  onUpdateNetwork: (network: NetworkState) => void;
}

/**
 * TODO - add readonly mode for when the user clicks info
 */
export function NetworkSetup(props: Props): JSX.Element {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const [network, setNetwork] = React.useState<NetworkState>(props.network);
  const [lobs, setLOBs] = React.useState<Array<LineOfBusiness>>([]);
  const [availableTiers, setAvailableTiers] = React.useState<Array<AvailableTierItem>>([]);
  const [error, setError] = React.useState<ApolloError | undefined>(undefined);

  //#region GraphQL Queries and Mutations
  const { loading: isLoadingLOBs } = useQuery<GetLOBResponse>(GET_LOB, {
    onCompleted: (res) => {
      setLOBs(res.linesOfBusiness.items);
    },
    onError: (err) => {
      console.error(err);
      setError(err);
    },
  });

  const { loading: isLoadingTiers } = useQuery<GetNetworkTiersResponse>(GET_NETWORK_TIERS, {
    onCompleted: (res) => {
      setAvailableTiers(
        res.subAccountNetworkTiers.items
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ id, name }) => ({ itemSubAccountId: id, name }))
      );
    },
    onError: (err) => {
      console.error("error loading tiers");
      console.error(err);
      setError(err);
    },
  });

  const [createNetwork, { loading: isCreating }] = useMutation<
    { createNetwork: APIEditNetworkItem },
    CreateNetworkInput
  >(CREATE_NETWORK, {
    onCompleted: (res) => {
      if (res) {
        props.onUpdateNetwork(toNetworkState(res.createNetwork));
      }
    },
    onError: (err) => {
      console.error(err);
      setError(err);
    },
  });

  const [updateNetwork, { loading: isUpdating }] = useMutation<
    { updateNetwork: APIEditNetworkItem },
    UpdateNetworkInput
  >(UPDATE_NETWORK, {
    onError: (err) => {
      console.error(err);
      setError(err);
    },
  });
  //#endregion

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setNetwork({ ...network, name: event.target.value });
  }

  function handleChangeCode(event: React.ChangeEvent<HTMLInputElement>) {
    setNetwork({ ...network, code: event.target.value });
  }

  function handleChangeRegion(event: React.ChangeEvent<HTMLInputElement>) {
    setNetwork({ ...network, region: event.target.value });
  }

  function handleEffDateChange(date: Date | null) {
    setNetwork({ ...network, effectiveDate: apiFormatDate(date) || "" });
  }

  function handleTermDateChange(date: Date | null) {
    setNetwork({ ...network, terminationDate: apiFormatDate(date) || "" });
  }

  function handleAddTier() {
    if (props.mode === NetworkPageMode.NEW) {
      const tierRanks = network.tiers.map((tier) => tier.order).sort((a, b) => a - b);
      const newTierOrder = (tierRanks[tierRanks.length - 1] ?? 0) + 1;
      setNetwork({
        ...network,
        tiers: [...network.tiers, { name: "", itemSubAccountId: "", order: newTierOrder }],
      });
    }
  }

  function handleRemoveTier(tierIndex: number) {
    setNetwork({ ...network, tiers: network.tiers.filter((_, index) => index !== tierIndex) });
  }

  function handleUpdateTierName(tierIndex: number, selectedAvailableTier: AvailableTierItem) {
    setNetwork({
      ...network,
      tiers: network.tiers.map((tier, index) =>
        index === tierIndex ? { ...tier, ...selectedAvailableTier } : tier
      ),
    });
  }

  function handleUpdateTierOrder(tierIndex: number, newOrder: number) {
    setNetwork({
      ...network,
      tiers: network.tiers.map((tier, index) =>
        index === tierIndex ? { ...tier, order: newOrder } : tier
      ),
    });
  }

  function handleUpdateLineOfBusiness(event: React.ChangeEvent<{ value: unknown }>) {
    setNetwork({ ...network, lineOfBusiness: event.target.value as string });
  }

  function handleSaveNetwork() {
    const sortedTiers = produce(network.tiers, (draft) => {
      draft.sort(compareTierOrder);
    });

    if (props.mode === NetworkPageMode.NEW) {
      createNetwork({
        variables: {
          input: {
            name: network.name,
            lineOfBusiness: network.lineOfBusiness,
            effectiveDate: network.effectiveDate,
            tiers: sortedTiers.map((tier) => ({ id: tier.itemSubAccountId })),
            code: network.code,
            region: network.region,
            terminationDate: network.terminationDate,
          },
        },
      });
    } else if (network.id && network.tiers.every((tier) => tier.id)) {
      updateNetwork({
        variables: {
          id: network.id,
          input: {
            name: network.name,
            code: network.code,
            region: network.region,
            terminationDate: network.terminationDate,
            tiers: sortedTiers.map<NetworkTierUpdate>((tier) => ({
              networkTierId: tier.id!, // Asserted due to condition check above
              subAccountNetworkTierId: tier.itemSubAccountId,
            })),
          },
        },
      });
    }
  }

  function handleSortTiers() {
    setNetwork(
      produce(network, (draft) => {
        draft.tiers = draft.tiers
          .sort(compareTierOrder)
          .map((tier, index) => ({ ...tier, order: index + 1 }));
      })
    );
  }

  function clearError() {
    setError(undefined);
  }

  const invalidCode = network.code !== null && network.code.length > 30;
  const readOnly = props.mode === NetworkPageMode.VIEW;
  const validToSave =
    !invalidCode &&
    Boolean(network.name) &&
    Boolean(network.code) &&
    Boolean(network.lineOfBusiness) &&
    Boolean(network.effectiveDate) &&
    network.tiers.every((tier) => tier.itemSubAccountId);

  const networkProperties = [
    {
      label: "Name",
      options: (
        <TextField
          required
          disabled={readOnly}
          value={network.name}
          onChange={handleChangeName}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "Code",
      options: (
        <TextField
          required
          error={invalidCode}
          helperText={invalidCode ? "Exceeds max length of 30" : "Must be unique"}
          disabled={readOnly || props.mode === NetworkPageMode.EDIT}
          value={network.code}
          onChange={handleChangeCode}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "Region code",
      options: (
        <TextField
          required
          disabled={readOnly}
          value={network.region}
          onChange={handleChangeRegion}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "Effective date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            disabled={readOnly || props.mode !== NetworkPageMode.NEW}
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={awsDateStringToDate(network.effectiveDate)}
            onChange={handleEffDateChange}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Termination date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            disabled={readOnly}
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={network.terminationDate ? awsDateStringToDate(network.terminationDate) : null}
            onChange={handleTermDateChange}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Line of business",
      options: readOnly ? (
        <TextField disabled value={network.lineOfBusiness} className={classes.textFieldOptions} />
      ) : (
        <Select
          required
          disabled={props.mode !== NetworkPageMode.NEW}
          value={network.lineOfBusiness}
          onChange={handleUpdateLineOfBusiness}
          style={{ textAlign: "left" }}
          className={classes.textFieldOptions}
        >
          {lobs.map((lob) => {
            return (
              <MenuItem key={lob.id} value={lob.name}>
                {lob.name}
              </MenuItem>
            );
          })}
        </Select>
      ),
    },
  ];

  return (
    <section className={sharedClasses.tabContent}>
      <SaveFab
        isSaving={isCreating || isUpdating}
        onClickSave={handleSaveNetwork}
        isValidToSave={validToSave && !readOnly}
      />
      <Card className={sharedClasses.formularyCard}>
        <CardHeader title="Properties" />
        <Divider />
        <CardContent>
          {isLoadingLOBs ? (
            <CenteredCircularLoading />
          ) : (
            <OptionsTable settings={networkProperties} />
          )}
        </CardContent>
      </Card>
      <Card className={sharedClasses.formularyCard}>
        <CardHeader title="Network Tiers" />
        <Divider />
        <CardContent className={classes.tierCardContent}>
          {isLoadingTiers || availableTiers === undefined ? (
            <CenteredCircularLoading />
          ) : (
            <TierOptionsTable
              availableSubAccountTiers={availableTiers}
              tiers={network.tiers}
              readonly={props.mode === NetworkPageMode.VIEW}
              canAdd={props.mode === NetworkPageMode.NEW}
              canDelete={props.mode === NetworkPageMode.NEW}
              onAddTier={handleAddTier}
              onRemoveTier={handleRemoveTier}
              onUpdateTierName={handleUpdateTierName}
              onUpdateTierOrder={handleUpdateTierOrder}
              afterOrderChange={handleSortTiers}
            />
          )}
        </CardContent>
      </Card>
      <NetworkErrorDialog isOpen={error !== undefined} onClose={clearError} pageErrors={error} />
    </section>
  );
}

function compareTierOrder(tierA: NetworkTier, tierB: NetworkTier) {
  return tierA.order - tierB.order;
}
