import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Fab,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/SaveRounded";
import produce, { Draft } from "immer";
import * as React from "react";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { ErrorDetailsTable } from "../../components/ErrorDetailsTable";
import { OptionsTable } from "../../components/tables/OptionsTable";
import {
  CreateApiKeyInput,
  CreateApiKeyResponse,
  CREATE_API_KEY,
  UpdateApiKeyInput,
  UpdateApiKeyResponse,
  UPDATE_API_KEY,
} from "../api";
import { ApiKey, ApiKeyPlan } from "../types";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  title: {
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  subTitle: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  saveButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
}));

type ApiKeyEditorProps = {
  accountId: string;
  plans: Array<ApiKeyPlan>;
  apiKey: ApiKey;
  onSaved: () => void;
};

export const ApiKeyEditor: React.FC<ApiKeyEditorProps> = (props) => {
  const classes = useStyles();
  const isNewApiKey = !props.apiKey.id;
  const [apiKey, setApiKey] = React.useState<ApiKey>(props.apiKey);

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState<boolean>(false);

  const [createApiKey, { error: errorCreating, loading: isCreating }] = useMutation<
    CreateApiKeyResponse,
    CreateApiKeyInput
  >(CREATE_API_KEY);
  const [updateApiKey, { error: errorUpdating, loading: isUpdating }] = useMutation<
    UpdateApiKeyResponse,
    UpdateApiKeyInput
  >(UPDATE_API_KEY);

  type UpdateApiKeyOptions = {
    nullable: boolean;
    translator?: (value: string) => string | undefined;
  };

  const handleUpdateApiKey = (
    setField: (draft: Draft<ApiKey>, value?: any) => void,
    options: UpdateApiKeyOptions = { nullable: false }
  ) => (event: React.ChangeEvent<any>) => {
    if (options.nullable || (!options.nullable && (event.target.value !== undefined || true))) {
      setApiKey(
        produce<ApiKey>(apiKey, (draft) => {
          if (options.translator) {
            const value = options.translator(event.target.value);
            setField(draft, value);
          } else {
            setField(draft, event.target.value);
          }
        })
      );
    }
  };

  const saveApiKey = async () => {
    try {
      if (isNewApiKey) {
        await createApiKey({
          variables: {
            apiKey: {
              accountId: props.accountId,
              name: apiKey.name,
              description: apiKey.description,
              apiKeyPlanId: apiKey.plan.id,
            },
          },
        });
      } else {
        await updateApiKey({
          variables: {
            apiKey: {
              id: apiKey.id,
              apiKeyPlanId: apiKey.plan.id,
              name: apiKey.name,
              description: apiKey.description,
            },
          },
        });
      }

      props.onSaved();
    } catch (err) {
      setShowAlert(true);
      console.error(err);
    }
  };

  const isSaving = isCreating || isUpdating;

  const APIKEY_OPTIONS_TABLE = [
    {
      label: "Name",
      options: (
        <TextField
          required
          disabled={isSaving}
          value={apiKey.name}
          onChange={handleUpdateApiKey((draft, value) => {
            draft.name = value;
          })}
          className={classes.textFieldOptions}
        >
          {apiKey.name}
        </TextField>
      ),
    },
    {
      label: "Description",
      options: (
        <TextField
          multiline
          disabled={isSaving}
          value={apiKey.description}
          onChange={handleUpdateApiKey((draft, value) => {
            draft.description = value;
          })}
          className={classes.textFieldOptions}
        >
          {apiKey.description}
        </TextField>
      ),
    },
    {
      label: "Usage Plan",
      options: (
        <Select
          disabled={isSaving}
          className={classes.textFieldOptions}
          value={apiKey.plan.id}
          onChange={handleUpdateApiKey((draft, value) => {
            draft.plan.id = value;
          })}
        >
          {props.plans.map((apiKeyPlan) => (
            <MenuItem value={apiKeyPlan.id}>{apiKeyPlan.name}</MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <>
      <Typography variant="h5" color="primary" className={classes.title}>
        {isNewApiKey ? "New Api Key" : apiKey.name}
      </Typography>
      <Fab
        color="primary"
        className={classes.saveButton}
        size="medium"
        disabled={isSaving}
        onClick={saveApiKey}
      >
        {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
      </Fab>
      {!isNewApiKey && (
        <Typography
          variant="body2"
          className={classes.subTitle}
        >{`API Key: ${apiKey.key}`}</Typography>
      )}
      <Divider variant="middle" />
      <OptionsTable settings={APIKEY_OPTIONS_TABLE} />

      <AlertDialog
        isError
        isOpen={showAlert}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setShowAlert(false);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          {`There was an error while ${isNewApiKey ? "creating" : "updating"} the api key.`}
        </Typography>
        <Button
          color="primary"
          style={{ width: "fit-content" }}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <ErrorDetailsTable errors={[errorCreating, errorUpdating]} />
        </Collapse>
      </AlertDialog>
    </>
  );
};
