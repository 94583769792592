import * as React from "react";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  message: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  styles?: React.CSSProperties;
  message?: string;
}

export function CenteredCircularLoading(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer} style={props.styles}>
      {props.message ? (
        <Typography className={classes.message} variant="h5">
          {props.message}
        </Typography>
      ) : null}
      <CircularProgress />
    </div>
  );
}
