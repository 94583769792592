import { useLocation } from "react-router-dom";
import { EditTemplateActions } from "./types";

export const useCurrentAction = (): EditTemplateActions => {
  const { pathname } = useLocation();
  if (pathname.includes("edit") || pathname.includes("view")) {
    return "edit";
  } else if (pathname.includes("copy")) {
    return "copy";
  } else {
    return "new";
  }
};
