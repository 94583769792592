import {
  FormControl,
  FormHelperText,
  Input,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { minMaxStepBoundary } from "../../../utils/FormUtils";
import { toLowerCaseUserText } from "../../../utils/StringUtils";
import { REQUIRED_ERROR } from "../../errorHelpers";
import { MARGIN_SETTINGS, PAGE_SETTINGS } from "../../FieldNameConstant";
import { FullFormularyTemplate, MarginUnits, TemplateFonts } from "../types";
import { ConfigOptionsTable } from "../../../components/tables/ConfigOptionsTable";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";

const useStyles = makeStyles((theme: Theme) => ({
  pageOptionsRoot: {
    alignSelf: "start",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textField: {
    marginRight: 10,
  },
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  marginSection: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const PageSettings: React.FC = () => {
  const { errors, setValue, getValues, clearError } = useFormContext<FullFormularyTemplate>();

  const isViewOnly = React.useContext(IsViewingTemplateContext);

  const classes = useStyles();

  const getMaxInput = () => {
    const unit: string | undefined = getValues(PAGE_SETTINGS.UNITS);

    if (unit) {
      const minMaxBound = minMaxStepBoundary[unit];
      return minMaxBound.max;
    } else {
      return "144";
    }
  };

  // TODO: Either generalize or remove in future
  const getFieldError = (whichField: string, errorType: string): JSX.Element | "" => {
    if (
      errors.settings &&
      errors.settings.marginSettings &&
      (errors.settings.marginSettings as any)[whichField]
    ) {
      const { type, message } = (errors.settings.marginSettings as any)[whichField];
      return <>{type === errorType && <FormHelperText>{message}</FormHelperText>}</>;
    }
    return "";
  };
  // TODO: Either generalize or remove in future
  const isFieldError = (whichField: string): boolean => {
    return Boolean(
      errors.settings &&
        errors.settings.marginSettings &&
        (errors.settings.marginSettings as any)[whichField]
    );
  };

  const onChangeMarginUnit = ([event]: any) => {
    const nextUnit: MarginUnits = event.target.value;

    const convertToCurrentMargin = adjustMarginOnUnitChange(
      getValues(PAGE_SETTINGS.UNITS),
      nextUnit
    );

    const top = parseFloat(getValues(PAGE_SETTINGS.TOP_MARGIN));
    setValue(PAGE_SETTINGS.TOP_MARGIN, convertToCurrentMargin(top));
    clearError(PAGE_SETTINGS.TOP_MARGIN);

    const bottom = parseFloat(getValues(PAGE_SETTINGS.BOTTOM_MARGIN));
    setValue(PAGE_SETTINGS.BOTTOM_MARGIN, convertToCurrentMargin(bottom));
    clearError(PAGE_SETTINGS.BOTTOM_MARGIN);

    const left = parseFloat(getValues(PAGE_SETTINGS.LEFT_MARGIN));
    setValue(PAGE_SETTINGS.LEFT_MARGIN, convertToCurrentMargin(left));
    clearError(PAGE_SETTINGS.LEFT_MARGIN);

    const right = parseFloat(getValues(PAGE_SETTINGS.RIGHT_MARGIN));
    setValue(PAGE_SETTINGS.RIGHT_MARGIN, convertToCurrentMargin(right));
    clearError(PAGE_SETTINGS.RIGHT_MARGIN);

    return event.target.value;
  };

  return (
    <div className={classes.pageOptionsRoot}>
      <ConfigOptionsTable
        settings={[
          {
            label: "Font",
            options: (
              <Controller
                name={PAGE_SETTINGS.FONT}
                as={
                  <Select
                    input={<Input classes={{ focused: classes.themedUnderline }} />}
                    disabled={isViewOnly}
                    data-testid={"Font"}
                  >
                    {Object.keys(TemplateFonts).map((fontKey) => {
                      const font = (TemplateFonts as any)[fontKey as any];
                      return (
                        <MenuItem key={font} value={font}>
                          {toLowerCaseUserText(font)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
              />
            ),
          },
          {
            label: "Page margins",
            options: (
              <div className={classes.marginSection}>
                {MARGIN_SECTIONS.map((marginSection) => {
                  const fieldName = `${MARGIN_SETTINGS}.${marginSection}`;
                  return (
                    <FormControl
                      key={marginSection}
                      error={isFieldError(marginSection)}
                      disabled={isViewOnly}
                      style={{ width: "fit-content" }}
                    >
                      <Controller
                        name={fieldName}
                        as={
                          <TextField
                            label={<Typography>{marginSection}</Typography>}
                            type="number"
                            disabled={isViewOnly}
                            className={classes.textField}
                            inputProps={{
                              step: "0.1",
                              max: getMaxInput(),
                              min: "0",
                            }}
                            InputProps={{ classes: { focused: classes.themedUnderline } }}
                            data-testid={marginSection}
                          />
                        }
                        rules={{
                          required: REQUIRED_ERROR,
                          validate: (value: string) => {
                            if (!/^[0-9]+(\.[0-9]{1,2})?$/.test(value)) {
                              return "margin can only be up to 2 decimal places";
                            }

                            const unit: string | undefined = getValues(PAGE_SETTINGS.UNITS);

                            if (unit) {
                              const minMaxBound = minMaxStepBoundary[unit];
                              const valueNum = parseFloat(value);

                              if (valueNum < parseFloat(minMaxBound.min)) {
                                return `margin below 0`;
                              } else if (valueNum > parseFloat(minMaxBound.max)) {
                                return `above max value of ${
                                  minMaxBound.max
                                } ${unit.toLowerCase()}`;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          },
                        }}
                      />
                      {getFieldError(marginSection, "required")}
                      {getFieldError(marginSection, "pattern")}
                      {getFieldError(marginSection, "validate")}
                    </FormControl>
                  );
                })}
              </div>
            ),
          },
          {
            label: "Margin units",
            options: (
              <FormControl error={isFieldError("units")}>
                <Controller
                  name={PAGE_SETTINGS.UNITS}
                  onChange={onChangeMarginUnit}
                  as={
                    <Select
                      input={<Input classes={{ focused: classes.themedUnderline }} />}
                      disabled={isViewOnly}
                      data-testid="Unit"
                    >
                      {Object.keys(MarginUnits).map((unitsName: string) => {
                        return (
                          <MenuItem key={unitsName} id={unitsName} value={unitsName}>
                            {toLowerCaseUserText(unitsName)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                />
              </FormControl>
            ),
          },
        ]}
      />
    </div>
  );
};

const MARGIN_SECTIONS = ["top", "left", "right", "bottom"];

const UNIT_CONVERSION_FACTOR = {
  [MarginUnits.CM]: 2.54,
  [MarginUnits.IN]: 1,
  [MarginUnits.PT]: 72,
};

const adjustMarginOnUnitChange = (currentUnit: MarginUnits, nextUnit: MarginUnits) => (
  currentMargin: number
) => {
  const convertedUnitsMargin =
    (currentMargin / UNIT_CONVERSION_FACTOR[currentUnit]) * UNIT_CONVERSION_FACTOR[nextUnit];

  const fixedPointMargin = parseFloat(convertedUnitsMargin.toFixed(2));

  const maxValue = parseFloat(minMaxStepBoundary[nextUnit].max);
  if (fixedPointMargin > maxValue) {
    return maxValue;
  } else if (fixedPointMargin <= 0) {
    return 0;
  } else {
    return fixedPointMargin;
  }
};
