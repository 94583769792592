import { useQuery } from "@apollo/client";
import { Divider, IconButton, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import BackButton from "@material-ui/icons/ArrowBackRounded";
import { compareDesc } from "date-fns";
import produce from "immer";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { HeaderSkeleton } from "../../../components/loading/Skeletons";
import { UserContext } from "../../../components/UserContext";
import { apiFormatDate, awsDateStringToDate } from "../../../utils/Date";
import { APIAlternativesList, GetListInput, GetListResponse, GET_LIST } from "../api";
import { ListTypeRoute, useDrugListRouteParams } from "../DrugListRouteUtils";
import { usePrioritizedDrugsStore } from "./AlternativesPriorityStore";
import { AlternativesListPriority } from "./Priority/AlternativesListPriority";
import { AlternativesListRules } from "./Rules/AlternativesListRules";
import { AlternativesListSetup } from "./Setup/AlternativesListSetup";
import { AlternativeListView } from "./View/AlternativeListView";

enum AlternativesListTab {
  SETUP = "Setup",
  RULES = "Rules",
  PRIORITY = "Priority",
  VIEW = "View",
}

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
}));

export const ManageAlternativesDrugList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { drugListId } = useDrugListRouteParams();
  const {
    user: { isManagingMedicare },
  } = React.useContext(UserContext);

  const reset = usePrioritizedDrugsStore((state) => state.reset);

  const [activeTab, setActiveTab] = React.useState<AlternativesListTab>(
    drugListId ? AlternativesListTab.VIEW : AlternativesListTab.SETUP
  );
  const [asOfDate, setAsOfDate] = React.useState(new Date());
  const [drugList, setDrugList] = React.useState<APIAlternativesList | undefined>(undefined);
  const [viewDate, setViewDate] = React.useState(new Date());

  const isNew = drugListId === undefined;

  //#region GQL Queries
  const { loading: loadingList, refetch } = useQuery<
    GetListResponse<APIAlternativesList>,
    GetListInput
  >(GET_LIST, {
    skip: isNew,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: drugListId as string,
      rulesInput: {
        asOfDate: apiFormatDate(asOfDate),
      },
      drugsInput: {
        asOfDate: apiFormatDate(asOfDate),
      },
    },
    onCompleted: (res) => {
      if (!isNew) {
        console.log("loaded list info");
        const sortedResponse = produce(res.list, (draft) => {
          draft?.rules?.items?.sort((a, b) =>
            a.createdAt && b.createdAt
              ? compareDesc(awsDateStringToDate(a.createdAt), awsDateStringToDate(b.createdAt))
              : 0
          );
        });
        setDrugList(sortedResponse as APIAlternativesList);
      }
    },
  });

  //#endregion

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, [drugListId, reset]);

  const refetchList = (asOfDate: Date) => () => {
    if (drugListId) {
      refetch({
        id: drugListId,
        rulesInput: {
          asOfDate: apiFormatDate(asOfDate),
        },
        drugsInput: {
          asOfDate: apiFormatDate(asOfDate),
        },
      }).then((value) => {
        setDrugList(
          produce(value.data.list, (draft) => {
            draft?.rules?.items?.sort((a, b) =>
              a.createdAt && b.createdAt
                ? compareDesc(awsDateStringToDate(a.createdAt), awsDateStringToDate(b.createdAt))
                : 0
            );
          })
        );
      });
    }
  };

  const handleCreateList = (drugListId: string) => {
    history.push(`${ListTypeRoute.ALTERNATIVE}/edit/${drugListId}`);
    setActiveTab(AlternativesListTab.RULES);
  };

  const handleChangeAsOfDate = (date: Date) => {
    setAsOfDate(date);
    refetchList(date);
  };

  const handleTabChange = (_: React.ChangeEvent<{}>, value: AlternativesListTab) => {
    setActiveTab(value);
  };

  const TabPanel = (props: React.PropsWithChildren<{ value: AlternativesListTab }>) => {
    const { children, value, ...other } = props;
    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  const Header = () => {
    if (isNew) {
      return (
        <Typography>{`New ${
          isManagingMedicare ? "Medicare" : ""
        } Alternative Drug List`}</Typography>
      );
    } else if (loadingList) {
      return (
        <div style={{ width: "100%" }}>
          <HeaderSkeleton headerVariant="h4" />
        </div>
      );
    } else if (drugList?.name) {
      return (
        <Typography variant="h4" color="primary">
          {drugList.name}
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.fullWidthPageArea}>
      <div className={classes.headerArea}>
        <IconButton onClick={() => history.push(ListTypeRoute.ALTERNATIVE)}>
          <BackButton />
        </IconButton>
        <Header />
      </div>
      <Divider variant="middle" />
      <Tabs
        centered
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        aria-label="formulary navigation tabs"
      >
        <Tab
          key="altSetup"
          label={<Typography color="inherit">{AlternativesListTab.SETUP}</Typography>}
          value={AlternativesListTab.SETUP}
          className={classes.tabText}
        />
        <Tab
          key="altRules"
          label={<Typography color="inherit">{AlternativesListTab.RULES}</Typography>}
          value={AlternativesListTab.RULES}
          className={classes.tabText}
          disabled={isNew}
        />
        <Tab
          key="altPriority"
          label={<Typography color="inherit">{AlternativesListTab.PRIORITY}</Typography>}
          value={AlternativesListTab.PRIORITY}
          className={classes.tabText}
          disabled={isNew}
        />
        <Tab
          key="altView"
          label={<Typography color="inherit">{AlternativesListTab.VIEW}</Typography>}
          value={AlternativesListTab.VIEW}
          className={classes.tabText}
          disabled={isNew}
        />
      </Tabs>
      <TabPanel value={AlternativesListTab.SETUP}>
        <AlternativesListSetup
          drugList={drugList}
          isLoading={loadingList}
          onCreateList={handleCreateList}
          onSaveList={refetchList(asOfDate)}
        />
      </TabPanel>
      <TabPanel value={AlternativesListTab.RULES}>
        <AlternativesListRules
          rules={drugList?.rules.items ?? []}
          asOfDate={asOfDate}
          isLoading={loadingList}
          onTerminateRule={refetchList(asOfDate)}
          onChangeAsOfDate={handleChangeAsOfDate}
          onAddRule={refetchList(asOfDate)}
        />
      </TabPanel>
      {drugListId && activeTab === AlternativesListTab.PRIORITY && (
        <TabPanel value={AlternativesListTab.PRIORITY}>
          <AlternativesListPriority listId={drugListId} isNew={isNew} />
        </TabPanel>
      )}
      {drugListId && activeTab === AlternativesListTab.VIEW && (
        <TabPanel value={AlternativesListTab.VIEW}>
          {drugList && (
            <AlternativeListView
              listId={drugListId}
              isLoading={loadingList}
              onDateChange={setViewDate}
              viewDate={viewDate}
            />
          )}
        </TabPanel>
      )}
    </div>
  );
};
