import { useQuery } from "@apollo/client";
import { makeStyles, Paper } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import * as React from "react";
import { SearchList } from "../../../components/lists/SearchList";
import { MENU_OPTIONS } from "../../constants";
import { TEMPLATE_TYPES } from "../../types";
import { ListFormularyTemplatesResponse, LIST_FORMULARY_TEMPLATES } from "../api";
import { FullFormularyTemplate } from "../types";

const useStyles = makeStyles((theme) => ({
  container: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
}));

type TemplateListProps = {
  onSelectTemplate: (template: FullFormularyTemplate) => void;
};

export const FormularyTemplatesList: React.FC<TemplateListProps> = (props) => {
  const classes = useStyles();

  const { data, loading } = useQuery<ListFormularyTemplatesResponse>(LIST_FORMULARY_TEMPLATES);

  const handleClickTemplate = (selectedTemplateId: string) => {
    if (data) {
      const selectedTemplate = data.templates.items.find(
        (template) => template.id === selectedTemplateId
      );
      if (selectedTemplate) {
        props.onSelectTemplate(selectedTemplate);
      }
    }
  };

  return (
    <Paper className={classes.container}>
      <SearchList
        listData={data?.templates.items.map(({ id, name }) => ({ id, name })) || []}
        dataLoading={loading}
        onItemSelection={handleClickTemplate}
        menuOptions={MENU_OPTIONS}
        hasAddButton
        addButtonText={`New ${TEMPLATE_TYPES.FORMULARY} Template`}
        redirectLink={`/templates/${TEMPLATE_TYPES.FORMULARY}`}
      />
    </Paper>
  );
};
