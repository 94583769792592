import * as React from "react";
import { NextSubAccount, NextUser, UserEntitlements } from "../types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from "@material-ui/core";

const FRIENDLY_ENTITLEMENT_NAMES: Record<UserEntitlements, string> = {
  CAN_EDIT_PLAN: "Edit Plan",
  CAN_EDIT_NETWORK: "Edit Network",
  CAN_EDIT_FORMULARY: "Edit Formulary",
  CAN_EDIT_ALTS: "Edit Alternatives",
  CAN_EDIT_DESIGNER: "Edit Designer",
  CAN_EDIT_LIST: "Edit List",
};

type Props = {
  user: NextUser;
  entitlements: Array<UserEntitlements>;
  subAccount: NextSubAccount;
  open: boolean;
  onClose: () => void;
  onSetEntitlements: (subAccountId: string, entitlements: Array<UserEntitlements>) => void;
};

export const UserPermissionsEditor: React.FC<Props> = ({
  open,
  onClose,
  user,
  subAccount,
  entitlements: initialEntitlements,
  onSetEntitlements,
}) => {
  const [entitlements, setEntitlements] = React.useState(initialEntitlements);
  const entitlementKeys = Object.keys(FRIENDLY_ENTITLEMENT_NAMES).sort() as Array<UserEntitlements>;

  const onEntitlementChanged = (entitlement: UserEntitlements) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setEntitlements([...entitlements, entitlement]);
    } else {
      setEntitlements(entitlements.filter((e) => e !== entitlement));
    }
  };

  const saveEntitlements = () => {
    onSetEntitlements(subAccount.id, entitlements);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Edit {user.email} Permissions on {subAccount.name}
      </DialogTitle>
      <DialogContent>
        <List dense component="div">
          {entitlementKeys.map((entitlement) => (
            <ListItem>
              <ListItemText primary={FRIENDLY_ENTITLEMENT_NAMES[entitlement]} />
              <ListItemSecondaryAction>
                <Switch
                  checked={entitlements.includes(entitlement)}
                  onChange={onEntitlementChanged(entitlement)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={saveEntitlements} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
