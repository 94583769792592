import { makeStyles, Typography, useTheme } from "@material-ui/core";
import * as React from "react";
import { WebSearchTemplate } from "./types";
import { WebSearchTemplateList } from "./WebSearchTemplateList";
import { WebSearchPreview } from "./Preview/WebSearchPreview";

const useStyles = makeStyles({
  previewArea: {
    gridColumn: "5 / 17",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});

const DEFAULT_TEMPLATE: WebSearchTemplate = {
  id: "",
  name: "",
  settings: {
    primaryColor: "#000000",
    logoPath: "",
    bannerPath: "",
    landingPageInfoText: "",
  },
};

export const ManageWebSearchTemplate: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedTemplate, setSelectedTemplate] = React.useState<WebSearchTemplate | undefined>(
    DEFAULT_TEMPLATE
  );

  const handleSelectTemplate = (webSearchTemplate: WebSearchTemplate) => {
    setSelectedTemplate(webSearchTemplate);
  };

  return (
    <>
      <WebSearchTemplateList onSelectTemplate={handleSelectTemplate} />
      <div className={classes.previewArea}>
        {selectedTemplate ? (
          <WebSearchPreview template={selectedTemplate} />
        ) : (
          <Typography variant="h5">
            Select a <span style={{ color: theme.palette.primary.main }}>Template</span> on the left
            to see a preview of its layout.
          </Typography>
        )}
      </div>
    </>
  );
};
