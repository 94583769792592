import { Avatar, Fab, Fade, fade, makeStyles, Theme, Typography } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { AddDrugIcon } from "../Icons/customIcons";
import { reorderArray } from "../../utils/arrayUtils";
import { DraggableDrugCard } from "../cards/DraggableDrugCard";

interface StyleProps {
  isDisabled: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  addIcon: (props) => ({
    color: "white",
    background: props.isDisabled ? theme.palette.text.disabled : theme.palette.primary.main,
  }),
  list: {
    padding: theme.spacing(),
    width: 280,
    borderRadius: 4,
    transition: theme.transitions.create("background", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  listDragging: {
    background: fade(theme.palette.primary.main, 0.1),
  },
  listItem: (props) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    marginBottom: theme.spacing(),
    opacity: props.isDisabled ? `0.4 !important` : 1,
  }),
  lastListItem: {
    marginTop: "2em",
  },
  listStatic: {
    background: "transparent",
  },
  priorityNumber: (props) => ({
    position: "absolute",
    left: "-100px",
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.common.white,
    border: props.isDisabled
      ? `2px solid ${theme.palette.text.disabled}`
      : `2px solid ${theme.palette.primary.main}`,
    color: props.isDisabled ? theme.palette.text.disabled : theme.palette.primary.main,
  }),
}));

type Props = {
  listData: Array<any>;
  onListDataPriorityChange: (prioritizedList: Array<any>) => void;
  onAddListItem: () => void;
  disabled: boolean;
  renderCardContent: (item: any, disabled: boolean) => JSX.Element;
};

export const DragAndDropPriorityList: React.FC<Props> = (props) => {
  const classes = useStyles({ isDisabled: props.disabled });
  const onDragEnd = (result: DropResult) => {
    if (result.destination) {
      props.onListDataPriorityChange(
        reorderArray(props.listData, result.source.index, result.destination.index)
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={classNames(
              classes.list,
              snapshot.isDraggingOver ? classes.listDragging : classes.listStatic
            )}
          >
            {props.listData.map((item, index) => (
              <Draggable
                key={`item-${index}`}
                draggableId={`drag-item-${index}`}
                index={index}
                isDragDisabled={props.disabled}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    className={classes.listItem}
                  >
                    <DraggableDrugCard
                      item={item}
                      isBeingDragged={snapshot.isDragging}
                      disabled={props.disabled}
                      renderCardContent={props.renderCardContent}
                    />
                    <Avatar className={classes.priorityNumber} color="primary">
                      <Typography color="inherit" variant="h5">
                        {index + 1}
                      </Typography>
                    </Avatar>
                  </div>
                )}
              </Draggable>
            ))}
            <Fade in={!snapshot.isDraggingOver} timeout={{ exit: 0, enter: 250 }}>
              <div className={classNames(classes.listItem, classes.lastListItem)}>
                <Fab
                  color="primary"
                  disabled={props.disabled}
                  onClick={() => {
                    props.onAddListItem();
                  }}
                >
                  <AddDrugIcon />
                </Fab>
                <Avatar className={classes.priorityNumber} color="primary">
                  <Typography color="inherit" variant="h5">
                    {props.listData.length > 0 ? props.listData.length + 1 : 1}
                  </Typography>
                </Avatar>
              </div>
            </Fade>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
