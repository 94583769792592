import * as React from "react";
import { useCurrentAction } from "../customHooks";
import { CopyCriteriaTemplatePage } from "./CopyCriteriaTemplatePage";
import { NewCriteriaTemplatePage } from "./CreateCriteriaTemplatePage";
import { EditCriteriaTemplatePage } from "./EditCriteriaTemplatePage";

export const CriteteriaTemplatePageSwitch: React.FC = () => {
  const action = useCurrentAction();

  switch (action) {
    case "copy":
      return <CopyCriteriaTemplatePage />;
    case "edit":
      return <EditCriteriaTemplatePage />;
    case "new":
      return <NewCriteriaTemplatePage />;
  }
};
