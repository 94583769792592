import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { standardUIFormat } from "../../utils/Date";

const useStyles = makeStyles((theme) => ({
  infoCard: {
    pointerEvents: "none",
    padding: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  noTableBorder: {
    borderBottom: 0,
  },
  rowLabel: {
    width: "40%",
  },
  actionChip: {
    margin: 0,
    alignItems: "inherit",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  captionText: {
    alignSelf: "flex-start",
    color: theme.palette.text.disabled,
  },
}));

type Props = {
  name: string;
  effectiveDate: Date;
  externalId?: string | null;
  terminationDate?: Date | null;
  lineOfBusiness?: string; // TODO - should be required in the future after data model updates
  description?: string;
  actionChip?: JSX.Element;
};

export const QuickViewCard: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="caption" className={classes.captionText}>
        Quick view
      </Typography>
      <Fade in={true} timeout={250}>
        <Card className={classes.infoCard}>
          <CardHeader
            title={props.name}
            action={props.actionChip}
            classes={{
              action: classes.actionChip,
            }}
          />
          <Divider variant="middle" />
          <CardContent>
            {props.description && <Typography>{props.description}</Typography>}
            <Table size="small" style={{ marginTop: 16 }}>
              <TableBody>
                <TableRow>
                  <TableCell className={classNames(classes.noTableBorder, classes.rowLabel)}>
                    <Typography variant="body2" color="primary">
                      External ID:
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.noTableBorder}>
                    {props.externalId || EMPTY_PLACEHOLDER}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classNames(classes.noTableBorder, classes.rowLabel)}>
                    <Typography variant="body2" color="primary">
                      Effective Date:
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.noTableBorder}>
                    {standardUIFormat(props.effectiveDate)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classNames(classes.noTableBorder, classes.rowLabel)}>
                    <Typography variant="body2" color="primary">
                      Termination Date:
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.noTableBorder}>
                    {props.terminationDate
                      ? standardUIFormat(props.terminationDate)
                      : EMPTY_PLACEHOLDER}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classNames(classes.noTableBorder, classes.rowLabel)}>
                    <Typography variant="body2" color="primary">
                      Line of Business:
                    </Typography>
                  </TableCell>
                  <TableCell className={classNames(classes.noTableBorder, classes.capitalize)}>
                    {props.lineOfBusiness ?? EMPTY_PLACEHOLDER}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Fade>
    </>
  );
};

const EMPTY_PLACEHOLDER = "-";
