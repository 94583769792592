import * as React from "react";
import { fade, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { ListAccountsResponse, LIST_ACCOUNTS } from "../api";
import { useQuery } from "@apollo/client";
import produce from "immer";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { SearchList } from "../../components/lists/SearchList";
import { NextAccount } from "../types";
import classNames from "classnames";
import { awsDateStringToDate, standardUIFormat } from "../../utils/Date";
import EditIcon from "@material-ui/icons/CreateRounded";

const useStyles = makeStyles((theme: Theme) => ({
  accountsList: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  previewArea: {
    gridColumn: "7 / span 8",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    background: "white",
    alignItems: "center",
  },
  infoCard: {
    pointerEvents: "none",
    padding: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  actionChip: {
    margin: 0,
    alignItems: "inherit",
  },
  noTableBorder: {
    borderBottom: 0,
  },
  rowLabel: {
    width: "40%",
  },
  captionText: {
    alignSelf: "flex-start",
    color: theme.palette.text.disabled,
  },
}));

export const AccountAdmin: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [accounts, setAccounts] = React.useState<Array<NextAccount>>([]);
  const [selectedAccount, setSelectedAccount] = React.useState<NextAccount | undefined>(undefined);

  const { loading: listAccountsLoading } = useQuery<ListAccountsResponse>(LIST_ACCOUNTS, {
    onCompleted: (res) =>
      setAccounts(
        produce(res.accounts.items, (draft) => {
          draft = draft.sort((a, b) => a.name.localeCompare(b.name));

          for (const account of draft) {
            account.subAccounts.items.sort((a, b) => a.name.localeCompare(b.name));
          }
        })
      ),
  });

  const MENU_OPTIONS = [
    {
      optionName: "Edit",
      link: `edit`,
      icon: <EditIcon fontSize="small" />,
    },
  ];

  const onItemSelected = (accountId: string) =>
    setSelectedAccount(accounts.find((account) => account.id === accountId));

  return (
    <>
      <Paper square className={classes.accountsList}>
        <SearchList
          listData={accounts}
          onItemSelection={onItemSelected}
          dataLoading={listAccountsLoading}
          hasAddButton
          addButtonText={"New Account"}
          redirectLink={"accounts"}
          menuOptions={MENU_OPTIONS}
        />
      </Paper>
      <div className={classes.previewArea}>
        {selectedAccount ? (
          <Fade in={true} timeout={250}>
            <>
              <Typography variant="caption" className={classes.captionText}>
                Quick view
              </Typography>
              <Card className={classes.infoCard}>
                <CardHeader
                  title={selectedAccount.name}
                  action={true}
                  classes={{
                    action: classes.actionChip,
                  }}
                />
                <Divider variant="middle" />
                <CardContent>
                  <Table size="small" style={{ marginTop: 16 }}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classNames(classes.noTableBorder, classes.rowLabel)}>
                          <Typography variant="body2" color="primary">
                            Legacy Customer Code:
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.noTableBorder}>
                          {selectedAccount.legacyCustomerCode}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell className={classNames(classes.noTableBorder, classes.rowLabel)}>
                          <Typography variant="body2" color="primary">
                            Created At:
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.noTableBorder}>
                          {standardUIFormat(awsDateStringToDate(selectedAccount.createdAt))}
                        </TableCell>
                      </TableRow>

                      {selectedAccount.updatedAt && (
                        <TableRow>
                          <TableCell
                            className={classNames(classes.noTableBorder, classes.rowLabel)}
                          >
                            <Typography variant="body2" color="primary">
                              Updated At:
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.noTableBorder}>
                            {standardUIFormat(awsDateStringToDate(selectedAccount.updatedAt))}
                          </TableCell>
                        </TableRow>
                      )}

                      {selectedAccount.launchDate && (
                        <TableRow>
                          <TableCell
                            className={classNames(classes.noTableBorder, classes.rowLabel)}
                          >
                            <Typography variant="body2" color="primary">
                              Launch Date:
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.noTableBorder}>
                            {standardUIFormat(awsDateStringToDate(selectedAccount.launchDate))}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell className={classNames(classes.noTableBorder, classes.rowLabel)}>
                          <Typography variant="body2" color="primary">
                            Sub Accounts:
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.noTableBorder}>
                          {selectedAccount.subAccounts.items.length}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </>
          </Fade>
        ) : (
          <Typography variant="h5">
            Select an <span style={{ color: theme.palette.primary.main }}>Account</span> to quickly
            view its properties
          </Typography>
        )}
      </div>
    </>
  );
};
