export interface NextUserListItem {
  id: string;
  email: string;
  name: string;
  title?: string;
  enabled: boolean;
  createdAt: string | Date;
  updatedAt?: string | Date;
  userAgreementAgreedToAt?: string | Date;
  userAgreementVersionAgreedTo?: string;
}

export interface NextDrugSource {
  id: string;
  shortName: string;
  drugSource: string;
}

export interface NextClaimSystem {
  id: string;
  sourceName: string;
}

export interface ApiKeyPlan {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface ApiKey {
  id: string;
  name: string;
  description?: string;
  enabled: boolean;
  key: string;
  plan: ApiKeyPlan;
  createdAt: string;
}

export interface NextSubAccount {
  id: string;
  name: string;
  legacyClientCode?: string;
  isParent: boolean;
  isMedicareEnabled: boolean;
  apiKeys?: Array<ApiKey>;
  drugSource: NextDrugSource;
  claimSystem: NextClaimSystem;
}

export interface NextAccount {
  id: string;
  name: string;
  shortName?: string;
  legacyCustomerCode?: string;
  launchDate?: string;
  createdAt: string;
  updatedAt?: string;
  subAccounts: {
    items: Array<NextSubAccount>;
  };
}

export type UserEntitlements =
  | "CAN_EDIT_PLAN"
  | "CAN_EDIT_NETWORK"
  | "CAN_EDIT_FORMULARY"
  | "CAN_EDIT_ALTS"
  | "CAN_EDIT_DESIGNER"
  | "CAN_EDIT_LIST";

export interface UserPermissions {
  subAccountId: string;
  entitlements: Array<UserEntitlements>;
}

export interface NextUser {
  id: string;
  email: string;
  name: string;
  title?: string;
  legacyId?: number;
  enabled: boolean;
  sub?: string;
  userAgreementAgreedToAt?: string;
  userAgreementVersionAgreedTo?: string;
  createdAt?: string;
  updatedAt?: string;
  subAccounts: {
    items: Array<NextSubAccount>;
  };
  permissions: Array<UserPermissions>;
  managedByAccount?: {
    id: string;
    name: string;
  };
}

export const EmptyUser: NextUser = {
  id: "",
  email: "",
  name: "",
  enabled: true,
  subAccounts: {
    items: [],
  },
  permissions: [],
};
