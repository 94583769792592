import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import * as React from "react";
import { EditNetworkTier } from "../../Network/api";
import { ClaimRangeCells } from "./ClaimRangeCells";
import { CostShareAction } from "./CostShareReducer";
import { ClaimRangeValue, CostShare, RowKind, ValueType } from "./CostShareSchema";
import { DollarInputCell, PercentageInputCell } from "./CostShareTableInputCells";

const useStyles = makeStyles((theme) => {
  const paddingSpace = theme.spacing(1);
  return {
    root: {
      padding: "0 !important",
      verticalAlign: "top",
      borderRight: "1px solid lightgrey",
    },
    rootEndOfRange: {
      padding: "0 !important",
      verticalAlign: "top",
      borderRight: "1px solid grey",
    },
    subTableRoot: {
      height: "100%",
      padding: "0",
      width: "100%",
      emptyCells: "show",
    },
    addCell: {
      borderRight: "1px solid lightgrey",
      padding: "0 !important",
      width: "3rem",
    },
    claimRangeCellRoot: {
      borderRight: "1px solid lightgrey",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    claimRangeCellContent: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      width: "7rem",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: theme.spacing(1),
      paddingRight: paddingSpace,
    },
    valueCellRoot: {
      paddingLeft: paddingSpace,
      paddingRight: paddingSpace,
      borderRight: "1px solid lightgrey",
    },
    valueCellRootNoClaimRange: { paddingLeft: paddingSpace, paddingRight: paddingSpace },
    valueCellContent: { display: "flex", flexDirection: "row", justifyContent: "flex-end" },
    minCell: {
      borderRight: "1px solid lightgrey",
      paddingLeft: paddingSpace,
      paddingRight: paddingSpace,
    },
    minCellNoClaimRange: {
      borderLeft: "1px solid lightgrey",
      borderRight: "1px solid lightgrey",
      paddingLeft: paddingSpace,
      paddingRight: paddingSpace,
    },
    maxCell: {
      borderRight: "1px solid lightgrey",
      paddingLeft: paddingSpace,
      paddingRight: paddingSpace,
    },
    maxCellNoClaimRange: {
      borderRight: "1px solid lightgrey",
      paddingLeft: paddingSpace,
      paddingRight: paddingSpace,
    },
    maxCellEndRangeNoClaimRange: {
      borderRight: "1px solid grey",
      paddingLeft: paddingSpace,
      paddingRight: paddingSpace,
    },
    removeClaimRangeCell: { paddingLeft: "0px", paddingRight: "0px" },
    hiddenElement: { visibility: "hidden" },
  };
});

interface Props {
  rowKind: RowKind;
  costShare: CostShare;
  networkTier: EditNetworkTier;
  daySupplyRangeStartingValue: number;
  isEndOfRange: boolean;
  readonly: boolean;
  updateCostShare: React.Dispatch<CostShareAction>;
  onChangeValueType: (
    rowKind: RowKind,
    networkTierId: string,
    daySupplyRangeStartingValue: number,
    claimRangeStartingCost: number,
    currentTarget: EventTarget & HTMLButtonElement
  ) => void;
}

export function CostShareValueCell(props: Props): JSX.Element {
  const classes = useStyles();

  function handleChangeValueCell(newValue: number) {
    props.updateCostShare({
      type: "update value cell value",
      rowActionKind: props.rowKind,
      daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
      claimRangeStartingCost: 0,
      networkTierId: props.networkTier.id,
      newValue,
    });
  }

  function handleChangeMinCell(newValue: number) {
    props.updateCostShare({
      type: "update min cell value",
      rowActionKind: props.rowKind,
      networkTierId: props.networkTier.id,
      daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
      claimRangeStartingCost: 0,
      newValue,
    });
  }

  function handleChangeMaxCell(newValue: number) {
    props.updateCostShare({
      type: "update max cell value",
      rowActionKind: props.rowKind,
      daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
      claimRangeStartingCost: 0,
      networkTierId: props.networkTier.id,
      newValue,
    });
  }

  function handleSelectNewValueType(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    props.onChangeValueType(
      props.rowKind,
      props.networkTier.id,
      props.daySupplyRangeStartingValue,
      0,
      event.currentTarget
    );
  }

  const claimRanges = (props.rowKind.kind === "formulary tier"
    ? props.costShare.formularyTiers[props.rowKind.formularyTierId]
    : props.costShare.lists[props.rowKind.listId]
  ).networkTiers[props.networkTier.id].daySupplyRanges[props.daySupplyRangeStartingValue]
    .claimRanges;

  if (props.costShare.isUsingClaimRanges) {
    const claimRangeEntries = Object.entries(claimRanges)
      .map<[number, ClaimRangeValue]>((claimRangeEntry) => [
        parseFloat(claimRangeEntry[0]),
        claimRangeEntry[1],
      ])
      .sort((a, b) => a[0] - b[0]);

    return (
      <TableCell
        key={`value-cells-${
          props.rowKind.kind === "formulary tier"
            ? props.rowKind.formularyTierId
            : props.rowKind.listId
        }-${props.networkTier.id}-${props.daySupplyRangeStartingValue}`}
        className={props.isEndOfRange ? classes.rootEndOfRange : classes.root}
        colSpan={7}
      >
        <Table className={classes.subTableRoot}>
          <TableBody>
            {claimRangeEntries.map(([claimRangeStartCost, claimRangeValue], index) => {
              const startValue = claimRangeStartCost;
              const isFinalRange = index === claimRangeEntries.length - 1;

              function handleUpdateValue(newValue: number) {
                props.updateCostShare({
                  type: "update value cell value",
                  rowActionKind: props.rowKind,
                  daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
                  claimRangeStartingCost: claimRangeStartCost,
                  networkTierId: props.networkTier.id,
                  newValue,
                });
              }
              function handleUpdateValueType(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) {
                props.onChangeValueType(
                  props.rowKind,
                  props.networkTier.id,
                  props.daySupplyRangeStartingValue,
                  claimRangeStartCost,
                  event.currentTarget
                );
              }
              function handleUpdateMin(newValue: number) {
                props.updateCostShare({
                  type: "update min cell value",
                  rowActionKind: props.rowKind,
                  networkTierId: props.networkTier.id,
                  daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
                  claimRangeStartingCost: claimRangeStartCost,
                  newValue,
                });
              }
              function handleUpdateMax(newValue: number) {
                props.updateCostShare({
                  type: "update max cell value",
                  rowActionKind: props.rowKind,
                  networkTierId: props.networkTier.id,
                  daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
                  claimRangeStartingCost: claimRangeStartCost,
                  newValue,
                });
              }

              return (
                <TableRow key={`${claimRangeStartCost}`}>
                  {index === 0 ? (
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={claimRangeEntries.length}
                      className={classes.addCell}
                    >
                      <IconButton
                        size="small"
                        className={props.readonly ? classes.hiddenElement : ""}
                        onClick={() => {
                          props.updateCostShare({
                            type: "add claim range",
                            row: props.rowKind,
                            networkId: props.networkTier.id,
                            daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
                          });
                        }}
                      >
                        <Add />
                      </IconButton>
                    </TableCell>
                  ) : null}
                  <TableCell align="center" colSpan={2} className={classes.claimRangeCellRoot}>
                    <div className={classes.claimRangeCellContent}>
                      {claimRangeEntries.length === 1 ? (
                        <Typography>None</Typography>
                      ) : !isFinalRange ? (
                        <ClaimRangeCells
                          claimRangeStartCost={startValue}
                          nextClaimRangeStartCost={claimRangeEntries[index + 1][0]}
                          onBlur={(newEndVal) => {
                            props.updateCostShare({
                              type: "update claim ranges",
                              daySupplyRangeStartValue: props.daySupplyRangeStartingValue,
                              networkTierId: props.networkTier.id,
                              row: props.rowKind,
                              updatedClaimRangeStartCost: startValue,
                              updatedEndCost: newEndVal,
                            });
                          }}
                          readonly={props.readonly}
                        />
                      ) : (
                        <Typography>${startValue}+</Typography>
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1} className={classes.valueCellRoot}>
                    <div className={classes.valueCellContent}>
                      {claimRangeValue.coPayCoIns === ValueType.DOLLAR ? (
                        <DollarInputCell
                          disabled={props.readonly}
                          value={claimRangeValue.value}
                          onValueChange={handleUpdateValue}
                        />
                      ) : (
                        <PercentageInputCell
                          disabled={props.readonly}
                          value={claimRangeValue.value}
                          onValueChange={handleUpdateValue}
                        />
                      )}
                      <IconButton
                        disabled={props.readonly}
                        size="small"
                        onClick={handleUpdateValueType}
                      >
                        <DownArrowIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell align="center" colSpan={1} className={classes.minCell}>
                    <DollarInputCell
                      disabled={claimRangeValue.coPayCoIns === ValueType.DOLLAR || props.readonly}
                      value={
                        claimRangeValue.coPayCoIns === ValueType.DOLLAR
                          ? undefined
                          : claimRangeValue.min
                      }
                      onValueChange={handleUpdateMin}
                    />
                  </TableCell>
                  <TableCell align="center" colSpan={1} className={classes.maxCell}>
                    <DollarInputCell
                      disabled={claimRangeValue.coPayCoIns === ValueType.DOLLAR || props.readonly}
                      value={
                        claimRangeValue.coPayCoIns === ValueType.DOLLAR
                          ? undefined
                          : claimRangeValue.max
                      }
                      onValueChange={handleUpdateMax}
                    />
                  </TableCell>
                  <TableCell align="center" colSpan={1} className={classes.removeClaimRangeCell}>
                    <IconButton
                      size="small"
                      className={
                        props.readonly || claimRangeStartCost === 0 ? classes.hiddenElement : ""
                      }
                      style={claimRangeStartCost === 0 ? { visibility: "hidden" } : undefined}
                      onClick={() => {
                        props.updateCostShare({
                          type: "delete claim range",
                          row: props.rowKind,
                          claimRangeStartingCost: claimRangeStartCost,
                          daySupplyRangeStartingValue: props.daySupplyRangeStartingValue,
                          networkTierId: props.networkTier.id,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableCell>
    );
  } else {
    const claimRange = claimRanges[0];
    return (
      <React.Fragment
        key={`value-cells-${
          props.rowKind.kind === "formulary tier"
            ? props.rowKind.formularyTierId
            : props.rowKind.listId
        }-${props.networkTier.id}-${props.daySupplyRangeStartingValue}`}
      >
        <TableCell align="center" colSpan={1} className={classes.valueCellRootNoClaimRange}>
          <div className={classes.valueCellContent}>
            {claimRange.coPayCoIns === ValueType.DOLLAR ? (
              <DollarInputCell
                disabled={props.readonly}
                value={claimRange.value}
                onValueChange={handleChangeValueCell}
              />
            ) : (
              <PercentageInputCell
                disabled={props.readonly}
                value={claimRange.value}
                onValueChange={handleChangeValueCell}
              />
            )}
            <IconButton disabled={props.readonly} size="small" onClick={handleSelectNewValueType}>
              <DownArrowIcon />
            </IconButton>
          </div>
        </TableCell>
        <TableCell align="center" colSpan={1} className={classes.minCellNoClaimRange}>
          <DollarInputCell
            disabled={claimRange.coPayCoIns === ValueType.DOLLAR || props.readonly}
            value={claimRange.coPayCoIns === ValueType.DOLLAR ? undefined : claimRange.min}
            onValueChange={handleChangeMinCell}
          />
        </TableCell>
        <TableCell
          align="center"
          colSpan={1}
          className={
            props.isEndOfRange ? classes.maxCellEndRangeNoClaimRange : classes.maxCellNoClaimRange
          }
        >
          <DollarInputCell
            disabled={claimRange.coPayCoIns === ValueType.DOLLAR || props.readonly}
            value={claimRange.coPayCoIns === ValueType.DOLLAR ? undefined : claimRange.max}
            onValueChange={handleChangeMaxCell}
          />
        </TableCell>
      </React.Fragment>
    );
  }
}
