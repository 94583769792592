import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import produce, { Draft } from "immer";
import * as React from "react";
import { OptionsTable } from "../../components/tables/OptionsTable";
import {
  AddApiKeyToSubaccountInput,
  AddApiKeyToSubaccountRespose,
  ADD_API_KEY_TO_SUBACCOUNT,
  CreateSubAccountInput,
  CreateSubAccountResponse,
  CREATE_SUB_ACCOUNT,
  DeleteSubAccountInput,
  DeleteSubAccountResponse,
  DELETE_SUB_ACCOUNT,
  RemoveApiKeyFromSubaccountInput,
  RemoveApiKeyFromSubaccountRespose,
  REMOVE_API_KEY_FROM_SUBACCOUNT,
  UDPATE_SUB_ACCOUNT,
  UpdateSubAccountInput,
  UpdateSubAccountResponse,
} from "../api";
import { ApiKey, NextClaimSystem, NextDrugSource, NextSubAccount } from "../types";
import SaveIcon from "@material-ui/icons/SaveRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import CopyIcon from "@material-ui/icons/AssignmentRounded";
import AddIcon from "@material-ui/icons/AddRounded";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { ErrorDetailsTable } from "../../components/ErrorDetailsTable";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  title: {
    margin: theme.spacing(3),
  },
  fabBar: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  deleteButton: {
    marginRight: theme.spacing(1),
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  dialogInputControl: {
    width: "100%",
  },
}));

type SubaccountEditorProps = {
  onSubAccountUpdated: () => void;
  subAccount: NextSubAccount;
  accountId: string;
  claimSystems: Array<NextClaimSystem>;
  drugSources: Array<NextDrugSource>;
  apiKeys: Array<ApiKey>;
};

export const SubaccountEditor: React.FC<SubaccountEditorProps> = (props) => {
  const classes = useStyles();
  const isNewSubAccount = !Boolean(props.subAccount.id);

  const { claimSystems, drugSources, accountId, onSubAccountUpdated } = props;

  const [subAccount, setSubAccount] = React.useState<NextSubAccount>(props.subAccount);
  const [subAccountApiKeys, setSubAccountApiKeys] = React.useState<Array<ApiKey>>(
    props.subAccount.apiKeys ?? []
  );
  const [availableApiKeys, setAvailableApiKeys] = React.useState<Array<ApiKey>>([]);
  const [selectedApiKeyId, setSelectedApiKeyId] = React.useState<string | undefined>();

  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);

  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState<boolean>(false);

  const [showAddApiKey, setShowAddApiKey] = React.useState<boolean>(false);
  const [showDeleteSubAccount, setShowDeleteSubAccount] = React.useState<boolean>(false);

  React.useEffect(() => {
    setAvailableApiKeys(
      props.apiKeys.filter((key) => !subAccountApiKeys.find((addedKey) => addedKey.id === key.id))
    );
  }, [subAccountApiKeys, props.apiKeys]);

  const [updateSubAccount, { error: errorUpdating }] = useMutation<
    UpdateSubAccountResponse,
    UpdateSubAccountInput
  >(UDPATE_SUB_ACCOUNT);
  const [createSubAccount, { error: errorCreating }] = useMutation<
    CreateSubAccountResponse,
    CreateSubAccountInput
  >(CREATE_SUB_ACCOUNT);

  const [addApiKey, { error: errorAddingApiKey }] = useMutation<
    AddApiKeyToSubaccountRespose,
    AddApiKeyToSubaccountInput
  >(ADD_API_KEY_TO_SUBACCOUNT);
  const [removeApiKey, { error: errorRemovingApiKey }] = useMutation<
    RemoveApiKeyFromSubaccountRespose,
    RemoveApiKeyFromSubaccountInput
  >(REMOVE_API_KEY_FROM_SUBACCOUNT);

  const [deleteSubAccount, { error: errorDeletingSubAccount }] = useMutation<
    DeleteSubAccountResponse,
    DeleteSubAccountInput
  >(DELETE_SUB_ACCOUNT);

  type UpdateSubAccountOptions = {
    nullable: boolean;
    translator?: (value: string) => string | undefined;
  };

  const handleUpdateSubAccount = (
    setField: (draft: Draft<NextSubAccount>, value?: any) => void,
    options: UpdateSubAccountOptions = { nullable: false }
  ) => (event: React.ChangeEvent<any>) => {
    if (options.nullable || (!options.nullable && (event.target.value !== undefined || true))) {
      setSubAccount(
        produce<NextSubAccount>(subAccount!, (draft) => {
          if (options.translator) {
            const value = options.translator(event.target.value);
            setField(draft, value);
          } else {
            setField(draft, event.target.value);
          }
        })
      );
    }
  };

  const handleShowAddApiKey = () => {
    setSelectedApiKeyId(availableApiKeys[0].id);
    setShowAddApiKey(true);
  };

  const handleAddApiKeyClose = () => {
    setSelectedApiKeyId(undefined);
    setShowAddApiKey(false);
  };

  const handleAddApiKey = () => {
    const keyToAdd = availableApiKeys.find((key) => key.id === selectedApiKeyId);

    if (keyToAdd) setSubAccountApiKeys([...subAccountApiKeys, keyToAdd]);

    handleAddApiKeyClose();
  };

  const handleRemoveApiKey = (apiKeyId: string) => {
    setSubAccountApiKeys(subAccountApiKeys.filter((key) => key.id !== apiKeyId));
  };

  const handleDeleteSubAccount = async () => {
    setShowDeleteSubAccount(false);
    setIsUpdating(true);

    try {
      await deleteSubAccount({
        variables: {
          subAccountId: subAccount.id,
        },
      });

      onSubAccountUpdated();
    } catch (ex) {
      setShowAlert(true);
      console.error(ex);
    }
    setIsUpdating(false);
  };

  const onSaveSubAccount = async () => {
    setIsUpdating(true);

    let currentSubAccount: NextSubAccount | undefined;

    try {
      if (isNewSubAccount) {
        const result = await createSubAccount({
          variables: {
            subAccount: {
              ...(produce(subAccount as Partial<NextSubAccount>, (draft) => {
                delete draft.claimSystem;
                delete draft.drugSource;
                delete draft.apiKeys;
                delete draft.id;
              }) as NextSubAccount),
              accountId,
              claimSystemId: subAccount.claimSystem.id,
              drugSourceId: subAccount.drugSource.id,
            },
          },
        });
        currentSubAccount = result.data?.createSubAccount;
      } else {
        const result = await updateSubAccount({
          variables: {
            id: subAccount.id,
            subAccount: {
              ...(produce(subAccount as Partial<NextSubAccount>, (draft) => {
                delete draft.claimSystem;
                delete draft.drugSource;
                delete draft.apiKeys;
                delete draft.id;
              }) as NextSubAccount),
              claimSystemId: subAccount.claimSystem.id,
              drugSourceId: subAccount.drugSource.id,
            },
          },
        });

        currentSubAccount = result.data?.updateSubAccount;
      }

      if (currentSubAccount) {
        const apiKeysToAdd = subAccountApiKeys.filter(
          (apiKey) => !currentSubAccount?.apiKeys?.find((saApiKey) => saApiKey.id === apiKey.id)
        );
        const apiKeysToRemove =
          currentSubAccount.apiKeys?.filter(
            (saApiKey) => !subAccountApiKeys.find((apiKey) => saApiKey.id === apiKey.id)
          ) ?? [];

        for (const key of apiKeysToAdd) {
          await addApiKey({
            variables: {
              apiKeyId: key.id,
              subAccountId: currentSubAccount.id,
            },
          });
        }

        for (const key of apiKeysToRemove) {
          await removeApiKey({
            variables: {
              apiKeyId: key.id,
              subAccountId: currentSubAccount.id,
            },
          });
        }
      }

      onSubAccountUpdated();
    } catch (err) {
      setShowAlert(true);
      console.error(err);
    } finally {
      setIsUpdating(false);
    }
  };

  const SUBACCOUNT_OPTIONS_TABLE = subAccount
    ? [
        {
          label: "Name",
          options: (
            <TextField
              required
              disabled={isUpdating}
              value={subAccount.name}
              onChange={handleUpdateSubAccount((draft, value) => {
                draft.name = value;
              })}
              className={classes.textFieldOptions}
            >
              {subAccount.name}
            </TextField>
          ),
        },
        {
          label: "Is Parent",
          options: (
            <Switch
              disabled={isUpdating}
              checked={subAccount.isParent}
              onChange={(_, value) =>
                setSubAccount(
                  produce(subAccount, (draft) => {
                    draft.isParent = value;
                  })
                )
              }
            />
          ),
        },
        {
          label: "Medicare Enabled",
          options: (
            <Switch
              disabled={isUpdating}
              checked={subAccount.isMedicareEnabled}
              onChange={(_, value) =>
                setSubAccount(
                  produce(subAccount, (draft) => {
                    draft.isMedicareEnabled = value;
                  })
                )
              }
            />
          ),
        },
        {
          label: "Legacy Client Code",
          options: (
            <TextField
              disabled={isUpdating}
              value={subAccount.legacyClientCode}
              onChange={handleUpdateSubAccount(
                (draft, value) => {
                  draft.legacyClientCode = value;
                },
                { nullable: true }
              )}
              className={classes.textFieldOptions}
            >
              {subAccount.legacyClientCode}
            </TextField>
          ),
        },
        {
          label: "Drug Source",
          options: (
            <Select
              disabled={isUpdating}
              className={classes.textFieldOptions}
              value={subAccount.drugSource!.id}
              onChange={handleUpdateSubAccount((draft, value) => {
                draft!.drugSource!.id = value;
              })}
            >
              {drugSources.map((drugSource) => (
                <MenuItem
                  value={drugSource.id}
                >{`${drugSource.drugSource} (${drugSource.shortName})`}</MenuItem>
              ))}
            </Select>
          ),
        },
        {
          label: "Claim System",
          options: (
            <Select
              disabled={isUpdating}
              className={classes.textFieldOptions}
              value={subAccount.claimSystem!.id}
              onChange={handleUpdateSubAccount((draft, value) => {
                draft!.claimSystem!.id = value;
              })}
            >
              {claimSystems.map((claimSystem) => (
                <MenuItem value={claimSystem.id}>{claimSystem.sourceName}</MenuItem>
              ))}
            </Select>
          ),
        },
      ]
    : [];
  return (
    <>
      <Typography variant="h5" color="primary" className={classes.title}>
        {isNewSubAccount ? "New SubAccount" : subAccount.name}
      </Typography>

      <Divider variant="middle" />
      <OptionsTable settings={SUBACCOUNT_OPTIONS_TABLE} />
      <List
        dense
        subheader={
          <ListSubheader disableSticky={true}>
            API Keys
            <IconButton onClick={handleShowAddApiKey} disabled={availableApiKeys.length === 0}>
              <AddIcon />
            </IconButton>
          </ListSubheader>
        }
      >
        {subAccountApiKeys.map((apiKey) => (
          <ApiKeyListItem apiKey={apiKey} onDelete={handleRemoveApiKey} />
        ))}
      </List>
      <div className={classes.fabBar}>
        {!isNewSubAccount && (
          <Fab
            className={classes.deleteButton}
            disabled={isUpdating}
            color="secondary"
            size="small"
            onClick={() => setShowDeleteSubAccount(true)}
          >
            <DeleteIcon />
          </Fab>
        )}

        <Fab disabled={isUpdating} color="primary" onClick={onSaveSubAccount}>
          {isUpdating ? <CircularProgress size={24} /> : <SaveIcon />}
        </Fab>
      </div>

      <Dialog open={showAddApiKey} onClose={handleAddApiKeyClose}>
        <DialogTitle>Add API Key</DialogTitle>
        <DialogContent>
          <Select
            className={classes.dialogInputControl}
            value={selectedApiKeyId}
            onChange={(event) => setSelectedApiKeyId(event?.target.value as string | undefined)}
          >
            {availableApiKeys.map((apiKey) => (
              <MenuItem value={apiKey.id}>
                <ListItemText primary={apiKey.name} secondary={apiKey.key} />
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddApiKeyClose}>Cancel</Button>
          <Button onClick={handleAddApiKey} disabled={!Boolean(selectedApiKeyId)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showDeleteSubAccount} onClose={() => setShowDeleteSubAccount(false)}>
        <DialogTitle>Delete SubAccount</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete "{subAccount.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteSubAccount}>Yes</Button>
          <Button onClick={() => setShowDeleteSubAccount(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <AlertDialog
        isError
        isOpen={showAlert}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setShowAlert(false);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          {`There was an error while ${isNewSubAccount ? "creating" : "updating"} the subaccount.`}
        </Typography>
        <Button
          color="primary"
          style={{ width: "fit-content" }}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <ErrorDetailsTable
            errors={[
              errorCreating,
              errorUpdating,
              errorAddingApiKey,
              errorRemovingApiKey,
              errorDeletingSubAccount,
            ]}
          />
        </Collapse>
      </AlertDialog>
    </>
  );
};

interface ApiKeyListItemProps {
  apiKey: ApiKey;
  onDelete: (apiKeyId: string) => void;
}

const ApiKeyListItem: React.FC<ApiKeyListItemProps> = (props) => {
  const { apiKey, onDelete } = props;

  const [showCopyTooltip, setShowCopyTooltip] = React.useState<boolean>(false);

  const handleCopyApiKey = (apiKey: string) => {
    setShowCopyTooltip(true);
    navigator.clipboard.writeText(apiKey);
  };

  return (
    <ListItem key={apiKey.id}>
      <ListItemText primary={apiKey.name} secondary={apiKey.key} />
      <ListItemSecondaryAction>
        <Tooltip title="Copied!" open={showCopyTooltip} onClose={() => setShowCopyTooltip(false)}>
          <IconButton edge="end" aria-label="copy" onClick={() => handleCopyApiKey(apiKey.key)}>
            <CopyIcon />
          </IconButton>
        </Tooltip>
        <IconButton edge="end" aria-label="delete" onClick={() => onDelete(apiKey.id)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
