import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { Route, Switch } from "react-router";
import { NotFound } from "../components/screens/404";
import { getAppTheme } from "../styles/Theme";
import { AppSections } from "../types/AppTypes";
import { ListFormulariesPage } from "./Formularies/ListFormulariesPage";
import { FormularyPage } from "./Formularies/FormularyPage/FormularyPage";
import { UserContext } from "../components/UserContext";

export const FormularyRoutes: React.FC = () => {
  const {
    user: { settings },
  } = React.useContext(UserContext);

  return (
    <ThemeProvider theme={getAppTheme(AppSections.FORMULARIES, settings.textSize)}>
      <Switch>
        <Route path="/formularies" exact={true}>
          <ListFormulariesPage />
        </Route>

        <Route path="/formularies/new" exact={true}>
          <FormularyPage kind="new" />
        </Route>

        <Route path="/formularies/edit/:formularyId" exact={true}>
          <FormularyPage kind="edit" />
        </Route>

        <Route path="/formularies/view/:formularyId" exact={true}>
          <FormularyPage kind="view" />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};
