import { TemplateStatus } from "../types";

export enum COLUMN_NAMES {
  DESCRIPTION = "Description",
  TIER = "Tier",
  EDITS = "Edits",
}

export interface DescriptionColumnSettings {
  position: number;
  title: string;
  width: number;
}

export interface EditsColumnSettings {
  position: number;
  hidden: boolean;
  title: string;
  showDetails: boolean;
  width: number;
}

export interface TierColumnSettings {
  position: number;
  hidden: boolean;
  title: string;
  rankPrefix: string | null;
  showRank: boolean;
  showDescription: boolean;
  width: number;
}

export type ColumnSettingsState = {
  descriptionColumnSettings: DescriptionColumnSettings;
  tierColumnSettings: TierColumnSettings;
  editsColumnSettings: EditsColumnSettings;
};

export enum MarginUnits {
  IN = "IN",
  CM = "CM",
  PT = "PT",
}

export interface MarginSettings {
  left: number;
  right: number;
  top: number;
  bottom: number;
  units: MarginUnits;
}

export enum TemplateFonts {
  ARIAL = "ARIAL",
  CALIBRI = "CALIBRI",
  CENTURY_GOTHIC = "CENTURY_GOTHIC",
  COURIER = "COURIER",
  HELVETICA = "HELVETICA",
  TIMES_NEW_ROMAN = "TIMES_NEW_ROMAN",
}

export enum TABLE_COLORS {
  BLACK = "BLACK",
  GREY = "GREY",
  WHITE = "WHITE",
}

export enum HORIZONTAL_ALIGNMENT {
  LEFT = "LEFT",
  CENTER = "CENTER",
  RIGHT = "RIGHT",
}

export type FullFormularyTemplate = {
  id: string;
  name: string;
  settings: FormularyTemplateSettings;
};

export type APIFullFormularyTemplate = {
  id: string;
  name: string;
  settings: APIFormularyTemplateSettings;
};

/**
 * todo - update type to reflect nullability where viable
 */
type BaseFormularyTemplateSettings = {
  descriptionColumnSettings: DescriptionColumnSettings;
  editsColumnSettings: EditsColumnSettings;
  font: TemplateFonts;
  indexFontSize: number;
  indexNumberOfColumns: number;
  indexTitle: string;
  indexTitleTextAlignment: HORIZONTAL_ALIGNMENT;
  legendShowLimitations: boolean;
  legendShowTiers: boolean;
  marginSettings: MarginSettings;
  showIndex: boolean;
  tableCellsFontSize: number;
  tableGroupDrugsByName: boolean;
  tableHeadingBackgroundColor: TABLE_COLORS;
  tableHeadingFontSize: number;
  tableShowColumnDividers: boolean;
  tableShowGroupDosage: ShowDosage;
  tableShowRowDividers: boolean;
  tierColumnSettings: TierColumnSettings;
};

export type FormularyTemplateSettings = BaseFormularyTemplateSettings & {
  indexFooterBase64: string;
  indexHeaderBase64: string;
  legendFooterBase64: string;
  legendHeaderBase64: string;
  tableFooterBase64: string;
  tableHeaderBase64: string;
};

export type APIFormularyTemplateSettings = BaseFormularyTemplateSettings & {
  indexFooterBase64: string | null;
  indexHeaderBase64: string | null;
  legendFooterBase64: string | null;
  legendHeaderBase64: string | null;
  tableFooterBase64: string | null;
  tableHeaderBase64: string | null;
};

const encodeBase64Property = (prop: string) => {
  return prop ? btoa(prop) : null;
};

/**
 * Make sure to base 64 encode the header and footers to persist images.
 * GQL nullable fields must not be empty strings
 */
export const toApiFormularyTemplateSettings = (
  settings: FormularyTemplateSettings
): APIFormularyTemplateSettings => {
  return {
    ...settings,
    indexFooterBase64: encodeBase64Property(settings.indexFooterBase64),
    indexHeaderBase64: encodeBase64Property(settings.indexHeaderBase64),
    legendFooterBase64: encodeBase64Property(settings.legendFooterBase64),
    legendHeaderBase64: encodeBase64Property(settings.legendHeaderBase64),
    tableFooterBase64: encodeBase64Property(settings.tableFooterBase64),
    tableHeaderBase64: encodeBase64Property(settings.tableHeaderBase64),
    tierColumnSettings: {
      ...settings.tierColumnSettings,
      rankPrefix: settings.tierColumnSettings.rankPrefix || null,
    },
  };
};

const decodeBase64Property = (prop: string | null): string => {
  return prop ? atob(prop) : "";
};

export const fromApiFormularyTemplateSettings = (
  settings: APIFormularyTemplateSettings
): FormularyTemplateSettings => {
  return {
    ...settings,
    indexFooterBase64: decodeBase64Property(settings.indexFooterBase64),
    indexHeaderBase64: decodeBase64Property(settings.indexHeaderBase64),
    legendFooterBase64: decodeBase64Property(settings.legendFooterBase64),
    legendHeaderBase64: decodeBase64Property(settings.legendHeaderBase64),
    tableFooterBase64: decodeBase64Property(settings.tableFooterBase64),
    tableHeaderBase64: decodeBase64Property(settings.tableHeaderBase64),
  };
};

export enum ShowDosage {
  ON_ALL_DRUGS = "ON_ALL_DRUGS",
  WHEN_DRUG_IS_SPLIT_BETWEEN_ROWS = "WHEN_DRUG_IS_SPLIT_BETWEEN_ROWS",
}

export type FormularyTemplateStatus = TemplateStatus<FullFormularyTemplate>;
