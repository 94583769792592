import {
  FullFormularyTemplate,
  HORIZONTAL_ALIGNMENT,
  MarginUnits,
  ShowDosage,
  TABLE_COLORS,
  TemplateFonts,
} from "./types";

export const FORMULARY_TEMPLATE_LIST_PAGE = "/templates/formulary";

export const DEFAULT_STARTING_TEMPLATE: FullFormularyTemplate = {
  id: "",
  name: "New Full Formulary Template",
  settings: {
    descriptionColumnSettings: { position: 0, title: "Description", width: 50 },
    editsColumnSettings: {
      hidden: false,
      position: 2,
      title: "Edits",
      showDetails: false,
      width: 25,
    },
    font: TemplateFonts.TIMES_NEW_ROMAN,
    indexFontSize: 10,
    indexFooterBase64: "",
    indexHeaderBase64: "",
    indexNumberOfColumns: 3,
    indexTitle: "Index",
    indexTitleTextAlignment: HORIZONTAL_ALIGNMENT.CENTER,
    legendFooterBase64: "",
    legendHeaderBase64: "",
    legendShowLimitations: true,
    legendShowTiers: true,
    marginSettings: { top: 1, bottom: 1, left: 1, right: 1, units: MarginUnits.IN },
    showIndex: true,
    tableCellsFontSize: 10,
    tableFooterBase64: "",
    tableGroupDrugsByName: true,
    tableHeaderBase64: "",
    tableHeadingBackgroundColor: TABLE_COLORS.BLACK,
    tableHeadingFontSize: 12,
    tableShowColumnDividers: true,
    tableShowGroupDosage: ShowDosage.ON_ALL_DRUGS,
    tableShowRowDividers: true,
    tierColumnSettings: {
      hidden: false,
      position: 1,
      title: "Tiers",
      rankPrefix: "",
      showDescription: true,
      showRank: true,
      width: 25,
    },
  },
};
