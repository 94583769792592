import * as React from "react";
import { SortableElement } from "react-sortable-hoc";
import { SortableItem } from "./HorizontalListTypes";

interface Props {
  item: SortableItem;
}

const SortableItemCore: React.FC<Props> = (props) => <>{props.item.listItem}</>;

export const HorizontalSortableItem = SortableElement(SortableItemCore);
