export const TEMPLATE_NAME = "name";

export const MARGIN_SETTINGS = "settings.marginSettings";

export const PAGE_SETTINGS = {
  FONT: "settings.font",
  TOP_MARGIN: "settings.marginSettings.top",
  BOTTOM_MARGIN: "settings.marginSettings.bottom",
  LEFT_MARGIN: "settings.marginSettings.left",
  RIGHT_MARGIN: "settings.marginSettings.right",
  UNITS: "settings.marginSettings.units",
};

export const LEGEND_SETTINGS = {
  FOOTER: "settings.legendFooterBase64",
  HEADER: "settings.legendHeaderBase64",
  EDITS: "settings.legendShowLimitations",
  TIERS: "settings.legendShowTiers",
};

export const TABLE_SETTINGS = {
  CELLS_FONT_SIZE: "settings.tableCellsFontSize",
  FOOTER: "settings.tableFooterBase64",
  GROUP_DRUGS_BY_NAME: "settings.tableGroupDrugsByName",
  HEADER: "settings.tableHeaderBase64",
  HEADING_BACKGROUND_COLOR: "settings.tableHeadingBackgroundColor",
  HEADING_FONT_SIZE: "settings.tableHeadingFontSize",
  SHOW_COLUMN_DIVIDERS: "settings.tableShowColumnDividers",
  SHOW_GROUP_DOSAGES: "settings.tableShowGroupDosage",
  SHOW_ROW_DIVIDERS: "settings.tableShowRowDividers",
};

export const DESCRIPTION_COLUMN = {
  POSITION: "settings.descriptionColumnSettings.position",
  TITLE: "settings.descriptionColumnSettings.title",
  WIDTH: "settings.descriptionColumnSettings.width",
};

export const EDITS_COLUMN = {
  POSITION: "settings.editsColumnSettings.position",
  HIDDEN: "settings.editsColumnSettings.hidden",
  TITLE: "settings.editsColumnSettings.title",
  SHOW_DETAILS: "settings.editsColumnSettings.showDetails",
  WIDTH: "settings.editsColumnSettings.width",
};

export const TIERS_COLUMN = {
  POSITION: "settings.tierColumnSettings.position",
  HIDDEN: "settings.tierColumnSettings.hidden",
  TITLE: "settings.tierColumnSettings.title",
  RANK_PREFIX: "settings.tierColumnSettings.rankPrefix",
  SHOW_RANK: "settings.tierColumnSettings.showRank",
  SHOW_DESCRIPTION: "settings.tierColumnSettings.showDescription",
  WIDTH: "settings.tierColumnSettings.width",
};

export const INDEX_SETTINGS = {
  FONT_SIZE: "settings.indexFontSize",
  FOOTER_BASE_64: "settings.indexFooterBase64",
  HEADER_BASE_64: "settings.indexHeaderBase64",
  NUMBER_OF_COLUMNS: "settings.indexNumberOfColumns",
  TITLE: "settings.indexTitle",
  TITLE_ALIGNMENT: "settings.indexTitleTextAlignment",
  SHOW_INDEX: "settings.showIndex",
};
