import { ApolloError, useMutation, useSubscription } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format } from "date-fns";
import * as React from "react";
import { AlertDialog } from "../../../../../components/dialogs/AlertDialog";
import {
  GenerateFormularySnapshotInput,
  GenerateFormularySnapshotResponse,
  GENERATE_FORMULARY_SNAPSHOT,
  SubscribeToSnapshotStatusInput,
  SubscribeToSnapshotStatusResponse,
  SUBSCRIBE_TO_SNAPSHOT_STATUS,
} from "../../api";
import { HPMSFileInfo, SnapshotStatus } from "../../types";
import { FilesSidebar } from "./FilesSidebar";

const useStyles = makeStyles({
  stepWrapper: {
    display: "flex",
    height: "calc(100% - 40px)",
  },
  fourthStep: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "75%",
  },
  buttonLoadingWrapper: {
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
  instructions: {
    position: "absolute",
    top: 0,
    textAlign: "center",
  },
});

type Props = {
  formularyFiles: Array<HPMSFileInfo>;
  haveFilesPassedValidation: boolean;
  uploadLocationPrefix: string;
  formularyId: string;
  onSnapshotComplete: () => void;
};

export const FourthStep: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [configDate, setConfigDate] = React.useState<Date | null>(null);
  const [hadErrorBuildingSnapshot, setHadErrorBuildingSnapshot] = React.useState(false);
  const [snapshotId, setSnapshotId] = React.useState<string | null>(null);
  const [snapshotStatus, setSnapshotStatus] = React.useState<SnapshotStatus | null>(null);

  const [
    generateFormularySnapshot,
    { loading: isSaving, error: errorGeneratingSnapshot },
  ] = useMutation<GenerateFormularySnapshotResponse, GenerateFormularySnapshotInput>(
    GENERATE_FORMULARY_SNAPSHOT,
    {
      onCompleted: (res) => {
        if (res && snapshotId === null) {
          console.info(`Result Structure: ${JSON.stringify(res, null, 2)}`);
          const { status, snapshotUuid } = res.generateFormularySnapshotAsync;
          if (status === SnapshotStatus.ERRORED) {
            setSnapshotId(null); // Currently no way to restart failed snapshot, so a new one will have to be created
            setSnapshotStatus(SnapshotStatus.ERRORED);
            setHadErrorBuildingSnapshot(true);
          } else {
            setSnapshotId(snapshotUuid);
            setSnapshotStatus(status);
          }
        }
      },
    }
  );

  useSubscription<SubscribeToSnapshotStatusResponse, SubscribeToSnapshotStatusInput>(
    SUBSCRIBE_TO_SNAPSHOT_STATUS,
    {
      skip: !snapshotId,
      variables: { snapshotId: snapshotId! },
      onSubscriptionData: (res) => {
        console.info(`Snapshot Status: ${JSON.stringify(res.subscriptionData.data, null, 2)}`);
        setSnapshotStatus(res.subscriptionData.data?.formularyStatusUpdates.status ?? null);
      },
    }
  );

  if (snapshotStatus === SnapshotStatus.ERRORED) {
    setSnapshotId(null); // Currently no way to restart failed snapshot, so a new one will have to be created
    setHadErrorBuildingSnapshot(true);
  } else if (snapshotStatus === SnapshotStatus.COMPLETED) {
    props.onSnapshotComplete();
  }

  const addFilesToFormulary = () => {
    if (props.haveFilesPassedValidation && configDate) {
      generateFormularySnapshot({
        variables: {
          asOfDate: format(configDate, "yyyy-MM-dd"),
          formularyId: props.formularyId,
          locationPrefix: props.uploadLocationPrefix,
        },
      }).catch((e) => {
        console.error("Error creating formulary snapshot:", e);
      });
    }
  };

  const handleConfigDateChange = (date: Date | null) => {
    setConfigDate(date);
  };

  const renderErrors = (error: ApolloError) => {
    return (
      <>
        <ErrorIcon color="error" fontSize="large" />
        <Typography align="center">
          Sorry, something went wrong while saving your files. Please wait a moment and try again.
          If the problem persists, contact support.
        </Typography>
        <Button
          color="primary"
          onClick={() => {
            setShowTechnicalErrors(true);
          }}
        >
          See Details
        </Button>
        <AlertDialog
          isOpen={showTechnicalErrors}
          onExitHandler={() => {
            setShowTechnicalErrors(false);
          }}
          dialogTitle="Validation Problems"
          isError
        >
          <Table>
            <TableBody>
              {error.graphQLErrors.map((error, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography color="error" variant="body2">
                      {error.message}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AlertDialog>
      </>
    );
  };

  return (
    <div className={classes.stepWrapper}>
      <FilesSidebar formularyFiles={props.formularyFiles} />
      <div className={classes.fourthStep}>
        {!!errorGeneratingSnapshot?.graphQLErrors ? (
          renderErrors(errorGeneratingSnapshot)
        ) : hadErrorBuildingSnapshot ? (
          <>
            <ErrorIcon color="error" fontSize="large" />
            <Typography align="center">
              Sorry, something went wrong while saving your files. Please wait a moment and try
              again. If the problem persists, contact support.
            </Typography>
          </>
        ) : (
          <>
            <div className={classes.instructions}>
              {snapshotStatus === SnapshotStatus.COMPLETED ? (
                <>
                  <Typography align="center" style={{ marginBottom: 16 }}>
                    The formulary configuration process is now complete!
                  </Typography>
                  <Typography align="center">
                    Go to Publishing to start creating your documents.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography align="center" style={{ marginBottom: 16 }}>
                    Review the files you've added to the left and add a date for when these files
                    are effective.
                  </Typography>
                  <Typography align="center" style={{ marginBottom: 16 }}>
                    If satisfied, click the finish formulary button to complete the process.
                  </Typography>
                </>
              )}
            </div>
            <>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  InputAdornmentProps={{ position: "end" }}
                  format="MMM do, yyyy"
                  label="Data effective date"
                  value={configDate}
                  onChange={handleConfigDateChange}
                  InputProps={{ readOnly: true }}
                  style={{ marginBottom: 16 }}
                />
              </MuiPickersUtilsProvider>
              <div className={classes.buttonLoadingWrapper}>
                <Button
                  onClick={addFilesToFormulary}
                  color="primary"
                  variant="contained"
                  disabled={isSaving || configDate === null || !!snapshotStatus}
                >
                  {isSaving || !snapshotStatus
                    ? "Finish formulary"
                    : snapshotStatus === SnapshotStatus.COMPLETED
                    ? snapshotStatus
                    : `${snapshotStatus}...`}
                </Button>
                {isSaving ||
                  (!!snapshotStatus && snapshotStatus !== SnapshotStatus.COMPLETED && (
                    <CircularProgress style={{ position: "absolute", right: -50 }} />
                  ))}
              </div>
            </>
          </>
        )}
      </div>
    </div>
  );
};
