import { useLazyQuery } from "@apollo/client";
import {
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterListRounded";
import SearchIcon from "@material-ui/icons/SearchRounded";
import * as React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import shallow from "zustand/shallow";
import { CenteredCircularLoading } from "../../../../components/loading/CenteredCircularLoading";
import { UserContext } from "../../../../components/UserContext";
import { apiFormatDate } from "../../../../utils/Date";
import { DrugSourceShort } from "../../../../utils/User";
import {
  DrugAttribute,
  GetGSNDrugsInput,
  GetGSNDrugsResponse,
  GET_GSN_DRUG_FOR_LIST,
  GSNDrug,
  PrioritizedRule,
} from "../../api";
import { querySearchConfig, State, usePrioritizedDrugsStore } from "../AlternativesPriorityStore";

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: 350,
    maxWidth: 500,
  },
  listSubheader: {
    backgroundColor: "white",
    color: theme.palette.text.primary,
  },
  switchFormControl: {
    minWidth: "50%",
    marginTop: theme.spacing(2),
  },
  searchRoot: {
    padding: "2px 4px 2px 16px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "94%",
  },
  searchInput: { width: "70%" },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  infiniteScrollRoot: {
    height: "100%",
    overflow: "auto",
  },
}));

/**
 * TODOs
 *
 *  other
 *    maybe use DRUG_ATTRIBUTES in place of what is currently used
 */

type Props = {
  listId: string;
  open: boolean;
  prioritizedBaskets: Array<PrioritizedRule>;
  onClose: () => void;
  onDrugClick: (drug: GSNDrug) => void;
};

const selectState = (state: State) => ({
  prioritySidebarMode: state.prioritySidebarMode,
  currentCursor: state.currentCursor,
  loadDrugs: state.loadDrugs,
  showSearchedDrugs: state.showSearchedDrugs,
  showFilteredDrugs: state.showFilteredDrugs,
});

type Filters = DrugAttribute.labelName | DrugAttribute.gpi14 | DrugAttribute.gcnSequenceNumber;

export const DrugDrawerPicker: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: {
      drugSourceShort,
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const {
    currentCursor,
    prioritySidebarMode,
    loadDrugs,
    showSearchedDrugs,
    showFilteredDrugs,
  } = usePrioritizedDrugsStore(selectState, shallow);
  const [searchText, setSearchText] = React.useState("");
  const [searchFilter, setSearchFilter] = React.useState<Filters>(DrugAttribute.labelName);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [getDrugs, { loading: isLoadingSearchResults }] = useLazyQuery<
    GetGSNDrugsResponse,
    GetGSNDrugsInput
  >(GET_GSN_DRUG_FOR_LIST, {
    onCompleted: (res) => {
      showSearchedDrugs(drugSourceShort, props.prioritizedBaskets, res.list.drugs.items);
    },
  });

  const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    getDrugs({
      variables: {
        id: props.listId,
        drugsInput: {
          analyticsMode: true,
          asOfDate: apiFormatDate(new Date()),
          ...querySearchConfig(drugSourceShort, { searchBy: searchFilter, searchText }),
        },
      },
    });
  };

  const handleSearchOnKeyboardInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchText.length >= 3) {
      handleSearch();
    }
  };

  const handleFilterClick = (filterType: Filters) => {
    setSearchFilter(filterType);
    setAnchorEl(null);
  };

  const handleSelectSearchResultDrug = (drug: GSNDrug) => {
    setSearchText("");
    showFilteredDrugs(drugSourceShort, props.prioritizedBaskets);
    props.onDrugClick(drug);
  };

  const loadMoreItems = () => {
    return loadDrugs(drugSourceShort, props.listId, props.prioritizedBaskets, currentCursor);
  };

  function renderResults() {
    if (isLoadingSearchResults) {
      return <CenteredCircularLoading />;
    } else {
      switch (prioritySidebarMode.mode) {
        case "filtered results":
          return (
            <InfiniteFilteredDrugsList
              cursor={currentCursor}
              drugSourceShort={drugSourceShort}
              drugs={prioritySidebarMode.filteredDrugs}
              loadMoreItems={loadMoreItems}
              onSelectDrug={props.onDrugClick}
            />
          );
        case "search results":
          return (
            <SearchResultsList
              drugSourceShort={drugSourceShort}
              drugs={prioritySidebarMode.searchedDrugs}
              onSelectDrug={handleSelectSearchResultDrug}
            />
          );
      }
    }
  }

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ className: classes.drawer }}
    >
      <ListSubheader className={classes.listSubheader} disableSticky={true}>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <List dense={contentSpacing === "dense"}>
            <ListSubheader>Search by...</ListSubheader>
            <ListItem
              button
              onClick={(_) => {
                handleFilterClick(DrugAttribute.labelName);
              }}
              selected={searchFilter === DrugAttribute.labelName}
            >
              Label Name
            </ListItem>
            {drugSourceShort === "MSPAN" && (
              <ListItem
                button
                onClick={(_) => {
                  handleFilterClick(DrugAttribute.gpi14);
                }}
                selected={searchFilter === DrugAttribute.gpi14}
              >
                GPI 14
              </ListItem>
            )}
            {drugSourceShort === "FDB" && (
              <ListItem
                button
                onClick={(_) => {
                  handleFilterClick(DrugAttribute.gcnSequenceNumber);
                }}
                selected={searchFilter === DrugAttribute.gcnSequenceNumber}
              >
                GCN Sequence Number
              </ListItem>
            )}
          </List>
        </Popover>

        <Paper className={classes.searchRoot}>
          <InputBase
            className={classes.searchInput}
            disabled={isLoadingSearchResults}
            value={searchText}
            placeholder="Search"
            inputProps={{ "aria-label": "Search Drugs" }}
            onChange={handleChangeSearchText}
            onKeyDown={handleSearchOnKeyboardInput}
          />
          <IconButton
            className={classes.iconButton}
            disabled={isLoadingSearchResults || searchText.length < 2}
            aria-label="search"
            onClick={handleSearch}
          >
            <SearchIcon />
          </IconButton>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            className={classes.iconButton}
            aria-label="clear search"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <FilterIcon />
          </IconButton>
        </Paper>
        <Divider />
      </ListSubheader>
      <div className={classes.infiniteScrollRoot}>{renderResults()}</div>
    </Drawer>
  );
};

const useCardStyles = makeStyles({
  generic: {
    fontStyle: "italic",
    textTransform: "lowercase",
  },
  brand: {
    textTransform: "uppercase",
  },
});

type FilteredDrugsListProps = {
  drugs: Array<GSNDrug>;
  cursor: string | null;
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<any> | null;
  drugSourceShort: DrugSourceShort;
  onSelectDrug: (drug: GSNDrug) => void;
};

function InfiniteFilteredDrugsList(props: FilteredDrugsListProps) {
  const cardClasses = useCardStyles();
  const listRef = React.useRef<null | any>(null);

  const hasNextPage = props.cursor !== "*";

  function isItemLoaded(index: number) {
    return !hasNextPage || index < props.drugs.length;
  }

  function itemCount() {
    return hasNextPage ? props.drugs.length + 1 : props.drugs.length;
  }

  function handleSelectDrug(drug: GSNDrug) {
    return function () {
      props.onSelectDrug(drug);
    };
  }

  function renderRow({ index, style }: any /* RenderRowProps */): JSX.Element | null {
    if (props.drugs[index] && isItemLoaded(index)) {
      return (
        <ListItem button onClick={handleSelectDrug(props.drugs[index])} style={style} key={index}>
          <ListItemText
            primary={props.drugs[index].labelName}
            primaryTypographyProps={{
              className:
                props.drugs[index].type === "BRAND" ? cardClasses.brand : cardClasses.generic,
            }}
            secondary={
              props.drugSourceShort === "FDB"
                ? props.drugs[index].fdbDetails.gcnSequenceNumber
                : props.drugs[index].medispanDetails.gpi14
            }
          />
        </ListItem>
      );
    } else if (hasNextPage) {
      return (
        <ListItem style={style} key={index}>
          <ListItemText
            primary="Loading..."
            primaryTypographyProps={{ color: "textSecondary", style: { fontStyle: "italic" } }}
          />
          <ListItemIcon>
            <CircularProgress />
          </ListItemIcon>
        </ListItem>
      );
    } else {
      return null;
    }
  }

  return (
    <InfiniteLoader
      ref={listRef}
      isItemLoaded={isItemLoaded}
      itemCount={itemCount()}
      loadMoreItems={props.loadMoreItems}
    >
      {({ ref, onItemsRendered }) => (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              ref={ref}
              height={height}
              width={width}
              itemSize={80}
              itemCount={itemCount()}
              onItemsRendered={onItemsRendered}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
}

type SearchResultsListProps = {
  drugs: Array<GSNDrug>;
  drugSourceShort: DrugSourceShort;
  onSelectDrug: (drug: GSNDrug) => void;
};

const SearchResultsList: React.FC<SearchResultsListProps> = (props) => {
  const cardClasses = useCardStyles();

  function handleSelectDrug(drug: GSNDrug) {
    return function () {
      props.onSelectDrug(drug);
    };
  }

  function renderSearchedResultRow({ index, style }: any /* ListChildComponentProps */) {
    return props.drugs[index] ? (
      <ListItem button onClick={handleSelectDrug(props.drugs[index])} style={style} key={index}>
        <ListItemText
          primary={props.drugs[index].labelName}
          primaryTypographyProps={{
            className:
              props.drugs[index].type === "BRAND" ? cardClasses.brand : cardClasses.generic,
          }}
          secondary={
            props.drugSourceShort === "FDB"
              ? props.drugs[index].fdbDetails.gcnSequenceNumber
              : props.drugs[index].medispanDetails.gpi14
          }
        />
      </ListItem>
    ) : null;
  }

  return props.drugs.length === 0 ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>No results</Typography>
    </div>
  ) : (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList height={height} width={width} itemSize={80} itemCount={props.drugs.length}>
          {renderSearchedResultRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};
