import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  smallMargins: {
    margin: theme.spacing(),
  },
}));

type Props = {
  max: number;
  contentLength: number;
};

export const CharacterCounter: React.FC<Props> = (props) => {
  const { contentLength, max } = props;
  const classes = useStyles();

  if (contentLength > max) {
    return (
      <Typography color="error" variant="body2" className={classes.smallMargins}>{`${
        contentLength - max
      } characters over limit`}</Typography>
    );
  } else {
    return (
      <Typography color="textPrimary" variant="body2" className={classes.smallMargins}>{`${
        max - contentLength
      } characters left`}</Typography>
    );
  }
};
