import { makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: 55,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    "&& p": {
      padding: theme.spacing(),
      margin: theme.spacing(),
    },
  },
}));

interface Props {
  children: React.ReactNode;
}

export function TableControlBar(props: Props): JSX.Element {
  const classes = useStyles();

  return <div className={classes.root}>{props.children}</div>;
}
