import { Button, Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  formRoot: { display: "flex", flexDirection: "column" },
  header: { textAlign: "center" },
  welcomeStatement: { alignSelf: "center", textAlign: "center" },
  primaryInlineText: {
    color: theme.palette.primary.main,
  },
  buttonRow: {
    position: "relative",
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  sectionSpacing: {
    marginTop: "1rem",
  },
}));

type Props = {
  email: string;
  customerName: string;
  title: string;
  agreementVersion: string;
  agreementUrl: string;
  onAcceptUserAgreement: () => Promise<void>;
  onDeclineUserAgreement: () => void;
};

export const UserAgreementForm: React.FC<Props> = (props) => {
  const [hasOpenedAgreement, setHasOpenedAgreement] = React.useState(false);
  const [hasReadAgreement, setHasReadAgreement] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const handleAcceptUserAgreement = async () => {
    try {
      setLoading(true);
      await props.onAcceptUserAgreement();
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  return loading ? (
    <CenteredCircularLoading />
  ) : (
    <form noValidate className={classes.formRoot}>
      <Typography variant="h4" className={classes.header}>
        License Agreement
      </Typography>
      <Typography
        variant="h5"
        className={classnames(classes.sectionSpacing, classes.welcomeStatement)}
      >
        Hello <span className={classes.primaryInlineText}>{` ${props.email}`}</span>,{" "}
        <span className={classes.primaryInlineText}>{props.title}</span> of{" "}
        <span className={classes.primaryInlineText}>{props.customerName}</span>
      </Typography>
      <FormControlLabel
        label={
          <Typography>
            I read and accept the{" "}
            <a
              href={props.agreementUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setHasOpenedAgreement(true);
              }}
            >
              System Access License Agreement
            </a>{" "}
            and confirm that I am authorized to accept such agreement on behalf of the organization
            named above.
          </Typography>
        }
        control={
          <Checkbox
            disabled={hasOpenedAgreement === false}
            value={hasReadAgreement}
            onChange={() => {
              setHasReadAgreement(!hasReadAgreement);
            }}
          />
        }
        className={classes.sectionSpacing}
        style={{ marginTop: "2rem" }}
      />
      <div className={classes.buttonRow}>
        <Button variant="contained" onClick={props.onDeclineUserAgreement}>
          Decline
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={hasReadAgreement === false}
          style={{ marginLeft: "1em" }}
          onClick={handleAcceptUserAgreement}
        >
          Agree
        </Button>
      </div>
    </form>
  );
};
