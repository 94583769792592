import { MarginUnits, TemplateFonts } from "../Formulary/types";
import { CriteriaTemplate } from "./types";

export const DEFAULT_SETTINGS: CriteriaTemplate = {
  id: "",
  name: "New Criteria Template",
  settings: {
    font: TemplateFonts.HELVETICA,
    criteriaTitleSize: 22,
    elementNameSize: 18,
    elementInfoSize: 12,
  },
};

export const ManagementPage = "/templates/criteria";

export const DefaultMarginSettings = {
  bottom: 1.0,
  left: 1.0,
  right: 1.0,
  top: 1.0,
  units: MarginUnits.IN,
};
