import * as React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  makeStyles,
  Theme,
} from "@material-ui/core";
import ExpandedIcon from "@material-ui/icons/ExpandLessRounded";
import CollapsedIcon from "@material-ui/icons/ChevronRightRounded";
import { FormularyErrorIndicator } from "./FormularyErrorIndicator";

const useStyles = makeStyles((theme: Theme) => ({
  expandedSelectionPanel: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "inherit",
    borderTopRightRadius: "inherit",
    color: theme.palette.common.white,
  },
  expansionPanelDetails: {
    padding: "10px 20px 20px 20px",
  },
  expensionPanelSummaryHeading: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
  },
}));

type Props = {
  title: string;
  isExpanded: boolean;
  onExpand: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  isContainsError?: boolean;
};

export const EditTemplateFormExpansionPanel: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <ExpansionPanel
      expanded={props.isExpanded}
      onChange={props.onExpand}
      data-testid={`${props.title}-expansion-panel`}
    >
      <ExpansionPanelSummary
        className={props.isExpanded ? classes.expandedSelectionPanel : ""}
        IconButtonProps={{ color: "inherit" }}
        expandIcon={props.isExpanded ? <ExpandedIcon /> : <CollapsedIcon />}
      >
        <div className={classes.expensionPanelSummaryHeading}>
          <Typography
            variant="h6"
            color="inherit"
            style={{ fontWeight: props.isExpanded ? 500 : 250 }}
          >
            {props.title}
          </Typography>
          <FormularyErrorIndicator
            isError={Boolean(props.isContainsError)}
            styleObject={{ color: props.isExpanded ? "white" : "red" }}
          />
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
