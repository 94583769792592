import * as React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import { asyncLocalStorage, CONTENT_SPACING } from "../../utils/localStorageUtils";
import ApplyIcon from "@material-ui/icons/CheckCircleRounded";
import { UserContext } from "../../components/UserContext";

const useStyles = makeStyles((theme) => ({
  twoColumnGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridAutoFlow: "column",
    justifyItems: "center",
    alignItems: "center",
  },
  tableExample: {
    display: "flex",
    alignItems: "center",
    width: 180,
    border: "2px solid transparent",
    transition: theme.transitions.create("border", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  },
  tableExampleSelected: {
    display: "flex",
    alignItems: "center",
    width: 180,
    border: `2px solid ${theme.palette.primary.main}`,
    transition: theme.transitions.create("border", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  },
  acceptButton: {
    position: "absolute",
    bottom: -50,
    width: 110,
    left: "calc(50% - 55px)",
  },
}));

export function ContentSpacing() {
  const classes = useStyles();
  const [isSaving, setIsSaving] = React.useState(false);
  const [contentSpacing, setContentSpacing] = React.useState({
    setting: "normal",
    previousSetting: "normal",
  });
  const {
    updateUserSettings,
    user: { settings },
  } = React.useContext(UserContext);

  React.useEffect(() => {
    setIsSaving(true);
    asyncLocalStorage.getItem(CONTENT_SPACING).then((res) => {
      setIsSaving(false);
      if (res) {
        setContentSpacing({ setting: res, previousSetting: res });
      }
    });
  }, []);

  function handleSpacingChange(spacing: "normal" | "dense") {
    setContentSpacing({ ...contentSpacing, setting: spacing });
  }

  function saveChanges() {
    setIsSaving(true);
    asyncLocalStorage.setItem(CONTENT_SPACING, contentSpacing.setting).then(() => {
      updateUserSettings({ ...settings, contentSpacing: contentSpacing.setting });
    });
  }

  return (
    <>
      {isSaving ? <LinearProgress /> : <Divider style={{ height: 4, visibility: "hidden" }} />}
      <Card>
        <CardHeader
          title="Adjust the content spacing within the app by choosing one of the following options"
          titleTypographyProps={{ align: "center", variant: "h6" }}
        />
        <CardContent className={classes.twoColumnGrid}>
          <div>
            <Fade in={contentSpacing.setting === "normal"}>
              <Typography variant="body2" color="primary" align="center" gutterBottom>
                Current
              </Typography>
            </Fade>
            <Paper
              className={
                contentSpacing.setting === "normal"
                  ? classes.tableExampleSelected
                  : classes.tableExample
              }
            >
              <Table>
                {[1, 2, 3, 4].map((item) => (
                  <TableRow hover>
                    <TableCell style={{ borderBottom: 0 }}>
                      <Typography>{`Line item ${item}`}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Paper>
          </div>
          <div>
            <Fade in={contentSpacing.setting === "dense"}>
              <Typography variant="body2" color="primary" align="center" gutterBottom>
                Current
              </Typography>
            </Fade>
            <Paper
              className={
                contentSpacing.setting === "dense"
                  ? classes.tableExampleSelected
                  : classes.tableExample
              }
            >
              <Table size="small">
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <TableRow hover>
                    <TableCell style={{ borderBottom: 0 }}>
                      <Typography>{`Line item ${item}`}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Paper>
          </div>
        </CardContent>
        <Divider style={{ marginTop: 16 }} />
        <CardActions className={classes.twoColumnGrid}>
          <div>
            <Typography
              variant="body2"
              align="center"
              color={contentSpacing.setting === "normal" ? "primary" : "inherit"}
            >
              Normal
            </Typography>
            <Button onClick={() => handleSpacingChange("normal")}>
              <ContentSpacingSelector
                type="normal"
                selected={contentSpacing.setting === "normal"}
              />
            </Button>
          </div>
          <div>
            <Typography
              variant="body2"
              align="center"
              color={contentSpacing.setting === "dense" ? "primary" : "inherit"}
            >
              Dense
            </Typography>
            <Button onClick={() => handleSpacingChange("dense")}>
              <ContentSpacingSelector type="dense" selected={contentSpacing.setting === "dense"} />
            </Button>
          </div>
        </CardActions>
      </Card>
      <Fade in={contentSpacing.setting !== contentSpacing.previousSetting}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ApplyIcon />}
          className={classes.acceptButton}
          onClick={saveChanges}
        >
          Apply
        </Button>
      </Fade>
    </>
  );
}

interface StyleProps {
  selected: boolean;
}

const useContentSpacingSelectorStyles = makeStyles<Theme, StyleProps>((theme) => ({
  spacingSelectorRoot: (props) => ({
    background: props.selected ? theme.palette.primary.main : theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 80,
    width: 180,
  }),
  spacingSelectorNormal: (props) => ({
    background: props.selected ? theme.palette.common.white : "#D8D8D8",
    height: 25,
    width: 160,
    marginBottom: 4,
  }),
  spacingSelectorNormalLast: (props) => ({
    background: props.selected ? theme.palette.common.white : "#D8D8D8",
    height: 25,
    width: 160,
    marginBottom: 0,
  }),
  spacingSelectorDense: (props) => ({
    background: props.selected ? theme.palette.common.white : "#D8D8D8",
    height: 12,
    width: 160,
    marginBottom: 4,
  }),
  spacingSelectorDenseLast: (props) => ({
    background: props.selected ? theme.palette.common.white : "#D8D8D8",
    height: 12,
    width: 160,
    marginBottom: 0,
  }),
}));

function ContentSpacingSelector(props: { type: "normal" | "dense"; selected: boolean }) {
  const classes = useContentSpacingSelectorStyles({ selected: props.selected });

  if (props.type === "normal") {
    return (
      <Paper className={classes.spacingSelectorRoot}>
        <Paper elevation={0} className={classes.spacingSelectorNormal}></Paper>
        <Paper elevation={0} className={classes.spacingSelectorNormalLast}></Paper>
      </Paper>
    );
  } else {
    return (
      <Paper className={classes.spacingSelectorRoot}>
        <Paper elevation={0} className={classes.spacingSelectorDense}></Paper>
        <Paper elevation={0} className={classes.spacingSelectorDense}></Paper>
        <Paper elevation={0} className={classes.spacingSelectorDense}></Paper>
        <Paper elevation={0} className={classes.spacingSelectorDenseLast}></Paper>
      </Paper>
    );
  }
}
