import { IServerSideGetRowsRequest } from "ag-grid-community";
import gql from "graphql-tag";
import { PaginationInput, PaginationResponse } from "../../types/ApiTypes";
import { awsDateStringToDate } from "../../utils/Date";
import { toAwsDateStr } from "../../utils/GraphQL";

//#region Types
export type PrioritizedRule = {
  conditions: Array<RuleCondition>;
  prices: Array<number> | null;
  priority: number;
  priorityMessage: string | null;
};

export type AlternativesListConfig = {
  hasCustomPriority: boolean;
  includeAllDrugsFromRules: boolean;
  usingPricingListId: string | null;
  prioritizedBasket: Array<PrioritizedRule>;
};

export type RuleCondition = {
  drugAttribute: DrugAttribute;
  matchValue: string;
  ruleId?: string;
};

export type ApiListRule = {
  conditions: Array<RuleCondition>;
  createdAt: string | null;
  effectiveDate: string | null;
  id: string | null;
  listId: string;
  terminationDate: string | null;
  price: number | null;
};

export type ListRule = Omit<ApiListRule, "createdAt" | "effectiveDate" | "terminationDate"> & {
  createdAt: Date | null;
  effectiveDate: Date | null;
  terminationDate: Date | null;
};

type DrugAttributeInfo = {
  display: string;
  field: DrugAttribute;
  exclusiveToDrugSource?: "FDB" | "MSPAN";
  exclusiveForView?: "RULE_ATTR" | "LIST_GRID";
  exclusiveForListType?: DrugListType;
  options?: Array<{ display: string; value: string }>;
};

export type ListDrug = {
  drugName: string;
  fdbDetails: {
    gcn: string;
    gcnSequenceNumber: string;
    hic3: string;
    hic3Description: string;
    hiclSequenceNumber: string;
    medId: string;
  } | null;
  genericDescription: string;
  isOtc: boolean;
  labelName: string;
  lastUpdated: string | null;
  medispanDetails: {
    drugDescriptorId: number;
    gpi14: string;
    mony: "M" | "O" | "N" | "Y";
  } | null;
  ndc: string;
  offMarketDate: string | null;
  onMarketDate: string | null;
  productId: string;
  routeOfAdmin: string;
  rxcui: string;
  type: string;
};

export type GSNDrug = {
  labelName: string;
  productId: string;
  type: "BRAND" | "GENERIC";
  fdbDetails: {
    gcnSequenceNumber: string;
  };
  medispanDetails: {
    gpi14: string;
    mony: string;
  };
};

export type GetListDrugsInput = {
  id: string;
  drugsInput: ListDrugsInput;
  paginationInput?: PaginationInput;
};

export type ListDrugsInput = {
  asOfDate: string; // aws date string
  analyticsMode?: boolean;
  filterModel?: string; // aws json
  groupCols?: Array<GridColumn>;
  groupKeys?: Array<string>;
  sortConfig?: Array<SortItem>;
  valueCols?: Array<GridColumn>;
};

export type GetListDrugsResponse = {
  list: {
    isMedicare: boolean;
    drugSource: string;
    drugs: {
      pageInfo: PaginationResponse;
      items: Array<{
        drugName: string;
        labelName: string;
        ndc: string;
        productId: string;
        fdbDetails: {
          gcn: string;
          medId: string;
        };
        medispanDetails: {
          gpi14: string;
          mony: string;
        };
        price?: number;
      }>;
    };
  };
};

export type APITierList = {
  id: string;
  name: string;
  listType: DrugListType.TIER;
  isMedicare: boolean;
  effectiveDate: string | null;
  terminationDate: string | null;
  externalId: string | null;
  description: string | null;
  rules: {
    items: Array<ApiListRule>;
  };
  drugs: {
    items: Array<ListDrug>;
  };
};

export type TierList = Omit<APITierList, "effectiveDate" | "terminationDate"> & {
  effectiveDate: Date | null;
  terminationDate: Date | null;
};

export type APIEditList = {
  id: string;
  name: string;
  listType: DrugListType.EDIT;
  editType: string | null;
  isMedicare: boolean;
  effectiveDate: string | null;
  terminationDate: string | null;
  externalId: string | null;
  description: string | null;
  rules: {
    items: Array<ApiListRule>;
  };
  drugs: {
    items: Array<ListDrug>;
  };
};

export type EditList = Omit<APIEditList, "effectiveDate" | "terminationDate"> & {
  effectiveDate: Date | null;
  terminationDate: Date | null;
};

export type APIAlternativesList = {
  id: string;
  name: string;
  listType: DrugListType.ALT;
  isMedicare: boolean;
  effectiveDate: string | null;
  terminationDate: string | null;
  externalId: string | null;
  description: string | null;
  rules: {
    items: Array<ApiListRule>;
  };
  drugs: {
    items: Array<ListDrug>;
  };
};

export type AlternativeList = Omit<APIAlternativesList, "effectiveDate" | "terminationDate"> & {
  effectiveDate: Date | null;
  terminationDate: Date | null;
};

export type APIPricingList = {
  id: string;
  name: string;
  listType: DrugListType.PRICE;
  isMedicare: boolean;
  effectiveDate: string | null;
  terminationDate: string | null;
  externalId: string | null;
  description: string | null;
  rules: {
    items: Array<ApiListRule>;
  };
  drugs: {
    items: Array<ListDrug>;
  };
};

export type PricingList = Omit<APIPricingList, "effectiveDate" | "terminationDate"> & {
  effectiveDate: Date | null;
  terminationDate: Date | null;
};

export type GetTierListsResponse = {
  lists: {
    items: Array<APITierList>;
  };
};

export type GetEditListsResponse = {
  lists: {
    items: Array<APIEditList>;
  };
};

export type GetAltListsResponse = {
  lists: {
    items: Array<APIAlternativesList>;
  };
};

export type GetPricingListsResponse = {
  lists: {
    items: Array<APIPricingList>;
  };
};

export type GridColumn = {
  field: string;
  aggFunc?: string;
};

export interface CreateListInput {
  input: {
    name: string;
    effectiveDate: string;
    listType: string;
    description: string | null;
    externalId: string | null;
    terminationDate: string | null;
  };
}

export type CreateListResponse = {
  createList: { id: string; name: string };
};

export type GetListInput = {
  id: string;
  rulesInput?: {
    asOfDate: string | null;
    productId?: string;
  };
  drugsInput?: {
    asOfDate: string | null;
  };
};

export type RuleForDrugInput = {
  asOfDate: string;
  drugListId: string;
  ndc: string;
};

export type GetGSNDrugsInput = {
  id: string;
  drugsInput: {
    analyticsMode: boolean;
    asOfDate: string | null;
    groupCols: Array<{ field: string }>;
  };
  pageInput?: PaginationInput;
};

export type BasicListInput = {
  id: string;
};

export type GetListResponse<
  T = APITierList | APIEditList | APIAlternativesList | APIPricingList
> = {
  list: T;
};

export type GetRulesResponse = {
  list: Pick<APITierList | APIEditList | APIAlternativesList | APIPricingList, "rules">;
};

export type RulesForDrugResponse = {
  rulesForDrug: {
    items: Array<ApiListRule>;
  };
};

export type GetGSNDrugsResponse = {
  list: {
    drugs: {
      items: Array<GSNDrug>;
      pageInfo: PaginationResponse;
    };
  };
};

export type UpdateListInput = {
  input: {
    id: string;
    description: string | null;
    name: string;
    externalId: string | null;
    terminationDate: string | null;
  };
};

export type UpdateListResponse = {
  updateList: APITierList | APIAlternativesList;
};

export type CopyListRequest = {
  id: string;
  input: {
    subAccountIds: Array<string>;
    name?: string;
  };
};

export type CopyListResponse = {
  copyList: {
    items: Array<APIAlternativesList | APIPricingList | APITierList | APIEditList>;
  };
};

export type ListInput = {
  input: {
    name: string;
    effectiveDate: string;
    listType: string;
    description?: string;
    externalId?: string;
    terminationDate?: string;
  };
};

export type ListResponse = {
  createDrugList: {
    id: string;
    name: string;
  };
};

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type SortItem = {
  field: string;
  sortDirection: SortDirection;
};

export type TerminateRuleInput = {
  id: string;
  terminationDate: string;
};

export type TerminateRuleResponse = {
  id: string;
  effectiveDate: string;
  terminationDate: string;
};

export type SaveAlternativesListConfigInput = {
  id: string;
  input: AlternativesListConfigInput;
};

export type SaveAlternativesListConfigResponse = {
  setAlternativesListConfig: {
    prioritizedBasket: Array<Pick<PrioritizedRule, "prices">>;
  };
};

export type AlternativesListConfigInput = {
  hasCustomPriority: boolean;
  includeAllDrugsFromRules: boolean | null;
  usingPricingListId: string | null;
  prioritizedBasket: Array<PrioritizedRuleInput>;
};

export type PrioritizedRuleInput = {
  conditions: Array<RuleCondition>;
  priority: number;
  priorityMessage: string | null;
};

export enum DialogStatus {
  CLOSED,
  OPEN,
  PROCESSING,
  ERROR,
}

export type ListSendDialog = {
  stage: DialogStatus;
  list?: APIAlternativesList | APITierList | APIEditList | APIPricingList;
  error?: any;
};

export type SendDialogParams = {
  name: string;
  subAccountIds: Array<string>;
};

//#endregion

//#region GraphQL Queries and Mutations
export const GET_TIER_LISTS = gql`
  query GetTierLists {
    lists(listType: TIER) {
      items {
        id
        name
        description
        listType
        externalId
        effectiveDate
        terminationDate
      }
    }
  }
`;

export const GET_EDIT_LISTS = gql`
  query GetEditLists {
    lists(listType: EDIT) {
      items {
        id
        name
        description
        listType
        externalId
        effectiveDate
        terminationDate

        ... on DrugList {
          editType {
            name
          }
        }
      }
    }
  }
`;

export const GET_ALT_LISTS = gql`
  query GetAlternativeLists {
    lists(listType: ALTERNATIVES) {
      items {
        id
        name
        description
        listType
        externalId
        effectiveDate
        terminationDate
      }
    }
  }
`;

export const GET_PRICING_LISTS = gql`
  query GetPricingLists {
    lists(listType: PRICING) {
      items {
        id
        name
        description
        listType
        externalId
        effectiveDate
        terminationDate
      }
    }
  }
`;

export const CREATE_LIST = gql`
  mutation CreateList($input: CreateListInput!) {
    createList(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_LIST = gql`
  mutation UpdateList($input: UpdateListInput!) {
    updateList(input: $input) {
      id
      name
      externalId
      effectiveDate
      terminationDate
    }
  }
`;

export const COPY_LIST = gql`
  mutation CopyList($id: ID!, $input: CopyListInput!) {
    copyList(id: $id, input: $input) {
      ... on ListsConnection {
        items {
          id
        }
      }
      ... on Error {
        code
        context
      }
    }
  }
`;

export const TIER_LIST_DRUGS = gql`
  query TierListDrugs($id: ID!, $drugsInput: ListDrugsInput!, $paginationInput: PaginationInput) {
    list(id: $id) {
      id
      ... on DrugList {
        isMedicare
        drugSource
        drugs(input: $drugsInput, paginationInput: $paginationInput) {
          pageInfo {
            endCursor
          }
          items {
            brandName
            drugName
            labelName
            genericName
            ndc
            productId
            type
            rxcui
            fdbDetails {
              gcn
              medId
              hiclSequenceNumber
              hic3
              hic3Description
            }
            medispanDetails {
              drugDescriptorId
              gpi14
              mony
            }
          }
        }
      }
    }
  }
`;

export const GET_ALTERNATIVE_LIST_DRUGS = gql`
  query AlternativeListDrugs(
    $id: ID!
    $drugsInput: ListDrugsInput!
    $paginationInput: PaginationInput
  ) {
    list(id: $id) {
      ... on AlternativesList {
        isMedicare
        drugSource
        drugs(input: $drugsInput, paginationInput: $paginationInput) {
          pageInfo {
            endCursor
          }
          items {
            brandName
            drugName
            labelName
            genericName
            ndc
            productId
            type
            isOtc
            rxcui
            fdbDetails {
              gcn
              gcnSequenceNumber
              medId
              hiclSequenceNumber
              hic3
              hic3Description
            }
            medispanDetails {
              drugDescriptorId
              gpi14
              mony
            }
          }
        }
      }
    }
  }
`;

export const GET_PRICING_LIST_DRUGS = gql`
  query drugs($id: ID!, $drugsInput: ListDrugsInput!, $paginationInput: PaginationInput) {
    list(id: $id) {
      ... on PricingList {
        isMedicare
        drugSource
        drugs(input: $drugsInput, paginationInput: $paginationInput) {
          pageInfo {
            endCursor
          }
          items {
            brandName
            drugName
            labelName
            genericName
            ndc
            productId
            type
            fdbDetails {
              gcn
              medId
              hiclSequenceNumber
              hic3
              hic3Description
            }
            medispanDetails {
              drugDescriptorId
              gpi14
              mony
            }
            price
          }
        }
      }
    }
  }
`;

export const GET_LIST = gql`
  query GetList($id: ID!, $rulesInput: ListRulesInput!, $drugsInput: ListDrugsInput!) {
    list(id: $id) {
      id
      name
      listType
      effectiveDate
      terminationDate
      externalId
      description

      ... on DrugList {
        isMedicare
        drugSource
        rules(input: $rulesInput) {
          items {
            id
            effectiveDate
            createdAt
            terminationDate
            conditions {
              ruleId
              matchValue
              drugAttribute
            }
          }
        }
      }

      ... on AlternativesList {
        isMedicare
        drugSource
        rules(input: $rulesInput) {
          items {
            id
            effectiveDate
            createdAt
            terminationDate
            conditions {
              ruleId
              matchValue
              drugAttribute
            }
          }
        }
        drugs(input: $drugsInput) {
          items {
            drugName
            labelName
            ndc
            productId
            fdbDetails {
              gcn
              medId
            }
            medispanDetails {
              gpi14
              mony
            }
          }
        }
      }

      ... on PricingList {
        isMedicare
        drugSource
        rules(input: $rulesInput) {
          items {
            id
            effectiveDate
            createdAt
            terminationDate
            conditions {
              ruleId
              matchValue
              drugAttribute
            }
            price
          }
        }
        drugs(input: $drugsInput) {
          items {
            drugName
            labelName
            ndc
            productId
            fdbDetails {
              gcn
              medId
            }
            medispanDetails {
              gpi14
              mony
            }
          }
        }
      }
    }
  }
`;

export const GET_BASIC_LIST = gql`
  query GetBasicList($id: ID!) {
    list(id: $id) {
      id
      name
      listType
      effectiveDate
      terminationDate
      externalId
      description
    }
  }
`;

export const TERMINATE_RULE = gql`
  mutation TerminateRule($id: ID!, $terminationDate: AWSDate) {
    updateListRule(input: { id: $id, terminationDate: $terminationDate }) {
      id
      effectiveDate
      terminationDate
    }
  }
`;

export const GET_RULES_FOR_DRUG = gql`
  query GetRulesForDrug($input: RulesForDrugInput!) {
    rulesForDrug(input: $input) {
      items {
        id
        effectiveDate
        createdAt
        terminationDate
        conditions {
          ruleId
          matchValue
          drugAttribute
        }
      }
    }
  }
`;

export const GET_ALTERNATIVES_LIST_CONFIG = gql`
  query GetAltListConfig($id: ID!) {
    alternativesListConfig(listId: $id) {
      hasCustomPriority
      includeAllDrugsFromRules
      usingPricingListId
      prioritizedBasket {
        conditions {
          drugAttribute
          matchValue
        }
        prices
        priority
        priorityMessage
      }
    }
  }
`;

export const GET_GSN_DRUG_FOR_LIST = gql`
  query GetAlternativeListDrugsByGSN(
    $id: ID!
    $drugsInput: ListDrugsInput!
    $pageInput: PaginationInput
  ) {
    list(id: $id) {
      id
      name
      ... on AlternativesList {
        drugs(input: $drugsInput, paginationInput: $pageInput) {
          items {
            labelName
            productId
            type
            fdbDetails {
              gcnSequenceNumber
            }
            medispanDetails {
              gpi14
              mony
            }
          }
          pageInfo {
            endCursor
          }
        }
      }
    }
  }
`;

export const SAVE_ALTERNATIVES_LIST_CONFIG = gql`
  mutation SaveConfig($input: AlternativesListConfigInput!, $id: ID!) {
    setAlternativesListConfig(input: $input, listId: $id) {
      prioritizedBasket {
        prices
      }
    }
  }
`;
//#endregion

//#region Functions, Enums, and Records
export enum DrugListType {
  TIER = "TIER",
  EDIT = "EDIT",
  ALT = "ALTERNATIVES",
  PRICE = "PRICING",
  BENEFITS = "BENEFITS",
}

export enum DrugAttribute {
  brandName = "brandName",
  drugName = "drugName",
  drugDescriptorId = "drugDescriptorId",
  gcn = "gcn",
  gcnSequenceNumber = "gcnSequenceNumber",
  genericName = "genericName",
  genericDescription = "genericDescription",
  gni = "gni",
  gpi10 = "gpi10",
  gpi12 = "gpi12",
  gpi14 = "gpi14",
  gpi2 = "gpi2",
  gpi4 = "gpi4",
  gpi6 = "gpi6",
  gpi8 = "gpi8",
  hic3 = "hic3",
  hiclSequenceNumber = "hiclSequenceNumber",
  isBrand = "isBrand",
  isOtc = "isOtc",
  isRepackager = "isRepackager",
  labelName = "labelName",
  lastUpdated = "lastUpdated",
  medId = "medId",
  mony = "mony",
  ndc = "ndc",
  ndc5 = "ndc5",
  ndc9 = "ndc9",
  rxcui = "rxcui",
  onMarketDate = "onMarketDate",
  offMarketDate = "offMarketDate",
  price = "price",
}

export const DRUG_ATTRIBUTES: Record<DrugAttribute, DrugAttributeInfo> = {
  brandName: {
    display: "Brand Name",
    field: DrugAttribute.brandName,
  },
  drugName: {
    display: "Drug Name",
    field: DrugAttribute.drugName,
    exclusiveForView: "LIST_GRID",
  },
  drugDescriptorId: {
    display: "Drug Descriptor Id",
    field: DrugAttribute.drugDescriptorId,
    exclusiveToDrugSource: "MSPAN",
    exclusiveForView: "LIST_GRID",
  },
  genericDescription: {
    display: "Generic Description",
    field: DrugAttribute.genericDescription,
  },
  gpi14: {
    exclusiveToDrugSource: "MSPAN",
    display: "GPI 14",
    field: DrugAttribute.gpi14,
  },
  gpi12: {
    exclusiveToDrugSource: "MSPAN",
    display: "GPI 12",
    field: DrugAttribute.gpi12,
    exclusiveForView: "RULE_ATTR",
  },
  gpi10: {
    exclusiveToDrugSource: "MSPAN",
    display: "GPI 10",
    field: DrugAttribute.gpi10,
    exclusiveForView: "RULE_ATTR",
  },
  gpi8: {
    exclusiveToDrugSource: "MSPAN",
    display: "GPI 8",
    field: DrugAttribute.gpi8,
    exclusiveForView: "RULE_ATTR",
  },
  gpi6: {
    exclusiveToDrugSource: "MSPAN",
    display: "GPI 6",
    field: DrugAttribute.gpi6,
    exclusiveForView: "RULE_ATTR",
  },
  gpi4: {
    exclusiveToDrugSource: "MSPAN",
    display: "GPI 4",
    field: DrugAttribute.gpi4,
    exclusiveForView: "RULE_ATTR",
  },
  gpi2: {
    exclusiveToDrugSource: "MSPAN",
    display: "GPI 2",
    field: DrugAttribute.gpi2,
    exclusiveForView: "RULE_ATTR",
  },
  hic3: {
    exclusiveToDrugSource: "FDB",
    display: "HIC3",
    field: DrugAttribute.hic3,
  },
  hiclSequenceNumber: {
    display: "HICL Seq. No.",
    field: DrugAttribute.hiclSequenceNumber,
    exclusiveToDrugSource: "FDB",
  },
  gcn: {
    display: "GCN",
    field: DrugAttribute.gcn,
    exclusiveToDrugSource: "FDB",
  },
  gcnSequenceNumber: {
    display: "Generic Seq. No.",
    field: DrugAttribute.gcnSequenceNumber,
    exclusiveToDrugSource: "FDB",
  },
  genericName: {
    display: "Generic Name",
    field: DrugAttribute.genericName,
  },
  gni: {
    display: "GNI",
    field: DrugAttribute.gni,
    exclusiveToDrugSource: "FDB",
  },
  labelName: {
    display: "Label Name",
    field: DrugAttribute.labelName,
  },
  medId: {
    display: "FDB Med ID",
    field: DrugAttribute.medId,
    exclusiveToDrugSource: "FDB",
  },
  mony: {
    display: "MONY",
    field: DrugAttribute.mony,
    exclusiveToDrugSource: "MSPAN",
    options: [
      { display: "M", value: "M" },
      { display: "O", value: "O" },
      { display: "N", value: "N" },
      { display: "Y", value: "Y" },
    ],
  },
  ndc: {
    display: "NDC",
    field: DrugAttribute.ndc,
  },
  ndc5: {
    display: "NDC-5",
    field: DrugAttribute.ndc5,
    exclusiveForView: "RULE_ATTR",
  },
  ndc9: {
    display: "NDC-9",
    field: DrugAttribute.ndc9,
    exclusiveForView: "RULE_ATTR",
  },
  rxcui: {
    display: "Rx CUI",
    field: DrugAttribute.rxcui,
  },
  isBrand: {
    display: "Brand Status",
    field: DrugAttribute.isBrand,
    options: [
      { display: "Brand", value: "true" },
      { display: "Generic", value: "false" },
    ],
  },
  isOtc: {
    display: "Rx/OTC Status",
    field: DrugAttribute.isOtc,
    options: [
      { display: "OTC", value: "true" },
      { display: "Rx", value: "false" },
    ],
  },
  isRepackager: {
    display: "Is Repackager",
    field: DrugAttribute.isRepackager,
    options: [
      { display: "Yes", value: "true" },
      { display: "No", value: "false" },
    ],
    exclusiveForView: "LIST_GRID",
  },
  lastUpdated: {
    display: "Last Updated",
    field: DrugAttribute.lastUpdated,
    exclusiveForView: "LIST_GRID",
  },
  onMarketDate: {
    display: "On Market Date",
    field: DrugAttribute.onMarketDate,
    exclusiveForView: "LIST_GRID",
  },
  offMarketDate: {
    display: "Off Market Date",
    field: DrugAttribute.offMarketDate,
    exclusiveForView: "LIST_GRID",
  },
  price: {
    display: "Price",
    field: DrugAttribute.price,
    exclusiveForView: "LIST_GRID",
    exclusiveForListType: DrugListType.PRICE,
  },
};

export const apiListToList = ({
  effectiveDate,
  terminationDate,
  ...rest
}: APITierList | APIEditList | APIAlternativesList | APIPricingList) => {
  return {
    ...rest,
    effectiveDate: effectiveDate ? awsDateStringToDate(effectiveDate) : null,
    terminationDate: terminationDate ? awsDateStringToDate(terminationDate) : null,
  };
};
// TODO Below 4 functions exist solely to map from one type of list to another. Should be removed in time
export const apiTierListToTierList = (list: APITierList) => apiListToList(list) as TierList;
export const apiEditListToList = (list: APIEditList) => apiListToList(list) as EditList;
export const apiAlternativeListToList = (list: APIAlternativesList) =>
  apiListToList(list) as AlternativeList;
export const apiPricingListToList = (list: APIPricingList) => apiListToList(list) as PricingList;

export const apiListRuleToListRule = ({
  effectiveDate,
  createdAt,
  terminationDate,
  ...rest
}: ApiListRule): ListRule => ({
  ...rest,
  createdAt: createdAt ? awsDateStringToDate(createdAt) : null,
  effectiveDate: effectiveDate ? awsDateStringToDate(effectiveDate) : null,
  terminationDate: terminationDate ? awsDateStringToDate(terminationDate) : null,
});

export const toListDrugsInput = (
  request: IServerSideGetRowsRequest,
  viewDate: Date
): {
  input: ListDrugsInput;
  paginationInput: PaginationInput;
} => {
  const sortConfig: SortItem[] = (request.sortModel as {
    colId: string;
    sort: "desc" | "asc";
  }[]).map((s) => ({
    field: s.colId,
    sortDirection: s.sort === "asc" ? SortDirection.ASC : SortDirection.DESC,
  }));

  const groupCols = request.rowGroupCols
    .filter((col) => col.field !== undefined)
    .map<GridColumn>((col) => ({
      field: col.field!,
      aggFunc: col.aggFunc,
    }));

  const valueCols = request.valueCols
    .filter((col) => col.field !== undefined)
    .map<GridColumn>((col) => ({
      field: col.field!,
      aggFunc: col.aggFunc,
    }));

  return {
    input: {
      asOfDate: toAwsDateStr(viewDate)!,
      filterModel: JSON.stringify(request.filterModel),
      sortConfig,
      groupCols,
      groupKeys: request.groupKeys,
      valueCols,
    },
    paginationInput: {
      after: request.startRow?.toString(),
      first: request.endRow - request.startRow,
    },
  };
};
//#endregion
