import { createMuiTheme, Theme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { UserAreaSections, AppSections, Security } from "../types/AppTypes";

type Apps = AppSections | UserAreaSections | Security;

export const TEMPLATE_PRIMARY_COLOR = "#ff5257";
export const FLEX_PRIMARY_COLOR = "#408bca";
export const PLANS_PRIMARY_COLOR = green[500]; //TODO: This is the same color as verification and accept buttons, revisit this theme choice when we revisit theme colors for the entire app.
export const NETWORK_PRIMARY_COLOR = "#8338EC";
export const DEFAULT_THEME_COLOR = "#408bca";
export const TEXT_PRIMARY_COLOR = "#4e4e4e";
export const TEXT_SECONDARY_COLOR = "#979797";
export const APP_LOGIN_COLOR = "#682875";
export const FONT_STACK = `-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif`;

export enum FONT_WEIGHTS {
  Thin = 250,
  Light = 300,
  Regular = 400,
  Medium = 500,
  Bold = 600,
  ExtraBold = 700,
}

const headersStyle = {
  fontFamily: FONT_STACK,
  color: TEXT_PRIMARY_COLOR,
  fontWeight: FONT_WEIGHTS.Light,
  "-webkit-font-smoothing": "auto",
};

function appFont(textSize: string | null) {
  return { fontFamily: FONT_STACK, fontSize: textSize ? textSize : "1rem" };
}

export function baseTheme(textSize: string | null): Theme {
  return createMuiTheme({
    overrides: {
      MuiButton: {
        root: appFont(textSize),
      },
      MuiTab: {
        root: appFont(textSize),
      },
      MuiInputBase: {
        root: appFont(textSize),
      },
      MuiTooltip: {
        tooltip: appFont(textSize),
      },
      MuiAvatar: {
        root: appFont(textSize),
      },
      MuiTypography: {
        body1: appFont(textSize),
        body2: appFont(textSize),
        h1: appFont(textSize),
        h2: appFont(textSize),
        h3: appFont(textSize),
        h4: appFont(textSize),
        h5: appFont(textSize),
        h6: appFont(textSize),
      },
    },
  });
}

export function getAppTheme(app: Apps, textSize: string | null = "14px"): Theme {
  return createMuiTheme({
    palette: {
      primary: {
        main: getThemePrimaryColor(app),
      },
      text: { primary: TEXT_PRIMARY_COLOR, secondary: TEXT_SECONDARY_COLOR },
    },
    ...(textSize && {
      typography: {
        fontSize: parseInt(textSize),
      },
    }),
    overrides: {
      MuiButton: {
        root: {
          fontFamily: FONT_STACK,
        },
      },
      MuiTab: {
        root: {
          fontFamily: FONT_STACK,
        },
      },
      MuiTooltip: {
        tooltip: {
          fontFamily: FONT_STACK,
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: FONT_STACK,
        },
      },
      MuiTableCell: {
        root: {
          fontFamily: FONT_STACK,
        },
      },
      MuiTextField: {
        root: {
          fontFamily: FONT_STACK,
        },
      },
      MuiFormLabel: {
        root: {
          fontFamily: FONT_STACK,
        },
      },
      MuiListSubheader: {
        root: {
          fontFamily: FONT_STACK,
        },
      },
      MuiTypography: {
        body1: {
          ...appFont(textSize),
          color: TEXT_PRIMARY_COLOR,
          fontWeight: FONT_WEIGHTS.Thin,
        },
        body2: {
          ...appFont(textSize),
          color: TEXT_PRIMARY_COLOR,
          fontWeight: FONT_WEIGHTS.Regular,
        },
        h1: headersStyle,
        h2: headersStyle,
        h3: headersStyle,
        h4: headersStyle,
        h5: headersStyle,
        h6: headersStyle,
      },
    },
  });
}

function getThemePrimaryColor(appName: Apps) {
  switch (appName) {
    case AppSections.TEMPLATES:
      return TEMPLATE_PRIMARY_COLOR;
    case AppSections.FORMULARIES:
      return FLEX_PRIMARY_COLOR;
    case AppSections.DRUG_SEARCH:
      return FLEX_PRIMARY_COLOR;
    case AppSections.DRUG_LISTS:
      return FLEX_PRIMARY_COLOR;
    case AppSections.BENEFITS:
      return PLANS_PRIMARY_COLOR;
    case AppSections.NETWORK:
      return NETWORK_PRIMARY_COLOR;
    case Security.LOGIN:
      return APP_LOGIN_COLOR;
    default:
      return DEFAULT_THEME_COLOR;
  }
}
