import produce from "immer";
import { FormularyTier } from "../../Flex/Formularies/FormularyPage/types";
import { EditNetworkTier } from "../../Network/api";
import {
  ClaimRanges,
  cleanCostShare,
  CostShare,
  createEmptyRow,
  DaySupplyRanges,
  daySupplyRangeStartingValues,
  emptyFormularyTierRow,
  emptyNetworkTierColumns,
  EMPTY_CLAIM_RANGES,
  firstFormularyTierRow,
  getRow,
  RowKind,
  startingDaySupplyRanges,
  ValueType,
} from "./CostShareSchema";

export type CostShareStatus =
  | "init"
  | "need to reconcile"
  | "reconciled"
  | "saving"
  | "error"
  | "done";

export interface CostShareState {
  status: CostShareStatus;
  costShare: CostShare | undefined;
  daySupplyRangeValues: Map<string, DaySupplyRangeValues>;
  benefitsLists: Array<{ id: string; name: string }>;
  formularyTiers: Array<FormularyTier>;
  networkTiers: Array<EditNetworkTier>;
}

export interface DaySupplyRangeValues {
  startValue: number;
  endValue?: number; // will be undefined at end boundary of day supply range
}

export interface ClaimRangeValues {
  startValue: number;
  endValue?: number; // will be undefined at end boundary of claim range
}

export type CostShareAction =
  | {
      type: "set loaded cost share data";
      costShare: CostShare;
      formularyTiers: Array<FormularyTier>;
      networkTiers: Array<EditNetworkTier>;
      benefitsList: Array<{ id: string; name: string }>;
    }
  | { type: "error" }
  | { type: "reconcile cost share" }
  | { type: "done saving" }
  | { type: "add day supply range"; networkTier: EditNetworkTier }
  | {
      type: "add claim range";
      row: RowKind;
      networkId: string;
      daySupplyRangeStartingValue: number;
    }
  | { type: "delete day supply range"; networkTierId: string; daySupplyRangeStartingValue: number }
  | {
      type: "delete claim range";
      row: RowKind;
      networkTierId: string;
      daySupplyRangeStartingValue: number;
      claimRangeStartingCost: number;
    }
  | {
      type: "update day supply range end value";
      networkTierId: string;
      startingValue: number;
      newEndValue: number;
    }
  | {
      type: "update day supply ranges";
      networkTierId: string;
      daySupplyRangeStartingValue: number;
    }
  | {
      type: "update claim ranges";
      row: RowKind;
      networkTierId: string;
      daySupplyRangeStartValue: number;
      updatedClaimRangeStartCost: number;
      updatedEndCost: number;
    }
  | {
      type: "update min cell value";
      rowActionKind: RowKind;
      networkTierId: string;
      daySupplyRangeStartingValue: number;
      claimRangeStartingCost: number;
      newValue: number;
    }
  | {
      type: "update max cell value";
      rowActionKind: RowKind;
      networkTierId: string;
      daySupplyRangeStartingValue: number;
      claimRangeStartingCost: number;
      newValue: number;
    }
  | {
      type: "update OON cell value";
      rowActionKind: RowKind;
      newValue: number;
    }
  | {
      type: "update value cell value";
      rowActionKind: RowKind;
      networkTierId: string;
      daySupplyRangeStartingValue: number;
      claimRangeStartingCost: number;
      newValue: number;
    }
  | {
      type: "update value cell value type";
      rowActionKind: RowKind;
      networkTierId: string;
      daySupplyRangeStartingValue: number;
      claimRangeStartingCost: number;
      newValueType: ValueType;
    }
  | {
      type: "update OON value type";
      rowActionKind: RowKind;
      newValueType: ValueType;
    }
  | { type: "prepare cost share for save" }
  | { type: "toggle day supply range status" }
  | { type: "toggle claim ranges" }
  | { type: "toggle formulary tier row"; formularyTierId: string }
  | { type: "add list"; list: { id: string; name: string } }
  | { type: "delete list row"; listId: string };

export function costShareReducer(state: CostShareState, action: CostShareAction): CostShareState {
  if (state.costShare) {
    const { costShare, daySupplyRangeValues } = state;
    switch (action.type) {
      case "error": {
        return { ...state, status: "error" };
      }
      case "add day supply range": {
        const highestRangeStartingValue: number = Object.keys(
          firstFormularyTierRow(state.costShare, state.formularyTiers).networkTiers[
            action.networkTier.id
          ].daySupplyRanges
        ).reduce<number>((highestRangeValue, currentRangeStartingValue) => {
          const currentRangeNum = parseInt(currentRangeStartingValue);
          return currentRangeNum > highestRangeValue ? currentRangeNum : highestRangeValue;
        }, 0);

        const newStartingRangeValue = highestRangeStartingValue + 30;

        return {
          ...state,
          daySupplyRangeValues: produce(state.daySupplyRangeValues, (draft) => {
            draft.set(daySupplyRangeKey(action.networkTier.id, highestRangeStartingValue), {
              startValue: highestRangeStartingValue,
              endValue: newStartingRangeValue - 1,
            });
            draft.set(daySupplyRangeKey(action.networkTier.id, newStartingRangeValue), {
              startValue: newStartingRangeValue,
            });
          }),
          costShare: produce<CostShare>(state.costShare, (draft) => {
            for (const formularyTierId in draft.formularyTiers) {
              const formularyTier = draft.formularyTiers[formularyTierId];
              formularyTier.networkTiers[action.networkTier.id].daySupplyRanges[
                newStartingRangeValue
              ] = { claimRanges: { 0: { coPayCoIns: ValueType.DOLLAR, value: undefined } } };
            }
            for (const listId in draft.lists) {
              const list = draft.lists[listId];
              list.networkTiers[action.networkTier.id].daySupplyRanges[newStartingRangeValue] = {
                claimRanges: { 0: { coPayCoIns: ValueType.DOLLAR, value: undefined } },
              };
            }
          }),
        };
      }
      case "add claim range": {
        const claimRanges: ClaimRanges = (action.row.kind === "list"
          ? state.costShare.lists[action.row.listId]
          : state.costShare.formularyTiers[action.row.formularyTierId]
        ).networkTiers[action.networkId].daySupplyRanges[action.daySupplyRangeStartingValue]
          .claimRanges;

        const largestClaimRangeStartingCost = Object.keys(claimRanges).reduce<number>(
          (highestRangeStartingValue, currentRangeStartingValue) => {
            const parsedStartingValue = parseFloat(currentRangeStartingValue);
            return highestRangeStartingValue < parsedStartingValue
              ? parsedStartingValue
              : highestRangeStartingValue;
          },
          0
        );

        const newClaimRangeStartingCost = largestClaimRangeStartingCost + 100;

        return {
          ...state,
          costShare: produce(state.costShare, (draft) => {
            const claimRanges = (action.row.kind === "list"
              ? draft.lists[action.row.listId]
              : draft.formularyTiers[action.row.formularyTierId]
            ).networkTiers[action.networkId].daySupplyRanges[action.daySupplyRangeStartingValue]
              .claimRanges;

            claimRanges[newClaimRangeStartingCost] = { coPayCoIns: ValueType.DOLLAR };
          }),
        };
      }
      case "delete day supply range": {
        const updatedCostShare = produce(costShare, (draft) => {
          for (const formularyTierId in draft.formularyTiers) {
            delete draft.formularyTiers[formularyTierId].networkTiers[action.networkTierId]
              .daySupplyRanges[action.daySupplyRangeStartingValue];
          }
          for (const listId in draft.lists) {
            delete draft.lists[listId].networkTiers[action.networkTierId].daySupplyRanges[
              action.daySupplyRangeStartingValue
            ];
          }
        });
        return {
          ...state,
          costShare: updatedCostShare,
          daySupplyRangeValues: parseDaySupplyRangeValues(updatedCostShare, state.formularyTiers),
        };
      }
      case "delete claim range": {
        return {
          ...state,
          costShare: produce(state.costShare, (draft) => {
            if (action.row.kind === "list") {
              delete draft.lists[action.row.listId].networkTiers[action.networkTierId]
                .daySupplyRanges[action.daySupplyRangeStartingValue].claimRanges[
                action.claimRangeStartingCost
              ];
            } else {
              delete draft.formularyTiers[action.row.formularyTierId].networkTiers[
                action.networkTierId
              ].daySupplyRanges[action.daySupplyRangeStartingValue].claimRanges[
                action.claimRangeStartingCost
              ];
            }
          }),
        };
      }
      case "prepare cost share for save": {
        const cleanedCostShare = cleanCostShare(costShare);
        return {
          ...state,
          costShare: cleanedCostShare,
          daySupplyRangeValues: parseDaySupplyRangeValues(cleanedCostShare, state.formularyTiers),
          status: "saving",
        };
      }
      case "set loaded cost share data": {
        return {
          ...state,
          costShare: action.costShare,
          formularyTiers: action.formularyTiers,
          networkTiers: action.networkTiers,
          benefitsLists: action.benefitsList,
        };
      }
      case "reconcile cost share": {
        return {
          ...state,
          status: "reconciled",
          costShare: reconcileCostShareChanges(
            state.formularyTiers,
            state.networkTiers,
            state.costShare
          ),
        };
      }
      case "done saving": {
        return { ...state, status: "done" };
      }
      case "toggle day supply range status": {
        return {
          ...state,
          costShare: {
            ...state.costShare,
            isUsingDaySupplyRanges: !state.costShare.isUsingDaySupplyRanges,
          },
        };
      }
      case "toggle claim ranges": {
        return {
          ...state,
          costShare: {
            ...state.costShare,
            isUsingClaimRanges: !state.costShare.isUsingClaimRanges,
          },
        };
      }
      case "update OON cell value": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            const row = getRow(action.rowActionKind, draft);
            if (row.outOfNetworkPenalty) {
              row.outOfNetworkPenalty.value = action.newValue;
            }
          }),
        };
      }
      case "update OON value type": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            const row = getRow(action.rowActionKind, draft);
            if (row.outOfNetworkPenalty) {
              row.outOfNetworkPenalty.valueType = action.newValueType;
            } else {
              row.outOfNetworkPenalty = {
                valueType: action.newValueType,
                value: undefined,
              };
            }
          }),
        };
      }
      case "update day supply range end value": {
        return {
          ...state,
          daySupplyRangeValues: produce(daySupplyRangeValues, (draft) => {
            const daySupplyRangeValueKey = daySupplyRangeKey(
              action.networkTierId,
              action.startingValue
            );
            const daySupplyRange = draft.get(daySupplyRangeValueKey);
            if (daySupplyRange) {
              draft.set(daySupplyRangeValueKey, {
                ...daySupplyRange,
                endValue: action.newEndValue,
              });
            }
          }),
        };
      }
      case "update day supply ranges": {
        const updatedDaySupplyRange = daySupplyRangeValues.get(
          daySupplyRangeKey(action.networkTierId, action.daySupplyRangeStartingValue)
        );

        if (updatedDaySupplyRange && updatedDaySupplyRange.endValue) {
          const rangeStartingValues = daySupplyRangeStartingValues(
            costShare,
            action.networkTierId,
            state.formularyTiers
          );
          const editedRangeIndex = rangeStartingValues.findIndex(
            (startingVal) => startingVal === action.daySupplyRangeStartingValue
          );
          const newRangeVal = updatedDaySupplyRange.endValue;
          if (newRangeVal !== rangeStartingValues[editedRangeIndex + 1] - 1) {
            const updatedCostShare = produce(costShare, (draft) => {
              for (const formularyTierId in draft.formularyTiers) {
                updateDaySupplyRanges(
                  daySupplyRangeStartingValues(draft, action.networkTierId, state.formularyTiers, {
                    kind: "formulary tier",
                    formularyTierId,
                  }),
                  action.daySupplyRangeStartingValue,
                  draft.formularyTiers[formularyTierId].networkTiers[action.networkTierId]
                );
              }

              for (const listId in draft.lists) {
                updateDaySupplyRanges(
                  daySupplyRangeStartingValues(draft, action.networkTierId, state.formularyTiers, {
                    kind: "list",
                    listId,
                  }),
                  action.daySupplyRangeStartingValue,
                  draft.lists[listId].networkTiers[action.networkTierId]
                );
              }

              function updateDaySupplyRanges(
                currentRangeStartingValues: Array<number>,
                daySupplyRangeStartingValue: number,
                networkTier: { daySupplyRanges: DaySupplyRanges }
              ) {
                // TODO - wrap check here for security if not found...also rename
                const indexOfEditedRangeStartingValue = currentRangeStartingValues.findIndex(
                  (startingRangeValue) => startingRangeValue === daySupplyRangeStartingValue
                );

                const rangesAfterUpdatedRangeInclusive = currentRangeStartingValues.slice(
                  indexOfEditedRangeStartingValue
                );

                const { daySupplyRanges } = networkTier;

                const nextRangeNewStartingValue = newRangeVal + 1;
                daySupplyRanges[newRangeVal + 1] =
                  daySupplyRanges[rangesAfterUpdatedRangeInclusive[1]];
                delete daySupplyRanges[rangesAfterUpdatedRangeInclusive[1]];

                if (
                  rangesAfterUpdatedRangeInclusive.length >= 3 &&
                  nextRangeNewStartingValue >= rangesAfterUpdatedRangeInclusive[2]
                ) {
                  let previousRangeNewStartingValue: number = nextRangeNewStartingValue;
                  for (let i = 2; i < rangesAfterUpdatedRangeInclusive.length; i++) {
                    const currentRangeStartingValue = rangesAfterUpdatedRangeInclusive[i];
                    const diff =
                      currentRangeStartingValue - rangesAfterUpdatedRangeInclusive[i - 1];
                    const newStartingValue = previousRangeNewStartingValue + diff;
                    daySupplyRanges[newStartingValue] = daySupplyRanges[currentRangeStartingValue];
                    delete daySupplyRanges[currentRangeStartingValue];
                    previousRangeNewStartingValue = newStartingValue;
                  }
                }
              }
            });

            return {
              ...state,
              costShare: updatedCostShare,
              daySupplyRangeValues: parseDaySupplyRangeValues(
                updatedCostShare,
                state.formularyTiers
              ),
            };
          }
        }
        return { ...state };
      }
      case "update claim ranges": {
        const updatedCostShare = produce(state.costShare, (draft) => {
          const { updatedClaimRangeStartCost, updatedEndCost } = action;

          const daySupplyRange = (action.row.kind === "list"
            ? draft.lists[action.row.listId]
            : draft.formularyTiers[action.row.formularyTierId]
          ).networkTiers[action.networkTierId].daySupplyRanges[action.daySupplyRangeStartValue];
          const claimRanges: ClaimRanges = daySupplyRange.claimRanges;

          const rangeStartingCosts: Array<number> = Object.keys(claimRanges)
            .map((claimRangeStartCost) => parseFloat(claimRangeStartCost))
            .sort((startCostA, startCostB) => startCostA - startCostB);

          if (updatedClaimRangeStartCost < updatedEndCost) {
            const positionOfEdited = rangeStartingCosts.findIndex(
              (startingValue) => startingValue === action.updatedClaimRangeStartCost
            );
            if (updatedEndCost !== rangeStartingCosts[positionOfEdited + 1]) {
              // If updated end value is between old range start value and next range start value, then only need to adjust adjacent value
              if (updatedEndCost < rangeStartingCosts[positionOfEdited + 1]) {
                claimRanges[updatedEndCost] = claimRanges[rangeStartingCosts[positionOfEdited + 1]];
                delete claimRanges[rangeStartingCosts[positionOfEdited + 1]];
              } else {
                // If updated end value is above old range start value but above unknown number of ranges, then need to adjust a number of ranges
                const updatedClaimRanges: ClaimRanges = {};
                rangeStartingCosts.slice(0, positionOfEdited + 1).forEach((startingCost) => {
                  updatedClaimRanges[startingCost] = claimRanges[startingCost];
                });

                let nextRangeStartingCost = updatedEndCost;
                for (let i = positionOfEdited + 1; i < rangeStartingCosts.length; i++) {
                  const currentRangeStartingCost = rangeStartingCosts[i];
                  updatedClaimRanges[nextRangeStartingCost] = claimRanges[currentRangeStartingCost];
                  if (i < rangeStartingCosts.length - 1) {
                    const diff = rangeStartingCosts[i + 1] - currentRangeStartingCost;
                    nextRangeStartingCost = nextRangeStartingCost + diff;
                  }
                }
                daySupplyRange.claimRanges = updatedClaimRanges;
              }
            }
          }
        });

        return {
          ...state,
          costShare: updatedCostShare,
        };
      }
      case "update max cell value": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            const row = getRow(action.rowActionKind, draft);
            const current =
              row.networkTiers[action.networkTierId].daySupplyRanges[
                action.daySupplyRangeStartingValue
              ].claimRanges[action.claimRangeStartingCost];
            if (current.coPayCoIns === ValueType.PERCENTAGE) {
              current.max = action.newValue;
            }
          }),
        };
      }
      case "update min cell value": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            const row = getRow(action.rowActionKind, draft);
            const current =
              row.networkTiers[action.networkTierId].daySupplyRanges[
                action.daySupplyRangeStartingValue
              ].claimRanges[action.claimRangeStartingCost];
            if (current.coPayCoIns === ValueType.PERCENTAGE) {
              current.min = action.newValue;
            }
          }),
        };
      }
      case "update value cell value": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            const row = getRow(action.rowActionKind, draft);
            row.networkTiers[action.networkTierId].daySupplyRanges[
              action.daySupplyRangeStartingValue
            ].claimRanges[action.claimRangeStartingCost].value = action.newValue;
          }),
        };
      }
      case "update value cell value type": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            const row = getRow(action.rowActionKind, draft);
            row.networkTiers[action.networkTierId].daySupplyRanges[
              action.daySupplyRangeStartingValue
            ].claimRanges[action.claimRangeStartingCost].coPayCoIns = action.newValueType;
          }),
        };
      }
      case "toggle formulary tier row": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            draft.formularyTiers[action.formularyTierId].isEnabled = !draft.formularyTiers[
              action.formularyTierId
            ].isEnabled;
          }),
        };
      }
      case "add list": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            if (state.costShare) {
              draft.lists[action.list.id] = createEmptyRow(
                state.networkTiers,
                state.costShare,
                state.formularyTiers
              );
            }
          }),
          benefitsLists: state.benefitsLists.some(
            (benefitsList) => benefitsList.id === action.list.id
          )
            ? state.benefitsLists
            : [...state.benefitsLists, action.list],
        };
      }
      case "delete list row": {
        return {
          ...state,
          costShare: produce(costShare, (draft) => {
            delete draft.lists[action.listId];
          }),
        };
      }
      default:
        return state;
    }
  } else if (action.type === "set loaded cost share data") {
    return {
      ...state,
      costShare: action.costShare,
      status: hasToReconcileChanges(action.costShare, action.formularyTiers)
        ? "need to reconcile"
        : "done",
      formularyTiers: action.formularyTiers,
      networkTiers: action.networkTiers,
      daySupplyRangeValues: parseDaySupplyRangeValues(action.costShare, action.formularyTiers),
    };
  } else if (action.type === "error") {
    return { ...state, status: "error" };
  } else {
    return state;
  }
}

function parseDaySupplyRangeValues(
  costShare: CostShare,
  formularyTiers: Array<FormularyTier>
): Map<string, DaySupplyRangeValues> {
  const formularyTierRow = firstFormularyTierRow(costShare, formularyTiers);
  const daySupplyRangeValues: Map<string, DaySupplyRangeValues> = new Map();
  if (formularyTierRow) {
    Object.keys(formularyTierRow.networkTiers).forEach((networkTierId) => {
      const networkTier = formularyTierRow.networkTiers[networkTierId];
      const daySupplyRangeStartingValues = Object.keys(
        networkTier.daySupplyRanges
      ).map((rangeValue) => parseInt(rangeValue));
      daySupplyRangeStartingValues.forEach((daySupplyRangeStartingValue, index) => {
        daySupplyRangeValues.set(daySupplyRangeKey(networkTierId, daySupplyRangeStartingValue), {
          startValue: daySupplyRangeStartingValue,
          endValue:
            index + 1 < daySupplyRangeStartingValues.length
              ? daySupplyRangeStartingValues[index + 1] - 1
              : undefined,
        });
      });
    });
  }

  return daySupplyRangeValues;
}

export function daySupplyRangeKey(networkTierId: string, daySupplyRangeStartingValue: number) {
  return `${networkTierId}-${daySupplyRangeStartingValue}`;
}

function hasToReconcileChanges(
  costShare: CostShare,
  formularyTiers: Array<FormularyTier>
): boolean {
  const currentCostShareFormularyTierIds = new Set(Object.keys(costShare.formularyTiers));
  return (
    formularyTiers.length !== currentCostShareFormularyTierIds.size ||
    formularyTiers.some(
      (formularyTier) => currentCostShareFormularyTierIds.has(formularyTier.id) === false
    )
  );
}

function reconcileCostShareChanges(
  formularyTiers: Array<FormularyTier>,
  networkTiers: Array<EditNetworkTier>,
  costShare: CostShare
): CostShare {
  return produce(costShare, (draft) => {
    const formularyTierIds = new Set(formularyTiers.map((tier) => tier.id));
    // remove tiers that are not present on the formulary
    for (const costShareFormularyTierId in draft.formularyTiers) {
      if (formularyTierIds.has(costShareFormularyTierId)) {
        formularyTierIds.delete(costShareFormularyTierId);
      } else {
        delete draft.formularyTiers[costShareFormularyTierId];
      }
    }

    // Add additional tiers
    if (formularyTierIds.size > 0) {
      const emptyCopyOfExistingDaySupplyRanges = (formularyTierId: string) => (
        networkTier: EditNetworkTier
      ): DaySupplyRanges =>
        daySupplyRangeStartingValues(draft, networkTier.id, formularyTiers, {
          kind: "formulary tier",
          formularyTierId,
        }).reduce<DaySupplyRanges>(
          (daySupplyRanges, daySupplyRangeStartingVal) => ({
            ...daySupplyRanges,
            [daySupplyRangeStartingVal]: EMPTY_CLAIM_RANGES,
          }),
          {}
        );

      const currentFormularyTierIds = Object.keys(draft.formularyTiers);
      const networkTierColumns = emptyNetworkTierColumns(
        networkTiers,
        currentFormularyTierIds.length > 0
          ? emptyCopyOfExistingDaySupplyRanges(currentFormularyTierIds[0])
          : startingDaySupplyRanges
      );

      for (const remainingActualFormularyTierId of formularyTierIds) {
        draft.formularyTiers[remainingActualFormularyTierId] = emptyFormularyTierRow(
          networkTierColumns
        );
      }
    }
  });
}
