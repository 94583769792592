import { Input, InputAdornment, makeStyles } from "@material-ui/core";
import * as React from "react";

const useInputCellStyles = makeStyles({
  inputCell: {
    width: "3rem",
  },
});

interface InputCellProps {
  value?: number;
  disabled?: boolean;
  width?: string;
  onValueChange: (newValue: number) => void;
  onBlur?: () => void;
}

export function DollarInputCell(props: InputCellProps): JSX.Element {
  const classes = useInputCellStyles();

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    props.onValueChange(parseFloat(event.target.value));
  }

  return (
    <Input
      type="number"
      inputProps={DOLLAR_VALIDATION_PROPS}
      disabled={props.disabled}
      className={classes.inputCell}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      value={props.value ? props.value : ""}
      onChange={onChange}
      onBlur={props.onBlur}
      style={{ width: props.width }}
    />
  );
}
const DOLLAR_VALIDATION_PROPS = { min: 0, step: 0.01 };

export function PercentageInputCell(props: InputCellProps): JSX.Element {
  const classes = useInputCellStyles();

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    props.onValueChange(parseFloat(event.target.value));
  }

  return (
    <Input
      type="number"
      inputProps={PERCENTAGE_VALIDATION_PROPS}
      disabled={props.disabled}
      className={classes.inputCell}
      endAdornment={<InputAdornment position="end">%</InputAdornment>}
      value={props.value ?? ""}
      onChange={onChange}
      onBlur={props.onBlur}
    />
  );
}
const PERCENTAGE_VALIDATION_PROPS = { min: 0, step: 1 };
