import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { PdfPreview } from "../../components/pdfPreview/PdfPreview";
import { TEMPLATE_PRIMARY_COLOR } from "../../styles/Theme";
import { useCriteriaTemplatePreview } from "./api";
import { CriteriaTemplateList } from "./CriteriaTemplateList";
import { CriteriaTemplate } from "./types";

const useStyles = makeStyles({
  previewWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gridColumn: "span 12",
  },
});

export const CriteriaTemplatesListPage: React.FC = () => {
  const classes = useStyles();

  const [selectedTemplateId, setSelectedTemplateId] = React.useState<string | undefined>(undefined);

  const { previewCriteriaTemplate, previewStatus } = useCriteriaTemplatePreview(
    <Typography variant="h5">
      Select a <span style={{ color: TEMPLATE_PRIMARY_COLOR }}>Template</span> on the left to see a
      preview of its layout.
    </Typography>
  );

  const handleSelectTemplate = (template: CriteriaTemplate) => {
    setSelectedTemplateId(template.id);
    previewCriteriaTemplate(template);
  };

  return (
    <>
      <CriteriaTemplateList
        selectedTemplateId={selectedTemplateId}
        onSelectTemplate={handleSelectTemplate}
      />
      <div className={classes.previewWrapper}>
        <PdfPreview status={previewStatus} style={{ gridColumn: "5 / 17" }} />
      </div>
    </>
  );
};
