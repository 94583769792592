import { Editor } from "@tinymce/tinymce-react";
import * as React from "react";
import { enableNestedNativeDialogs } from "../../../components/dialogs/DialogUtils";
import { TinyMCE5Editor } from "../../../components/wysiwygs/types";
import { TINY_MCE_API_KEY } from "../../../components/wysiwygs/TinyMCEUtilts";

type Props = {
  content: string; // todo - decode from the source into string
  onUpdateContent: (content: string) => void;
};

type State = {
  tinyMCEEditor?: TinyMCE5Editor;
};

export class WebSearchTextEditor extends React.Component<Props, State> {
  state: State = {
    tinyMCEEditor: undefined,
  };

  setupEditor = (editor: TinyMCE5Editor) => {
    this.setState({ tinyMCEEditor: editor });
  };

  handleContentChange = (editorContent: string) => {
    this.props.onUpdateContent(editorContent);
  };

  componentDidMount() {
    enableNestedNativeDialogs();
  }

  render() {
    return (
      <Editor
        id="header-footer-editor"
        apiKey={TINY_MCE_API_KEY}
        init={{
          height: "100%",
          menubar: false,
          statusbar: true,
          branding: false,
          elementpath: false,
          plugins: "advlist lists link paste",
          paste_retain_style_properties: "font font-style font-family font-weight font-size",
          toolbar:
            "undo redo | bold italic underline strikethrough | link | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | removeformat",
          images_upload_credentials: true,
          setup: this.setupEditor as any,
        }}
        initialValue={this.props.content}
        onEditorChange={this.handleContentChange}
      />
    );
  }
}
