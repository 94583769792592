import { useMutation } from "@apollo/client";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  CreateFormularyTemplateInput,
  CREATE_FORMULARY_TEMPLATE,
  SaveFormularyTemplateResponse,
} from "./api";
import { DEFAULT_STARTING_TEMPLATE, FORMULARY_TEMPLATE_LIST_PAGE } from "./constants";
import { FormularyTemplateContainer } from "./FormularyTemplatePageContainer";
import { FullFormularyTemplate, toApiFormularyTemplateSettings } from "./types";

export const CreateFormularyTemplatePage: React.FC = () => {
  const history = useHistory();
  const [createFormularyTemplate, { loading: isSaving }] = useMutation<
    SaveFormularyTemplateResponse,
    CreateFormularyTemplateInput
  >(CREATE_FORMULARY_TEMPLATE);

  const saveTemplate = (template: FullFormularyTemplate) => {
    createFormularyTemplate({
      variables: {
        name: template.name,
        settings: toApiFormularyTemplateSettings(template.settings),
      },
    }).then(() => {
      history.push(FORMULARY_TEMPLATE_LIST_PAGE);
    });
  };

  return (
    <FormularyTemplateContainer
      saveTemplate={saveTemplate}
      templateStatus={
        isSaving
          ? { status: "saving", template: DEFAULT_STARTING_TEMPLATE }
          : { status: "available", template: DEFAULT_STARTING_TEMPLATE }
      }
    />
  );
};
