import {
  IconButton,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import * as React from "react";
import { OptionsTableSetting } from "./OptionTableTypes";
import classnames from "classnames";
import { UserContext } from "../UserContext";

const useStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    border: 0,
  },
  alignRight: {
    textAlign: "right",
  },
  helpButton: {
    marginLeft: theme.spacing(1),
  },
  popover: {
    padding: theme.spacing(2),
    minWidth: 200,
    maxWidth: 500,
    minHeight: 50,
  },
  popoverHeader: {
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  settings: Array<OptionsTableSetting>;
  noHover?: boolean;
  disabled?: boolean;
  labelWidth?: "10%" | "20%" | "30%" | "40%" | "50%" | "60%" | "70%" | "80%" | "90%";
  splitAlignment?: boolean;
}

export const OptionsTable: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [helpText, setHelpText] = React.useState<string>();

  const handleHelpClick = (helpText: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setHelpText(helpText);
  };

  const handleHelpClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Table size={contentSpacing === "dense" ? "small" : "medium"}>
        <TableBody>
          {props.settings.map((setting, index) => (
            <TableRow key={index} hover={!props.noHover || !props.disabled}>
              <TableCell
                id="label"
                className={classes.tableCell}
                style={{ width: props.labelWidth ? props.labelWidth : "50%" }}
              >
                <Typography style={{ opacity: props.disabled ? 0.5 : 1, display: "inline" }}>
                  {setting.label}
                </Typography>
                {setting.helpText && (
                  <IconButton
                    size="small"
                    className={classes.helpButton}
                    onClick={handleHelpClick(setting.helpText)}
                  >
                    <HelpIcon style={{ opacity: 0.5 }} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell
                className={classnames(classes.tableCell, {
                  [classes.alignRight]: Boolean(props.splitAlignment),
                })}
              >
                {setting.options}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleHelpClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <Typography color="primary" variant="body2" className={classes.popoverHeader}>
          What is this?
        </Typography>
        <Typography>{helpText}</Typography>
      </Popover>
    </>
  );
};
