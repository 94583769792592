import { useSubscription } from "@apollo/client";
import {
  CircularProgress,
  fade,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { FileSettingsIcon, WebSearchSettingsIcon } from "../../../../components/Icons/customIcons";
import { UserContext } from "../../../../components/UserContext";
import { TEMPLATE_TYPE_USER_NAMES } from "../../../../types/ApiTypes";
import { openURLInNewTab } from "../../../../utils/Browser";
import { basePath } from "../../../../utils/Files";
import {
  SubscribeToDocumentRenderUpdatesInput,
  SubscribeToDocumentRenderUpdatesResponse,
  SUBSCRIBE_TO_DOCUMENT_RENDER,
} from "../api";
import { ApiFormulary } from "../types";
import {
  ApiFullFormularyDocument,
  ApiWebSearchSettings,
  DocumentRenderStatus,
  WebSearchSettingsForm,
} from "./DocumentTypes";
import webSearchBackdrop from "./webSearchButtonBG.svg";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    display: "flex",
    flexDirection: "column",
  },
  documentList: {
    background: theme.palette.common.white,
    height: "100%",
  },
  documentListItem: {
    height: "4em",
  },
  documentListHover: {
    "&:hover $settingsButton": {
      opacity: 1,
    },
  },
  selectedListItem: {
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  notSelectedListItem: {
    color: theme.palette.common.black,
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  datePicker: {
    height: "5em",
    justifyContent: "center",
  },
  webSearchItem: {
    background: `no-repeat url(${webSearchBackdrop})`,
    backgroundPositionX: "90%",
    backgroundSize: "contain",
  },
  loading: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  noDocuments: {
    textAlign: "center",
    color: fade(theme.palette.text.primary, 0.5),
    backgroundColor: fade(theme.palette.common.black, 0.1),
    fontStyle: "italic",
  },
  settingsButton: {
    opacity: 0,
  },
  white: {
    color: "white",
  },
}));

/**
 * TODO
 *
 *  work through PublishingDocument type and assess for compat w/ new types
 */

interface Props {
  formulary: ApiFormulary;
  selectedDocumentId: string | undefined;
  documents: Array<ApiFullFormularyDocument>;
  webSearchSettings: ApiWebSearchSettings | null;
  isLoading: boolean;
  isPublishing: boolean;
  onSelectDocument: (selectedDocument: ApiFullFormularyDocument) => void;
  onClickDocumentSettings: (document: ApiFullFormularyDocument) => void;
  onEditWebSearchSettings: (webSearchSettings: WebSearchSettingsForm) => void;
  onDocumentStatusChange: (documentId: string, renderStatus: DocumentRenderStatus) => void;
}

export const DocumentList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  const handleClickWebSearchSettings = () => {
    if (props.webSearchSettings === null) {
      props.onEditWebSearchSettings({ name: "" });
    } else {
      openURLInNewTab(`${basePath(window.location.host)}/search/${props.formulary.id}`);
    }
  };

  const handleSelectEditWebSearchSettings = () => {
    if (props.webSearchSettings) {
      props.onEditWebSearchSettings({
        name: props.webSearchSettings.name,
        id: props.webSearchSettings.id,
        template: {
          id: props.webSearchSettings.settings.template.id,
          name: props.webSearchSettings.settings.template.name,
        },
      });
    }
  };

  const isWebSearchSelected = false;

  return (
    <Paper square elevation={0} className={classes.paperRoot}>
      <List dense={contentSpacing === "dense"} className={classes.documentList} disablePadding>
        {props.isLoading ? (
          <ListItem className={classes.documentListItem}>
            <ListItemText primary="Loading..." style={{ fontStyle: "italic" }} />
            <ListItemSecondaryAction>
              <CircularProgress />
            </ListItemSecondaryAction>
          </ListItem>
        ) : (
          <>
            {props.webSearchSettings && (
              <ListItem
                button
                key="webSearch"
                disabled={props.isPublishing}
                selected={isWebSearchSelected}
                className={
                  props.webSearchSettings
                    ? classNames(classes.documentListItem, classes.webSearchItem)
                    : classes.documentListItem
                }
                ContainerProps={
                  props.webSearchSettings ? { className: classes.documentListHover } : undefined
                }
                onClick={handleClickWebSearchSettings}
                classes={{ selected: classes.selectedListItem }}
              >
                <ListItemText
                  primary={props.webSearchSettings?.name}
                  primaryTypographyProps={{ color: "inherit" }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    className={classes.settingsButton}
                    onClick={handleSelectEditWebSearchSettings}
                  >
                    <WebSearchSettingsIcon htmlColor={false ? "white" : "inherit"} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
            {props.documents.length > 0 ? (
              props.documents.map((document) => (
                <DocumentListItem
                  key={document.id}
                  document={document}
                  isSelected={props.selectedDocumentId === document.id}
                  isPublishing={props.isPublishing}
                  onClickDocumentSettings={props.onClickDocumentSettings}
                  onSelectDocument={props.onSelectDocument}
                  onDocumentStatusChange={props.onDocumentStatusChange}
                />
              ))
            ) : (
              <ListItem
                className={classNames(classes.documentListItem, classes.noDocuments)}
                key="noDocs"
              >
                <ListItemText
                  primary="No Documents"
                  primaryTypographyProps={{
                    color: "inherit",
                  }}
                />
              </ListItem>
            )}
          </>
        )}
      </List>
    </Paper>
  );
};

interface DocumentListItemProps {
  document: ApiFullFormularyDocument;
  isSelected: boolean;
  isPublishing: boolean;
  onSelectDocument: (document: any) => void;
  onClickDocumentSettings: (document: any) => void;
  onDocumentStatusChange: (documentId: string, renderStatus: DocumentRenderStatus) => void;
}

const DocumentListItem: React.FC<DocumentListItemProps> = ({
  document,
  isSelected,
  isPublishing,
  onSelectDocument,
  onClickDocumentSettings,
  onDocumentStatusChange,
}) => {
  const classes = useStyles();

  const { data } = useSubscription<
    SubscribeToDocumentRenderUpdatesResponse,
    SubscribeToDocumentRenderUpdatesInput
  >(SUBSCRIBE_TO_DOCUMENT_RENDER, {
    variables: { documentId: document.id },
    onSubscriptionData: (res) => {
      if (res.subscriptionData.data?.documentRenderUpdates) {
        onDocumentStatusChange(document.id, res.subscriptionData.data.documentRenderUpdates.status);
      }
    },
  });

  const handleSelectDocument = () => {
    onSelectDocument(document);
  };
  const handleClickDocumentSettings = () => {
    onClickDocumentSettings(document);
  };

  const isRendering: boolean =
    data?.documentRenderUpdates.status !== undefined &&
    data.documentRenderUpdates.status !== DocumentRenderStatus.FAILED &&
    data.documentRenderUpdates.status !== DocumentRenderStatus.DONE &&
    data.documentRenderUpdates.status !== DocumentRenderStatus.NOT_COPIED;

  return (
    <ListItem
      button
      disabled={isPublishing}
      selected={isSelected}
      className={classes.documentListItem}
      ContainerProps={{ className: classes.documentListHover }}
      key={document.id}
      onClick={handleSelectDocument}
      classes={{ selected: classes.selectedListItem }}
    >
      <ListItemText
        primary={document.name}
        secondary={TEMPLATE_TYPE_USER_NAMES[document.settings.template.templateType]}
        primaryTypographyProps={{
          color: "inherit",
        }}
        secondaryTypographyProps={{
          color: isSelected ? "inherit" : "textSecondary",
          style: { textTransform: "capitalize" },
        }}
      />
      <ListItemSecondaryAction>
        {isRendering ? (
          <CircularProgress
            size="1.5rem"
            color={isSelected ? "secondary" : "primary"}
            classes={{ colorSecondary: classes.white }}
          />
        ) : (
          <IconButton className={classes.settingsButton} onClick={handleClickDocumentSettings}>
            <FileSettingsIcon htmlColor={isSelected ? "white" : "inherit"} />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
