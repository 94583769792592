import {
  Button,
  FormControlLabel,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import cn from "classnames";
import * as React from "react";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { FormularyTier } from "../../Flex/Formularies/FormularyPage/types";
import { EditNetworkTier } from "../../Network/api";
import { CostShareAction, daySupplyRangeKey, DaySupplyRangeValues } from "./CostShareReducer";
import {
  CostShare,
  daySupplyRangeStartingValues,
  FormularyTierRow,
  ListRow,
  numberOfDaySupplyRangesOnNetworkTier,
  RowKind,
  ValueType,
} from "./CostShareSchema";
import { DollarInputCell, PercentageInputCell } from "./CostShareTableInputCells";
import { CostShareValueCell } from "./CostShareValueCell";
import { CustomDrugListControls } from "./CustomDrugListControls";
import "./CustomNumberInput.css";

const useInternalTableStyles = makeStyles({
  tableContainer: {
    width: "fit-content",
    maxWidth: "100%",
    margin: "0 auto",
    position: "relative",
    overflow: "auto",
  },
  tableRoot: {
    maxWidth: "100%",
    width: "fit-content",
  },
  tableControlContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  daySupplyRangeSwitch: {
    marginRight: "2rem",
  },
});

const useTableCellStyles = makeStyles({
  cell: {
    maxWidth: "61px",
  },
  formularyCellRoot: {
    minWidth: "10rem",
    borderRight: "1px solid grey",
    padding: 0,
  },
  minCellBorder: {
    borderLeft: "1px solid lightgrey",
    borderRight: "1px solid lightgrey",
  },
  maxCellBorder: {
    borderRight: "1px solid lightgrey",
  },
  noBottomBorder: {
    borderBottom: "none",
  },
  stickyCell: {
    position: "sticky",
    left: "0px",
    backgroundColor: "white",
    zIndex: 100,
  },
  tableCellContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  tableCell: {
    width: "3rem",
  },
  tableCellPadding: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  tableCellNoBottomPadding: {
    paddingBottom: 0,
  },
  tableCellNoPadding: {
    padding: "0 !important",
  },
  sectionSeparatorBorder: {
    borderRight: "1px solid grey",
  },
});

const FORMULARY_TIER_COL_SPAN = 3;
const FULL_NETWORK_SECTION_COL_SPAN = 3;
const FULL_NETWORK_WITH_CLAIM_RANGES = 7;
const CLAIM_RANGE_COLUMN_SPAN = 2;
const CLAIM_RANGE_ACTION_COLUMN_SPAN = 1;
const OON_COL_SPAN = 1;
const VALUE_SUB_HEADER_COL_SPAN = 1;

interface CostShareDataGridProps {
  costShare: CostShare;
  daySupplyRangeValues: Map<string, DaySupplyRangeValues>;
  formularyTiers: Array<FormularyTier>;
  networkTiers: Array<EditNetworkTier>;
  benefitsLists: Array<{ id: string; name: string }>;
  readonly?: boolean;
  updateCostShare: React.Dispatch<CostShareAction>;
}

type ActiveValueType =
  | { anchorElement: null }
  | { anchorElement: HTMLElement; selectedCellInfo: SelectedCellInfo };

type SelectedCellInfo =
  | {
      kind: "value cell";
      rowKind: RowKind;
      networkTierId: string;
      daySupplyRangeStartingValue: number;
      claimRangeStartingCost: number;
    }
  | { kind: "oon cell"; rowKind: RowKind };

type DeleteDialog = { open: false } | { open: true; listId: string };

/**
 * TODO - Later (cleanup and improvements)
 *  header table row component since so much of it is common and repeated
 *  column widths could calculated and passed around as props?
 *  cleanup CostShare DS updates and access
 *  add error dialog
 *  further reduce spacing and improve density where possible
 */

export function CostShareDataGrid({
  costShare,
  daySupplyRangeValues,
  updateCostShare,
  formularyTiers,
  networkTiers,
  benefitsLists,
  readonly,
}: CostShareDataGridProps): JSX.Element {
  const classes = useInternalTableStyles();
  const tableCellClasses = useTableCellStyles();

  const [activeValueType, setActiveValueType] = React.useState<ActiveValueType>({
    anchorElement: null,
  });
  const [deleteDialog, setDeleteDialog] = React.useState<DeleteDialog>({ open: false });

  function handleAddDaySupplyRange(headerNetworkTier: EditNetworkTier) {
    updateCostShare({ type: "add day supply range", networkTier: headerNetworkTier });
  }

  function handleDeleteDaySupplyRange(networkTierId: string, daySupplyRangeStartingValue: number) {
    updateCostShare({
      type: "delete day supply range",
      daySupplyRangeStartingValue,
      networkTierId,
    });
  }

  function handleUpdateDaySupplyRangeEndValue(
    networkTierId: string,
    startingValue: number,
    newEndValue: number
  ) {
    updateCostShare({
      type: "update day supply range end value",
      networkTierId,
      newEndValue,
      startingValue,
    });
  }

  function handleUpdateDaySupplyRanges(networkTierId: string, daySupplyRangeStartingValue: number) {
    updateCostShare({
      type: "update day supply ranges",
      daySupplyRangeStartingValue,
      networkTierId,
    });
  }

  function handleValueTypeMenuSelect(valueType: ValueType) {
    return function updateValueType(): void {
      if (activeValueType.anchorElement !== null) {
        if (activeValueType.selectedCellInfo.kind === "value cell") {
          updateCostShare({
            type: "update value cell value type",
            rowActionKind: activeValueType.selectedCellInfo.rowKind,
            networkTierId: activeValueType.selectedCellInfo.networkTierId,
            daySupplyRangeStartingValue:
              activeValueType.selectedCellInfo.daySupplyRangeStartingValue,
            claimRangeStartingCost: activeValueType.selectedCellInfo.claimRangeStartingCost,
            newValueType: valueType,
          });
        } else {
          updateCostShare({
            type: "update OON value type",
            rowActionKind: activeValueType.selectedCellInfo.rowKind,
            newValueType: valueType,
          });
        }
      }

      setActiveValueType({ anchorElement: null });
    };
  }

  function handleSelectToUpdateOONValueType(
    rowKind: RowKind,
    currentTarget: EventTarget & HTMLButtonElement
  ) {
    setActiveValueType({
      anchorElement: currentTarget,
      selectedCellInfo: {
        kind: "oon cell",
        rowKind,
      },
    });
  }

  function handleSelectToUpdateValueCellType(
    rowKind: RowKind,
    networkTierId: string,
    daySupplyRangeStartingValue: number,
    claimRangeStartingCost: number,
    currentTarget: EventTarget & HTMLButtonElement
  ) {
    setActiveValueType({
      anchorElement: currentTarget,
      selectedCellInfo: {
        kind: "value cell",
        claimRangeStartingCost,
        daySupplyRangeStartingValue,
        rowKind,
        networkTierId,
      },
    });
  }

  function handleCloseMenu(): void {
    setActiveValueType({ anchorElement: null });
  }

  function handleToggleDaySupplyRange(): void {
    updateCostShare({ type: "toggle day supply range status" });
  }

  function handleToggleClaimRanges(): void {
    updateCostShare({ type: "toggle claim ranges" });
  }

  function handleListRowClickDelete(listId: string) {
    setDeleteDialog({ open: true, listId });
  }
  function closeDeleteListRowDialog() {
    setDeleteDialog({ open: false });
  }
  function deleteListRow() {
    if (deleteDialog.open) {
      updateCostShare({ type: "delete list row", listId: deleteDialog.listId });
    }
    closeDeleteListRowDialog();
  }

  return (
    <>
      {!readonly && (
        <div className={classes.tableControlContainer}>
          <FormControlLabel
            label="Day Supply Ranges"
            control={
              <Switch
                color="primary"
                checked={costShare.isUsingDaySupplyRanges}
                onChange={handleToggleDaySupplyRange}
                name="Day Supply Ranges Switch"
              />
            }
            className={classes.daySupplyRangeSwitch}
          />
          <FormControlLabel
            label="Claim Ranges"
            control={
              <Switch
                color="primary"
                checked={costShare.isUsingClaimRanges}
                onChange={handleToggleClaimRanges}
                name="Claim Ranges Switch"
              />
            }
            className={classes.daySupplyRangeSwitch}
          />
        </div>
      )}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="Cost Share Table" className={classes.tableRoot} size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={FORMULARY_TIER_COL_SPAN}
                className={cn(
                  tableCellClasses.stickyCell,
                  tableCellClasses.tableCellNoPadding,
                  tableCellClasses.noBottomBorder
                )}
              />
              {networkTiers.map((headerNetworkTier) => (
                <NetworkTierHeader
                  numberOfDaySupplyRanges={
                    costShare.isUsingDaySupplyRanges
                      ? numberOfDaySupplyRangesOnNetworkTier(
                          costShare,
                          headerNetworkTier.id,
                          formularyTiers
                        )
                      : 1
                  }
                  headerNetworkTier={headerNetworkTier}
                  isUsingClaimRanges={costShare.isUsingClaimRanges}
                />
              ))}
              <TableCell
                className={cn(
                  tableCellClasses.tableCellNoBottomPadding,
                  tableCellClasses.noBottomBorder
                )}
                align="center"
                colSpan={OON_COL_SPAN}
              >
                <Typography variant="h6">OON Penalty</Typography>
              </TableCell>
            </TableRow>

            {Boolean(readonly) === false && costShare.isUsingDaySupplyRanges && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={FORMULARY_TIER_COL_SPAN}
                  className={cn(
                    tableCellClasses.stickyCell,
                    tableCellClasses.tableCellNoPadding,
                    tableCellClasses.noBottomBorder
                  )}
                />
                {networkTiers.map((networkTier) => (
                  <DaySupplyRangeAddButton
                    networkTier={networkTier}
                    numberOfDaySupplyRanges={
                      costShare.isUsingDaySupplyRanges
                        ? numberOfDaySupplyRangesOnNetworkTier(
                            costShare,
                            networkTier.id,
                            formularyTiers
                          )
                        : 1
                    }
                    isUsingClaimRanges={costShare.isUsingClaimRanges}
                    onAddDaySupplyRange={handleAddDaySupplyRange}
                  />
                ))}
                <TableCell
                  className={cn(
                    tableCellClasses.cell,
                    tableCellClasses.tableCellNoPadding,
                    tableCellClasses.noBottomBorder
                  )}
                  align="center"
                  colSpan={OON_COL_SPAN}
                />
              </TableRow>
            )}

            {costShare.isUsingDaySupplyRanges && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={FORMULARY_TIER_COL_SPAN}
                  className={cn(
                    tableCellClasses.stickyCell,
                    tableCellClasses.tableCellPadding,
                    tableCellClasses.noBottomBorder,
                    tableCellClasses.formularyCellRoot
                  )}
                />
                {networkTiers.map((networkTier) => {
                  const rangeStartingVals = daySupplyRangeStartingValues(
                    costShare,
                    networkTier.id,
                    formularyTiers
                  );
                  return rangeStartingVals.map((startingValue, i) => {
                    const daySupplyRange = daySupplyRangeValues.get(
                      daySupplyRangeKey(networkTier.id, startingValue)
                    );
                    return (
                      <DaySupplyRangeHeaderCell
                        id={`${networkTier.id}-header-range-${startingValue}`}
                        daySupplyRange={daySupplyRange}
                        networkTierId={networkTier.id}
                        isEndofRange={
                          startingValue === rangeStartingVals[rangeStartingVals.length - 1]
                        }
                        isUsingClaimRanges={costShare.isUsingClaimRanges}
                        onDeleteRange={handleDeleteDaySupplyRange}
                        onSaveCellEndValue={handleUpdateDaySupplyRanges}
                        onUpdateCellEndValue={handleUpdateDaySupplyRangeEndValue}
                        readonly={Boolean(readonly)}
                      />
                    );
                  });
                })}
                <TableCell
                  className={cn(
                    tableCellClasses.cell,
                    tableCellClasses.tableCellPadding,
                    tableCellClasses.noBottomBorder
                  )}
                  align="center"
                  colSpan={OON_COL_SPAN}
                />
              </TableRow>
            )}

            <TableRow>
              <TableCell
                align="center"
                colSpan={FORMULARY_TIER_COL_SPAN}
                className={cn(
                  tableCellClasses.stickyCell,
                  tableCellClasses.tableCellPadding,
                  tableCellClasses.noBottomBorder,
                  tableCellClasses.formularyCellRoot
                )}
              />
              {networkTiers.map((headerNetworkTier) => {
                if (costShare.isUsingDaySupplyRanges) {
                  const daySupplyRanges = daySupplyRangeStartingValues(
                    costShare,
                    headerNetworkTier.id,
                    formularyTiers
                  );
                  return daySupplyRanges.map((daySupplyRangeStartingValue) => (
                    <TableCell
                      key={`sub-header-${headerNetworkTier.id}-${daySupplyRangeStartingValue}`}
                      className={cn(
                        tableCellClasses.tableCell,
                        tableCellClasses.tableCellPadding,
                        tableCellClasses.noBottomBorder,
                        {
                          [tableCellClasses.sectionSeparatorBorder]:
                            daySupplyRangeStartingValue ===
                            daySupplyRanges[daySupplyRanges.length - 1],
                        }
                      )}
                      style={{ borderLeft: "1px solid lightgrey" }}
                      align="center"
                      colSpan={
                        costShare.isUsingClaimRanges
                          ? FULL_NETWORK_WITH_CLAIM_RANGES
                          : FULL_NETWORK_SECTION_COL_SPAN
                      }
                    >
                      Co-Pay / Co-insurance
                    </TableCell>
                  ));
                } else {
                  return (
                    <TableCell
                      key={`sub-header-${headerNetworkTier.id}`}
                      className={cn(
                        tableCellClasses.tableCell,
                        tableCellClasses.tableCellPadding,
                        tableCellClasses.noBottomBorder,
                        tableCellClasses.sectionSeparatorBorder
                      )}
                      style={{ borderLeft: "1px solid lightgrey" }}
                      align="center"
                      colSpan={
                        costShare.isUsingClaimRanges
                          ? FULL_NETWORK_WITH_CLAIM_RANGES
                          : FULL_NETWORK_SECTION_COL_SPAN
                      }
                    >
                      Co-Pay / Co-insurance
                    </TableCell>
                  );
                }
              })}
              <TableCell
                className={cn(
                  tableCellClasses.cell,
                  tableCellClasses.tableCellPadding,
                  tableCellClasses.noBottomBorder
                )}
                align="center"
                colSpan={OON_COL_SPAN}
              />
            </TableRow>

            <TableRow>
              <TableCell
                align="center"
                colSpan={FORMULARY_TIER_COL_SPAN}
                className={cn(
                  tableCellClasses.stickyCell,
                  tableCellClasses.tableCellPadding,
                  tableCellClasses.formularyCellRoot
                )}
              />
              {networkTiers.map((networkTier) => {
                if (costShare.isUsingDaySupplyRanges) {
                  const daySupplyRanges = daySupplyRangeStartingValues(
                    costShare,
                    networkTier.id,
                    formularyTiers
                  );
                  return daySupplyRanges.map((daySupplyRangeStartingValue) => (
                    <NetworkTierSubheader
                      id={`sub-columns-${networkTier.id}-${daySupplyRangeStartingValue}`}
                      networkTier={networkTier}
                      isEndOfRange={
                        daySupplyRangeStartingValue === daySupplyRanges[daySupplyRanges.length - 1]
                      }
                      isUsingClaimRanges={costShare.isUsingClaimRanges}
                    />
                  ));
                } else {
                  return (
                    <NetworkTierSubheader
                      id={`sub-columns-${networkTier.id}-${networkTier.id}`}
                      isUsingClaimRanges={costShare.isUsingClaimRanges}
                      networkTier={networkTier}
                      isEndOfRange
                    />
                  );
                }
              })}
              <TableCell
                className={cn(tableCellClasses.cell, tableCellClasses.tableCellPadding)}
                align="center"
                colSpan={OON_COL_SPAN}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {formularyTiers.map((formularyTier) => (
              <CostShareTableBodyRow
                costShare={costShare}
                formularyTiers={formularyTiers}
                rowKind={{ kind: "formulary tier", formularyTierId: formularyTier.id }}
                rowTitle={`Tier ${formularyTier.rank}`}
                rowSubtitle={formularyTier.name}
                networkTiers={networkTiers}
                readonly={Boolean(readonly)}
                updateCostShare={updateCostShare}
                onChangeOONValueType={handleSelectToUpdateOONValueType}
                onChangeValueType={handleSelectToUpdateValueCellType}
                onListRowClickDelete={handleListRowClickDelete}
              />
            ))}
            {Object.keys(costShare.lists)
              .sort((idA, idB) => costShare.lists[idA].order - costShare.lists[idB].order)
              .map((listId) => (
                <CostShareTableBodyRow
                  costShare={costShare}
                  formularyTiers={formularyTiers}
                  rowKind={{ kind: "list", listId }}
                  rowTitle={
                    benefitsLists.find((benefitsList) => benefitsList.id === listId)?.name ?? ""
                  }
                  networkTiers={networkTiers}
                  readonly={Boolean(readonly)}
                  updateCostShare={updateCostShare}
                  onChangeOONValueType={handleSelectToUpdateOONValueType}
                  onChangeValueType={handleSelectToUpdateValueCellType}
                  onListRowClickDelete={handleListRowClickDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomDrugListControls
        benefitsLists={benefitsLists}
        costShare={costShare}
        readonly={readonly}
        onAddList={(list) => {
          updateCostShare({ type: "add list", list });
        }}
      />
      <ConfirmationDialog
        isOpen={deleteDialog.open}
        onNoHandler={closeDeleteListRowDialog}
        onYesHandler={deleteListRow}
        dialogTitle="Delete List"
        dialogContent={
          deleteDialog.open
            ? `Delete ${
                benefitsLists.find((list) => list.id === deleteDialog.listId)?.name ?? ""
              } from Cost Share?`
            : "Delete List?"
        }
      />
      <Menu
        anchorEl={activeValueType.anchorElement}
        keepMounted
        open={Boolean(activeValueType.anchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleValueTypeMenuSelect(ValueType.DOLLAR)}>$</MenuItem>
        <MenuItem onClick={handleValueTypeMenuSelect(ValueType.PERCENTAGE)}>%</MenuItem>
      </Menu>
    </>
  );
}

interface Props {
  headerNetworkTier: EditNetworkTier;
  numberOfDaySupplyRanges: number;
  isUsingClaimRanges: boolean;
}

function NetworkTierHeader(props: Props): JSX.Element {
  const classes = useTableCellStyles();

  return (
    <TableCell
      className={cn(classes.tableCellNoPadding, classes.noBottomBorder)}
      key={`network-header-${props.headerNetworkTier.id}`}
      align="center"
      colSpan={
        props.isUsingClaimRanges
          ? FULL_NETWORK_WITH_CLAIM_RANGES * props.numberOfDaySupplyRanges
          : FULL_NETWORK_SECTION_COL_SPAN * props.numberOfDaySupplyRanges
      }
    >
      <Typography variant="h6" classes={{ root: classes.tableCellNoPadding }}>
        {props.headerNetworkTier.name}
      </Typography>
    </TableCell>
  );
}

const useDaySupplyRangeButtonCellStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  button: {
    color: theme.palette.primary.main,
  },
}));

interface DaySupplyRangeAddButtonCellProps {
  numberOfDaySupplyRanges: number;
  isUsingClaimRanges: boolean;
  networkTier: EditNetworkTier;
  onAddDaySupplyRange: (networkTier: EditNetworkTier) => void;
}

function DaySupplyRangeAddButton(props: DaySupplyRangeAddButtonCellProps): JSX.Element {
  const classes = useTableCellStyles();
  const buttonStyles = useDaySupplyRangeButtonCellStyles();

  function handleAddDaySupplyRange() {
    props.onAddDaySupplyRange(props.networkTier);
  }

  return (
    <TableCell
      colSpan={
        props.numberOfDaySupplyRanges *
        (props.isUsingClaimRanges ? FULL_NETWORK_WITH_CLAIM_RANGES : FULL_NETWORK_SECTION_COL_SPAN)
      }
      className={cn(classes.tableCellNoPadding, classes.noBottomBorder, buttonStyles.root)}
    >
      <Button
        size="small"
        color="primary"
        className={buttonStyles.button}
        onClick={handleAddDaySupplyRange}
      >
        add day supply range
      </Button>
    </TableCell>
  );
}

const useDaySupplyRangeCellStyles = makeStyles((theme) => ({
  root: { textAlign: "center" },
  daySupplyRangeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "&:hover button": {
      display: "block !important",
    },
  },
  daySupplyRangeEndValue: {
    width: "3.5rem",
  },
  daySupplyRangeDeleteButton: {
    display: "none",
  },
  daySupplyRangeDeleteButtonContainer: {
    width: "1.5rem",
  },
  addSupplyRangeButton: {
    color: theme.palette.primary.main,
  },
  daySupplyRangeCell: {
    borderLeft: "1px solid lightgrey",
  },
  toCell: { marginLeft: "0.25rem", marginRight: "0.25rem" },
}));

interface DaySupplyRangeHeaderCellProps {
  id: string;
  daySupplyRange?: DaySupplyRangeValues;
  networkTierId: string;
  readonly: boolean;
  isEndofRange: boolean;
  isUsingClaimRanges: boolean;
  onUpdateCellEndValue: (networkTierId: string, startingValue: number, newEndValue: number) => void;
  onSaveCellEndValue: (networkTierId: string, rangeStartingValue: number) => void;
  onDeleteRange: (networkTierId: string, rangeStartingValue: number) => void;
}

function DaySupplyRangeHeaderCell(props: DaySupplyRangeHeaderCellProps): JSX.Element {
  const classes = useTableCellStyles();
  const daySupplyRangeClasses = useDaySupplyRangeCellStyles();
  const tableCellClasses = useTableCellStyles();

  function handleUpdateCellValue(event: React.ChangeEvent<HTMLInputElement>) {
    if (props.daySupplyRange) {
      props.onUpdateCellEndValue(
        props.networkTierId,
        props.daySupplyRange.startValue,
        parseInt(event.target.value)
      );
    }
  }

  function handleSaveCellEndValue() {
    if (props.daySupplyRange) {
      props.onSaveCellEndValue(props.networkTierId, props.daySupplyRange.startValue);
    }
  }

  function handleDeleteRange() {
    if (props.daySupplyRange) {
      props.onDeleteRange(props.networkTierId, props.daySupplyRange.startValue);
    }
  }

  return props.daySupplyRange ? (
    <TableCell
      key={props.id}
      colSpan={
        props.isUsingClaimRanges ? FULL_NETWORK_WITH_CLAIM_RANGES : FULL_NETWORK_SECTION_COL_SPAN
      }
      className={cn(
        daySupplyRangeClasses.root,
        classes.tableCellPadding,
        classes.noBottomBorder,
        daySupplyRangeClasses.daySupplyRangeCell,
        {
          [classes.sectionSeparatorBorder]: props.isEndofRange,
        }
      )}
    >
      <span className={daySupplyRangeClasses.daySupplyRangeContainer}>
        {props.daySupplyRange.endValue === undefined ? (
          `${props.daySupplyRange.startValue}+`
        ) : (
          <>
            <Typography>{props.daySupplyRange.startValue}</Typography>
            <Typography className={daySupplyRangeClasses.toCell}>-</Typography>
            {props.readonly ? (
              <Typography>{props.daySupplyRange.endValue}</Typography>
            ) : (
              <TextField
                type="number"
                variant="outlined"
                size="small"
                disabled={props.readonly}
                className={daySupplyRangeClasses.daySupplyRangeEndValue}
                value={props.daySupplyRange.endValue || ""}
                onChange={handleUpdateCellValue}
                onBlur={handleSaveCellEndValue}
              />
            )}
          </>
        )}
        {!props.readonly && (
          <span className={daySupplyRangeClasses.daySupplyRangeDeleteButtonContainer}>
            {props.daySupplyRange.startValue > 1 && (
              <IconButton
                className={daySupplyRangeClasses.daySupplyRangeDeleteButton}
                size="small"
                onClick={handleDeleteRange}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </span>
        )}
      </span>
    </TableCell>
  ) : (
    <TableCell
      key={props.id}
      colSpan={FULL_NETWORK_SECTION_COL_SPAN}
      className={cn(tableCellClasses.tableCellPadding, tableCellClasses.noBottomBorder)}
      style={{
        textAlign: "center",
        borderLeft: "1px solid lightgrey",
        borderRight: "1px solid lightgrey",
      }}
    >
      <Typography variant={"body2"}>-</Typography>
    </TableCell>
  );
}

interface NetworkTierSubHeaderProps {
  id: string;
  networkTier: EditNetworkTier;
  isEndOfRange: boolean;
  isUsingClaimRanges: boolean;
}

function NetworkTierSubheader(props: NetworkTierSubHeaderProps): JSX.Element {
  const classes = useTableCellStyles();

  if (props.isUsingClaimRanges) {
    return (
      <React.Fragment key={props.id}>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding)}
          align="center"
          colSpan={CLAIM_RANGE_ACTION_COLUMN_SPAN}
          style={{ width: "2rem", padding: "0" }}
        />
        <TableCell
          className={cn(classes.tableCell, classes.tableCellNoPadding)}
          align="center"
          colSpan={CLAIM_RANGE_COLUMN_SPAN}
          style={{ width: "7rem" }}
        >
          Claim Ranges
        </TableCell>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding)}
          align="center"
          colSpan={VALUE_SUB_HEADER_COL_SPAN}
          style={{ width: "5rem" }}
        >
          value
        </TableCell>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding)}
          align="center"
          colSpan={VALUE_SUB_HEADER_COL_SPAN}
        >
          min
        </TableCell>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding, {
            [classes.sectionSeparatorBorder]: props.isEndOfRange && !props.isUsingClaimRanges,
          })}
          align="center"
          colSpan={VALUE_SUB_HEADER_COL_SPAN}
        >
          max
        </TableCell>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding, classes.maxCellBorder, {
            [classes.sectionSeparatorBorder]: props.isEndOfRange,
          })}
          align="center"
          colSpan={CLAIM_RANGE_ACTION_COLUMN_SPAN}
          style={{ width: "2rem", padding: "0" }}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment key={props.id}>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding)}
          align="center"
          colSpan={VALUE_SUB_HEADER_COL_SPAN}
        >
          value
        </TableCell>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding)}
          align="center"
          colSpan={VALUE_SUB_HEADER_COL_SPAN}
        >
          min
        </TableCell>
        <TableCell
          className={cn(classes.tableCell, classes.tableCellPadding, classes.maxCellBorder, {
            [classes.sectionSeparatorBorder]: props.isEndOfRange && !props.isUsingClaimRanges,
          })}
          align="center"
          colSpan={VALUE_SUB_HEADER_COL_SPAN}
        >
          max
        </TableCell>
      </React.Fragment>
    );
  }
}

const useRowStyles = makeStyles({
  oonTextField: {
    width: "4rem",
  },
  formularyHeaderCell: {
    display: "grid",
    width: "100%",
    height: "fit-content",
    gridTemplateColumns: "10rem 1fr",
    gridTemplateRows: "repeat(2, auto)",
    gridTemplateAreas: `
      "rank disable"
      "name name"
    `,
  },
  formularyTierRank: {
    gridArea: "rank",
  },
  formularyTierName: {
    gridArea: "name",
    height: "fit-content",
  },
  disableSwitch: {
    gridArea: "disable",
    justifySelf: "end",
  },
  listHeaderTitle: { gridColumn: "1 / 2", gridRow: "1 / 3" },
  listHeaderDeleteIcon: {
    gridColumn: "2 / 3",
    gridRow: "1 / 3",
    justifySelf: "center",
    alignSelf: "start",
  },
});

interface CostShareTableBodyRowProps {
  rowKind: RowKind;
  rowTitle: string;
  rowSubtitle?: string;
  networkTiers: Array<EditNetworkTier>;
  costShare: CostShare;
  readonly: boolean;
  formularyTiers: Array<FormularyTier>;
  updateCostShare: React.Dispatch<CostShareAction>;
  onChangeValueType: (
    rowKind: RowKind,
    networkTierId: string,
    daySupplyRangeStartingValue: number,
    claimRangeStartingCost: number,
    currentTarget: EventTarget & HTMLButtonElement
  ) => void;
  onChangeOONValueType: (rowKind: RowKind, currentTarget: EventTarget & HTMLButtonElement) => void;
  onListRowClickDelete: (listId: string) => void;
}

function CostShareTableBodyRow(props: CostShareTableBodyRowProps): JSX.Element {
  const tableCellClasses = useTableCellStyles();
  const rowClasses = useRowStyles();

  // TODO - see if there is any utility in implementing this throughout the component
  const row: FormularyTierRow | ListRow =
    props.rowKind.kind === "formulary tier"
      ? props.costShare.formularyTiers[props.rowKind.formularyTierId]
      : props.costShare.lists[props.rowKind.listId];

  function handleChangeOONValue(newValue: number) {
    props.updateCostShare({
      type: "update OON cell value",
      rowActionKind: props.rowKind,
      newValue,
    });
  }

  function handleChangeOONValueType(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    props.onChangeOONValueType(props.rowKind, event.currentTarget);
  }

  function toggleFormularyRow() {
    if (props.rowKind.kind === "formulary tier") {
      props.updateCostShare({
        type: "toggle formulary tier row",
        formularyTierId: props.rowKind.formularyTierId,
      });
    }
  }

  function deleteListRow() {
    if (props.rowKind.kind === "list") {
      props.onListRowClickDelete(props.rowKind.listId);
    }
  }

  const isRowDisabled: boolean =
    props.readonly ||
    Boolean(
      props.rowKind.kind === "formulary tier" &&
        props.costShare.formularyTiers[props.rowKind.formularyTierId].isEnabled === false
    );

  return (
    <TableRow
      key={
        props.rowKind.kind === "formulary tier"
          ? props.rowKind.formularyTierId
          : props.rowKind.listId
      }
    >
      <TableCell
        align="left"
        colSpan={FORMULARY_TIER_COL_SPAN}
        className={cn(
          tableCellClasses.formularyCellRoot,
          tableCellClasses.stickyCell,
          tableCellClasses.tableCellPadding
        )}
      >
        {props.rowKind.kind === "formulary tier" ? (
          <div className={rowClasses.formularyHeaderCell}>
            <Typography variant="h6" className={rowClasses.formularyTierRank}>
              {props.rowTitle}
            </Typography>
            <Typography variant="subtitle2" className={rowClasses.formularyTierName}>
              {props.rowSubtitle}
            </Typography>
            {props.readonly === false && (
              <Switch
                className={rowClasses.disableSwitch}
                color="primary"
                checked={props.costShare.formularyTiers[props.rowKind.formularyTierId].isEnabled}
                onChange={toggleFormularyRow}
              />
            )}
          </div>
        ) : (
          <div className={rowClasses.formularyHeaderCell}>
            <Typography variant="subtitle1" className={rowClasses.listHeaderTitle}>
              {props.rowTitle}
            </Typography>
            {props.readonly ? null : (
              <IconButton
                size="small"
                className={rowClasses.listHeaderDeleteIcon}
                onClick={deleteListRow}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        )}
      </TableCell>

      {props.networkTiers.map((headerNetworkTier) => {
        if (props.costShare.isUsingDaySupplyRanges) {
          const daySupplyRanges = daySupplyRangeStartingValues(
            props.costShare,
            headerNetworkTier.id,
            props.formularyTiers,
            props.rowKind
          );
          return daySupplyRanges.map((daySupplyRangeStartingValue) => {
            return (
              <CostShareValueCell
                key={`value-cells-${
                  props.rowKind.kind === "formulary tier"
                    ? props.rowKind.formularyTierId
                    : props.rowKind.listId
                }-${headerNetworkTier.id}-${daySupplyRangeStartingValue}`}
                costShare={props.costShare}
                daySupplyRangeStartingValue={daySupplyRangeStartingValue}
                rowKind={props.rowKind}
                updateCostShare={props.updateCostShare}
                networkTier={headerNetworkTier}
                readonly={isRowDisabled}
                onChangeValueType={props.onChangeValueType}
                isEndOfRange={
                  daySupplyRangeStartingValue === daySupplyRanges[daySupplyRanges.length - 1]
                }
              />
            );
          });
        } else {
          return (
            <CostShareValueCell
              costShare={props.costShare}
              daySupplyRangeStartingValue={
                daySupplyRangeStartingValues(
                  props.costShare,
                  headerNetworkTier.id,
                  props.formularyTiers,
                  props.rowKind
                )[0]
              }
              rowKind={props.rowKind}
              networkTier={headerNetworkTier}
              readonly={isRowDisabled}
              updateCostShare={props.updateCostShare}
              onChangeValueType={props.onChangeValueType}
              isEndOfRange
            ></CostShareValueCell>
          );
        }
      })}

      <TableCell
        align="center"
        colSpan={OON_COL_SPAN}
        className={tableCellClasses.tableCellPadding}
      >
        <div className={tableCellClasses.tableCellContainer}>
          {row.outOfNetworkPenalty ? (
            row.outOfNetworkPenalty.valueType === ValueType.DOLLAR ? (
              <DollarInputCell
                disabled={isRowDisabled}
                value={row.outOfNetworkPenalty.value}
                onValueChange={handleChangeOONValue}
              />
            ) : (
              <PercentageInputCell
                disabled={isRowDisabled}
                value={row.outOfNetworkPenalty.value}
                onValueChange={handleChangeOONValue}
              />
            )
          ) : (
            <TextField className={rowClasses.oonTextField} disabled />
          )}
          <IconButton disabled={isRowDisabled} size="small" onClick={handleChangeOONValueType}>
            <DownArrowIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}
