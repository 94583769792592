import * as React from "react";
import {
  Divider,
  fade,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchRounded";
import { TextSizing } from "./TextSizing";
import { ContentSpacing } from "./ContentSpacing";
import { UserContext } from "../../components/UserContext";

const useStyles = makeStyles((theme) => ({
  container: {
    gridColumn: "span 4",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  root: {
    backgroundColor: "white",
  },
  searchListItem: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    position: "sticky",
    top: 0,
    zIndex: 100,
  },
  searchBar: {
    padding: "0 20px",
    width: "80%",
    backgroundColor: "#ECECEC",
  },
  noMatch: {
    textAlign: "center",
    backgroundColor: "white",
    color: fade(theme.palette.text.primary, 0.5),
  },
  settingArea: {
    gridColumn: "5 / span 12",
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "center",
    alignItems: "center",
  },
}));

enum AppSettings {
  TEXT_SIZE = "Text Accessibility",
  SPACING = "Content spacing",
  THEME = "App Theme Customization",
  SIDEBAR = "Sidebar Options",
}

const SETTINGS_LIST = [
  {
    name: AppSettings.SPACING,
    route: `/content-spacing`,
    disabled: false,
  },
  // TODO: Future feature ideas for this area
  // {
  //   name: AppSettings.TEXT_SIZE,
  //   route: `/text-size`,
  //   disabled: true,
  // },
  // {
  //   name: AppSettings.THEME,
  //   route: `/theme-customization`,
  //   disabled: true,
  // },
  // {
  //   name: AppSettings.SIDEBAR,
  //   route: `/sidebar-options`,
  //   disabled: true,
  // },
];

export function UserAppSettings(): JSX.Element {
  const classes = useStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [activeSetting, setActiveSetting] = React.useState<AppSettings>(AppSettings.SPACING);

  function searchList(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.currentTarget.value);
  }

  function getFilteredList() {
    if (searchTerm === "") {
      return SETTINGS_LIST;
    } else {
      const searchText = searchTerm.toLowerCase();
      return SETTINGS_LIST.filter((item) => item.name.toLowerCase().includes(searchText));
    }
  }

  function handleSettingClick(settingName: AppSettings) {
    setActiveSetting(settingName);
  }

  function SettingPanel(props: { children?: React.ReactNode; value: AppSettings }): JSX.Element {
    const { children, value } = props;

    return (
      <div style={{ position: "relative" }} hidden={value !== activeSetting}>
        {children}
      </div>
    );
  }

  return (
    <>
      <Paper square className={classes.container}>
        <List dense={contentSpacing === "dense"} disablePadding className={classes.root}>
          <ListItem className={classes.searchListItem} key="search">
            <Paper elevation={0} className={classes.searchBar}>
              <InputBase
                placeholder="Find setting"
                fullWidth
                inputProps={{ "aria-label": "find setting" }}
                onChange={searchList}
                endAdornment={
                  <IconButton disabled>
                    <SearchIcon />
                  </IconButton>
                }
              />
            </Paper>
          </ListItem>
          <Divider />
          {searchTerm !== "" && getFilteredList().length === 0 ? (
            <ListItem className={classes.noMatch} key="no entries">
              <ListItemText
                primary="No matches found for"
                secondary={searchTerm}
                primaryTypographyProps={{ color: "inherit" }}
              />
            </ListItem>
          ) : (
            getFilteredList().map((setting, index) => (
              <ListItem
                button
                key={`setting-${index}`}
                selected={setting.name === activeSetting}
                onClick={() => handleSettingClick(setting.name)}
                disabled={setting.disabled}
              >
                <ListItemText primary={setting.name} />
              </ListItem>
            ))
          )}
        </List>
      </Paper>
      <section className={classes.settingArea}>
        <SettingPanel value={AppSettings.SPACING}>
          <ContentSpacing />
        </SettingPanel>
        <SettingPanel value={AppSettings.TEXT_SIZE}>
          <TextSizing />
        </SettingPanel>
      </section>
    </>
  );
}
