import { Card, CardContent, makeStyles } from "@material-ui/core";
import * as React from "react";
import Criteria from "./Criteria.svg";

const useStyles = makeStyles({
  previewWrapper: {
    position: "absolute",
    width: "100%",
  },
  sectionCard: {
    margin: 15,
  },
  sectionCardContent: {
    border: "3px solid rgba(0, 0, 0, 0)",
    padding: "0 40px 0 40px !important",
    transition: "border 200ms",
  },
  imageFill: {
    width: "100%",
  },
});

export const CriteriaTemplateGuide: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.previewWrapper}>
      <Card className={classes.sectionCard}>
        <CardContent className={classes.sectionCardContent}>
          <img className={classes.imageFill} src={Criteria} alt={"Criteria"} />
        </CardContent>
      </Card>
    </div>
  );
};
