const invalidCharacters = new Set(["e", "+", "-", "E"]);
const DECIMAL = ".";
const EMPTY_STRING = "";
const matchOnlyDigitRegEx = /^[0-9]\d{0,9}/g;

export const minMaxStepBoundary: { [unit: string]: { min: string; max: string; step: string } } = {
  IN: {
    min: "0",
    max: "2",
    step: "0.1",
  },
  CM: {
    min: "0",
    max: "5.08",
    step: "0.1",
  },
  PT: {
    min: "0",
    max: "144",
    step: "0.1",
  },
};

export const fontSizeMinMaxBoundary = {
  min: "8",
  max: "18",
};

export const matchOnlyDigit = (value: string | number, e: React.KeyboardEvent<HTMLDivElement>) => {
  if (invalidCharacters.has(e.key) || e.key === DECIMAL) {
    e.preventDefault();
  }
  if (value !== EMPTY_STRING) {
    const isMatched = new RegExp(matchOnlyDigitRegEx).test(value.toString());
    if (!isMatched) {
      e.preventDefault();
    }
  }
};
