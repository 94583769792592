import * as React from "react";
import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import EditorDialogIcon from "@material-ui/icons/TextFormatRounded";
import classNames from "classnames";
import { MarginUnits, TemplateFonts } from "../../Templates/Formulary/types";
import { HeaderFooterEditor } from "../wysiwygs/HeaderFooterEditor";
import { TwoActionDialog } from "./TwoActionDialog";

const useStyles = makeStyles({
  rootButton: {
    marginTop: 15,
    padding: "10px 8px",
    width: "fit-content",
    textTransform: "capitalize",
  },
});

type Props = {
  title: string;
  templateFont: TemplateFonts;
  leftMargin: number;
  rightMargin: number;
  units: MarginUnits;
  content: string;
  disabled?: boolean;
  updateContent: (content: string) => void;
};

export const EditorDialog: React.FC<Props> = (props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [editorContent, setEditorContent] = React.useState(props.content);
  const classes = useStyles();

  const handleOk = () => {
    props.updateContent(editorContent);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openDialog = () => {
    setEditorContent(props.content);
    setOpen(true);
  };

  const handleUpdateContent = (content: string) => {
    setEditorContent(content);
  };

  const buttonClasses = () => {
    const lowercaseTitle = props.title.toLowerCase();
    if (lowercaseTitle.includes("header")) {
      return classNames(classes.rootButton, "add-header-button");
    } else if (lowercaseTitle.includes("footer")) {
      return classNames(classes.rootButton, "add-footer-button");
    } else {
      return classes.rootButton;
    }
  };
  const isContentEmpty =
    props.content === "" || props.content === null || props.content === undefined;

  return (
    <>
      <TwoActionDialog
        isOpen={isOpen}
        dialogIcon={<EditorDialogIcon />}
        dialogTitle={props.title}
        primaryActionName="ok"
        primaryActionHandler={handleOk}
        secondaryActionName="cancel"
        secondaryActionHandler={handleClose}
        onExitHandler={handleClose}
        maxWidth="lg"
      >
        <HeaderFooterEditor
          content={editorContent}
          font={props.templateFont}
          leftMargin={props.leftMargin}
          rightMargin={props.rightMargin}
          marginUnits={props.units}
          onUpdateContent={handleUpdateContent}
        />
      </TwoActionDialog>
      <Button className={buttonClasses()} onClick={openDialog} disabled={Boolean(props.disabled)}>
        {`${isContentEmpty ? "Add" : "Edit"} ${props.title}`}
        {isContentEmpty ? <AddIcon /> : <EditIcon />}
      </Button>
    </>
  );
};
