import * as React from "react";
import { ColDef, IServerSideDatasource, RowClickedEvent } from "ag-grid-community";
import { UserContext } from "../../components/UserContext";
import { Medispan } from "../../types/DrugSearchTypes";
import { DrugDetailsDrawer } from "./DrugDetailsDrawer/DrugDetailsDrawer";
import { queryMspanDrugs, requestToCriteria } from "./drugSearchApi";
import { COMMON_COL_DEF } from "./drugSearchCommon";
import { StyledAgGrid } from "../../components/tables/dataTables/StyledAgGrid";

//MSPAN Label Name, NDC, Drug Description Id, GPI, MONY, Is Brand/BG Status, Brand Name, Generic Description, Route of Admin,  Is OTC?, On Market Date, Off Market Date
const MSPAN_COLUMNS: Array<ColDef> = [
  COMMON_COL_DEF.LABEL_NAME,
  COMMON_COL_DEF.NDC,
  COMMON_COL_DEF.RXCUI,
  {
    headerName: "Drug Descriptor ID",
    field: "drugDescriptorId",
    filter: "text",
    width: 190,
  },
  {
    headerName: "Generic Description",
    field: "genericDescription",
    filter: "text",
    width: 333,
  },
  {
    headerName: "GPI",
    field: "gpi14",
    filter: "text",
    width: 175,
  },
  COMMON_COL_DEF.BG_STATUS,
  {
    headerName: "MONY",
    field: "mony",
    filter: "text",
    width: 110,
  },
  COMMON_COL_DEF.IS_OTC,
  {
    headerName: "Brand Name",
    field: "brandName",
    filter: "text",
    width: 333,
  },
  COMMON_COL_DEF.ROA,
  COMMON_COL_DEF.IS_OTC,
  COMMON_COL_DEF.LAST_UPDATED,
  COMMON_COL_DEF.ON_MARKET,
  COMMON_COL_DEF.OFF_MARKET,
];

export const MspanSearchGrid: React.FC = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<Medispan | undefined>(undefined);

  const {
    user: { claimSystemId },
  } = React.useContext(UserContext);

  const handleRowClick = (event: RowClickedEvent) => {
    setSelectedRow(event.data);
    setShowDrawer(true);
  };

  const drugMetaData = {
    displayName: selectedRow?.brandName ?? "",
    ndc: selectedRow?.ndc ?? "",
    drugAttributes: {
      "GPI 14": selectedRow?.gpi14,
      MONY: selectedRow?.mony,
      "Drug Descriptor ID": selectedRow?.drugDescriptorId,
      RxCUI: selectedRow?.rxcui,
    },
  };

  const datasource: IServerSideDatasource = {
    getRows(params) {
      queryMspanDrugs(requestToCriteria(params.request, claimSystemId))
        .then((result) => {
          params.successCallback(result.drugs, result.lastRow);
        })
        .catch((err) => {
          console.error(err);
          params.failCallback();
        });
    },
  };

  return (
    <>
      <DrugDetailsDrawer
        isDrawerOpen={showDrawer}
        productUuid={selectedRow?.productUuid ?? ""}
        drugMetaData={drugMetaData}
        onClose={() => {
          setShowDrawer(false);
          setSelectedRow(undefined);
        }}
      />
      <StyledAgGrid
        onRowClicked={handleRowClick}
        //data model parameters
        columnDefs={MSPAN_COLUMNS}
        serverSideDatasource={datasource}
        rowModelType="serverSide"
        //performance parameters
        cacheBlockSize={100}
        maxBlocksInCache={10000}
        maxConcurrentDatasourceRequests={4}
        blockLoadDebounceMillis={250}
        serverSideSortingAlwaysResets={true}
      />
    </>
  );
};
