import { ApolloError, useMutation } from "@apollo/client";
import {
  Button,
  Collapse,
  Divider,
  fade,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import NotesIcon from "@material-ui/icons/DescriptionRounded";
import { GridApi } from "ag-grid-community";
import * as React from "react";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { BasicDialog } from "../../components/dialogs/BasicDialog";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { TwoActionDialog } from "../../components/dialogs/TwoActionDialog";
import { CopyIcon } from "../../components/Icons/customIcons";
import {
  CopyPlanInput,
  CopyPlanResponse,
  COPY_PLAN,
  DeletePlanInput,
  DeletePlanResponse,
  DELETE_PLAN,
  PublishPlanInput,
  PublishPlanResponse,
  PUBLISH_PLAN,
} from "../api";
import { APIPlanItem, FlatPlan, PlanStatus } from "../types";
import { PlansLandingPageTable } from "./PlansLandingPageTable";

const useStyles = makeStyles((theme) => ({
  controlBarButton: {
    margin: theme.spacing(),
    marginTop: theme.spacing() + 2, //offset from loading bar
    "&:hover": {
      background: fade(theme.palette.common.white, 0.2),
    },
  },
  overlayPaper: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  iconFilter: {
    filter: `invert(0.3)`,
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "fit-content",
  },
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
}));

/**
 * TODO
 *  all columns with "hide: true" need to be replaced with "hide: false" after the demo
 */

export function PlansTablePage(): JSX.Element {
  const classes = useStyles();

  const gridApi = React.useRef<GridApi | null>(null);

  const [isTableLoading, setIsTableLoading] = React.useState(false);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<ApolloError | undefined>(undefined);
  const [deleteDialog, setDeleteDialog] = React.useState<{
    show: boolean;
    planName?: string;
    planId?: string;
    onSuccessfulDelete?: (deletedPlanId: string) => void;
  }>({
    show: false,
  });
  const [publishDialog, setPublishDialog] = React.useState<{
    show: boolean;
    planName?: string;
    planId?: string;
    onSuccessfulPublish?: (planId: string, planStatus: string) => void;
  }>({
    show: false,
  });
  const [planNotes, setPlanNotes] = React.useState<{
    name?: string;
    notes?: string;
    show: boolean;
  }>({
    show: false,
  });
  const [copyDialog, setCopyDialog] = React.useState<{
    show: boolean;
    plan?: FlatPlan;
    onSuccessfulCopy?: (plan: APIPlanItem) => void;
  }>({ show: false });

  //#region Apollo queries and mutations

  const [updatePlan] = useMutation<PublishPlanResponse, PublishPlanInput>(PUBLISH_PLAN, {
    onCompleted: (res) => {
      publishDialog.onSuccessfulPublish?.(res.updatePlan.id, res.updatePlan.status);
      setIsTableLoading(false);
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
      setIsTableLoading(false);
      gridApi.current?.hideOverlay();
    },
  });

  const [copyPlan] = useMutation<CopyPlanResponse, CopyPlanInput>(COPY_PLAN, {
    onCompleted: (res) => {
      copyDialog.onSuccessfulCopy?.(res.copyPlan);
      setIsTableLoading(false);
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
      setIsTableLoading(false);
      gridApi.current?.hideOverlay();
    },
  });

  const [deletePlan] = useMutation<DeletePlanResponse, DeletePlanInput>(DELETE_PLAN, {
    onCompleted: (res) => {
      deleteDialog.onSuccessfulDelete?.(res.deletePlans[0].id);
      setDeleteDialog({ show: false });
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
      setIsTableLoading(false);
      setDeleteDialog({ show: false });
      gridApi.current?.hideOverlay();
    },
  });

  //#endregion

  function handlePublishPlan(planId?: string) {
    setIsTableLoading(true);
    if (planId) {
      gridApi.current?.showLoadingOverlay();
      updatePlan({
        variables: {
          id: planId,
          input: {
            status: PlanStatus.Published,
          },
        },
      });
    }
  }

  function handleDeletePlan(planId?: string) {
    setIsTableLoading(true);
    if (planId) {
      gridApi.current?.showLoadingOverlay();
      deletePlan({
        variables: {
          ids: [planId],
        },
      });
    }
  }

  function handleCopyPlan(plan: FlatPlan) {
    setIsTableLoading(true);
    gridApi.current?.showLoadingOverlay();
    copyPlan({
      variables: {
        id: plan.id,
        input: {
          name: plan.name,
          externalId: plan.externalId,
          planType: plan.planType,
          calendarYear: plan.calendarYear,
          effectiveDate: plan.effectiveDate,
          lineOfBusinessId: plan.lineOfBusinessId,
        },
      },
    });
  }

  function handleDialogChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    property: "name" | "externalId",
    plan?: FlatPlan
  ) {
    if (plan) {
      const newPlan = { ...plan };
      if (property === "name") {
        newPlan.name = event.target.value;
      } else if (property === "externalId") {
        newPlan.externalId = event.target.value;
      }
      setCopyDialog({ ...copyDialog, plan: newPlan });
    }
  }

  function handleSelectToCopyPlan(
    plan: FlatPlan,
    newPlanName: string,
    onSuccess: (plan: APIPlanItem) => void
  ) {
    setCopyDialog({
      plan: {
        ...plan,
        name: newPlanName,
        externalId: "",
      },
      onSuccessfulCopy: onSuccess,
      show: true,
    });
  }

  function handleSelectToDeletePlan(
    planId: string,
    planName: string,
    onSuccess: (deletedPlanId: string) => void
  ) {
    setDeleteDialog({ planId, planName, show: true, onSuccessfulDelete: onSuccess });
  }

  function handleSelectToPublishPlan(
    planId: string,
    planName: string,
    onSuccess: (planId: string, planStatus: string) => void
  ) {
    setPublishDialog({ planId, planName, show: true, onSuccessfulPublish: onSuccess });
  }

  function handleSelectToShowNotes(notes: string, planName: string) {
    setPlanNotes({ notes, name: planName, show: true });
  }

  return (
    <section style={{ gridColumn: "span 16" }}>
      <PlansLandingPageTable
        isLoading={isTableLoading}
        onCopyPlan={handleSelectToCopyPlan}
        onDeletePlan={handleSelectToDeletePlan}
        onPublishPlan={handleSelectToPublishPlan}
        onShowPlanNotes={handleSelectToShowNotes}
      />
      <BasicDialog
        isOpen={planNotes.show}
        dialogIcon={<NotesIcon />}
        dialogTitle={planNotes?.name ? `Notes for ${planNotes.name}` : ""}
        closeButtonText="OK"
        onExitHandler={() => setPlanNotes({ ...planNotes, show: false })}
      >
        <Typography>{planNotes?.notes}</Typography>
      </BasicDialog>
      <TwoActionDialog
        isOpen={copyDialog.show}
        dialogIcon={<CopyIcon />}
        dialogTitle={`Change properties?`}
        primaryActionName="COPY"
        primaryActionHandler={() => {
          setCopyDialog({ ...copyDialog, show: false });
          if (copyDialog.plan) {
            handleCopyPlan(copyDialog.plan);
          }
        }}
        secondaryActionName="CANCEL"
        secondaryActionHandler={() => {
          setCopyDialog({ ...copyDialog, show: false });
        }}
        disabled={!Boolean(copyDialog.plan?.externalId) || !Boolean(copyDialog.plan?.name)}
      >
        <div style={{ textAlign: "center", width: 500 }}>
          <TextField
            label="Plan name"
            value={copyDialog.plan?.name || ""}
            onChange={(event) => handleDialogChange(event, "name", copyDialog.plan)}
            className={classes.textFieldOptions}
          />
          <TextField
            label="External ID"
            value={copyDialog.plan?.externalId || ""}
            onChange={(event) => handleDialogChange(event, "externalId", copyDialog.plan)}
            className={classes.textFieldOptions}
          />
        </div>
      </TwoActionDialog>
      <ConfirmationDialog
        isOpen={deleteDialog.show}
        onYesHandler={() => {
          setDeleteDialog({ ...deleteDialog, show: false });
          handleDeletePlan(deleteDialog.planId);
        }}
        onNoHandler={() => {
          setDeleteDialog({ ...deleteDialog, show: false });
        }}
        dialogTitle={`Delete ${deleteDialog.planName}?`}
        dialogContent={`Are you sure you want to delete ${deleteDialog.planName}?`}
      />
      <ConfirmationDialog
        isOpen={publishDialog.show}
        onYesHandler={() => {
          setPublishDialog({ ...publishDialog, show: false });
          handlePublishPlan(publishDialog.planId);
        }}
        onNoHandler={() => {
          setPublishDialog({ ...publishDialog, show: false });
        }}
        dialogTitle={`Publish ${publishDialog.planName}?`}
        dialogContent={`Are you sure you want to publish ${publishDialog.planName}?`}
      />
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error on this page. Please wait a moment and refresh or try again. If the
          problem persists, contact your administrator.
        </Typography>
        <Button
          className={classes.errorButton}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Divider />
          <Table className={classes.technicalDetailsTable}>
            <TableHead>
              <TableRow key="errorHead">
                <TableCell>
                  <Typography variant="body2" color="error">
                    {pageErrors?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageErrors?.graphQLErrors &&
                pageErrors.graphQLErrors.map((error, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2" color="error">
                        {error.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </section>
  );
}
