import { ApolloError, useMutation, useQuery } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Fade,
  fade,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { format } from "date-fns";
import produce from "immer";
import * as React from "react";
import { AlertDialog } from "../../../components/dialogs/AlertDialog";
import { CenteredCircularLoading } from "../../../components/loading/CenteredCircularLoading";
import { OptionsTable } from "../../../components/tables/OptionsTable";
import { FormularyTier } from "../../../Flex/Formularies/FormularyPage/types";
import { sharedStyles } from "../../../Flex/styles";
import { Bool, toBoolean, toBooleanString } from "../../../utils/Boolean";
import {
  GetAccumulatorsResponse,
  GetFormularyTiersResponse,
  GET_FORMULARY_TIERS,
  GET_PLAN_ACCUMULATORS,
  SaveAccumulatorsInput,
  SAVE_ACCUMULATORS,
} from "../../api";
import { useBenefitRouteParams } from "../../BenefitsRouteUtils";
import {
  Accumulator,
  PlanAccumulatorDeductible,
  PlanAccumulatorMaxOOP,
  PlanAccumulators,
  toPlanAccumulator,
} from "../../types";
import { AccumulatorCategoryTable } from "./AccumulatorTable";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "5rem",
  },
  tierAvatar: {
    width: "2em",
    height: "2em",
    background: "transparent",
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.text.primary}`,
    marginRight: theme.spacing(),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  datePickerToolbar: {
    display: "flex",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(-2),
    justifyContent: "center",
  },
  marginRightSpacing: {
    marginRight: theme.spacing(),
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "fit-content",
  },
}));

const DEFAULT_PLAN_ACCUMULATOR: PlanAccumulators = {
  id: "",
  planId: "",
  annualResetDate: "",
  hasDeductible: false,
  hasMaxOutOfPocket: false,
  deductible: null,
  maxOop: null,
};

const DEFAULT_DEDUCTIBLE: PlanAccumulatorDeductible = {
  excludedTiers: [],
  isEmbedded: true, //TODO DON"T ASSUME TRUE
  isIntegrated: true, //TODO DON"T ASSUME TRUE
  inNetwork: {
    individual: null,
    family: null,
    individualPlus1: null,
  },
  outOfNetwork: {
    individual: null,
    family: null,
    individualPlus1: null,
  },
};

const DEFAULT_MAX_OOP: PlanAccumulatorMaxOOP = {
  excludedTiers: [],
  isEmbedded: true, //TODO DON"T ASSUME TRUE
  isIntegrated: true, //TODO DON"T ASSUME TRUE
  inNetwork: {
    individual: null,
    family: null,
    individualPlus1: null,
  },
  outOfNetwork: {
    individual: null,
    family: null,
    individualPlus1: null,
  },
  mailOrder: {
    individual: null,
    family: null,
    individualPlus1: null,
  },
};

interface Props {
  formularyId: string | null;
}

export function PlanAccumulatorsSection(props: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const { planId } = useBenefitRouteParams();

  const [accumulators, setAccumulators] = React.useState<PlanAccumulators>(
    DEFAULT_PLAN_ACCUMULATOR
  );
  const [availableTiers, setAvailableTiers] = React.useState<Array<FormularyTier>>([]);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<ApolloError | undefined>(undefined);

  //#region Apollo Queries
  const { loading: loadingDetails } = useQuery<GetAccumulatorsResponse, { planId: string }>(
    GET_PLAN_ACCUMULATORS,
    {
      variables: {
        planId: planId as string,
      },
      skip: planId === undefined,
      onCompleted: (res) => {
        setAccumulators(toPlanAccumulator(res.planAccumulator));
      },
      onError: (err) => {
        console.error(err);
        setPageErrors(err);
      },
    }
  );

  const { loading: loadingTiers } = useQuery<GetFormularyTiersResponse, { id: string }>(
    GET_FORMULARY_TIERS,
    {
      skip: !props.formularyId,
      variables: {
        id: props.formularyId as string,
      },
      onCompleted: (res) => {
        if (res) {
          setAvailableTiers(res.formulary.tiers.items);
        }
      },
      onError: (err) => {
        console.error(err);
        setPageErrors(err);
      },
    }
  );

  const [saveAccumulatorChanges, { loading: isSaving }] = useMutation<
    { id: string },
    SaveAccumulatorsInput
  >(SAVE_ACCUMULATORS, {
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });
  //#endregion

  function handleSaveAccumulators(updatedAccumulator: PlanAccumulators) {
    saveAccumulatorChanges({
      variables: {
        planId: planId!,
        input: {
          annualResetDate: updatedAccumulator.annualResetDate,
          hasDeductible: updatedAccumulator.hasDeductible,
          hasMaxOutOfPocket: updatedAccumulator.hasMaxOutOfPocket,
          deductible: updatedAccumulator.deductible,
          maxOop: updatedAccumulator.maxOop,
        },
      },
    });
  }

  function handleChangeDate(date: Date | null) {
    if (date) {
      const newAccumulators: PlanAccumulators = {
        ...accumulators,
        annualResetDate: format(date, `--MM-dd`),
      };
      setAccumulators(newAccumulators);
      handleSaveAccumulators(newAccumulators);
    }
  }

  function handleDeductibleSwitch() {
    const newAccumulators: PlanAccumulators = {
      ...accumulators,
      hasDeductible: !accumulators.hasDeductible,
      deductible: !accumulators.hasDeductible ? DEFAULT_DEDUCTIBLE : null,
    };
    setAccumulators(newAccumulators);
    handleSaveAccumulators(newAccumulators);
  }

  function handleMaxOOPSwitch() {
    const newAccumulators = {
      ...accumulators,
      hasMaxOutOfPocket: !accumulators.hasMaxOutOfPocket,
      maxOop: !accumulators.hasMaxOutOfPocket ? DEFAULT_MAX_OOP : null,
    };
    setAccumulators(newAccumulators);
    handleSaveAccumulators(newAccumulators);
  }

  const handleUpdateMultiSelect = (tierType: "deductible" | "maxOOP") => ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    const tiersToAdd = (value as Array<number>)
      .map((tierRank) => availableTiers.find((tier) => tier.rank === tierRank)!)
      .sort((a, b) => a.rank - b.rank);

    if (tierType === "deductible") {
      const updatedAccumulators = produce(accumulators, (draft) => {
        if (draft.deductible) {
          draft.deductible.excludedTiers = tiersToAdd.map((tier) => tier.rank);
        }
      });
      setAccumulators(updatedAccumulators);
      handleSaveAccumulators(updatedAccumulators);
    } else {
      const updatedAccumulators = produce(accumulators, (draft) => {
        if (draft.maxOop) {
          draft.maxOop.excludedTiers = tiersToAdd.map((tier) => tier.rank);
        }
      });
      setAccumulators(updatedAccumulators);
      handleSaveAccumulators(updatedAccumulators);
    }
  };

  function handleChangeDeductibleEmbedStatus(event: React.ChangeEvent<HTMLInputElement>) {
    if (accumulators.deductible) {
      const newAccumulators: PlanAccumulators = {
        ...accumulators,
        deductible: {
          ...accumulators.deductible,
          isEmbedded: toBoolean(event.target.value as Bool),
        },
      };
      setAccumulators(newAccumulators);
      handleSaveAccumulators(newAccumulators);
    }
  }

  function handleChangeDeductibleAccType(event: React.ChangeEvent<HTMLInputElement>) {
    if (accumulators.deductible) {
      const newAccumulators: PlanAccumulators = {
        ...accumulators,
        deductible: {
          ...accumulators.deductible,
          isIntegrated: toBoolean(event.target.value as Bool),
        },
      };
      setAccumulators(newAccumulators);
      handleSaveAccumulators(newAccumulators);
    }
  }

  function handleChangeMaxOOPEmbedStatus(event: React.ChangeEvent<HTMLInputElement>) {
    if (accumulators.maxOop) {
      const newAccumulators: PlanAccumulators = {
        ...accumulators,
        maxOop: { ...accumulators.maxOop, isEmbedded: toBoolean(event.target.value as Bool) },
      };
      setAccumulators(newAccumulators);
      handleSaveAccumulators(newAccumulators);
    }
  }

  function handleChangeMaxOOPAccType(event: React.ChangeEvent<HTMLInputElement>) {
    if (accumulators.maxOop) {
      const newAccumulators: PlanAccumulators = {
        ...accumulators,
        maxOop: { ...accumulators.maxOop, isIntegrated: toBoolean(event.target.value as Bool) },
      };
      setAccumulators(newAccumulators);
      handleSaveAccumulators(newAccumulators);
    }
  }

  const deductibleExtras = [
    {
      label: "Embed status",
      options: (
        <RadioGroup
          value={toBooleanString(accumulators.deductible?.isEmbedded)}
          onChange={handleChangeDeductibleEmbedStatus}
        >
          <FormControlLabel value="true" control={<Radio color="primary" />} label="Embedded" />
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="Non-embedded"
          />
        </RadioGroup>
      ),
    },
    {
      label: "Accumulator type",
      options: (
        <RadioGroup
          value={toBooleanString(accumulators.deductible?.isIntegrated)}
          onChange={handleChangeDeductibleAccType}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="Medical and Rx"
          />
          <FormControlLabel value="false" control={<Radio color="primary" />} label="Rx only" />
        </RadioGroup>
      ),
    },
    {
      label: "Excluded formulary tiers",
      options: (
        <Select
          fullWidth
          multiple
          value={accumulators.deductible?.excludedTiers ?? []}
          onChange={handleUpdateMultiSelect("deductible")}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          style={{ minWidth: 150, maxWidth: 225 }}
        >
          {loadingTiers ? (
            <MenuItem key="loadingTiers" disabled>
              <CircularProgress size={20} className={classes.marginRightSpacing} /> Loading Tiers...
            </MenuItem>
          ) : availableTiers.length === 0 ? (
            <MenuItem key="noTiers" disabled>
              No tiers to exclude.
            </MenuItem>
          ) : (
            availableTiers.map((tier) => (
              <MenuItem key={tier.id} value={tier.rank}>
                <Avatar className={classes.tierAvatar}>{tier.rank}</Avatar>
                {tier.name}
              </MenuItem>
            ))
          )}
        </Select>
      ),
    },
  ];

  const maxOOPExtras = [
    {
      label: "Embed status",
      options: (
        <RadioGroup
          value={toBooleanString(accumulators.maxOop?.isEmbedded)}
          onChange={handleChangeMaxOOPEmbedStatus}
        >
          <FormControlLabel value="true" control={<Radio color="primary" />} label="Embedded" />
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="Non-embedded"
          />
        </RadioGroup>
      ),
    },
    {
      label: "Accumulator type",
      options: (
        <RadioGroup
          value={toBooleanString(accumulators.maxOop?.isIntegrated)}
          onChange={handleChangeMaxOOPAccType}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="Medical and Rx"
          />
          <FormControlLabel value="false" control={<Radio color="primary" />} label="Rx only" />
        </RadioGroup>
      ),
    },
    {
      label: "Excluded formulary tiers",
      options: (
        <Select
          fullWidth
          multiple
          value={accumulators.maxOop?.excludedTiers ?? []}
          onChange={handleUpdateMultiSelect("maxOOP")}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          style={{ minWidth: 150, maxWidth: 225 }}
        >
          {loadingTiers ? (
            <MenuItem key="loadingTiers" disabled>
              <CircularProgress size={20} className={classes.marginRightSpacing} /> Loading Tiers...
            </MenuItem>
          ) : availableTiers.length === 0 ? (
            <MenuItem key="noTiers" disabled>
              No tiers to exclude.
            </MenuItem>
          ) : (
            availableTiers.map((tier) => (
              <MenuItem key={tier.id} value={tier.rank}>
                <Avatar className={classes.tierAvatar}>{tier.rank}</Avatar>
                {tier.name}
              </MenuItem>
            ))
          )}
        </Select>
      ),
    },
  ];

  const deductibleTableInfo: Accumulator = {
    kind: "deductible",
    details: accumulators.deductible,
  };
  const maxOopTableInfo: Accumulator = { kind: "maxOOP", details: accumulators.maxOop };

  function handleUpdateAccumulatorTable(updatedAccumulatorInfo: Accumulator) {
    const updatedAccumulators: PlanAccumulators =
      updatedAccumulatorInfo.kind === "deductible"
        ? { ...accumulators, deductible: updatedAccumulatorInfo.details }
        : { ...accumulators, maxOop: updatedAccumulatorInfo.details };
    setAccumulators(updatedAccumulators);
    handleSaveAccumulators(updatedAccumulators);
  }

  return loadingDetails ? (
    <CenteredCircularLoading />
  ) : (
    <>
      <div className={classes.datePickerToolbar}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            label="Accumulators annual reset date"
            variant="dialog"
            inputVariant="outlined"
            InputAdornmentProps={{ position: "end" }}
            format="MMMM do"
            value={new Date(accumulators.annualResetDate).setFullYear(new Date().getFullYear())}
            onChange={handleChangeDate}
            InputProps={{ readOnly: true }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <section className={sharedClasses.tabContent}>
        <div className={sharedClasses.fab} style={{ position: "fixed" }}>
          <Fade in={isSaving}>
            <CircularProgress />
          </Fade>
        </div>
        <Card className={sharedClasses.formularyCard}>
          <CardHeader
            title="Deductible"
            action={
              <Switch
                color="primary"
                checked={accumulators.hasDeductible}
                onChange={handleDeductibleSwitch}
              />
            }
          ></CardHeader>
          <Divider />
          <CardContent>
            <Fade in={!accumulators.hasDeductible} timeout={250}>
              <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                Not applicable.
              </Typography>
            </Fade>
            <Collapse in={accumulators.hasDeductible}>
              <AccumulatorCategoryTable
                accumulator={deductibleTableInfo}
                onUpdateAccumulator={handleUpdateAccumulatorTable}
              />
              <OptionsTable settings={deductibleExtras} />
            </Collapse>
          </CardContent>
        </Card>
        <Card className={sharedClasses.formularyCard}>
          <CardHeader
            title="Max out of pocket"
            action={
              <Switch
                color="primary"
                checked={accumulators.hasMaxOutOfPocket}
                onChange={handleMaxOOPSwitch}
              />
            }
          ></CardHeader>
          <Divider />
          <CardContent>
            <Fade in={!accumulators.hasMaxOutOfPocket} timeout={250}>
              <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                Not applicable.
              </Typography>
            </Fade>
            <Collapse in={accumulators.hasMaxOutOfPocket}>
              <AccumulatorCategoryTable
                accumulator={maxOopTableInfo}
                onUpdateAccumulator={handleUpdateAccumulatorTable}
              />
              <OptionsTable settings={maxOOPExtras} />
            </Collapse>
          </CardContent>
        </Card>
      </section>
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error on this page. Please wait a moment and refresh or try again. If the
          problem persists, contact your administrator.
        </Typography>
        <Button
          className={classes.errorButton}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Divider />
          <Table className={classes.technicalDetailsTable}>
            <TableHead>
              <TableRow key="errorHead">
                <TableCell>
                  <Typography variant="body2" color="error">
                    {pageErrors?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageErrors?.graphQLErrors &&
                pageErrors.graphQLErrors.map((error, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2" color="error">
                        {error.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </>
  );
}
