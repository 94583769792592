import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import ExpandLess from "@material-ui/icons/ChevronRightRounded";
import ExpandMore from "@material-ui/icons/ExpandMoreRounded";
import SubAccountIcon from "@material-ui/icons/SupervisorAccountRounded";
import produce from "immer";
import * as React from "react";
import { SubAccount } from "../LoginTypes";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  buttonWrapper: {
    position: "relative",
    width: "100%",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    marginBottom: "0.5em",
  },
  selectFormControl: {
    width: "100%",
    minWidth: "200px",
  },
  buttonRow: {
    position: "relative",
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  continueButton: {
    marginLeft: "0.25rem",
  },
  subaccountList: {
    maxHeight: 500,
    overflow: "auto",
  },
  subaccountListItemText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  listItem: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
}));

type Props = {
  availableSubAccounts: Array<SubAccount>;
  onSelectSubaccount: (subAccountId: string) => Promise<void>;
  onBackClick: () => void;
};

export const SubaccountForm: React.FC<Props> = (props) => {
  const [selectedSubAccountId, setSelectedSubAccountId] = React.useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [organizedSubaccounts, setOrganizedSubaccounts] = React.useState(
    organizeSubaccounts(props.availableSubAccounts)
  );

  const classes = useStyles();

  const loginUserWithSubaccount = async () => {
    try {
      setIsLoading(true);
      if (selectedSubAccountId !== null && selectedSubAccountId !== undefined) {
        props.onSelectSubaccount(selectedSubAccountId);
      }
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        setError("Error Selecting Sub Account. Please Try Logging in Again.");
      } else {
        setError("Error Logging In. Please Try Again.");
      }
      setIsLoading(false);
      console.error(error);
    }
  };

  const renderSubaccountList = (subaccounts: Array<SubAccount>) => {
    return (
      <List style={{ backgroundColor: "white" }} disablePadding>
        {subaccounts
          .sort((subaccountA, subaccountB) => (subaccountA.name > subaccountB.name ? 1 : -1))
          .map((subaccount, index) => (
            <ListItem
              button
              selected={subaccount.id === selectedSubAccountId}
              className={classes.listItem}
              classes={{ selected: classes.selected }}
              key={index}
              onClick={() => {
                setSelectedSubAccountId(subaccount.id);
              }}
            >
              <ListItemText
                primary={subaccount.name}
                primaryTypographyProps={{
                  variant: "body2",
                  color: "inherit",
                }}
              />
            </ListItem>
          ))}
      </List>
    );
  };

  const accounts = Object.keys(organizedSubaccounts);
  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <SubAccountIcon />
          </Avatar>
        }
        title="Choose a Subaccount"
      />
      <Divider />
      <CardContent style={{ padding: 0, maxHeight: "75vh", overflow: "auto" }}>
        {accounts.length > 1
          ? accounts
              .sort((accountNameA, accountNameB) => (accountNameA > accountNameB ? 1 : -1))
              .map((accountName, index) => {
                const { subaccounts, isExpanded } = organizedSubaccounts[accountName];
                return (
                  <React.Fragment key={`account-${index}`}>
                    <ListItem
                      button
                      onClick={() => {
                        setOrganizedSubaccounts(
                          produce(organizedSubaccounts, (draft) => {
                            draft[accountName].isExpanded = !isExpanded;
                          })
                        );
                      }}
                    >
                      <ListItemText
                        primary={accountName}
                        primaryTypographyProps={{
                          variant: "body2",
                          color: "inherit",
                        }}
                      />
                      {isExpanded ? <ExpandMore /> : <ExpandLess />}
                    </ListItem>
                    <Collapse in={isExpanded}>{renderSubaccountList(subaccounts)}</Collapse>
                  </React.Fragment>
                );
              })
          : accounts.length === 1
          ? renderSubaccountList(organizedSubaccounts[accounts[0]].subaccounts)
          : renderSubaccountList([])}
      </CardContent>
      <Divider />
      <CardActions>
        <div className={classes.buttonWrapper}>
          <Button
            fullWidth
            className={classes.continueButton}
            color="primary"
            variant="contained"
            disabled={selectedSubAccountId === undefined || isLoading}
            onClick={loginUserWithSubaccount}
          >
            Continue
          </Button>
          {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </CardActions>
      {error && <Typography color="error">{error}</Typography>}
    </Card>
  );
};

type AccountMap = {
  [AccountName: string]: { subaccounts: Array<SubAccount>; isExpanded: boolean };
};

const organizeSubaccounts = (subaccounts: Array<SubAccount>): AccountMap => {
  return subaccounts.reduce((accountMap, subaccount) => {
    if (accountMap[subaccount.accountName]) {
      accountMap[subaccount.accountName].subaccounts.push(subaccount);
    } else {
      accountMap[subaccount.accountName] = { subaccounts: [subaccount], isExpanded: false };
    }
    return accountMap;
  }, {} as AccountMap);
};
