import { Card, CardActionArea, CardContent, Divider, Typography } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import { FLEX_PRIMARY_COLOR, TEMPLATE_PRIMARY_COLOR } from "../styles/Theme";

export const Home: React.FC = () => {
  const {
    user: { subAccountName },
  } = React.useContext(UserContext);
  const history = useHistory();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gridColumn: "6 / span 6",
      }}
    >
      <Typography variant="body1">Welcome {subAccountName}</Typography>
      <Typography align="center" variant="h5">
        Get started by choosing an App from the sidebar on the left, or choose from an option below:
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: 20,
        }}
      >
        <Card
          style={{
            marginRight: 40,
            maxWidth: 250,
            height: 150,
            display: "flex",
          }}
        >
          <CardActionArea
            onClick={() => {
              history.push("/templates/formulary/new");
            }}
          >
            <CardContent
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="center" variant="h6">
                Make a new Template with{" "}
                <span style={{ color: TEMPLATE_PRIMARY_COLOR }}>Template</span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Divider orientation="vertical" />
        <Card
          style={{
            marginLeft: 40,
            maxWidth: 250,
            height: 150,
            display: "flex",
          }}
        >
          <CardActionArea
            onClick={() => {
              history.push("/formularies");
            }}
          >
            <CardContent
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="center" variant="h6">
                Manage your Formularies in <span style={{ color: FLEX_PRIMARY_COLOR }}>Flex</span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    </div>
  );
};
