import { TierOptionTableItem } from "../components/tables/TiersOptionsTable";
import { APIEditNetworkItem } from "./api";

export interface NetworkState {
  id?: string;
  name: string;
  code: string | null;
  region: string | null;
  externalId: string | null;
  lineOfBusiness: string;
  effectiveDate: string;
  terminationDate: string | null;
  tiers: Array<NetworkTier>;
}

export interface NetworkTier extends TierOptionTableItem {
  id?: string;
}

export function toNetworkState(networkRes: APIEditNetworkItem): NetworkState {
  return {
    id: networkRes.id,
    code: networkRes.code,
    effectiveDate: networkRes.effectiveDate,
    externalId: networkRes.externalId,
    lineOfBusiness: networkRes.lineOfBusiness,
    name: networkRes.name,
    region: networkRes.region,
    terminationDate: networkRes.terminationDate,
    tiers: networkRes.tiers.items.map<NetworkTier>((tier) => ({
      id: tier.id,
      name: tier.name,
      itemSubAccountId: tier.subAccountNetworkTierId,
      order: tier.tierOrder,
    })),
  };
}
