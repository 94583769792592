import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { format } from "date-fns";
import * as React from "react";
import { CenteredCircularLoading } from "../../../components/loading/CenteredCircularLoading";
import { UserContext } from "../../../components/UserContext";
import { ProductHistory } from "../../../types/DrugSearchTypes";
import { executeQuery } from "../../../utils/GraphQL";

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: "65vh",
    margin: 10,
    overflow: "auto",
  },
  fullWidth: {
    width: "100%",
  },
  tableCell: {
    maxWidth: 110,
    borderBottom: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tableCellOld: {
    maxWidth: 110,
    backgroundColor: red[100],
    borderBottom: 0,
  },
  tableCellNew: {
    maxWidth: 110,
    backgroundColor: green[100],
    borderBottom: 0,
  },
});

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting<K extends keyof any>(
  order: "asc" | "desc",
  orderBy: K
): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headerCells = [
  { id: "beginDate", label: "Date" },
  { id: "attribute", label: "Attribute Changed" },
  { id: "oldValue", label: "Old Value" },
  { id: "newValue", label: "New Value" },
];

export const DrugHistoryTable: React.FC<{
  productUuid: string;
}> = (props) => {
  const classes = useStyles();
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState<string>("beginDate");
  const [productHistory, setProductHistory] = React.useState<Array<ProductHistory>>([]);
  const {
    user: { drugSourceId },
  } = React.useContext(UserContext);

  React.useEffect(() => {
    if (props.productUuid !== "") {
      getProductHistory(drugSourceId, props.productUuid)
        .then((productHistory) => {
          setProductHistory(productHistory);
          setHasLoaded(true);
        })
        .catch((err) => {
          //TODO: Proper error handling
          console.error("get product history effect error", err);
        });
    }
  }, [props.productUuid, drugSourceId]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return hasLoaded ? (
    <Paper className={classes.tableContainer}>
      <Table size="medium" stickyHeader>
        <TableHead>
          <TableRow>
            {headerCells.map((headerCell) => {
              return (
                <TableCell
                  key={headerCell.id}
                  sortDirection={orderBy === headerCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headerCell.id}
                    direction={orderBy === headerCell.id ? order : "asc"}
                    onClick={() => {
                      handleRequestSort(headerCell.id);
                    }}
                  >
                    {headerCell.label}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(
            productHistory.map((historyItem) => {
              return {
                ...historyItem,
                beginDate: historyItem.beginDate.getTime(),
              };
            }),
            getSorting(order, orderBy)
          ).map((change, index) => {
            return (
              <TableRow hover key={`${change.attribute}-${index}-row`}>
                <TableCell className={classes.tableCell}>
                  {format(change.beginDate, "MMM do, yyy")}
                </TableCell>
                <TableCell className={classes.tableCell}>{change.attribute}</TableCell>
                <TableCell className={classes.tableCellOld}>{change.oldValue}</TableCell>
                <TableCell className={classes.tableCellNew}>{change.newValue}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  ) : (
    <CenteredCircularLoading />
  );
};

const getProductHistory = (
  drugSourceUuid: string,
  productUuid: string
): Promise<ProductHistory[]> => {
  return executeQuery<{ productHistory: ProductHistory[] }>(`
    query {
      productHistory(drugSourceUuid: "${drugSourceUuid}", productUuid: "${productUuid}") {
        attribute
        oldValue
        newValue
        beginDate
      }
    }
  `).then((response) => {
    if (response.errors) {
      console.error("product history errors: ", response.errors);
    }
    if (response.data) {
      return response.data.productHistory.map((history) => {
        return {
          ...history,
          beginDate: new Date(history.beginDate),
        };
      });
    } else {
      console.warn("no product history data");
      return [];
    }
  });
};
