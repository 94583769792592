import { Avatar, IconButton, makeStyles, Typography } from "@material-ui/core";
import Questionmark from "@material-ui/icons/HelpRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { WebSearchTemplatesManagementPage } from "./constants";
import { WebSearchPreview } from "./Preview/WebSearchPreview";
import { WebSearchTemplate, WebSearchTemplateStatus } from "./types";
import { WebSearchTemplateForm } from "./WebSearchTemplateForm";

const useStyles = (templateColor: string | undefined) =>
  makeStyles(() => {
    const editsColor = templateColor ?? "black";
    return {
      previewArea: {
        gridColumn: "7 / 17",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        borderLeft: "1px solid lightgray",
      },
      tierEdit: {
        backgroundColor: editsColor,
        border: `2px solid ${editsColor}`,
      },
      editAvatar: {
        color: editsColor,
        border: `2px solid ${editsColor}`,
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
    };
  });

type Props = {
  templateStatus: WebSearchTemplateStatus;
  updateTemplate: (template: WebSearchTemplate) => void;
  saveTemplate: () => void;
};

export const WebSearchTemplatePageContainer: React.FC<Props> = (props) => {
  const classes = useStyles(
    props.templateStatus.status !== "loading"
      ? props.templateStatus.template.settings.primaryColor
      : undefined
  )();
  const history = useHistory();

  const [isDialogVisible, setDialogVisibility] = React.useState(false);

  const handleBackButtonClick = () => {
    history.push(WebSearchTemplatesManagementPage);
  };

  return (
    <>
      {props.templateStatus.status === "loading" ? (
        <div style={{ gridColumn: "span 16" }}>
          <CenteredCircularLoading />
        </div>
      ) : (
        <>
          <WebSearchTemplateForm
            template={props.templateStatus.template}
            isSaving={props.templateStatus.status === "saving"}
            onUpdateTemplate={props.updateTemplate}
            onBackButtonClick={handleBackButtonClick}
            saveTemplate={props.saveTemplate}
          />
          <div className={classes.previewArea}>
            <WebSearchPreview
              template={props.templateStatus.template}
              additionalContent={
                <>
                  <Typography
                    variant="h5"
                    style={{
                      color: props.templateStatus.template.settings.primaryColor,
                    }}
                  >
                    Samples for Testing
                  </Typography>
                  <IconButton size="small" style={{ width: "fit-content" }}>
                    <Avatar className={classes.tierEdit}>T1</Avatar>
                  </IconButton>
                  <IconButton size="small" style={{ width: "fit-content" }}>
                    <Avatar className={classes.editAvatar}>PA</Avatar>
                  </IconButton>
                </>
              }
            />
          </div>
        </>
      )}
      <ConfirmationDialog
        isOpen={isDialogVisible}
        onYesHandler={() => {
          history.push(WebSearchTemplatesManagementPage);
        }}
        onNoHandler={() => {
          setDialogVisibility(!isDialogVisible);
        }}
        dialogIcon={<Questionmark fontSize="large" color="inherit" />}
        dialogTitle="Warning"
        dialogContent={
          <Typography variant="body1">
            Leaving this page will result in the loss of all unsaved changes. Are you sure you want
            to leave?
          </Typography>
        }
      />
    </>
  );
};
