import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Check from "@material-ui/icons/DoneRounded";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import ValidationErrorIcon from "@material-ui/icons/PriorityHighRounded";
import * as React from "react";
import { useParams } from "react-router-dom";
import { AlertDialog } from "../../../../../components/dialogs/AlertDialog";
import { ValidateHPMSFilesInput, ValidateHPMSFilesResponse, VALIDATE_HPMS_FILES } from "../../api";
import {
  APIFileType,
  FileFormatType,
  HPMSFileInfo,
  HPMSFileValidationErrorResponse,
} from "../../types";

const useStyles = makeStyles({
  thirdStep: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "75%",
  },
  instructions: {
    position: "absolute",
    top: 0,
    textAlign: "center",
  },
  stepWrapper: {
    display: "flex",
    height: "calc(100% - 40px)",
  },
  fileRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dividerMargins: {
    marginLeft: 20,
    marginRight: 20,
  },
  buttonLoadingWrapper: {
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
  fileValidatingSpinner: {
    display: "inline-block",
    flexShrink: 0,
  },
});

/**
 * TODOs
 *
 *  Loading next to "waiting" button might be positioned differently in original...
 */

type Props = {
  formularyFiles: Array<HPMSFileInfo>;
  onValidationSuccess: () => void;
  onValidationFailure: (validationErrors: Array<HPMSFileValidationErrorResponse>) => void;
  uploadLocationPrefix: string;
};

export const ThirdStep: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { formularyId } = useParams<any>();

  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);

  const [
    validateHPMSFiles,
    { loading: isValidating, data, error: validationRequestErrors },
  ] = useMutation<ValidateHPMSFilesResponse, ValidateHPMSFilesInput>(VALIDATE_HPMS_FILES);

  const validateAllFiles = () => {
    if (!isValidating && formularyId) {
      validateHPMSFiles({
        variables: {
          formularyId,
          fileFormatType: FileFormatType.HPMS,
          locationPrefix: props.uploadLocationPrefix,
          fileMapping: props.formularyFiles.map(({ name, type }) => ({ name, type })),
        },
      })
        .then((res) => {
          if (res.data?.validateFormularyFiles) {
            const { invalidReasons, isValid } = res.data.validateFormularyFiles;
            if (isValid) {
              props.onValidationSuccess();
            } else {
              console.error("invalid reasons", invalidReasons);
              if (invalidReasons) {
                props.onValidationFailure(invalidReasons);
              }
            }
          }
        })
        .catch((e) => {
          console.error("Error validating files", e);
        });
    }
  };

  const renderMainContent = () => {
    if (props.formularyFiles.every((file) => file.status === "validated")) {
      return (
        <Typography align="center">
          Files successfully validated. Click next to continue.
        </Typography>
      );
    } else if (validationRequestErrors && validationRequestErrors.graphQLErrors.length !== 0) {
      return (
        <>
          <ErrorIcon color="error" fontSize="large" />
          <Typography align="center">
            Sorry, something went wrong while validating your files. The files may not be compatible
            with this formulary. If this result is unexpected, double check your files and try
            again.
          </Typography>
          <Button
            color="primary"
            onClick={() => {
              setShowTechnicalErrors(true);
            }}
          >
            See Details
          </Button>
          <AlertDialog
            isOpen={showTechnicalErrors}
            onExitHandler={() => {
              setShowTechnicalErrors(false);
            }}
            dialogTitle="Validation Problems"
            isError
          >
            <Table>
              <TableBody>
                {validationRequestErrors.graphQLErrors.map((error, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography color="error" variant="body2">
                          {error.message}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </AlertDialog>
        </>
      );
    } else if (data && data.validateFormularyFiles.invalidReasons) {
      return (
        <>
          <ErrorIcon color="error" fontSize="large" />
          <Typography align="center">
            Looks like there was an error validating your data. Please check the files uploaded and
            make sure they are correct for this formulary.
          </Typography>
          <Button
            color="primary"
            onClick={() => {
              setShowTechnicalErrors(true);
            }}
          >
            See Details
          </Button>
          <AlertDialog
            isOpen={showTechnicalErrors}
            onExitHandler={() => {
              setShowTechnicalErrors(false);
            }}
            dialogTitle="Validation Problems"
            isError
          >
            {data.validateFormularyFiles.invalidReasons.map((reason) => {
              return (
                <Table>
                  <TableBody>
                    {reason.serverMessage ? (
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2">
                            {`${APIFileType[reason.fileType]} Error:`}
                          </Typography>
                          <Typography color="error">{reason.serverMessage}</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      reason.fileErrors.map((fileError, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="body2">
                                {`${APIFileType[reason.fileType]} Error:`}
                              </Typography>
                              <Typography variant="body2" color="error">
                                {fileError.name}
                              </Typography>
                              <Typography color="error">{fileError.details}</Typography>
                              <Typography color="error">
                                {`Records with issue(s): ${fileError.recordIds.toString()}`}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              );
            })}
          </AlertDialog>
        </>
      );
    } else {
      return (
        <>
          <Typography align="center" className={classes.instructions}>
            Click the button below to make sure these files are fit for this specific formulary.
          </Typography>
          <div className={classes.buttonLoadingWrapper}>
            <Button
              onClick={validateAllFiles}
              color="primary"
              variant="contained"
              disabled={isValidating}
            >
              {isValidating ? "Working" : "Check"}
            </Button>
            {isValidating && <CircularProgress style={{ position: "absolute", right: -50 }} />}
          </div>
        </>
      );
    }
  };

  return (
    <div className={classes.stepWrapper}>
      <div style={{ width: "25%" }}>
        <Typography variant="body2">Files:</Typography>
        {props.formularyFiles.map((file) => (
          <div key={file.name} className={classes.fileRow}>
            <Typography noWrap>{file.name}</Typography>
            {file.status === "error" ? (
              <ValidationErrorIcon color="error" />
            ) : isValidating ? (
              <CircularProgress size="1em" className={classes.fileValidatingSpinner} />
            ) : (
              <Check
                htmlColor={green[500]}
                visibility={file.status === "validated" ? "show" : "hidden"}
              />
            )}
          </div>
        ))}
      </div>
      <Divider orientation="vertical" className={classes.dividerMargins} />
      <div className={classes.thirdStep}>{renderMainContent()}</div>
    </div>
  );
};
