import { makeStyles, Theme } from "@material-ui/core";

export const sharedStyles = makeStyles((theme: Theme) => ({
  tabContent: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  formularyCard: {
    height: "fit-content",
    margin: theme.spacing(),
    minWidth: 585,
  },
  fab: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: theme.spacing(2.5),
    right: theme.spacing(6),
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
  centeredLoadingSpinner: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorIcon: {
    marginLeft: "5px",
    color: "red",
  },
  table: {
    width: "100%",
  },
  wrapper: {
    position: "relative",
  },
}));
