import * as React from "react";
import {
  Typography,
  makeStyles,
  Card,
  Box,
  CardContent,
  Collapse,
  IconButton,
  fade,
} from "@material-ui/core";
import CollapseIcon from "@material-ui/icons/UnfoldLessRounded";
import ExpandIcon from "@material-ui/icons/UnfoldMoreRounded";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EFEFEF",
    borderTop: `1px solid ${fade(theme.palette.common.black, 0.1)}`,
    borderBottom: `1px solid ${fade(theme.palette.common.black, 0.1)}`,
  },
  expand: {
    height: "fit-content",
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  generic: {
    fontStyle: "italic",
    textTransform: "lowercase",
  },
  brand: {
    textTransform: "uppercase",
  },
}));

type DrugMetaDataProp = {
  displayName: string;
  ndc: string;
  drugAttributes: { [name: string]: string | number | undefined };
  isGeneric?: boolean;
};

type DefinedAttributes = { [name: string]: React.ReactText };

export const DrugMetaDataCard: React.FC<DrugMetaDataProp> = (props) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const definedAttributes: DefinedAttributes = Object.keys(props.drugAttributes).reduce<
    DefinedAttributes
  >((definedAttrs, curKey) => {
    const value = props.drugAttributes[curKey];
    if (value !== undefined && value !== null) {
      return { ...definedAttrs, [curKey]: value };
    } else {
      return definedAttrs;
    }
  }, {});
  const attributeNames: string[] = Object.keys(definedAttributes);

  return (
    <Card className={classes.card} elevation={0} square>
      <CardContent style={{ width: "100%" }}>
        <Box display="flex">
          <Box width={5 / 6}>
            <Typography className={props.isGeneric ? classes.generic : classes.brand}>
              {props.displayName}
            </Typography>
            <DrugAttribute name="NDC" value={props.ndc} />
            <Collapse in={isExpanded}>
              {attributeNames.map((name) => (
                <DrugAttribute key={name} name={name} value={definedAttributes[name]} />
              ))}
            </Collapse>
          </Box>
        </Box>
      </CardContent>
      <IconButton
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        className={classNames({ [classes.expandOpen]: isExpanded }, classes.expand)}
      >
        {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
      </IconButton>
    </Card>
  );
};

const DrugAttribute: React.FC<{
  name: React.ReactText;
  value: React.ReactText;
}> = (props) => {
  return (
    <Box key={props.name} display="flex" width="100%">
      <Box width={1 / 3}>
        <Typography variant="body2" color="primary">
          {`${props.name}`}:
        </Typography>
      </Box>
      <Typography>{props.value}</Typography>
    </Box>
  );
};
