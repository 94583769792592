import { Button, LinearProgress, ThemeProvider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Sidebar } from "../dashboard/sidebar/Sidebar";
import { AppSettings, getUser, User } from "../utils/User";
import { MainContentRoutes } from "./MainContent";
import { UserContext } from "./UserContext";
import { GeneralErrorPage } from "./screens/broken";
import { baseTheme } from "../styles/Theme";
import { AccountSwitcher } from "./AccountSwitcher";

/**
 * The app component is special in that it is the root component of the component tree.
 * In the component hierarchy every other component is beneath App Component
 */

const useStyles = makeStyles({
  app: {
    display: "flex",
  },
  centeredContentContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

type AppStatus =
  | { type: "loading" }
  | { type: "error" }
  | { type: "redirectError" } // if the subaccount is not valid, redirect the user
  | { type: "maintenance" }
  | {
      type: "dataLoaded";
      currentUser: User;
      sidebarSize: string;
    };

const App: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [appState, setAppState] = React.useState<AppStatus>({
    type: "loading",
  });

  React.useEffect(() => {
    if (appState.type === "loading") {
      getUser()
        .then((user) => {
          const storedSidebarSize = localStorage.getItem("sidebar");
          setAppState({
            type: "dataLoaded",
            currentUser: user,
            sidebarSize: storedSidebarSize === null ? "full" : storedSidebarSize,
          });
        })
        .catch((error) => {
          console.error(error);
          setAppState({ type: "error" });
        });
    }
  }, [appState.type]);

  function handleRedirectError(_: unknown) {
    history.push("/");
  }

  function handleUpdateSettings(newSettings: AppSettings) {
    //do stuff
    if (appState.type === "dataLoaded") {
      const updateUserSettings = {
        ...appState,
        currentUser: { ...appState.currentUser, settings: newSettings },
      };
      setAppState(updateUserSettings);
    }
  }

  switch (appState.type) {
    case "error":
      return <GeneralErrorPage />;
    case "maintenance":
      return (
        <div className={classes.centeredContentContainer}>
          <Typography variant="h4">Undergoing Maintenance</Typography>
          <Typography variant="h6">
            We are currently undergoing some maintenance and will be back online soon. We apologize
            for the inconvenience.
          </Typography>
        </div>
      );
    case "redirectError":
      return (
        <div className={classes.centeredContentContainer}>
          <Typography variant="h6">
            Could not verify subaccount. Please click the button below to navigate back home and log
            into a different sub account
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRedirectError}>
            try again
          </Button>
        </div>
      );
    case "loading":
      return (
        <div className={classes.centeredContentContainer}>
          <Typography variant="h4" style={{ marginBottom: "50px" }}>
            Loading...
          </Typography>
          <LinearProgress style={{ width: "800px", height: "2px" }} />
        </div>
      );
    case "dataLoaded":
      return (
        <UserContext.Provider
          value={{ user: appState.currentUser, updateUserSettings: handleUpdateSettings }}
        >
          <div className={classes.app}>
            <AccountSwitcher>
              <ThemeProvider theme={baseTheme(null)}>
                <Sidebar
                  currentSubAccount={appState.currentUser}
                  sidebarSize={appState.sidebarSize}
                />
              </ThemeProvider>
              <MainContentRoutes />
            </AccountSwitcher>
          </div>
        </UserContext.Provider>
      );
  }
};

export default App;
