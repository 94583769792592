import { makeStyles, Paper } from "@material-ui/core";
import * as React from "react";
import { MessageScreen } from "../../components/screens/MessageScreen";
import { UserContext } from "../../components/UserContext";
import { FdbSearchGrid } from "./FdbSearch";
import { MspanSearchGrid } from "./MspanSearch";

const useStyles = makeStyles({
  tableContainer: {
    gridColumn: "span 16",
    margin: 20,
    height: "95vh",
  },
});

export const DrugSearch: React.FC = () => {
  //TODO this will be replaced with some kind of access to the subaccount information
  const classes = useStyles();
  const {
    user: { drugSourceShort },
  } = React.useContext(UserContext);

  const chooseSearchGrid = (drugSourceShort: string) => {
    switch (drugSourceShort) {
      case "FDB":
        return <FdbSearchGrid />;
      case "MSPAN":
        return <MspanSearchGrid />;
      default:
        console.error("did not handle sub account drug source:", drugSourceShort);
        return (
          <MessageScreen>
            There was no drug source provider for your account
            <a href="/">Try reloading the homepage</a>
          </MessageScreen>
        );
    }
  };

  return (
    <Paper className={classes.tableContainer}>
      <div
        className="ag-theme-material"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {chooseSearchGrid(drugSourceShort)}
      </div>
    </Paper>
  );
};
