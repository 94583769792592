import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import TemplateIcon from "@material-ui/icons/StyleRounded";
import classnames from "classnames";
import produce from "immer";
import * as React from "react";
import { OptionsTable } from "../../../../components/tables/OptionsTable";
import { LIST_WEB_SEARCH_TEMPLATES } from "../../../../Templates/WebSearch/api";
import { sharedStyles } from "../../../styles";
import {
  CreateWebSearchSettingsInput,
  CREATE_WEB_SEARCH_SETTINGS,
  ListWebSearchTemplatesResponse,
  UpdateWebSearchSettingsInput,
  UPDATE_WEB_SEARCH_SETTINGS,
} from "../api";
import { FormularyEditingContext } from "../FormularyEditingContext";
import { PublishingTemplate, WebSearchSettingsForm } from "./DocumentTypes";
import { SaveFabButton } from "./SaveFabButton";
import { TemplateList } from "./TemplateList";

const useStyles = makeStyles({
  settingsContainer: {
    gridColumn: "span 12",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 88px - 1px - 48px - 1px)",
    overflow: "auto",
    alignItems: "center",
  },
  tabContent: {
    flexDirection: "column",
  },
  formularyCard: {
    width: 585,
  },
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
    textAlign: "left",
  },
  templateDrawer: {
    minWidth: 350,
    maxWidth: 500,
  },
});

type Props = {
  formularyId: string;
  webSearchFormSettings: WebSearchSettingsForm;
  onUpdateWebSearchSettings: (newSettings: WebSearchSettingsForm) => void;
  onCreate: () => void;
};

export const WebSearchSettings: React.FC<Props> = (props) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const isEditingFormulary = React.useContext(FormularyEditingContext);

  const [showTemplateList, setShowTemplateList] = React.useState(false);
  const [availableTemplates, setAvailableTemplates] = React.useState<
    Map<string, Array<PublishingTemplate>>
  >(new Map());

  const { loading: isLoadingTemplates } = useQuery<ListWebSearchTemplatesResponse>(
    LIST_WEB_SEARCH_TEMPLATES,
    {
      onCompleted: (res) => {
        setAvailableTemplates(
          produce(availableTemplates, (draft) => {
            draft.set("Web Search", res.templates.items || []);
          })
        );
      },
    }
  );

  const [createWebSearchSettings, { loading: isCreating }] = useMutation<
    unknown,
    CreateWebSearchSettingsInput
  >(CREATE_WEB_SEARCH_SETTINGS);
  const [updateWebSearchSettings, { loading: isUpdating }] = useMutation<
    unknown,
    UpdateWebSearchSettingsInput
  >(UPDATE_WEB_SEARCH_SETTINGS);

  const handleUpdateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdateWebSearchSettings({ ...props.webSearchFormSettings, name: event.target.value });
  };
  const handleSelectTemplate = ({ id, name }: PublishingTemplate) => {
    props.onUpdateWebSearchSettings({ ...props.webSearchFormSettings, template: { id, name } });
    setShowTemplateList(false);
  };
  const handleShowTemplateList = () => {
    setShowTemplateList(true);
  };
  const handleSave = () => {
    if (props.webSearchFormSettings.template) {
      const name = props.webSearchFormSettings.name || "Web Search";
      if (props.webSearchFormSettings.id === undefined) {
        createWebSearchSettings({
          variables: {
            formularyId: props.formularyId,
            name,
            templateId: props.webSearchFormSettings.template?.id,
          },
        }).then(() => {
          props.onCreate();
        });
      } else {
        updateWebSearchSettings({
          variables: {
            documentId: props.webSearchFormSettings.id,
            formularyId: props.formularyId,
            name,
            templateId: props.webSearchFormSettings.template.id,
          },
        });
      }
    }
  };

  const isSaving = isCreating || isUpdating;
  return (
    <>
      <div className={classes.settingsContainer}>
        <section className={classnames(sharedClasses.tabContent, classes.tabContent)}>
          <Card className={classnames(sharedClasses.formularyCard, classes.formularyCard)}>
            <CardHeader title="Properties" />
            <Divider />
            <CardContent id="document-properties">
              <OptionsTable
                settings={[
                  {
                    label: "Name",
                    options: (
                      <TextField
                        value={props.webSearchFormSettings.name}
                        disabled={!isEditingFormulary}
                        placeholder={"Web Search"}
                        onChange={handleUpdateName}
                        className={classes.textFieldOptions}
                      />
                    ),
                  },
                  {
                    label: "Template",
                    options: props.webSearchFormSettings.template ? (
                      <TextField
                        value={props.webSearchFormSettings?.template.name || ""}
                        disabled={!isEditingFormulary}
                        className={classes.textFieldOptions}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              size="small"
                              disabled={!isEditingFormulary}
                              onClick={handleShowTemplateList}
                            >
                              <TemplateIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<TemplateIcon />}
                        onClick={handleShowTemplateList}
                      >
                        Choose Template
                      </Button>
                    ),
                  },
                ]}
              />
            </CardContent>
            <Divider />
          </Card>
        </section>
        <SaveFabButton
          isNew={props.webSearchFormSettings.id === undefined}
          isDisabled={props.webSearchFormSettings.template === undefined}
          isSaving={isSaving}
          onClickSave={handleSave}
        />
      </div>
      <Drawer
        variant="temporary"
        anchor="left"
        open={showTemplateList}
        onClose={() => {
          setShowTemplateList(false);
        }}
        PaperProps={{ className: classes.templateDrawer }}
      >
        <TemplateList
          isLoading={isLoadingTemplates}
          templates={availableTemplates}
          onClickTemplate={handleSelectTemplate}
        />
      </Drawer>
    </>
  );
};
