import { makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

type Props = {
  children: JSX.Element;
};

export function CenteredContent(props: Props) {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
}
