import { useMutation, useQuery } from "@apollo/client";
import { Divider, fade, makeStyles, Paper, Typography } from "@material-ui/core";
import compareAsc from "date-fns/compareAsc";
import compareDesc from "date-fns/compareDesc";
import format from "date-fns/format";
import produce from "immer";
import * as React from "react";
import { ConfirmationDialog } from "../../../../components/dialogs/ConfirmationDialog";
import { awsDateStringToDate } from "../../../../utils/Date";
import { toAwsDateStr } from "../../../../utils/GraphQL";
import {
  GET_SNAPSHOT_HISTORY,
  ListDocumentsInput,
  ListDocumentsResponse,
  LIST_FORMULARY_PUBLISHING_CONTENT,
  PublishFormularyDocumentsInput,
  PUBLISH_FORMULARY_DOCUMENTS,
  SnapshotHistoryInput,
  SnapshotHistoryResponse,
  UpdateFormularyPublishDateInput,
  UPDATE_FORMULARY_PUBLISH_DATE,
} from "../api";
import { ApiFormulary, SnapshotHistory } from "../types";
import { DocumentList } from "./DocumentList";
import { DocumentPreview, NothingSelectedMessage, PublishingMessage } from "./DocumentPreview";
import { DocumentSettings } from "./DocumentSettings";
import {
  ApiDocumentTypes,
  ApiFullFormularyDocument,
  apiToFormDocument,
  DocumentForm,
  DocumentRenderStatus,
  WebSearchSettingsForm,
} from "./DocumentTypes";
import { PublishToolbar } from "./PublishToolbar";
import { WebSearchSettings } from "./WebSearchSettings";

const useStyles = makeStyles((theme) => ({
  listHolder: {
    position: "relative",
    gridColumn: "span 4",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  publishingArea: {
    display: "grid",
    gridTemplateColumns: "repeat(16, 1fr)",
    height: "calc(100% - 1px)",
    overflowY: "hidden",
  },
  textEmphasis: {
    fontWeight: "bold",
  },
}));

/**
 * TODOs
 *
 *  newPublishDate state
 *    possible to localize in child component and pass with callback.
 *
 *  WARNING: heuristic fragment matching going on!
 */

type Props = {
  formulary: ApiFormulary;
};

export type SelectedDocument =
  | { kind: "none" }
  | { kind: "document settings"; document: DocumentForm }
  | { kind: "web search settings"; webSearchSettings: WebSearchSettingsForm }
  | { kind: "preview"; document: ApiFullFormularyDocument };

export const FormularyPublishing: React.FC<Props> = (props) => {
  const classes = useStyles();

  const earliestAllowedPublishDate = earliestEffectiveDate(props.formulary.snapshotHistory.items);

  const [newPublishDate, setNewPublishDate] = React.useState<Date | null>(
    earliestAllowedPublishDate
  );
  const [showPublishConfirm, setShouldShowPublishConfirm] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState<SelectedDocument>({
    kind: "none",
  });
  const [currentlyPublishingDocumentIds, setCurrentlyPublishingDocumentIds] = React.useState(
    new Set<string>()
  );
  const [documentsList, setDocumentsList] = React.useState<ListDocumentsResponse | undefined>(
    undefined
  );

  const { data } = useQuery<SnapshotHistoryResponse, SnapshotHistoryInput>(GET_SNAPSHOT_HISTORY, {
    variables: { id: props.formulary.id },
  });

  const { loading, refetch } = useQuery<ListDocumentsResponse, ListDocumentsInput>(
    LIST_FORMULARY_PUBLISHING_CONTENT,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: { formularyId: props.formulary.id },
      onCompleted: (res) => {
        setDocumentsList(
          produce(res, (draft) => {
            draft.documents.items.sort((a, b) => (a.name > b.name ? 1 : -1));
            draft.documents.items.forEach((document) => {
              document.publishHistory.items.sort((a, b) =>
                compareDesc(
                  awsDateStringToDate(a.publishStartedAt),
                  awsDateStringToDate(b.publishStartedAt)
                )
              );
            });
          })
        );
      },
    }
  );

  const [updateFormularyPublishDate] = useMutation<unknown, UpdateFormularyPublishDateInput>(
    UPDATE_FORMULARY_PUBLISH_DATE
  );
  const [publishFormularyDocuments] = useMutation<unknown, PublishFormularyDocumentsInput>(
    PUBLISH_FORMULARY_DOCUMENTS
  );

  const handleStartPublish = () => {
    if (newPublishDate) {
      setNewPublishDate(newPublishDate);
      setShouldShowPublishConfirm(true);
    }
  };

  const handlePublish = async () => {
    if (newPublishDate && documentsList) {
      try {
        setShouldShowPublishConfirm(false);
        setCurrentlyPublishingDocumentIds(
          new Set(documentsList.documents.items.map((document) => document.id))
        );
        setSelectedDocument({ kind: "none" });
        const publishDate = toAwsDateStr(newPublishDate) as string;
        await updateFormularyPublishDate({
          variables: {
            id: props.formulary.id,
            publishDate,
          },
        });
        publishFormularyDocuments({
          variables: {
            formularyId: props.formulary.id,
            publishDate,
          },
        });
      } catch (e) {
        console.error("Error Publishing Documents \n", e);
        setCurrentlyPublishingDocumentIds(new Set());
      }
    } else {
      // todo - potentially warn the user here that the publish date was not set?
      setShouldShowPublishConfirm(false);
    }
  };

  const handleDocumentStatusChange = (documentId: string, renderStatus: DocumentRenderStatus) => {
    if (
      renderStatus === DocumentRenderStatus.FAILED ||
      renderStatus === DocumentRenderStatus.DONE ||
      renderStatus === DocumentRenderStatus.NOT_COPIED
    ) {
      if (
        currentlyPublishingDocumentIds.size === 1 &&
        currentlyPublishingDocumentIds.has(documentId)
      ) {
        refetch();
      }
      setCurrentlyPublishingDocumentIds(
        produce(currentlyPublishingDocumentIds, (draft) => {
          draft.delete(documentId);
        })
      );
    }
  };

  const handleUpdatePublishDate = (newDate: Date | null) => {
    setNewPublishDate(newDate);
  };
  const handleSelectDocumentSettings = (document: ApiFullFormularyDocument) => {
    if (currentlyPublishingDocumentIds.has(document.id)) {
      setSelectedDocument({ kind: "preview", document });
    } else {
      setSelectedDocument({ kind: "document settings", document: apiToFormDocument(document) });
    }
  };

  const handleUpdateDocumentState = (document: DocumentForm) => {
    setSelectedDocument({ kind: "document settings", document });
  };

  const handleSelectNewDocument = (docType: string) => {
    if (docType === "webSearch") {
      setSelectedDocument({ kind: "web search settings", webSearchSettings: { name: "" } });
    } else {
      setSelectedDocument({
        kind: "document settings",
        document: {
          kind: { type: "new", dataType: null },
          name: "",
          backMatter: null,
          documentDescription: null,
          frontMatter: null,
          publishHistory: { items: [] },
          shown: true,
          downloadPath: null,
        },
      });
    }
  };

  const handleSelectDocument = (document: ApiFullFormularyDocument) => {
    setSelectedDocument({ kind: "preview", document });
  };

  const handleSaveDocumentChanges = () => {
    setSelectedDocument({ kind: "none" });
    refetch();
  };

  const selectedDocumentId = (selectedDocument: SelectedDocument) => {
    if (selectedDocument.kind === "preview") {
      return selectedDocument.document.id;
    } else if (selectedDocument.kind === "document settings") {
      return selectedDocument.document.kind.type === "new"
        ? undefined
        : selectedDocument.document.id;
    } else {
      return undefined;
    }
  };

  const handleEditWebSearchSettings = (webSearchSettings: WebSearchSettingsForm): void => {
    setSelectedDocument({ kind: "web search settings", webSearchSettings: webSearchSettings });
  };

  const handleUpdateWebSearchSettings = (newSettings: WebSearchSettingsForm) => {
    setSelectedDocument({ kind: "web search settings", webSearchSettings: newSettings });
  };

  const renderSelectedDocument = () => {
    if (currentlyPublishingDocumentIds.size > 0) {
      return <PublishingMessage />;
    } else {
      switch (selectedDocument.kind) {
        case "none":
          return <NothingSelectedMessage />;
        case "preview":
          return (
            <DocumentPreview
              formularyId={props.formulary.id}
              document={selectedDocument.document}
              isPublishing={currentlyPublishingDocumentIds.has(selectedDocument.document.id)}
            />
          );
        case "document settings":
          return (
            <DocumentSettings
              document={selectedDocument.document}
              existingDocumentTypes={(documentsList?.documents.items ?? []).reduce(
                (acc, document) => ({
                  ...acc,
                  [document.documentType]: acc[document.documentType] + 1,
                }),
                {
                  [ApiDocumentTypes.Criteria]: 0,
                  [ApiDocumentTypes.FullFormulary]: 0,
                  [ApiDocumentTypes.WebSearch]: 0,
                } as Record<ApiDocumentTypes, number>
              )}
              formularyId={props.formulary.id}
              onUpdateDocumentState={handleUpdateDocumentState}
              onSaveChanges={handleSaveDocumentChanges}
            />
          );
        case "web search settings":
          return (
            <WebSearchSettings
              formularyId={props.formulary.id}
              webSearchFormSettings={selectedDocument.webSearchSettings}
              onCreate={handleSaveDocumentChanges}
              onUpdateWebSearchSettings={handleUpdateWebSearchSettings}
            />
          );
      }
    }
  };

  return (
    <>
      <Divider />

      <section aria-label="Publishing Area" className={classes.publishingArea}>
        <Paper className={classes.listHolder} square>
          <PublishToolbar
            formulary={props.formulary}
            publishDate={newPublishDate}
            isPublishing={currentlyPublishingDocumentIds.size > 0}
            earliestPublishDate={earliestEffectiveDate(data?.formulary.snapshotHistory.items)}
            onPublish={handleStartPublish}
            onUpdatePublishDate={handleUpdatePublishDate}
            onClickNew={handleSelectNewDocument}
            webSearchExists={Boolean(documentsList?.webSearchSettings)}
          />
          <DocumentList
            formulary={props.formulary}
            webSearchSettings={documentsList?.webSearchSettings ?? null}
            isLoading={loading}
            documents={documentsList?.documents.items ?? []}
            isPublishing={currentlyPublishingDocumentIds.size > 0}
            selectedDocumentId={selectedDocumentId(selectedDocument)}
            onClickDocumentSettings={handleSelectDocumentSettings}
            onSelectDocument={handleSelectDocument}
            onEditWebSearchSettings={handleEditWebSearchSettings}
            onDocumentStatusChange={handleDocumentStatusChange}
          />
        </Paper>
        {renderSelectedDocument()}
      </section>

      <ConfirmationDialog
        isOpen={showPublishConfirm}
        onYesHandler={handlePublish}
        onNoHandler={() => {
          setShouldShowPublishConfirm(false);
        }}
        dialogTitle="Confirm Publish"
        dialogContent={
          <Typography>
            Current documents, including those in this formulary's Web Search, will be updated to{" "}
            <span className={classes.textEmphasis}>
              {newPublishDate !== null ? format(newPublishDate, "MMMM do, yyyy") : ""}
            </span>{" "}
            with this action.
            <br />
            Are you sure you wish to continue?
          </Typography>
        }
      />
    </>
  );
};

function earliestEffectiveDate(snapshotHistory: Array<SnapshotHistory> | undefined): Date | null {
  if (snapshotHistory && snapshotHistory.length > 0) {
    return snapshotHistory.reduce<Date | null>((lowestDate, snapshot) => {
      const snapshotEffectiveDate = awsDateStringToDate(snapshot.effectiveDate);
      if (lowestDate) {
        return compareAsc(lowestDate, snapshotEffectiveDate) === 1
          ? snapshotEffectiveDate
          : lowestDate;
      } else {
        return snapshotEffectiveDate;
      }
    }, null);
  } else {
    return null;
  }
}
