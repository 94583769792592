import { Editor } from "@tinymce/tinymce-react";
import imageCompression from "browser-image-compression";
import * as React from "react";
import { MarginUnits, TemplateFonts } from "../../Templates/Formulary/types";
import { enableNestedNativeDialogs } from "../dialogs/DialogUtils";
import { UserContext } from "../UserContext";
import { TINY_MCE_API_KEY } from "./TinyMCEUtilts";
import { addLoadingSpinner, removeLoadingSpinner } from "./UploadDialogLoaders";

const FONT_CSS_CONVERSIONS: Record<TemplateFonts, string> = {
  [TemplateFonts.ARIAL]: "arial,sans-serif",
  [TemplateFonts.CALIBRI]: "calibri,sans-serif",
  [TemplateFonts.CENTURY_GOTHIC]: `"Century Gothic","Futura",san-serif`,
  [TemplateFonts.COURIER]: "Courier,sans-serif",
  [TemplateFonts.HELVETICA]: "Helvetica,sans-serif",
  [TemplateFonts.TIMES_NEW_ROMAN]: `"Times New Roman", Times, serif`,
};

type Props = {
  content: string;
  font: TemplateFonts;
  leftMargin: number;
  rightMargin: number;
  marginUnits: MarginUnits;
  onUpdateContent: (content: string) => void;
};

interface State {
  tinyMCEEditor?: Editor;
}

export class HeaderFooterEditor extends React.Component<Props, State> {
  state: State = {
    tinyMCEEditor: undefined,
  };

  prepareMarginSettings = (left: number, right: number, units: MarginUnits) => {
    const convertedUnits = units.toLocaleLowerCase();
    return `0 ${right}${convertedUnits} 0 ${left}${convertedUnits}`;
  };

  setupEditor = (editor: Editor) => {
    this.addStampsToolToEditor(editor);
    (editor as any).on("init", (editor: any) => {
      const editorBody = editor.target.contentDocument.querySelector("body");
      if (editorBody) {
        editorBody.style.fontFamily = FONT_CSS_CONVERSIONS[this.props.font];
        editorBody.style.margin = this.prepareMarginSettings(
          this.props.leftMargin,
          this.props.rightMargin,
          this.props.marginUnits
        );
      }
    });
    this.setState({ tinyMCEEditor: editor });
  };

  handleContentChange = (editorContent: string) => {
    this.props.onUpdateContent(editorContent);
  };

  addStampsToolToEditor = (editor: Editor) => {
    (editor as any).ui.registry.addMenuButton("stamp", {
      text: "Add Stamp",
      fetch: (addMenuButtons: (buttons: Array<object>) => void) => {
        addMenuButtons(stampingMenuButtons(editor));
      },
    });
  };

  addImage = (callback: (name: string, config: object) => void) => {
    const { tinyMCEEditor } = this.state;

    enableNestedNativeDialogs();

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = (event: any) => {
      const file: File = event.target.files[0];
      addLoadingSpinner();
      imageCompression(file, { maxSizeMB: 0.25 })
        .then((compressedFile: File) => {
          removeLoadingSpinner();
          const reader = new FileReader();
          reader.onload = (_: any) => {
            if (tinyMCEEditor) {
              const id = "blobid" + new Date().getTime();
              const blobCache = (tinyMCEEditor as any).editorUpload.blobCache;
              const base64 = (reader.result as any).split(",")[1];
              const blobInfo = blobCache.create(id, compressedFile, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              callback(blobInfo.blobUri(), { title: compressedFile.name });
            }
          };
          reader.readAsDataURL(compressedFile);
        })
        .catch((err: unknown) => {
          console.error(err);
        });
    };

    input.click();
  };

  render() {
    return (
      <Editor
        id="header-footer-editor"
        apiKey={TINY_MCE_API_KEY}
        init={{
          width: "8.5in",
          height: "1.5in",
          min_height: 100,
          max_height: 400,
          menubar: false,
          statusbar: true,
          branding: false,
          elementpath: false,
          plugins: "image",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright | fontsizeselect | image stamp",
          file_picker_callback: this.addImage as any,
          file_picker_types: "image",
          images_upload_credentials: true,
          setup: this.setupEditor as any,
        }}
        initialValue={this.props.content}
        onEditorChange={this.handleContentChange}
      />
    );
  }
}

HeaderFooterEditor.contextType = UserContext;

const stampingMenuButtons = (editor: Editor) => [
  {
    type: "nestedmenuitem",
    text: "Current Date",
    getSubmenuItems: () => [
      {
        type: "menuitem",
        text: "mm/yyyy",
        onAction: () => {
          (editor as any).insertContent("{{current_date:mm_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "Mon. yyyy",
        onAction: () => {
          (editor as any).insertContent("{{current_date:mon_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "Month yyyy",
        onAction: () => {
          (editor as any).insertContent("{{current_date:month_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "dd/mm/yyyy",
        onAction: () => {
          (editor as any).insertContent("{{current_date:dd_mm_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "yyyy",
        onAction: () => {
          (editor as any).insertContent("{{current_date:yyyy}}");
        },
      },
    ],
  },
  {
    type: "nestedmenuitem",
    text: "CMS Approval Date",
    getSubmenuItems: () => [
      {
        type: "menuitem",
        text: "mm/yyyy",
        onAction: () => {
          (editor as any).insertContent("{{approved_on_date:mm_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "Mon. yyyy",
        onAction: () => {
          (editor as any).insertContent("{{approved_on_date:mon_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "Month yyyy",
        onAction: () => {
          (editor as any).insertContent("{{approved_on_date:month_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "dd/mm/yyyy",
        onAction: () => {
          (editor as any).insertContent("{{approved_on_date:dd_mm_yyyy}}");
        },
      },
      {
        type: "menuitem",
        text: "yyyy",
        onAction: () => {
          (editor as any).insertContent("{{approved_on_date:yyyy}}");
        },
      },
    ],
  },
  {
    type: "menuitem",
    text: "CMS Contract ID",
    onAction: () => {
      (editor as any).insertContent("{{cms_contract_id}}");
    },
  },
  {
    type: "menuitem",
    text: "CMS Formulary Version",
    onAction: () => {
      (editor as any).insertContent("{{cms_formulary_version}}");
    },
  },
  {
    type: "menuitem",
    text: "Plan Name",
    onAction: () => {
      (editor as any).insertContent("{{plan_name}}");
    },
  },
  {
    type: "menuitem",
    text: "Plan PDF Title",
    onAction: () => {
      (editor as any).insertContent("{{plan_pdf_title}}");
    },
  },
];
