import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { Route, Switch } from "react-router";
import { NotFound } from "../components/screens/404";
import { UserContext } from "../components/UserContext";
import { getAppTheme } from "../styles/Theme";
import { AppSections } from "../types/AppTypes";
import { NetworkPageMode } from "./api";
import { ListNetworksPage } from "./ListNetworksPage";
import { NetworkPage } from "./NetworkPage";

export const NetworkRoutes: React.FC = () => {
  const {
    user: { settings },
  } = React.useContext(UserContext);

  return (
    <ThemeProvider theme={getAppTheme(AppSections.NETWORK, settings.textSize)}>
      <Switch>
        <Route path="/networks" exact={true}>
          <ListNetworksPage />
        </Route>
        <Route path="/networks/new" exact={true}>
          <NetworkPage mode={NetworkPageMode.NEW} />
        </Route>
        <Route path="/networks/edit/:networkId" exact={true}>
          <NetworkPage mode={NetworkPageMode.EDIT} />
        </Route>
        <Route path="/networks/view/:networkId" exact={true}>
          <NetworkPage mode={NetworkPageMode.VIEW} />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};
