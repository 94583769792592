import { ApolloError, useMutation, useQuery } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Divider,
  Fab,
  fade,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Zoom,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/SaveRounded";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import produce from "immer";
import * as React from "react";
import { AlertDialog } from "../../../../components/dialogs/AlertDialog";
import { CenteredCircularLoading } from "../../../../components/loading/CenteredCircularLoading";
import { OptionsTable } from "../../../../components/tables/OptionsTable";
import {
  AvailableTierItem,
  TierOptionsTable,
} from "../../../../components/tables/TiersOptionsTable";
import { apiFormatDate } from "../../../../utils/Date";
import { EXTERNAL_ID_HELP_TEXT } from "../../../helpText";
import { sharedStyles } from "../../../styles";
import {
  CreateFormularyInput,
  CreateFormularyResponse,
  CREATE_FORMULARY,
  GetAvailableTiersResponse,
  GetLOBResponse,
  GET_AVAILABLE_TIERS,
  GET_LOB,
  LineOfBusiness,
  UpdateFormularyInput,
  UpdateFormularyResponse,
  UPDATE_FORMULARY,
} from "../api";
import { FormularyEditingContext } from "../FormularyEditingContext";
import { ApiFormulary, FormularyDetails, FormularyTier } from "../types";

const useStyles = makeStyles((theme) => ({
  tierCardContent: {
    overflow: "auto",
    maxHeight: "60vh",
  },
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  extendedFab: {
    marginRight: theme.spacing(),
  },
}));

interface Props {
  formulary: FormularyDetails;
  onCreateFormulary: (newFormulary: ApiFormulary) => void;
  onUpdateFormulary: (updatedFormulary: ApiFormulary) => void;
}

export const FormularySetup: React.FC<Props> = (props) => {
  //#region Component Declarations
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const isEditing = React.useContext(FormularyEditingContext);

  const [formulary, setFormulary] = React.useState(props.formulary);
  const [availableTiers, setAvailableTiers] = React.useState<Array<AvailableTierItem>>([]);
  const [lobs, setLOBs] = React.useState<Array<LineOfBusiness>>([]);
  const [tiersWithErrors, setTiersWithErrors] = React.useState(
    validateTiers(props.formulary.details.tiers.items)
  );
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<ApolloError | undefined>();

  const isLegacy = formulary.details.hasLegacyPlan;
  //#endregion

  //#region GraphQL Queries and Mutations
  const [createFormulary, { loading: isCreating }] = useMutation<
    CreateFormularyResponse,
    CreateFormularyInput
  >(CREATE_FORMULARY);

  const [updateFormulary, { loading: isUpdating }] = useMutation<
    UpdateFormularyResponse,
    UpdateFormularyInput
  >(UPDATE_FORMULARY);

  const { loading: isLoadingLOBs } = useQuery<GetLOBResponse>(GET_LOB, {
    onCompleted: (res) => {
      setLOBs(res.linesOfBusiness.items);
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });

  const { loading: isLoadingTiers } = useQuery<GetAvailableTiersResponse>(GET_AVAILABLE_TIERS, {
    onCompleted: (res) => {
      setAvailableTiers(
        res.subaccountTiers.items
          .map(({ id, name }) => ({ itemSubAccountId: id, name }))
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });
  //#endregion

  //#region Component Functions
  const isValidToSave = () => {
    const {
      name,
      effectiveDate,
      terminationDate,
      lineOfBusiness,
      tiers,
      externalId,
    } = formulary.details;
    if (formulary.kind === "new") {
      return (
        name !== "" &&
        effectiveDate !== null &&
        lineOfBusiness !== "" &&
        tiers.items.length !== 0 &&
        tiers.items.every((tier) => tier.subAccountFormularyTierId) &&
        tiersWithErrors.size === 0
      );
    } else {
      const sortedOriginalTiers = [...props.formulary.details.tiers.items].sort(
        (a, b) => a.rank - b.rank
      );
      const sortedCurrentTiers = [...tiers.items].sort((a, b) => a.rank - b.rank);
      return (
        formulary.details.tiers.items.length !== 0 &&
        tiersWithErrors.size === 0 &&
        (name !== props.formulary.details.name ||
          externalId !== props.formulary.details.externalId ||
          terminationDate !== props.formulary.details.terminationDate ||
          (sortedCurrentTiers.some(
            (tier, index) =>
              tier.subAccountFormularyTierId !==
              sortedOriginalTiers[index].subAccountFormularyTierId
          ) &&
            tiersWithErrors.size === 0))
      );
    }
  };

  const getTiers = () => {
    if (!isEditing || isLegacy) {
      return (
        <OptionsTable
          settings={formulary.details.tiers.items.map((tier) => ({
            label: tier.rank.toString(),
            options: <TextField disabled value={tier.name} className={classes.textFieldOptions} />,
          }))}
          labelWidth="20%"
        />
      );
    } else if (isLoadingTiers) {
      return (
        <div className={sharedClasses.centeredLoadingSpinner}>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <TierOptionsTable
          tiers={formulary.details.tiers.items.map((tier) => ({
            id: tier.id,
            itemSubAccountId: tier.subAccountFormularyTierId,
            name: tier.name,
            order: tier.rank,
          }))}
          availableSubAccountTiers={availableTiers}
          tierItemIdsWithErrors={tiersWithErrors}
          onUpdateTierOrder={handleUpdateTierRank}
          onUpdateTierName={handleUpdateTierName}
          onRemoveTier={handleRemoveTier}
          onAddTier={handleAddTier}
          nameOnly={formulary.kind === "edit"}
        />
      );
    }
  };

  const handleUpdateTierRank = (tierIndex: number, newRank: number) => {
    const updatedFormulary = produce(formulary, (draft) => {
      const tier = draft.details.tiers.items[tierIndex];
      tier.rank = newRank;
    });
    setFormulary(updatedFormulary);
    setTiersWithErrors(validateTiers(updatedFormulary.details.tiers.items));
  };

  // todo - tier description could probably be changed
  function handleUpdateTierName(tierIndex: number, selectedTier: AvailableTierItem) {
    const updatedFormulary = produce(formulary, (draft) => {
      const tier = draft.details.tiers.items[tierIndex];
      tier.subAccountFormularyTierId = selectedTier.itemSubAccountId;
      tier.name = selectedTier.name;
    });
    setFormulary(updatedFormulary);
    setTiersWithErrors(validateTiers(updatedFormulary.details.tiers.items));
  }

  const handleRemoveTier = (tierIndex: number) => {
    setFormulary(
      produce(formulary, (draft) => {
        draft.details.tiers.items = draft.details.tiers.items.filter(
          (_, index) => index !== tierIndex
        );
      })
    );
  };

  function handleAddTier() {
    function nextRank(): number {
      if (formulary.details.tiers.items.length === 0) {
        return 1;
      }

      const tierRanks = formulary.details.tiers.items
        .map((tier) => tier.rank)
        .sort((rankA, rankB) => rankA - rankB);

      const tierRankGapStart = tierRanks.find(
        (rank, index, allRanks) => allRanks[index + 1] - rank > 1
      );

      if (tierRankGapStart) {
        return tierRankGapStart + 1;
      } else if (tierRanks[0] > 1) {
        return tierRanks[0] - 1;
      } else if (tierRanks[tierRanks.length - 1] < 99) {
        return tierRanks[tierRanks.length - 1] + 1;
      } else {
        return 99;
      }
    }

    const updatedFormulary = produce(formulary, (draft) => {
      draft.details.tiers.items.push({
        rank: nextRank(),
        id: "",
        subAccountFormularyTierId: "",
        name: "",
      });
    });
    setFormulary(updatedFormulary);
    setTiersWithErrors(validateTiers(updatedFormulary.details.tiers.items));
  }

  const updateFormularyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormulary(
      produce(formulary, (draft) => {
        draft.details.name = event.target.value;
      })
    );
  };

  const updateExternalId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormulary(
      produce(formulary, (draft) => {
        draft.details.externalId = event.target.value;
      })
    );
  };

  const handleEffectiveDateChange = (date: Date | null) => {
    setFormulary(
      produce(formulary, (draft) => {
        draft.details.effectiveDate = date;
      })
    );
  };

  const handleTerminationDateChange = (date: Date | null) => {
    setFormulary(
      produce(formulary, (draft) => {
        draft.details.terminationDate = date;
      })
    );
  };

  const handleUpdateLineOfBusiness = (event: React.ChangeEvent<any>) => {
    event.persist();
    setFormulary(
      produce(formulary, (draft) => {
        draft.details.lineOfBusiness = event.target.value;
      })
    );
  };

  const handleSaveFormulary = () => {
    if (formulary.kind === "new" && !isCreating) {
      const {
        effectiveDate,
        terminationDate,
        lineOfBusiness,
        name,
        tiers,
        isManaged,
        externalId,
      } = formulary.details;
      // Although the save button is disabled unless these are defined, this reinforces the requirement
      if (effectiveDate && lineOfBusiness && name) {
        const input = {
          effectiveDate: apiFormatDate(effectiveDate)!,
          terminationDate: apiFormatDate(terminationDate),
          externalId,
          isManaged,
          lineOfBusiness,
          name,
          tiers: tiers.items.map((tier) => ({
            subAccountFormularyTierId: tier.subAccountFormularyTierId,
            rank: tier.rank,
          })),
        };
        createFormulary({
          variables: {
            input,
          },
        })
          .then((res) => {
            if (res.data) {
              props.onCreateFormulary(res.data.createFormulary);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    } else if (formulary.kind === "edit" && !isUpdating) {
      const { externalId, terminationDate, name, id, tiers } = formulary.details;
      if (name) {
        updateFormulary({
          variables: {
            id,
            input: {
              name,
              terminationDate: apiFormatDate(terminationDate),
              externalId,
              tiers: tiers.items.map((tier) => ({
                formularyTierId: tier.id,
                subAccountFormularyTierId: tier.subAccountFormularyTierId,
                rank: tier.rank,
              })),
            },
          },
        })
          .then(() => {
            props.onUpdateFormulary(formulary.details);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  };
  //#endregion

  const formularyProperties = [
    {
      label: "Name",
      options: (
        <TextField
          required
          disabled={!isEditing || isLegacy}
          value={formulary.details.name}
          onChange={updateFormularyName}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "External ID",
      helpText: EXTERNAL_ID_HELP_TEXT,
      options: (
        <TextField
          disabled={!isEditing}
          value={formulary.details.externalId}
          onChange={updateExternalId}
          className={classes.textFieldOptions}
        />
      ),
    },
    {
      label: "Effective Date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            disabled={!isEditing || formulary.kind === "edit" || isLegacy}
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={formulary.details.effectiveDate}
            onChange={handleEffectiveDateChange}
            maxDate={formulary.details.terminationDate ?? undefined}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Termination Date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disabled={!isEditing}
            disableToolbar
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={formulary.details.terminationDate}
            onChange={handleTerminationDateChange}
            minDate={formulary.details.effectiveDate}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Line of Business",
      options:
        isLegacy || formulary.kind === "edit" ? (
          <TextField
            disabled
            value={formulary.details.lineOfBusiness}
            className={classes.textFieldOptions}
          />
        ) : (
          <Select
            required
            disabled={!isEditing}
            value={formulary.details.lineOfBusiness}
            onChange={handleUpdateLineOfBusiness}
            style={{ textAlign: "left" }}
            className={classes.textFieldOptions}
          >
            {lobs.map((lob) => (
              <MenuItem key={lob.id} value={lob.name}>
                {lob.name}
              </MenuItem>
            ))}
          </Select>
        ),
    },
  ];

  return (
    <>
      <section className={sharedClasses.tabContent}>
        <div className={sharedClasses.fab}>
          <Zoom in={isValidToSave()} timeout={{ enter: 200, exit: 200 }}>
            <Fab
              variant="extended"
              size="large"
              aria-label="save"
              color="primary"
              onClick={handleSaveFormulary}
              disabled={!isEditing || isCreating || isUpdating}
            >
              {isCreating || isUpdating ? (
                <CircularProgress size={30} className={classes.extendedFab} />
              ) : (
                <SaveIcon className={classes.extendedFab} />
              )}
              {isCreating || isUpdating ? "Saving" : "Save"}
            </Fab>
          </Zoom>
        </div>

        <Card className={sharedClasses.formularyCard}>
          <CardHeader title="Properties" />
          <Divider />
          <CardContent>
            {isLoadingLOBs ? (
              <CenteredCircularLoading />
            ) : (
              <OptionsTable settings={formularyProperties} />
            )}
          </CardContent>
        </Card>
        <Card className={sharedClasses.formularyCard}>
          <CardHeader title="Tiers" />
          <Divider />
          <CardContent className={classes.tierCardContent}>{getTiers()}</CardContent>
        </Card>
      </section>

      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error on this page. Please wait a moment and refresh or try again. If the
          problem persists, contact your administrator.
        </Typography>
        <Button
          color="primary"
          style={{ width: "fit-content" }}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Divider />
          <Table className={classes.technicalDetailsTable}>
            <TableHead>
              <TableRow key="errorHead">
                <TableCell>
                  <Typography variant="body2" color="error">
                    {pageErrors?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageErrors?.graphQLErrors.map((error, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" color="error">
                      {error.message}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </>
  );
};

function validateTiers(tiers: Array<FormularyTier>): Set<string> {
  return new Set(
    tiers.reduce<Array<string>>(
      (idsWithErrors, tier, currentTierIndex) =>
        tiers.some(
          (otherTier, otherTierIndex) =>
            currentTierIndex !== otherTierIndex &&
            (otherTier.rank === tier.rank ||
              otherTier.subAccountFormularyTierId === tier.subAccountFormularyTierId)
        )
          ? idsWithErrors.concat(tier.id)
          : idsWithErrors,
      []
    )
  );
}
