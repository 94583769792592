export enum FormularyTabs {
  SETUP = "Setup",
  CONFIGURATION = "Configure",
  VIEW = "View",
  PUBLISH = "Publish",
}

export type ConfigTabs = {
  name: string;
  content: JSX.Element;
};

export type FormularyDetails =
  | { kind: "new"; details: NewFormularyDetails }
  | { kind: "edit"; details: ApiFormulary };

export type NewFormularyDetails = {
  effectiveDate: Date | null;
  terminationDate: Date | null;
  externalId: string | null;
  hasLegacyPlan: boolean;
  isManaged: boolean;
  lineOfBusiness: string;
  name: string;
  tiers: { items: Array<FormularyTier> };
};

export type ApiFormulary = {
  cmsApprovedOn: Date | null;
  cmsEffectiveDate: Date | null;
  cmsContractId: string | null;
  cmsFormularyId: string | null;
  cmsFormularyVersion: string | null;
  effectiveDate: Date;
  terminationDate: Date | null;
  externalId: string | null;
  hasLegacyPlan: boolean;
  id: string;
  isManaged: boolean;
  lineOfBusiness: string;
  name: string;
  publishDate: Date | null;
  tiers: { items: Array<FormularyTier> };
  snapshotHistory: { items: Array<SnapshotHistory> };
};

export interface FormularyTier {
  id: string;
  subAccountFormularyTierId: string;
  name: string;
  rank: number;
}

export type AvailableSubAccountFormularyTier = {
  id: string;
  name: string;
};

export type HPMSFileToUpload = {
  file: File;
  type: APIFileType;
};

export type HPMSFile = {
  type: APIFileType;
  name: string;
};

export type HPMSFileInfo = HPMSFile &
  (
    | {
        status: "not validated";
      }
    | {
        status: "error";
        errors: HPMSfileValidationError;
      }
    | {
        status: "validated";
      }
  );
export enum FileFormatType {
  COMMERCIAL = "COMMERCIAL",
  HPMS = "HPMS",
  MARKETPLACE = "MARKETPLACE",
}
// todo - sustainable way to extract subtyped enum?
export enum APIFileType {
  Commercial_FormularyFile = "Commercial_FormularyFile",
  HPMS_EDFile = "HPMS_EDFile",
  HPMS_FFFFile = "HPMS_FFFFile", // Free First Fill File
  HPMS_FormularyFile = "HPMS_FormularyFile",
  HPMS_HIFile = "HPMS_HIFile",
  HPMS_IBCFile = "HPMS_IBCFile",
  HPMS_MMPFile = "HPMS_MMPFile",
  HPMS_OTCFile = "HPMS_OTCFile",
  HPMS_PAFile = "HPMS_PAFile",
  HPMS_PGCFile = "HPMS_PGCFile",
  HPMS_STFile = "HPMS_STFile",
  HPMS_VBIDFile = "HPMS_VBIDFile",
  Marketplace_FormularyFile = "Marketplace_FormularyFile",
}
export type HPMSFileValidationErrorResponse = {
  fileType: APIFileType;
  fileErrors: Array<{ details: string; name: string; recordIds: Array<string> }>;
  serverMessage: string;
};

export type HPMSfileValidationError = Omit<HPMSFileValidationErrorResponse, "fileType">;

export enum SnapshotStatus {
  PENDING = "PENDING",
  POPULATING = "POPULATING",
  RENDERING = "RENDERING",
  COMPLETED = "COMPLETED",
  ERRORED = "ERRORED",
}

export type SnapshotHistory = {
  id: string;
  locationPrefix: string;
  createdOn: string; // AWS Date
  effectiveDate: string; // AWS Date
  terminationDate: string | null; // AWS Date
  status: SnapshotStatus;
};
