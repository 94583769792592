import { useMutation, useQuery } from "@apollo/client";
import { makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import * as React from "react";
import { SaveFab } from "../../components/buttons/SaveFab";
import { CenteredContent } from "../../components/CenteredContent";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { Bold } from "../../components/Text/Bold";
import { getAppTheme } from "../../styles/Theme";
import { AppSections } from "../../types/AppTypes";
import {
  GetAllPlanConfigCostShareDataInput,
  GetAllPlanConfigCostShareDataResponse,
  getCostShareId,
  PLAN_CONFIG_COST_SHARE_DATA,
  SetCostShareInput,
  SET_COST_SHARE,
} from "../api";
import { CostShareDataGrid } from "./CostShareDataGrid";
import { costShareReducer, CostShareState } from "./CostShareReducer";

const useCostShareTableStyles = makeStyles((theme) => ({
  loadingContainer: {
    height: "100%",
    width: "100%",
  },
  errorMessageContainer: {
    height: "80%",
    width: "100%",
  },
  alertDialogOkText: {
    marginTop: theme.spacing(1),
  },
}));

const INITIAL_COST_SHARE_STATE: CostShareState = {
  status: "init",
  costShare: undefined,
  daySupplyRangeValues: new Map(),
  benefitsLists: [],
  formularyTiers: [],
  networkTiers: [],
};

interface Props {
  formularyId: string;
  planConfigId: string;
  networkId: string;
  readonly?: boolean;
}

export function CostShareTable(props: Props): JSX.Element {
  const classes = useCostShareTableStyles();

  const [costShareState, dispatch] = React.useReducer(costShareReducer, INITIAL_COST_SHARE_STATE);

  const { data } = useQuery<
    GetAllPlanConfigCostShareDataResponse,
    GetAllPlanConfigCostShareDataInput
  >(PLAN_CONFIG_COST_SHARE_DATA, {
    variables: {
      formularyId: props.formularyId,
      networkId: props.networkId,
      planConfigId: props.planConfigId,
    },
    onCompleted: (res) => {
      if (res.costShare?.data) {
        dispatch({
          type: "set loaded cost share data",
          costShare: JSON.parse(res.costShare.data),
          formularyTiers: res.associatedFormularyTiersInfo.tiers.items.sort(
            (a, b) => a.rank - b.rank
          ),
          networkTiers: res.associatedNetworkTiersInfo.tiers.items.sort(
            (a, b) => a.tierOrder - b.tierOrder
          ),
          benefitsList: res.benfitsLists,
        });
      }
    },
    onError: () => {
      dispatch({ type: "error" });
    },
  });

  const [saveCostShare, { loading: isSaving }] = useMutation<unknown, SetCostShareInput>(
    SET_COST_SHARE,
    {
      onError: (e) => {
        console.error("error saving cost share data", e);
      },
    }
  );

  React.useEffect(() => {
    if (costShareState.status === "reconciled" || costShareState.status === "saving") {
      if (data?.costShareTypes) {
        const costShareType = getCostShareId(data.costShareTypes);
        if (costShareType && costShareState.costShare) {
          console.info("Saving Cost Share");
          saveCostShare({
            variables: {
              planConfigId: props.planConfigId,
              input: {
                data: JSON.stringify(costShareState.costShare),
                typeId: costShareType,
              },
            },
          }).then(() => {
            dispatch({ type: "done saving" });
          });
        }
      }
    }
  }, [
    costShareState.costShare,
    costShareState.status,
    data?.costShareTypes,
    props.planConfigId,
    saveCostShare,
  ]);

  function handleCleanCostShare() {
    dispatch({ type: "prepare cost share for save" });
  }

  function handleReconcileCostShare() {
    dispatch({ type: "reconcile cost share" });
  }

  function renderCostShareTableContent() {
    switch (costShareState.status) {
      case "done": {
        return (
          <>
            {!Boolean(props.readonly) && (
              <SaveFab isSaving={isSaving} onClickSave={handleCleanCostShare} />
            )}
            <CostShareDataGrid
              costShare={costShareState.costShare!}
              daySupplyRangeValues={costShareState.daySupplyRangeValues}
              updateCostShare={dispatch}
              formularyTiers={costShareState.formularyTiers}
              networkTiers={costShareState.networkTiers}
              benefitsLists={costShareState.benefitsLists}
              readonly={props.readonly}
            />
          </>
        );
      }
      case "error": {
        return (
          <div className={classes.errorMessageContainer}>
            <CenteredContent>
              <Typography variant="h5" color="error">
                Error Loading Cost Share Data. Please try refreshing the page.
              </Typography>
            </CenteredContent>
            \
          </div>
        );
      }
      case "init": {
        return (
          <div className={classes.loadingContainer}>
            <CenteredCircularLoading />
          </div>
        );
      }
      case "saving": {
        return (
          <div className={classes.loadingContainer}>
            <CenteredCircularLoading />
          </div>
        );
      }
      default:
        return null;
    }
  }

  return (
    <>
      <AlertDialog
        isOpen={costShareState.status === "need to reconcile"}
        onExitHandler={handleReconcileCostShare}
        dialogTitle="Need to Make Cost Share Changes"
      >
        <Typography variant="body1">
          Since this <Bold>Cost Share</Bold> was last viewed, the <Bold>Formulary</Bold> has
          changed.
        </Typography>
        <Typography className={classes.alertDialogOkText}>
          Click <Bold>OK</Bold> to update this <Bold>Cost Share</Bold>
        </Typography>
      </AlertDialog>

      {
        <ThemeProvider theme={getAppTheme(AppSections.BENEFITS, "12px")}>
          {renderCostShareTableContent()}
        </ThemeProvider>
      }
    </>
  );
}
