import { useMutation, useQuery } from "@apollo/client";
import {
  Checkbox,
  fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/CreateRounded";
import SendIcon from "@material-ui/icons/SendRounded";
import * as React from "react";
import { GetUserInput, GetUserResponse, GET_USER } from "../../AccountArea/api";
import { NextUser } from "../../AccountArea/types";
import { QuickViewCard } from "../../components/cards/QuickViewCard";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { CopyIcon } from "../../components/Icons/customIcons";
import { SearchList } from "../../components/lists/SearchList";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { UserContext } from "../../components/UserContext";
import { awsDateStringToDate } from "../../utils/Date";
import {
  APIAlternativesList,
  CopyListRequest,
  CopyListResponse,
  COPY_LIST,
  DialogStatus,
  GetAltListsResponse,
  GET_ALT_LISTS,
  ListSendDialog,
  SendDialogParams,
} from "./api";
import { ListTypeRoute } from "./DrugListRouteUtils";

const useStyles = makeStyles((theme: Theme) => ({
  formField: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    gridColumn: "span 4",
    overflowY: "auto",
    backgroundColor: fade(theme.palette.common.black, 0.3),
  },
  previewArea: {
    gridColumn: "7 / span 8",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const AlternativesListsLandingPage: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    user: { userId, isManagingMedicare, drugSourceShort },
  } = React.useContext(UserContext);

  const [selectedDrugList, setSelectedDrugList] = React.useState<APIAlternativesList | null>();
  const [listSendDialogStatus, setListSendDialogStatus] = React.useState<ListSendDialog>({
    stage: DialogStatus.CLOSED,
  });
  const [sendDialogParams, setSendDialogParams] = React.useState<SendDialogParams | null>();
  const [nextUser, setNextUser] = React.useState<NextUser>();

  const { data, loading } = useQuery<GetAltListsResponse>(GET_ALT_LISTS, {
    fetchPolicy: "cache-and-network",
  });

  useQuery<GetUserResponse, GetUserInput>(GET_USER, {
    variables: {
      userId,
      entitlements: ["CAN_EDIT_LIST"],
    },
    onCompleted(data) {
      setNextUser(data.user);
    },
  });

  const [copyList] = useMutation<CopyListResponse, CopyListRequest>(COPY_LIST);

  const MENU_OPTIONS = [
    {
      optionName: "Edit",
      link: "edit",
      icon: <EditIcon fontSize="small" />,
    },
    {
      optionName: "Copy",
      icon: <CopyIcon fontSize="small" />,
      fn: async (listId: string) => {
        const selectedList = data?.lists.items.find((list) => list.id === listId);
        if (selectedList) {
          await copyList({
            variables: {
              id: listId,
              input: {
                subAccountIds: [],
              },
            },
          });
          setSelectedDrugList(null);
        }
      },
    },
    {
      optionName: "Send",
      icon: <SendIcon fontSize="small" />,
      fn: (listId: string) => {
        const selectedList = data?.lists.items.find((list) => list.id === listId);
        if (selectedList) {
          setListSendDialogStatus({
            stage: DialogStatus.OPEN,
            list: selectedList,
          });
          setSendDialogParams({
            name: selectedList.name,
            subAccountIds: [],
          });
          setSelectedDrugList(null);
        }
      },
    },
  ];

  const updateDialogStatusName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendDialogParams({
      name: event.target.value,
      subAccountIds: sendDialogParams?.subAccountIds ?? [],
    });
  };

  const updateDialogStatusSubAccounts = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = Boolean(event.target.checked);
    if (isChecked) {
      setSendDialogParams({
        name: sendDialogParams?.name ?? "",
        subAccountIds: sendDialogParams?.subAccountIds.concat(event.target.value) ?? [],
      });
    } else {
      setSendDialogParams({
        name: sendDialogParams?.name ?? "",
        subAccountIds:
          sendDialogParams?.subAccountIds.filter(
            (subAccountId: string) => subAccountId !== event.target.value
          ) ?? [],
      });
    }
  };

  const listSendDialogContent = () => {
    if (listSendDialogStatus.stage === DialogStatus.PROCESSING) {
      return <CenteredCircularLoading />;
    } else if (listSendDialogStatus.stage === DialogStatus.ERROR) {
      return (
        <AlertDialog
          isOpen
          onExitHandler={() => {
            setListSendDialogStatus({ stage: DialogStatus.CLOSED });
            setSendDialogParams(null);
          }}
        />
      );
    }
    return (
      <>
        <TextField
          variant="outlined"
          className={classes.formField}
          label="New name (optional)"
          value={sendDialogParams?.name}
          onChange={updateDialogStatusName}
        />
        <br />
        <FormControl>
          <FormLabel>
            Send <b>{listSendDialogStatus.list?.name}</b> to...
          </FormLabel>
          <FormGroup>
            {nextUser?.subAccounts.items
              .filter(
                (subAccount) =>
                  subAccount.drugSource.shortName === drugSourceShort &&
                  (!isManagingMedicare || subAccount.isMedicareEnabled === isManagingMedicare)
              )
              .map((subAccount) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.formField}
                      value={subAccount.id}
                      onChange={updateDialogStatusSubAccounts}
                      checked={sendDialogParams?.subAccountIds.includes(subAccount.id)}
                    />
                  }
                  id={subAccount.id}
                  label={subAccount.name}
                />
              ))}
          </FormGroup>
        </FormControl>
      </>
    );
  };

  const handleSendCopyToSubAccounts = (status: ListSendDialog) => () => {
    if (!status.list) {
      console.error(
        `Unable to process list copy without required parameters: ${JSON.stringify(
          status,
          null,
          2
        )}`
      );
      setListSendDialogStatus({ stage: DialogStatus.CLOSED });
      return;
    } else if (status.stage === DialogStatus.PROCESSING) {
      console.error(`Send dialog is still open.`);
      return;
    }
    if (!!status.list) {
      setListSendDialogStatus({ ...status, stage: DialogStatus.PROCESSING });
      copyList({
        variables: {
          id: status.list.id,
          input: {
            subAccountIds: sendDialogParams?.subAccountIds ?? [],
            name: sendDialogParams?.name ?? "",
          },
        },
      });
    }
    setListSendDialogStatus({ stage: DialogStatus.CLOSED });
    setSendDialogParams(null);
  };

  const handleSelectList = (drugListId: string) => {
    const foundDrugList = data?.lists.items.find((dl) => dl.id === drugListId);
    setSelectedDrugList(foundDrugList);
  };

  return (
    <>
      <Paper className={classes.container}>
        <SearchList
          listData={
            data?.lists.items.map((dl) => ({
              id: dl.id,
              name: dl.name,
              groupName: dl.listType,
            })) || []
          }
          dataLoading={loading}
          onItemSelection={handleSelectList}
          hasAddButton
          addButtonText="New Alternatives List"
          redirectLink={ListTypeRoute.ALTERNATIVE}
          menuOptions={MENU_OPTIONS}
        />
      </Paper>
      <div className={classes.previewArea}>
        {selectedDrugList ? (
          <QuickViewCard
            effectiveDate={awsDateStringToDate(selectedDrugList.effectiveDate as string)}
            externalId={selectedDrugList.externalId}
            name={selectedDrugList.name}
            terminationDate={
              selectedDrugList.terminationDate
                ? awsDateStringToDate(selectedDrugList.terminationDate)
                : null
            }
            description={selectedDrugList.description as string}
          />
        ) : (
          <Typography variant="h5">
            Select an <span style={{ color: theme.palette.primary.main }}>Alternatives List</span>{" "}
            to quickly view its properties
          </Typography>
        )}
      </div>
      <ConfirmationDialog
        isOpen={listSendDialogStatus.stage !== DialogStatus.CLOSED}
        onYesHandler={handleSendCopyToSubAccounts(listSendDialogStatus)}
        onNoHandler={() => {
          setListSendDialogStatus({ stage: DialogStatus.CLOSED });
          setSendDialogParams(null);
        }}
        dialogTitle={`Send a copy of ${listSendDialogStatus.list?.name} to another sub-account?`}
        dialogIcon={<SendIcon fontSize="large" color="inherit" />}
        dialogContent={listSendDialogContent()}
      />
    </>
  );
};
