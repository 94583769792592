import { IconButton, makeStyles, Typography } from "@material-ui/core";
import BackButton from "@material-ui/icons/ArrowBackRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: theme.spacing(2),
    alignItems: "center",
  },
  name: {
    marginLeft: theme.spacing(),
    color: theme.palette.primary.main,
  },
}));

export const NamedPageHeader: React.FC<{ name: string; backLink: string }> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.push(props.backLink);
  };

  return (
    <div className={classes.headerArea}>
      <IconButton onClick={goBack}>
        <BackButton />
      </IconButton>
      <Typography variant="h4" className={classes.name}>
        {props.name}
      </Typography>
    </div>
  );
};
