import gql from "graphql-tag";
import {
  ApiCriteriaDocument,
  ApiDocumentDataTypes,
  ApiFullFormularyDocument,
  ApiWebSearchSettings,
  DocumentRenderStatus,
  PublishingTemplate,
} from "./Publishing/DocumentTypes";
import {
  ApiFormulary,
  AvailableSubAccountFormularyTier,
  FileFormatType,
  FormularyTier,
  HPMSFile,
  HPMSFileValidationErrorResponse,
  SnapshotHistory,
  SnapshotStatus,
} from "./types";

export const GET_FORMULARY = gql`
  query GetFormulary($id: ID!) {
    formulary(id: $id) {
      cmsApprovedOn
      cmsEffectiveDate
      cmsContractId
      cmsFormularyId
      cmsFormularyVersion
      effectiveDate
      terminationDate
      externalId
      hasLegacyPlan
      id
      isManaged
      lineOfBusiness
      name
      publishDate
      tiers {
        items {
          id
          subAccountFormularyTierId
          name
          rank
        }
      }
      snapshotHistory {
        items {
          id
          locationPrefix
          createdOn
          effectiveDate
          terminationDate
          status
        }
      }
    }
  }
`;
export type GetFormularyResponse = {
  formulary: {
    cmsApprovedOn: string | null;
    cmsEffectiveDate: string | null;
    cmsContractId: string | null;
    cmsFormularyId: string | null;
    cmsFormularyVersion: string | null;
    effectiveDate: string;
    terminationDate: string | null;
    externalId: string | null;
    hasLegacyPlan: boolean;
    id: string;
    isManaged: boolean;
    lineOfBusiness: string;
    name: string;
    publishDate: string | null;
    tiers: { items: Array<FormularyTier> };
    snapshotHistory: { items: Array<SnapshotHistory> };
  };
};
export type GetFormularyInput = {
  id: string;
};

export const GET_SNAPSHOT_HISTORY = gql`
  query getSnapshotHistory($id: ID!) {
    formulary(id: $id) {
      snapshotHistory {
        items {
          id
          locationPrefix
          createdOn
          effectiveDate
          terminationDate
          status
        }
      }
    }
  }
`;
export type SnapshotHistoryInput = { id: string };
export type SnapshotHistoryResponse = {
  formulary: { snapshotHistory: { items: Array<SnapshotHistory> } };
};

export const GET_AVAILABLE_TIERS = gql`
  query ListAvailableTiers {
    subaccountTiers {
      items {
        id
        name
      }
    }
  }
`;
export type GetAvailableTiersResponse = {
  subaccountTiers: { items: Array<AvailableSubAccountFormularyTier> };
};

export const CREATE_FORMULARY = gql`
  mutation CreateFormulary($input: CreateFormularyInput!) {
    createFormulary(input: $input) {
      cmsApprovedOn
      cmsContractId
      cmsEffectiveDate
      cmsFormularyId
      cmsFormularyVersion
      effectiveDate
      terminationDate
      externalId
      hasLegacyPlan
      id
      isManaged
      isMedicare
      lineOfBusiness
      name
      publishDate
      tiers {
        items {
          id
          subAccountFormularyTierId
          name
          rank
        }
      }
    }
  }
`;
export type CreateFormularyInput = {
  input: {
    effectiveDate: string;
    terminationDate: string | null;
    externalId: string | null;
    isManaged: boolean;
    lineOfBusiness: string;
    name: string;
    tiers: Array<FormularyApiCreateTier>;
  };
};
export type CreateFormularyResponse = {
  createFormulary: ApiFormulary;
};

type FormularyApiCreateTier = {
  subAccountFormularyTierId: string;
  rank: number;
};

/**
 * todo - once working on publish page add support for publish date and cms fields
 */
export const UPDATE_FORMULARY = gql`
  mutation UpdateFormulary($id: ID!, $input: UpdateFormularyInput!) {
    updateFormulary(id: $id, input: $input) {
      cmsApprovedOn
      cmsContractId
      cmsEffectiveDate
      cmsFormularyId
      cmsFormularyVersion
      effectiveDate
      terminationDate
      externalId
      hasLegacyPlan
      id
      isManaged
      isMedicare
      lineOfBusiness
      name
      publishDate
      tiers {
        items {
          id
          subAccountFormularyTierId
          name
          rank
        }
      }
    }
  }
`;
export type UpdateFormularyInput = {
  id: string;
  input: {
    name: string;
    externalId: string | null;
    terminationDate: string | null;
    tiers: Array<FormularyApiUpdateTier>;
  };
};
export type UpdateFormularyResponse = {
  updateFormulary: ApiFormulary;
};

type FormularyApiUpdateTier = {
  formularyTierId: string;
  subAccountFormularyTierId: string;
  rank: number;
};

//#region Get Lines of Business Query
export const GET_LOB = gql`
  query GetLOB {
    linesOfBusiness {
      items {
        id
        name
        createdAt
        updatedAt
        isMedicare
      }
    }
  }
`;

export type GetLOBResponse = {
  linesOfBusiness: {
    items: Array<LineOfBusiness>;
  };
};

export type LineOfBusiness = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  isMedicare: boolean;
};
//#endregion

export const VALIDATE_HPMS_FILES = gql`
  mutation ValidateHPMSFiles(
    $formularyId: ID!
    $fileFormatType: FileFormatType!
    $locationPrefix: String!
    $fileMapping: [FileInput!]!
  ) {
    validateFormularyFiles(
      id: $formularyId
      input: {
        fileFormatType: $fileFormatType
        locationPrefix: $locationPrefix
        fileMapping: $fileMapping
      }
    ) {
      isValid
      invalidReasons {
        fileType
        fileErrors {
          name
          details
          recordIds
        }
        serverMessage
      }
    }
  }
`;
export type ValidateHPMSFilesInput = {
  formularyId: string;
  fileFormatType: FileFormatType;
  locationPrefix: string;
  fileMapping: Array<HPMSFile>;
};
export type ValidateHPMSFilesResponse = {
  validateFormularyFiles: {
    isValid: boolean;
    invalidReasons: Array<HPMSFileValidationErrorResponse> | null; // might be null when no errors are present
  };
};

export const GENERATE_FORMULARY_SNAPSHOT = gql`
  mutation GenerateFormularySnapshot(
    $formularyId: ID!
    $asOfDate: AWSDate!
    $locationPrefix: String!
  ) {
    generateFormularySnapshotAsync(
      id: $formularyId
      input: { asOfDate: $asOfDate, locationPrefix: $locationPrefix }
    ) {
      snapshotUuid
      status
    }
  }
`;
export type GenerateFormularySnapshotResponse = {
  generateFormularySnapshotAsync: { snapshotUuid: string; status: SnapshotStatus };
};
export type GenerateFormularySnapshotInput = {
  formularyId: string;
  asOfDate: string; // In the standard api date format yyyy-mm-dd
  locationPrefix: string;
};

export const SUBSCRIBE_TO_SNAPSHOT_STATUS = gql`
  subscription SubscribeToSnapshotStatus($snapshotId: ID!) {
    formularyStatusUpdates(snapshotId: $snapshotId) {
      status
    }
  }
`;
export type SubscribeToSnapshotStatusInput = {
  snapshotId: string;
};
export type SubscribeToSnapshotStatusResponse = {
  formularyStatusUpdates: { status: SnapshotStatus };
};

export const UPDATE_FORMULARY_CMS_SETTINGS = gql`
  mutation UpdateFormularyCMSSettings(
    $id: ID!
    $cmsContractId: String
    $cmsFormularyVersion: String
    $cmsFormularyId: String
    $cmsApprovedOn: AWSDate
    $cmsEffectiveDate: AWSDate
  ) {
    updateFormulary(
      id: $id
      input: {
        cmsContractId: $cmsContractId
        cmsFormularyVersion: $cmsFormularyVersion
        cmsFormularyId: $cmsFormularyId
        cmsApprovedOn: $cmsApprovedOn
        cmsEffectiveDate: $cmsEffectiveDate
      }
    ) {
      id
      name
      cmsContractId
      cmsFormularyId
      cmsFormularyVersion
      cmsApprovedOn
      cmsEffectiveDate
    }
  }
`;
export type UpdateFormularyCMSSettingsInput = {
  id: string;
  cmsContractId: string | null;
  cmsFormularyVersion: string | null;
  cmsFormularyId: string | null;
  cmsEffectiveDate: string | null; // aws string date
  cmsApprovedOn: string | null; // aws string date
};

// PUBLISHING API

export const LIST_FORMULARY_PUBLISHING_CONTENT = gql`
  query ListFormularyPublishingContent($formularyId: ID!) {
    documents: documents(formularyId: $formularyId) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        documentType
        downloadPath
        publishHistory {
          items {
            errorMessage
            status
            publishedAt
            publishStartedAt
            versionId
          }
        }
        settings {
          ... on CriteriaDocumentSettings {
            template {
              id
              name
              templateType
            }
            frontMatter
            backMatter
            shown
            documentDescription
            dataType
          }
          ... on FullFormularyDocumentSettings {
            template {
              id
              name
              templateType
            }
            frontMatter
            backMatter
            shown
            documentDescription
            dataType
          }
        }
      }
    }

    webSearchSettings: webSearch(formularyId: $formularyId) {
      id
      name
      settings {
        template {
          id
          name
          templateType
        }
      }
    }
  }
`;
export type ListDocumentsInput = {
  formularyId: string;
};
export type ListDocumentsResponse = {
  documents: {
    pageInfo: {
      endCursor: string | null;
    } | null;
    items: Array<ApiFullFormularyDocument>;
  };
  webSearchSettings: ApiWebSearchSettings | null;
};

/**
 * TODO
 *  can reuse apollo mutations for create/update?
 */

export const CREATE_FORMULARY_DOCUMENT = gql`
  mutation CreateFormularyDocument(
    $formularyId: ID!
    $name: String
    $settings: InputFullFormularyDocumentSettings!
  ) {
    saveFullFormularyDocument(formularyId: $formularyId, name: $name, settings: $settings) {
      id
      name
      documentType
      publishHistory {
        items {
          errorMessage
          status
          publishedAt
          versionId
        }
      }
      settings {
        ... on FullFormularyDocumentSettings {
          template {
            id
            name
            templateType
          }
          frontMatter
          backMatter
          shown
          documentDescription
          dataType
        }
      }
    }
  }
`;
export type CreateFormularyDocumentInput = {
  formularyId: string;
  name: string;
  settings: {
    templateId: string;
    shown: boolean;
    documentDescription: string | null;
    frontMatter: string | null;
    backMatter: string | null;
  };
};

export const UPDATE_FORMULARY_DOCUMENT = gql`
  mutation UpdateFormularyDocument(
    $formularyId: ID!
    $documentId: ID
    $name: String
    $settings: InputFullFormularyDocumentSettings!
  ) {
    saveFullFormularyDocument(
      formularyId: $formularyId
      documentId: $documentId
      name: $name
      settings: $settings
    ) {
      id
      name
      documentType
      publishHistory {
        items {
          errorMessage
          status
          publishedAt
          versionId
        }
      }
      settings {
        ... on FullFormularyDocumentSettings {
          template {
            id
            name
            templateType
          }
          frontMatter
          backMatter
          shown
          documentDescription
          dataType
        }
      }
    }
  }
`;
export type UpdateFormularyDocumentInput = CreateFormularyDocumentInput & {
  documentId: string;
};

export const CREATE_CRITERIA_DOCUMENT = gql`
  mutation CreateCriteriaDocument(
    $formularyId: ID!
    $name: String
    $settings: InputCriteriaDocumentSettings!
  ) {
    saveCriteriaDocument(formularyId: $formularyId, name: $name, settings: $settings) {
      id
      name
      documentType
      publishHistory {
        items {
          errorMessage
          status
          publishedAt
          versionId
        }
      }
      settings {
        ... on CriteriaDocumentSettings {
          template {
            id
            name
            templateType
          }
          frontMatter
          backMatter
          shown
          documentDescription
          dataType
        }
      }
    }
  }
`;
export type CreateCriteriaDocumentInput = {
  formularyId: string;
  name: string;
  settings: {
    templateId: string;
    shown: boolean;
    documentDescription: string | null;
    frontMatter: string | null;
    backMatter: string | null;
    dataType: ApiDocumentDataTypes.PACriteria | ApiDocumentDataTypes.STCriteria;
  };
};
export type CreateCriteriaDocumentResponse = {
  saveCriteriaDocument: ApiCriteriaDocument;
};

export const UPDATE_CRITERIA_DOCUMENT = gql`
  mutation UpdateCriteriaDocument(
    $formularyId: ID!
    $documentId: ID
    $name: String
    $settings: InputCriteriaDocumentSettings!
  ) {
    saveCriteriaDocument(
      formularyId: $formularyId
      documentId: $documentId
      name: $name
      settings: $settings
    ) {
      id
      name
      documentType
      publishHistory {
        items {
          errorMessage
          status
          publishedAt
          versionId
        }
      }
      settings {
        ... on CriteriaDocumentSettings {
          template {
            id
            name
            templateType
          }
          frontMatter
          backMatter
          shown
          documentDescription
          dataType
        }
      }
    }
  }
`;
export type UpdateCriteriaDocumentInput = CreateCriteriaDocumentInput & {
  documentId: string;
};

export const CREATE_WEB_SEARCH_SETTINGS = gql`
  mutation CreateWebSearchSettings($formularyId: ID!, $templateId: ID!, $name: String) {
    saveWebSearchSettings(formularyId: $formularyId, templateId: $templateId, name: $name) {
      id
      name
      settings {
        ... on WebSearchDocumentSettings {
          template {
            id
            name
            templateType
          }
        }
      }
    }
  }
`;
export type CreateWebSearchSettingsInput = {
  formularyId: string;
  templateId: string;
  name: string;
};

export const UPDATE_WEB_SEARCH_SETTINGS = gql`
  mutation UpdateWebSearchSettings(
    $formularyId: ID!
    $documentId: ID
    $templateId: ID!
    $name: String
  ) {
    saveWebSearchSettings(
      formularyId: $formularyId
      documentId: $documentId
      templateId: $templateId
      name: $name
    ) {
      id
      name
      settings {
        ... on WebSearchDocumentSettings {
          template {
            id
            name
            templateType
          }
        }
      }
    }
  }
`;
export type UpdateWebSearchSettingsInput = CreateWebSearchSettingsInput & { documentId: string };

export const LIST_TEMPLATES = gql`
  query ListTemplates {
    FullFormulary: templates(templateType: FULL_FORMULARY) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        templateType
      }
    }

    Criteria: templates(templateType: CRITERIA) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        templateType
      }
    }
  }
`;
export type ListTemplatesResponse = {
  FullFormulary: {
    pageInfo: {
      endCursor: string | null;
    } | null;
    items: Array<PublishingTemplate> | null;
  };
  Criteria: {
    pageInfo: {
      endCursor: string | null;
    } | null;
    items: Array<PublishingTemplate> | null;
  };
};

export const LIST_WEB_SEARCH_TEMPLATES = gql`
  query ListWebSearchTemplates {
    templates(templateType: WEB_SEARCH) {
      pageInfo {
        endCursor
      }
      items {
        id
        name
        templateType
      }
    }
  }
`;
export type ListWebSearchTemplatesResponse = {
  templates: {
    pageInfo: {
      endCursor: string | null;
    } | null;
    items: Array<PublishingTemplate> | null;
  };
};

export const SUBSCRIBE_TO_DOCUMENT_RENDER = gql`
  subscription SubscribeToDocumentRenderUpdates($documentId: ID!) {
    documentRenderUpdates(documentId: $documentId) {
      documentId
      status
    }
  }
`;
export type SubscribeToDocumentRenderUpdatesInput = {
  documentId: string;
};
export type SubscribeToDocumentRenderUpdatesResponse = {
  documentRenderUpdates: { documentId: string; status: DocumentRenderStatus };
};

export const UPDATE_FORMULARY_PUBLISH_DATE = gql`
  mutation UpdateFormularyPublishDate($id: ID!, $publishDate: AWSDate!) {
    updateFormulary(id: $id, input: { publishDate: $publishDate }) {
      id
      name
      publishDate
    }
  }
`;
export type UpdateFormularyPublishDateInput = {
  id: string;
  publishDate: string;
};

export const PUBLISH_FORMULARY_DOCUMENTS = gql`
  mutation PublishFormularyDocuments($formularyId: ID!, $publishDate: AWSDate!) {
    publishFormularyDocuments(formularyId: $formularyId, publishDate: $publishDate) {
      executionArn
      executionName
      formularyId
      userId
    }
  }
`;
export type PublishFormularyDocumentsInput = {
  formularyId: string;
  publishDate: string;
};
