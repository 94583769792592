import * as React from "react";
import { LinkProps, Link } from "react-router-dom";

type Props = LinkProps & {
  disabled: boolean;
};

export const CustomLink: React.FC<Props> = (props) => {
  const { disabled, children, ...linkProps } = props;
  return disabled ? <span>{children}</span> : <Link {...linkProps}>{children}</Link>;
};
