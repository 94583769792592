import { useMutation } from "@apollo/client";
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import produce from "immer";
import * as React from "react";
import { useParams } from "react-router-dom";
import {
  ApiListRule,
  apiListRuleToListRule,
  DRUG_ATTRIBUTES,
  DrugAttribute,
  DrugListType,
  ListRule,
} from "../../Flex/DrugLists/api";
import { Autocomplete } from "../../Flex/DrugLists/TierLists/Rules/Autocomplete";
import {
  attributeSuggestions,
  CREATE_RULE,
  CreateListRuleInput,
  CreateListRuleResponse,
  FoundDrugs,
  getDrugsForConditions,
} from "../../Flex/DrugLists/TierLists/Rules/rulesAPI";
import NumberFormat from "react-number-format";
import { DrugMetaDataCard } from "../../Flex/DrugSearch/DrugDetailsDrawer/DrugMetaDataCard";
import { apiFormatDate } from "../../utils/Date";
import { AlertDialog } from "../dialogs/AlertDialog";
import { UserContext } from "../UserContext";
import { StandardDatePicker } from "../Pickers/DatePickers";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "fixed",
    display: "grid",
    gridTemplateRows: "40px auto 40px",
    gridTemplateColumns: "auto",
    gridTemplateAreas: `"header" "content" "actions"`,
    right: 0,
    top: 0,
    zIndex: 3,
    width: "30vw",
    height: `calc(100vh - ${theme.spacing(4)}px)`,
    overflow: "hidden",
    padding: theme.spacing(2),
  },
  backgroundFade: {
    position: "fixed",
    background: fade(theme.palette.common.black, 0.3),
    width: "100vw",
    height: "100vh",
    zIndex: 1,
    top: 0,
    left: 0,
  },
  contentArea: {
    gridArea: "content",
    overflow: "auto",
  },
  savingArea: {
    gridArea: "content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  contentAreaProperties: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 400,
  },
  conditionHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 5,
  },
  conditionRow: {
    display: "flex",
    alignItems: "center",
  },
  conditionAttribute: {
    height: "35px",
    margin: theme.spacing(1),
  },
  conditionValue: {
    height: "35px",
    margin: theme.spacing(1),
  },
  actions: {
    gridArea: "actions",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    bottom: 0,
  },
  drugsFoundDrawer: {
    position: "fixed",
    top: 0,
    right: `calc(30vw + ${theme.spacing(4)}px)`, //Must account for padding on drawerPaper.
    zIndex: 2,
    width: "20vw",
    height: "100vh",
    background: "#f3f3f3",
    overflow: "auto",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  drugInfoCardList: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onRuleSave: (rule: ApiListRule) => void;
  rule?: ApiListRule;
  listType?: DrugListType;
}

type NumberFormatPriceProps = {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

function NumberFormatPrice(props: NumberFormatPriceProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
}

export const RuleDrawer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { drugListId } = useParams() as { drugListId: string };
  const {
    user: { drugSourceShort },
  } = React.useContext(UserContext);

  const NEW_RULE: ListRule = {
    id: null,
    listId: drugListId || "",
    effectiveDate: new Date(),
    createdAt: null,
    terminationDate: null,
    conditions: [{ drugAttribute: DrugAttribute.brandName, matchValue: "" }],
    price: null,
  };

  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoadingDrugsForConditions, setIsLoadingDrugsForConditions] = React.useState(false);
  const [rule, setRule] = React.useState<ListRule>(
    props.rule ? apiListRuleToListRule(props.rule) : NEW_RULE,
  );
  const [drugsForRule, setDrugsForRule] = React.useState<Array<FoundDrugs> | undefined>(undefined);
  const [error, setError] = React.useState<undefined | string>(undefined);
  const [createRule] = useMutation<CreateListRuleResponse, CreateListRuleInput>(CREATE_RULE);

  const handleChangeConditionValue = (
    attributeName: DrugAttribute,
    index: number,
    value: string,
  ) => {
    const newRule = produce(rule, (draft) => {
      draft.conditions[index] = {
        drugAttribute: attributeName,
        matchValue: value,
      };
    });
    setRule(newRule);
    setDrugsForRule(undefined);
    setIsLoadingDrugsForConditions(true);
    getDrugsForConditions(newRule.conditions)
      .then(setDrugsForRule)
      .finally(() => setIsLoadingDrugsForConditions(false));
  };

  const handleChangeConditionAttribute = (index: number) => (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const newRule = produce(rule, (draft) => {
      draft.conditions[index].drugAttribute = event.target.value as DrugAttribute;
      draft.conditions[index].matchValue = "";
    });
    setRule(newRule);
  };

  const handleRemoveCondition = (index: number) => {
    setRule(
      produce(rule, (draft) => {
        draft.conditions.splice(index, 1);
      }),
    );
  };

  const handleAddRowButtonClick = () => {
    setRule(
      produce(rule, (draft) => {
        draft.conditions.push({ drugAttribute: DrugAttribute.brandName, matchValue: "" });
      }),
    );
  };

  const handleSaveClick = () => {
    if (rule.conditions.every((condition) => Boolean(condition.matchValue))) {
      setIsSaving(true);
      createRule({
        variables: {
          input: {
            conditions: rule.conditions,
            effectiveDate: apiFormatDate(rule.effectiveDate) as string,
            terminationDate: apiFormatDate(rule.terminationDate) as string,
            price: rule.price ?? undefined,
          },
          listId: drugListId as string,
        },
      })
        .then((resp) => {
          if (resp.data) {
            props.onRuleSave(resp.data.createListRule);
            setRule(NEW_RULE);
          }
        })
        .catch((err) => {
          //TODO: Refine error message
          setError("There was an error saving the rule, please try again.");
          console.error(err);
        })
        .finally(() => {
          setIsSaving(false);
          setDrugsForRule(undefined);
        });
    } else {
      setError("Rule has invalid conditions.");
      console.log("RULE CONDITIONS:");
      console.table(rule, ["conditions"]);
    }
  };

  const disableSave = (): boolean =>
    !rule.effectiveDate ||
    !rule.conditions.every((condition) => Boolean(condition.matchValue)) ||
    (props.listType === DrugListType.PRICE && !rule.price);

  const handleCancelClick = () => {
    props.onCancel();
    setRule(NEW_RULE);
    setError(undefined);
  };

  /* Picks between an Autocomplete or a Select for the attribute value*/
  const getValueField = (attributeName: DrugAttribute, value: string, conditionIndex: number) => {
    const { options, field } = DRUG_ATTRIBUTES[attributeName];

    if (options) {
      return (
        <Select
          value={value}
          onChange={(event) => {
            handleChangeConditionValue(attributeName, conditionIndex, event.target.value as string);
          }}
          className={classes.conditionValue}
        >
          {options.map((opt) => (
            <MenuItem key={opt.display} value={opt.value}>
              {opt.display}
            </MenuItem>
          ))}
        </Select>
      );
    } else {
      return (
        <Autocomplete
          value={value}
          onSelect={(value) => {
            handleChangeConditionValue(attributeName, conditionIndex, value);
          }}
          getCompletions={(search) => attributeSuggestions({
            attribute: field,
            search,
          })}
          inputBaseProps={{ className: classes.conditionValue }}
        />
      );
    }
  };

  console.log("termination date", rule.terminationDate);

  return (
    <>
      <AlertDialog
        isError
        isOpen={Boolean(error)}
        onExitHandler={() => {
          setError(undefined);
        }}
      >
        <Typography>{error}</Typography>
      </AlertDialog>
      <div hidden={!props.isOpen} className={classes.backgroundFade} />
      <Slide in={props.isOpen} direction="left" mountOnEnter unmountOnExit>
        <Paper square className={classes.drawerPaper}>
          {isSaving ? (
            <div className={classes.savingArea}>
              <Typography color="primary" variant="h4" align="center" style={{ marginBottom: 16 }}>
                Saving
              </Typography>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Typography variant="h4" style={{ gridArea: "header" }}>
                New Rule
              </Typography>
              <section className={classes.contentArea}>
                <div className={classes.contentAreaProperties}>
                  <StandardDatePicker
                    required={true}
                    label="Effective Date"
                    value={rule.effectiveDate}
                    onChange={(date) => setRule({ ...rule, effectiveDate: date })}
                    maxDate={rule.terminationDate ?? undefined}
                    margin="normal"
                  />
                  <StandardDatePicker
                    label="Termination Date"
                    value={rule.terminationDate}
                    onChange={(date) => setRule({ ...rule, terminationDate: date })}
                    minDate={rule.effectiveDate}
                    margin="normal"
                  />

                  {props.listType === DrugListType.PRICE && (
                    <FormControl margin="normal" fullWidth>
                      <TextField
                        required
                        variant="outlined"
                        label="Price"
                        value={rule.price}
                        onChange={(event) => {
                          const value = parseFloat(event.target.value);
                          setRule({
                            ...rule,
                            price: value,
                          });
                        }}
                        InputProps={{
                          inputComponent: NumberFormatPrice as any,
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  )}
                </div>
                <div className={classes.conditionHeader}>
                  <InputLabel shrink>Conditions</InputLabel>
                  {rule.conditions.some((condition) => Boolean(condition.matchValue)) && (
                    <InputLabel shrink>
                      {isLoadingDrugsForConditions
                        ? "Searching for matches..."
                        : drugsForRule && drugsForRule.length > 0
                          ? `Found ${drugsForRule.length}${
                            drugsForRule.length > 99 ? "+" : ""
                          } drug(s) matching these conditions.`
                          : "No drugs found for these conditions."}
                    </InputLabel>
                  )}
                </div>
                {isLoadingDrugsForConditions ? (
                  <LinearProgress style={{ height: 2 }} />
                ) : (
                  <>
                    <Divider />
                    <Divider style={{ background: "transparent" }} />
                  </>
                )}
                {rule.conditions.map((condition, index) => {
                  return (
                    <div className={classes.conditionRow} key={index}>
                      {index > 0 && <Chip label="AND" color="primary" />}
                      <Select
                        className={classes.conditionAttribute}
                        value={condition.drugAttribute}
                        onChange={handleChangeConditionAttribute(index)}
                      >
                        {Object.entries(DRUG_ATTRIBUTES)
                          .filter(
                            ([_, drugAttribute]) =>
                              (drugAttribute.exclusiveToDrugSource === drugSourceShort || drugAttribute.exclusiveToDrugSource === undefined)
                              && (drugAttribute.exclusiveForView === "RULE_ATTR" || drugAttribute.exclusiveForView === undefined),
                          )
                          .sort(([k1, v1], [_, v2]) => v1.display.localeCompare(v2.display))
                          .map(([key, { display }]) => (
                            <MenuItem value={key} key={key}>
                              {display}
                            </MenuItem>
                          ))}
                      </Select>
                      {getValueField(condition.drugAttribute, condition.matchValue, index)}
                      {index > 0 && (
                        <IconButton onClick={() => handleRemoveCondition(index)}>
                          <ClearRoundedIcon />
                        </IconButton>
                      )}
                    </div>
                  );
                })}
                <Button
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddRowButtonClick}
                  style={{ justifyContent: "flex-start" }}
                >
                  Add Condition
                </Button>
              </section>

              <div className={classes.actions}>
                <Button color="primary" onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button color="primary" onClick={handleSaveClick} disabled={disableSave()}>
                  Save
                </Button>
              </div>
            </>
          )}
        </Paper>
      </Slide>

      <Slide in={drugsForRule && drugsForRule.length > 0 && props.isOpen} direction="left">
        <Paper className={classes.drugsFoundDrawer}>
          <div className={classes.drugInfoCardList}>
            {drugsForRule?.map((drug) => (
              <DrugMetaDataCard
                key={drug.ndc}
                displayName={drug["labelName"]}
                isGeneric={!Boolean(drug.isBrand)}
                ndc={drug.ndc}
                //TODO flex on drug source, or maybe not if values are going to be undefined
                drugAttributes={{
                  "Med ID": drug.medId,
                  "HICL Seq. No.": drug.hiclSequenceNumber,
                  HIC3: drug.hic3,
                  MONY: drug.mony,
                  GPI: drug.gpi14,
                  "Is Brand": drug.isBrand ? "Yes" : "No",
                }}
              />
            ))}
          </div>
        </Paper>
      </Slide>
    </>
  );
};
