import { useLazyQuery, useQuery } from "@apollo/client";
import { Divider, Fade, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { NamedPageHeader } from "../../components/NamedPageHeader";
import { apiFormatDate } from "../../utils/Date";
import { GetPlanConfigResponse, GetPlanResponse, GET_PLAN, GET_PLAN_CONFIG } from "../api";
import { useBenefitRouteParams } from "../BenefitsRouteUtils";
import { APIPlan, APIPlanConfig, PlanPageMode, PlanStatus } from "../types";
import { PlanAccumulatorsSection } from "./Accumulators/PlanAccumulators";
import { PlanCoverageOptions } from "./CoverageOptions/PlanCoverageOptions";
import { PlanView } from "./View/PlanView";
import { PlanSetupTab } from "./Setup/PlanSetup";
import { PlanHierarchy } from "./Hierarchy/PlanHierarchy";

const useStyles = makeStyles((theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  planIdentifier: {
    marginLeft: 10,
    color: theme.palette.primary.main,
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
}));

type Props = { mode: PlanPageMode };

type TabPanelProps = {
  children?: React.ReactNode;
  value: PlanTab;
};

enum PlanTab {
  SETUP = "Setup",
  HIERARCHY = "Hierarchy",
  ACCUMULATORS = "Accumulators",
  COVERAGE_OPTIONS = "Coverage Options",
  VIEW = "View",
}

const DEFAULT_STARTING_PLAN: APIPlan = {
  id: "",
  name: "",
  lineOfBusiness: null,
  planType: null,
  configId: null,
  externalId: null,
  effectiveDate: apiFormatDate(new Date())!,
  terminationDate: null,
  calendarYear: new Date().getUTCFullYear(),
  rxBIN: null,
  rxPCN: null,
  rxGroup: null,
  hierarchies: null,
  networkId: null,
  notes: null,
  status: null,
};

export function PlanPage(props: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { planId } = useBenefitRouteParams();

  const isNewPlan = props.mode === PlanPageMode.NEW && planId === undefined;
  const readOnly = props.mode === PlanPageMode.VIEW;
  const [activeTab, setActiveTab] = React.useState<PlanTab>(
    readOnly ? PlanTab.VIEW : PlanTab.SETUP
  );
  const [plan, setPlan] = React.useState<APIPlan>(DEFAULT_STARTING_PLAN);
  const [planConfig, setPlanConfig] = React.useState<APIPlanConfig | null>(null);

  const { loading: loadingPlan } = useQuery<GetPlanResponse>(GET_PLAN, {
    skip: isNewPlan,
    variables: { id: planId as string },
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      if (res) {
        setPlan(res.plan);
        if (planConfig === null && res.plan.configId) {
          getPlanConfig({ variables: { id: res.plan.configId } });
        }
      }
    },
    onError: (err) => {
      console.error(err);
      //TODO: setPageErrors(err);
    },
  });

  const [getPlanConfig] = useLazyQuery<GetPlanConfigResponse, { id: string }>(GET_PLAN_CONFIG, {
    onCompleted: (res) => {
      setPlanConfig(res.planConfig);
    },
  });

  function TabPanel({ children, value, ...other }: TabPanelProps) {
    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  }

  function handleTabChange(_: React.ChangeEvent<{}>, newTab: PlanTab) {
    setActiveTab(newTab);
  }

  function handleCreatePlan(planId: string) {
    history.push(`/benefits/plans/edit/${planId}`);
  }

  function handleUpdatePlan(updatedPlan: APIPlan, updatedPlanConfig?: APIPlanConfig | null) {
    setPlan(updatedPlan);
    if (updatedPlanConfig) {
      setPlanConfig(updatedPlanConfig);
    }
  }

  function getPlanName() {
    if (props.mode !== PlanPageMode.NEW) {
      return plan.name;
    } else {
      return "New Plan";
    }
  }

  function getPlanStatus() {
    if (plan.status === PlanStatus.Published) {
      return "Published";
    } else {
      return "Not Published";
    }
  }

  return (
    <div className={classes.fullWidthPageArea}>
      {loadingPlan ? (
        <CenteredCircularLoading />
      ) : (
        <>
          <NamedPageHeader
            name={`${getPlanName()} (${getPlanStatus()})`}
            backLink={"/benefits/plans"}
          />
          <Divider variant="middle" />
          <Tabs
            centered
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="formulary navigation tabs"
          >
            {!readOnly && (
              <Tab
                label={<Typography color="inherit">{PlanTab.SETUP}</Typography>}
                value={PlanTab.SETUP}
                className={classes.tabText}
              />
            )}
            {!readOnly && (
              <Tab
                label={<Typography color="inherit">{PlanTab.HIERARCHY}</Typography>}
                disabled={isNewPlan}
                value={PlanTab.HIERARCHY}
                className={classes.tabText}
              />
            )}
            {!readOnly && (
              <Tab
                label={<Typography color="inherit">{PlanTab.ACCUMULATORS}</Typography>}
                disabled={isNewPlan}
                value={PlanTab.ACCUMULATORS}
                className={classes.tabText}
              />
            )}
            {!readOnly && (
              <Tab
                label={<Typography color="inherit">{PlanTab.COVERAGE_OPTIONS}</Typography>}
                disabled={isNewPlan}
                value={PlanTab.COVERAGE_OPTIONS}
                className={classes.tabText}
              />
            )}
            <Tab
              label={<Typography color="inherit">{PlanTab.VIEW}</Typography>}
              disabled={isNewPlan}
              value={PlanTab.VIEW}
              className={classes.tabText}
            />
          </Tabs>
          <Fade in={activeTab === PlanTab.ACCUMULATORS}>
            <Divider />
          </Fade>
          <TabPanel value={PlanTab.SETUP}>
            {activeTab === PlanTab.SETUP && (
              <PlanSetupTab
                plan={plan}
                planConfig={planConfig}
                pageMode={props.mode}
                onCreate={handleCreatePlan}
                onUpdate={handleUpdatePlan}
              />
            )}
          </TabPanel>
          <TabPanel value={PlanTab.HIERARCHY}>
            {activeTab === PlanTab.HIERARCHY && (
              <PlanHierarchy planId={plan.id} cags={plan.hierarchies} onUpdate={handleUpdatePlan} />
            )}
          </TabPanel>
          <TabPanel value={PlanTab.ACCUMULATORS}>
            {activeTab === PlanTab.ACCUMULATORS && (
              <PlanAccumulatorsSection formularyId={planConfig?.formularyId ?? null} />
            )}
          </TabPanel>
          <TabPanel value={PlanTab.COVERAGE_OPTIONS}>
            {activeTab === PlanTab.COVERAGE_OPTIONS && <PlanCoverageOptions />}
          </TabPanel>
          <TabPanel value={PlanTab.VIEW}>
            {activeTab === PlanTab.VIEW && <PlanView plan={plan} />}
          </TabPanel>
        </>
      )}
    </div>
  );
}
