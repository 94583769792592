import { ApolloError, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  fade,
  Fade,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchRounded";
import * as React from "react";
import { AlertDialog } from "../../../components/dialogs/AlertDialog";
import { CenteredCircularLoading } from "../../../components/loading/CenteredCircularLoading";
import { LabelSwitch } from "../../../components/switches/LabelSwitch";
import { OptionsTable } from "../../../components/tables/OptionsTable";
import { OptionsTableSetting } from "../../../components/tables/OptionTableTypes";
import { sharedStyles } from "../../../Flex/styles";
import { toBoolean } from "../../../utils/Boolean";
import {
  ListCoverageOptionsInput,
  ListCoverageOptionsResponse,
  LIST_PLAN_COVERAGE_OPTIONS,
  UpdatePlanCoverageOptionInput,
  UPDATE_PLAN_COVERAGE_OPTION,
} from "../../api";
import { useBenefitRouteParams } from "../../BenefitsRouteUtils";
import { PlanCoverageOption } from "../../types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "98%",
  },
  fab: {
    color: theme.palette.common.white,
  },
  sectionContainer: {
    height: "100%",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  searchBar: {
    margin: theme.spacing(2),
    padding: `0 ${theme.spacing(3)}px`,
    width: "50%",
    backgroundColor: "#ECECEC",
  },
  tableContainer: {
    height: "100%",
    overflow: "auto",
    alignSelf: "center",
    width: "clamp(800px, 100%, 1300px)",
    backgroundColor: theme.palette.background.paper,
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "fit-content",
  },
}));

export function PlanCoverageOptions() {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const { planId } = useBenefitRouteParams();

  const [allCoverageOptions, setAllCoverageOptions] = React.useState<Array<PlanCoverageOption>>([]);
  const [searchText, setSearchText] = React.useState("");
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  const [pageErrors, setPageErrors] = React.useState<ApolloError | undefined>(undefined);

  //#region Apollo Queries
  const { loading: isLoadingCoverageOptions } = useQuery<
    ListCoverageOptionsResponse,
    ListCoverageOptionsInput
  >(LIST_PLAN_COVERAGE_OPTIONS, {
    skip: planId === undefined || planId === null,
    variables: { id: planId as string },
    onCompleted: (res) => {
      if (res) {
        setAllCoverageOptions(
          res.planCoverageOptions.sort((a, b) => a.question.localeCompare(b.question))
        );
      }
    },
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });
  const [savePlanCoverageOption, { loading: isSaving }] = useMutation<
    unknown,
    UpdatePlanCoverageOptionInput
  >(UPDATE_PLAN_COVERAGE_OPTION, {
    onError: (err) => {
      console.error(err);
      setPageErrors(err);
    },
  });
  //#endregion

  function handleUpdateSearchText(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length >= 3) {
      setSearchText(event.target.value);
    } else if (event.target.value.length === 0) {
      setSearchText("");
    }
  }

  function handleUpdateOption(option: PlanCoverageOption, updatedValue: string) {
    savePlanCoverageOption({
      variables: {
        id: option.id,
        input: { value: option.type === "TEXT" ? updatedValue.trim() : updatedValue },
      },
    });
  }

  function renderCoverageOptions(): Array<OptionsTableSetting> {
    const lowerCaseSearch = searchText.toLowerCase();
    const coverageOptions: Array<OptionsTableSetting> = [];
    for (const option of allCoverageOptions) {
      if (option.question.toLowerCase().includes(lowerCaseSearch)) {
        coverageOptions.push(toOptionTableSetting(option));
      }
    }
    return coverageOptions;
  }

  function toOptionTableSetting(option: PlanCoverageOption): OptionsTableSetting {
    switch (option.type) {
      case "TEXT":
        return {
          label: option.question,
          options: <TextCoverageOption coverageOption={option} onUpdate={handleUpdateOption} />,
        };
      case "INT":
        return {
          label: option.question,
          options: <IntCoverageOption coverageOption={option} onUpdate={handleUpdateOption} />,
        };
      case "DECIMAL":
        return {
          label: option.question,
          options: (
            <DecimalFieldCoverageOption coverageOption={option} onUpdate={handleUpdateOption} />
          ),
        };
      case "ENUM":
        return {
          label: option.question,
          options: <EnumCoverageOption coverageOption={option} onUpdate={handleUpdateOption} />,
        };
      case "BOOL":
        return {
          label: option.question,
          options: <BooleanCoverageOption coverageOption={option} onUpdate={handleUpdateOption} />,
        };
      default:
        return {
          label: option.question,
          options: <TextField></TextField>,
        };
    }
  }

  return (
    <>
      <section className={classes.root}>
        <div className={sharedClasses.fab} style={{ position: "fixed" }}>
          <Fade in={isSaving}>
            <CircularProgress />
          </Fade>
        </div>
        <div className={classes.sectionContainer}>
          <Paper elevation={0} className={classes.searchBar}>
            <InputBase
              placeholder="Find an option"
              fullWidth
              inputProps={{ "aria-label": "search term" }}
              onChange={handleUpdateSearchText}
              endAdornment={
                <IconButton disabled>
                  <SearchIcon />
                </IconButton>
              }
            />
          </Paper>
          {isLoadingCoverageOptions ? (
            <CenteredCircularLoading />
          ) : (
            <Paper className={classes.tableContainer}>
              <OptionsTable settings={renderCoverageOptions()} splitAlignment />
            </Paper>
          )}
        </div>
      </section>
      <AlertDialog
        isError
        isOpen={Boolean(pageErrors)}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setPageErrors(undefined);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          There was an error on this page. Please wait a moment and refresh or try again. If the
          problem persists, contact your administrator.
        </Typography>
        <Button
          className={classes.errorButton}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <Divider />
          <Table className={classes.technicalDetailsTable}>
            <TableHead>
              <TableRow key="errorHead">
                <TableCell>
                  <Typography variant="body2" color="error">
                    {pageErrors?.message}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageErrors?.graphQLErrors &&
                pageErrors.graphQLErrors.map((error, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="body2" color="error">
                        {error.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </AlertDialog>
    </>
  );
}

interface FieldProps {
  coverageOption: PlanCoverageOption;
  onUpdate: (option: PlanCoverageOption, updatedValue: string) => void;
}

function TextCoverageOption(props: FieldProps): JSX.Element {
  const [value, setValue] = React.useState<string>(props.coverageOption.value ?? "");

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleBlur() {
    props.onUpdate(props.coverageOption, value);
  }

  return <TextField value={value} onChange={handleChange} onBlur={handleBlur} />;
}

function IntCoverageOption(props: FieldProps): JSX.Element {
  const [value, setValue] = React.useState<string>(props.coverageOption.value ?? "");

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const intValue = parseInt(e.target.value);
    setValue(isNaN(intValue) ? "" : intValue < 0 ? "0" : intValue.toString());
  }

  function handleBlur() {
    props.onUpdate(props.coverageOption, value);
  }

  return (
    <TextField
      type="number"
      inputProps={{ min: 0 }}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

function DecimalFieldCoverageOption(props: FieldProps): JSX.Element {
  const [value, setValue] = React.useState<string>(props.coverageOption.value ?? "");

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const floatVal = parseFloat(e.target.value);
    setValue(isNaN(floatVal) ? "" : floatVal < 0 ? "0" : e.target.value);
  }

  function handleBlur() {
    props.onUpdate(props.coverageOption, value);
  }

  return (
    <TextField
      type="number"
      inputProps={{ min: 0 }}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

const useSelectStyles = makeStyles({
  select: { minWidth: "167px", textAlign: "left" },
});

function EnumCoverageOption(props: FieldProps): JSX.Element {
  const classes = useSelectStyles();
  const [value, setValue] = React.useState(props.coverageOption.value);

  function handleChange(e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) {
    if (typeof e.target.value === "string") {
      setValue(e.target.value);
      props.onUpdate(props.coverageOption, e.target.value);
    }
  }

  return (
    <FormControl>
      <InputLabel id={`enum-label-${props.coverageOption.question}`} />
      <Select
        id={`enum-field-${props.coverageOption.question}`}
        className={classes.select}
        labelId={`enum-label-${props.coverageOption.question}`}
        value={value}
        onChange={handleChange}
      >
        {props.coverageOption.enumValues?.map((enumValue, index) => (
          <MenuItem key={index} value={enumValue}>
            {enumValue}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function BooleanCoverageOption(props: FieldProps): JSX.Element {
  const [value, setValue] = React.useState(
    props.coverageOption.value ? toBoolean(props.coverageOption.value) : false
  );

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.checked);
    props.onUpdate(props.coverageOption, value.toString());
  }

  return (
    <LabelSwitch
      switchProps={{
        onChange: handleChange,
        checked: value,
      }}
      position="end"
    />
  );
}
