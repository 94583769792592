import * as React from "react";
import { useCurrentAction } from "../customHooks";
import { CopyWebSearchTemplatePage } from "./CopyWebSearchTemplatePage";
import { CreateWebSearchTemplatePage } from "./CreateWebSearchTemplatePage";
import { EditWebSearchTemplatePage } from "./EditWebSearchTemplatePage";

export const WebSearchTemplatePageSwitch: React.FC = () => {
  const action = useCurrentAction();

  switch (action) {
    case "copy":
      return <CopyWebSearchTemplatePage />;
    case "edit":
      return <EditWebSearchTemplatePage />;
    case "new":
      return <CreateWebSearchTemplatePage />;
  }
};
