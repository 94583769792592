import * as React from "react";
import { Drawer, Typography, Tabs, Tab, makeStyles } from "@material-ui/core";
import { DrugHistoryTable } from "./DrugHistoryTable";
import { DrugMetaDataCard } from "./DrugMetaDataCard";

type Props = {
  isDrawerOpen: boolean;
  productUuid: string;
  drugMetaData: {
    displayName: string;
    ndc: string;
    drugAttributes: { [id: string]: string | number | undefined };
  };
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
};

const useStyles = makeStyles({
  drawerPaper: {
    width: "45vw",
    height: "100vh",
    overflow: "hidden",
  },
});

export const DrugDetailsDrawer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(1);

  const handleTabChange = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Drawer
      anchor="right"
      open={props.isDrawerOpen}
      onClose={props.onClose}
      PaperProps={{ className: classes.drawerPaper }}
    >
      <div>
        <div style={{ padding: 16 }}>
          <Typography display="inline" variant="h5">
            Details:{" "}
          </Typography>
          <Typography display="inline" color="primary" variant="h5">
            {props.drugMetaData.displayName}
          </Typography>
        </div>
        <DrugMetaDataCard {...props.drugMetaData} />
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="drug details navigation tabs"
        >
          <Tab
            label={<Typography color="inherit">Usages</Typography>}
            style={{ textTransform: "capitalize" }}
            disabled
          />
          <Tab
            label={<Typography color="inherit">Changes</Typography>}
            style={{ textTransform: "capitalize" }}
          />
        </Tabs>
        <TabPanel value={tabIndex} index={0}></TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <DrugHistoryTable productUuid={props.productUuid} />
        </TabPanel>
      </div>
    </Drawer>
  );
};

const TabPanel: React.FC<{ index: number; value: number }> = (props) => {
  const { children, value, index } = props;
  return (
    <div hidden={value !== index} style={{ height: "100%" }}>
      {children}
    </div>
  );
};
