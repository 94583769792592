import * as React from "react";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  fade,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Warning from "@material-ui/icons/WarningRounded";

import { useDialogStyles } from "./DialogStyles";
import {
  AWSApolloError,
  AWSGraphQLError,
  ServerErrorCodeInput,
  ServerErrorCodeUnhandled,
} from "../../Benefits/types";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  fab: {
    color: theme.palette.common.white,
  },
  marginRightSpacing: {
    marginRight: theme.spacing(),
  },
  primaryAvatar: {
    background: theme.palette.primary.main,
  },
  noTiers: {
    fontStyle: "italic",
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "fit-content",
  },
}));

interface Props {
  isOpen: boolean;
  onExitHandler: () => void;
  onAcceptEventHandler?: () => void;
  dialogIcon?: React.ComponentType<SvgIconProps> | JSX.Element;
  dialogTitle?: JSX.Element | React.ComponentType | string;
  isError?: boolean;
  error?: AWSApolloError;
  defaultMessage?: string;
  children?: React.ReactNode;
}

export function AlertDialog(props: Props) {
  const contentClasses = useStyles();
  const dialogClasses = useDialogStyles();
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState(false);
  return (
    <Dialog
      open={props.isOpen}
      onExit={props.onExitHandler}
      PaperProps={{ classes: { root: dialogClasses.dialogBox } }}
    >
      <DialogTitle disableTypography className={dialogClasses.dialogTitleArea}>
        <div
          className={
            props.isError
              ? dialogClasses.dialogTitleIconAreaWithError
              : dialogClasses.dialogTitleIconArea
          }
        >
          {props.dialogIcon ? props.dialogIcon : <Warning fontSize="large" color="inherit" />}
        </div>
        <Typography variant="h6" className={dialogClasses.dialogTitleTextArea}>
          {props.dialogTitle ? props.dialogTitle : "Alert"}
        </Typography>
      </DialogTitle>
      <DialogContent className={dialogClasses.dialogContentArea}>
        {!!props.children ? (
          props.children
        ) : !!props.error ? (
          <>
            {generateServerErrorMessages(props.error)}
            <Button
              className={contentClasses.errorButton}
              onClick={() => {
                setShowTechnicalErrors(!showTechnicalErrors);
              }}
            >
              See Details
            </Button>
            <Collapse in={showTechnicalErrors} className={contentClasses.technicalErrors}>
              <Divider />
              <Table className={contentClasses.technicalDetailsTable}>
                <TableBody>
                  {props.error?.graphQLErrors ? (
                    props.error.graphQLErrors.map((error, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body2" color="error">
                            {error.message}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow key={0}>
                      <TableCell>
                        <Typography variant="body2" color="error">
                          {props.error?.message}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Collapse>
          </>
        ) : (
          <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
            {props.defaultMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={props.isError ? dialogClasses.errorButtons : dialogClasses.actionButtons}
          onClick={props.onAcceptEventHandler ? props.onAcceptEventHandler : props.onExitHandler}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function generateServerErrorMessages(gqlError?: AWSApolloError): Array<JSX.Element> {
  return (
    gqlError?.graphQLErrors?.map((gqlError) => {
      if (!gqlError.data) {
        return (
          <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
            {gqlError.message}
          </Typography>
        );
      }
      switch (gqlError.data.code) {
        case ServerErrorCodeUnhandled.NULL_OPERATION:
          return generateMessage(
            `Although the request completed without error, we are unable to identify the requested change to the system. If
        the problem persists, contact your administrator.`,
            gqlError
          );
        case ServerErrorCodeUnhandled.UNKNOWN:
          return generateMessage(
            `There was an error on this page. Please wait a moment and refresh or try again. If the problem persists, contact your administrator.`,
            gqlError
          );
        case ServerErrorCodeUnhandled.DATABASE_FAILURE:
          return generateMessage(
            `The request is unable to complete because of an error communicating with the database. Please wait a moment and refresh or try again. If the problem persists, contact your administrator.`,
            gqlError
          );
        case ServerErrorCodeInput.MISSING_REQUIRED_INPUT:
          return generateMessage(
            `The request is missing required inputs. Please check the following fields to ensure a valid request:`,
            gqlError
          );
        case ServerErrorCodeInput.INVALID_REQUIRED_CONDITION:
          return generateMessage(
            `The request violates a required condition. Please check the following fields to ensure a valid request:`,
            gqlError
          );
        case ServerErrorCodeInput.UNIQUE_IDENTIFIER_CONFLICT:
          return generateMessage(
            `The request contains an entry with a unique identifier that already exists for the sub-account. Please check the following fields to ensure a valid request:`,
            gqlError
          );
        case ServerErrorCodeInput.INVALID_CARDINALITY:
          return generateMessage(
            `The request contains a variable length field that violates the required cardinality. Please check the following fields to ensure a valid request:`,
            gqlError
          );
        default:
          return generateMessage(`Unsupported server error code: ${gqlError.data.code}`);
      }
    }) ?? []
  );
}

function generateMessage(leadingMessage: string, gqlError?: AWSGraphQLError): JSX.Element {
  return (
    <>
      <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
        {leadingMessage}
      </Typography>
      {gqlError?.data?.context?.map((text) => (
        <List dense={true}>
          <ListItem>
            <ListItemText primary={text} />
          </ListItem>
        </List>
      ))}
    </>
  );
}
