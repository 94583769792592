export const moveElement = <T>(arr: Array<T>, fromIndex: number, toIndex: number) => {
  const elementToMove = arr[fromIndex];
  const arrWithoutEle = arr.filter((currElement) => currElement !== elementToMove);

  return [
    ...arrWithoutEle.slice(0, toIndex),
    elementToMove,
    ...arrWithoutEle.slice(toIndex, arrWithoutEle.length),
  ];
};

export const not = (a: number[], b: number[]) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

export const intersection = (a: number[], b: number[]) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

export const sortDataByField = <T, K extends keyof T>(data: Array<T>, key: K) => {
  return data.sort((a, b) => {
    const itemA = a[key];
    const itemB = b[key];
    if (typeof itemA === "string" && typeof itemB === "string") {
      return itemA.toLowerCase() > itemB.toLowerCase() ? 1 : -1;
    } else {
      return itemA > itemB ? 1 : -1;
    }
  });
};

export const reorderArray = (list: Array<any>, startIndex: number, endIndex: number) => {
  const copy = [...list];
  const [removed] = copy.splice(startIndex, 1);
  copy.splice(endIndex, 0, removed);

  return copy;
};
