import { useLazyQuery, useQuery } from "@apollo/client";
import { Drawer, Fab, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/AddRounded";
import { ColDef, ColGroupDef, RowClickedEvent } from "ag-grid-community";
import produce from "immer";
import * as React from "react";
import { CenteredCircularLoading } from "../../components/loading/CenteredCircularLoading";
import { StyledAgGrid } from "../../components/tables/dataTables/StyledAgGrid";
import { awsDateStringToDate } from "../../utils/Date";
import {
  GetUserInput,
  GetUserResponse,
  GET_USER,
  ListAccountsResponse,
  ListUsersResponse,
  LIST_ACCOUNTS,
  LIST_USERS,
} from "../api";
import { EmptyUser, NextUser } from "../types";
import { UserEditor } from "./UserEditor";

const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Name",
    field: "name",
    flex: 1,
    sort: "asc",
  },
  {
    headerName: "Email",
    field: "email",
    flex: 1,
  },
  {
    headerName: "Title",
    field: "title",
  },
  {
    headerName: "Enabled",
    field: "enabled",
  },
  {
    headerName: "Created",
    field: "createdAt",
    filter: "agDateColumnFilter",
    initialHide: true,
  },
  {
    headerName: "Updated",
    field: "updatedAt",
    filter: "agDateColumnFilter",
  },
  {
    headerName: "User Agreement",
    children: [
      {
        headerName: "Version",
        field: "userAgreementVersionAgreedTo",
        initialHide: true,
      },

      {
        headerName: "Date",
        field: "userAgreementAgreedToAt",
        initialHide: true,
        filter: "agDateColumnFilter",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => {
  return {
    root: {
      gridColumnStart: 1,
      gridColumnEnd: 17,
    },
    userDrawer: {
      maxWidth: "40%",
      minWidth: "35%",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  };
});

export const UserAdmin: React.FC = () => {
  const classes = useStyles();

  const [accounts, setAccounts] = React.useState<ListAccountsResponse | undefined>(undefined);
  const [selectedUser, setSelectedUser] = React.useState<NextUser | undefined>(undefined);

  const { loading: listAccountsLoading } = useQuery<ListAccountsResponse>(LIST_ACCOUNTS, {
    onCompleted: (res) =>
      setAccounts(
        produce(res, (draft) => {
          draft.accounts.items = draft.accounts.items
            .filter((account) => account.subAccounts.items.length > 0)
            .sort((a, b) => a.name.localeCompare(b.name));

          for (const account of draft.accounts.items) {
            account.subAccounts.items.sort((a, b) => a.name.localeCompare(b.name));
          }
        })
      ),
  });
  const { data: listUsersData, loading: listUsersLoading, refetch: reloadUserList } = useQuery<
    ListUsersResponse
  >(LIST_USERS);

  const [getUser, { loading: userLoading }] = useLazyQuery<GetUserResponse, GetUserInput>(
    GET_USER,
    {
      fetchPolicy: "network-only",
      onCompleted(data) {
        setSelectedUser(data.user);
      },
    }
  );

  const onUserSelected = (event: RowClickedEvent) => {
    getUser({
      variables: { userId: event.data.id },
    });
  };

  const createNewUser = () => {
    console.info("Creating new user");
    setSelectedUser(EmptyUser);
  };

  const closeUserEditor = () => {
    setSelectedUser(undefined);
    reloadUserList();
  };

  return (
    <Paper className={classes.root}>
      <Drawer
        anchor="right"
        open={selectedUser !== undefined}
        PaperProps={{ className: classes.userDrawer }}
        onClose={closeUserEditor}
      >
        {(userLoading || listAccountsLoading) && <CenteredCircularLoading />}
        {selectedUser && accounts?.accounts && (
          <UserEditor
            user={selectedUser ?? EmptyUser}
            accounts={accounts.accounts.items}
            onSave={closeUserEditor}
          />
        )}
      </Drawer>
      <StyledAgGrid
        isLoading={listUsersLoading}
        columnDefs={COLUMN_DEFS}
        onRowClicked={onUserSelected}
        rowData={listUsersData?.users.items.map((user) =>
          produce(user, (draft) => {
            if (typeof draft.userAgreementAgreedToAt === "string") {
              draft.userAgreementAgreedToAt = awsDateStringToDate(draft.userAgreementAgreedToAt);
            }

            if (typeof draft.createdAt === "string") {
              draft.createdAt = awsDateStringToDate(draft.createdAt);
            }

            if (typeof draft.updatedAt === "string") {
              draft.updatedAt = awsDateStringToDate(draft.updatedAt);
            }
          })
        )}
      />
      <Fab className={classes.fab} onClick={createNewUser} color="primary">
        <Add />
      </Fab>
    </Paper>
  );
};
