import { useMutation } from "@apollo/client";
import { CircularProgress, Fade } from "@material-ui/core";
import * as React from "react";
import { VerticalTabs } from "../../../../../components/tabs/VerticalTabs";
import { sharedStyles } from "../../../../styles";
import { ConfigTabs, FormularyTier } from "../../types";
import {
  FormularyAlternativesListConfig,
  GetFormularyTiersListConfigResponse,
  SetFormularyTiersListConfigInput,
  SET_FORMULARY_TIERS_CONFIG,
  UpdateAlternativesConfigInput,
  UpdateAlternativesConfigResponse,
  UPDATE_ALTERNATIVES_CONFIG,
} from "../api";
import { FormularyAlternativesConfig } from "./FormularyAlternativesConfig";
import { FormularyTiersConfig } from "./FormularyTiersConfig";

interface Props {
  formularyId: string;
  formularyTiers: Array<FormularyTier>;
}

export const ManagedConfiguration: React.FC<Props> = (props) => {
  const { formularyTiers: tiers, formularyId } = props;
  const sharedClasses = sharedStyles();
  const [configTabs, setConfigTabs] = React.useState<Array<ConfigTabs>>([]);
  const [updateFormularyAlternativesConfig, { loading: isSavingAlternativesConfig }] = useMutation<
    UpdateAlternativesConfigResponse,
    UpdateAlternativesConfigInput
  >(UPDATE_ALTERNATIVES_CONFIG, {
    onCompleted: (_) => {
      console.info("Formulary Alternative Lists saved.");
    },
    onError: (err) => {
      console.error("Error Synchronizing Alternatives Lists \n", err);
    },
  });
  const [setFormularyTiersConfig, { loading: isSavingTiersConfig }] = useMutation<
    GetFormularyTiersListConfigResponse,
    SetFormularyTiersListConfigInput
  >(SET_FORMULARY_TIERS_CONFIG, {
    onCompleted: (_) => {
      console.info("Formulary Tiers Lists configuration saved.");
    },
    onError: (err) => {
      console.error("Error Synchronizing Tiers Lists Config \n", err);
    },
  });

  React.useEffect(() => {
    const tabs: Array<ConfigTabs> = [];
    tabs.push({
      name: "Alt",
      content: (
        <FormularyAlternativesConfig
          formularyId={formularyId}
          formularyTiers={tiers}
          onSaveConfig={handleSaveAlternativesConfig}
        />
      ),
    });
    tabs.push({
      name: "Tier",
      content: (
        <FormularyTiersConfig
          formularyId={formularyId}
          formularyTiers={tiers}
          onSaveConfig={handleSaveTierConfig}
        />
      ),
    });
    setConfigTabs(tabs);
    //TODO: useEffect is a temporary solution for rendering default tabs to display
    // to the user, but a better solution should be investigated. For now, disabling
    // exhaustive dependencies prevents a warning regarding this implementation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formularyId]);

  const handleSaveTierConfig = (configInput: SetFormularyTiersListConfigInput) => {
    setFormularyTiersConfig({
      variables: {
        formularyId: props.formularyId,
        input: {
          tierLists: configInput.input.tierLists,
        },
      },
    });
  };

  const handleSaveAlternativesConfig = (updatedConfig: FormularyAlternativesListConfig) => {
    updateFormularyAlternativesConfig({
      variables: {
        formularyId: props.formularyId,
        input: {
          alternativesLists: updatedConfig.alternativesLists.map(({ id }, index) => ({
            id,
            priority: index + 1,
          })),
          unlistedDrugSettings: {
            filterByTier: updatedConfig.unlistedDrugSettings.filterByTier,
            groupBy: updatedConfig.unlistedDrugSettings.groupBy,
            tierFilters: updatedConfig.unlistedDrugSettings.tierFilters.map((tier) => ({
              id: tier.id,
              matchingTiers: tier.matchingTiers.map((matchedTier) => matchedTier.id),
            })),
            pricingListId: updatedConfig.unlistedDrugSettings.pricingListId,
          },
        },
      },
    });
  };

  return (
    <>
      <div className={sharedClasses.fab} style={{ position: "fixed" }}>
        <Fade in={isSavingAlternativesConfig || isSavingTiersConfig}>
          <CircularProgress />
        </Fade>
      </div>
      <VerticalTabs tabs={configTabs} />
    </>
  );
};
