import * as React from "react";
import { useCurrentAction } from "../customHooks";
import { CopyFormularyTemplatePage } from "./CopyFormularyTemplatePage";
import { CreateFormularyTemplatePage } from "./CreateFormularyTemplatePage";
import { EditFormularyTemplatePage } from "./EditFormularyTemplatePage";

export const FormularyTemplatePageSwitch: React.FC = () => {
  const action = useCurrentAction();

  console.log("current action", action);

  switch (action) {
    case "copy":
      return <CopyFormularyTemplatePage />;
    case "edit":
      return <EditFormularyTemplatePage />;
    case "new":
      return <CreateFormularyTemplatePage />;
  }
};
