import { makeStyles, Tab, Tabs } from "@material-ui/core";
import * as React from "react";
import { ConfigTabs } from "../../Flex/Formularies/FormularyPage/types";
import { ReactComponent as VerticalSlice } from "./verticalSlice.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
  },
  tabsBox: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    overflow: "hidden",
    color: "white",
    width: 140,
    height: "100%",
  },
  tabSelected: {
    backgroundColor: "rgba(255, 255, 255, 0.15) !important",
  },
  tabIndicator: {
    backgroundColor: "white",
    left: 0,
  },
  tab: {
    width: "100%",
  },
  tabRoot: {
    minWidth: "130px",
  },
  tabContent: {
    paddingTop: "1rem",
    width: "100%",
    height: "calc(100% - 1rem)",
    overflow: "auto",
  },
  svg: {
    height: "100%",
    width: 180,
  },
});

type Props = {
  tabs: Array<ConfigTabs>;
};

export const VerticalTabs: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChangeActiveTab = (_: unknown, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    const tabgroup = props.tabs[activeTab];
    if (tabgroup) {
      return tabgroup.content;
    }
  };

  return (
    <div className={classes.root}>
      <VerticalSlice className={classes.svg} />
      <div className={classes.tabsBox}>
        <Tabs
          orientation="vertical"
          value={activeTab}
          classes={{ indicator: classes.tabIndicator }}
          style={{ width: "100%" }}
          onChange={handleChangeActiveTab}
        >
          {props.tabs.map((tab, index) => (
            <Tab
              label={tab.name}
              disableRipple
              className={classes.tab}
              classes={{ selected: classes.tabSelected, root: classes.tabRoot }}
              key={index}
            />
          ))}
        </Tabs>
      </div>
      <div className={classes.tabContent}>{renderTabContent()}</div>
    </div>
  );
};
