import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { FilePicker } from "../../../../../components/buttons/FilePicker";
import { OptionsTable } from "../../../../../components/tables/OptionsTable";
import { APIFileType, HPMSFileInfo, HPMSFileToUpload } from "../../types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
  },
});

type Props = {
  mainFormularyFile?: HPMSFileInfo;
  onAddMainFile: (newMainFile: HPMSFileToUpload) => void;
  onRemoveMainFile: () => void;
};

export const FirstStep: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleAddFile = (newFile: File) => {
    props.onAddMainFile({ file: newFile, type: APIFileType.HPMS_FormularyFile });
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography align="center">
          First, choose your main formulary file. This file is required and the first step in
          generating your formulary.
        </Typography>
        <Typography align="center">
          Any additional files you have will be asked for in the next step.
        </Typography>
      </div>
      <div style={{ minWidth: "45%" }}>
        <OptionsTable
          noHover
          settings={[
            {
              label: "Main Formulary File",
              options: (
                <FilePicker
                  handleFile={handleAddFile}
                  existingFile={
                    props.mainFormularyFile
                      ? {
                          fileName: props.mainFormularyFile.name || "",
                          onRemoveFile: props.onRemoveMainFile,
                        }
                      : undefined
                  }
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
