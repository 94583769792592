import { ColDef } from "ag-grid-community";

export const COMMON_COL_DEF: { [name: string]: ColDef } = {
  LABEL_NAME: {
    headerName: "Label Name",
    field: "labelName",
    filter: "text",
    width: 300,
  },
  GENERIC_DESC: {
    headerName: "Generic Description",
    field: "genericDescription",
    filter: "text",
    width: 250,
  },
  NDC: {
    headerName: "NDC",
    field: "ndc",
    filter: "text",
    width: 150,
    valueFormatter: (params) => {
      const ndc: string = params.value;
      if (ndc === undefined) {
        console.error("ndc was undefined");
        return ndc;
      } else if (ndc.length === 11) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, _1, _2, _3] = (params.value as string).match(/(\d\d\d\d\d)(\d\d\d\d)(\d\d)/)!;
        return `${_1}-${_2}-${_3}`;
      } else {
        return ndc;
      }
    },
    hide: true,
  },
  RXCUI: {
    headerName: "RxCUI",
    field: "rxcui",
    filter: "text",
    width: 150,
    hide: true,
  },
  BG_STATUS: {
    headerName: "B/G Status",
    field: "bgStatus",
    filter: "set",
    filterParams: {
      values: ["B", "G", "O"],
    },
    type: "text",
    width: 130,
  },
  ROA: {
    headerName: "Route of Admin",
    field: "routeOfAdmin",
    filter: "text",
    width: 180,
    hide: true,
  },
  IS_OTC: {
    headerName: "Rx/OTC",
    field: "isOtc",
    filter: "set",
    filterParams: {
      values: [true, false],
    },
    valueFormatter: (params) => {
      const isOtc = params.value;
      if (isOtc === undefined) {
        console.error("isOtc was undefined");
        return isOtc;
        // eslint-disable-next-line eqeqeq
      } else if (isOtc == true) {
        return "OTC";
        // eslint-disable-next-line eqeqeq
      } else if (isOtc == false) {
        return "Rx";
      } else {
        console.error("unhandled isOtc value:", isOtc);
        return "??";
      }
    },
    width: 110,
  },
  LAST_UPDATED: {
    headerName: "Last Updated",
    field: "lastUpdated",
    sort: "desc",
    filter: "date",
    width: 170,
    hide: true,
  },
  ON_MARKET: {
    headerName: "On Market",
    field: "onMarketDate",
    filter: "date",
    width: 170,
  },
  OFF_MARKET: {
    headerName: "Off Market",
    field: "offMarketDate",
    filter: "date",
    width: 170,
  },
};

export const attributeToDisplayMapping: { [attr: string]: string } = {
  bg_status: "B/G Status",
  brand_name: "Brand Name",
  dosage_form: "Dosage Form",
  etc_id: "ETC ID",
  gcn: "GCN",
  gcn_sequence_number: "GSN",
  generic_name: "Generic Name",
  generic_description: "Generic Description",
  hic3: "HIC 3",
  hic4: "HIC 4",
  hic3Description: "HIC 3 Description",
  hic_sequence_number: "HIC Seq No.",
  hic3_sequence_number: "HIC 3 Seq No.",
  hicl_sequence_number: "HICL Seq No.",
  is_multi_source: "Is Multi-Source",
  is_innov: "Is Innovator",
  label_name: "Label Name",
  med_id: "Med ID",
  mony: "MONY",
  ndc: "NDC",
  off_market_date: "Off Market Date",
  on_market_date: "On Market Date",
  package_size: "Package Size",
  route_of_admin: "Route of Admin",
  rxcui: "RxCUI",
  strength: "Strength",
};
