import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Collapse,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/SaveRounded";
import produce from "immer";
import * as React from "react";
import { AlertDialog } from "../../components/dialogs/AlertDialog";
import { ErrorDetailsTable } from "../../components/ErrorDetailsTable";
import { awsDateStringToDate, standardUIFormat } from "../../utils/Date";
import {
  AddUserToSubaccountInput,
  AddUserToSubaccountResponse,
  ADD_USER_TO_SUBACCOUNT,
  CreateUserInput,
  CreateUserResponse,
  CREATE_USER,
  RemoveUserFromSubaccountInput,
  RemoveUserFromSubaccountResponse,
  REMOVE_USER_FROM_SUBACCOUNT,
  SaveUserEntitlementsInput,
  SaveUserEntitlementsResponse,
  SAVE_USER_ENTITLEMENTS,
  SetUserEnabledInput,
  SetUserEnabledResponse,
  SET_USER_ENABLED,
  UpdateUserInput,
  UpdateUserResponse,
  UPDATE_USER,
} from "../api";
import { NextAccount, NextSubAccount, NextUser, UserEntitlements, UserPermissions } from "../types";
import { AccountListItem } from "./AccountListItem";
import { UserPermissionsEditor } from "./UserPermissionsEditor";

const useStyles = makeStyles((theme) => {
  return {
    formField: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    title: {
      margin: theme.spacing(2),
      marginBottom: 0,
    },
    subTitle: {
      margin: theme.spacing(2),
      marginTop: 0,
    },
    saveButton: {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    technicalErrors: {
      maxHeight: 600,
      overflow: "auto",
    },
  };
});

type Props = {
  user: NextUser;
  accounts: Array<NextAccount>;
  onSave: (userId?: string) => void;
};

export const UserEditor: React.FC<Props> = (props) => {
  const classes = useStyles();
  const isNewUser = !Boolean(props.user.id);

  const [user, setUser] = React.useState<NextUser>(props.user);
  const [selectedSubAccounts, setSelectedSubAccounts] = React.useState<Array<string>>(
    props.user.subAccounts.items?.map((subAccount) => subAccount.id) ?? []
  );
  const [modifiedPermissionSubAccountIds, setModifiedPermissionSubAccountIds] = React.useState<
    Array<string>
  >([]);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [showTechnicalErrors, setShowTechnicalErrors] = React.useState<boolean>(false);
  const [managedByAccountId, setManagedByAccountId] = React.useState<string | undefined>(
    props.user?.managedByAccount?.id
  );

  const [
    editingPermissionsSubAccount,
    setEditingPermissionsSubAccount,
  ] = React.useState<NextSubAccount | null>(null);

  const [updateUser, { error: errorSaving }] = useMutation<UpdateUserResponse, UpdateUserInput>(
    UPDATE_USER
  );
  const [createUser, { error: errorUpdating }] = useMutation<CreateUserResponse, CreateUserInput>(
    CREATE_USER
  );
  const [addSubaccount, { error: errorAddingSubaccount }] = useMutation<
    AddUserToSubaccountResponse,
    AddUserToSubaccountInput
  >(ADD_USER_TO_SUBACCOUNT);
  const [removeSubaccount, { error: errorRemovingSubaccount }] = useMutation<
    RemoveUserFromSubaccountResponse,
    RemoveUserFromSubaccountInput
  >(REMOVE_USER_FROM_SUBACCOUNT);
  const [setUserEnabled, { error: errorDisablingUser }] = useMutation<
    SetUserEnabledResponse,
    SetUserEnabledInput
  >(SET_USER_ENABLED);
  const [saveUserEntitlements, { error: errorModifyingPermissions }] = useMutation<
    SaveUserEntitlementsResponse,
    SaveUserEntitlementsInput
  >(SAVE_USER_ENTITLEMENTS);

  const updateProperty = <T extends keyof NextUser>(property: T) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUser({
      ...user,
      [property]: event.target.value,
    });
  };

  const updateBooleanProperty = <T extends keyof NextUser>(property: T) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUser({
      ...user,
      [property]: event.target.checked,
    });
  };

  const updateLegacyId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const legacyId = Boolean(event.target.value) ? parseInt(event.target.value) : undefined;

    if (typeof legacyId === "number" && isNaN(legacyId)) return;

    setUser({
      ...user,
      legacyId: legacyId,
    });
  };

  const onSaveEntitlements = (subAccountId: string, entitlements: Array<UserEntitlements>) => {
    setUser(
      produce(user, (draft) => {
        let permission = draft.permissions.find(
          (permissions) => permissions.subAccountId === subAccountId
        );

        if (!permission) {
          permission = { subAccountId, entitlements };
          draft.permissions.push(permission);
        } else {
          permission.entitlements = entitlements;
        }
      })
    );

    if (!modifiedPermissionSubAccountIds.includes(subAccountId)) {
      setModifiedPermissionSubAccountIds([...modifiedPermissionSubAccountIds, subAccountId]);
    }
  };

  const saveOrUpdateUser = (): Promise<NextUser> => {
    if (isNewUser) {
      return createUser({
        variables: {
          user: {
            email: user.email,
            name: user.name,
            legacyId: user.legacyId,
            title: user.title,
            managedByAccountId,
          },
        },
      }).then((result) => result.data?.user as NextUser);
    } else {
      return updateUser({
        variables: {
          user: {
            id: user.id,
            name: user.name,
            legacyId: user.legacyId,
            title: user.title,
            managedByAccountId,
          },
        },
      }).then((result) => result.data?.user as NextUser);
    }
  };

  const onSaveUser = async () => {
    setIsSaving(true);

    try {
      const updatedUser = await saveOrUpdateUser();

      const permissionsToUpdate = modifiedPermissionSubAccountIds
        .map((subAccountId) =>
          user.permissions.find((permission) => permission.subAccountId === subAccountId)
        )
        .filter(Boolean) as Array<UserPermissions>;

      for (const permission of permissionsToUpdate) {
        await saveUserEntitlements({
          variables: {
            userId: updatedUser.id,
            subAccountId: permission.subAccountId,
            entitlements: permission.entitlements,
          },
        });
      }

      const subaccountsToAdd = selectedSubAccounts.filter(
        (subAccountId) =>
          !updatedUser.subAccounts.items.some((subaccount) => subaccount.id === subAccountId)
      );

      const subaccountsToRemove = updatedUser.subAccounts.items
        .filter((subaccount) => !selectedSubAccounts.includes(subaccount.id))
        .map((subaccount) => subaccount.id);

      for (const subAccountId of subaccountsToAdd) {
        await addSubaccount({
          variables: {
            userId: updatedUser.id,
            subAccountId,
          },
        });
      }

      for (const subAccountId of subaccountsToRemove) {
        await removeSubaccount({
          variables: {
            userId: updatedUser.id,
            subAccountId,
          },
        });
      }

      if (updatedUser.enabled !== user.enabled) {
        await setUserEnabled({
          variables: {
            userId: updatedUser.id,
            enabled: user.enabled,
          },
        });
      }

      props.onSave(updatedUser.id);
    } catch (err) {
      setShowAlert(true);
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <Typography variant="h5" color="primary" className={classes.title}>
        {isNewUser ? "New User" : user.name}
      </Typography>
      <Fab
        color="primary"
        className={classes.saveButton}
        size="medium"
        disabled={isSaving}
        onClick={onSaveUser}
      >
        {isSaving ? <CircularProgress size={24} /> : <SaveIcon />}
      </Fab>
      {!isNewUser && (
        <>
          <Typography variant="subtitle2" className={classes.subTitle}>
            {user.email}
          </Typography>
          <Grid container className={classes.subTitle}>
            <Grid item xs={12}>
              <Typography variant="body2">{`User ID: ${user.id}`}</Typography>
            </Grid>
            {user.createdAt && (
              <Grid item xs={6}>
                <Typography variant="body2">{`Created: ${standardUIFormat(
                  awsDateStringToDate(user.createdAt)
                )}`}</Typography>
              </Grid>
            )}
            {user.updatedAt && (
              <Grid item xs={6}>
                <Typography variant="body2">{`Updated: ${standardUIFormat(
                  awsDateStringToDate(user.updatedAt)
                )}`}</Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography variant="body2">{`User Agreement: ${user.userAgreementVersionAgreedTo}`}</Typography>
            </Grid>
            {user.userAgreementAgreedToAt && (
              <Grid item xs={6}>
                <Typography variant="body2">{`Agreed At: ${standardUIFormat(
                  awsDateStringToDate(user.userAgreementAgreedToAt)
                )}`}</Typography>
              </Grid>
            )}
          </Grid>
          <FormControlLabel
            disabled={isSaving}
            label="Enabled"
            control={
              <Switch
                className={classes.formField}
                onChange={updateBooleanProperty("enabled")}
                checked={user.enabled}
              />
            }
          />
        </>
      )}
      <TextField
        className={classes.formField}
        disabled={isSaving}
        label="Name"
        required={true}
        value={user.name}
        onChange={updateProperty("name")}
      />

      {isNewUser && (
        <TextField
          label="Email"
          className={classes.formField}
          disabled={isSaving}
          required={true}
          value={user.email}
          onChange={updateProperty("email")}
        />
      )}

      <TextField
        label="Title"
        className={classes.formField}
        disabled={isSaving}
        value={user.title}
        onChange={updateProperty("title")}
      />

      <TextField
        label="Legacy Id"
        className={classes.formField}
        disabled={isSaving}
        value={user.legacyId}
        inputMode="numeric"
        onChange={updateLegacyId}
      />

      <FormControl className={classes.formField}>
        <InputLabel id="managed-by-account-id-label">Managed by Account</InputLabel>
        <Select
          value={managedByAccountId}
          labelId="managed-by-account-id-label"
          onChange={(event) => setManagedByAccountId(event?.target.value as string | undefined)}
        >
          {props.accounts.map((account) => (
            <MenuItem value={account.id}>
              <ListItemText primary={account.name} secondary={account.id} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <List
        dense
        subheader={<ListSubheader disableSticky={true}>SubAccounts</ListSubheader>}
        component="div"
      >
        {props.accounts.map((account) => {
          return (
            <AccountListItem
              account={account}
              disabled={isSaving}
              onEditUserPermissions={setEditingPermissionsSubAccount}
              selectedSubAccounts={selectedSubAccounts}
              onSubAccountsChanged={setSelectedSubAccounts}
            />
          );
        })}
      </List>

      <AlertDialog
        isError
        isOpen={showAlert}
        dialogTitle={"Error"}
        onExitHandler={() => {
          setShowAlert(false);
        }}
      >
        <Typography variant="body2" paragraph style={{ padding: "6px 8px" }}>
          {`There was an error while saving the user.`}
        </Typography>
        <Button
          color="primary"
          style={{ width: "fit-content" }}
          onClick={() => {
            setShowTechnicalErrors(!showTechnicalErrors);
          }}
        >
          See Details
        </Button>
        <Collapse in={showTechnicalErrors} className={classes.technicalErrors}>
          <ErrorDetailsTable
            errors={[
              errorSaving,
              errorUpdating,
              errorAddingSubaccount,
              errorRemovingSubaccount,
              errorDisablingUser,
              errorModifyingPermissions,
            ]}
          />
        </Collapse>
      </AlertDialog>

      {editingPermissionsSubAccount && (
        <UserPermissionsEditor
          open={Boolean(editingPermissionsSubAccount)}
          onClose={() => setEditingPermissionsSubAccount(null)}
          onSetEntitlements={onSaveEntitlements}
          subAccount={editingPermissionsSubAccount}
          entitlements={
            user.permissions.find(
              (permissions) => permissions.subAccountId === editingPermissionsSubAccount.id
            )?.entitlements ?? []
          }
          user={user}
        />
      )}
    </>
  );
};
