import create from "zustand";
import { AWSApolloClient } from "../../../Config/apollo";
import { END_CURSOR } from "../../../types/ApiTypes";
import { apiFormatDate } from "../../../utils/Date";
import { DrugSourceShort } from "../../../utils/User";
import {
  DrugAttribute,
  GetGSNDrugsInput,
  GetGSNDrugsResponse,
  GET_GSN_DRUG_FOR_LIST,
  GSNDrug,
  PrioritizedRule,
} from "../api";

type PrioritySidebarMode =
  | { mode: "filtered results"; filteredDrugs: Array<GSNDrug> }
  | { mode: "search results"; searchedDrugs: Array<GSNDrug> };

/**
 * TODOs
 *  more of the state could be managed from here
 *  state operation are sub-optimal at best. Data Structures and Algo improvements...
 */

export type State = {
  allLoadedDrugs: Array<GSNDrug>;
  currentCursor: string;
  prioritySidebarMode: PrioritySidebarMode;
  loadDrugs: (
    drugSourceShort: DrugSourceShort,
    listId: string,
    prioritizedBaskets: Array<PrioritizedRule>,
    currentCursor: string
  ) => Promise<void>;
  showFilteredDrugs: (
    drugSourceShort: DrugSourceShort,
    prioritizedBaskets: Array<PrioritizedRule>
  ) => void;
  showSearchedDrugs: (
    drugSourceShort: DrugSourceShort,
    prioritizedBaskets: Array<PrioritizedRule>,
    searchResults: Array<GSNDrug>
  ) => void;
  reset: () => void;
};

export const usePrioritizedDrugsStore = create<State>((set) => ({
  allLoadedDrugs: [],
  currentCursor: "0",
  prioritySidebarMode: { mode: "filtered results", filteredDrugs: [] as Array<GSNDrug> },
  loadDrugs: async (
    drugSourceShort: DrugSourceShort,
    listId: string,
    prioritizedBaskets: Array<PrioritizedRule>,
    currentCursor: string
  ) => {
    const res = await AWSApolloClient.query<GetGSNDrugsResponse, GetGSNDrugsInput>({
      query: GET_GSN_DRUG_FOR_LIST,
      variables: {
        id: listId,
        drugsInput: {
          analyticsMode: true,
          asOfDate: apiFormatDate(new Date()),
          ...querySearchConfig(drugSourceShort),
        },
        pageInput: {
          after: currentCursor,
          first: PAGE_SIZE,
        },
      },
      fetchPolicy: "no-cache",
    });

    set((state) => {
      if (state.prioritySidebarMode.mode === "filtered results") {
        const drugsToAdd = res.data.list.drugs.items;
        const filteredDrugs = [
          ...state.prioritySidebarMode.filteredDrugs,
          ...filterDrugs(drugSourceShort, prioritizedBaskets, drugsToAdd),
        ];
        return {
          allLoadedDrugs: [...state.allLoadedDrugs, ...drugsToAdd],
          currentCursor:
            res.data.list.drugs.pageInfo.endCursor === END_CURSOR
              ? END_CURSOR
              : filteredDrugs.length.toString(),
          prioritySidebarMode: { mode: "filtered results", filteredDrugs },
        };
      } else {
        return { ...state };
      }
    });
  },
  showFilteredDrugs: (
    drugSourceShort: DrugSourceShort,
    prioritizedBaskets: Array<PrioritizedRule>
  ) => {
    set((state) => ({
      prioritySidebarMode: {
        mode: "filtered results",
        filteredDrugs: filterDrugs(drugSourceShort, prioritizedBaskets, state.allLoadedDrugs),
      },
    }));
  },
  showSearchedDrugs: (
    drugSourceShort: DrugSourceShort,
    prioritizedBaskets: Array<PrioritizedRule>,
    searchedDrugs: Array<GSNDrug>
  ) => {
    set(() => ({
      prioritySidebarMode: {
        mode: "search results",
        searchedDrugs: filterDrugs(drugSourceShort, prioritizedBaskets, searchedDrugs),
      },
    }));
  },
  reset: () => {
    set(() => ({
      currentCursor: "0",
      allLoadedDrugs: [],
      prioritySidebarMode: { mode: "filtered results", filteredDrugs: [] },
    }));
  },
}));

function filterDrugs(
  drugSourceShort: DrugSourceShort,
  prioritizedDrugs: Array<PrioritizedRule>,
  drugs: Array<GSNDrug>
): Array<GSNDrug> {
  return drugs.filter((drug) => {
    if (drugSourceShort === "FDB") {
      const {
        type,
        fdbDetails: { gcnSequenceNumber },
      } = drug;
      return (
        prioritizedDrugs.some((addedDrug) => {
          let gsnMatch = false;
          let bgStatusMatch = false;
          for (const condition of addedDrug.conditions) {
            if (condition.drugAttribute === DrugAttribute.gcnSequenceNumber) {
              gsnMatch = condition.matchValue === gcnSequenceNumber;
            } else if (condition.drugAttribute === DrugAttribute.isBrand) {
              bgStatusMatch =
                (condition.matchValue === "true" && type === "BRAND") ||
                (condition.matchValue === "false" && type === "GENERIC");
            }
          }
          return gsnMatch && bgStatusMatch;
        }) === false
      );
    } else {
      const { gpi14 } = drug.medispanDetails;
      return (
        prioritizedDrugs.some((addedDrug) => {
          return addedDrug.conditions.some(
            (condition) =>
              condition.drugAttribute === DrugAttribute.gpi14 && condition.matchValue === gpi14
          );
        }) === false
      );
    }
  });
}

const PAGE_SIZE = 250;

export const querySearchConfig = (
  drugSourceShort: DrugSourceShort,
  searchSettings?: { searchBy: DrugAttribute; searchText: string }
): any => {
  if (drugSourceShort === "FDB") {
    const baseParams = {
      sortConfig: [{ field: "labelName", sortDirection: "ASC" }],
      groupCols: [{ field: "gcnSequenceNumber" }, { field: "type" }],
    };
    return searchSettings
      ? {
          ...baseParams,
          filterModel: JSON.stringify({
            [searchSettings.searchBy]: {
              filterType: "text",
              type: "contains",
              filter: searchSettings.searchText,
            },
          }),
        }
      : baseParams;
  } else {
    const baseParams = {
      sortConfig: [{ field: "labelName", sortDirection: "ASC" }],
      groupCols: [{ field: "gpi14" }, { field: "mony" }],
    };
    return searchSettings
      ? {
          ...baseParams,
          filterModel: JSON.stringify({
            [searchSettings.searchBy]: {
              filterType: "text",
              type: "contains",
              filter: searchSettings.searchText,
            },
          }),
        }
      : baseParams;
  }
};
