import {
  AdminSections,
  AppSections,
  BenefitsSections,
  ListSections,
  TemplateSections,
  UserAreaSections,
} from "../types/AppTypes";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export const isAppSectionEnabled = (section: AppSections): boolean => {
  switch (section) {
    case AppSections.DRUG_LISTS:
      return true;
    case AppSections.DRUG_SEARCH:
      return false;
    case AppSections.FORMULARIES:
      return true;
    case AppSections.BENEFITS:
      return true;
    case AppSections.TEMPLATES:
      return true;
    case AppSections.NETWORK:
      return true;
  }
};

export const isAppSubSectionEnabled = (
  subSection: TemplateSections | ListSections | BenefitsSections
): boolean => {
  switch (subSection) {
    case TemplateSections.CRITERIA:
    case TemplateSections.FORMULARY:
    case TemplateSections.WEB_SEARCH:
      return true;
    case ListSections.ALT:
      return true;
    case ListSections.EDIT:
      return true;
    case ListSections.PRICE:
      return true;
    case ListSections.TIER:
      return true;
    case BenefitsSections.PLANS:
      return true;
    case BenefitsSections.PLAN_CONFIGS:
      return true;
  }
};

// @ts-ignore
export const isUserAreaSectionEnabled = (section: UserAreaSections): boolean => {
  if (section === UserAreaSections.ADMIN) {
    if (cookies.get("isAdmin") === "true") {
      return true;
    } else {
      return false;
    }
  }

  if (section === UserAreaSections.SIGN_OUT) {
    return true;
  }
  if (section === UserAreaSections.SWITCH_ACCOUNTS) {
    return true;
  }
  if (section === UserAreaSections.SUPPORT) {
    return true;
  }
  if (section === UserAreaSections.APP_SETTINGS) {
    return true; //TODO: Might want a flag here?
  }
};

// @ts-ignore
export const isAdminSectionEnabled = (section: AdminSections): boolean => {
  if (section && cookies.get("isAdmin") === "true") {
    if (AdminSections.USERS) {
      return true;
    }
    if (AdminSections.ACCOUNTS) {
      return true;
    }
  }
};
