import * as React from "react";
import {
  Avatar,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  makeStyles,
  Divider,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Email from "@material-ui/icons/Email";
import VpnKey from "@material-ui/icons/VpnKey";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { green } from "@material-ui/core/colors";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonProgressNormal: {
    top: "50%",
    left: "50%",
  },
  buttonProgressError: {
    top: "42%",
    left: "50%",
  },
  divider: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  fixedHeight: {
    height: theme.spacing(7),
  },
  forgotPasswordLink: {
    cursor: "pointer",
    color: "inherit",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    transition: theme.transitions.create("color", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  },
  form: {
    width: "100%",
  },
  wrapper: {
    position: "relative",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
}));

type Props = {
  email: string;
  onChangeEmail: (newEmail: string) => void;
  onLogin: (password: string) => Promise<void>;
  onForgotPassword: () => void;
};

export const LoginForm: React.FC<Props> = (props) => {
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInvalidLogin, setIsInvalidLogin] = React.useState(false);

  const classes = useStyles();

  const handleLogin = () => {
    if (!isLoading) {
      setIsLoading(true);
      props.onLogin(password).catch((err) => {
        console.error("error signing in..", err);
        setIsLoading(false);
        setIsInvalidLogin(true);
      });
    }
  };

  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <form
        className={classes.form}
        noValidate
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleLogin();
          }
        }}
      >
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={props.email}
          className={classes.fixedHeight}
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(event) => {
            props.onChangeEmail(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          className={classes.fixedHeight}
          autoComplete="current-password"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKey />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  {password && showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.wrapper}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.fixedHeight}
            onClick={handleLogin}
            disabled={isLoading}
          >
            <Typography color="inherit" variant="body2">
              Sign In
            </Typography>
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              className={classnames(
                classes.buttonProgress,
                isInvalidLogin ? classes.buttonProgressError : classes.buttonProgressNormal
              )}
            />
          )}
          {isInvalidLogin && (
            <Typography color="error">Invalid Login. Please try again.</Typography>
          )}
        </div>
        <Divider className={classes.divider} />
        <Typography
          align="center"
          variant="subtitle2"
          onClick={() => {
            props.onForgotPassword();
          }}
          className={classes.forgotPasswordLink}
        >
          Forgot Password?
        </Typography>
      </form>
    </>
  );
};
