import { Divider, InputBase, makeStyles, Paper, Theme } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchRounded";
import * as React from "react";
import { CenteredCircularLoading } from "../../../components/loading/CenteredCircularLoading";
import { UserContext } from "../../../components/UserContext";
import logoPlaceholder from "../../../styles/Graphics/logoPlaceholder.webp";
import { downloadFile } from "../../../utils/Files";
import { WebSearchTemplate } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  previewWrapper: {
    position: "relative",
    backgroundColor: "#979797",
    flex: 1,
    display: "flex",
    height: "100%",
    width: "100%",
  },
  rootPaper: {
    margin: theme.spacing(2),
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#ececec !important",
  },
  header: {
    backgroundColor: "#000000",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    maxHeight: 100,
    maxWidth: 200,
    marginLeft: theme.spacing(2),
  },
  searchBarWrapper: {
    display: "flex",
    margin: theme.spacing(4),
  },
  searchBar: {
    margin: theme.spacing(1),
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    maxWidth: 700,
    minWidth: 500,
  },
  searchBarIcon: {
    padding: 5,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  inputRoot: {
    flexWrap: "wrap",
    color: "#fff",
  },
  customerTextWrapper: {
    zIndex: 2,
    width: "62.5%",
    paddingTop: theme.spacing(5),
  },
}));

type Props = {
  template: WebSearchTemplate;
  additionalContent?: JSX.Element | React.ComponentType;
};

export const WebSearchPreview: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    user: { subAccountId },
  } = React.useContext(UserContext);
  const [logo, setLogo] = React.useState<string>(logoPlaceholder);
  const [isLoading, setIsLoading] = React.useState(false);

  // load logo
  React.useEffect(() => {
    if (props.template.settings.logoPath) {
      setIsLoading(true);
      downloadFile({ fileKey: props.template.settings.logoPath })
        .then((res) => {
          setLogo(res as string);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return () => {
      setLogo(logoPlaceholder);
    };
  }, [subAccountId, props.template.settings.logoPath]);

  return isLoading ? (
    <CenteredCircularLoading />
  ) : (
    <div className={classes.previewWrapper}>
      <Paper elevation={0} square className={classes.rootPaper}>
        <header
          className={classes.header}
          style={{
            backgroundColor: props.template.settings.primaryColor,
          }}
        >
          <img className={classes.logo} src={logo} alt="Company Logo" />
          <div className={classes.searchBarWrapper}>
            <Paper component="form" className={classes.searchBar}>
              <SearchIcon className={classes.searchBarIcon} fontSize="default" color="disabled" />
              <InputBase
                fullWidth
                id="test-id-search"
                placeholder="Search by drug name or therapy class"
                className={classes.searchInput}
                inputProps={{
                  classes: { root: classes.inputRoot },
                }}
              />
            </Paper>
          </div>
        </header>
        <div className={classes.customerTextWrapper}>
          <section
            dangerouslySetInnerHTML={{
              __html: decodeURIComponent(props.template.settings.landingPageInfoText ?? ""),
            }}
          />
          {props.additionalContent && (
            <>
              <Divider />
              {props.additionalContent}
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};
