import * as React from "react";
import { ColDef, IServerSideDatasource, RowClickedEvent } from "ag-grid-community";
import { UserContext } from "../../components/UserContext";
import { FDB } from "../../types/DrugSearchTypes";
import { DrugDetailsDrawer } from "./DrugDetailsDrawer/DrugDetailsDrawer";
import { queryFdbDrugs, requestToCriteria } from "./drugSearchApi";
import { COMMON_COL_DEF } from "./drugSearchCommon";
import { StyledAgGrid } from "../../components/tables/dataTables/StyledAgGrid";

const FDB_COLUMNS: Array<ColDef> = [
  COMMON_COL_DEF.LABEL_NAME,
  COMMON_COL_DEF.GENERIC_DESC,
  {
    headerName: "Med ID",
    field: "medId",
    filter: "text",
    width: 120,
  },
  {
    headerName: "GCN",
    field: "gcn",
    width: 100,
    filter: "text",
  },
  {
    headerName: "GSN",
    field: "gcnSequenceNumber",
    width: 100,
    filter: "text",
    hide: true,
  },
  COMMON_COL_DEF.BG_STATUS,
  COMMON_COL_DEF.IS_OTC,
  {
    headerName: "HIC3",
    field: "hic3",
    filter: "text",
    width: 100,
  },
  {
    headerName: "HIC3 Description",
    field: "hic3Description",
    filter: "text",
    width: 333,
  },
  COMMON_COL_DEF.ON_MARKET,
  COMMON_COL_DEF.OFF_MARKET,
  COMMON_COL_DEF.LAST_UPDATED,
  COMMON_COL_DEF.NDC,
  COMMON_COL_DEF.RXCUI,
  COMMON_COL_DEF.ROA,
];

export const FdbSearchGrid: React.FC = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<FDB | undefined>(undefined);

  const {
    user: { claimSystemId },
  } = React.useContext(UserContext);

  const handleRowClick = (event: RowClickedEvent) => {
    setSelectedRow(event.data);
    setShowDrawer(true);
  };

  const drugMetaData = {
    displayName: selectedRow?.labelName ?? "",
    ndc: selectedRow?.ndc ?? "",
    drugAttributes: {
      "Med ID": selectedRow?.medId,
      GCN: selectedRow?.gcn,
      GSN: selectedRow?.gcnSequenceNumber,
      RxCUI: selectedRow?.rxcui,
      "HIC 3": selectedRow?.hic3,
    },
  };

  const datasource: IServerSideDatasource = {
    getRows(params) {
      queryFdbDrugs(requestToCriteria(params.request, claimSystemId))
        .then((result) => {
          params.successCallback(result.drugs, result.lastRow);
        })
        .catch((err) => {
          console.error(err);
          params.failCallback();
        });
    },
  };

  return (
    <>
      <DrugDetailsDrawer
        isDrawerOpen={showDrawer}
        productUuid={selectedRow?.productUuid ?? ""}
        drugMetaData={drugMetaData}
        onClose={() => {
          setShowDrawer(false);
          setSelectedRow(undefined);
        }}
      />
      <StyledAgGrid
        onRowClicked={handleRowClick}
        //data model parameters
        columnDefs={FDB_COLUMNS}
        serverSideDatasource={datasource}
        rowModelType="serverSide"
        //performance parameters
        cacheBlockSize={100}
        maxBlocksInCache={10000}
        maxConcurrentDatasourceRequests={4}
        blockLoadDebounceMillis={250}
        serverSideSortingAlwaysResets={true}
      />
    </>
  );
};
