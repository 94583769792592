import { FormControl, Input, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EditorDialog } from "../../../components/dialogs/EditorDialog";
import { PAGE_SETTINGS, TABLE_SETTINGS } from "../../FieldNameConstant";
import { FullFormularyTemplate, TABLE_COLORS } from "../types";
import { ConfigOptionsTable } from "../../../components/tables/ConfigOptionsTable";
import { TableGroupBy } from "./TableGroupBy";
import { LabelSwitchController } from "../../../components/switches/LabelSwitchController";
import {
  GROUP_BY_DRUG_NAME_HELP_TEXT,
  SHOW_ROW_DIVIDERS_HELP_TEXT,
  SHOW_COL_DIVIDERS_HELP_TEXT,
} from "../../helpText";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignSelf: "start",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  switchEdgeFix: {
    marginLeft: "-12px",
  },
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  headerFooterButtons: {
    marginTop: 15,
    padding: "10px 8px",
    width: "fit-content",
    textTransform: "capitalize",
  },
}));

export const TableSettings: React.FC = () => {
  const classes = useStyles();

  const { setValue, getValues, watch } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  watch([TABLE_SETTINGS.HEADER, TABLE_SETTINGS.FOOTER]);

  const updateHeaderContent = (newContent: string) => {
    setValue(TABLE_SETTINGS.HEADER, newContent);
  };

  const updateFooterContent = (newContent: string) => {
    setValue(TABLE_SETTINGS.FOOTER, newContent);
  };

  return (
    <div className={classes.root}>
      <ConfigOptionsTable
        settings={[
          {
            label: "Show row dividers",
            helpText: SHOW_ROW_DIVIDERS_HELP_TEXT,
            options: (
              <LabelSwitchController
                isOn={getValues(TABLE_SETTINGS.SHOW_ROW_DIVIDERS)}
                disabled={isViewOnly}
                name={TABLE_SETTINGS.SHOW_ROW_DIVIDERS}
              />
            ),
          },
          {
            label: "Show column dividers",
            helpText: SHOW_COL_DIVIDERS_HELP_TEXT,
            options: (
              <LabelSwitchController
                isOn={getValues(TABLE_SETTINGS.SHOW_COLUMN_DIVIDERS)}
                disabled={isViewOnly}
                name={TABLE_SETTINGS.SHOW_COLUMN_DIVIDERS}
              />
            ),
          },
          {
            label: "Table headings font size",
            options: (
              <FormControl>
                <Controller
                  as={
                    <Select
                      input={<Input classes={{ focused: classes.themedUnderline }} />}
                      disabled={isViewOnly}
                      data-testid="Table headings font size"
                    >
                      {TABLE_FONT_SIZES.map((fontSize) => (
                        <MenuItem key={fontSize} value={fontSize}>
                          {fontSize}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  name={TABLE_SETTINGS.HEADING_FONT_SIZE}
                />
              </FormControl>
            ),
          },
          {
            label: "Table headings background color",
            options: (
              <Controller
                as={
                  <Select
                    input={<Input classes={{ focused: classes.themedUnderline }} />}
                    disabled={isViewOnly}
                    data-testid="Table headings background color"
                  >
                    <MenuItem value={TABLE_COLORS.BLACK}>Black</MenuItem>
                    <MenuItem value={TABLE_COLORS.GREY}>Gray</MenuItem>
                    <MenuItem value={TABLE_COLORS.WHITE}>White</MenuItem>
                  </Select>
                }
                name={TABLE_SETTINGS.HEADING_BACKGROUND_COLOR}
              />
            ),
          },
          {
            label: "Table cells font size",
            options: (
              <FormControl>
                <Controller
                  as={
                    <Select
                      input={<Input classes={{ focused: classes.themedUnderline }} />}
                      disabled={isViewOnly}
                      data-testid="Table cells font size"
                    >
                      {TABLE_FONT_SIZES.map((fontSize) => (
                        <MenuItem key={fontSize} value={fontSize}>
                          {fontSize}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  name={TABLE_SETTINGS.CELLS_FONT_SIZE}
                />
              </FormControl>
            ),
          },
          {
            label: "Group by drug name",
            options: <TableGroupBy />,
            helpText: GROUP_BY_DRUG_NAME_HELP_TEXT,
          },
        ]}
      />
      <Controller
        as={
          <EditorDialog
            title="Table Header"
            disabled={isViewOnly}
            templateFont={getValues(PAGE_SETTINGS.FONT)}
            leftMargin={getValues(PAGE_SETTINGS.LEFT_MARGIN)}
            rightMargin={getValues(PAGE_SETTINGS.RIGHT_MARGIN)}
            units={getValues(PAGE_SETTINGS.UNITS)}
            content={getValues(TABLE_SETTINGS.HEADER)}
            updateContent={updateHeaderContent}
          />
        }
        name={TABLE_SETTINGS.HEADER}
      />
      <Controller
        as={
          <EditorDialog
            title="Table Footer"
            disabled={isViewOnly}
            templateFont={getValues(PAGE_SETTINGS.FONT)}
            leftMargin={getValues(PAGE_SETTINGS.LEFT_MARGIN)}
            rightMargin={getValues(PAGE_SETTINGS.RIGHT_MARGIN)}
            units={getValues(PAGE_SETTINGS.UNITS)}
            content={getValues(TABLE_SETTINGS.FOOTER)}
            updateContent={updateFooterContent}
          />
        }
        name={TABLE_SETTINGS.FOOTER}
      />
    </div>
  );
};

const TABLE_FONT_SIZES = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
