import { makeStyles, Theme, Typography } from "@material-ui/core";
import classnames from "classnames";
import produce from "immer";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { SortEnd, SortStart } from "react-sortable-hoc";
import { SortableItem } from "../../../components/lists/horizontalSortableList/HorizontalListTypes";
import { HorizontalSortableList } from "../../../components/lists/horizontalSortableList/HorizontalSortableList";
import { moveElement } from "../../../utils/arrayUtils";
import { DESCRIPTION_COLUMN, EDITS_COLUMN, TIERS_COLUMN } from "../../FieldNameConstant";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";
import { ColumnSettingsState, COLUMN_NAMES, FullFormularyTemplate } from "../types";
import { DescriptionColumnForm } from "./FormularyTemplateColumns/DescriptionColumnForm";
import { EditsColumnForm } from "./FormularyTemplateColumns/EditsColumnForm";
import { TiersColumnForm } from "./FormularyTemplateColumns/TiersColumnForm";

const useStyles = makeStyles((theme: Theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tabsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  tabListItem: {
    padding: 10,
    width: 100,
    height: 50,
    textAlign: "center",
    borderRadius: 4,
    cursor: "pointer",
    overflow: "hidden",
  },
  tabListItemSelected: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
}));

type Props = {
  descriptionColumnPosition: number;
  editsColumnPosition: number;
  tierColumnPosition: number;
};

export const FormularyTemplateTableColumnsSection: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { getValues, setValue, watch } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  watch([
    TIERS_COLUMN.HIDDEN,
    EDITS_COLUMN.HIDDEN,
    DESCRIPTION_COLUMN.WIDTH,
    EDITS_COLUMN.WIDTH,
    TIERS_COLUMN.WIDTH,
    DESCRIPTION_COLUMN.POSITION,
    EDITS_COLUMN.POSITION,
    TIERS_COLUMN.POSITION,
  ]);

  const [selectedColumn, setSelectedColumn] = React.useState<COLUMN_NAMES>(
    COLUMN_NAMES.DESCRIPTION
  );

  const handleUpdateBeforeSortStart = (columns: Array<SortableItem>) => (event: SortStart) => {
    const selectedItemId = columns[event.index].id;
    const foundItem = columns.find((item) => item.id === selectedItemId);
    if (foundItem) {
      setSelectedColumn(foundItem.id as COLUMN_NAMES);
    }
  };

  const handleSortEnd = (sortEnd: SortEnd) => {
    const { oldIndex, newIndex } = sortEnd;
    const formularyTemplate = getValues({ nest: true });
    const columnSettings: ColumnSettingsState = {
      descriptionColumnSettings: formularyTemplate.settings.descriptionColumnSettings,
      editsColumnSettings: formularyTemplate.settings.editsColumnSettings,
      tierColumnSettings: formularyTemplate.settings.tierColumnSettings,
    };

    const rearrangedColumns = moveColumnInSettings(columnSettings, oldIndex, newIndex);
    updateColumnPosition(
      rearrangedColumns.descriptionColumnSettings.position,
      rearrangedColumns.editsColumnSettings.position,
      rearrangedColumns.tierColumnSettings.position
    );
  };

  const handleHideColumn = (columnToHide: COLUMN_NAMES.EDITS | COLUMN_NAMES.TIER) => () => {
    const { settings } = getValues({ nest: true });
    const columnSettings: ColumnSettingsState = {
      descriptionColumnSettings: settings.descriptionColumnSettings,
      editsColumnSettings: settings.editsColumnSettings,
      tierColumnSettings: settings.tierColumnSettings,
    };
    const columnsList = getColumnsList(columnSettings);
    const lastIndex = columnsList.findIndex((columnName) => {
      switch (columnName) {
        case COLUMN_NAMES.DESCRIPTION:
          return false;
        case COLUMN_NAMES.EDITS:
          return settings.editsColumnSettings.hidden === true;
        case COLUMN_NAMES.TIER:
          return settings.tierColumnSettings.hidden === true;
        default:
          return false;
      }
    });
    const endingPosition = lastIndex === -1 ? 2 : lastIndex - 1;
    const getCurrentColumnPosition = () => {
      switch (columnToHide) {
        case COLUMN_NAMES.EDITS:
          return settings.editsColumnSettings.position;
        case COLUMN_NAMES.TIER:
          return settings.tierColumnSettings.position;
        default:
          return 2;
      }
    };
    const startingPosition = getCurrentColumnPosition();
    const rearrangedColumns = moveColumnInSettings(
      columnSettings,
      startingPosition,
      endingPosition
    );
    updateColumnPosition(
      rearrangedColumns.descriptionColumnSettings.position,
      rearrangedColumns.editsColumnSettings.position,
      rearrangedColumns.tierColumnSettings.position
    );

    if (columnToHide === COLUMN_NAMES.EDITS) {
      setValue(EDITS_COLUMN.HIDDEN, true);
    } else {
      setValue(TIERS_COLUMN.HIDDEN, true);
    }
  };

  const updateColumnPosition = (
    newDescriptionColumnPos: number,
    newEditsColumnPos: number,
    newTierColumnPos: number
  ) => {
    setValue(DESCRIPTION_COLUMN.POSITION, newDescriptionColumnPos);
    setValue(EDITS_COLUMN.POSITION, newEditsColumnPos);
    setValue(TIERS_COLUMN.POSITION, newTierColumnPos);
  };

  const toSortableItem = (columnName: COLUMN_NAMES): SortableItem => {
    const getWidth = (columnName: COLUMN_NAMES) => {
      switch (columnName) {
        case COLUMN_NAMES.DESCRIPTION:
          return getValues({ nest: true })?.settings?.descriptionColumnSettings?.width;
        case COLUMN_NAMES.EDITS:
          return getValues({ nest: true })?.settings?.editsColumnSettings?.width;
        case COLUMN_NAMES.TIER:
          return getValues({ nest: true })?.settings?.tierColumnSettings?.width;
      }
    };

    return {
      id: columnName,
      listItem: (
        <div
          className={classnames(classes.tabListItem, {
            [classes.tabListItemSelected]: selectedColumn === columnName,
          })}
        >
          <Typography variant={selectedColumn === columnName ? "body2" : "body1"} color="inherit">
            {columnName}
          </Typography>
          <Typography variant={selectedColumn === columnName ? "body2" : "body1"} color="inherit">
            {getWidth(columnName)}
          </Typography>
        </div>
      ),
    };
  };

  const buildSeparateSortableLists = () => {
    const activeColumns = AllFormularyTemplateColumns.filter((columnName) => {
      switch (columnName) {
        case COLUMN_NAMES.DESCRIPTION:
          return true;
        case COLUMN_NAMES.EDITS:
          return (
            Boolean(getValues({ nest: true })?.settings?.editsColumnSettings?.hidden) === false
          );
        case COLUMN_NAMES.TIER:
          return Boolean(getValues({ nest: true })?.settings?.tierColumnSettings?.hidden) === false;
        default:
          return false;
      }
    })
      .sort((colA, colB) => {
        const colAPosition = columnOrderFromName(colA);
        const colBPosition = columnOrderFromName(colB);
        return colAPosition < colBPosition ? -1 : 1;
      })
      .map(toSortableItem);

    const disabledColumns = AllFormularyTemplateColumns.filter((columnName) => {
      switch (columnName) {
        case COLUMN_NAMES.DESCRIPTION:
          return false;
        case COLUMN_NAMES.EDITS:
          return Boolean(getValues(EDITS_COLUMN.HIDDEN));
        case COLUMN_NAMES.TIER:
          return Boolean(getValues(TIERS_COLUMN.HIDDEN));
        default:
          return false;
      }
    }).map(toSortableItem);

    return { activeColumns, disabledColumns };
  };

  const columnOrderFromName = (columnName: COLUMN_NAMES) => {
    switch (columnName) {
      case COLUMN_NAMES.DESCRIPTION:
        return props.descriptionColumnPosition;
      case COLUMN_NAMES.EDITS:
        return props.editsColumnPosition;
      case COLUMN_NAMES.TIER:
        return props.tierColumnPosition;
    }
  };

  function renderSortableList(): JSX.Element {
    const { activeColumns, disabledColumns } = buildSeparateSortableLists();

    return (
      <>
        {isViewOnly ? (
          <HorizontalSortableList
            items={activeColumns}
            updateBeforeSortStart={handleUpdateBeforeSortStart(activeColumns)}
            axis="x"
            lockAxis="x"
            disabled={true}
          />
        ) : (
          <HorizontalSortableList
            items={activeColumns}
            updateBeforeSortStart={handleUpdateBeforeSortStart(activeColumns)}
            onSortEnd={handleSortEnd}
            axis="x"
            lockAxis="x"
            disabled={true}
          />
        )}
        {disabledColumns.length > 0 && (
          <HorizontalSortableList
            items={disabledColumns}
            updateBeforeSortStart={handleUpdateBeforeSortStart(disabledColumns)}
            axis="x"
            lockAxis="x"
            disabled={true}
          />
        )}
      </>
    );
  }

  return (
    <div className={classes.sectionContainer}>
      <div className={classes.tabsContainer}>{renderSortableList()}</div>
      <DescriptionColumnForm
        disabled={isViewOnly}
        isActiveColumn={selectedColumn === COLUMN_NAMES.DESCRIPTION}
      />
      <EditsColumnForm
        isActiveColumn={selectedColumn === COLUMN_NAMES.EDITS}
        onHideColumn={handleHideColumn(COLUMN_NAMES.EDITS)}
      />
      <TiersColumnForm
        isActiveColumn={selectedColumn === COLUMN_NAMES.TIER}
        onHideColumn={handleHideColumn(COLUMN_NAMES.TIER)}
      />
    </div>
  );
};

const AllFormularyTemplateColumns = [
  COLUMN_NAMES.DESCRIPTION,
  COLUMN_NAMES.EDITS,
  COLUMN_NAMES.TIER,
];

const moveColumnInSettings = (
  columnSettings: ColumnSettingsState,
  fromPosition: number,
  toPosition: number
) => {
  const columns = getColumnsList(columnSettings);
  const arrangedColumns = moveElement(columns, fromPosition, toPosition);
  const reArrangedColumns = arrangedColumns.reduce((repositionedColumns, columnName, index) => {
    switch (columnName) {
      case COLUMN_NAMES.DESCRIPTION:
        return produce(repositionedColumns, (draft) => {
          draft.descriptionColumnSettings.position = index;
        });
      case COLUMN_NAMES.EDITS:
        return produce(repositionedColumns, (draft) => {
          draft.editsColumnSettings.position = index;
        });
      case COLUMN_NAMES.TIER:
        return produce(repositionedColumns, (draft) => {
          draft.tierColumnSettings.position = index;
        });
      default:
        return repositionedColumns;
    }
  }, columnSettings);
  return reArrangedColumns;
};

const getColumnsList = (columnSettings: ColumnSettingsState) => {
  const getColumn = (columnName: COLUMN_NAMES) => {
    switch (columnName) {
      case COLUMN_NAMES.DESCRIPTION:
        return columnSettings.descriptionColumnSettings;
      case COLUMN_NAMES.EDITS:
        return columnSettings.editsColumnSettings;
      case COLUMN_NAMES.TIER:
        return columnSettings.tierColumnSettings;
    }
  };
  return [COLUMN_NAMES.DESCRIPTION, COLUMN_NAMES.EDITS, COLUMN_NAMES.TIER].sort(
    (colNameA, colNameB) => {
      if (getColumn(colNameA).position < getColumn(colNameB).position) {
        return -1;
      } else {
        return 1;
      }
    }
  );
};
