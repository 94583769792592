import { Card, makeStyles, Theme } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: (props) => ({
    width: "100%",
    border: props.isBeingDragged ? `solid ${theme.palette.primary.main}` : `solid transparent`,
    transition: theme.transitions.create("border", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

interface Props {
  item: any;
  isBeingDragged: boolean;
  disabled: boolean;
  renderCardContent: (item: any, disabled: boolean) => JSX.Element;
}

type StyleProps = Pick<Props, "isBeingDragged">;

export const DraggableDrugCard: React.FC<Props> = (props) => {
  const classes = useStyles({ isBeingDragged: props.isBeingDragged });

  return (
    <Card className={classes.card}>{props.renderCardContent(props.item, props.disabled)}</Card>
  );
};
