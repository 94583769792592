import { FormControl, FormHelperText, makeStyles, TextField } from "@material-ui/core";
import * as React from "react";
import { Controller } from "react-hook-form";
import { REQUIRED_ERROR, minValueError } from "../../../errorHelpers";

const useStyles = makeStyles((theme) => ({
  themedUnderline: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  textFieldRoot: {
    width: "200px",
  },
}));

type Props = {
  disabled: boolean;
  widthFieldName: string;
  hasMaxWidthError: boolean;
  hasMinWidthError: boolean;
  hasRequiredError: boolean;
};

export const ColumnWidthInput: React.FC<Props> = ({
  disabled,
  widthFieldName,
  hasMaxWidthError,
  hasMinWidthError,
  hasRequiredError,
}) => {
  const classes = useStyles();

  return (
    <FormControl error={hasMaxWidthError || hasMinWidthError || hasRequiredError}>
      <Controller
        as={
          <TextField
            type="number"
            disabled={disabled}
            className={classes.textFieldRoot}
            InputProps={{ classes: { focused: classes.themedUnderline } }}
            inputProps={{ step: 1, max: 100, min: 0 }}
          />
        }
        name={widthFieldName}
        rules={{ max: 100, min: 0, required: true }}
      />
      {hasMaxWidthError && (
        <FormHelperText>100 is the combined width of the columns</FormHelperText>
      )}
      {hasMinWidthError && <FormHelperText>{minValueError(0)}</FormHelperText>}
      {hasRequiredError && <FormHelperText>{REQUIRED_ERROR}</FormHelperText>}
    </FormControl>
  );
};
