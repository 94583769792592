import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
    top: "55%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Props {
  onChangePassword: (password: string) => Promise<void>;
  onRedirect: () => Promise<void>;
}

export const NewPasswordForm: React.FC<Props> = (props) => {
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const classes = useStyles(undefined);

  const handleChangePassword = async () => {
    setIsLoading(true);
    props.onChangePassword(newPassword).catch((e) => {
      if (
        e.code === "UserLambdaValidationException" &&
        e.message.includes("PASSWORD_CHANGED_REDIRECT_TO_LOGIN")
      ) {
        props.onRedirect();
      } else {
        console.error(e);
        if (e.name === "InvalidPasswordException") {
          setError(e.message);
        }
        setIsLoading(false);
      }
    });
  };

  const displayPasswordsDoNotMatchError =
    confirmNewPassword !== "" && newPassword !== confirmNewPassword;

  return (
    <form className={classes.form} noValidate>
      <Typography component="h1" variant="h5">
        Update Your Password
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="new password"
        label="New Password"
        type={showNewPassword ? "text" : "password"}
        value={newPassword}
        autoComplete="new-password"
        error={displayPasswordsDoNotMatchError}
        onChange={(event) => {
          setNewPassword(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowNewPassword(!showNewPassword);
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="confirm new password"
        label="Confirm New Password"
        type={showConfirmPassword ? "text" : "password"}
        value={confirmNewPassword}
        autoComplete="confirm-new-password"
        error={displayPasswordsDoNotMatchError}
        onChange={(event) => {
          setConfirmNewPassword(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div style={{ position: "relative" }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleChangePassword}
          disabled={newPassword === "" || displayPasswordsDoNotMatchError || isLoading}
        >
          Change Password
        </Button>
        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
      {error && <Typography color="error">{error}</Typography>}
    </form>
  );
};
