import {
  Box,
  Button,
  CircularProgress,
  Divider,
  fade,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Auth } from "aws-amplify";
import VpnKey from "@material-ui/icons/VpnKey";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    marginTop: -12,
    marginLeft: -12,
    top: "50%",
    left: "50%",
  },
  buttonWrapper: {
    position: "relative",
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  divider: {
    width: "100%",
    margin: theme.spacing(2, 0, 2, 0),
  },
  fixedHeight: {
    height: theme.spacing(7),
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sendAgainLink: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontWeight: 400,
    "&:hover": {
      color: fade(theme.palette.primary.main, 0.8),
    },
  },
}));

type Props = {
  email: string;
  onChangeEmail: (newEmail: string) => void;
  onChangePassword: (userName: string, code: string, newPassword: string) => Promise<void>;
  onRedirect: () => Promise<void>;
};

export const ForgotPasswordForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [sendStatus, setSendStatus] = React.useState<
    undefined | "sending" | "sent" | "resending"
  >();
  const [resetCode, setResetCode] = React.useState<string>("");
  const [newPassword, setNewPassword] = React.useState<string>("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<{ code: string; message: string } | undefined>(
    undefined
  );

  const handleSendResetCode = () => {
    Auth.forgotPassword(props.email)
      .then((data) => {
        setSendStatus("sent");
        setError(undefined);
      })
      .catch((err) => {
        if (err.code === "UserNotFoundException") {
          setError({ code: err.code, message: "Account not found." });
        } else if (err.code === "LimitExceededException") {
          setError({
            code: err.code,
            message: "Account has exceeded reset attempts. Please try again later.",
          });
        } else {
          setError({ code: err.code, message: err.message });
        }
        setSendStatus(undefined);
        console.error("error sending code", err);
      });
  };

  const handleChangePassword = () => {
    setIsLoading(true);
    props.onChangePassword(props.email, resetCode, newPassword).catch((err) => {
      if (
        err.code === "UserLambdaValidationException" &&
        err.message.includes("PASSWORD_CHANGED_REDIRECT_TO_LOGIN")
      ) {
        props.onRedirect();
      } else {
        console.error(err);
        setError({ code: err.code, message: err.message });
        setIsLoading(false);
      }
    });
  };

  const insertErrorMessagesIfAny = () => {
    if (error) {
      if (
        error.code === "InvalidPasswordException" ||
        error.message.includes("Value at 'password' failed to satisfy constraint")
      ) {
        return (
          <>
            <Typography color="error" variant="body2" align="center">
              Password must meet the following conditions:
            </Typography>
            <Typography color="error" variant="body2" component="ul">
              <Box component="li">Must be 8 characters or longer.</Box>
              <Box component="li">Have uppercase letters.</Box>
              <Box component="li">Have lowercase letters.</Box>
              <Box component="li">Contain at least one special character.</Box>
            </Typography>
          </>
        );
      } else {
        return (
          <Typography color="error" variant="body2" align="center">
            {error.message}
          </Typography>
        );
      }
    } else {
      return <></>;
    }
  };

  if (sendStatus === undefined || sendStatus === "sending") {
    return (
      <Fade in={true}>
        <div className={classes.formWrapper}>
          <Typography variant="h5">Enter your email address below.</Typography>
          <Typography>A code for verifying your account will be sent to you.</Typography>
          <Divider className={classes.divider} />
          {insertErrorMessagesIfAny()}
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={props.email}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={error && error.code === "UserNotFoundException"}
            onChange={(event) => {
              props.onChangeEmail(event.target.value);
            }}
            className={classes.fixedHeight}
          />
          <div className={classes.buttonWrapper}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={sendStatus === "sending"}
              onClick={() => {
                setSendStatus("sending");
                handleSendResetCode();
              }}
              className={classes.fixedHeight}
            >
              <Typography color="inherit" variant="body2">
                {sendStatus === "sending" ? "Sending" : "Request Reset Code"}
              </Typography>
            </Button>
            {sendStatus === "sending" && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </div>
      </Fade>
    );
  } else {
    return (
      <Fade in={true}>
        <div className={classes.formWrapper}>
          <Typography color="inherit" align="center">
            Change your password using the code sent to you.
          </Typography>
          <Divider className={classes.divider} />
          {insertErrorMessagesIfAny()}
          <FormControl fullWidth>
            <TextField
              autoComplete="off"
              variant="outlined"
              fullWidth
              value={resetCode}
              label="Reset code"
              margin="normal"
              error={error && error.code === "CodeMismatchException"}
              onChange={(event) => {
                setResetCode(event.target.value);
              }}
              className={classes.fixedHeight}
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <TextField
              autoComplete="new-password"
              variant="outlined"
              fullWidth
              label="New password"
              margin="normal"
              error={
                error &&
                (error.code === "InvalidPasswordException" ||
                  error.message.includes("Value at 'password' failed to satisfy constraint"))
              }
              type={showPassword ? "text" : "password"}
              value={newPassword}
              className={classes.fixedHeight}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              inputProps={{
                form: {
                  autocomplete: "off",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VpnKey />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                    >
                      {newPassword && showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.buttonWrapper}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={newPassword.length <= 0}
                onClick={handleChangePassword}
                className={classes.fixedHeight}
              >
                <Typography color="inherit" variant="body2">
                  Change Password
                </Typography>
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <Divider className={classes.divider} />
            <Typography color="inherit" align="center">
              If you did not receive a code,{" "}
              <span
                onClick={() => {
                  setSendStatus("resending");
                  handleSendResetCode();
                }}
                className={classes.sendAgainLink}
              >
                click here{" "}
              </span>
              to send another one.
            </Typography>
          </FormControl>
        </div>
      </Fade>
    );
  }
};
