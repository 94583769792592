import gql from "graphql-tag";

//#region Types
export interface APIEditNetworkItem {
  id: string;
  name: string;
  code: string | null;
  region: string | null;
  externalId: string | null;
  lineOfBusiness: string;
  effectiveDate: string;
  terminationDate: string | null;
  tiers: {
    items: Array<EditNetworkTier>;
  };
}
export interface EditNetworkTier {
  id: string;
  subAccountNetworkTierId: string;
  name: string;
  tierOrder: number;
}

export interface NewNetworkItem {
  name: string;
  lineOfBusiness: string;
  effectiveDate: string;
  code: string | null;
  region: string | null;
  terminationDate: string | null;
  tiers: { items: Array<NewNetworkTier> };
}
export interface NewNetworkTier {
  id: string;
  order: number;
}

export function isEditTier(tier: NewNetworkTier | EditNetworkTier): tier is EditNetworkTier {
  return tier.hasOwnProperty("subAccountNetworkTierId");
}

export enum NetworkPageMode {
  NEW = "NEW",
  EDIT = "EDIT",
  VIEW = "VIEW",
  ERROR = "ERROR",
}

export interface LineOfBusiness {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  isMedicare: boolean;
}
//#endregion

//#region List Networks Query
export const LIST_NETWORKS = gql`
  query ListNetworks {
    networks {
      items {
        id
        name
        lineOfBusiness
        effectiveDate
        terminationDate
        code
        region
      }
    }
  }
`;

export type ListNetworksResponse = {
  networks: {
    items: Array<APIEditNetworkItem>;
  };
};
//#endregion

//#region Get Network Query
export const GET_NETWORK = gql`
  query GetNetwork($id: ID!) {
    network(id: $id) {
      id
      name
      code
      region
      lineOfBusiness
      effectiveDate
      terminationDate
      tiers {
        items {
          id
          subAccountNetworkTierId
          name
          tierOrder
        }
      }
    }
  }
`;

export type GetNetworkResponse = {
  network: APIEditNetworkItem;
};
//#endregion

//#region Get Lines of Business Query
export const GET_LOB = gql`
  query GetLOB {
    linesOfBusiness {
      items {
        id
        name
        createdAt
        updatedAt
        isMedicare
      }
    }
  }
`;

export type GetLOBResponse = {
  linesOfBusiness: {
    items: Array<LineOfBusiness>;
  };
};
//#endregion

//#region Get Network Tiers Query
export const GET_NETWORK_TIERS = gql`
  query GetNetworkTiers {
    subAccountNetworkTiers {
      items {
        id
        name
      }
    }
  }
`;

export interface GetNetworkTiersResponse {
  subAccountNetworkTiers: {
    items: Array<SubAccountNetworkTier>;
  };
}

export interface SubAccountNetworkTier {
  id: string;
  name: string;
}
//#endregion

//#region Create Network Mutation
export const CREATE_NETWORK = gql`
  mutation CreateNetwork($input: CreateNetworkInput!) {
    createNetwork(input: $input) {
      id
      name
      code
      region
      lineOfBusiness
      effectiveDate
      terminationDate
      tiers {
        items {
          id
          name
        }
      }
    }
  }
`;

export type CreateNetworkInput = {
  input: {
    name: string;
    lineOfBusiness: string;
    effectiveDate: string;
    tiers: Array<{ id: string }>;
    code: string | null;
    region: string | null;
    terminationDate: string | null;
  };
};
//#endregion

//#region Update Network Mutation
export const UPDATE_NETWORK = gql`
  mutation UpdateNetwork($id: ID!, $input: UpdateNetworkInput!) {
    updateNetwork(id: $id, input: $input) {
      id
      name
      code
      region
      lineOfBusiness
      effectiveDate
      terminationDate
      tiers {
        items {
          id
          name
        }
      }
    }
  }
`;

export interface UpdateNetworkInput {
  id: string;
  input: {
    code: string | null;
    name: string | null;
    region: string | null;
    terminationDate: string | null;
    tiers?: Array<NetworkTierUpdate> | null;
  };
}

export interface NetworkTierUpdate {
  networkTierId: string;
  subAccountNetworkTierId: string;
}
//#endregion
