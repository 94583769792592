import * as React from "react";
import { Hub } from "aws-amplify";
import { useHistory } from "react-router-dom";

const channel = new BroadcastChannel(window.location.hostname);

export type BroadcastMessage = {
  event: "signIn";
};

Hub.listen("auth", (data) => {
  if (data.payload.event === "signIn") {
    channel.postMessage({ event: "signIn" } as BroadcastMessage);
  }
  console.info(`Auth Event: ${JSON.stringify(data.payload)}`);
});

type BroadcastChannelContextProps = {
  sendMessage: (message: BroadcastMessage) => void;
};

const BroadcastChannelContext = React.createContext<BroadcastChannelContextProps>({
  sendMessage() {},
});

export const BroadcastProvider: React.FC = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    const onMessage = (ev: MessageEvent<BroadcastMessage>) => {
      if (ev.data.event === "signIn") {
        history.replace("/");
        window.location.reload();
      }
    };

    channel.addEventListener("message", onMessage);

    return () => channel.removeEventListener("message", onMessage);
  }, [history]);

  return (
    <BroadcastChannelContext.Provider
      value={{ sendMessage: (message) => channel.postMessage(message) }}
    >
      {props.children}
    </BroadcastChannelContext.Provider>
  );
};

export const useBroadcastChannel = () => React.useContext(BroadcastChannelContext);
