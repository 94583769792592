import * as React from "react";
import { Typography, Grid, Switch } from "@material-ui/core";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  isOn: boolean;
  yesLabel?: string;
  noLabel?: string;
  disabled?: boolean;
};

export const LabelSwitchController: React.FC<Props> = (props) => {
  const { name, isOn, yesLabel, noLabel } = props;
  const [checked, setChecked] = React.useState(isOn);

  const isDisabled = props.disabled ?? false;

  React.useEffect(() => {
    if (isOn) {
      setChecked(isOn);
    }
  }, [isOn]);

  return (
    <Grid component="label" container alignItems="center" justify="center" spacing={1}>
      <Grid item>
        <Typography
          color={checked ? "textSecondary" : "textPrimary"}
          variant={checked ? "body1" : "body2"}
        >
          {noLabel ? noLabel : "NO"}
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name={name}
          as={<Switch disabled={isDisabled} color="primary" />}
          onChange={([event]) => {
            setChecked(event.target.checked);
            return event.target.checked;
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          color={!isDisabled && checked ? "primary" : "textSecondary"}
          variant={checked ? "body2" : "body1"}
        >
          {yesLabel ? yesLabel : "YES"}
        </Typography>
      </Grid>
    </Grid>
  );
};
