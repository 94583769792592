import * as React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import wave from "../../styles/Graphics/wave.svg";
import BrokenMug from "../../styles/Graphics/Broken-Mug-big.webp";

const useStyles = makeStyles({
  wrapper: {
    height: "100vh",
    width: "100vw",
    zIndex: 1200,
    backgroundColor: deepPurple[400],
    background: `no-repeat url(${wave})`,
    backgroundPositionY: "top",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    alignItems: "center",
  },
  fadedBackgroundText: {
    position: "absolute",
    right: 10,
    bottom: 0,
    opacity: 0.2,
    fontSize: "5rem",
    fontWeight: 100,
  },
});

export const GeneralErrorPage: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img src={BrokenMug} alt="broken mug" style={{ width: "30%" }} />
      <div className={classes.content}>
        <Typography className={classes.fadedBackgroundText} color="inherit">
          Error
        </Typography>
        <Typography align="center" color="inherit" variant="h4" style={{ fontWeight: 300 }}>
          It looks like something broke.
        </Typography>
        <Typography align="center" color="inherit" variant="h5" style={{ fontWeight: 300 }}>
          Please wait a moment then try again. If the problem persists, contact support.
        </Typography>
      </div>
    </div>
  );
};
