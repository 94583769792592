import { ApiTemplateType } from "../../../../types/ApiTypes";

export enum ApiDocumentTypes {
  FullFormulary = "FULL_FORMULARY",
  Criteria = "CRITERIA",
  WebSearch = "WEB_SEARCH",
}

export enum ApiDocumentDataTypes {
  FullFormulary = "FULL_FORMULARY",
  PACriteria = "PA_CRITERIA",
  STCriteria = "ST_CRITERIA",
  WebSearch = "WEB_SEARCH",
}

export type PublishingTemplate = {
  id: string;
  name: string;
  templateType: ApiTemplateType;
};

export type ApiFullFormularyDocument = ApiCriteriaDocument | ApiFormularyDocument;

export type ApiCriteriaDocument = {
  id: string;
  name: string;
  documentType: ApiDocumentTypes.Criteria;
  settings: CriteriaDocumentSettings;
  publishHistory: ApiPublishHistory;
  downloadPath: string | null;
};
type CriteriaDocumentSettings = {
  frontMatter: string | null;
  backMatter: string | null;
  // stamps: any; // todo - implement later w/ feature
  // renderStatus?: DocumentRenderStatus; // todo should not necessarily be optional
  shown: boolean;
  dataType: ApiDocumentDataTypes.PACriteria | ApiDocumentDataTypes.STCriteria;
  documentDescription: string | null;
  template: {
    id: string;
    name: string;
    templateType: ApiTemplateType.Criteria;
  };
};

type ApiFormularyDocument = {
  id: string;
  name: string;
  documentType: ApiDocumentTypes.FullFormulary;
  settings: FormularyDocumentSettings;
  publishHistory: ApiPublishHistory;
  downloadPath: string | null;
};
type FormularyDocumentSettings = {
  frontMatter: string | null;
  backMatter: string | null;
  // stamps: any; // todo - implement later w/ feature
  // renderStatus?: DocumentRenderStatus; // todo should not necessarily be optional
  shown: boolean;
  dataType: ApiDocumentDataTypes.FullFormulary;
  documentDescription: string | null;
  template: {
    id: string;
    name: string;
    templateType: ApiTemplateType.FullFormulary;
  };
};

export type ApiPublishHistory = {
  items: Array<PublishHistory>;
};

export type PublishHistory = {
  errorMessage: string | null;
  status: DocumentRenderStatus;
  publishStartedAt: string;
  publishedAt: string | null;
  versionId: string;
};

export type ApiWebSearchSettings = {
  id: string;
  name: string;
  settings: {
    template: {
      id: string;
      name: string;
      templateType: ApiTemplateType.WebSearch;
    };
  };
};

export enum DocumentRenderStatus {
  DONE = "DONE",
  DONE_RENDERING = "DONE_RENDERING",
  FAILED = "FAILED",
  NOT_COPIED = "NOT_COPIED",
  RENDERING = "RENDERING",
  STAMPING = "STAMPING",
}

export type DocumentForm = {
  id?: string;
  name: string;
  kind:
    | {
        type: ApiDocumentTypes.Criteria;
        dataType: ApiDocumentDataTypes.PACriteria | ApiDocumentDataTypes.STCriteria;
      }
    | { type: ApiDocumentTypes.FullFormulary; dataType: ApiDocumentDataTypes.FullFormulary }
    | { type: "new"; dataType: null };
  template?: { id: string; name: string };
  shown: boolean;
  frontMatter: string | null;
  backMatter: string | null;
  documentDescription: string | null;
  downloadPath: string | null;
  publishHistory: ApiPublishHistory;
};

/**
 * New Web Search Settings Form = just name field defined as ""
 */
export type WebSearchSettingsForm = {
  id?: string;
  name: string;
  template?: { id: string; name: string };
};

export const apiToFormDocument = (apiDocument: ApiFullFormularyDocument): DocumentForm => {
  switch (apiDocument.documentType) {
    case ApiDocumentTypes.Criteria:
      return {
        kind: {
          type: ApiDocumentTypes.Criteria,
          dataType: apiDocument.settings.dataType,
        },
        backMatter: apiDocument.settings.backMatter,
        documentDescription: apiDocument.settings.documentDescription,
        frontMatter: apiDocument.settings.frontMatter,
        name: apiDocument.name,
        publishHistory: apiDocument.publishHistory,
        shown: apiDocument.settings.shown,
        id: apiDocument.id,
        template: {
          id: apiDocument.settings.template.id,
          name: apiDocument.settings.template.name,
        },
        downloadPath: apiDocument.downloadPath,
      };
    case ApiDocumentTypes.FullFormulary:
      return {
        kind: {
          type: ApiDocumentTypes.FullFormulary,
          dataType: apiDocument.settings.dataType,
        },
        backMatter: apiDocument.settings.backMatter,
        documentDescription: apiDocument.settings.documentDescription,
        frontMatter: apiDocument.settings.frontMatter,
        name: apiDocument.name,
        publishHistory: apiDocument.publishHistory,
        shown: apiDocument.settings.shown,
        id: apiDocument.id,
        template: {
          id: apiDocument.settings.template.id,
          name: apiDocument.settings.template.name,
        },
        downloadPath: apiDocument.downloadPath,
      };
  }
};
