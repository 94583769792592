import { Drawer, Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { CenteredCircularLoading } from "../../../../components/loading/CenteredCircularLoading";
import { DrugMetaDataCard } from "../../../DrugSearch/DrugDetailsDrawer/DrugMetaDataCard";
import {
  ListDrug,
  GET_RULES_FOR_DRUG,
  ApiListRule,
  TerminateRuleResponse,
  TerminateRuleInput,
  TERMINATE_RULE,
  RuleForDrugInput,
  RulesForDrugResponse,
} from "../../api";
import { useQuery, useMutation } from "@apollo/client";
import { apiFormatDate } from "../../../../utils/Date";
import { RuleCard } from "../../../../components/cards/RuleCard";
import classNames from "classnames";
import { useDrugListRouteParams } from "../../DrugListRouteUtils";

const useStyles = makeStyles((theme) => ({
  ruleCardColumn: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  drugDrawer: {
    maxWidth: "40%",
    minWidth: "35%",
  },
  brand: {
    textTransform: "uppercase",
  },
  generic: {
    textTransform: "lowercase",
    fontStyle: "italic",
  },
  margins: {
    margin: theme.spacing(2),
  },
}));

type Props = {
  asOfDate: Date;
  drug: ListDrug;
  onClose: () => void;
};

export const DrugListDrugDrawer: React.FC<Props> = (props) => {
  const { drug, asOfDate } = props;
  const { drugListId } = useDrugListRouteParams();
  const classes = useStyles();
  const [rulesForDrug, setRulesForDrug] = React.useState<Array<ApiListRule>>([]);
  const [terminateRule] = useMutation<TerminateRuleResponse, TerminateRuleInput>(TERMINATE_RULE);
  const { loading, refetch } = useQuery<RulesForDrugResponse, { input: RuleForDrugInput }>(
    GET_RULES_FOR_DRUG,
    {
      skip: drugListId === undefined,
      variables: {
        input: {
          drugListId: drugListId as string,
          asOfDate: apiFormatDate(asOfDate)!,
          ndc: drug.ndc,
        },
      },
      onCompleted: (res) => {
        setRulesForDrug(res.rulesForDrug.items);
      },
    }
  );

  const handleTerminateRule = (ruleId: string) => (date: Date) => {
    if (drugListId) {
      terminateRule({ variables: { id: ruleId, terminationDate: apiFormatDate(date) as string } })
        .then(() => {
          refetch({
            input: { drugListId, asOfDate: apiFormatDate(asOfDate)!, ndc: drug.ndc },
          });
        })
        .catch((e) => {
          console.error("ERROR WHILE TERMINATING RULE", e);
        });
    }
  };

  return (
    <Drawer
      open={Boolean(drug)}
      onClose={props.onClose}
      anchor="right"
      PaperProps={{ className: classes.drugDrawer }}
    >
      <Typography
        variant="h5"
        color="primary"
        className={classNames(
          drug.type === "BRAND" ? classes.brand : classes.generic,
          classes.margins
        )}
      >
        {drug.drugName.toLowerCase()}
      </Typography>
      {drug && (
        <DrugMetaDataCard
          displayName={drug.labelName}
          ndc={drug.ndc}
          //TODO this will flex on FDB/Medispan
          drugAttributes={{
            "B/G": drug.type,
            "FDB Med Id": drug.fdbDetails?.medId,
            GCN: drug.fdbDetails?.gcn,
            "HICL Seq. No.": drug.fdbDetails?.hiclSequenceNumber,
            "GPI 14": drug.medispanDetails?.gpi14,
            MONY: drug.medispanDetails?.mony,
          }}
        />
      )}
      <div className={classes.margins}>
        <Typography>This drug appears on this list because of the following rules:</Typography>
      </div>
      <Grid container spacing={2} className={classes.ruleCardColumn}>
        {loading ? (
          <CenteredCircularLoading />
        ) : (
          rulesForDrug.map((rule, index) => (
            <Grid item key={index} style={{ width: 280 }}>
              <RuleCard rule={rule} handleTerminate={handleTerminateRule(rule.id as string)} />
            </Grid>
          ))
        )}
      </Grid>
    </Drawer>
  );
};
