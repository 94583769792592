export enum ApiTemplateType {
  FullFormulary = "FULL_FORMULARY",
  WebSearch = "WEB_SEARCH",
  Criteria = "CRITERIA",
}

export const TEMPLATE_TYPE_USER_NAMES: Record<ApiTemplateType, string> = {
  [ApiTemplateType.FullFormulary]: "Full Formulary",
  [ApiTemplateType.WebSearch]: "Web Search",
  [ApiTemplateType.Criteria]: "Criteria",
};

export type PageInfo = {
  endCursor?: string;
};

export type PaginationInput = {
  after: string | null;
  first: number | null;
};
export type PaginationResponse = {
  endCursor: string | null;
};

export const END_CURSOR = "*";
