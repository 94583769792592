import { CircularProgress, Drawer, makeStyles, Theme, Typography } from "@material-ui/core";
import Questionmark from "@material-ui/icons/HelpRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmationDialog } from "../../components/dialogs/ConfirmationDialog";
import { PdfPreview } from "../../components/pdfPreview/PdfPreview";
import { TEMPLATE_PRIMARY_COLOR } from "../../styles/Theme";
import { useCriteriaTemplatePreview } from "./api";
import { ManagementPage } from "./constants";
import { CriteriaTemplateForm } from "./EditTemplates/EditCriteriaTemplateForm";
import { CriteriaTemplateGuide } from "./PreviewArea/CriteriaTemplateGuide";
import { CriteriaTemplate, CriteriaTemplateStatus } from "./types";

interface StyleProps {
  previewOut: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  settingsArea: {
    gridColumn: "span 8",
    alignSelf: "start",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
  },
  loadingArea: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  previewArea: (props) => ({
    position: "relative",
    backgroundColor: "#979797",
    gridColumn: "span 8",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    userSelect: "none",
    overflow: "scroll",
    ...(props.previewOut && {
      overflow: "hidden",
    }),
  }),
  fullPreviewDrawer: (props) => ({
    position: "sticky",
    top: 0,
    zIndex: 2,
    ...(!props.previewOut && {
      zIndex: 0,
      transition: theme.transitions.create("z-index", {
        duration: theme.transitions.duration.leavingScreen,
        delay: theme.transitions.duration.leavingScreen,
      }),
    }),
    width: "100%",
    height: "100%",
  }),
  fullPreviewDrawerPaper: {
    position: "inherit",
  },
}));

type Props = {
  templateStatus: CriteriaTemplateStatus;
  updateTemplate: (template: CriteriaTemplate) => void;
  saveTemplate: () => void; // TODO - after migration can move into this component
};

export const CriteriaTemplateContainer: React.FC<Props> = (props) => {
  const history = useHistory();

  const [showFullPreview, setShowFullPreview] = React.useState(false);
  const [isDialogVisible, setDialogVisibility] = React.useState(false);

  const { previewCriteriaTemplate, previewStatus } = useCriteriaTemplatePreview(
    <Typography variant="h5">
      Select a <span style={{ color: TEMPLATE_PRIMARY_COLOR }}>Template</span> on the left to see a
      preview of its layout.
    </Typography>
  );

  const classes = useStyles({ previewOut: showFullPreview });

  const handlePreview = async () => {
    if (props.templateStatus.status !== "loading") {
      setShowFullPreview(true);
      previewCriteriaTemplate(props.templateStatus.template);
    }
  };

  const toggleConfirmDialog = () => {
    setDialogVisibility(!isDialogVisible);
  };

  const navigateBack = () => {
    setDialogVisibility(!isDialogVisible);
    history.push(ManagementPage);
  };

  const handleClosePreview = () => {
    setShowFullPreview(false);
  };

  const handleUpdateTemplate = (template: CriteriaTemplate) => {
    props.updateTemplate(template);
  };

  return (
    <>
      <div className={classes.settingsArea}>
        {props.templateStatus.status === "loading" ? (
          <div className={classes.loadingArea}>
            <CircularProgress />
          </div>
        ) : (
          <CriteriaTemplateForm
            template={props.templateStatus.template}
            updateTemplate={handleUpdateTemplate}
            onBack={navigateBack}
            onPreview={handlePreview}
            onClosePreview={handleClosePreview}
            saveTemplate={props.saveTemplate}
            isSaving={props.templateStatus.status === "saving"}
          />
        )}
      </div>

      <div id="previewArea" className={classes.previewArea}>
        <Drawer
          anchor="right"
          variant="persistent"
          classes={{
            root: classes.fullPreviewDrawer,
            paper: classes.fullPreviewDrawerPaper,
          }}
          open={showFullPreview}
          SlideProps={{
            unmountOnExit: true,
            mountOnEnter: true,
          }}
        >
          <PdfPreview status={previewStatus} style={{ gridColumn: "5 / 17" }} />
        </Drawer>
        <CriteriaTemplateGuide />
      </div>

      {/* Todo - the leave confirmation dialog is common enough it can be abstracted into its own component */}
      <ConfirmationDialog
        isOpen={isDialogVisible}
        onYesHandler={navigateBack}
        onNoHandler={toggleConfirmDialog}
        dialogIcon={<Questionmark fontSize="large" color="inherit" />}
        dialogTitle="Warning"
        dialogContent={
          <Typography variant="body1">
            Leaving this page will result in the loss of all unsaved changes. Are you sure you want
            to leave?
          </Typography>
        }
      />
    </>
  );
};
