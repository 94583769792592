import { AppBar, Button, Dialog, makeStyles, Slide, Toolbar, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import * as React from "react";
import { WebSearchTextEditor } from "./WebSearchTextEditor";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

type Props = {
  editorText: string;
  isOpen: boolean;
  onClickClose: () => void;
  onClickUpdate: (editorContent: string) => void;
};

export const FullScreenEditorDialog: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [editorText, setEditorText] = React.useState(props.editorText);

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClickClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.title}>
            Landing Page Text
          </Typography>
          <Button
            variant="outlined"
            autoFocus
            color="inherit"
            onClick={() => {
              props.onClickClose();
              setEditorText(props.editorText);
            }}
            style={{ marginRight: "1rem" }}
          >
            cancel
          </Button>
          <Button
            variant="outlined"
            autoFocus
            color="inherit"
            onClick={() => {
              props.onClickUpdate(editorText);
            }}
          >
            ok
          </Button>
        </Toolbar>
      </AppBar>
      <WebSearchTextEditor
        content={editorText}
        onUpdateContent={(content: string) => {
          setEditorText(content);
        }}
      />
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
