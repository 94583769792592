import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Fade,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/CheckRounded";
import ErrorIcon from "@material-ui/icons/Error";
import TemplateIcon from "@material-ui/icons/StyleRounded";
import classnames from "classnames";
import format from "date-fns/format";
import produce from "immer";
import * as React from "react";
import { UploadButton } from "../../../../components/buttons/UploadButton";
import { PDFIcon } from "../../../../components/Icons/customIcons";
import { LabelSwitch } from "../../../../components/switches/LabelSwitch";
import { OptionsTable } from "../../../../components/tables/OptionsTable";
import { UserContext } from "../../../../components/UserContext";
import { ApiTemplateType, TEMPLATE_TYPE_USER_NAMES } from "../../../../types/ApiTypes";
import { openURLInNewTab } from "../../../../utils/Browser";
import { getFileName, publishedDocumentPreviewPath, saveFile } from "../../../../utils/Files";
import { sharedStyles } from "../../../styles";
import {
  CreateCriteriaDocumentInput,
  CreateFormularyDocumentInput,
  CREATE_CRITERIA_DOCUMENT,
  CREATE_FORMULARY_DOCUMENT,
  ListTemplatesResponse,
  LIST_TEMPLATES,
  UpdateCriteriaDocumentInput,
  UpdateFormularyDocumentInput,
  UPDATE_CRITERIA_DOCUMENT,
  UPDATE_FORMULARY_DOCUMENT,
} from "../api";
import { FormularyEditingContext } from "../FormularyEditingContext";
import {
  ApiDocumentDataTypes,
  ApiDocumentTypes,
  DocumentForm,
  DocumentRenderStatus,
  PublishHistory,
  PublishingTemplate,
} from "./DocumentTypes";
import { SaveFabButton } from "./SaveFabButton";
import { TemplateList } from "./TemplateList";

const useStyles = makeStyles((theme) => ({
  settingsContainer: {
    gridColumn: "span 12",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 88px - 1px - 48px - 1px)",
    overflow: "auto",
    alignItems: "center",
  },
  tabContent: {
    flexDirection: "column",
  },
  formularyCard: {
    width: 585,
  },
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
    textAlign: "left",
  },
  descriptionTextField: {
    marginTop: theme.spacing(2),
  },
  templateDrawer: {
    minWidth: 350,
    maxWidth: 500,
  },
  historyTable: {
    maxHeight: 300,
    overflowY: "auto",
  },
}));

interface Props {
  formularyId: string;
  document: DocumentForm;
  existingDocumentTypes: Record<ApiDocumentTypes, number>;
  onUpdateDocumentState: (document: DocumentForm) => void;
  onSaveChanges: () => void;
}

export const DocumentSettings: React.FC<Props> = (props) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const isEditing = React.useContext(FormularyEditingContext);

  const [showTemplateList, setShowTemplateList] = React.useState(false);
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);
  const [availableTemplates, setAvailableTemplates] = React.useState<
    Map<string, Array<PublishingTemplate>>
  >(new Map());

  const { loading: isLoadingTemplates } = useQuery<ListTemplatesResponse>(LIST_TEMPLATES, {
    onCompleted: (res) => {
      setAvailableTemplates(
        produce(availableTemplates, (draft) => {
          draft.set("Full Formulary", res.FullFormulary.items || []);
          draft.set("Criteria", res.Criteria.items || []);
        })
      );
    },
  });

  const [createFormularyDocument, { loading: isCreatingFormularyDocument }] = useMutation<
    unknown,
    CreateFormularyDocumentInput
  >(CREATE_FORMULARY_DOCUMENT);
  const [updateFormularyDocument, { loading: isUpdatingFormularyDocument }] = useMutation<
    unknown,
    UpdateFormularyDocumentInput
  >(UPDATE_FORMULARY_DOCUMENT);

  const [createCriteriaDocument, { loading: isCreatingCriteriaDocument }] = useMutation<
    unknown,
    CreateCriteriaDocumentInput
  >(CREATE_CRITERIA_DOCUMENT);
  const [updateCriteriaDocument, { loading: isUpdatingCriteriaDocument }] = useMutation<
    unknown,
    UpdateCriteriaDocumentInput
  >(UPDATE_CRITERIA_DOCUMENT);

  const handleSaveDocument = () => {
    const { document } = props;
    const name =
      document.name ||
      documentPlaceholderName(props.document.kind.type, props.existingDocumentTypes);
    if (document.kind.type !== "new" && document.template) {
      // if editing document
      if (document.id) {
        if (document.kind.type === ApiDocumentTypes.Criteria) {
          updateCriteriaDocument({
            variables: {
              documentId: document.id,
              formularyId: props.formularyId,
              name,
              settings: {
                backMatter: document.backMatter,
                frontMatter: document.frontMatter,
                documentDescription: document.documentDescription,
                shown: document.shown,
                templateId: document.template.id,
                dataType: document.kind.dataType,
              },
            },
          }).then(() => {
            props.onSaveChanges();
          });
        } else if (document.kind.type === ApiDocumentTypes.FullFormulary) {
          updateFormularyDocument({
            variables: {
              documentId: document.id,
              formularyId: props.formularyId,
              name,
              settings: {
                backMatter: document.backMatter,
                documentDescription: document.documentDescription,
                frontMatter: document.frontMatter,
                shown: document.shown,
                templateId: document.template.id,
              },
            },
          }).then(() => {
            props.onSaveChanges();
          });
        }
      }
      // else creating new doc
      else {
        if (document.kind.type === ApiDocumentTypes.Criteria) {
          createCriteriaDocument({
            variables: {
              formularyId: props.formularyId,
              name,
              settings: {
                backMatter: document.backMatter,
                frontMatter: document.frontMatter,
                documentDescription: document.documentDescription,
                shown: document.shown,
                templateId: document.template.id,
                dataType: document.kind.dataType,
              },
            },
          }).then(() => {
            props.onSaveChanges();
          });
        } else if (document.kind.type === ApiDocumentTypes.FullFormulary) {
          createFormularyDocument({
            variables: {
              formularyId: props.formularyId,
              name,
              settings: {
                backMatter: document.backMatter,
                documentDescription: document.documentDescription,
                frontMatter: document.frontMatter,
                shown: document.shown,
                templateId: document.template.id,
              },
            },
          }).then(() => {
            props.onSaveChanges();
          });
        }
      }
    }
  };

  const handleUpdateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdateDocumentState({
      ...props.document,
      name: event.target.value,
    });
  };

  const handleShowTemplateList = () => {
    setShowTemplateList(true);
  };

  const handleUpdateDataType = ({ target: { value } }: React.ChangeEvent<{ value: unknown }>) => {
    if (value === ApiDocumentDataTypes.PACriteria || value === ApiDocumentDataTypes.STCriteria) {
      props.onUpdateDocumentState(
        produce(props.document, (draft) => {
          draft.kind.dataType = value;
        })
      );
    }
  };

  const updateShowInWebSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onUpdateDocumentState({ ...props.document, shown: event.target.checked });
  };

  const handleUpdateDocumentDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onUpdateDocumentState({ ...props.document, documentDescription: event.target.value });
  };

  const handleSelectTemplate = ({ id, name, templateType }: PublishingTemplate) => {
    const template = { id, name };
    if (props.document.kind.type === "new") {
      if (templateType === ApiTemplateType.FullFormulary) {
        props.onUpdateDocumentState({
          ...props.document,
          template,
          kind: {
            type: ApiDocumentTypes.FullFormulary,
            dataType: ApiDocumentDataTypes.FullFormulary,
          },
        });
      } else if (templateType === ApiTemplateType.Criteria) {
        props.onUpdateDocumentState({
          ...props.document,
          template,
          kind: {
            type: ApiDocumentTypes.Criteria,
            dataType: ApiDocumentDataTypes.PACriteria,
          },
        });
      }
      setShowTemplateList(false);
    } else if (
      templateType === ApiTemplateType.Criteria &&
      props.document.kind.type === ApiDocumentTypes.FullFormulary
    ) {
      props.onUpdateDocumentState({
        ...props.document,
        template,
        kind: {
          type: ApiDocumentTypes.Criteria,
          dataType: ApiDocumentDataTypes.PACriteria,
        },
      });
    } else if (
      templateType === ApiTemplateType.FullFormulary &&
      props.document.kind.type === ApiDocumentTypes.Criteria
    ) {
      props.onUpdateDocumentState({
        ...props.document,
        template,
        kind: {
          type: ApiDocumentTypes.FullFormulary,
          dataType: ApiDocumentDataTypes.FullFormulary,
        },
      });
    } else {
      props.onUpdateDocumentState({
        ...props.document,
        template,
      });
    }
    setShowTemplateList(false);
  };

  const uploadContent = async (file: File, onFinishedUpload: (filePath: string) => void) => {
    setIsUploadingFile(true);
    try {
      const uploadPath = await saveFile({ fileType: { type: "documents uploads" }, file });
      onFinishedUpload(uploadPath);
      setIsUploadingFile(false);
    } catch (e) {
      console.error(e);
      setIsUploadingFile(false);
    }
  };

  const uploadFrontMatter = (file: File) => {
    return uploadContent(file, (filePath) => {
      props.onUpdateDocumentState({ ...props.document, frontMatter: `public/${filePath}` });
    });
  };

  const uploadBackMatter = (file: File) => {
    return uploadContent(file, (filePath) => {
      props.onUpdateDocumentState({ ...props.document, backMatter: `public/${filePath}` });
    });
  };

  const removeFrontContent = () => {
    props.onUpdateDocumentState({ ...props.document, frontMatter: null });
  };

  const removeBackContent = () => {
    props.onUpdateDocumentState({ ...props.document, backMatter: null });
  };

  const SHOW_IN_WEB_SEARCH = {
    label: "Show in Web Search",
    options: (
      <LabelSwitch
        disabled={!isEditing}
        switchProps={{ onChange: updateShowInWebSearch, checked: props.document.shown }}
      />
    ),
  };

  const FRONT_CONTENT = {
    label: "Front Content",
    options: (
      <UploadButton
        fileType="application/pdf"
        fileName={getFileName(props.document.frontMatter || "")}
        handleUpload={uploadFrontMatter}
        onRemoveFile={removeFrontContent}
        disabled={!isEditing}
      />
    ),
  };

  const BACK_CONTENT = {
    label: "Back Content",
    options: (
      <UploadButton
        fileType="application/pdf"
        fileName={getFileName(props.document.backMatter || "")}
        handleUpload={uploadBackMatter}
        onRemoveFile={removeBackContent}
        disabled={!isEditing}
      />
    ),
  };

  const template = props.document.kind.type === "new" ? undefined : props.document.template;
  const isSavingDocument =
    isCreatingCriteriaDocument ||
    isCreatingFormularyDocument ||
    isUpdatingCriteriaDocument ||
    isUpdatingFormularyDocument;

  return (
    <>
      <div className={classes.settingsContainer}>
        <section className={classnames(sharedClasses.tabContent, classes.tabContent)}>
          <Card className={classnames(sharedClasses.formularyCard, classes.formularyCard)}>
            <CardHeader title="Properties" />
            <Divider />
            <CardContent id="document-properties">
              <OptionsTable
                settings={[
                  {
                    label: "Name",
                    options: (
                      <TextField
                        value={props.document.name}
                        placeholder={documentPlaceholderName(
                          props.document.kind.type,
                          props.existingDocumentTypes
                        )}
                        onChange={handleUpdateName}
                        className={classes.textFieldOptions}
                        disabled={!isEditing}
                      />
                    ),
                  },
                  {
                    label: "Template",
                    options: template ? (
                      <TextField
                        value={template.name || ""}
                        disabled={!isEditing}
                        className={classes.textFieldOptions}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <IconButton
                              size="small"
                              disabled={!isEditing}
                              onClick={handleShowTemplateList}
                            >
                              <TemplateIcon />
                            </IconButton>
                          ),
                        }}
                      />
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<TemplateIcon />}
                        disabled={!isEditing}
                        onClick={handleShowTemplateList}
                      >
                        Choose Template
                      </Button>
                    ),
                  },
                  {
                    label: "Type",
                    options:
                      props.document.kind.type === ApiDocumentTypes.Criteria ? (
                        <Select
                          value={props.document.kind.dataType}
                          className={classes.textFieldOptions}
                          onChange={handleUpdateDataType}
                          disabled={!isEditing}
                        >
                          <MenuItem key="PA" value={ApiDocumentDataTypes.PACriteria}>
                            PA Criteria
                          </MenuItem>
                          <MenuItem key="ST" value={ApiDocumentDataTypes.STCriteria}>
                            ST Criteria
                          </MenuItem>
                        </Select>
                      ) : (
                        <TextField
                          disabled
                          className={classes.textFieldOptions}
                          value={
                            props.document.kind.type === ApiDocumentTypes.FullFormulary
                              ? TEMPLATE_TYPE_USER_NAMES[ApiTemplateType.FullFormulary]
                              : ""
                          }
                          InputProps={{ readOnly: true }}
                        />
                      ),
                  },
                ]}
              />
            </CardContent>
            <Divider />
          </Card>
          <Card className={classnames(sharedClasses.formularyCard, classes.formularyCard)}>
            <CardHeader title="Features" />
            <Divider />
            <CardContent>
              <OptionsTable settings={[SHOW_IN_WEB_SEARCH, FRONT_CONTENT, BACK_CONTENT]} />
              <TextField
                multiline
                fullWidth
                value={props.document.documentDescription || ""}
                disabled={!isEditing}
                onChange={handleUpdateDocumentDescription}
                className={classes.descriptionTextField}
                label="Document description"
                rows="6"
                variant="outlined"
              />
            </CardContent>
          </Card>
          {props.document.id && (
            <PublishHistoryTable
              publishHistory={props.document.publishHistory.items ?? []}
              documentPath={props.document.downloadPath}
            />
          )}
        </section>
        <Fade in={Boolean(template)}>
          <SaveFabButton
            isDisabled={!isEditing || props.document.template === undefined}
            isNew={props.document.kind.type === "new"}
            isSaving={isUploadingFile || isSavingDocument}
            onClickSave={handleSaveDocument}
          />
        </Fade>
      </div>
      <Drawer
        variant="temporary"
        anchor="left"
        open={showTemplateList}
        onClose={() => {
          setShowTemplateList(false);
        }}
        PaperProps={{ className: classes.templateDrawer }}
      >
        <TemplateList
          isLoading={isLoadingTemplates}
          templates={availableTemplates}
          onClickTemplate={handleSelectTemplate}
        />
      </Drawer>
    </>
  );
};

type PublishHistoryProps = {
  publishHistory: Array<PublishHistory>;
  documentPath: string | null;
};

const PublishHistoryTable: React.FC<PublishHistoryProps> = (props) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const theme = useTheme();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);

  return (
    <Card className={classnames(sharedClasses.formularyCard, classes.formularyCard)}>
      <CardHeader title="History" />
      <Divider />
      <CardContent id="document-history" className={classes.historyTable}>
        {props.publishHistory.length === 0 ? (
          <Typography style={{ textAlign: "center" }}>No History to Display</Typography>
        ) : (
          <Table
            size={contentSpacing === "dense" ? "small" : "medium"}
            aria-label="Publish History Table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Publish Started at</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.publishHistory.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{format(new Date(item.publishStartedAt), "PPPpp (z)")}</TableCell>
                    <TableCell>
                      {item.status === DocumentRenderStatus.FAILED ||
                      item.status === DocumentRenderStatus.NOT_COPIED ? (
                        <ErrorIcon color="error" />
                      ) : (
                        <SuccessIcon />
                      )}
                    </TableCell>
                    <TableCell>
                      <PDFIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (props.documentPath) {
                            openURLInNewTab(
                              publishedDocumentPreviewPath(
                                window.location.hostname,
                                props.documentPath,
                                item.versionId
                              )
                            );
                          }
                        }}
                        htmlColor={theme.palette.primary.main}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </CardContent>
      <Divider />
    </Card>
  );
};

const documentPlaceholderName = (
  documentType: ApiDocumentTypes | string | undefined,
  existingDocumentTypes: { [key: string]: number } | undefined
) => {
  const uiDocumentType =
    documentType === undefined || documentType === "new"
      ? "New"
      : documentType === ApiDocumentTypes.Criteria
      ? "Criteria"
      : documentType === ApiDocumentTypes.FullFormulary
      ? "Full Formulary"
      : documentType === ApiDocumentTypes.WebSearch
      ? "Web Search"
      : documentType;

  if (documentType) {
    if (documentType === ApiDocumentTypes.WebSearch) {
      return uiDocumentType;
    } else if (documentType === "new") {
      return `${uiDocumentType} Document (${
        Object.values(existingDocumentTypes ?? {}).reduce((acc, cur) => acc + cur) + 1
      })`;
    } else if (existingDocumentTypes) {
      return `${uiDocumentType} Document (${existingDocumentTypes[documentType] + 1})`;
    } else {
      return `${uiDocumentType} Document`;
    }
  } else {
    return `New Document`;
  }
};
