import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  fade,
  Fade,
  IconButton,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogMessageIcon from "@material-ui/icons/NotesRounded";
import MessageNotificationIcon from "@material-ui/icons/TextsmsRounded";
import classNames from "classnames";
import * as React from "react";
import shallow from "zustand/shallow";
import { CharacterCounter } from "../../../../components/CharacterCounter";
import { ConfirmationDialog } from "../../../../components/dialogs/ConfirmationDialog";
import { TwoActionDialog } from "../../../../components/dialogs/TwoActionDialog";
import { DragAndDropPriorityList } from "../../../../components/lists/DragAndDropPriorityList";
import { CenteredCircularLoading } from "../../../../components/loading/CenteredCircularLoading";
import { LabelSwitch } from "../../../../components/switches/LabelSwitch";
import { OptionsTable } from "../../../../components/tables/OptionsTable";
import { UserContext } from "../../../../components/UserContext";
import { formatMoneyToUSD } from "../../../../utils/Numbers";
import {
  CUSTOM_PRICING_LIST_HELP_TEXT,
  CUSTOM_PRIORITY_HELP_TEXT,
  USE_ALL_DRUGS_FROM_RULES_HELP_TEXT,
} from "../../../helpText";
import { sharedStyles } from "../../../styles";
import {
  AlternativesListConfig,
  APIPricingList,
  DrugAttribute,
  GET_ALTERNATIVES_LIST_CONFIG,
  GET_PRICING_LISTS,
  GSNDrug,
  PrioritizedRule,
  SaveAlternativesListConfigInput,
  SaveAlternativesListConfigResponse,
  SAVE_ALTERNATIVES_LIST_CONFIG,
} from "../../api";
import { State, usePrioritizedDrugsStore } from "../AlternativesPriorityStore";
import { DrugDrawerPicker } from "./DrugDrawerPicker";

const useStyles = makeStyles((theme) => ({
  tabContent: {
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    marginTop: 30,
  },
  stickyCard: {
    position: "sticky",
    top: 40,
  },
  messageNotification: {
    position: "absolute",
    top: -10,
    left: -20,
    transform: "scale(-1,1)",
    color: theme.palette.primary.main,
    "&:hover": {
      background: "transparent",
      color: fade(theme.palette.primary.main, 0.8),
    },
  },
  popover: {
    padding: theme.spacing(2),
    minWidth: 200,
    maxWidth: 500,
    minHeight: 50,
  },
  popoverHeader: {
    marginBottom: theme.spacing(1),
  },
  generic: {
    fontStyle: "italic",
    textTransform: "lowercase",
  },
  brand: {
    textTransform: "uppercase",
  },
}));

interface Props {
  listId: string;
  isNew: boolean;
}

const selectState = (state: State) => ({ reset: state.reset });

export function AlternativesListPriority(props: Props) {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const {
    user: { drugSourceShort },
  } = React.useContext(UserContext);

  const { reset } = usePrioritizedDrugsStore(selectState, shallow);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [showDrugPicker, setShowDrugPicker] = React.useState(false);
  const [editMessageOpen, setEditMessageOpen] = React.useState(false);
  const [ruleBeingChanged, setRuleBeingChanged] = React.useState<PrioritizedRule>();
  const [priorityMessage, setPriorityMessage] = React.useState<string>();
  const [alternativeListConfig, setAlternativeListConfig] = React.useState<
    AlternativesListConfig | undefined
  >();
  const [togglePricingList, setTogglePricingList] = React.useState<boolean>(
    !!alternativeListConfig?.usingPricingListId
  );
  const [shouldShowWarning, setShouldShowWarning] = React.useState(false);
  //#region GQL Queries
  const { data: pricingLists, loading: isLoadingPricingList } = useQuery<{
    lists: { items: Array<APIPricingList> };
  }>(GET_PRICING_LISTS);

  const { loading: isLoadingAlternativesListConfig } = useQuery<
    { alternativesListConfig: AlternativesListConfig },
    { id: string }
  >(GET_ALTERNATIVES_LIST_CONFIG, {
    skip: props.isNew,
    fetchPolicy: "no-cache",
    variables: { id: props.listId },
    onCompleted: (res) => {
      if (!props.isNew) {
        console.log("loaded priorities");
        setAlternativeListConfig(res.alternativesListConfig);
        setTogglePricingList(!!res.alternativesListConfig?.usingPricingListId);
      }
    },
  });

  const [saveConfig, { loading: isSavingConfig }] = useMutation<
    SaveAlternativesListConfigResponse,
    SaveAlternativesListConfigInput
  >(SAVE_ALTERNATIVES_LIST_CONFIG, {
    onCompleted: (res) => {
      const basket = res.setAlternativesListConfig.prioritizedBasket;
      if (alternativeListConfig) {
        const priceAdjustedBasket = alternativeListConfig.prioritizedBasket.map(
          (prioritizedRule, index) => {
            if (basket[index]) {
              return { ...prioritizedRule, prices: basket[index].prices };
            } else {
              return { ...prioritizedRule, prices: null };
            }
          }
        );
        setAlternativeListConfig({
          ...alternativeListConfig,
          prioritizedBasket: priceAdjustedBasket,
        });
      }
    },
  });
  //#endregion
  const priorityProperties = [
    {
      label: "Use custom priority",
      helpText: CUSTOM_PRIORITY_HELP_TEXT,
      options: (
        <LabelSwitch
          switchProps={{
            checked: alternativeListConfig?.hasCustomPriority ?? false,
            onChange: (event) => {
              handleChangeCustomPrioritySwitch(event);
            },
          }}
        />
      ),
    },
    {
      label: "Use all drugs from rules",
      helpText: USE_ALL_DRUGS_FROM_RULES_HELP_TEXT,
      options: (
        <LabelSwitch
          switchProps={{
            checked: alternativeListConfig?.includeAllDrugsFromRules ?? false,
            onChange: handleChangeUseAllDrugsFromRulesSwitch,
          }}
          disabled={!(alternativeListConfig?.hasCustomPriority ?? false)}
        />
      ),
    },
    {
      label: "Use custom pricing list",
      helpText: CUSTOM_PRICING_LIST_HELP_TEXT,
      options: (
        <LabelSwitch
          switchProps={{
            checked: togglePricingList,
            onChange: () => {
              if (alternativeListConfig) {
                const shouldUsePricingList = !togglePricingList;
                setTogglePricingList(shouldUsePricingList);
                if (!shouldUsePricingList) {
                  const newListConfig: AlternativesListConfig = {
                    ...alternativeListConfig,
                    usingPricingListId: null,
                  };
                  setAlternativeListConfig(newListConfig);
                  handleSavePriority(newListConfig);
                }
              }
            },
          }}
        />
      ),
    },
    {
      label: "Pricing list",
      options: (
        <Select
          fullWidth
          disabled={!togglePricingList || isLoadingPricingList}
          value={alternativeListConfig?.usingPricingListId ?? ""}
          onChange={({ target: { value } }) => {
            if (alternativeListConfig && typeof value === "string") {
              const newConfig: AlternativesListConfig = {
                ...alternativeListConfig,
                usingPricingListId: value,
              };
              setAlternativeListConfig(newConfig);
              handleSavePriority(newConfig);
            }
          }}
        >
          {pricingLists?.lists.items.map((pricingList) => (
            <MenuItem key={pricingList.id} value={pricingList.id}>
              {pricingList.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  function handleSavePriority(config: AlternativesListConfig) {
    saveConfig({
      variables: {
        id: props.listId,
        input: {
          ...config,
          prioritizedBasket: config.prioritizedBasket.map(({ prices, ...rest }) => rest),
        },
      },
    });
  }

  function handleChangeCustomPrioritySwitch(event: any) {
    const { checked } = event.target;
    if (alternativeListConfig) {
      if (checked) {
        const newConfig: AlternativesListConfig = {
          ...alternativeListConfig,
          hasCustomPriority: checked,
        };
        setAlternativeListConfig(newConfig);
        handleSavePriority(newConfig);
      } else {
        if (alternativeListConfig.prioritizedBasket.length !== 0) {
          setShouldShowWarning(true);
        } else {
          const newConfig: AlternativesListConfig = {
            ...alternativeListConfig,
            hasCustomPriority: checked,
            includeAllDrugsFromRules: false,
          };
          setAlternativeListConfig(newConfig);
          handleSavePriority(newConfig);
        }
      }
    }
  }

  function handleChangeUseAllDrugsFromRulesSwitch(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = event.target;
    if (alternativeListConfig) {
      const newConfig: AlternativesListConfig = {
        ...alternativeListConfig,
        includeAllDrugsFromRules: alternativeListConfig.hasCustomPriority && (checked ?? false),
      };
      setAlternativeListConfig(newConfig);
      handleSavePriority(newConfig);
    }
  }

  function handlePriorityChange(priorityList: Array<PrioritizedRule>) {
    if (alternativeListConfig) {
      const newConfig: AlternativesListConfig = {
        ...alternativeListConfig,
        prioritizedBasket: priorityList.map((rule, index) => ({ ...rule, priority: index + 1 })),
      };
      setAlternativeListConfig(newConfig);
      handleSavePriority(newConfig);
    }
  }

  function handleAddDrugToPriority(drug: GSNDrug) {
    if (alternativeListConfig) {
      const newPriorityRule: PrioritizedRule = {
        conditions: [
          {
            drugAttribute: DrugAttribute.labelName,
            matchValue: drug.labelName,
          },
          drugSourceShort === "FDB"
            ? {
                drugAttribute: DrugAttribute.gcnSequenceNumber,
                matchValue: drug.fdbDetails.gcnSequenceNumber,
              }
            : {
                drugAttribute: DrugAttribute.gpi14,
                matchValue: drug.medispanDetails.gpi14,
              },
          drugSourceShort === "FDB"
            ? {
                drugAttribute: DrugAttribute.isBrand,
                matchValue: drug.type === "BRAND" ? "true" : "false",
              }
            : {
                drugAttribute: DrugAttribute.mony,
                matchValue: drug.medispanDetails.mony,
              },
        ],
        prices: null,
        priority: alternativeListConfig.prioritizedBasket.length + 1,
        priorityMessage: null,
      };
      const updatedConfig: AlternativesListConfig = {
        ...alternativeListConfig,
        prioritizedBasket: alternativeListConfig.prioritizedBasket.concat(newPriorityRule),
      };
      setAlternativeListConfig(updatedConfig);
      setShowDrugPicker(false);
      reset();
      handleSavePriority(updatedConfig);
    }
  }

  function handleSavePriorityMessage() {
    if (alternativeListConfig) {
      const { prioritizedBasket } = alternativeListConfig;
      const copyList = [...prioritizedBasket];
      const newRule: PrioritizedRule = {
        conditions: (ruleBeingChanged as PrioritizedRule).conditions,
        prices: (ruleBeingChanged as PrioritizedRule).prices,
        priority: (ruleBeingChanged as PrioritizedRule).priority,
        priorityMessage: priorityMessage as string,
      };
      const pos = prioritizedBasket.findIndex((item) => item === ruleBeingChanged);
      copyList.splice(pos, 1, newRule);
      setEditMessageOpen(false);
      setAlternativeListConfig({
        hasCustomPriority: alternativeListConfig.hasCustomPriority,
        includeAllDrugsFromRules: alternativeListConfig.includeAllDrugsFromRules,
        usingPricingListId: alternativeListConfig.usingPricingListId,
        prioritizedBasket: copyList,
      });
      handleSavePriority({
        hasCustomPriority: alternativeListConfig.hasCustomPriority,
        includeAllDrugsFromRules: alternativeListConfig.includeAllDrugsFromRules,
        usingPricingListId: alternativeListConfig.usingPricingListId,
        prioritizedBasket: copyList,
      });
    }
  }

  function handleMessageNotificationClick(message: string) {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setPriorityMessage(message);
    };
  }

  function handleAddEditMessageButtonClick(rule: PrioritizedRule) {
    setRuleBeingChanged(rule);
    if (rule.priorityMessage) {
      setPriorityMessage(rule.priorityMessage);
    } else {
      setPriorityMessage(undefined);
    }
    setEditMessageOpen(true);
  }

  const handleCloseDrawer = () => {
    setShowDrugPicker(false);
    reset();
  };

  function removeRule(rule: PrioritizedRule) {
    if (alternativeListConfig) {
      const updatedConfig: AlternativesListConfig = {
        ...alternativeListConfig,
        prioritizedBasket: alternativeListConfig.prioritizedBasket
          .filter((listItem) => listItem !== rule)
          .map((prioritizedItem, index) => ({ ...prioritizedItem, priority: index + 1 })),
      };
      setAlternativeListConfig(updatedConfig);
      handleSavePriority(updatedConfig);
      reset();
    }
  }

  function renderCardContent(item: PrioritizedRule, disabled: boolean) {
    return (
      <>
        {item.priorityMessage && (
          <IconButton
            onClick={handleMessageNotificationClick(item.priorityMessage)}
            className={classes.messageNotification}
            size="small"
            disableRipple
          >
            <MessageNotificationIcon fontSize="large" color={disabled ? "disabled" : "inherit"} />
          </IconButton>
        )}
        <CardContent>
          {drugSourceShort === "FDB" ? (
            <>
              <Typography
                color={disabled ? "textSecondary" : "primary"}
                variant="h6"
                className={
                  getConditionString(item, DrugAttribute.isBrand) === "true"
                    ? classes.brand
                    : classes.generic
                }
              >
                {getConditionString(item, DrugAttribute.labelName)}
              </Typography>

              <Typography>
                <Typography component="span" color="textSecondary" style={{ marginRight: 5 }}>
                  GSN:
                </Typography>
                {getConditionString(item, DrugAttribute.gcnSequenceNumber)}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                color={disabled ? "textSecondary" : "primary"}
                variant="h6"
                className={
                  getConditionString(item, DrugAttribute.mony) === "Y"
                    ? classes.generic
                    : classes.brand
                }
              >
                {getConditionString(item, DrugAttribute.labelName)}
              </Typography>

              <Typography color={disabled ? "textSecondary" : "primary"} variant="h6">
                <Typography component="span" color="textSecondary" style={{ marginRight: 5 }}>
                  GPI14:
                </Typography>
                {getConditionString(item, DrugAttribute.gpi14)}
              </Typography>
              <Typography>
                <Typography component="span" color="textSecondary" style={{ marginRight: 5 }}>
                  MONY:
                </Typography>
                {getConditionString(item, DrugAttribute.mony)}
              </Typography>
            </>
          )}

          {isSavingConfig ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                component="span"
                color="textSecondary"
                style={{ marginRight: 10, fontStyle: "italic" }}
              >
                Loading prices
              </Typography>
              <CircularProgress size={16} />
            </div>
          ) : item.prices && item.prices.length !== 0 ? (
            <Typography>
              <Typography component="span" color="textSecondary" style={{ marginRight: 5 }}>
                Prices:
              </Typography>
              {item.prices.map(formatMoneyToUSD).join(", ")}
            </Typography>
          ) : (
            <Typography component="span" color="textSecondary">
              Prices: N/A
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => {
              removeRule(item);
            }}
            disabled={disabled}
          >
            Remove
          </Button>

          <Button
            size="small"
            onClick={() => {
              handleAddEditMessageButtonClick(item);
            }}
            disabled={disabled}
          >
            {item.priorityMessage ? "EDIT MESSAGE" : "ADD MESSAGE"}
          </Button>
        </CardActions>
      </>
    );
  }

  return isLoadingAlternativesListConfig ? (
    <div style={{ height: "100%" }}>
      <CenteredCircularLoading />
    </div>
  ) : (
    <>
      <section className={classes.tabContent}>
        <div className={sharedClasses.fab} style={{ position: "fixed" }}>
          <Fade in={isSavingConfig}>
            <CircularProgress />
          </Fade>
        </div>
        <Card className={classNames(sharedClasses.formularyCard, classes.stickyCard)}>
          <CardHeader title="Priority settings" />
          <Divider />
          <CardContent>
            <OptionsTable settings={priorityProperties} />
          </CardContent>
        </Card>
        <DragAndDropPriorityList
          listData={alternativeListConfig?.prioritizedBasket ?? []}
          onListDataPriorityChange={handlePriorityChange}
          onAddListItem={() => {
            setShowDrugPicker(true);
          }}
          disabled={!Boolean(alternativeListConfig?.hasCustomPriority)}
          renderCardContent={renderCardContent}
        />
        <DrugDrawerPicker
          listId={props.listId}
          open={showDrugPicker}
          prioritizedBaskets={alternativeListConfig?.prioritizedBasket ?? []}
          onClose={handleCloseDrawer}
          onDrugClick={handleAddDrugToPriority}
        />
        <TwoActionDialog
          isOpen={editMessageOpen}
          primaryActionName="SAVE"
          primaryActionHandler={handleSavePriorityMessage}
          secondaryActionName="CANCEL"
          secondaryActionHandler={() => {
            setEditMessageOpen(false);
            setPriorityMessage(undefined);
          }}
          dialogIcon={<DialogMessageIcon />}
          dialogTitle="Add/Edit priority message"
          maxWidth="lg"
          disabled={(priorityMessage?.length || 0) > 200}
        >
          <TextField
            multiline
            variant="outlined"
            error={(priorityMessage?.length || 0) > 200}
            style={{ width: 600 }}
            rows={6}
            rowsMax={6}
            value={priorityMessage}
            onChange={(event) => {
              setPriorityMessage(event.target.value);
            }}
          />
          <CharacterCounter max={200} contentLength={priorityMessage?.length || 0} />
        </TwoActionDialog>
        <Popover
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            setPriorityMessage(undefined);
          }}
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          PaperProps={{
            className: classes.popover,
          }}
        >
          <Typography color="primary" variant="body2" className={classes.popoverHeader}>
            Priority Message
          </Typography>
          <Typography>{priorityMessage}</Typography>
        </Popover>
      </section>
      <ConfirmationDialog
        isOpen={shouldShowWarning}
        dialogContent={
          <Typography>Turn off custom priority and loose all prioritized drugs?</Typography>
        }
        onYesHandler={() => {
          if (alternativeListConfig) {
            const newConfig: AlternativesListConfig = {
              usingPricingListId: alternativeListConfig.usingPricingListId,
              hasCustomPriority: false,
              includeAllDrugsFromRules: false,
              prioritizedBasket: [],
            };
            setAlternativeListConfig(newConfig);
            handleSavePriority(newConfig);
            setShouldShowWarning(false);
          }
        }}
        onNoHandler={() => {
          setShouldShowWarning(false);
        }}
      />
    </>
  );
}

const getConditionString = (item: PrioritizedRule, matchKey: DrugAttribute) => {
  const foundCondition = item.conditions.find((condition) => condition.drugAttribute === matchKey);
  return foundCondition?.matchValue;
};
