import { Typography } from "@material-ui/core";
import * as React from "react";
import { PdfPreview } from "../../../components/pdfPreview/PdfPreview";
import { TEMPLATE_PRIMARY_COLOR } from "../../../styles/Theme";
import { useFormularyTemplatePreview } from "../api";
import { FullFormularyTemplate } from "../types";
import { FormularyTemplatesList } from "./FormularyTemplatesList";

export const FormularyTemplatesListPage: React.FC = () => {
  const { previewStatus, previewFormularyTemplate } = useFormularyTemplatePreview(
    <Typography variant="h5">
      Select a <span style={{ color: TEMPLATE_PRIMARY_COLOR }}>Template</span> on the left to see a
      preview of its layout.
    </Typography>
  );

  const handleSelectTemplate = (template: FullFormularyTemplate) => {
    previewFormularyTemplate(template.settings);
  };

  return (
    <>
      <FormularyTemplatesList onSelectTemplate={handleSelectTemplate} />
      <PdfPreview status={previewStatus} style={{ gridColumn: "5 / 17" }} />
    </>
  );
};
