import { useLazyQuery } from "@apollo/client";
import { Divider, IconButton, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import BackButton from "@material-ui/icons/ArrowBackRounded";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { HeaderSkeleton } from "../../../components/loading/Skeletons";
import { UserContext } from "../../../components/UserContext";
import { apiFormatDate } from "../../../utils/Date";
import { APIEditList, GetListInput, GetListResponse, GET_LIST } from "../api";
import { useDrugListRouteParams } from "../DrugListRouteUtils";
import { ListTypeRoute } from "./../DrugListRouteUtils";
import { EditListSetup } from "./Setup/EditListSetup";

enum EDIT_LIST_TABS {
  SETUP = "Setup",
  RULES = "Rules",
  VIEW = "View",
}

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  headerArea: {
    display: "flex",
    flexDirection: "row",
    height: "fit-content",
    margin: 20,
    alignItems: "center",
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
}));

export const ManageEditDrugList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { drugListId } = useDrugListRouteParams();
  const {
    user: { isManagingMedicare },
  } = React.useContext(UserContext);

  const [activeTab, setActiveTab] = React.useState<EDIT_LIST_TABS>(
    drugListId ? EDIT_LIST_TABS.VIEW : EDIT_LIST_TABS.SETUP
  );
  // const [viewDate, setViewDate] = React.useState<Date>(new Date());
  const [getList, { data, loading }] = useLazyQuery<GetListResponse, GetListInput>(GET_LIST);

  React.useEffect(() => {
    if (drugListId) {
      getList({
        variables: {
          id: drugListId as string,
          rulesInput: {
            asOfDate: apiFormatDate(new Date()), //TODO: Pass proper asOfDate
          },
          drugsInput: {
            asOfDate: apiFormatDate(new Date()), //TODO: Pass proper asOfDate
          },
        },
      });
    }
  }, [drugListId, getList]);

  const handleSaveList = (drugListId: string) => {
    history.push(`${ListTypeRoute.EDIT}/edit/${drugListId}`);
    setActiveTab(EDIT_LIST_TABS.RULES);
  };

  const TabPanel = (props: React.PropsWithChildren<{ value: EDIT_LIST_TABS }>) => {
    const { children, value, ...other } = props;
    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  const Header = () => {
    if (!drugListId) {
      return <Typography>{`New ${isManagingMedicare ? "Medicare" : ""} Edit List`}</Typography>;
    } else if (loading) {
      return (
        <div style={{ width: "100%" }}>
          <HeaderSkeleton headerVariant="h4" />
        </div>
      );
    } else if (data?.list?.name) {
      return (
        <Typography variant="h4" color="primary">
          {data.list.name}
        </Typography>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.fullWidthPageArea}>
      <div className={classes.headerArea}>
        <IconButton onClick={() => history.push(ListTypeRoute.EDIT)}>
          <BackButton />
        </IconButton>
        <Header />
      </div>
      <Divider variant="middle" />
      <Tabs
        centered
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, value) => setActiveTab(value)}
        aria-label="formulary navigation tabs"
      >
        {Object.values(EDIT_LIST_TABS).map((tabValue, index) => (
          <Tab
            key={index}
            label={<Typography color="inherit">{tabValue}</Typography>}
            value={tabValue}
            className={classes.tabText}
            disabled={tabValue !== EDIT_LIST_TABS.SETUP && !Boolean(data?.list)}
          />
        ))}
      </Tabs>
      <TabPanel value={EDIT_LIST_TABS.SETUP}>
        <EditListSetup drugList={data?.list as APIEditList} onSave={handleSaveList} />
      </TabPanel>
      {data?.list && (
        <TabPanel value={EDIT_LIST_TABS.RULES}>
          <Typography variant="h3">Soon.</Typography>
        </TabPanel>
      )}
      {data?.list && (
        <TabPanel value={EDIT_LIST_TABS.VIEW}>
          <Typography variant="h3">Soon.</Typography>
        </TabPanel>
      )}
    </div>
  );
};
