import * as React from "react";
import Downshift from "downshift";
import { Paper, MenuItem, TextField, InputBaseProps } from "@material-ui/core";

type Props = {
  value: string;
  onSelect: (value: string) => void;
  getCompletions: (search: string) => Promise<Array<string>>;
  inputBaseProps?: InputBaseProps;
};

//TODO this is almost generified
export const Autocomplete: React.FC<Props> = (props) => {
  const [autocompletes, setAutocompletes] = React.useState<Array<string>>([]);
  const [searchValue, setSearchValue] = React.useState(props.value);

  const handleInputValueChange = (value: string) => {
    setSearchValue(value);
    props.getCompletions(value).then(setAutocompletes);
  };

  React.useEffect(() => {
    setSearchValue(props.value);
  }, [props.value]);

  const handleSelect = (value: any) => {
    props.onSelect(value as string);
  };

  return (
    <Downshift
      onSelect={handleSelect}
      onInputValueChange={handleInputValueChange}
      inputValue={searchValue}
    >
      {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen }) => {
        return (
          <div>
            <TextField
              id="formulary-view-search-value"
              inputProps={getInputProps()}
              InputProps={props.inputBaseProps}
            />
            <div {...getMenuProps()}>
              {isOpen && (
                <Paper style={{ position: "fixed", zIndex: 1 }}>
                  {autocompletes.map((suggestion, index) => (
                    <MenuItem
                      {...getItemProps({ item: suggestion, index, key: index })}
                      key={index}
                      selected={highlightedIndex === index}
                      style={{ width: "489px" }}
                    >
                      {suggestion}
                    </MenuItem>
                  ))}
                </Paper>
              )}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
};
