import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import * as React from "react";
import { PreviewStatus } from "../../types/PreviewTypes";
import { DefaultMarginSettings } from "./constants";
import { APICriteriaTemplateInput, CriteriaTemplate } from "./types";

export const CREATE_CRITERIA_TEMPLATE = gql`
  mutation saveCriteriaTemplate($name: String, $settings: InputCriteriaTemplate!) {
    saveCriteriaTemplate(name: $name, settings: $settings) {
      id
    }
  }
`;
export type CreateCriteriaTemplateInput = {
  name: string;
  settings: APICriteriaTemplateInput;
};

export const GET_CRITERIA_TEMPLATE = gql`
  query GetCriteriaTemplate($templateId: ID!) {
    template(templateId: $templateId) {
      id
      name
      settings {
        ... on CriteriaTemplateSettings {
          criteriaTitleSize
          elementInfoSize
          elementNameSize
          font
          marginSettings {
            left
            right
            top
            bottom
            units
          }
        }
      }
    }
  }
`;
export type GetCriteriaTemplateInput = {
  templateId: string;
};
export type GetCriteriaTemplateResponse = {
  template: CriteriaTemplate;
};

export const UPDATE_CRITERIA_TEMPLATE = gql`
  mutation saveCriteriaTemplate($name: String, $settings: InputCriteriaTemplate!, $templateId: ID) {
    saveCriteriaTemplate(name: $name, settings: $settings, templateId: $templateId) {
      id
      name
      settings {
        ... on CriteriaTemplateSettings {
          criteriaTitleSize
          elementInfoSize
          elementNameSize
          font
          marginSettings {
            left
            right
            top
            bottom
            units
          }
        }
      }
    }
  }
`;
export type UpdateCriteriaTemplateInput = CreateCriteriaTemplateInput & {
  templateId: string;
};

export type SaveCriteriaTemplateResponse = {
  saveCriteriaTemplate: {
    id: string;
  };
};

export const PREVIEW_CRITERIA_TEMPLATE = gql`
  query PreviewCriteriaTemplate($criteriaType: CriteriaType!, $settings: InputCriteriaTemplate!) {
    templatePreview {
      criteria(criteriaType: $criteriaType, settings: $settings) {
        preview
      }
    }
  }
`;
export type PreviewCriteriaTemplateInput = {
  criteriaType: string;
  settings: APICriteriaTemplateInput;
};
export type PreviewCriteriaTemplateResponse = {
  templatePreview: {
    criteria: {
      preview: string; // base 64 string response
    };
  };
};

export const useCriteriaTemplatePreview = (initMessage: JSX.Element | string) => {
  const [newPreviewCriteriaTemplate, { data, loading }] = useLazyQuery<
    PreviewCriteriaTemplateResponse,
    PreviewCriteriaTemplateInput
  >(PREVIEW_CRITERIA_TEMPLATE, { fetchPolicy: "network-only" });

  const [previewStatus, setPreviewStatus] = React.useState<PreviewStatus>({
    type: "init",
    content: initMessage,
  });

  React.useEffect(() => {
    if (loading === true && previewStatus.type !== "loading") {
      setPreviewStatus({ type: "loading" });
    } else if (loading === false && previewStatus.type === "loading" && data?.templatePreview) {
      setPreviewStatus({ type: "preview", base64Content: data.templatePreview.criteria.preview });
    }
  }, [data, loading, previewStatus.type]);

  const previewCriteriaTemplate = (template: CriteriaTemplate) => {
    const { criteriaTitleSize, elementInfoSize, elementNameSize, font } = template.settings;
    newPreviewCriteriaTemplate({
      variables: {
        criteriaType: "PA",
        settings: {
          criteriaTitleSize,
          elementInfoSize,
          elementNameSize,
          font: font as any,
          footer: null,
          header: null,
          marginSettings: DefaultMarginSettings,
        },
      },
    });
  };

  return { previewCriteriaTemplate, previewStatus };
};
