import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import * as React from "react";

type Props = {
  headerVariant: "h1" | "h2" | "h3" | "h4";
  width?: number;
};

export const HeaderSkeleton: React.FunctionComponent<Props> = (props) => {
  return (
    <Skeleton variant="rect" animation="pulse" width={props.width ?? 300}>
      <Typography variant={props.headerVariant}>&nbsp;</Typography>
    </Skeleton>
  );
};
