import { useLazyQuery, useQuery } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  Fade,
  fade,
  FormControl,
  FormControlLabel,
  IconButton,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchRounded";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import produce from "immer";
import * as React from "react";
import { CenteredCircularLoading } from "../../../components/loading/CenteredCircularLoading";
import { LabelSwitch } from "../../../components/switches/LabelSwitch";
import { OptionsTable } from "../../../components/tables/OptionsTable";
import { OptionsTableSetting } from "../../../components/tables/OptionTableTypes";
import { UserContext } from "../../../components/UserContext";
import { sharedStyles } from "../../../Flex/styles";
import { Bool, toBoolean, toBooleanString } from "../../../utils/Boolean";
import { awsDateStringToDate } from "../../../utils/Date";
import {
  GetAccumulatorsResponse,
  GetFormularyTiersResponse,
  GetNetworkTiersResponse,
  GetPlanConfigResponse,
  GET_FORMULARY_TIERS,
  GET_NETWORK_TIERS,
  GET_PLAN_ACCUMULATORS,
  GET_PLAN_CONFIG,
  ListCoverageOptionsInput,
  ListCoverageOptionsResponse,
  ListLOBsResponse,
  ListPlanConfigsResponse,
  LIST_LOBS,
  LIST_PLAN_CONFIGS,
  LIST_PLAN_COVERAGE_OPTIONS,
} from "../../api";
import { CostShareTable } from "../../CostShare/CostShareTable";
import { Accumulator, APIPlan, PlanCoverageOption, PlanType, toPlanAccumulator } from "../../types";
import { AccumulatorCategoryTable } from "../Accumulators/AccumulatorTable";
import { PlanHierarchy } from "../Hierarchy/PlanHierarchy";

const useStyles = makeStyles((theme) => ({
  textFieldOptions: {
    width: "100%",
    maxWidth: "75%",
  },
  fab: {
    color: theme.palette.common.white,
  },
  marginRightSpacing: {
    marginRight: theme.spacing(),
  },
  primaryAvatar: {
    background: theme.palette.primary.main,
  },
  noTiers: {
    fontStyle: "italic",
  },
  technicalErrors: {
    maxHeight: 600,
    overflow: "auto",
  },
  technicalDetailsTable: {
    background: fade(theme.palette.common.black, 0.02),
  },
  errorButton: {
    color: theme.palette.error.main,
    width: "fit-content",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  tierAvatar: {
    width: "2em",
    height: "2em",
    background: "transparent",
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.text.primary}`,
    marginRight: theme.spacing(),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  datePickerToolbar: {
    display: "flex",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(-2),
    justifyContent: "center",
  },
  sectionContainer: {
    height: "50rem",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
  },
  searchBar: {
    margin: theme.spacing(2),
    padding: `0 ${theme.spacing(3)}px`,
    width: "50%",
    backgroundColor: "#ECECEC",
    alignSelf: "center",
  },
  tableContainer: {
    height: "100%",
    overflow: "auto",
    alignSelf: "center",
    width: "clamp(800px, 100%, 1300px)",
    backgroundColor: theme.palette.background.paper,
  },
  select: {
    minWidth: "167px",
    textAlign: "left",
  },
  costShareSectionContainer: {
    margin: theme.spacing(4),
  },
  hierarchySection: {
    height: "30rem",
  },
}));

interface Props {
  plan: APIPlan;
}

/**
 * Order of information on preview page:
 *  Plan settings -> Accumulator -> Cost Share -> Coverage Options
 *
 */

export function PlanView({ plan }: Props): JSX.Element {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const {
    user: {
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const [allCoverageOptions, setAllCoverageOptions] = React.useState<
    Map<string, PlanCoverageOption>
  >(new Map());
  const [searchText, setSearchText] = React.useState("");

  const { data: planConfigs, loading: loadingConfigs } = useQuery<ListPlanConfigsResponse>(
    LIST_PLAN_CONFIGS,
    {
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { data: lobs } = useQuery<ListLOBsResponse>(LIST_LOBS, {
    onError: (err) => {
      console.error(err);
    },
  });

  const { loading: isLoadingPlanConfig, data: planConfigRes } = useQuery<
    GetPlanConfigResponse,
    { id: string }
  >(GET_PLAN_CONFIG, {
    skip: plan.configId === null,
    variables: { id: plan.configId as string },
    onCompleted: (res) => {
      if (res) {
        if (res.planConfig.formularyId) {
          getFormularyTiers({ variables: { id: res.planConfig.formularyId } });
        }
        if (res.planConfig.networkId) {
          getNetworkTiers({ variables: { id: res.planConfig.networkId } });
        }
      }
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const { data: accumulatorRes } = useQuery<GetAccumulatorsResponse, { planId: string }>(
    GET_PLAN_ACCUMULATORS,
    {
      variables: {
        planId: plan.id,
      },
      skip: plan.id === undefined,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { loading: isLoadingCoverageOptions } = useQuery<
    ListCoverageOptionsResponse,
    ListCoverageOptionsInput
  >(LIST_PLAN_COVERAGE_OPTIONS, {
    variables: { id: plan.id },
    onCompleted: (res) => {
      const sortedResults = res.planCoverageOptions.sort(
        (a, b) => a.coverageOrder - b.coverageOrder
      );
      setAllCoverageOptions(
        produce(allCoverageOptions, (draft) => {
          sortedResults.forEach((coverageOptionResult) => {
            draft.set(coverageOptionResult.id, coverageOptionResult);
          });
        })
      );
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const [
    getFormularyTiers,
    { loading: loadingFormularyTiers, data: formularyTiersRes },
  ] = useLazyQuery<GetFormularyTiersResponse, { id: string }>(GET_FORMULARY_TIERS, {
    onError: (err) => {
      console.error(err);
    },
  });

  const [getNetworkTiers, { loading: loadingNetworkTiers, data: networkTiersRes }] = useLazyQuery<
    GetNetworkTiersResponse,
    { id: string }
  >(GET_NETWORK_TIERS, {
    onError: (err) => {
      console.error(err);
    },
  });

  const noConfig = plan.configId === null;
  const networkTiers = networkTiersRes?.network.tiers.items || [];
  const formularyTiers = formularyTiersRes?.formulary.tiers.items || [];

  const planConfigOption = [
    {
      label: "Plan configuration",
      options: (
        <Select
          required
          disabled
          fullWidth
          value={plan.configId || ""}
          className={classes.textFieldOptions}
        >
          {planConfigs?.planConfigs.items.length === 0 ? (
            <MenuItem key="noPlans" disabled>
              No plan configurations found.
            </MenuItem>
          ) : (
            planConfigs?.planConfigs.items.map((planConfig) => (
              <MenuItem key={planConfig.id} value={planConfig.id}>
                {planConfig.name}
              </MenuItem>
            ))
          )}
        </Select>
      ),
    },
  ];

  const propertiesOptions = [
    {
      label: "Plan Name",
      options: (
        <TextField required value={plan.name} className={classes.textFieldOptions} disabled>
          {plan.name}
        </TextField>
      ),
    },
    {
      label: "Plan type",
      options: (
        <Select
          fullWidth
          value={plan.planType || ""}
          className={classes.textFieldOptions}
          disabled
          style={{ textTransform: "capitalize" }}
        >
          {Object.keys(PlanType).map((planType) => (
            <MenuItem key={planType} value={planType}>
              {planType}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      label: "External ID",
      options: (
        <TextField value={plan.externalId || ""} className={classes.textFieldOptions} disabled>
          {plan.externalId}
        </TextField>
      ),
    },
    {
      label: "Effective date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            disabled
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={plan.effectiveDate ? awsDateStringToDate(plan.effectiveDate) : new Date()}
            onChange={emptyHandler}
            InputProps={{ readOnly: true }}
            className={classes.textFieldOptions}
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Termination date",
      options: (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            autoOk
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            format="MMM do, yyyy"
            value={plan.terminationDate ? awsDateStringToDate(plan.terminationDate) : null}
            onChange={emptyHandler}
            className={classes.textFieldOptions}
            disabled
          />
        </MuiPickersUtilsProvider>
      ),
    },
    {
      label: "Line of business",
      options: (
        <Select
          required
          fullWidth
          value={plan.lineOfBusiness?.id || ""}
          className={classes.textFieldOptions}
          disabled
        >
          {lobs?.linesOfBusiness?.items?.map((lob) => (
            <MenuItem key={lob.id} value={lob.id}>
              {lob.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      label: "RxBIN",
      options: (
        <TextField value={plan.rxBIN || ""} className={classes.textFieldOptions} disabled>
          {plan.rxBIN}
        </TextField>
      ),
    },
    {
      label: "RxPCN",
      options: (
        <TextField value={plan.rxPCN || ""} className={classes.textFieldOptions} disabled>
          {plan.rxPCN}
        </TextField>
      ),
    },
    {
      label: "RxGroup",
      options: (
        <TextField value={plan.rxGroup || ""} className={classes.textFieldOptions} disabled>
          {plan.rxGroup}
        </TextField>
      ),
    },
  ];

  function getNetworkTiersCardTitle() {
    if (planConfigRes?.planConfig.name) {
      return `Network tiers for ${planConfigRes.planConfig.name}`;
    } else {
      return `Network tiers`;
    }
  }

  const deductibleExtras = [
    {
      label: "Embed status",
      options: (
        <RadioGroup value={toBooleanString(accumulatorRes?.planAccumulator.deductible?.isEmbedded)}>
          <FormControlLabel
            disabled
            value="true"
            control={<Radio color="primary" />}
            label="Embedded"
          />
          <FormControlLabel
            disabled
            value="false"
            control={<Radio color="primary" />}
            label="Non-embedded"
          />
        </RadioGroup>
      ),
    },
    {
      label: "Accumulator type",
      options: (
        <RadioGroup
          value={toBooleanString(accumulatorRes?.planAccumulator.deductible?.isIntegrated)}
        >
          <FormControlLabel
            disabled
            value="true"
            control={<Radio color="primary" />}
            label="Medical and Rx"
          />
          <FormControlLabel
            disabled
            value="false"
            control={<Radio color="primary" />}
            label="Rx only"
          />
        </RadioGroup>
      ),
    },
    {
      label: "Excluded formulary tiers",
      options: (
        <Select
          disabled
          fullWidth
          multiple
          value={accumulatorRes?.planAccumulator.deductible?.excludedTiers ?? []}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} disabled label={value} className={classes.chip} />
              ))}
            </div>
          )}
          style={{ minWidth: 150, maxWidth: 225 }}
        >
          {formularyTiers.map((tier) => (
            <MenuItem disabled key={tier.id} value={tier.rank}>
              <Avatar className={classes.tierAvatar}>{tier.rank}</Avatar>
              {tier.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  const maxOOPExtras = [
    {
      label: "Embed status",
      options: (
        <RadioGroup value={toBooleanString(accumulatorRes?.planAccumulator.maxOop?.isEmbedded)}>
          <FormControlLabel
            disabled
            value="true"
            control={<Radio color="primary" />}
            label="Embedded"
          />
          <FormControlLabel
            disabled
            value="false"
            control={<Radio color="primary" />}
            label="Non-embedded"
          />
        </RadioGroup>
      ),
    },
    {
      label: "Accumulator type",
      options: (
        <RadioGroup value={toBooleanString(accumulatorRes?.planAccumulator.maxOop?.isIntegrated)}>
          <FormControlLabel
            disabled
            value="true"
            control={<Radio color="primary" />}
            label="Medical and Rx"
          />
          <FormControlLabel
            disabled
            value="false"
            control={<Radio color="primary" />}
            label="Rx only"
          />
        </RadioGroup>
      ),
    },
    {
      label: "Excluded formulary tiers",
      options: (
        <Select
          disabled
          fullWidth
          multiple
          value={accumulatorRes?.planAccumulator.maxOop?.excludedTiers ?? []}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} disabled label={value} className={classes.chip} />
              ))}
            </div>
          )}
          style={{ minWidth: 150, maxWidth: 225 }}
        >
          {formularyTiers.map((tier) => (
            <MenuItem disabled key={tier.id} value={tier.rank}>
              <Avatar className={classes.tierAvatar}>{tier.rank}</Avatar>
              {tier.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  function renderCoverageOptions(): Array<OptionsTableSetting> {
    const lowerCaseSearch = searchText.toLowerCase();
    const coverageOptions: Array<OptionsTableSetting> = [];
    for (let option of allCoverageOptions) {
      if (option[1].question.toLowerCase().includes(lowerCaseSearch)) {
        coverageOptions.push(toOptionTableSetting(option[1]));
      }
    }
    return coverageOptions;
  }

  function toOptionTableSetting(option: PlanCoverageOption): OptionsTableSetting {
    switch (option.type) {
      case "TEXT":
        return {
          label: option.question,
          options: <TextField disabled id={option.id} value={option.value} />,
        };
      case "INT":
        return {
          label: option.question,
          options: <TextField disabled id={option.id} type="number" value={option.value} />,
        };
      case "DECIMAL":
        return {
          label: option.question,
          options: <TextField disabled id={option.id} type="number" value={option.value} />,
        };
      case "ENUM":
        return {
          label: option.question,
          options: (
            <FormControl>
              <InputLabel id={`enum-label-${option.question}`} />
              <Select
                disabled
                id={`enum-field-${option.question}`}
                className={classes.select}
                labelId={`enum-label-${option.question}`}
                value={option.value}
              >
                {option.enumValues?.map((enumValue, index) => (
                  <MenuItem key={index} value={enumValue}>
                    {enumValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        };
      case "BOOL":
        return {
          label: option.question,
          options: (
            <LabelSwitch
              switchProps={{
                onChange: emptyHandler,
                checked: toBoolean(option.value as Bool),
                id: option.id,
              }}
              position="end"
              disabled
            />
          ),
        };
      default:
        return {
          label: option.question,
          options: <TextField disabled />,
        };
    }
  }

  function handleUpdateSearchText(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length >= 3) {
      setSearchText(event.target.value);
    } else if (event.target.value.length === 0) {
      setSearchText("");
    }
  }

  const formattedPlanAccumulator = accumulatorRes?.planAccumulator
    ? toPlanAccumulator(accumulatorRes.planAccumulator)
    : null;
  const deductibleTableInfo: Accumulator = {
    kind: "deductible",
    details: formattedPlanAccumulator?.deductible ?? null,
  };
  const maxOopTableInfo: Accumulator = {
    kind: "maxOOP",
    details: formattedPlanAccumulator?.maxOop ?? null,
  };

  return (
    <div>
      <section id="plan-setup-settings" className={sharedClasses.tabContent}>
        <div>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title="Configuration" />
            <Divider />
            <CardContent>
              {loadingConfigs ? (
                <CenteredCircularLoading />
              ) : (
                <OptionsTable settings={planConfigOption} noHover />
              )}
            </CardContent>
          </Card>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title="Properties" />
            <Divider />
            <CardContent>
              <OptionsTable settings={propertiesOptions} disabled />
            </CardContent>
          </Card>

          <Card className={sharedClasses.formularyCard}>
            <CardHeader title="Notes" />
            <Divider />
            <CardContent>
              <TextField
                multiline
                fullWidth
                disabled
                variant="outlined"
                value={plan.notes || ""}
                rows={6}
                rowsMax={6}
              />
            </CardContent>
          </Card>
        </div>
        <div>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader
              title={`Formulary tiers${
                planConfigRes?.planConfig.name ? ` for ${planConfigRes?.planConfig.name}` : ""
              }`}
            />
            <Divider />
            <CardContent>
              {isLoadingPlanConfig || loadingFormularyTiers ? (
                <CenteredCircularLoading />
              ) : (
                <List dense={contentSpacing === "dense"}>
                  {noConfig ? (
                    <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                      No plan configuration selected.
                    </Typography>
                  ) : formularyTiers.length === 0 ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No tiers found for this formulary." />
                    </ListItem>
                  ) : (
                    formularyTiers.map((tier) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar className={classes.primaryAvatar}>{`T${tier.rank}`}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={tier.name} />
                      </ListItem>
                    )) || []
                  )}
                </List>
              )}
            </CardContent>
          </Card>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader title={getNetworkTiersCardTitle()} />
            <Divider />
            <CardContent>
              {isLoadingPlanConfig || loadingNetworkTiers ? (
                <CenteredCircularLoading />
              ) : (
                <List dense={contentSpacing === "dense"}>
                  {noConfig ? (
                    <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                      No plan configuration selected.
                    </Typography>
                  ) : networkTiers.length === 0 ? (
                    <ListItem className={classes.noTiers}>
                      <ListItemText primary="No tiers found for this network." />
                    </ListItem>
                  ) : (
                    networkTiers.map((network) => (
                      <ListItem>
                        <ListItemText primary={network.name} />
                      </ListItem>
                    ))
                  )}
                </List>
              )}
            </CardContent>
          </Card>
        </div>
      </section>
      <Divider className={classes.divider} />
      <section className={classes.hierarchySection}>
        <PlanHierarchy cags={plan.hierarchies} onUpdate={() => {}} planId={plan.id} readonly />
      </section>
      <Divider className={classes.divider} />
      <section id="accumulators-preview">
        <div className={classes.datePickerToolbar}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled
              autoOk
              label="Accumulators annual reset date"
              variant="dialog"
              inputVariant="outlined"
              InputAdornmentProps={{ position: "end" }}
              format="MMMM do"
              value={new Date(accumulatorRes?.planAccumulator.annualResetDate || "").setFullYear(
                new Date().getFullYear()
              )}
              onChange={emptyHandler}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={sharedClasses.tabContent}>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader
              title="Deductible"
              action={
                <Switch
                  disabled
                  color="primary"
                  checked={accumulatorRes?.planAccumulator.hasDeductible}
                />
              }
            />
            <Divider />
            <CardContent>
              <Fade in={!accumulatorRes?.planAccumulator.hasDeductible} timeout={250}>
                <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                  Not applicable.
                </Typography>
              </Fade>
              <Collapse in={accumulatorRes?.planAccumulator.hasDeductible}>
                <AccumulatorCategoryTable
                  disabled
                  accumulator={deductibleTableInfo}
                  onUpdateAccumulator={emptyHandler}
                />
                <OptionsTable settings={deductibleExtras} />
              </Collapse>
            </CardContent>
          </Card>
          <Card className={sharedClasses.formularyCard}>
            <CardHeader
              title="Max out of pocket"
              action={
                <Switch
                  disabled
                  color="primary"
                  checked={accumulatorRes?.planAccumulator.hasMaxOutOfPocket}
                />
              }
            />
            <Divider />
            <CardContent>
              <Fade in={!accumulatorRes?.planAccumulator.hasMaxOutOfPocket} timeout={250}>
                <Typography color="textSecondary" style={{ fontStyle: "italic" }}>
                  Not applicable.
                </Typography>
              </Fade>
              <Collapse in={accumulatorRes?.planAccumulator.hasMaxOutOfPocket}>
                <AccumulatorCategoryTable
                  disabled
                  accumulator={maxOopTableInfo}
                  onUpdateAccumulator={emptyHandler}
                />
                <OptionsTable settings={maxOOPExtras} />
              </Collapse>
            </CardContent>
          </Card>
        </div>
      </section>
      <Divider className={classes.divider} />
      <section id="cost-share-preview">
        <div className={classes.costShareSectionContainer}>
          {planConfigRes?.planConfig.formularyId &&
            planConfigRes?.planConfig.networkId &&
            plan.configId && (
              <CostShareTable
                formularyId={planConfigRes.planConfig.formularyId}
                networkId={planConfigRes.planConfig.networkId}
                planConfigId={plan.configId}
                readonly
              />
            )}
        </div>
      </section>
      <Divider className={classes.divider} />
      <section id="coverage-options-preview">
        <div className={classes.sectionContainer}>
          <Paper elevation={0} className={classes.searchBar}>
            <InputBase
              placeholder="Find an option"
              fullWidth
              inputProps={{ "aria-label": "search term" }}
              onChange={handleUpdateSearchText}
              endAdornment={
                <IconButton disabled>
                  <SearchIcon />
                </IconButton>
              }
            />
          </Paper>
          {isLoadingCoverageOptions ? (
            <CenteredCircularLoading />
          ) : (
            <Paper className={classes.tableContainer}>
              <OptionsTable settings={renderCoverageOptions()} splitAlignment />
            </Paper>
          )}
        </div>
      </section>
    </div>
  );
}

function emptyHandler() {}
