import { useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  CreateFormularyTemplateInput,
  CREATE_FORMULARY_TEMPLATE,
  GetFormularyTemplateInput,
  GetFormularyTemplateResponse,
  GET_FORMULARY_TEMPLATE,
  SaveFormularyTemplateResponse,
} from "./api";
import { FORMULARY_TEMPLATE_LIST_PAGE } from "./constants";
import { FormularyTemplateContainer } from "./FormularyTemplatePageContainer";
import {
  FormularyTemplateStatus,
  fromApiFormularyTemplateSettings,
  FullFormularyTemplate,
  toApiFormularyTemplateSettings,
} from "./types";

export const CopyFormularyTemplatePage: React.FC = () => {
  const { templateId } = useParams<any>();
  const history = useHistory();
  const { data } = useQuery<GetFormularyTemplateResponse, GetFormularyTemplateInput>(
    GET_FORMULARY_TEMPLATE,
    { variables: { templateId: templateId as string } }
  );
  const [createTemplate, { loading: isSaving }] = useMutation<
    SaveFormularyTemplateResponse,
    CreateFormularyTemplateInput
  >(CREATE_FORMULARY_TEMPLATE);

  const [templateStatus, setTemplateStatus] = React.useState<FormularyTemplateStatus>({
    status: "loading",
  });

  React.useEffect(() => {
    if (templateStatus.status === "loading" && data) {
      setTemplateStatus({
        status: "available",
        template: {
          ...data.template,
          name: `${data.template.name} - Copy`,
          settings: fromApiFormularyTemplateSettings(data.template.settings),
        },
      });
    } else if (templateStatus.status === "available" && isSaving) {
      setTemplateStatus({ status: "saving", template: templateStatus.template });
    }
  }, [templateStatus, data, isSaving]);

  const saveTemplate = (template: FullFormularyTemplate) => {
    if (templateStatus.status === "available") {
      createTemplate({
        variables: {
          name: template.name,
          settings: toApiFormularyTemplateSettings(template.settings),
        },
      }).then(() => {
        history.push(FORMULARY_TEMPLATE_LIST_PAGE);
      });
    }
  };

  return <FormularyTemplateContainer templateStatus={templateStatus} saveTemplate={saveTemplate} />;
};
