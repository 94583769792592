import { useQuery } from "@apollo/client";
import { Divider, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { format } from "date-fns";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { CenteredCircularLoading } from "../components/loading/CenteredCircularLoading";
import { NamedPageHeader } from "../components/NamedPageHeader";
import { GetNetworkResponse, GET_NETWORK, NetworkPageMode } from "./api";
import { NetworkSetup } from "./NetworkSetup";
import { NetworkState, toNetworkState } from "./types";

const useStyles = makeStyles((theme) => ({
  fullWidthPageArea: {
    gridColumn: "span 16",
    display: "flex",
    maxHeight: "100vh",
    flexDirection: "column",
  },
  tabText: {
    textTransform: "capitalize",
    color: theme.palette.text.primary,
    transformOrigin: "left top",
  },
  tabPanel: {
    flex: "1 1 auto",
    height: "68vh",
    overflow: "auto",
  },
}));

interface Props {
  mode: NetworkPageMode;
}

enum NetworkTabs {
  //Only one for now, but might add more in future
  SETUP = "Setup",
}

const NEW_NETWORK: NetworkState = {
  name: "",
  code: "",
  region: "",
  lineOfBusiness: "",
  effectiveDate: format(new Date(), "yyyy-MM-dd"),
  terminationDate: null,
  externalId: "",
  tiers: [],
};

export function NetworkPage(props: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const { networkId } = useParams() as { networkId: string };

  const [network, setNetwork] = React.useState<NetworkState>(NEW_NETWORK);
  const [activeTab, setActiveTab] = React.useState<NetworkTabs>(NetworkTabs.SETUP);

  //#region Queries and Mutations
  const { loading } = useQuery<GetNetworkResponse, { id: string }>(GET_NETWORK, {
    skip: props.mode === NetworkPageMode.NEW,
    variables: {
      id: networkId,
    },
    onCompleted: (res) => {
      if (res) {
        setNetwork(toNetworkState(res.network));
      }
    },
    onError: (err) => {
      console.error(err);
    },
  });
  //#endregion

  function getNetworkName() {
    return props.mode !== NetworkPageMode.NEW ? network.name : "New Network";
  }

  function handleTabChange(_: React.ChangeEvent<{}>, newTab: NetworkTabs) {
    setActiveTab(newTab);
  }

  function handleUpdateNetwork(network: NetworkState) {
    if (props.mode === NetworkPageMode.NEW) {
      history.push(`/networks/edit/${network.id}`);
    } else {
      setNetwork(network);
    }
  }

  const TabPanel = (props: { children?: React.ReactNode; value: NetworkTabs }) => {
    const { children, value, ...other } = props;

    return (
      <div
        className={classes.tabPanel}
        role="tabpanel"
        hidden={value !== activeTab}
        id={`simple-tabpanel-${value}`}
        aria-labelledby={`simple-tab-${value}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  return (
    <div className={classes.fullWidthPageArea}>
      {loading ? (
        <CenteredCircularLoading />
      ) : (
        <>
          <NamedPageHeader name={getNetworkName()} backLink={`/networks`} />
          <Divider variant="middle" />
          <Tabs
            centered
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="formulary navigation tabs"
          >
            <Tab
              label={<Typography color="inherit">{NetworkTabs.SETUP}</Typography>}
              value={NetworkTabs.SETUP}
              className={classes.tabText}
            />
          </Tabs>
          <Divider />
          <TabPanel value={NetworkTabs.SETUP}>
            <NetworkSetup
              mode={props.mode}
              network={network}
              onUpdateNetwork={handleUpdateNetwork}
            />
          </TabPanel>
        </>
      )}
    </div>
  );
}
