import { Button, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { HPMSFileInfo } from "../../types";
import { FilesSidebar } from "./FilesSidebar";

const useStyles = makeStyles({
  stepWrapper: {
    display: "flex",
    height: "calc(100% - 40px)",
  },
  rightSideContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "75%",
  },
  instructions: {
    position: "absolute",
    top: 0,
    textAlign: "center",
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "355px",
  },
});

type Props = {
  formularyFiles: Array<HPMSFileInfo>;
  onStartNewConfig: () => void;
  onFinishConfig: () => void;
};

export const FinishedStep: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.stepWrapper}>
      <FilesSidebar formularyFiles={props.formularyFiles} />
      <div className={classes.rightSideContent}>
        <div className={classes.instructions}>
          <Typography align="center" style={{ marginBottom: 16 }}>
            This formulary has already been configured.
          </Typography>
          <Typography align="center">
            If you have changes to make, click the button below to configure this formulary again.
          </Typography>
        </div>
        <span className={classes.buttonRow}>
          <Button color="primary" variant="contained" onClick={props.onFinishConfig}>
            Go to Publishing
          </Button>
          <Button onClick={props.onStartNewConfig} variant="contained">
            Choose New Files
          </Button>
        </span>
      </div>
    </div>
  );
};
