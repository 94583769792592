import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";
import { Route, Switch } from "react-router";
import { NotFound } from "../components/screens/404";
import { UserContext } from "../components/UserContext";
import { getAppTheme } from "../styles/Theme";
import { AppSections } from "../types/AppTypes";
import { ListPlanConfigsPage } from "./PlanConfigs/PlanConfigsLandingPage";
import { PlanConfigPage } from "./PlanConfigs/PlanConfigPage";
import { PlanPage } from "./Plans/PlanPage";
import { PlansTablePage } from "./Plans/PlansLandingPage";
import { PlanConfigPageMode, PlanPageMode } from "./types";

export const BenefitsRoutes: React.FC = () => {
  const {
    user: {
      settings: { textSize },
    },
  } = React.useContext(UserContext);

  return (
    <ThemeProvider theme={getAppTheme(AppSections.BENEFITS, textSize)}>
      <Switch>
        <Route path="/benefits/plans" exact={true}>
          {/* <PlansLandingPage /> */}
          <PlansTablePage />
        </Route>
        <Route path="/benefits/plans/new" exact={true}>
          <PlanPage mode={PlanPageMode.NEW} />
        </Route>
        <Route path="/benefits/plans/edit/:planId" exact={true}>
          <PlanPage mode={PlanPageMode.EDIT} />
        </Route>
        <Route path="/benefits/plans/view/:planId" exact={true}>
          <PlanPage mode={PlanPageMode.VIEW} />
        </Route>

        <Route path="/benefits/plan-configs" exact={true}>
          <ListPlanConfigsPage />
        </Route>
        <Route path="/benefits/plan-configs/new" exact={true}>
          <PlanConfigPage mode={PlanConfigPageMode.NEW} />
        </Route>
        <Route path="/benefits/plan-configs/edit/:planConfigId" exact={true}>
          <PlanConfigPage mode={PlanConfigPageMode.EDIT} />
        </Route>
        <Route path="/benefits/plan-configs/view/:planConfigId" exact={true}>
          <PlanConfigPage mode={PlanConfigPageMode.VIEW} />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};
