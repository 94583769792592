import { TemplateStatus } from "../types";

export type WebSearchTemplate = {
  id: string;
  name: string;
  settings: {
    landingPageInfoText: string | null;
    primaryColor: string;
    logoPath: string | null;
    bannerPath: string | null;
  };
};

export const convertLandingPageText = (base64LandingPageText: string | null) => {
  if (base64LandingPageText) {
    try {
      return decodeURIComponent(atob(base64LandingPageText));
    } catch (e) {
      console.error(e);
      return "";
    }
  } else {
    return "";
  }
};

export type WebSearchTemplateStatus = TemplateStatus<WebSearchTemplate>;
