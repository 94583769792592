import { useMutation } from "@apollo/client";
import {
  Collapse,
  Divider,
  fade,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Paper,
  Popover,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@material-ui/core";
import PublishDateIcon from "@material-ui/icons/CalendarTodayRounded";
import CMSApprovalIcon from "@material-ui/icons/GavelRounded";
import AddIcon from "@material-ui/icons/NoteAddRounded";
import PreviewIcon from "@material-ui/icons/PlayArrowRounded";
import * as React from "react";
import { StandardDatePicker } from "../../../../components/Pickers/DatePickers";
import { UserContext } from "../../../../components/UserContext";
import { apiFormatDate } from "../../../../utils/Date";
import { UpdateFormularyCMSSettingsInput, UPDATE_FORMULARY_CMS_SETTINGS } from "../api";
import { FormularyEditingContext } from "../FormularyEditingContext";
import { ApiFormulary } from "../types";

const useStyles = makeStyles((theme) => ({
  toolbarRoot: {
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  tab: {
    minWidth: 60,
    color: theme.palette.text.primary,
    "&:hover": {
      color: fade(theme.palette.primary.main, 0.7),
    },
  },
  lightToolTip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  datePicker: {
    display: "flex",
    height: "5em",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
  },
  cmsStamps: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 20px",
  },
  cmsInputs: {
    width: "100%",
    maxWidth: "75%",
  },
}));

/**
 * todos
 *
 *  state duplication can be reduced?
 *    cms settings
 */

interface Props {
  formulary: ApiFormulary;
  publishDate: Date | null;
  isPublishing: boolean;
  earliestPublishDate: Date | null;
  onUpdatePublishDate: (newDate: Date | null) => void;
  onPublish: () => void;
  onClickNew: (type: string) => void;
  webSearchExists: boolean;
}

enum ToolbarActions {
  NewDocument,
  PublishDate,
  CMSStamps,
  Publish,
}

type CMSSettings = {
  cmsApprovedOn: Date | null;
  cmsContractId: string;
  cmsFormularyVersion: string;
  cmsFormularyId: string;
  cmsEffectiveDate: Date | null;
};

export const PublishToolbar: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    user: {
      isManagingMedicare,
      settings: { contentSpacing },
    },
  } = React.useContext(UserContext);
  const isEditing = React.useContext(FormularyEditingContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [tab, setTab] = React.useState<ToolbarActions | false>(false);
  const [cmsSettings, setCMSSettings] = React.useState<CMSSettings>({
    cmsApprovedOn: props.formulary.cmsApprovedOn,
    cmsContractId: props.formulary.cmsContractId || "",
    cmsFormularyVersion: props.formulary.cmsFormularyVersion || "",
    cmsFormularyId: props.formulary.cmsFormularyId || "",
    cmsEffectiveDate: props.formulary.cmsEffectiveDate,
  });

  const [updateFormularyCMSSettings] = useMutation<unknown, UpdateFormularyCMSSettingsInput>(
    UPDATE_FORMULARY_CMS_SETTINGS,
    {
      onError: (e) => {
        console.error("Error Updating CMS settings", e);
      },
    }
  );

  const TOOLBAR_TABS = [
    {
      icon: <AddIcon color="inherit" />,
      label: "New Document",
      value: ToolbarActions.NewDocument,
      disabled: !isEditing,
    },
    {
      icon: <PublishDateIcon color="inherit" />,
      label: "Formulary as of",
      value: ToolbarActions.PublishDate,
      disabled: !isEditing,
    },
    {
      icon: <CMSApprovalIcon color="inherit" />,
      label: "CMS",
      value: ToolbarActions.CMSStamps,
      disabled: !isEditing || isManagingMedicare === false,
    },
    {
      icon: <PreviewIcon color="inherit" />,
      label: "Publish",
      value: ToolbarActions.Publish,
      disabled: !isEditing || props.isPublishing,
    },
  ];

  const handleAction = (event: React.ChangeEvent<{}>, newTab: ToolbarActions) => {
    if (tab === newTab) {
      setTab(false);
    } else if (newTab === ToolbarActions.Publish && props.publishDate !== null) {
      props.onPublish();
    } else if (newTab === ToolbarActions.NewDocument) {
      setTab(newTab);
      setAnchorEl(event.currentTarget as HTMLButtonElement);
    } else {
      setTab(newTab);
    }
  };

  const disableIneligibleDays = (day: Date | null) => {
    return Boolean(
      day &&
        props.formulary.effectiveDate &&
        day.getTime() < props.formulary.effectiveDate.getTime()
    );
  };

  const saveCMSSettings = (newSettings: CMSSettings) => {
    updateFormularyCMSSettings({
      variables: {
        id: props.formulary.id,
        cmsApprovedOn: apiFormatDate(newSettings.cmsApprovedOn),
        cmsEffectiveDate: apiFormatDate(newSettings.cmsEffectiveDate),
        cmsContractId: newSettings.cmsContractId || null,
        cmsFormularyId: newSettings.cmsFormularyId || null,
        cmsFormularyVersion: newSettings.cmsFormularyVersion || null,
      },
    }).catch((e) => {
      console.error(e);
    });
  };
  const saveCurrentSettings = () => {
    saveCMSSettings(cmsSettings);
  };
  const updateCMSId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCMSSettings({
      ...cmsSettings,
      cmsContractId: event.currentTarget.value,
    });
  };
  const updateCMSVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCMSSettings({
      ...cmsSettings,
      cmsFormularyVersion: event.currentTarget.value,
    });
  };
  const updateCMSID = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCMSSettings({
      ...cmsSettings,
      cmsFormularyId: event.currentTarget.value,
    });
  };
  const updateCMSApprovalDate = (date: Date | null) => {
    const newSettings: CMSSettings = { ...cmsSettings, cmsApprovedOn: date };
    setCMSSettings(newSettings);
    saveCMSSettings(newSettings);
  };
  const updateCMSEffectiveDate = (date: Date | null) => {
    const newSettings: CMSSettings = { ...cmsSettings, cmsEffectiveDate: date };
    setCMSSettings(newSettings);
    saveCMSSettings(newSettings);
  };

  return (
    <>
      <Paper square elevation={0} className={classes.toolbarRoot}>
        <Tabs
          centered
          value={tab}
          onChange={handleAction}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ hidden: true }}
          disabled={props.earliestPublishDate === null}
        >
          {TOOLBAR_TABS.map((tab) => (
            <Tooltip title={tab.label} placement="top" classes={{ tooltip: classes.lightToolTip }}>
              <Tab
                id={tab.label}
                key={tab.label}
                icon={tab.icon}
                value={tab.value}
                disabled={tab.disabled}
                className={classes.tab}
              />
            </Tooltip>
          ))}
        </Tabs>
        <Collapse in={tab === ToolbarActions.PublishDate}>
          <div className={classes.datePicker}>
            <StandardDatePicker
              label="Formulary as of"
              value={props.publishDate}
              disabled={!isEditing}
              onChange={props.onUpdatePublishDate}
              disableDates={disableIneligibleDays}
              minDate={props.earliestPublishDate}
            />
          </div>
        </Collapse>
        <Collapse in={tab === ToolbarActions.CMSStamps}>
          <form noValidate autoComplete="off">
            <div className={classes.cmsStamps}>
              <StandardDatePicker
                label="CMS Approval Date"
                disabled={!isEditing}
                value={cmsSettings.cmsApprovedOn}
                onChange={updateCMSApprovalDate}
              />
              <div style={{ marginTop: "1em" }}>
                <StandardDatePicker
                  label="CMS Effective Date"
                  disabled={!isEditing}
                  value={cmsSettings.cmsEffectiveDate}
                  onChange={updateCMSEffectiveDate}
                />
              </div>

              <TextField
                label="CMS Contract ID"
                margin="dense"
                disabled={!isEditing}
                value={cmsSettings.cmsContractId}
                onChange={updateCMSId}
                className={classes.cmsInputs}
                onBlur={saveCurrentSettings}
              />
              <TextField
                label="CMS Formulary ID"
                margin="dense"
                disabled={!isEditing}
                value={cmsSettings.cmsFormularyId}
                onChange={updateCMSID}
                className={classes.cmsInputs}
                onBlur={saveCurrentSettings}
              />
              <TextField
                label="CMS Formulary Version"
                margin="dense"
                disabled={!isEditing}
                value={cmsSettings.cmsFormularyVersion}
                onChange={updateCMSVersion}
                className={classes.cmsInputs}
                onBlur={saveCurrentSettings}
              />
            </div>
          </form>
        </Collapse>
        <Divider />
      </Paper>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setTab(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List dense={contentSpacing === "dense"}>
          <ListSubheader>New...</ListSubheader>
          <ListItem
            button
            onClick={() => {
              setAnchorEl(null);
              setTab(false);
              props.onClickNew("document");
            }}
          >
            Document
          </ListItem>
          <ListItem
            button
            disabled={props.webSearchExists}
            onClick={() => {
              setAnchorEl(null);
              setTab(false);
              props.onClickNew("webSearch");
            }}
          >
            Web Search
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
