import { IServerSideGetRowsRequest } from "ag-grid-community";
import { DrugSearchCriteria, FDB, Medispan } from "../../types/DrugSearchTypes";
import { executeQuery, fromAwsDateStr } from "../../utils/GraphQL";
import { PageInfo } from "../../types/ApiTypes";
import { GridColumn, SortDirection, SortItem } from "../DrugLists/api";

export const requestToCriteria = (
  request: IServerSideGetRowsRequest,
  claimSystemId: string
): DrugSearchCriteria => {
  const sortConfig: SortItem[] = (request.sortModel as {
    colId: string;
    sort: "desc" | "asc";
  }[]).map((s) => ({
    field: s.colId,
    sortDirection: s.sort === "asc" ? SortDirection.ASC : SortDirection.DESC,
  }));

  const groupCols = request.rowGroupCols.map((col) => ({
    field: col.field as any,
    aggFunc: col.aggFunc,
  }));

  const valueCols: GridColumn[] = request.valueCols.map((col) => ({
    field: col.field!,
    aggFunc: col.aggFunc,
  }));

  return {
    claimSystemUuid: claimSystemId,
    offset: request.startRow,
    limit: request.endRow - request.startRow,
    filterModel: JSON.stringify(request.filterModel),
    sortConfig,
    groupCols,
    groupKeys: request.groupKeys,
    valueCols,
  };
};

type DrugLookupConnection = {
  items: Array<{
    productId: string;
    ndc: string;
    drugName: string;
    labelName: string;
    genericDescription: string;
    type: "BRAND" | "GENERIC";
    routeOfAdmin: string;
    rxcui: string;
    isOtc: boolean;
    fdbDetails?: {
      medId: string;
      gcn: string;
      hiclSequenceNumber: string;
      hic3: string;
      hic3Description: string;
      gcnSequenceNumber: string;
    };
    medispanDetails?: {
      mony: "M" | "O" | "N" | "Y";
      gpi14: string;
      drugDescriptorId: string;
    };
    lastUpdated: string; //Date
    onMarketDate: string; //Date
    offMarketDate?: string; //Date
  }>;
  pageInfo: PageInfo;
};

export const queryFdbDrugs = (
  criteria: DrugSearchCriteria
): Promise<{ drugs: FDB[]; lastRow: number }> => {
  return executeQuery<{ drugLookup: DrugLookupConnection }>(
    `
      query ($criteria: DrugSearchCriteria!, $paginationInput: PaginationInput) {
        drugLookup(criteria: $criteria, paginationInput: $paginationInput) {
          items {
            productId
            ndc
            drugName
            labelName
            genericDescription
            rxcui
            routeOfAdmin
            isOtc
            onMarketDate
            offMarketDate
            lastUpdated
            type
            fdbDetails {
              medId
              gcn
              hiclSequenceNumber
              hic3
              hic3Description
              gcnSequenceNumber
            }
          }
          pageInfo {
            endCursor
          }
        }
      }
    `,
    {
      criteria: {
        filterModel: criteria.filterModel,
        sortConfig: criteria.sortConfig,
        groupCols: criteria.groupCols,
        groupKeys: criteria.groupKeys,
        valueCols: criteria.valueCols,
      },
      paginationInput: {
        first: criteria.limit,
        after: criteria.offset?.toString(),
      },
    }
  ).then((response) => {
    if (response.errors) {
      console.error("FDB search errors: ", response.errors);
    }
    if (response.data) {
      const drugs: Array<FDB> = response.data.drugLookup.items.map((drugLookupDrug) => ({
        productUuid: drugLookupDrug.productId,
        labelName: drugLookupDrug.labelName,
        ndc: drugLookupDrug.ndc,
        medId: drugLookupDrug.fdbDetails!.medId,
        gcn: drugLookupDrug.fdbDetails?.gcn,
        gcnSequenceNumber: drugLookupDrug.fdbDetails?.gcnSequenceNumber,
        genericDescription: drugLookupDrug.genericDescription,
        routeOfAdmin: drugLookupDrug.routeOfAdmin,
        isOtc: drugLookupDrug.isOtc,
        rxcui: drugLookupDrug.rxcui,
        hic3: drugLookupDrug.fdbDetails?.hic3,
        hic3Description: drugLookupDrug.fdbDetails?.hic3Description,
        offMarketDate: fromAwsDateStr(drugLookupDrug.offMarketDate),
        onMarketDate: fromAwsDateStr(drugLookupDrug.onMarketDate),
        bgStatus: drugLookupDrug.type === "BRAND" ? "B" : "G",
      }));
      let totalRowCount = -1;
      if (response.data?.drugLookup?.pageInfo?.endCursor === "*") {
        totalRowCount = criteria.offset + drugs.length;
      }
      return {
        drugs,
        lastRow: totalRowCount,
      };
    } else {
      console.warn("no FDB search data");
      return {
        drugs: [],
        lastRow: 0,
      };
    }
  });
};

export const queryMspanDrugs = (
  criteria: DrugSearchCriteria
): Promise<{ drugs: Array<Medispan>; lastRow: number }> => {
  return executeQuery<{ drugLookup: DrugLookupConnection }>(
    `
      query ($criteria: DrugSearchCriteria!, $paginationInput: PaginationInput) {
        drugLookup(criteria: $criteria, paginationInput: $paginationInput) {
          items {
            productId
            ndc
            drugName
            labelName
            genericDescription
            rxcui
            routeOfAdmin
            isOtc
            onMarketDate
            offMarketDate
            lastUpdated
            type
            medispanDetails {
              drugDescriptorId
              mony
              gpi14
            }
          }
          pageInfo {
            endCursor
          }
        }
      }
    `,
    {
      criteria: {
        filterModel: criteria.filterModel,
        sortConfig: criteria.sortConfig,
        groupCols: criteria.groupCols,
        groupKeys: criteria.groupKeys,
        valueCols: criteria.valueCols,
      },
      paginationInput: {
        first: criteria.limit,
        after: criteria.offset.toString(),
      },
    }
  ).then((response) => {
    if (response.errors) {
      console.error("Medispan search errors: ", response.errors);
    }
    if (response.data) {
      const drugs: Array<Medispan> = response.data.drugLookup.items.map((drugLookupDrug) => ({
        productUuid: drugLookupDrug.productId,
        labelName: drugLookupDrug.labelName,
        ndc: drugLookupDrug.ndc,
        drugDescriptorId: parseInt(drugLookupDrug.medispanDetails!.drugDescriptorId),
        gpi14: drugLookupDrug.medispanDetails!.gpi14,
        mony: drugLookupDrug.medispanDetails!.mony,
        brandName: drugLookupDrug.drugName, //TODO remap?
        genericDescription: drugLookupDrug.genericDescription,
        routeOfAdmin: drugLookupDrug.routeOfAdmin,
        isOtc: drugLookupDrug.isOtc,
        rxcui: drugLookupDrug.rxcui,
        offMarketDate: fromAwsDateStr(drugLookupDrug.offMarketDate),
        onMarketDate: fromAwsDateStr(drugLookupDrug.onMarketDate),
        bgStatus: drugLookupDrug.type === "BRAND" ? "B" : "G",
      }));
      let totalRowCount = -1;
      if (response.data?.drugLookup?.pageInfo?.endCursor === "*") {
        totalRowCount = criteria.offset + drugs.length;
      }
      return {
        drugs,
        lastRow: totalRowCount,
      };
    } else {
      console.warn("no Medispan search data");
      return {
        drugs: [],
        lastRow: 0,
      };
    }
  });
};
