import Auth from "@aws-amplify/auth";
import { Bool, toBoolean } from "./Boolean";
import { asyncLocalStorage, CONTENT_SPACING, TEXT_SIZE } from "./localStorageUtils";

export type DrugSourceShort = "FDB" | "MSPAN";

type AuthenticatedUser = {
  accountName: string;
  email: string;
  subAccountId: string;
  subAccountName: string;
  drugSourceShort: DrugSourceShort;
  claimSystemId: string;
  drugSourceId: string;
  claimSystemSourceName: string;
  isManagingMedicare: Bool;
  userId: string;
  sub: string;
};

export type User = {
  accountName: string;
  subAccountId: string;
  userName: string;
  subAccountName: string;
  code: string;
  drugSourceId: string;
  drugSourceShort: DrugSourceShort;
  claimSystemId: string;
  claimSystemSourceName: string;
  isManagingMedicare: boolean;
  userId: string;
  userEmail: string;
  sub: string;
  settings: AppSettings;
};

export type AppSettings = {
  textSize: string | null;
  contentSpacing: string | null;
};

export const getUser = async (): Promise<User> => {
  const res = await Auth.currentAuthenticatedUser();

  const {
    accountName,
    email,
    subAccountId,
    subAccountName,
    drugSourceShort,
    claimSystemId,
    drugSourceId,
    claimSystemSourceName,
    isManagingMedicare,
    userId,
    sub,
  }: AuthenticatedUser = res.signInUserSession.idToken.payload;

  const textSize = await asyncLocalStorage.getItem(TEXT_SIZE);
  const contentSpacing = await asyncLocalStorage.getItem(CONTENT_SPACING);

  return {
    accountName,
    subAccountId,
    code: "",
    userName: email,
    userEmail: email,
    subAccountName,
    drugSourceId,
    drugSourceShort,
    claimSystemId,
    claimSystemSourceName,
    isManagingMedicare: toBoolean(isManagingMedicare),
    userId,
    sub,
    settings: {
      textSize,
      contentSpacing,
    },
  };
};
