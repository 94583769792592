import { useApolloClient } from "@apollo/client";
import {
  Button,
  Divider,
  fade,
  IconButton,
  LinearProgress,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import NotesIcon from "@material-ui/icons/DescriptionRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import PublishIcon from "@material-ui/icons/PublishRounded";
import DenseIcon from "@material-ui/icons/ReorderRounded";
import SaveIcon from "@material-ui/icons/SaveRounded";
import VisibilityIcon from "@material-ui/icons/VisibilityRounded";
import {
  AgGridEvent,
  ColDef,
  ColumnApi,
  GetContextMenuItemsParams,
  GridApi,
  ValueFormatterParams,
} from "ag-grid-community";
import { format } from "date-fns";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CopyIcon } from "../../components/Icons/customIcons";
import { PlanStyledAgGrid } from "../../components/tables/dataTables/PlanStyledAgGrid";
import { TableContainer } from "../../components/tables/TableContainer";
import { TableControlBar } from "../../components/tables/tableControlBar/TableControlBar";
import { useAgGridStoredTableState } from "../../components/tables/TableHooks";
import copyIcon from "../../styles/Graphics/content_copy-24px.svg";
import deleteIcon from "../../styles/Graphics/delete-24px.svg";
import editIcon from "../../styles/Graphics/edit-24px.svg";
import publishIcon from "../../styles/Graphics/publish-24px.svg";
import visibilityIcon from "../../styles/Graphics/visibility-24px.svg";
import { awsDateStringToDate, planTableFileDate, standardUIFormat } from "../../utils/Date";
import { GetPlansResponse, GET_PLANS } from "../api";
import { APIPlanItem, FlatPlan, flattenPlan, PlanAttributes, PlanStatus } from "../types";
import { ExportSampleButton } from "./ExportsSampleButton";

const useStyles = makeStyles((theme) => ({
  overlayPaper: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
  },
  controlBarButton: {
    margin: theme.spacing(),
    marginTop: theme.spacing() + 2, //offset from loading bar
    "&:hover": {
      background: fade(theme.palette.common.white, 0.2),
    },
  },
}));

interface Props {
  isLoading: boolean;
  onCopyPlan: (plan: FlatPlan, newPlanName: string, onSuccess: (plan: APIPlanItem) => void) => void;
  onDeletePlan: (
    planId: string,
    planName: string,
    onSuccess: (deletedPlanId: string) => void
  ) => void;
  onPublishPlan: (
    planId: string,
    planName: string,
    onSuccess: (planId: string, planStatus: string) => void
  ) => void;
  onShowPlanNotes: (notes: string, planName: string) => void;
}

export function PlansLandingPageTable(props: Props): JSX.Element {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const Apollo = useApolloClient();

  const { updateTable, saveTableState } = useAgGridStoredTableState();

  const gridApi = React.useRef<GridApi | null>(null);
  const gridColumnAPI = React.useRef<ColumnApi | null>(null);
  const rowHeight = React.useRef("dense");
  const loadedPlans = React.useRef<Array<APIPlanItem>>([]);

  const [isInitializing, setIsInitializing] = React.useState(true);

  // TODO - make sure to get all via pagination
  function getAllPlans() {
    return Apollo.query<GetPlansResponse>({
      query: GET_PLANS,
      fetchPolicy: "no-cache",
    });
  }

  function onGridReady(params: AgGridEvent) {
    gridApi.current = params.api;
    gridColumnAPI.current = params.columnApi;

    updateTable(pathname, params.columnApi).catch((e) => {
      console.error("error fetching table config", e);
    });

    getAllPlans().then((res) => {
      if (res.data) {
        params.api.setRowData(res.data.plans.items.flatMap(flattenPlan));
        const allColumns = params.columnApi.getAllColumns();
        if (allColumns) {
          params.columnApi.autoSizeColumns(allColumns.slice(0, -1));
        }
        loadedPlans.current = res.data.plans.items;
      } else {
        params.api.showNoRowsOverlay();
      }
      setIsInitializing(false);
    });
  }

  function handleSaveTable() {
    if (gridColumnAPI.current) {
      const columnState = gridColumnAPI.current.getColumnState();
      const columnGroupState = gridColumnAPI.current.getColumnGroupState();
      saveTableState(pathname, { columnGroupState, columnState }).catch((e) => {
        console.error("Error saving config table to storage", e);
      });
    }
  }

  const formatLabel = (params: ValueFormatterParams) => {
    if (params.value) {
      return params.value;
    } else {
      return "N/A";
    }
  };

  const formatDate = (params: ValueFormatterParams) => {
    if (params.value) {
      return standardUIFormat(awsDateStringToDate(params.value));
    } else {
      return "N/A";
    }
  };

  const formatDateTime = (params: ValueFormatterParams) => {
    if (params.value) {
      return format(new Date(params.value), "PPpp");
    } else {
      return "N/A";
    }
  };

  const formatIntegrated = (params: ValueFormatterParams) => {
    if (params.value) {
      if (params.value === "true") {
        return "Medical and Rx";
      } else {
        return "Rx only";
      }
    } else {
      return "N/A";
    }
  };

  const formatEmbedded = (params: ValueFormatterParams) => {
    if (params.value) {
      if (params.value === "true") {
        return "Embedded";
      } else {
        return "Non-embedded";
      }
    } else {
      return "N/A";
    }
  };

  const formatPrice = (params: ValueFormatterParams) => {
    if (params.value) {
      return "$" + params.value;
    } else {
      return "N/A";
    }
  };

  const width = 200;
  const COL_DEFS: Array<ColDef> = [
    {
      field: PlanAttributes.EXTERNAL_ID,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return `External ID: ${params.value}`;
        } else {
          return "N/A";
        }
      },
      rowGroup: true,
      hide: true,
    },
    {
      field: PlanAttributes.CARRIER_CODE,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.CARRIER_NAME,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.ACCOUNT_CODE,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.ACCOUNT_NAME,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.GROUP_CODE,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.GROUP_NAME,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.EFFECTIVE_DATE,
      filter: "agDateColumnFilter",
      valueFormatter: formatDate,
    },
    {
      field: PlanAttributes.TERMINATION_DATE,
      valueFormatter: formatDate,
    },
    {
      field: PlanAttributes.FORMULARY_NAME,
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.NETWORK_NAME,
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.NAME,
      headerName: "Plan Name",
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.PLAN_CONFIG_NAME,
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.CALENDAR_YEAR,
      filter: "agNumberColumnFilter",
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.LOB_NAME,
      headerName: "Line of Business",
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.DEDUCTIBLE_INTEGRATED,
      hide: false,
      filter: true,
      filterParams: {
        suppressMiniFilter: true,
        values: [true, false, null],
        valueFormatter: formatIntegrated,
      },
      valueFormatter: formatIntegrated,
    },
    {
      field: PlanAttributes.DEDUCTIBLE_EMBEDDED,
      hide: false,
      filter: true,
      filterParams: {
        suppressMiniFilter: true,
        values: [true, false, null],
        valueFormatter: formatEmbedded,
      },
      valueFormatter: formatEmbedded,
    },
    {
      field: PlanAttributes.DEDUCTIBLE_AMOUNT_INDIVIDUAL,
      hide: false,
      filter: "agNumberColumnFilter",
      valueFormatter: formatPrice,
    },
    {
      field: PlanAttributes.DEDUCTIBLE_AMOUNT_FAMILY,
      hide: false,
      filter: "agNumberColumnFilter",
      valueFormatter: formatPrice,
    },
    {
      field: PlanAttributes.DEDUCTIBLE_AMOUNT_INDIVIDUAL_PLUS_1,
      hide: false,
      filter: "agNumberColumnFilter",
      valueFormatter: formatPrice,
    },
    {
      field: PlanAttributes.DEDUCTIBLE_EXCLUDED_TIERS,
      hide: false,
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.MAX_OOP_INTEGRATED,
      hide: false,
      filter: true,
      filterParams: {
        suppressMiniFilter: true,
        values: [true, false, null],
        valueFormatter: formatIntegrated,
      },
      valueFormatter: formatIntegrated,
    },
    {
      field: PlanAttributes.MAX_OOP_EMBEDDED,
      hide: false,
      filter: true,
      filterParams: {
        suppressMiniFilter: true,
        values: [true, false, null],
        valueFormatter: formatEmbedded,
      },
      valueFormatter: formatEmbedded,
    },
    {
      field: PlanAttributes.MAX_OOP_AMOUNT_INDIVIDUAL,
      hide: false,
      filter: "agNumberColumnFilter",
      valueFormatter: formatPrice,
    },
    {
      field: PlanAttributes.MAX_OOP_AMOUNT_FAMILY,
      hide: false,
      filter: "agNumberColumnFilter",
      valueFormatter: formatPrice,
    },
    {
      field: PlanAttributes.MAX_OOP_AMOUNT_INDIVIDUAL_PLUS_1,
      hide: false,
      filter: "agNumberColumnFilter",
      valueFormatter: formatPrice,
    },
    {
      field: PlanAttributes.MAX_OOP_EXCLUDED_TIERS,
      hide: false,
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.STATUS,
      hide: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (!!params.value) {
          if (params.value === PlanStatus.Published) {
            return "Published";
          }
          return "Not published";
        }
        return " ";
      },
    },
    {
      field: PlanAttributes.CREATED_BY,
      hide: false,
      enableRowGroup: false,
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.CREATED_ON,
      valueFormatter: formatDateTime,
      hide: false,
    },
    {
      field: PlanAttributes.UPDATED_BY,
      hide: false,
      enableRowGroup: false,
      valueFormatter: formatLabel,
    },
    {
      field: PlanAttributes.UPDATED_ON,
      valueFormatter: formatDateTime,
      hide: false,
    },
    {
      field: PlanAttributes.NOTES,
      width: 120,
      cellRenderer: "notesButton",
    },
    {
      field: PlanAttributes.GROUP_EFFECTIVE_DATE,
      valueFormatter: formatDate,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.GROUP_TERMINATION_DATE,
      valueFormatter: formatDate,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.GROUP_CONTACT_NAME,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: PlanAttributes.GROUP_CONTACT_NUMBER,
      valueFormatter: formatLabel,
      resizable: true,
      width,
    },
    {
      field: "Actions",
      pinned: "right",
      width: 150,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      // suppressAutoSize: true,
      // suppressSizeToFit: true,
      cellRenderer: "actionsCell",
    },
  ];

  function changeRowHeight() {
    if (rowHeight.current === "dense") {
      return 36;
    } else {
      return 48;
    }
  }

  function handleCopyPlan(plan: FlatPlan, gridApi?: GridApi | null) {
    let newName = `${plan.name} - Copy`;
    if (gridApi) {
      let dupCount = 0;
      gridApi.forEachNode(({ data }) => {
        if (data.name.includes(newName)) {
          ++dupCount;
        }
      });
      if (dupCount > 0) {
        newName = `${newName} (${dupCount})`;
      }
    }
    props.onCopyPlan(plan, newName, handleSuccessfulCopy);
  }

  function getContextMenuItems(params: GetContextMenuItemsParams) {
    if (params.node) {
      if (true) {
        return [
          {
            name: "View",
            action: () => {
              history.push(`/benefits/plans/view/${params.node.data.id}`);
            },
            icon: `<img src="${visibilityIcon}" height="18px" alt="viewIcon" />`,
          },
          {
            name: "Edit",
            action: () => {
              history.push(`/benefits/plans/edit/${params.node.data.id}`);
            },
            icon: `<img src="${editIcon}" height="18px" alt="editIcon" />`,
          },
          {
            name: "Duplicate",
            action: () => {
              handleCopyPlan(params.node.data, params.api);
            },
            icon: `<img src="${copyIcon}" height="18px" alt="deleteIcon" />`,
          },
          {
            name: "Delete",
            action: () => {
              props.onDeletePlan(
                params.node.data.id,
                params.node.data.name,
                handleSuccessfulDelete
              );
            },
            icon: `<img src="${deleteIcon}" height="18px" alt="deleteIcon" />`,
          },
          {
            name: "Publish",
            disabled: params.node?.data?.status === PlanStatus.Published,
            action: () => {
              params.api?.showLoadingOverlay();
              props.onPublishPlan(
                params.node.data.id,
                loadedPlans.current.find((plan) => plan.id === params.node.data.id)?.name ?? "",
                handleSuccessfulPublish
              );
            },
            icon: `<img src="${publishIcon}" height="18px" alt="publishIcon" />`,
          },
          {
            name: "Export",
            subMenu: [
              {
                name: "CSV Export",
                action: () => {
                  gridApi.current?.exportDataAsCsv({
                    fileName: `plan_export_${planTableFileDate(new Date())}`,
                  });
                },
              },
              {
                name: "Excel Export (.xlsx)",
                action: () => {
                  gridApi.current?.exportDataAsExcel({
                    fileName: `plan_export_${planTableFileDate(new Date())}`,
                  });
                },
              },
              {
                name: "Excel Export (.xml)",
                action: () => {
                  gridApi.current?.exportDataAsExcel({
                    fileName: `plan_export_${planTableFileDate(new Date())}`,
                    exportMode: "xml",
                  });
                },
              },
            ],
          },
        ];
      } else {
        return [
          {
            name: "View",
            action: () => {
              history.push(`/benefits/plans/view/${params.node.data.id}`);
            },
            icon: `<img src="${visibilityIcon}" height="18px" alt="viewIcon" />`,
          },
          "export",
        ];
      }
    } else {
      return ["export"];
    }
  }

  function handleSuccessfulDelete(deletedPlanId: string) {
    const nodeToDelete = gridApi.current?.getRowNode(deletedPlanId);
    gridApi.current?.applyTransaction({ remove: [nodeToDelete] });
    gridApi.current?.hideOverlay();
  }

  function handleSuccessfulPublish(planId: string, planStatus: string) {
    gridApi.current?.forEachNode((rowNode) => {
      if (rowNode.data.id === planId) {
        const rowData = rowNode.data;
        rowData.status = planStatus;
        rowNode.setData(rowData);
      }
    });
    gridApi.current?.hideOverlay();
  }

  function handleSuccessfulCopy(plan: APIPlanItem) {
    const newFlatPlan = flattenPlan(plan);
    gridApi.current?.applyTransaction({ add: [newFlatPlan] });
    gridApi.current?.hideOverlay();
  }

  function simpleCellRender(params: any) {
    if (params.valueFormatted) {
      return `<p>${params.valueFormatted}</p>`;
    } else {
      return `<p>${params.value}</p>`;
    }
  }

  function getNotesButton(params: any) {
    if (!!params.data?.notes) {
      return (
        <IconButton
          color="inherit"
          size="small"
          onClick={() => {
            props.onShowPlanNotes(params.data.notes, params.data.name);
          }}
        >
          <NotesIcon fontSize="small" color="inherit" />
        </IconButton>
      );
    } else if (params.data === null) {
      return "N/A";
    }
    return " ";
  }

  function getActionsCell(params: any) {
    //TODO: Width may need to be adjusted if more actions are added

    if (!params.data) {
      return null;
    }
    return (
      <>
        <Tooltip title="View">
          <IconButton
            color="inherit"
            size="small"
            component="a"
            disabled={!params.data}
            href={`/benefits/plans/view/${params.data?.id}`}
          >
            <VisibilityIcon fontSize="small" color="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            color="inherit"
            size="small"
            component="a"
            disabled={!params.data}
            href={`/benefits/plans/edit/${params.data?.id}`}
          >
            <EditIcon fontSize="small" color="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Duplicate">
          <IconButton
            color="inherit"
            size="small"
            disabled={!params.data}
            onClick={() => {
              handleCopyPlan(params.data);
            }}
          >
            <CopyIcon fontSize="small" color="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            color="inherit"
            size="small"
            disabled={!params.data}
            onClick={() => {
              props.onDeletePlan(params.data.id, params.data.name, handleSuccessfulDelete);
            }}
          >
            <DeleteIcon fontSize="small" color="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Publish">
          <IconButton
            color="inherit"
            size="small"
            disabled={!params.data || params.data.status === PlanStatus.Published}
            onClick={() => {
              props.onPublishPlan(params.data.id, params.data.name, handleSuccessfulPublish);
            }}
          >
            <PublishIcon fontSize="small" color="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  function goToNewPlan() {
    history.push("/benefits/plans/new");
  }

  function handleTableSizeSwitch() {
    rowHeight.current = rowHeight.current === "normal" ? "dense" : "normal";
    gridApi.current?.resetRowHeights();
  }

  return (
    <TableContainer>
      <TableControlBar>
        <div>
          <Button
            className={classes.controlBarButton}
            color="inherit"
            onClick={goToNewPlan}
            startIcon={<AddIcon />}
          >
            Create New Plan
          </Button>
        </div>
        <div>
          <Button
            className={classes.controlBarButton}
            color="inherit"
            onClick={handleTableSizeSwitch}
            startIcon={<DenseIcon />}
          >
            ROW SPACING
          </Button>
          <Button
            className={classes.controlBarButton}
            color="inherit"
            onClick={handleSaveTable}
            startIcon={<SaveIcon />}
          >
            Save Table
          </Button>
          <ExportSampleButton />
        </div>
      </TableControlBar>
      {isInitializing || props.isLoading ? (
        <LinearProgress color="primary" style={{ height: 2 }} />
      ) : (
        <Divider color="primary" className={classes.divider} />
      )}

      <PlanStyledAgGrid
        animateRows
        groupUseEntireRow
        defaultColDef={{
          cellRenderer: "defaultCellRenderer",
          resizable: true,
          filter: true,
        }}
        columnDefs={COL_DEFS}
        getRowHeight={changeRowHeight}
        getRowNodeId={(data) =>
          `${data.id}|${data.carrierCode}|${data.accountCode}|${data.groupCode}`
        }
        onGridReady={onGridReady}
        getContextMenuItems={getContextMenuItems}
        components={{
          defaultCellRenderer: simpleCellRender,
        }}
        frameworkComponents={{
          notesButton: getNotesButton,
          actionsCell: getActionsCell,
        }}
      />
    </TableContainer>
  );
}
