import { FormControl, FormHelperText, makeStyles, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { maxLengthError, REQUIRED_ERROR } from "../../errorHelpers";
import {
  DESCRIPTION_COLUMN,
  EDITS_COLUMN,
  INDEX_SETTINGS,
  LEGEND_SETTINGS,
  TEMPLATE_NAME,
  TIERS_COLUMN,
} from "../../FieldNameConstant";
import { IsViewingTemplateContext } from "../../isEditingTemplateContext";
import { TemplateToolbar } from "../../TemplateToolbar";
import { FullFormularyTemplate } from "../types";
import { EditTemplateFormExpansionPanel } from "./EditTemplateFormExpansionPanel";
import { FormularyTemplateTableColumnsSection } from "./FormularyTemplateTableColumnsSection";
import { IndexSettings } from "./IndexSettings";
import { LegendSettings } from "./LegendSettings";
import { PageSettings } from "./PageSettings";
import { TableSettings } from "./TableSettings";
import { TemplateSection } from "./types";

const useStyles = makeStyles({
  editSectionRoot: {
    gridColumn: "span 8",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  followingContent: {
    order: 1,
    height: "calc(100vh - 72px)",
    overflow: "auto",
    margin: 0,
    padding: 20,
    zIndex: 0,
    "scrollbar-width": "none", // Hide Scrollbar - Firefox
    "&::-webkit-scrollbar": {
      // Hide Scrollbar - Chrome & Safari
      display: "none",
    },
  },
  templateName: {
    maxWidth: "100%",
    marginBottom: 20,
    display: "inline-flex",
    alignItems: "flex-start",
  },
  templateNameWithEditButton: {
    maxWidth: "100%",
    marginBottom: 20,
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

enum FormularyTemplateSettings {
  PAGE = "Page Settings",
  LEGEND = "Legend Settings",
  TABLE = "Table Settings",
  TABLE_COLUMN = "Table Column Settings",
  INDEX = "Index Settings",
}

type Props = {
  isSaving: boolean;
  onChangeSection: (section: TemplateSection) => void;
  onPreview: () => void;
  onBackButtonClick: () => void;
  onClosePreview: () => void;
  saveTemplate: () => void;
};

export const FormularyTemplateForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { errors, getValues } = useFormContext<FullFormularyTemplate>();
  const isViewOnly = React.useContext(IsViewingTemplateContext);

  const [expandedPanel, setExpandedPanel] = React.useState("");

  const handleExpandPanel = (panel: string, panelType: TemplateSection) => (
    _: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpandedPanel(isExpanded ? panel : "");
    props.onChangeSection(isExpanded ? panelType : null);
  };

  const getFieldError = (whichField: string, errorType: string): JSX.Element | "" => {
    if ((errors as any)[whichField]) {
      const { type, message } = (errors as any)[whichField];
      return <>{type === errorType && <FormHelperText>{message}</FormHelperText>}</>;
    }
    return "";
  };

  return (
    <>
      <div className={classes.editSectionRoot}>
        <TemplateToolbar
          isSaving={props.isSaving}
          isValid={Object.entries(errors).length === 0}
          isViewOnly={isViewOnly}
          onBack={props.onBackButtonClick}
          onSave={props.saveTemplate}
          onPreview={props.onPreview}
          onPreviewClose={props.onClosePreview}
        />

        <div className={classes.followingContent}>
          <FormControl fullWidth className={classes.templateName} error={Boolean(errors.name)}>
            <Controller
              name={TEMPLATE_NAME}
              as={
                <TextField
                  fullWidth
                  disabled={isViewOnly}
                  label={<Typography>Template Name</Typography>}
                  inputProps={{
                    "aria-label": "template name",
                    style: { textOverflow: "ellipsis" },
                  }}
                />
              }
              rules={{
                required: REQUIRED_ERROR,
                maxLength: {
                  value: 200,
                  message: maxLengthError(200),
                },
              }}
            />
            {getFieldError(TEMPLATE_NAME, "required")}
            {getFieldError(TEMPLATE_NAME, "maxLength")}
          </FormControl>
          <EditTemplateFormExpansionPanel
            title={FormularyTemplateSettings.PAGE}
            isExpanded={expandedPanel === FormularyTemplateSettings.PAGE}
            onExpand={handleExpandPanel(FormularyTemplateSettings.PAGE, "Page")}
            isContainsError={Boolean(errors.settings?.marginSettings)}
          >
            <PageSettings />
          </EditTemplateFormExpansionPanel>
          <EditTemplateFormExpansionPanel
            title={FormularyTemplateSettings.LEGEND}
            isExpanded={expandedPanel === FormularyTemplateSettings.LEGEND}
            onExpand={handleExpandPanel(FormularyTemplateSettings.LEGEND, "Legend")}
          >
            <LegendSettings
              showEdits={getValues(LEGEND_SETTINGS.EDITS)}
              showTiers={getValues(LEGEND_SETTINGS.TIERS)}
            />
          </EditTemplateFormExpansionPanel>
          <EditTemplateFormExpansionPanel
            title={FormularyTemplateSettings.TABLE}
            isExpanded={expandedPanel === FormularyTemplateSettings.TABLE}
            onExpand={handleExpandPanel(FormularyTemplateSettings.TABLE, "Table")}
          >
            <TableSettings />
          </EditTemplateFormExpansionPanel>

          <EditTemplateFormExpansionPanel
            title={FormularyTemplateSettings.TABLE_COLUMN}
            isExpanded={expandedPanel === FormularyTemplateSettings.TABLE_COLUMN}
            onExpand={handleExpandPanel(FormularyTemplateSettings.TABLE_COLUMN, "Table")}
            isContainsError={
              Boolean(errors.settings?.descriptionColumnSettings) ||
              Boolean(errors.settings?.editsColumnSettings) ||
              Boolean(errors.settings?.tierColumnSettings)
            }
          >
            <FormularyTemplateTableColumnsSection
              descriptionColumnPosition={getValues(DESCRIPTION_COLUMN.POSITION)}
              editsColumnPosition={getValues(EDITS_COLUMN.POSITION)}
              tierColumnPosition={getValues(TIERS_COLUMN.POSITION)}
            />
          </EditTemplateFormExpansionPanel>
          <EditTemplateFormExpansionPanel
            title={FormularyTemplateSettings.INDEX}
            isExpanded={expandedPanel === FormularyTemplateSettings.INDEX}
            onExpand={handleExpandPanel(FormularyTemplateSettings.INDEX, "Table")}
          >
            <IndexSettings
              titleAlignment={getValues(INDEX_SETTINGS.TITLE_ALIGNMENT)}
              showIndex={getValues(INDEX_SETTINGS.SHOW_INDEX)}
            />
          </EditTemplateFormExpansionPanel>
        </div>
      </div>
    </>
  );
};
