import { Theme, makeStyles } from "@material-ui/core";

export const useDialogStyles = makeStyles((theme: Theme) => ({
  secondaryColorLabel: {
    color: theme.palette.primary.main + " !important",
  },
  secondaryColorUnderline: {
    "&:hover:before": {
      borderBottomColor: theme.palette.primary.main + " !important",
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main + " !important",
    },
  },
  tabbedDialogBox: {
    width: 600,
  },
  tabbedDialogContentArea: {
    marginTop: "3rem",
  },
  dialogBox: {
    position: "relative",
    overflow: "visible",
    minWidth: 400,
  },
  dialogTitleArea: {
    padding: "0 1rem 0 0",
  },
  dialogTitleIconArea: {
    color: "white !important",
    backgroundColor: theme.palette.primary.main,
    padding: "1rem",
    display: "flex",
    position: "absolute",
    top: "-1rem",
    left: "1rem",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.2)",
  },
  dialogTitleIconAreaWithError: {
    color: "white !important",
    backgroundColor: theme.palette.error.main,
    padding: "1rem",
    display: "flex",
    position: "absolute",
    top: "-1rem",
    left: "1rem",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.2)",
  },
  dialogTitleTabArea: {
    left: "2rem !important",
    right: "2rem !important",
  },
  dialogTitleTextArea: {
    marginLeft: "6rem",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  dialogContentArea: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  dialogActionsArea: {
    justifyContent: "space-between",
  },
  actionButtons: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  errorButtons: {
    color: theme.palette.error.main,
    textTransform: "capitalize",
  },
  primaryButton: {
    textTransform: "capitalize",
  },
  secondaryButton: {
    textTransform: "capitalize",
  },
  divider: {
    border: 0,
    width: "100%",
    margin: 0,
    height: 1,
    backgroundImage: "-webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
  tabsContainer: {
    minHeight: "0px",
  },
  tab: {
    textTransform: "capitalize",
    borderRadius: 6,
    padding: theme.spacing(0.5),
    minHeight: "0px",
  },
  tabIndicator: {
    opacity: 0,
  },
  tabSelected: {
    fontWeight: 400,
    background: "rgba(0, 0, 0, 0.05)",
    boxShadow: "inset 0 0 8px rgba(0, 0, 0, 0.10)",
  },
}));
