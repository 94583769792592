import {
  Button,
  Divider,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { CenteredContent } from "../../../../../components/CenteredContent";
import { CenteredCircularLoading } from "../../../../../components/loading/CenteredCircularLoading";
import { UserContext } from "../../../../../components/UserContext";
import { locationPrefix, saveFile } from "../../../../../utils/Files";
import { sharedStyles } from "../../../../styles";
import { FormularyEditingContext } from "../../FormularyEditingContext";
import {
  APIFileType,
  HPMSFileInfo,
  HPMSFileToUpload,
  HPMSFileValidationErrorResponse,
  SnapshotHistory,
  SnapshotStatus,
} from "../../types";
import { FinishedStep } from "./FinishedStep";
import { FirstStep } from "./FirstStep";
import { FourthStep } from "./FourthStep";
import { SecondStep } from "./SecondStep";
import { ThirdStep } from "./ThirdStep";

const useStyles = makeStyles({
  wizardPaper: {
    position: "relative",
    width: "65%",
    height: "57vh",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: 20,
  },
  stepper: {
    padding: 0,
    width: "100%",
  },
  buttons: {
    position: "absolute",
    bottom: 20,
    left: 20,
  },
  fullHeightFlexCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  fullWidthFlexHidden: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
});

interface Props {
  snapshotHistory: Array<SnapshotHistory>;
  formularyId: string;
  onFinishConfig: () => void;
}

type Steps = 0 | 1 | 2 | 3 | 4;

export const PartDConfiguration: React.FC<Props> = (props) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const {
    user: { subAccountId },
  } = React.useContext(UserContext);
  const isEditing = React.useContext(FormularyEditingContext);

  const [showNewConfigStep, setShowNewConfigStep] = React.useState(
    props.snapshotHistory.length === 0 ||
      !props.snapshotHistory.some((snapshot) => snapshot.status === SnapshotStatus.COMPLETED)
  );
  const [currentStep, setCurrentStep] = React.useState<Steps>(
    props.snapshotHistory.some((snapshot) => snapshot.status === SnapshotStatus.COMPLETED) ? 4 : 0
  );
  const [filesToBeUploaded, setFilesToBeUploaded] = React.useState<Array<HPMSFileToUpload>>([]);
  const [formularyFiles, setFormularyFiles] = React.useState<Array<HPMSFileInfo>>([]);
  const [isNextDisabled, setIsNextDisabled] = React.useState(true);
  const [timestamp, setTimestamp] = React.useState(Date.now());
  const [isUploadingFiles, setIsUploadingFiles] = React.useState(false);

  // can be variadic if necessary
  const handleAddFile = (file: HPMSFileToUpload) => {
    const formularyFileToAdd: HPMSFileInfo = {
      type: file.type,
      name: file.file.name,
      status: "not validated",
    };

    if (formularyFiles.some((formularyFile) => formularyFile.type === file.type)) {
      setFormularyFiles(
        formularyFiles.map((formularyFile) =>
          formularyFile.type === file.type ? formularyFileToAdd : formularyFile
        )
      );
    } else {
      setFormularyFiles(formularyFiles.concat(formularyFileToAdd));
    }

    if (filesToBeUploaded.some((fileToBeUploaded) => fileToBeUploaded.type === file.type)) {
      setFilesToBeUploaded(
        filesToBeUploaded.map((fileToBeUploaded) =>
          fileToBeUploaded.type === file.type ? file : fileToBeUploaded
        )
      );
    } else {
      setFilesToBeUploaded(filesToBeUploaded.concat(file));
    }

    setIsNextDisabled(false);
  };

  const handleRemoveFile = (fileType: APIFileType) => {
    setFormularyFiles(formularyFiles.filter((file) => file.type !== fileType));
    setFilesToBeUploaded(filesToBeUploaded.filter((file) => file.type !== fileType));
  };

  const handleValidationSuccess = () => {
    setFormularyFiles(formularyFiles.map((file) => ({ ...file, status: "validated" })));
    setIsNextDisabled(false);
  };

  const handleValidationFailure = (validationErrors: Array<HPMSFileValidationErrorResponse>) => {
    setIsNextDisabled(true);
    setFormularyFiles(
      formularyFiles.map((formularyFile) => {
        const fileError = validationErrors.find((error) => error.fileType === formularyFile.type);
        return fileError ? { ...formularyFile, status: "error", errors: fileError } : formularyFile;
      })
    );
  };

  const getButtonVisibility = (): { backButton: boolean; nextButton: boolean } => {
    if (currentStep >= 4) {
      return { backButton: false, nextButton: false };
    } else if (currentStep === 3) {
      return { backButton: true, nextButton: false };
    } else if (currentStep === 0) {
      return { backButton: false, nextButton: true };
    } else {
      return { backButton: true, nextButton: true };
    }
  };

  const handleNext = () => {
    if (filesToBeUploaded.length > 0) {
      setIsUploadingFiles(true);
      Promise.all(
        filesToBeUploaded.map(({ file }) =>
          saveFile({
            fileType: {
              type: "formulary file",
              formularyId: props.formularyId,
              utcTimestamp: timestamp,
            },
            file,
          })
        )
      )
        .then(() => {
          setFilesToBeUploaded([]);
          if (currentStep + 1 === 2) {
            setIsNextDisabled(formularyFiles.some((file) => file.status !== "validated"));
          }
          incrementStep();
        })
        .catch((e) => {
          console.error("upload files err", e);
        })
        .finally(() => {
          setIsUploadingFiles(false);
        });
    } else {
      incrementStep();
    }
  };

  const handleBack = () => {
    decrementStep();
    setIsNextDisabled(false);
  };

  const incrementStep = () => {
    if (currentStep >= 0 && currentStep < 4) {
      setCurrentStep((currentStep + 1) as Steps);
    }
  };
  const decrementStep = () => {
    if (currentStep <= 4 && currentStep > 0) {
      setCurrentStep((currentStep - 1) as Steps);
    }
  };

  const handleStartNewConfig = () => {
    setFormularyFiles([]);
    setCurrentStep(0);
    setTimestamp(Date.now());
  };

  const renderCurrentStep = () => {
    const hasPassedValidationStep = formularyFiles.every((file) => file.status === "validated");
    switch (currentStep) {
      case 0:
        return (
          <FirstStep
            mainFormularyFile={formularyFiles.find(
              (file) => file.type === APIFileType.HPMS_FormularyFile
            )}
            onAddMainFile={handleAddFile}
            onRemoveMainFile={() => {
              setIsNextDisabled(true);
              handleRemoveFile(APIFileType.HPMS_FormularyFile);
            }}
          />
        );
      case 1:
        return (
          <SecondStep
            formularyFiles={formularyFiles}
            areFilesValidated={hasPassedValidationStep}
            onAddFile={handleAddFile}
            onRemoveFile={handleRemoveFile}
          />
        );
      case 2:
        if (!isNextDisabled && !hasPassedValidationStep) {
          setIsNextDisabled(true);
        }
        return (
          <ThirdStep
            formularyFiles={formularyFiles}
            onValidationSuccess={handleValidationSuccess}
            onValidationFailure={handleValidationFailure}
            uploadLocationPrefix={locationPrefix(
              {
                type: "formulary file",
                formularyId: props.formularyId,
                utcTimestamp: timestamp,
              },
              subAccountId
            )}
          />
        );
      case 3:
        return (
          <FourthStep
            formularyFiles={formularyFiles}
            haveFilesPassedValidation={hasPassedValidationStep}
            formularyId={props.formularyId}
            uploadLocationPrefix={locationPrefix(
              {
                type: "formulary file",
                formularyId: props.formularyId,
                utcTimestamp: timestamp,
              },
              subAccountId
            )}
            onSnapshotComplete={props.onFinishConfig}
          />
        );
      case 4:
        return (
          <FinishedStep
            formularyFiles={formularyFiles}
            onStartNewConfig={handleStartNewConfig}
            onFinishConfig={props.onFinishConfig}
          />
        );
    }
  };

  function renderContent() {
    if (isEditing) {
      if (showNewConfigStep) {
        return (
          <div className={classes.fullHeightFlexCenter}>
            <div style={{ margin: 10 }}>
              <Typography variant="h6" align="center">
                Formulary not yet configured
              </Typography>
            </div>
            <div className={classes.fullWidthFlexHidden}>
              <Divider variant="middle" style={{ width: "100%" }} />
              <Button
                variant="contained"
                color="primary"
                style={{ margin: 10, flexShrink: 0 }}
                onClick={() => {
                  setShowNewConfigStep(false);
                }}
              >
                Begin
              </Button>
              <Divider variant="middle" style={{ width: "100%" }} />
            </div>
          </div>
        );
      } else if (isUploadingFiles) {
        return <CenteredCircularLoading />;
      } else {
        const { backButton, nextButton } = getButtonVisibility();
        return (
          <>
            <Stepper className={classes.stepper} activeStep={currentStep} alternativeLabel>
              {new Array(4).fill(undefined).map((_, index) => (
                <Step key={index}>
                  <StepLabel>{""}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderCurrentStep()}
            <div className={classes.buttons}>
              {backButton && (
                <Button onClick={handleBack} style={{ marginRight: 10 }}>
                  Back
                </Button>
              )}
              {nextButton && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isNextDisabled}
                  onClick={handleNext}
                  style={{ marginLeft: 10 }}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        );
      }
    } else {
      return (
        <CenteredContent>
          <Typography>Can only modify configuration while editing</Typography>
        </CenteredContent>
      );
    }
  }

  return (
    <section className={sharedClasses.tabContent}>
      <Paper className={classes.wizardPaper}>{renderContent()}</Paper>
    </section>
  );
};
