import * as React from "react";
import ReactDom from "react-dom";
import { Typography } from "@material-ui/core";
import { CenteredCircularLoading } from "../loading/CenteredCircularLoading";

const ImageCompressionLoading: React.FC = () => {
  return (
    <div
      style={{
        height: 150,
        padding: 25,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography style={{ marginBottom: 25 }}>Loading Image</Typography>
      <CenteredCircularLoading />
    </div>
  );
};

const DIALOG_CLASS = "tox-dialog";
const DIALOG_CONTENT_CLASS = "tox-dialog__content-js";
const LOADING_CONTAINER_ID = "react-loading-container";

export const addLoadingSpinner = () => {
  const dialogElement = document.querySelector(`.${DIALOG_CLASS}`);
  if (dialogElement !== null) {
    const dialogContentElement = dialogElement.querySelector(`.${DIALOG_CONTENT_CLASS}`);
    if (dialogContentElement !== null) {
      const reactContainerElement = document.createElement("div");
      reactContainerElement.id = LOADING_CONTAINER_ID;
      dialogElement.insertBefore(reactContainerElement, dialogContentElement);
      ReactDom.render(<ImageCompressionLoading />, reactContainerElement);
      (dialogContentElement as HTMLElement).style.display = "none";
    }
  }
};

export const removeLoadingSpinner = () => {
  const dialogElement = document.querySelector(`.${DIALOG_CLASS}`);
  if (dialogElement !== null) {
    const reactContainerElement = dialogElement.querySelector(`#${LOADING_CONTAINER_ID}`);
    const dialogContentElement = dialogElement.querySelector(`.${DIALOG_CONTENT_CLASS}`);
    if (reactContainerElement !== null && dialogContentElement !== null) {
      ReactDom.unmountComponentAtNode(reactContainerElement);
      dialogElement.removeChild(reactContainerElement);
      (dialogContentElement as HTMLElement).style.display = "block";
    }
  }
};
